import React from "react";

import { Link } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
const Footer = () => {
  return (
    <section className="container py-3">
      <hr />
      <div className="row-cols-lg-2 row-cols-1 row align-items-start gy-2">
        <div className="d-flex flex-column col-lg-8">
          <span className="fs-4 fw-bold text-black ">
            {" "}
            Sign up to get updates
          </span>
          <p>
            Stay up to date with the lates news , announcememnts and articles
          </p>
        </div>
        <div className="d-flex gap-1 col-lg-4">
          <input
            type="text"
            className="form-control py-1 w-100"
            placeholder="Enter Email"
          />
          <button className="btn btn-dark">Subscribe</button>
        </div>
      </div>

      <div className="row-cols-lg-6 row  p-0 m-0  py-5 gy-3 ">
        <div className="col-lg-4 p-0 m-0">
          <img src={"/assets/icons/onf.png"} alt="" srcSet="" width={250} />
          <p className="mt-3 ">
            Non profit organization that aims to provide a platform for
            organizations to connect with each other and the public.
          </p>
        </div>
        <div className="row row-cols-lg-5 row-cols-2 col-lg-8 gy-3 justify-content-between">
          <ul className=" m-0 d-flex flex-column align-items-start gap-1 list-unstyled">
            <li className=" text-black-50  m-0 p-0">
              <h6 className=" fw-bold text-black">Overview</h6>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/faqs'} className="link">FAQs</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/contactus'} className="link">Contact Us</Link>
            </li>

            <li className="   m-0 p-0">
              <Link to={'/about'} className="link">About</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/teams'} className="link">Teams</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/careers'} className="link">Careers</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/blog'} className="link">Blog</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/press'} className="link">Press</Link>
            </li>
          </ul>
          <ul className=" m-0 d-flex flex-column align-items-start gap-1 list-unstyled">
            <li className=" text-black-50  m-0 p-0">
              <Link className="fw-bold text-black">Organizations</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/organizations'} className="link">Homepage</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/organizations/filte'} className="link">Find organizations</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/organizations'} className="link">Provenience</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/organizations'} className="link">Government</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/organizations'} className="link">Education</Link>
            </li>
          </ul>

          <ul className=" m-0 d-flex flex-column align-items-start gap-1 list-unstyled">
            <li className=" text-black-50  m-0 p-0">
              <Link to={'/business'} className="fw-bold text-black">Business</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/business'}className="link">Category</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/business'} className="link">Higest Rating</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/business'} className="link">Most Reviewed</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/business'} className="link">Most Viewed</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/business'} className="link">List your business</Link>
            </li>
          </ul>

          <ul className=" m-0 d-flex flex-column align-items-start gap-1 list-unstyled">
            <li className=" text-black-50  m-0 p-0">
              <Link className="text-black fw-bold">Rentals</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/rental/add'} className="link">Add Rentals</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/rental/filter/'} className="link">Rooms</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/rental/filter'} className="link">Flate Mates</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/rental/filter'} className="link">Teamup</Link>
            </li>
          </ul>
          <ul className="col-1 m-0 d-flex flex-column align-items-start gap-1 list-unstyled">
            <li className=" text-black-50  m-0 p-0">
              <Link className="text-black fw-bold">Professionals</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/professionals'} className="link">Upgrade your profile</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/professionals/filter/'} className="link">Find a professional</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/professionals'} className="link">Services</Link>
            </li>
            <li className="   m-0 p-0">
              <Link to={'/professionals'} className="link">Easy calling</Link>
            </li>

            <li className="   m-0 p-0">
              <Link to={'/professionals'} className="link">Appointment</Link>
            </li>
   
          </ul>
        </div>
      </div>
      <hr className="my-2" />

      <div className="d-flex justify-content-between">
        <span>@ 2023 | ONF Canada | All right reserved</span>
        <div className="d-flex gap-1">
          <Link className="link">
            <TwitterIcon />
          </Link>
          <Link className="link">
            <FacebookIcon />
          </Link>
          <Link className="link">
            <InstagramIcon />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
