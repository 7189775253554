import { Box, Button, ButtonGroup, Container, Typography } from "@mui/material";
import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Email,
  Facebook,
  RemoveRedEye,
  ShareOutlined,
  Twitter,
} from "@mui/icons-material";
import { Chip } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LaunchIcon from "@mui/icons-material/Launch";
import { ImCross } from "react-icons/im";

import { staticCImg } from "../../../../../utils/globalSettings";

import {
  adminVerifyProfessionalAction,
  adminGiveReviewProfessionalAction,
} from "../../../../../context/actions/admin/professionalAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ProfessionalReviewDetail = ({ data, activeindex, closeModal }) => {
  const dispatch = useDispatch();
  const [comment, setcomment] = React.useState("");

  const [readmore, setreadmore] = React.useState(false);

  const toggleReadmore = () => {
    setreadmore((prev) => !prev);
  };

  const commentchange = (e) => {
    if (comment.length < 300) {
      setcomment(e.target.value);
    }
  };
  const handlegiveReview = (e) => {
    e.preventDefault();
    dispatch(
      adminGiveReviewProfessionalAction({
        id: data._id,
        data: comment,
        toast: toast,
        activeindex,
      })
    );

    setcomment("");
  };

  const handleApprove = (e) => {
    e.preventDefault();
     const options = {
       status: true,
       active: true,
     };
     dispatch(
       adminVerifyProfessionalAction({
         id: data?._id,
         data: options,
         toast: toast,
         activeindex,
       })
     );
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        paddingBlock: "3vh",
        overflow: "hidden",
        overflowY: "scroll",
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="container d-flex flex-column"
    >
      <section className="profesional-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Professional Reivew Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={closeModal}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <Box
        className="  w-100  overflow-hidden position-relative d-flex align-items-end"
        sx={{
          minHeight: "35vh",
          height: "max-content",
        }}
      >
        <Box
          sx={{
            height: { xs: "37%", md: "55%" },
          }}
          className="  w-100 rounded rounded-4 overflow-hidden  position-absolute top-0 start-0"
        >
          <img
            src={staticCImg}
            alt=""
            srcSet=""
            className="object-cover w-100 h-100  "
          />
        </Box>

        <Box
          sx={{
            paddingLeft: { lg: "7%", md: "5%", xs: "0" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center" },
            justifyContent: "center",
            width: "100%",
          }}
          className=""
        >
          <Box
            sx={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
              gap: "2%",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
            }}
            className=""
          >
            <Box
              className=" position-relative rounded rounded-circle "
              sx={{
                overflow: "hidden",
                maxHeight: "175px",
                height: { xs: "calc(50px + 15vw)", md: "175px" },
                width: { xs: "calc(50px + 15vw)", md: "175px" },
                maxWidth: "175px",
                border: "10px solid #f1f1f1",
              }}
            >
              <img
                alt="Remy Sharp"
                src={data?.userPic}
                style={{
                  cursor: "pointer",
                }}
                className="object-cover object-center w-100 h-100"
              />
            </Box>
            <Box
              sx={{
                lineHeight: "1em",
              }}
              className="h-maxcontent d-flex flex-column justify-content-start pt-md-5 text-center text-md-start "
            >
              <h3 className="fw-bold m-1">
                {data?.firstName} {data?.lastName}
              </h3>

              <p className="mb-1 ms-1 ">{data?.profession}</p>
              <div className="d-flex align-items-center  mt-1 text-black p-0">
                <p>
                  <LocationOnIcon
                    fontSize="small"
                    className="m-0 p-0 w-maxcontent"
                  />
                  {data.provenience},{data.city}
                </p>
              </div>
            </Box>
          </Box>

          <ButtonGroup
            className="text-black"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button>
              <div className="comments d-flex align-items-center gap-1">
                <RemoveRedEye />
                {data.viewCount}
              </div>
            </Button>
            <Button>
              <div className="clapped d-flex align-content-center gap-1">
                <ShareOutlined />
                Share
              </div>
            </Button>
            {/* <Button onClick={togglebookmark}>
              <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                {bookmarked ? (
                  <BookmarkIcon onClick={togglebookmark} role="button" />
                ) : (
                  <MdOutlineBookmarkAdd role="button" />
                )}
              </div>
            </Button> */}
          </ButtonGroup>
        </Box>
      </Box>

      <section className="row row-cols-1 row-cols-lg-2 gx-2 mt-2">
        <div className="left col-lg-8">
          <section className="about d-flex flex-column">
            <h5 className="fw-bold">About</h5>
            <Typography
              variant="p"
              sx={{
                fontSize: "clamp(0.7rem , 1.5vw , 1rem)",
              }}
            >
              {readmore ? data?.description : data?.description?.slice(0, 700)}
              {data?.description?.length > 700 && !readmore ? "..." : null}{" "}
            </Typography>
            <hr />
            {data?.description?.length > 400 ? (
              !readmore ? (
                <Typography
                  onClick={toggleReadmore}
                  className="w-100 text-center text-black fw-bold "
                  role="button"
                  variant="p"
                >
                  ShowMore
                </Typography>
              ) : (
                <Typography
                  onClick={toggleReadmore}
                  className="w-100 text-center text-black fw-bold"
                  role="button"
                  variant="p"
                >
                  ShowLess
                </Typography>
              )
            ) : null}
          </section>

          <section className="offers">
            <h5 className="fw-bold">Offered Services</h5>
            <div className="d-flex flex-wrap gap-1">
              {data?.offers?.slice(0, 4).map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      backgroundColor: "#F2F7F2",
                      color: "#13544E",
                    }}
                    label={item}
                    size="medium"
                  />
                );
              })}
            </div>
          </section>
        </div>
        <Box
          sx={{
            height: "max-content",
          }}
          className="right-section col-lg-4 d-flex flex-column gap-2 rounded p-2 "
        >
          <section>
            <h5 className="fw-bold">Get in touch | Contacts</h5>
            <div className="d-flex gap-2 mt-1">
              <Chip
                icon={<FacebookIcon />}
                label="Facebook"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
              <Chip
                icon={<TwitterIcon />}
                label="Twitter"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
              <Chip
                icon={<LaunchIcon />}
                label="Website"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
            </div>
            <div className="d-flex gap-3 mt-3">
              <Email
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <div>
                <p className="m-0 p-0">General Email</p>
                <p className=" fw-bold">{data?.email}</p>
              </div>
            </div>

            <div className="d-flex gap-3 mt-3">
              <Facebook
                sx={{
                  fontSize: "3.5rem",
                  color: "#4267B2",
                }}
              />
              <div className="d-flex flex-column overflow-hidden">
                <p className="m-0 p-0">Facebook | Social Media</p>
                <div className="d-flex overflow-hidden">
                  <h6 className=" fw-bold text-overflow-hidden">
                    {data?.socialMediaLinks?.facebookLink}
                  </h6>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Twitter
                sx={{
                  fontSize: "3.5rem",
                  color: "#1DA1F2",
                }}
              />
              <div className="overflow-hidden d-flex flex-column">
                <p className="p-0">Twitter | Social Media</p>
                <p className="fw-bold text-overflow-hidden overflow-hidden">
                  {data?.socialMediaLinks?.twitterLink}
                </p>
              </div>
            </div>
          </section>
        </Box>
      </section>
      <section className=" col-lg-10 w-100 mt-5 w-100 ">
        <h4 className="my-2 p-0 m-0">Is this professional reviewed?</h4>
        <div className="mt-2 text-end d-flex flex-column">
          <textarea
            className="form-control w-100 p-2"
            id="textAreaExample"
            rows="4"
            placeholder="Give your comments here..."
            value={comment}
            onChange={commentchange}
          ></textarea>
          <span className="my-2">{comment.length} / 300</span>
          <div className="d-flex w-100 justify-content-end gap-2 mt-2">
            <button
              className="btn shadow-0 "
              onClick={() => {
                setcomment("");
              }}
            >
              Clear
            </button>
            <button
              onClick={handlegiveReview}
              className="btn shadow-0 btn-danger"
            >
              Need More Info
            </button>
            <button
              onClick={handleApprove}
              className="btn shadow-0 btn-success "
            >
              Approve
            </button>
          </div>
        </div>
      </section>
    </Container>
  );
};

export default ProfessionalReviewDetail;
