import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Badge, List, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { NavLink } from "react-router-dom";
import { logout } from "../../context/reducers/authenticateSlice";
// AiFillHeart
import { AiFillHeart } from "react-icons/ai";
// BsPersonFillCheck
import { BsPersonFillCheck } from "react-icons/bs";

// RiLogoutCircleRLine
import { RiLogoutCircleRLine } from "react-icons/ri";

import CloseIcon from "@mui/icons-material/Close";

// IoChatbubbles
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import { FaBell } from "react-icons/fa";
import { IoChatbubbles } from "react-icons/io5";

// BiTimeFive
import { BiTimeFive } from "react-icons/bi";
import { MdOutlineSecurity } from "react-icons/md";

// HiOutlineAcademicCap
import moment from "moment";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { getNotifications } from "../../context/actions/notificationAction";

const Navbar = () => {
  const dispatch = useDispatch();
  const page = [
    {
      name: "Organizations",
      path: "/organizations",
    },
    {
      name: "Business",
      path: "/business",
    },
    {
      name: "Rental",
      path: "/rental",
    },
    // {
    //   name: "Professionals",
    //   path: "/professionals",
    // },
    {
      name: "Blogs",
      path: "/blogs",
    },
    {
      name: "Abroad Study",
      path: "/abroad",
    },
    {
      name: "Events & Gallery",
      path: "/events",
    },
    {
      name: "News",
      path: "/news",
    },
    {
      name: "Help",
      path: "/help",
    },
  ];

  const { isAuth, user_data } = useSelector((state) => state.auth_slice);

  // use navigate

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleLogout = () => {
    dispatch(logout());
    localStorage.removeItem("onf_canada");
    navigate("/login");

    handleMenuClose();
  };

  const handlebookmark = () => {
    navigate("/bookmarks");
  };

  const handleverification = () => {
    navigate("/verification");
  };

  const handleChat = () => {
    navigate("/rental/contact");
  };

  const handleNotification = () => {
    navigate("/notification");
  };
  const [isOpen, setOpen] = React.useState(false);
  const toggleNav = () => {
    setOpen((prev) => !prev);
  };

  const closeSmNav = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const { notifications } = useSelector((state) => ({
    notifications: state.notification_reducer.notifications,
  }));

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-white navbar-light shadow-2-soft position-sticky  top-0 left-0"
        style={{
          zIndex: "5",
        }}
      >
        <div className="container">
          <span className="p-2  me-3" onClick={() => navigate("/")}>
            <img
              height={"35px"}
              src="/assets/icons/onf.png"
              alt="logo"
              border="0"
            />
          </span>

          <div className="menu-btn" onClick={toggleNav}>
            <span
              className="navbar-toggler bg-hover "
              role="button"
              data-mdb-toggle="collapse"
              data-mdb-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </span>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
              {page.map((item, index) => {
                return (
                  <NavLink
                    to={item.path}
                    key={index}
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link active py-0 px-1 m-0"
                        : "nav-link py-0 px-1 m-0"
                    }
                  >
                    <List
                      className="nav-item px-2 py-1 rounded rounded-4 "
                      role={"button"}
                      sx={{
                        fontSize: { md: "0.8em", lg: "1rem" },
                        "&:hover": {
                          backgroundColor: "var(--hover-color)",
                        },
                      }}
                    >
                      {item.name}
                    </List>
                  </NavLink>
                );
              })}
            </ul>
            <div className="">
              {isAuth ? (
                <div className="d-flex gap-2 align-items-center">
                  <div className="dropdown">
                    <Box
                      type="button"
                      id="dropdownMenuButton"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                      // onClick={handleNotification}
                      className="d-flex align-items-center justify-content-center"
                      sx={{
                        backgroundColor: "rgb(228,230,235)",
                        borderRadius: "50%",
                        padding: "7px",
                        cursor: "pointer",
                      }}
                    >
                      <Badge badgeContent={"🤍"} color="error">
                        <FaBell size={20} color="black" />
                      </Badge>

                      <Typography
                        sx={{
                          width: "100%",
                          maxWidth: { xs: "200px", sm: "400px" ,lg:"600px"},
                        }}
                        variant="ul"
                        className="dropdown-menu  mt-4 p-4 dropdown-menu-right h-maxcontent"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {notifications?.map((item, index) => (
                          <>
                            <li>
                              <h6 className="fw-bold m-0 p-0 mb-2">
                                {item?.title}
                              </h6>
                              <p>{item?.description.slice(0, 130)}...</p>
                              <small>
                                <BiTimeFive />{" "}
                                {moment(item?.scheduledDate).fromNow()}
                              </small>
                            </li>
                            <hr className="mt-2 mb-2" />
                          </>
                        ))}
                        <li>
                          {/* see all notification */}
                          <button
                            onClick={handleNotification}
                            className="btn shadow-0 btn-sm w-100"
                          >
                            See all notification
                          </button>
                        </li>
                      </Typography>
                    </Box>
                  </div>
                  <Box
                    onClick={handleChat}
                    className="d-flex align-items-center justify-content-center"
                    sx={{
                      backgroundColor: "rgb(228,230,235)",
                      borderRadius: "50%",
                      padding: "7px",
                      cursor: "pointer",
                    }}
                  >
                    <Badge badgeContent={1} color="error">
                      <IoChatbubbles size={20} color="black" />
                    </Badge>
                  </Box>
                  <div className="dropdown">
                    <Link
                      className="dropdown-toggle d-flex align-items-center hidden-arrow"
                      id="navbarDropdownMenuAvatar"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://mdbcdn.b-cdn.net/img/new/avatars/2.webp"
                        className="rounded-circle"
                        height="35"
                        alt="Black and White Portrait of a Man"
                        loading="lazy"
                      />
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-end p-3 shadow"
                      aria-labelledby="navbarDropdownMenuAvatar"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleProfile}
                        >
                          <BsPersonFillCheck size={17} /> Profile
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleverification}
                        >
                          <MdOutlineSecurity size={17} /> Verification
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handlebookmark}
                        >
                          <HiOutlineAcademicCap size={17} /> Abroad Study
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handlebookmark}
                        >
                          <AiFillHeart size={17} /> Favourite
                        </button>
                      </li>

                      <li>
                        <button
                          className="dropdown-item"
                          onClick={handleLogout}
                        >
                          <RiLogoutCircleRLine /> Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <span onClick={() => navigate("/login")}>
                  <button
                    type="button"
                    className="btn btn-outline-dark  w-100 "
                    data-mdb-ripple-color="dark"
                  >
                    Login
                  </button>
                </span>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
