import { Button, ButtonGroup } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ImCross } from 'react-icons/im';
import { FormInputB } from '../../../../components/Index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: "95vw", lg: "50%" },
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 3,
};

export default function AddNotificationModal({
  open,
  handleClose,
  notificationData,
  errors,
  handleNotificationDataChange,
  handleCreateNotification,
  handleBroadcastClick,
  handleScheduleClick,
  handleDateChange,
}) {
  const { title, description, broadcast, scheduledDate } = notificationData;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="fs-5 fw-bold text-black">
                  Notification & <span className="text-danger">Announcement</span>
                </span>
                <small>Create, broadcast, and manage</small>
              </div>
              <Box
                className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
                sx={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50px',
                }}
                role="button"
                onClick={handleClose}
              >
                <ImCross style={{ fontSize: '1.3em' }} />
              </Box>
            </div>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <FormInputB
                label={'Create notification title'}
                name={'title'}
                type={'text'}
                placeholder={'Ex: Welcome to ONFCanada'}
                value={title}
                handleChange={handleNotificationDataChange}
                error={errors.title}
              />
              {errors.title && <small className="text-danger">{errors.title}</small>}
              <div className="form-group mt-2 w-100">
                <small className="mb-1 text-black">Description</small>
                <textarea
                  className="form-control py-2"
                  placeholder={'Create notification description'}
                  value={description}
                  onChange={handleNotificationDataChange}
                  name="description"
                />
              </div>
              {errors.description && <small className="text-danger">{errors.description}</small>}
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
                sx={{
                  width: '100%',
                  marginTop: '1rem',
                }}
              >
                <Button
                  onClick={handleBroadcastClick}
                  variant={broadcast ? 'contained' : 'outlined'}
                >
                  Broadcast
                </Button>
                <Button
                  onClick={handleScheduleClick}
                  variant={!broadcast ? 'contained' : 'outlined'}
                >
                  Scheduled
                </Button>
              </ButtonGroup>
              {broadcast ? (
                <div style={{ marginTop: '1rem' }}>
                  <small className="text-danger fw-bold m-0 p-0">
                    Broadcast on <br />
                  </small>
                  <small className="m-0 p-0">
                    {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                  </small>
                </div>
              ) : (
                <div style={{ marginTop: '1rem' }}>
                  <small className="text-danger fw-bold m-0 p-0">
                    Scheduled on <br />
                  </small>
                  <DatePicker
                    selected={scheduledDate}
                    onChange={handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    className="form-control w-100"
                    dateFormat="MM/dd/yyyy HH:mm"
                  />
                </div>
              )}
            </Typography>
            <div className="d-flex justify-content-end mt-3">
              <button className="btn btn-outline-danger me-2" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn btn-dark" onClick={handleCreateNotification}>
                Create
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
