import CallMadeIcon from "@mui/icons-material/CallMade";
import { Box, List, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getAbroadStudyDetailsAction } from "../../context/actions/abordStudyAction";
// AiFillCheckCircle
import { CheckCircle } from "@mui/icons-material";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { Helmet } from "react-helmet-async";

const StudentCountryDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(getAbroadStudyDetailsAction({ id }));
  }, [id]);

  const { abroadStudyDetails } = useSelector((state) => ({
    abroadStudyDetails: state.abroad_study_reducer.abroadStudyDetails,
  }));

  // if (
  //   (abroadStudyDetails && Object?.keys(abroadStudyDetails).length === 0) ||
  //   abroadStudyDetails === undefined
  // ) {
  //   return <NotFoundPage />;
  // }

  const [formData, setFormData] = React.useState({
    fullname: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name] : e.target.value});
  }

  return (
    <>
      <Helmet>
        <title>
          {abroadStudyDetails?.country}
        </title>
        <meta name="description"
          data-rh="true"
          content={abroadStudyDetails?.countryOverview?.description} />
        <link rel="canonical" href="/register" />
      </Helmet>
      <Box
        sx={{
          minHeight: "100vh",
          paddingBottom: "3vh",
        }}
        className="container "
      >
        <Box
          className="headerimg position-relative rounded rounded-5 overflow-hidden"
          sx={{
            height: "250px",
          }}
        >
          <Box
            className="w-100 h-100 position-absolute top-0 start-0"
            sx={{
              background:
                "linear-gradient(90deg, #000000 -1.46%, rgba(138, 91, 91, 0) 79%);",
            }}
          ></Box>
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}${abroadStudyDetails?.coverpage}`}
            alt=""
            srcSet=""
            className="w-100 h-100 object-cover object-center"
          />
          <Box
            className="d-flex flex-column position-absolute"
            sx={{
              top: "81px",
              left: "3%",
              fontSize: "1.3rem",
              lineHeight: "1.8em",
            }}
          >
            <small className="text-white">Study in</small>
            <div className="d-flex gap-3 align-items-center">
              <Typography
                variant="p"
                color="initial"
                className=" text-white fw-bold"
                sx={{
                  fontSize: { xs: "clamp(0.8em , 0.6em + 1vw ,2.3vw)" },
                }}
              >
                {abroadStudyDetails?.country}
              </Typography>
              <img
                src="https://static.vecteezy.com/system/resources/thumbnails/016/328/557/small/canada-flat-rounded-flag-with-transparent-background-free-png.png"
                width={30}
                height={30}
                className="rounded rounded-cirlce"
                alt=""
              />
            </div>

            <Link
              to={`/abroad/requirements/${abroadStudyDetails?._id}`}
              className="btn btn-outline-white mt-2"
            >
              Explore Requirements
            </Link>
          </Box>
        </Box>

        <div className="row-cols-1 row row-cols-lg-2 gx-2 mt-3">
          <div
            className="student-left col-lg-8
        d-flex flex-column gap-4
        "
          >
            <p>{abroadStudyDetails?.countryOverview?.description}</p>
            <section>
              <h5 className="text-black fw-bold">Why Study in Canada</h5>
              <List
                sx={{
                  listStylePosition: "inside",
                  listStyleType: "disc",
                }}
                className="m-0 p-0"
              >
                {abroadStudyDetails?.countryOverview?.whyStudy?.map(
                  (item, index) => (
                    <li key={index}>{item?.text}</li>
                  )
                )}
              </List>
            </section>
            <section>
              <h5 className="fw-bold">Requirements</h5>
              <List
                sx={{
                  listStylePosition: "inside",
                  listStyleType: "disc",
                }}
                className="m-0 p-0"
              >
                {abroadStudyDetails?.countryOverview?.requirements?.map(
                  (item, index) => (
                    <li key={index}>{item?.text}</li>
                  )
                )}
              </List>
              <Link
                to={`/abroad/requirements/${abroadStudyDetails?._id}`}
                className="btn btn-sm btn-outline-black w-100 mt-2"
              >
                Explore more requirements <CallMadeIcon />
              </Link>
            </section>
            <section>
              <h5>Cost of Living</h5>
              <table className="table table-bordered text-center table-striped  table-hover">
                <thead>
                  <tr style={{ backgroundColor: "#6C7AE0", color: "white" }}>
                    <th scope="col-2">S.no.</th>
                    <th scope="col">
                      Study expenses in Canada for international students
                    </th>
                    <th scope="col">Average Anual fee</th>
                  </tr>
                </thead>
                <tbody>
                  {abroadStudyDetails?.countryOverview?.costOfLiving?.map(
                    (item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.avgFees}</td>
                        <td>{item?.course}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </section>
            <section>
              <h5>Average Tution Fee</h5>
              <table className="table table-bordered text-center table-striped  table-hover">
                <thead>
                  <tr style={{ backgroundColor: "#6C7AE0", color: "white" }}>
                    <th scope="col-2">S.no.</th>
                    <th scope="col">
                      Study expenses in Canada for international students
                    </th>
                    <th scope="col">AverageAnual fee</th>
                  </tr>
                </thead>
                <tbody>
                  {abroadStudyDetails?.countryOverview?.avgTuition?.map(
                    (item, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.avgFees}</td>
                        <td>{item?.course}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </section>

            <section>
              <h5>Popular course in Canada</h5>
              <List
                sx={{
                  listStylePosition: "inside",
                  listStyleType: "disc",
                }}
                className="m-0 p-0"
              >
                {abroadStudyDetails?.countryOverview?.popularCourse?.map(
                  (item, index) => (
                    <li key={index}>{item?.text}</li>
                  )
                )}
              </List>
            </section>
          </div>

          <Link
            to={`/abroad/requirements/${abroadStudyDetails?._id}`}
            className="student-right col-lg-4 d-flex flex-column gap-3 p-4"
          >
            <section className="card rounded rounded-2 shadow-2 shadow shadow-3-strong p-4">
              <div className=" ">
                <span className="fw-bold text-black">
                  Before / After Applying for -
                </span>{" "}
                <strong className="fw-bold text-black text-danger">
                  {abroadStudyDetails?.country}
                </strong>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="">
                  <div className="d-flex align-items-center">
                    <CheckCircle /> <span>Common, Financial checklist</span>
                  </div>
                  <div className="d-flex align-items-cent">
                    <CheckCircle />
                    <span>After reaching checklist</span>
                  </div>
                </div>
                <Box
                  className="bg-hover rounded rounded-4 d-flex align-items-center justify-content-center"
                  sx={{
                    height: "50px",
                    width: "50px",
                  }}
                >
                  <CallMadeIcon />
                </Box>
              </div>
            </section>
            <section className="card rounded rounded-3 shadow shadow-3-strong p-3 d-flex flex-column gap-2" onClick={e => e.preventDefault()}>
              <p className="fw-bold text-black">General inquiry for Canada</p>{" "}
              <form>
              <input
              name={"fullname"}
              value={formData.fullname}
                type="text"
                placeholder="Fullname"
                className="form-control mb-2"
                onChange = {handleChange}
              />
              <input
               name={"email"}
               value={formData.email}
                type="email" 
                placeholder="Email"
                 className="form-control"
                 onChange= {handleChange}
                  />
              <textarea
                name={"message"}
                value={formData.message}
                placeholder="Message"
                id=""
                rows="5"
                style={{
                  resize: "none",
                }}
                className="form-control mt-2"
                onChange= {handleChange}
              ></textarea>
              </form>
            </section>
          </Link>
        </div>
      </Box>
    </>
  );
};

export default StudentCountryDetail;
