import customInstance from "./manage_access_route";
import API from "./common_api";

export const register_api = (data) => API.post("/api/user/register", data);
export const Login = (data) => API.post("/api/user/login", data);
//chaneg password
export const ChangePassword = (data) => customInstance.put("/api/user/change_password",data);

// reset password
export const ResetPassword = (data) => API.post("/api/user/reset_password", data);
// edit profile
export const EditProfile = (data) => customInstance.put("/api/user/edit_profile", data);

export const sendOtpCode = (data) => customInstance.post("/api/user/send_otp", data);
export const verifyOtpCode = (data) => API.post("/api/user/verify_otp", data);
export const google_login = (data) =>customInstance.post("/api/user/google_login", data);
export const get_all_rental_data_by_user = () => customInstance.get("/api/rental/get_rental_by_user");
export const  get_flatmate_by_user = () => customInstance.get("/api/rental/get_flatmate_by_user");

export const edit_flatemate_by_user = (id, formData) =>customInstance.put(`/api/rental/edit_flatmate_by_user/${id}`, formData);

export const edit_rental_data_by_user = (id, formData) =>customInstance.put(`/api/rental/edit_rental_by_user/${id}`, formData);
export const get_professional_data_by_user = () =>customInstance.get("/api/professional/get_professional_by_user");
// edit professional
export const edit_professional_by_user = (id, formData) =>customInstance.put(`/api/professional/update_professional/${id}`, formData);
export const get_organization_data_by_user = () =>customInstance.get("/api/organization/get_organization_by_user");
export const edit_organization_by_user = (id, data) =>customInstance.put(`/api/organization/edit_organization/${id}`,data)
export const get_business_by_user = () =>customInstance.get("/api/business/get_business_by_user");
export const edit_business_by_user = (id, data) =>customInstance.put(`/api/business/edit_business/${id}`,data)
export const post_bookmark = (id, data) => customInstance.post(`/api/user/add_to_favorite/${id}`, data);
export const createHelpSupport = (data) => API.post("/api/user/create_help", data);
export const createContactUser = (data) => API.post("/api/user/create_contact", data);
