import { MoreVert } from "@mui/icons-material";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllBusinessAction } from "../../../../context/actions/admin/businessAction";
import { Drawer } from "@mui/material";
import AdminBusinessReview from "./businessReview/AdminBusinessReview";
// import AdminOrgProfile from "./component/AdminOrgProfile";

export default function BusinessReviewTable({ keyword }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const [status, SetStatus] = React.useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    dispatch(adminGetAllBusinessAction({ keyword, page, status }));
  }, [dispatch, page, keyword, status]);

  const columns = [
    {
      field: "logo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35%", height: "auto", borderRadius: "50%" }}
        />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },

    {
      field: "action",
      headerName: "Action",
      width: 150,

      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);
         
          toggleDrawer();
        };

        return (
          <Button
            size="medium"
            variant="outlined"
            onClick={handleButtonClick}
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];

  const { business, resultperpage, totalbusinesscount, business_loading } =
    useSelector((state) => ({
      ...state.admin_reducer,
    }));

  const mappedRows = business.map((business, index) => ({
    id: business?._id,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${business?.images[0]}`,
    name: business?.introduction.businessName,
    address: `${business?.introduction.provenience}|${business?.introduction.city}`,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={business_loading}
          page={page}
          rowCount={totalbusinesscount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminBusinessReview
          activeindex={activeindex}
          data={business[activeindex]}
        />
      </Drawer>
    </div>
  );
}
