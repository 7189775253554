import React from "react";
import {
  FormInputB,
  FormSelectB,
  IconInputB,
} from "../../../../../../components/Index";
import { FaDollarSign } from "react-icons/fa";
import { Box, Input } from "@mui/material";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { adminEditFlatemateAction } from "../../../../../../context/actions/admin/flatemateAction";
import { toast } from "react-toastify";
import { stayLst } from "../../../../../../utils/flateMateSettings";
const BasicInformation = ({
  budgetPerWeek,
  lengthOfStay,
  moveInDate,
  activeindex,
  id,
  onClose,
}) => {
  const value = {
    price: budgetPerWeek,
    lengthOfStay: lengthOfStay,
    moveInDate: moveInDate.split("T")[0],
  };
  const evalue = {
    budgetPerWeek: "",
    lengthOfStay: "",
    moveInDate: "",
  };

 
  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);

  const formValidation = () => {
    const { price, lengthOfStay, moveInDate } = formValue;
    let isValid = false;
    let newerror = { ...evalue };
    if (!price || !lengthOfStay || !moveInDate) {
      if (!price) {
        newerror.price = "Please Provide budgetPerWeek *";
      }
      if (!lengthOfStay) {
        newerror.lengthOfStay = "Please Provide Length of Stay *";
      }

      if (!moveInDate) {
        newerror.moveInDate = "Please Provide Move In Date*";
      }
      newerror.message = "Invalid creadintial please check again";
      setError(newerror);
    } else {
      setError(newerror);

      isValid = true;
      return isValid;
    }
  };
  const dispatch = useDispatch();
  const onSubmit = () => {
    const isValid = formValidation();
    if (isValid) {
      dispatch(
        adminEditFlatemateAction({
          id: id,
          formData: formValue,
          activeindex: activeindex,
          toast: toast,
        })
      );
      onClose();
    }
  };
  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "moveInDate":
        const birthTime = new Date(value).getTime();
        const nowTime = new Date().getTime();
        const ageInMilliseconds = nowTime - birthTime;
        const ageInYears = Math.floor(
          ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)
        );
        setFormValue({ ...formValue, moveInDate: value, age: ageInYears });

        break;

      default:
        setFormValue({ ...formValue, [name]: value });
    }
  };
  return (
    <div className="d-flex flex-column gap-2">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <span className=" fs-5 fw-bold text-black">Basic Information</span>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          role="button"
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>
      <div className="row-cols-2 row-cols-1 row gx-3">
        <Box
          sx={{
            marginTop: "12.5px",
          }}
          className={""}
        >
          <label htmlFor={"budgetPerWeek"}>Weekly Budget</label>
          <div className="d-flex align-items-center input-group ">
            <Box
              className={
                errors.budgetPerWeek
                  ? "input-group-text h-100 shadow shadow-1-strong border border-danger  text-danger"
                  : "input-group-text h-100 shadow shadow-1-strong"
              }
              id={budgetPerWeek}
              sx={{
                borderEndEndRadius: 0,
                borderTopRightRadius: 0,
                paddingBlock: "13px",
              }}
            >
              <FaDollarSign />
            </Box>
            <Input
              type={"number"}
              className={
                errors.price
                  ? "form-control is-invalid  h-100 p-1"
                  : "form-control h-100"
              }
              placeholder={"Enter your budget per week"}
              onChange={handleFormChange}
              name={"price"}
              value={formValue.price}
              sx={{
                paddingBlock: "10px",
              }}
              disableUnderline
            />
          </div>
          <small
            id="emailHelp"
            className={errors.price ? "form-text text-danger" : ""}
          >
            {errors.price}
          </small>
        </Box>

        <Box
          sx={{
            marginTop: "13px",
          }}
          className=""
        >
          <FormSelectB
            list={stayLst}
            name={"lengthOfStay"}
            label={"Length Of Stay"}
            error={errors.lengthOfStay ? true : false}
            placeholder={"Enter your  length Of Stay"}
            type={"text"}
            value={formValue.lengthOfStay}
            handleChange={handleFormChange}
            helpertext={errors.lengthOfStay}
          />
        </Box>

        <div className="">
          <FormInputB
            name={"moveInDate"}
            label={"Move in Date"}
            error={errors.moveInDate ? true : false}
            placeholder={"Enter move In Date"}
            type={"date"}
            value={formValue.moveInDate}
            handleChange={handleFormChange}
            helpertext={errors.moveInDate}
          />
        </div>
      </div>
      {errors.message && (
        <div className="bg-danger text-center w-75 mx-auto rounded rounded-2 text-white fw-bold">
          <small>{errors.message}</small>
        </div>
      )}
      <button
        className="btn-dark btn ms-auto justify-self-end mt-3"
        onClick={onSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default BasicInformation;
