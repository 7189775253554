import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FormInput = React.memo(
  ({
    error,
    name,
    label,
    handleChange,
    type,
    required,
    helperText,
    classname,
    placeholder,
    value,
  }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <TextField
        required={required}
        error={error}
        size="sm"
        name={name}
        label={label}
        className={classname}
        onChange={handleChange}
        helperText={helperText}
        value={value}
        placeholder={placeholder}
        type={
          type === "password" ? (showPassword ? "text" : "password") : "text"
        }
        InputProps={
          type === "password"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : null
        }
      />
    );
  }
);
export default FormInput;
