import React from "react";
import { BsFillHouseDoorFill, BsHouses } from "react-icons/bs";
import {
  MdChurch,
  MdApartment,
  MdOutlineChurch,
  MdOutlineWarehouse,
} from "react-icons/md";
import { FaSchool, FaWarehouse } from "react-icons/fa";
import HouseTypes from "../components/HouseTypes";
import { Box } from "@mui/material";
import { AiOutlineHome } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
const TypeofProperty = ({ handleHouseType, houseType, errors }) => {
  const typerent = [
    {
      name: "House",
      icon: <AiOutlineHome style={{ fontSize: "3em" }} />,
    },
    { name: "Church", icon: <MdOutlineChurch style={{ fontSize: "3em" }} /> },
    {
      name: "Appartment",
      icon: <BiBuildingHouse style={{ fontSize: "3em" }} />,
    },
    {
      name: "Warehouse",
      icon: <MdOutlineWarehouse style={{ fontSize: "3em" }} />,
    },
    {
      name: "Farmhouse",
      icon: <BsHouses style={{ fontSize: "3em" }} />,
    },
    {
      name: "Student Acc",
      icon: <FaSchool style={{ fontSize: "3em" }} />,
    },
  ];

  return (
    <div className="mt-3">
      <p className="text-black fs-3 fw-bold">
        Select the property for listing.
      </p>
      <Box
        sx={{
          maxWidth: { sm: "90%", md: "80%", lg: "40%" },
        }}
        className="row-cols-3 row container mt-2"
      >
        {typerent.map((item, index) => {
          return (
            <div className="pt-1 pb-1 ps-0" key={item.name}>
              <HouseTypes
                key={index}
                item={item}
                selected={houseType}
                handleHouseType={handleHouseType}
              />
            </div>
          );
        })}
      </Box>

      <small className={errors ? "form-text text-danger" : "d-none"}>
        {errors}
      </small>
    </div>
  );
};

export default TypeofProperty;
