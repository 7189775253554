import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout as DashboardLayout } from "../layouts/dashboard/layout";
import OrganizationReviewTable from "../sections/organization/organizationReviewTable";
import OrganizationTable from "../sections/organization/organizationTable";

const buttons = [
  <Button key="one">Added By User</Button>,
  <Button key="two">Added By Admin</Button>,
];

const AdminOrganizations = () => {
  const [activeButton, setActiveButton] = useState("all");
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState(<OrganizationTable />);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  Showing {activeButton === "all" ? "All" : "Review"}{" "} Organizations
                </span>
                <small>
                  {activeButton === "all"
                    ? "Showing all organizations, that are added by user and admin, and are reviewed"
                    : "Showing organizations that are not reviewed yet, review them and make them visible to users"}

                </small>
              </div>
              <div>
                <Button
                  sx={{ mr: 1 }}
                  variant={activeButton === "all" ? "contained" : "outlined"}
                  onClick={() => {
                    setActiveButton("all");
                    setActiveComponent(<OrganizationTable />);
                  }}
                >
                  All Organization
                </Button>
                <Badge badgeContent={4} color="error" sx={{ mr: 1 }}>
                  <Button
                    variant={
                      activeButton === "review" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setActiveButton("review");
                      setActiveComponent(<OrganizationReviewTable />);
                    }}
                  >
                    Review List
                  </Button>
                </Badge>

                <Button
                  variant="outlined"
                  onClick={() => navigate("/admin/organization/add")}
                >
                  ADD
                </Button>
              </div>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OutlinedInput
                sx={{}}
                size="small"
                defaultValue=""
                fullWidth
                placeholder="Search company"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(event) => {
                  if (event.target.value === "") {
                    setKeyword("");
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setKeyword(event.target.value);
                  }
                }}
              />
              <ButtonGroup color="primary" size="small" variant="outlined">
                {buttons}
              </ButtonGroup>
            </Stack>

            {activeButton === "all" ? (
              <OrganizationTable keyword={keyword} />
            ) : (
              <OrganizationReviewTable keyword={keyword} />
            )}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

AdminOrganizations.getLayout = (page) => (
  <DashboardLayout>{page}</DashboardLayout>
);

export default AdminOrganizations;
