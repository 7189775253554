import React from 'react';
import AllBlogs from "./components/AllBlogs";
import Skeleton from "react-loading-skeleton";
import blogcategories from '../../../../../utils/all_blog_category';
import { Chip, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

const DraftBlogTab = ({
    blogs,
    blog_loading
}) => {
    const [category, setCategory] = React.useState("");

    const handleCategorySelect = (item) => {
        setCategory(item);
    };

    const filteredBlogs = category
        ? blogs.filter(blog => blog.category === category && blog.status === 'draft')
        : blogs.filter(blog => blog.status === 'draft')
        

    return (
        <>
            <div className="d-flex gap-2 flex-wrap align-items-center mb-2">
                <Chip label="All Blogs" variant={!category ? "filled" : "outlined"} onClick={() => handleCategorySelect("")} />
                {blogcategories.slice(0, 8).map((item, index) => (
                    <Chip
                        label={item}
                        key={index}
                        variant={item !== category ? "outlined" : "filled"}
                        onClick={() => handleCategorySelect(item)}
                    />
                ))}
                {blogcategories.length > 8 && (
                    <div class="btn-group shadow-0 me-auto me-md-0">
                        <Chip
                            type="button"
                            label="View More"
                            icon={<ArrowDropDown />}
                            data-mdb-toggle="dropdown"
                            aria-expanded="false"
                        >
                        </Chip>
                        <ul class="dropdown-menu">
                            <Typography sx={{
                                overflowY: "scroll",
                                maxHeight: "200px",
                            }} >
                                {blogcategories.map((item, index) => {
                                    return (
                                        <li className="dropdown-item" key={index}>
                                            {item}
                                        </li>
                                    );
                                })}
                            </Typography>
                        </ul>

                    </div>
                )}
            </div>
            {blog_loading ? (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4   ">
                    {Array.from({ length: 8 }, (_, index) => (
                        <div key={index} className="col grid-box">
                            <div className="position-relative">
                                <Skeleton variant="rectangular" width="100%" height="200px" />
                                <div className="mt-3">
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="text" width="100%" />
                                    <Skeleton variant="text" width="60%" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <AllBlogs blogs={filteredBlogs} />
            )}
        </>
    )
}

export default DraftBlogTab