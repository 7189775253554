import React from "react";
import styled from "styled-components";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import location from "../../utils/all_city";
import { Box, List, ListItem } from "@mui/material";
import { useMemo, useRef, useEffect } from "react";
const Wrapper = styled.div`
  position: relative;
  width: 200px;
  &.no-cursor {
    cursor: not-allowed;
  }
  & > .select-list {
    position: absolute;
    top: 125%;
    left: 0;
    width: 100%;
    z-index: 2009;
    & > .select-list__item {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
      }
    }
  }

  & > input {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    padding: 8px;
    border: none;
    border-left: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    font-size: 16px;
  }
`;
const SelectSearch = React.memo(
  ({ province, handleChange, city, error, helpertext }) => {
    const options = {
      value: "Select City",
      open: false,
      search: "",
    };
    const [selected, setselect] = React.useState(options);
    const menuRef = useRef(null);

    useEffect(() => {
      // add event listener to document when component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // remove event listener when component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const toggleopen = (e) => {
      e.stopPropagation();
      if (province) {
        setselect({ ...selected, open: true });
      }
    };

    const handleClickOutside = (event) => {
      event.stopPropagation();

      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setselect({ ...selected, open: false });
      }
    };
    function handleSearchInputChange(event) {
      event.stopPropagation();

      const value = event.target.value;
      setselect({ ...selected, search: value });
    }

    const toggleclose = (e) => {
      e.stopPropagation();

      setselect({ ...selected, open: false });
    };

    const oncityselect = (e, item) => {
      handleChange(item);
      toggleclose(e);
    };
    const filteredOptions = useMemo(() => {
      return location.filter(
        (option) =>
          option.city.toLowerCase().includes(selected.search.toLowerCase()) &&
          option.province_name === province
      );
    }, [province, selected.search]);
    return (
      <div className="form-group w-100 mt-2">
        <label htmlFor="city" className="mb-1 text-back">
          City
        </label>
        <Wrapper
          className={
            province
              ? "select-container w-100"
              : "select-container w-100 no-cursor "
          }
          onClick={toggleopen}
        >
          <Box
            className={
              error
                ? "d-flex py-2 form-select justify-content-between align-items-center is-invalid"
                : province
                ? "d-flex py-2 form-select justify-content-between align-items-center "
                : "d-flex py-2 form-select justify-content-between align-items-center bg-hover"
            }
            sx={{
              outline: selected.open ? (error ? "" : "2px solid #799aed") : "",
              zIndex: 200,
            }}
          >
            <span className=" w-100">
              {city.length > 0 ? city : selected.value}
            </span>
            {/* <ArrowDropDownIcon /> */}
          </Box>
          <div
            className={
              selected.open
                ? "m-0 p-1  shadow shadow-lg select-list "
                : "d-none"
            }
            ref={menuRef}
          >
            <input
              type="text"
              placeholder="Search..."
              className="form-control my-1 py-2"
              onChange={handleSearchInputChange}
              onFocus={(e) => e.stopPropagation()}
              autoFocus={true}
            />
            <List
              className="m-0 p-0 select-list__item"
              sx={{
                maxHeight: "100px",
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {filteredOptions.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    onClick={(e) => {
                      const value = item.city;
                      oncityselect(e, value);
                    }}
                    role="button"
                    className="m-0 p-1"
                    sx={{
                      "&:hover": {
                        background: "#797979",
                        color: "white",
                      },
                    }}
                  >
                    {item.city}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Wrapper>
        <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
          {helpertext}
        </small>
      </div>
    );
  }
);

export default SelectSearch;
