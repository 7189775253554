import { createAsyncThunk } from "@reduxjs/toolkit";

import * as notification_api from "../../api/notification_api";

// get notification
export const getNotifications = createAsyncThunk(
  "notification/get_notification",
  async (data, { rejectWithValue }) => {
    try {
      const response = await notification_api.getNotifications();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);