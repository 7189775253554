import styled from "styled-components";

const Wrapper = styled.div`
  z-index: 20;

  & > .card {
    & > .circle-icon {
      & > .icon {
        font-size: 3.5rem;
        &.success {
          color: #87cc6d;
          width: 102px;
          height: 102px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2387CC6DFF' stroke-width='5' stroke-dasharray='11' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 100px;
          padding: 30px;
        }
        &.danger {
          width: 102px;
          height: 102px;
          color: red;

          padding: 30px;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23FF0F0FFF' stroke-width='8' stroke-dasharray='12' stroke-dashoffset='86' stroke-linecap='butt'/%3e%3c/svg%3e");
          border-radius: 100px;
        }
      }
    }

    & > p {
      color: #797979;
      font-size: 0.8rem;
    }
    & > .success-btn {
      background-color: #87cc6d;
      color: white;
      margin-inline: auto;
      height: 40px;
      width: 130px;
      white-space: nowrap;
      inset-inline: 0;
      bottom: -8%;
      letter-spacing: 3px;
      text-align: center;
      /* transform: translateY(50px); */
    }
  }
`;

export default Wrapper;
