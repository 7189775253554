import { BookmarkOutlined, LocationOnOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useDispatch, useSelector } from "react-redux";

const BookmarkFlatmateCard = ({ flatemate }) => {
  const dispatch = useDispatch();

  const [isTeamUp, setIsTeamUp] = useState(false);
  const { name, image, location, category } = flatemate;
  const [liked, setliked] = React.useState(false);
  const [type, setType] = React.useState("Flatemate");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    setBookmark({
      name: name,
      type: type,
      image: image,
      category: category,
      location: location,
    });
  }, [category, image, location, name, type]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm._id === flatemate?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, flatemate?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: flatemate?._id, data: bookmark }));
    }
  };

  return (
    <Box
      sx={{
        aspectRatio: { xs: "1/1.5", sm: "1/1.1" },
        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "1",
          },
        },
      }}
      className="card overflow-hidden position-relative d-flex custom-card flex-column justify-content-between"
    >
      <Link
        to={`/flatemate/${flatemate._id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>

      <Box
        sx={{
          width: "100%",
          height: "60%",
        }}
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${flatemate?.image}`}
          className="w-100 h-100 object-cover object-center"
          alt=""
        />
      </Box>
      <div class="px-1 px-lg-2 py-1 py-lg-2 ">
        <h6>4500/monthswedfr</h6>
        <small className="d-flex">
          <span className=" text-overflow-hidden cTitle text-black">
            {flatemate?.name}
          </span>
        </small>
        <h5 className="card-title text-black fw-bold">{flatemate?.name}</h5>
        <small className="mb-2 text-black mb-2 d-flex">
          <LocationOnOutlined fontSize="small" />{" "}
          <span className="text-overflow-hidden">{flatemate?.location}</span>
        </small>
        <div className="d-flex justify-content-between mt-3">
          {flatemate.teamUp ? (
            <button className="card-text btn btn-sm btn-outline-danger">
              team-up
            </button>
          ) : <button className="card-text btn btn-sm btn-outline-black">
            FlatMates
          </button>}
          <Box
            sx={{
              zIndex: 4,
            }}
            className="text-black"
          >

            {liked ? (
              <BookmarkBorderIcon role="button" onClick={togglelike} />
            ) : (
              <BookmarkOutlined role="button" onClick={togglelike} />
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default BookmarkFlatmateCard;
