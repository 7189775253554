import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store_redux/store";
// import './index.css'; // mdb local css
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
// eslint-disable-next-line
import * as mdb from "mdb-ui-kit"; // lib
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
root.render(
  <HelmetProvider>
    <GoogleOAuthProvider clientId="313450377856-j589t8gd0rj3jsq7fs79injs58cb1voo.apps.googleusercontent.com">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
