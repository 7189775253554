import React from "react";
import allcity from "../../utils/all_city";
const FormSelectCityB = ({ province, city, handleFilter }) => {
  const filteredcity = React.useMemo(() => {
    return allcity
      .filter((option) => option.province_name === province)
      .sort((a, b) => a.city.localeCompare(b.city));
  }, [province]);
  return (
    <div className="w-100">
      <label htmlFor="city">city</label>

      <select
        className={" w-100 rounded-0 py-2 rounded-2 form-select"}
        aria-label=".form-select-lg example"
        value={city}
        name="city"
        id="city"
        onChange={handleFilter}
        disabled={province.length > 1 ? false : true}
      >
        <option value="" className="w-100">
          Open To City
        </option>
        {filteredcity.map((city, index) => {
          return (
            <option value={city.city} key={index} className="w-100">
              {city.city}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default FormSelectCityB;