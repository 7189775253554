import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/userApi";

export const adminGetAllUserAction = createAsyncThunk(
  "admin/get_all_user",
  async ({ keyword = "", page = 0 }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllUser(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// register user by admin

export const adminRegisterUserAction = createAsyncThunk(
  "admin/register_user",
  async ({ data, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminRegisterUser(data);
      toast.success(response.data.message||"Register user successfully");
      navigate("/admin/users");

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// adminChangeUserStatus

export const adminChangeUserStatusAction = createAsyncThunk(
  "admin/change_user_status",
  async ({ data, id, toast, activeIndex }, { rejectWithValue }) => {
    console.log(activeIndex);

    try {
      const response = await admin_api.adminChangeUserStatus(data, id);
      toast.success(response.data.message);
     const  response2 = { data: response.data, activeIndex: activeIndex }
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// adminEditUser

export const adminEditUserAction = createAsyncThunk(
  "admin/edit_user",
  async ({ data, id, toast ,activeindex}, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditUser(data, id);
      toast.success(response.data.message);
      return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

