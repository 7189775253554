import { Box, Input, Typography } from "@mui/material";
import React from "react";
import { ImCross } from "react-icons/im";
import DurationSelect from "../addrental/components/DurationSelect";
import AvailableDate from "../addrental/components/AvailableDate";
import rentalOffers from "../../utils/rentaloffers";
import CancelIcon from "@mui/icons-material/Cancel";
import Modal from "@mui/material/Modal";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { RentalEditImage, OfferCard, ModalOfferCard } from "./components/index";
import { AddLocation } from "../../components/Index";
// GoLocation
import { GoLocation } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { editRentalByUser } from "../../context/actions/authAction";
import { toast } from "react-toastify";
import { mduration } from "../../utils/rentSettings";
import { clearError } from "../../context/reducers/authSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "50%", md: "60%", sm: "90%", xm: "95%" },
  bgcolor: "background.paper",

  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  minHeight: "50vh",
};
const RentalEdit = ({ data, closeModal, activeindex, id }) => {
  const dispatch = useDispatch();

  // get the error from user_reducer using useSelector
  const { error, editRentalLoading } = useSelector((state) => ({
    error: state.user_reducer.error,
  }));

  // toast error
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError())
  }, [error]);



  

  
  const values = {
    title: data?.title,
    description: data?.description,
    availableDate: data?.availableDate?.split("T")[0],
    offers: data?.offers,
    price: data?.price,
    bedroom: data?.bedroom,
    kitchen: data?.kitchen,
    bathroom: data?.bathroom,
    location: data?.location,
  };

  const evalues = {
    title: "",
    description: "",
    minimumStay: "",
    availableDate: "",
    price: "",
    bedroom: "",
    kitchen: "",
    bathroom: "",
    images: "",
  };

  const [value, setValue] = React.useState(values);

  const [minimumStay, setminimumStay] = React.useState(data?.minimumStay);
  const [newoffers, setNewOffers] = React.useState([]);

  const [errors, setErrors] = React.useState(evalues);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // on location set
  const handleLocation = (location) => {
    setValue((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        place_name: location.place_name,
      },
    }));
  };

  // all the uploaded images are in images state
  const [images, setImages] = React.useState({
    files: [],
    pImages: data?.images,
    nImages: data?.images,
  });
  // handle image upload and remove images
  const handleimages = React.useCallback(
    ({ files, pImages }) => {
      setImages({ ...images, files: files, pImages: pImages });
    },
    [images]
  );

  // handle image upload and remove images
  const removeImages = React.useCallback(
    ({ files, pImages, nImages }) => {
      setImages({
        ...images,
        // send to api
        files: files,
        pImages: pImages,
        // send to api
        nImages: nImages,
      });
    },
    [images]
  );

  const newFeature = React.useMemo(() => {
    const list = rentalOffers.filter(
      (item) => !value.offers.includes(item.name)
    );
    return list;
  }, [value.offers]);

  const handleChange = (name, value) => {
    switch (name) {
      case "price":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setValue((prev) => ({ ...prev, price: value }));
        }
        break;

      case "addprice":
        if (value === "") {
          setValue((prev) => ({ ...prev, price: 1 }));
        }

        if (value <= 100000000) {
          const addedprice = value + 1;
          setValue((prev) => ({ ...prev, price: addedprice }));
        }
        break;

      case "minusprice":
        if (value === "") {
          setValue((prev) => ({ ...prev, price: 0 }));
        }
        if (value > 0) {
          const minusprice = value - 1;
          setValue((prev) => ({ ...prev, price: minusprice }));
        }
        break;

      case "addbedroom":
        if (value <= 5) {
          const addedbedroom = value + 1;

          setValue((prev) => ({ ...prev, bedroom: addedbedroom }));
        }
        break;

      case "minusbedroom":
        if (value === 0) {
        } else {
          const minusbedroom = value - 1;
          setValue((prev) => ({ ...prev, bedroom: minusbedroom }));
        }
        break;

      case "addkitchen":
        if (value <= 5) {
          const addedkitchen = value + 1;
          setValue((prev) => ({ ...prev, kitchen: addedkitchen }));
        }
        break;

      case "minuskitchen":
        if (value === 0) {
        } else {
          const minuskitchen = value - 1;
          setValue((prev) => ({ ...prev, kitchen: minuskitchen }));
        }
        break;

      case "addbathroom":
        if (value <= 5) {
          const addedbathroom = value + 1;
          setValue((prev) => ({ ...prev, bathroom: addedbathroom }));
        }
        break;

      case "minusbathroom":
        if (value === 0) {
        } else {
          const minusbathroom = value - 1;
          setValue((prev) => ({ ...prev, bathroom: minusbathroom }));
        }
        break;

      default:
        setValue((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const handleOffers = (value) => {
    setNewOffers(value);
  };

  const onCancel = () => {
    setValue(values);
    setNewOffers(newoffers);
    setImages({
      files: [],
      pImages: data.images,
      nImages: data.images,
    });
    setErrors(evalues);
  };

  const handleMChange = (name, value) => {
    switch (name) {
      default:
        setminimumStay(value);
    }
  };
  const clearModal = () => {
    setNewOffers([]);
    handleClose();
  };

  const saveOffers = () => {
    const newlist = [...newoffers];
    const oldlst = [...value.offers, ...newlist];
    setValue({ ...value, offers: oldlst });
    clearModal();
  };

  const formvalidation = () => {
    let isValid = true;
    const newerror = { ...evalues };
    const {
      title,
      description,
      availableDate,
      offers,
      price,
      bedroom,
      kitchen,
      bathroom,
    } = value;

    const bedroomvalid = bedroom === 0 || bedroom === "";
    const kitchenvalid = kitchen === 0 || kitchen === "";
    const bathroomvalid = bathroom === 0 || bathroom === "";
    const imagesvalid = images.pImages.length < 5;
    const offersvalid = offers.length === 0;

    if (
      !title ||
      !description ||
      !availableDate ||
      !price ||
      imagesvalid ||
      images.pImages.length > 10 ||
      offers.length === 0
    ) {
      if (offers.length === 0) {
        newerror.offers = "Please provide offers *";
      }
      if (!title) {
        newerror.title = "Please provide title *";
      }

      if (!description) {
        newerror.description = "Please provide description *";
      }
      if (!availableDate) {
        newerror.availableDate = "Please provide availableDate *";
      }
      if (!offers) {
        newerror.offers = "Please provide at least one offers *";
      }
      if (!price) {
        newerror.price = "Please provide  price *";
      }
      if (imagesvalid) {
        newerror.images = "Please provide at leaset 5 images *";
      }

      if (images.pImages.length > 10) {
        newerror.images = "Max 10 images allowed *";
      }
      newerror.message = "Invalid credintial  *";

      setErrors(newerror);
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = () => {
    const isValid = formvalidation();
    if (isValid) {
      const formData = new FormData();

      for (let i = 0; i < images.files.length; i++) {
        formData.append("images", images.files[i]);
      }
      for (let i = 0; i < images.nImages.length; i++) {
        formData.append("nImages", images.nImages[i]);
      }
      for (let i = 0; i < value.offers.length; i++) {
        formData.append("offers", value.offers[i]);
      }
      formData.append("title", value.title);
      formData.append("description", value.description);
      formData.append("availableDate", value.availableDate);
      formData.append("price", value.price);
      formData.append("bedroom", value.bedroom);
      formData.append("kitchen", value.kitchen);
      formData.append("bathroom", value.bathroom);
      formData.append("minimumStay", minimumStay);
      formData.append("lat", value.location.lat);
      formData.append("lng", value.location.lng);
      formData.append("place_name", value.location.place_name);
      dispatch(
        editRentalByUser({
          id: id,
          formData: formData,
          toast: toast,
          activeindex: activeindex,
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: { lg: "70vw", xs: "100dvw", md: "80vw", sm: "90vw" },
      }}
      className="d-flex flex-column gap-2 p-3 position-relative"
    >
      <section className="w-100">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Editing property
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
              role="button"
              onClick={closeModal}
            />
          </Box>
        </div>
      </section>

      <section className="d-flex flex-column gap-2">
        <div className="form-group mt-2 w-100">
          <label className="mb-1 fw-bold fs-5" htmlFor={"title"}>
            Property title
          </label>
          <input
            type={"text"}
            className={
              errors.title
                ? "form-control is-invalid py-2 m-0"
                : "form-control py-2"
            }
            id="title"
            name="title"
            aria-describedby="emailHelp"
            placeholder="Enter title"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            value={value.title}
          />
          <small
            id="emailHelp"
            className={errors.title ? "form-text text-danger" : ""}
          >
            {errors.title}
          </small>
        </div>

        <div className="form-group">
          <label className="mb-1 fw-bold fs-5" htmlFor="textAreaExample">
            About Your Place
          </label>
          <textarea
            className={
              errors.description ? "form-control is-invalid" : "form-control"
            }
            id="textAreaExample"
            rows="4"
            maxLength={400}
            data-mdb-showcounter="true"
            placeholder={"Enter description"}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            name="description"
            type="text"
            value={value.description}
          ></textarea>
          <div className="d-flex justify-content-between align-content-center">
            <small
              id="emailHelp"
              className={errors.description ? "form-text text-danger" : ""}
            >
              {errors.description}
            </small>
            <span
              className={
                value?.description?.length === 400
                  ? "form-helper text-end text-danger ms-auto "
                  : "form-helper text-end ms-auto"
              }
            >
              {value?.description?.length} / {400}
            </span>
          </div>
        </div>

        <div className="row row-cols-lg-2 row-cols-1 gx-2">
          <div className="d-flex flex-column gap-2">
            <p className="mb-1 fw-bold fs-5">Minimum Stay Duration</p>
            <DurationSelect
              minimumStay={minimumStay}
              handleFormValue={handleMChange}
              durations={mduration}
              name={"minimumStay"}
              height={"50px"}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <p className="mb-1 fw-bold fs-5">Property available from</p>
            <AvailableDate
              availabeDate={value.availableDate}
              handleFormValue={handleChange}
              errors={errors.availableDate}
              name={"availableDate"}
            />
          </div>
        </div>
      </section>

      <section>
        <RentalEditImage
          handleimages={handleimages}
          errors={errors.images}
          pImages={images.pImages}
          imageFile={images.files}
          nImages={images.nImages}
          removeImages={removeImages}
        />
      </section>

      <section className="mt-2">
        <p className="fw-bold fs-5">About Property</p>
        <Box
          className=" d-flex align-items-center  justify-content-between "
          sx={{
            width: { sm: "90%", lg: "50%" },
          }}
        >
          <p className="fs-bold">Bedrooms</p>
          <div className="d-flex gap-4 align-items-center">
            <RemoveIcon
              className="bg-white text-black rounded border border-3 rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              role="button"
              onClick={() => handleChange("minusbedroom", value.bedroom)}
            />

            <Typography
              variant="p"
              className="fw-bold text-center"
              sx={{
                minWidth: "40px",
              }}
            >
              {value.bedroom}
            </Typography>

            <AddIcon
              role="button"
              className="bg-white text-black border border-3 rounded rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              onClick={() => handleChange("addbedroom", value.bedroom)}
            />
          </div>
        </Box>

        <Box
          className=" d-flex align-items-center  justify-content-between mt-3"
          sx={{
            width: { sm: "90%", lg: "50%" },
          }}
        >
          <p className="fs-bold">Kitchen</p>
          <div className="d-flex gap-4 align-items-center">
            <RemoveIcon
              className="bg-white text-black rounded border border-3 rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              role="button"
              onClick={() => handleChange("minuskitchen", value.kitchen)}
            />

            <Typography
              variant="p"
              className="fw-bold text-center"
              sx={{
                minWidth: "40px",
              }}
            >
              {value.kitchen}
            </Typography>

            <AddIcon
              role="button"
              className="bg-white text-black border border-3 rounded rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              onClick={() => handleChange("addkitchen", value.kitchen)}
            />
          </div>
        </Box>

        <Box
          className=" d-flex align-items-center  justify-content-between mt-3"
          sx={{
            width: { sm: "90%", lg: "50%" },
          }}
        >
          <p className="fs-bold">Bathroom</p>
          <div className="d-flex gap-4 align-items-center">
            <RemoveIcon
              className="bg-white text-black rounded border border-3 rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              role="button"
              onClick={() => handleChange("minusbathroom", value.bathroom)}
            />

            <Typography
              variant="p"
              className="fw-bold text-center"
              sx={{
                minWidth: "40px",
              }}
            >
              {value.bathroom}
            </Typography>

            <AddIcon
              role="button"
              className="bg-white text-black border border-3 rounded rounded-circle p-2"
              sx={{ width: "40px", height: "40px" }}
              onClick={() => handleChange("addbathroom", value.bathroom)}
            />
          </div>
        </Box>
      </section>

      <section className="offers">
        <div className="d-flex align-items-center justify-content-between">
          <p className="fw-bold fs-5">Offerings</p>
          <button className="btn btn-sm btn-outline-black" onClick={handleOpen}>
            add
          </button>
        </div>
        {errors.offers && (
          <small className="text-danger">{errors.offers}</small>
        )}

        <div className="row row-cols-1 row-cols-lg-5 row-cols-md-2 ">
          {value.offers.map((item) => {
            return (
              <div key={item}>
                <OfferCard
                  name={item}
                  key={item}
                  handleFormValue={handleChange}
                  offers={value.offers}
                />
              </div>
            );
          })}
        </div>
      </section>

      <section>
        <span className="fs-5 fw-bold">Adust your Pricing</span>
        <div className="bg-hover rounded rounded-3">
          <Box
            className=" d-flex align-items-center flex-column justify-content-center mx-auto"
            sx={{
              width: { sm: "90%", lg: "70%", xs: "97%" },
              height: "30vh",
              paddingInline: { lg: "8%", sm: "4%", xs: "2%" },
            }}
          >
            <div className="d-flex gap-4 align-items-center">
              <RemoveIcon
                className="bg-white text-black rounded rounded-circle p-3"
                sx={{
                  width: { lg: "100px", xs: "50px" },
                  height: { lg: "100px", xs: "50px" },
                }}
                role="button"
                onClick={(e) => handleChange("minusprice", value.price)}
              />
              <div className="d-flex align-items-center  bg-white p-2">
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "3em",
                  }}
                >
                  $
                </Typography>
                <Input
                  type="text"
                  className="w-100 h-100 bg-white border-0 rounded rounded-3 fw-bold"
                  disableUnderline
                  onChange={(e) => handleChange("price", e.target.value)}
                  value={value.price}
                  sx={{
                    fontSize: "3em",
                    "&:focus": {
                      border: "none",
                      outline: "none",
                    },
                  }}
                />
              </div>
              <AddIcon
                role="button"
                className="bg-white text-black rounded rounded-circle p-3"
                sx={{
                  width: { lg: "100px", xs: "50px" },
                  height: { lg: "100px", xs: "50px" },
                }}
                onClick={(e) => handleChange("addprice", value.price)}
              />
            </div>
            <h5 className="mt-3">per month</h5>
          </Box>
        </div>

        {errors.price ? (
          <small className="text-danger"> {errors.price}</small>
        ) : null}
      </section>

      <section className="">
        <span className="fs-5 fw-bold">Location on Map</span>
        <p className="badge badge-light">
          <GoLocation /> {value?.location?.place_name}
        </p>
        <AddLocation handleLocation={handleLocation} />
      </section>

      <Box
        sx={{
          zIndex: "5",
        }}
        className="d-flex gap-2 w-100 justify-content-end position-sticky bg-white bottom-0 right-0 align-items-center flex-column flex-md-row "
      >
        {errors.message && (
          <div className="bg-danger  w-75 h-maxcontent text-center text-white fw-bold">
            {errors.message}
          </div>
        )}
        <div className="ms-auto d-flex gap-2">
          <button
            className="btn bg-danger text-white fw-bold"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="btn bg-black text-white fw-bold text-nowrap"
            onClick={onSubmit}
          >
            Save Changes
          </button>
        </div>
      </Box>

      <Modal
        open={open}
        onClose={clearModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center w-100 justify-content-between">
            <span className="fs-3 fw-bold">Add Features</span>
            <CancelIcon
              sx={{
                fontSize: "2.5em",
              }}
              onClick={clearModal}
            />
          </div>
          <div className="d-flex flex-wrap gap-1 align-items-center">
            {newFeature?.map((item) => {
              return (
                <div className="p-1" key={item.name}>
                  <ModalOfferCard
                    name={item.name}
                    key={item.name}
                    handleFormValue={handleOffers}
                    offers={newoffers}
                  />
                </div>
              );
            })}

            {newFeature.length === 0 ? (
              <span className="fw-bold fs-4">No Features to Add</span>
            ) : null}
          </div>

          <button
            className="btn-dark btn ms-auto justify-self-end mt-auto"
            onClick={saveOffers}
          >
            Save
          </button>
        </Box>
      </Modal>
    </Box>
  );
};

export default RentalEdit;
