import { Box, Typography } from "@mui/material";
import React from "react";
import CheckRequirementCard from "../components/CheckRequirementCard";

const BeforeCountry = ({ country, list }) => {
  const [ispercentage, setPercentage] = React.useState({
    percentage: 0,
    completed: 0,
  });
  const onCompleted = (success) => {
    if (success && ispercentage.completed >= 0) {
      const completed = ispercentage.completed + 1;
      const newper = (completed / list.length) * 100;

      setPercentage({ completed: completed, percentage: newper.toFixed(3) });
    } else {
      const completed = ispercentage.completed - 1;
      const newper = (completed / list.length) * 100;
      setPercentage({ completed: completed, percentage: newper.toFixed(3) });
    }
  };

  const commonList = React.useMemo(() => {
    return list?.filter((item) => item.name === "Common");
  }, [list]);
  const financialList = React.useMemo(() => {
    return list?.filter((item) => item.name === "Financial");
  }, [list]);
  const governmentList = React.useMemo(() => {
    return list?.filter((item) => item.name === "Government");
  }, [list]);
  const otherDocumentsList = React.useMemo(() => {
    return list?.filter((item) => item.name === "Other Document");
  }, [list]);

  return (
    <Box
      sx={
        {
          // overflow: "hidden",
          // overflowX: "scroll",
        }
      }
      className="p-0 m-0"
    >
      <Box
        sx={{
          border: "1px solid #4D4B4B",
          // height: "77vh",
        }}
        className="rounded rounded-3 d-block d-flex flex-column m-0 p-0 w-100 "
      >
        <Typography
          variant="h6"
          className="text-center fw-bold text-black p-2 w-100  "
          sx={{
            height: "7vh",
          }}
        >
          Documents for {country}
        </Typography>
        <Box
          className=" p-0 m-0 "
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4,minmax(280px,1fr))",
            height: "62dvh",
            overflowX: "auto",
          }}
        >
          <Box
            sx={{
              borderRight: "1px solid #4D4B4B",
              borderTop: "1px solid #4D4B4B",
              height: "62vh",
              overflowY: "auto",
            }}
            className="general position-relative p-0 h-100  m-0"
          >
            <Typography
              variant="h6"
              className="w-100 text-center py-2 position-sticky top-0 start-0 bg-white"
              sx={{
                borderBottom: "1px solid #4D4B4B",
                zIndex: 1,
                
              }}
            >
              Common
            </Typography>
            {commonList.map((item, index) => {
              return (
                <CheckRequirementCard
                  onCompleted={onCompleted}
                  item={item}
                  key={index}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              borderRight: "1px solid #4D4B4B",
              borderTop: "1px solid #4D4B4B",
              height: "62vh",
              overflowY: "auto",
            }}
            className="financial p-0 m-0 position-relative"
          >
            <Typography
              variant="h6"
              className="w-100 text-center py-2  position-sticky top-0 start-0 bg-white"
              sx={{
                borderBottom: "1px solid #4D4B4B",
                zIndex: 1,
              }}
            >
              Financial
            </Typography>
            {financialList.map((item, index) => {
              return (
                <CheckRequirementCard
                  onCompleted={onCompleted}
                  item={item}
                  key={index}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              borderRight: "1px solid #4D4B4B",
              borderTop: "1px solid #4D4B4B",
              height: "62vh",
              overflowY: "auto",
            }}
            className=" p-0 m-0 position-relative"
          >
            <Typography
              variant="h6"
              className="w-100 text-center py-2 position-sticky top-0 start-0 bg-white"
              sx={{
                borderBottom: "1px solid #4D4B4B",
                zIndex: 1,
              }}
            >
              Government
            </Typography>
            {governmentList.map((item, index) => {
              return (
                <CheckRequirementCard
                  onCompleted={onCompleted}
                  item={item}
                  key={index}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              borderTop: "1px solid #4D4B4B",
              height: "62vh",
              overflowY: "auto",
            }}
            className="otherDocument p-0 m-0 position-relative "
          >
            <Typography
              variant="h6"
              className="w-100 text-center py-2 position-sticky top-0 start-0 bg-white"
              sx={{
                borderBottom: "1px solid #4D4B4B",
                zIndex: 1,
              }}
            >
              Other Documents
            </Typography>
            {otherDocumentsList.map((item, index) => {
              return (
                <CheckRequirementCard
                  onCompleted={onCompleted}
                  item={item}
                  key={index}
                />
              );
            })}
          </Box>
        </Box>

        <Box
          sx={{
            borderTop: "1px solid #4D4B4B",
          }}
          className=" p-3  w-100 d-flex justify-content-end m-0"
        >
          <h5>{Math.floor(ispercentage.percentage)} %</h5>
        </Box>
      </Box>
    </Box>
  );
};

export default BeforeCountry;
