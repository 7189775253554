import React from "react";
import { AddLocation } from "../../../components/Index";
import { Chip } from "@mui/material";
import { Box } from "@mui/material";
import { ImCross } from "react-icons/im";
import { editFlatemateByUser } from "../../../context/actions/authAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const PreferedLocations = ({ onClose, addLocation, preferedLocations, id }) => {
  const [pLocation, setPlocation] = React.useState(preferedLocations);
  const [errors, setErrors] = React.useState({ pLocation: "", message: "" });
  const dispatch = useDispatch();
  const onSubmit = () => {
    if (pLocation.length > 0) {
      const formData = new FormData();
      for (let i = 0; i < pLocation.length; i++) {
        formData.append("preferedLocations", pLocation[i]);
      }
      dispatch(
        editFlatemateByUser({ id: id, formData: formData, toast: toast })
      );
      onClose();
    } else {
      setErrors({ ...errors, message: "Please add at least one location *" });
    }
  };
  const addnewLocation = (location) => {
    setPlocation((prev) => {
      const lst = [...prev, location.place_name];
      if (lst.length <= 5) {
        setErrors((m) => {
          if (m.message) {
            const newlst = { ...m, message: "" };
            return newlst;
          } else {
            return m;
          }
        });

        return lst;
      } else {
        setErrors({ ...errors, pLocation: "Max allowed 5 location *" });
        return prev;
      }
    });
  };

  const removelocation = (index) => {
    if (errors.pLocation) {
      setErrors({ ...errors, pLocation: "" });
    }

    setPlocation((prevFormValues) =>
      prevFormValues.filter((_, i) => i !== index)
    );
  };
  return (
    <div className="d-flex flex-column gap-2">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Preffered location for stay
        </span>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>
      <div className="row row-cols-1 row-cols-lg-2">
        <div className="location-info d-flex flex-column gap-1">
          <small>Search the destination in map</small>
          {errors.pLocation && (
            <small className="text-danger">{errors.pLocation}</small>
          )}
          <div className="d-flex gap-1 flex-wrap">
            {pLocation?.map((item, index) => {
              return (
                <Chip
                  label={item}
                  key={index}
                  variant="outlined"
                  onDelete={() => removelocation(index)}
                />
              );
            })}
          </div>
        </div>
        <div className="location-input">
          <AddLocation handleLocation={addnewLocation} />
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center gap-1">
        {errors.message && (
          <div className="bg-danger text-center w-75 mx-auto h-maxcontent rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <button
          className="btn-dark btn ms-auto justify-self-end mt-3"
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PreferedLocations;
