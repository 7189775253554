import React from "react";
import { Box } from "@mui/material";
import DurationSelect from "../components/DurationSelect";
import AvailableDate from "../components/AvailableDate";
import { mduration } from "../../../utils/rentSettings";
const AboutProperty = ({ errors, values, handleFormValue }) => {
 
  return (
    <Box
      className="mt-2"
      sx={{
        width: { sm: "90%", lg: "60%" },
      }}
    >
      <p className="text-black fs-3 fw-bold my-0">Create a short title</p>

      <div className="form-group">
        <textarea
          className={errors.title ? "form-control is-invalid" : "form-control"}
          id="textAreaExample"
          rows="2"
          maxLength={30}
          data-mdb-showcounter="true"
          placeholder={"Enter title"}
          name={"title"}
          required={"true"}
          type="text"
          onChange={(e) => handleFormValue("title", e.target.value)}
          value={values.title}
          style={{ resize: "none" }}
        ></textarea>
        <div className="d-flex justify-content-between align-content-center">
          <small
            id="emailHelp"
            className={errors.title ? "form-text text-danger" : ""}
          >
            {errors.title}
          </small>
          <span
            className={
              values.title.length === 30
                ? "form-helper text-end text-danger ms-auto "
                : "form-helper text-end ms-auto"
            }
          >
            {values.title.length} / {30}
          </span>
        </div>
      </div>

      <p className="text-black fs-3 fw-bold my-0">Describe your property</p>


      <div className="form-group">
        <textarea
          className={errors.title ? "form-control is-invalid" : "form-control"}
          id="textAreaExample"
          rows="7"
          maxLength={1000}
          data-mdb-showcounter="true"
          placeholder={"Enter description"}
          name={"description"}
          required={"true"}
          type="text"
          onChange={(e) => handleFormValue("description", e.target.value)}
          value={values.description}
        ></textarea>
        <div className="d-flex justify-content-between align-content-center">
          <small
            id="emailHelp"
            className={errors.description ? "form-text text-danger" : ""}
          >
            {errors.description}
          </small>
          <span
            className={
              values.description.length === 1000
                ? "form-helper text-end text-danger ms-auto "
                : "form-helper text-end ms-auto"
            }
          >
            {values.description.length} / {1000}
          </span>
        </div>
      </div>

      <p className="text-black fs-3 fw-bold my-0">Minimum length of stay</p>

      <DurationSelect
        minimumStay={values.minimumStay}
        handleFormValue={handleFormValue}
        name={"minimumStay"}
        durations={mduration}
      />

<p className="text-black fs-3 fw-bold my-0 mt-3">When will Property be available</p>

      <div className="">
        <AvailableDate
          availabeDate={values.availableDate}
          handleFormValue={handleFormValue}
          name={"availableDate"}
          errors={errors.availableDate}
        />
      </div>
    </Box>
  );
};

export default AboutProperty;
