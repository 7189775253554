import customInstance from "./manage_access_route";
import API from "./common_api";


// get_all_country
export const get_all_country = () => API.get("/api/abroadstudy/get_all_country");
// get_abroad_study details
export const getAbroadStudyDetails = (id) =>
  API.get(`/api/abroadstudy/abroad_study/${id}`);

// get_abroad_study_requirements
export const getAbroadStudyRequirements = (id) => API.get(`/api/abroadstudy/abroad_study_table/${id}`);
