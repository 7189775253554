import React from "react";

const FormSelectB = React.memo(({
  value,
  name,
  handleChange,
  list,
  disabled,
  placeholder,
  error,
  helpertext,
  label
}) => {
  return (
    <div className="w-100">
      <small className="text-capitalize text-back">{label}</small>
      <select
        className={
          error
            ? "form-select rounded-0 form-select-lg  py-2 rounded-2 is-invalid"
            : "form-select rounded-0 form-select-lg  py-2 rounded-2"
        }
        aria-label=".form-select-lg example"
        value={value}
        name={name}
        onChange={handleChange}
        disabled={disabled}
      >
        <option value="">{placeholder}</option>
        {list.map((province) => {
          return (
            <option value={province} key={province}>
              {province}
            </option>
          );
        })}
      </select>
      <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
        {helpertext}
      </small>
    </div>
  );
});

export default FormSelectB;
