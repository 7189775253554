import React from "react";
import { Box, Chip, Rating, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const ProfessionalCard = React.memo(({ professional }) => {
  const offers = ["Free CheckUp", "Free Trail", "20% discount", "offers"];
  return (
    <Box
      sx={{
        width: "100%",
        aspectRatio: { xs: "1/1.4", md: "1/1.5", lg: "1/1.3" },
      }}
      className="text-center position-relative d-flex flex-column card col  pt-4 px-2  mt-3 position-relative custom-card"
    >
      <Link
        to={`/professionals/${professional._id}`}
        className=" position-absolute top-0 start-0  hover-link"
        style={{
          display: "block",
          height: "100%",
          width: "100%",
          zIndex: "1",
        }}
      ></Link>

      <Box
        sx={{
          width: "100%",
          height: "30%",
          backgroundColor: "#414141",
          position: "absolute",
          top: "0",
          left: "0",
          borderRadius: "10px 10px 0 0 ",
        }}
      ></Box>
      <Box
        sx={{
          cursor: "pointer",
          borderRadius: "50%",
          zIndex: "1",
          border: { xs: "5px solid #F1F1F1", sm: "10px solid #F1F1F1" },
          overflow: "hidden",
        }}
        className="mt-3 mx-auto logo"
      >
        <img
          alt="Org Logo"
          src={`${process.env.REACT_APP_IMAGE_PATH}${professional?.userPic}`}
          className="object-cover object-center w-100 h-100 "
        />
      </Box>
      <Typography
        variant="p"
        sx={{
          fontSize: { xs: "1em", lg: "1.5em" },
        }}
        className="text-black mt-2 fw-bold"
      >
        {professional.firstName} {professional.lastName}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "0.9em", lg: "1.4em" },
        }}
        variant="small"
        className="text-black-50 fw-bold"
      >
        {professional.profession}
      </Typography>

      <div className="d-flex w-100 justify-content-center gap-1 py-2">
        <Rating
          size="small"
          name="half-rating-read"
          value={professional.averageRating}
          precision={0.5}
          readOnly
        />
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontWeight: "bold",
            marginLeft: "0.2rem",
          }}
        >
          {professional.averageRating} ({professional.totalReview || 0})
        </Typography>
      </div>

      <Box
        sx={{
          overflow: "hidden",
          height: "50px",
        }}
        className="d-flex gap-1  flex-wrap w-100 bg justify-content-center"
      >
        {professional?.offers.slice(0, 4).map((item, index) => {
          return (
            <Chip
              key={index}
              sx={{
                backgroundColor: "#F2F7F2",
                color: "#13544E",
              }}
              label={item}
              size="small"
            />
          );
        })}
      </Box>
      <hr className="mt-auto mx-0 mb-0" />
      <Typography
        variant="p"
        sx={{
          zIndex: "2",
          fontSize: { xs: "0.7em", lg: "1.2em" },
        }}
      >
        view more
      </Typography>
    </Box>
  );
});

export default ProfessionalCard;
