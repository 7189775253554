import ArrowPathIcon from "@heroicons/react/24/solid/ArrowPathIcon";
import { Button, Card, CardContent, CardHeader, SvgIcon } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import ReactApexChart from "react-apexcharts";
import formatViewCount from "../../../../utils/formatViewCount";

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: [
      theme.palette.primary.main,
      alpha(theme.palette.primary.main, 0.25),
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: "solid",
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "40px",
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        "Organizations",
        "Businesses",
        "Rentals",
        "Professionals",
        "Blog Posts",
        "Flatmates",
        "Events",
      ],
      labels: {
        offsetY: 5,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (value) =>
          value > 0 ? `${formatViewCount(value)}` : `${value}`,
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const OverviewViewsCount = (props) => {
  const { chartSeries, sx } = props;
  const chartOptions = useChartOptions();
  console.log(chartSeries);

  return (
    <Card sx={sx}>
      <CardHeader
        action={
          <Button
            color="inherit"
            size="small"
            startIcon={
              <SvgIcon fontSize="small">
                <ArrowPathIcon />
              </SvgIcon>
            }
          >
            Sync
          </Button>
        }
        title="Views per section"
      />
      <CardContent>
      <div id="chart">
        <ReactApexChart options={chartOptions} series={chartSeries} type="bar" height={350} />
      </div>

      </CardContent>
    </Card>
  );
};
