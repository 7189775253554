import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Box } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";

const BookmarkBlogCard = ({ item }) => {
  const { firstname } = useSelector((state) => state.auth_slice.user_data);
  const [liked, setliked] = React.useState(false);
  const dispatch = useDispatch();
  const { name, image, location, category } = item;
  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: item?._id, data: bookmark }));
    }
  };

  const [type, setType] = React.useState("Item");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    setBookmark({
      name: name,
      type: type,
      image: image,
      category: category,
      location: location,
    });
  }, [category, image, location, name, type]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm._id === item?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, item?._id]);

  return (
    <Box
      sx={{
        aspectRatio: { xs: "1/1.5", sm: "1/1.1" },

        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "1",
          },
        },
      }}
      className="rounded overflow-hidden position-relative d-flex custom-card flex-column justify-content-between"
    >
      <Link
        to={`/item/${item._id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>
      <Box
        className=""
        sx={{
          height: "50%",
        }}
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${item.image}`}
          alt=""
          srcSet=""
          className="object-cover w-100 h-100"
        />
      </Box>
      <div className="px-1 px-lg-2 py-1 py-lg-2">
        <small className="d-flex">
          <span className="description cTitle ">{item?.name}</span>
        </small>
        <p className="text-black mb-1"> 28 July 2023</p>

        <div className="d-flex mt-auto justify-content-between align-items-center">
          <Box
            className=" text-white bcagegory btn text-start text-nowrap rounded "
            sx={{
              backgroundColor: "#494949",
            }}
          >
            Educational
          </Box>
          <Box className="text-black">
            {liked ? (
              <BookmarkBorderIcon onClick={togglelike} />
            ) : (
              <BookmarkIcon onClick={togglelike} />
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default BookmarkBlogCard;
