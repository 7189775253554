import React, {useEffect, useRef} from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {Box, List, ListItem,} from "@mui/material";
import Tab from "@mui/material/Tab";

import TabPanel from "@mui/lab/TabPanel";
import {useSelector} from "react-redux";

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const EditFeatured = () => {

  const modalContentRef = useRef();

  const tabs = ["Active", "Inactive"];
  const [value, setValue] = React.useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [items, setItems] = React.useState([
  ]);

  // dispach her the features blogs

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = Array.from(items);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItems(newItems);
  };

  const {
    blogs,
    resultperpage,
    filterblogcount,
    totalBlogCount,
    blog_loading,
  } = useSelector((state) => ({
    ...state.admin_reducer,
  }));

  useEffect(() => {
    setItems(blogs);
  }, [blogs]);


  return (
    <div ref={modalContentRef} className="">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value.toString()}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {tabs.map((item, index) => {
                return <Tab label={item} value={index.toString()} key={item} />;
              })}
            </TabList>
          </Box>
          <TabPanel value="0">
            <section className="active-blog">
              
            </section>
          </TabPanel>
          <TabPanel value="1">
            <section className="pastblog"></section>
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default EditFeatured;
