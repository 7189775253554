import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Unstable_Grid2 as Grid,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { BiBuildingHouse } from "react-icons/bi";
import { BsBuildings } from "react-icons/bs";
import { OverviewActivityLog } from "../overview/overview-activity-log";
import { OverviewHelp } from "../overview/overview-help-section";
import { OverviewViewsCount } from "../overview/overview-views";
// FiUsers
import { FiUsers } from "react-icons/fi";
// RiGroupLine
import { ImProfile } from "react-icons/im";
import { MdArticle } from "react-icons/md";
import { RiGroupLine } from "react-icons/ri";
// IoNewspaperOutline
import { IoNewspaperOutline } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import {
  adminCountDocumentsAndViewsAction,
  adminGetAllLogsAction,
} from "../../../../context/actions/admin/dashbaordAction";
import { adminGetAllHelpSupportAction } from "../../../../context/actions/admin/helpsupportAction";

const now = new Date();
const DashboardCard = memo(({ icon, title, iconColor, value }) => {
  return (
    <Grid xs={12} sm={6} lg={3}>
      <Card
        sx={{
          height: "100%",
        }}
      >
        <CardContent>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography color="text.secondary" variant="overline">
                {title}
              </Typography>
              <Typography variant="h4">{value || 0}</Typography>
            </Stack>
            <Avatar
              sx={{
                backgroundColor: iconColor,
                height: 56,
                width: 56,
              }}
            >
              <SvgIcon>{icon}</SvgIcon>
            </Avatar>
          </Stack>
          <small>Since last months</small>
        </CardContent>
      </Card>
    </Grid>
  );
});


export default function AdminDashboard() {
  const dispatch = useDispatch();

  const [controller, setController] = React.useState({
    page: 0,
    rowsPerPage: 10,
  });

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };



  React.useEffect(() => {
    Promise.all([
      dispatch(adminCountDocumentsAndViewsAction({})),
      dispatch(adminGetAllHelpSupportAction({})),
    ]);
  }, [dispatch]);

React.useEffect(() => {
  dispatch(adminGetAllLogsAction(controller.page));
}, [controller.page]);


  const {
    blogOverview,
    userOverview,
    organizationOverview,
    businessOverview,
    flatemateOverview,
    rentalOverview,
    professionalOverview,
    eventOverview,
    help_support,
    logs,
    logsresultperpage,
    totallogscount,
  } = useSelector((state) => ({
    blogOverview: state.admin_reducer.blogOverview,
    userOverview: state.admin_reducer.userOverview,
    organizationOverview: state.admin_reducer.organizationOverview,
    businessOverview: state.admin_reducer.businessOverview,
    flatemateOverview: state.admin_reducer.flatemateOverview,
    rentalOverview: state.admin_reducer.rentalOverview,
    professionalOverview: state.admin_reducer.professionalOverview,
    eventOverview: state.admin_reducer.eventOverview,
    help_support: state.admin_reducer.help_support,
    logs: state.admin_reducer.logs,
    logsresultperpage: state.admin_reducer.logsresultperpage,
    totallogscount: state.admin_reducer.totallogscount,
  }));

  const data = [
    organizationOverview?.totalviewCount || 0,
    businessOverview?.totalviewCount || 0,
    rentalOverview?.totalviewCount || 0,
    professionalOverview?.totalviewCount || 0,
    blogOverview?.totalviewCount || 0,
    flatemateOverview?.totalviewCount || 0,
    eventOverview?.totalviewCount || 0,
  ];

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <DashboardCard
              icon={<BsBuildings />}
              title="Organization"
              iconColor="error.main"
              value={organizationOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<BiBuildingHouse />}
              title="Business"
              iconColor="primary.main"
              value={businessOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<BsBuildings />}
              title="Rental Property"
              iconColor="success.main"
              value={rentalOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<FiUsers />}
              title="Total Users"
              iconColor="blue"
              value={userOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<RiGroupLine />}
              title="Flatemates"
              iconColor="black"
              value={flatemateOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<ImProfile />}
              title="Professionals"
              iconColor="orange"
              value={professionalOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<MdArticle />}
              title="Blogs"
              iconColor="gray"
              value={blogOverview?.totalDocuments}
            />

            <DashboardCard
              icon={<IoNewspaperOutline />}
              title="News & Events"
              iconColor="error.main"
              value={eventOverview?.totalDocuments}
            />

            <Grid xs={12} lg={7}>
              <OverviewViewsCount
                chartSeries={[
                  {
                    name: "Views",
                    data: data,
                  },
                ]}
                sx={{ height: "100%" }}
              />
            </Grid>

            <Grid xs={12} md={6} lg={5}>
              <OverviewHelp
                help_support_list={help_support}
                sx={{ height: "100%" }}
              />
            </Grid>
            <Grid xs={12} md={12} lg={12}>
              <OverviewActivityLog
                activity={logs}
                totalcount={totallogscount}
                resultperpage={logsresultperpage}
                handlePageChange={handlePageChange}
                page={controller.page}
               
                sx={{ height: "100%" }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
