import { Box, Modal, Tab, Typography } from "@mui/material";
import React from "react";

import { ImCross } from "react-icons/im";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AdminHelpDetailIntro, AdminHelpStatusLog } from "./tabs";
import {useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {toast} from "react-toastify";
import { adminChangeStatusHelpAndSupport } from "../../../../../context/actions/admin/helpsupportAction";
const AdminHelpAndSupportDetail = ({ data, onClose, activeindex, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabs = ["Information", "Followup"];
  const statuslst = ["Pending", "In Progress", "Resolved"];

  const [value, setValue] = React.useState("0");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: 400 },
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  // change tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [formvalue, setFormValue] = React.useState({
    status: "",
    message: "",
  });

  const handleFormValue = (e) => {
    setFormValue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeStatusHelpAndSupport({
        id: id,
        data: formvalue,
        toast: toast,
        activeIndex: activeindex,
        navigate: navigate,
      })
    );
  };

  return (
    <Box
      sx={{
        paddingBlock: "3vh",
        minHeight: "100vh",

        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="container h-maxcontent"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Help And Support Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <hr />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          textAlign: { xs: "center", sm: "start" },
        }}
        className=" gap-2   w-100 align-items-center justify-content-center "
      >
        <img
          src='/assets/images/problem.png'
          width={150}
          
        />
        <div className="d-flex flex-column">
          <Typography
            variant="h2"
            className="fw-bold text-black"
            sx={{
              fontSize: { xs: "0.7em", sm: "0.9em", md: "1.1em", lg: "1.5em" },
            }}
          >
            {data?.topic}
          </Typography>

          <div className="d-flex align-items-center gap-2 mt-2">
           <span className="btn btn-sm btn-outline-danger">
           {data?.severity}⭕
           </span>
           <span className="btn btn-sm btn-outline-danger">
           {data?.status}
           </span>
          </div>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            justifyContent: { xs: "center" },
            width: { xs: "100%", sm: "max-content" },
          }}
          className=" gap-2 ms-auto  "
        >
          <button
            type="button"
            className="btn  bg-danger  text-white d-flex flex-nowrap gap-1"
            data-mdb-ripple-color="dark"
            onClick={handleOpen}
          >
            update status
          </button>
        </Box>
      </Box>

      <section className="mt-3">
        <TabContext value={value.toString()}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              background: "#F6F6F6",
            }}
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  label={item}
                  value={index.toString()}
                  key={item}
                  className="text-capitalize"
                  indicatorcolor={""}
                  sx={{
                    "&.Mui-selected": {
                      color: "#797979",
                      fontWeight: "bolder",
                      border: "none",
                    },
                  }}
                />
              );
            })}
          </TabList>

          <TabPanel className="m-0 p-0 py-3" value="0">
            <AdminHelpDetailIntro data={data} />
          </TabPanel>
          <TabPanel className="m-0 p-0 py-3" value="1">
            <AdminHelpStatusLog data={data.history} />
          </TabPanel>
         
        </TabContext>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h5 className="text-center fw-bold text-black">Update Status</h5>
          <form action="" onSubmit={handleSubmit}>
            <div className=" form-group w-100">
              <label htmlFor="">Status</label>
              <select
                required={true}
                className={
                  "form-select rounded-0 form-select-lg  py-2 rounded-2"
                }
                aria-label=".form-select-lg example"
                value={formvalue.status}
                name={"status"}
                onChange={handleFormValue}
              >
                <option value="">Select Status</option>
                {statuslst.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mt-2 w-100">
              <label htmlFor="message" className="mb-1 text-black">
                Message
              </label>
              <textarea
                name="message"
                onChange={handleFormValue}
                className={"form-control py-2"}
                id={"message"}
                aria-describedby="emailHelp"
                placeholder={"Enter Message"}
                cols="30"
                rows="6"
                required={true}
                value={formvalue.message}
              ></textarea>
            </div>
            <button
              className="btn bg-black text-white w-100 mt-3 "
              type="submit"
            >
              Submit
            </button>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminHelpAndSupportDetail;
