import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/adsApi";

// create ads banner

export const adminCreateAdsBannerAction = createAsyncThunk(
  "admin/create_ads_banner",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.postAdsBanner(formData);
      toast.success(response.data.message || "Ads banner created successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

