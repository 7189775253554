import { Container, Divider, Stack, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormInput, SubmitButton } from "../../components/Index";
import {
  LoginAction,
  ResetPasswordAction,
  google_login,
} from "../../context/actions/authAction";
import { clearError } from "../../context/reducers/authenticateSlice";
import useResponsive from "../../hooks/useResponsive";
import "./Login.css";

import jwt_decode from "jwt-decode";
import styled from "styled-components";
import ForgotPassword from "../../components/forgotPassword/FotgotPassword";
import { Helmet } from "react-helmet-async";

const StyledSection = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default function Login(props) {
  const buttonRef = React.useRef(null);
  // use dspatch to dispatch action
  const dispatch = useDispatch();
  // useNavigate to navigate to other page
  const navigate = useNavigate();

  // intialize form data state
  const initialState = {
    email: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialState);

  // intialize errors state
  const [errors, setErrors] = useState({});

  // use useSelector to get data from store from user_reducer
  const { loading, error } = useSelector((state) => ({
    ...state.auth_slice,
  }));

  


  // use useCallback to memoize the function

  useEffect(() => {
    if (error) {
      toast.error(error);
    }

    dispatch(clearError());
  }, [error]);



  const { email, password } = formData;

  // handle the login form
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {

      dispatch(LoginAction({ formData, navigate, toast, dispatch }));
    } else {
      setErrors(errors);
    }
  };

  // handle the onchnage value form Login
  const handleChnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // body code
  const mdUp = useResponsive("up", "md");

  // validate the form
  const validateForm = () => {
    let errors = {};
    if (!email) {
      errors.email = "Email is required *";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Please enter a valid email address *";
    }
    if (!password) {
      errors.password = "Password is required *";
    }
    return errors;
  };

  // google login function
  const handleLoginSuccess = (response) => {
    const userObject = jwt_decode(response.credential);

    const { email, email_verified, given_name, family_name } = userObject;
    // formData to send to backend
    const formData = {
      email: email,
      firstname: given_name,
      lastname: family_name,
      verified: email_verified,
      country: "Nepal",
      password: "nullllllllllllll1123333@",
    };
    dispatch(google_login({ formData, navigate, toast, dispatch }));
  };

  // google login failure function

  const handleLoginFailure = (response) => {
    console.error(response);
  };

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      buttonRef.current.style.width = `${buttonWidth}px`;
    }

    const element = document.querySelector(
      '[title="Sign in with Google Button"]'
    );
    if (element) {
      element.style.backgroundColor = "blue";
      element.style.width = "100%";
    }
  }, []);

  // forget password
  const [openForgotPassword, setOpenForgotPassword] = React.useState(false);

  const handleOpenForgotPassword = React.useCallback(() => {
    setOpenForgotPassword(true);
  }, []);

  const handleCloseForgotPassword = React.useCallback(() => {
    setOpenForgotPassword(false);
  }, []);

  const [forgetPassword, setForgetPassword] = useState({ email: "" });

  const handleEmailChnage = (e) => {
    setForgetPassword({ ...forgetPassword, [e.target.name]: e.target.value });
  };

  const handleSubmitForgotPassword = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", forgetPassword.email);
    dispatch(ResetPasswordAction({ formData: formData, toast: toast }));
    handleCloseForgotPassword();
  };

  // retrun the jsx
  return (
    // loading ? <Loader /> :
    <Fragment>
      <Helmet>
        <title>Login</title>
        <meta name="description"
          content="Log in to your ONF CANADA account. Access your profile, connect with peers, and explore resources dedicated to organizations, businesses, rentals, flatmates, teaming up, blogs, and studying abroad in Canada for a fulfilling Canadian experience." />
      </Helmet>

      <div className="login-header">
        {mdUp && (
          <StyledSection>
            <div className="left">
              <img src="/assets/images/login.png" alt="login" />
            </div>
          </StyledSection>
        )}

        <Container maxWidth="xs">
          <form onSubmit={handleSubmit}>
            <div className="right-side">
              <Typography variant="h4" gutterBottom>
                Sign in
              </Typography>

              <Typography variant="body2" sx={{ mb: 4 }}>
                Don’t have an account? {""}
                <Link to="/register" style={{ textDecoration: "none" }}>
                  Create account
                </Link>
              </Typography>

              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                cookiePolicy={"single_host_origin"}
                size={"large"}
                id="google-login-button"
              />

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  OR
                </Typography>
              </Divider>

              {/* Login Form */}
              <Stack spacing={3}>
                <FormInput
                  name={"email"}
                  label={"Email Address"}
                  value={email}
                  required={true}
                  handleChange={handleChnage}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />
                <FormInput
                  name={"password"}
                  label={"Password"}
                  required={true}
                  value={password}
                  handleChange={handleChnage}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  type={"password"}
                />
              </Stack>

              <Stack
                alignItems="flex-end"
                justifyContent="space-between"
                sx={{ my: 1 }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  variant="subtitle2"
                  underline="hover"
                  onClick={handleOpenForgotPassword}
                >
                  Forgotton your password?
                </Typography>
              </Stack>

              <SubmitButton isLoading={loading} name={"Login"} />
            </div>
          </form>
        </Container>
      </div>
      <ForgotPassword
        open={openForgotPassword}
        handleClose={handleCloseForgotPassword}
        handleFormChnage={handleEmailChnage}
        handleSubmit={handleSubmitForgotPassword}
      />
    </Fragment>
  );
}
