import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/businessApi";



export const adminGetAllBusinessAction = createAsyncThunk(
  "admin/get_all_business",
  async ({ keyword = "", page = 0, status = true }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllBusiness(
        keyword,
        page,
        status
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// change status of business for admin to control business
export const adminChangeBusinessStatusAction = createAsyncThunk(
  "admin/change_business_status",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
    
      const response = await admin_api.adminChangeBusinessStatus(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured business

export const adminUpdateFeaturedBusinessAction = createAsyncThunk(
  "admin/featured_business",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateFeaturedBusiness(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



// verify business
export const adminVerifyBusinessAction = createAsyncThunk(
  "admin/verify_business",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminVerifyBusiness(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//give_review_business
export const adminGiveReviewBusinessAction = createAsyncThunk(
  "admin/give_review_business",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGiveReviewBusiness(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




// create business for admin
export const adminCreateBusinessAdminAction = createAsyncThunk(
  "admin/create_business",
  async ({ toast, formData, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateBusinessAdmin(formData);
      toast.success(response.data.message);
      navigate("/admin/business");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// edit business by admin
export const adminEditBusinessAction = createAsyncThunk(
  "admin/edit_business",
  async ({ formData, id, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditBusiness(formData, id);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);