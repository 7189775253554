import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
const AdminProfessionalContact = ({ data }) => {
  const { socialMediaLinks } = data;
  return (
    <div className="d-flex flex-column gap-3">
      <section className="d-flex gap-2 flex-column fs-5">
        <span className="fs-5 fw-bold">Social Media Links</span>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PublicIcon />
            <span className="fs-6">Personal Website</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">
            {socialMediaLinks?.personalLink.length === 0
              ? "none"
              : socialMediaLinks?.personalLink}
          </p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <FacebookIcon />
            <span className="fs-6">FaceBook Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">
            {socialMediaLinks?.facebookLink.length === 0
              ? "none"
              : socialMediaLinks?.facebookLink}
          </p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <TwitterIcon />
            <span className="fs-6">Twitter Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">
            {socialMediaLinks?.twitterLink.length === 0
              ? "none"
              : socialMediaLinks?.twitterLink}
          </p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <InstagramIcon />
            <span className="fs-6">Instagram Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">
            {socialMediaLinks?.instagramLink.length === 0
              ? "none"
              : socialMediaLinks?.instagramLink}
          </p>
        </div>
      </section>
    </div>
  );
};

export default AdminProfessionalContact;
