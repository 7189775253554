import React from "react";

const NoResultFound = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center w-100 "
      style={{ minHeight: "50vh" }}
    >
      <div className="text-center w-50">
        <img src="/assets/icons/search.png" alt="" />
        <p className="fw-bold">No result found</p>
        <p className="text-muted">try with different keywords</p>
      </div>
    </div>
  );
};

export default NoResultFound;
