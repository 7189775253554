import { Box, Modal } from "@mui/material";
import React from "react";
import { MdEditSquare } from "react-icons/md";
import ProfessionalMIntroduction from "../modals/ProfessionalMIntroduction";
const ProfessionalIntroduction = ({introduction}) => {
  const [open, setOpen] = React.useState(false);
  const handleModal = () => {
    setOpen((prev) => !prev);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "95%", md: "70%", lg: "50%" },
    bgcolor: "var(--secondary-color)",
    borderRadius: "15px",
    color: "var(--primary-color)",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div className="">
      <h5 className="text-black fw-bold">Introduction</h5>

      <Box
        sx={{
          fontSize: { lg: "1.1em" },
        }}
        className="row-cols-2 row gx-2 gy-2 position-relative"
      >
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            color: "white",
            bgcolor: "black",
          }}
          onClick={handleModal}
        >
          <MdEditSquare />
        </Box>
        <div className=" d-flex justify-content-between col-3">
          <p>Name</p>
          <p>:</p>
        </div>
        <div className="col-9">
          <p className="fw-bold">
            {introduction?.firstName}

            {introduction?.lastName}
          </p>
        </div>
        <div className=" d-flex justify-content-between col-3">
          <p>Profession</p>
          <p>:</p>
        </div>
        <div className="col-9">
          <p className="fw-bold">{introduction?.profession}</p>
        </div>
        <div className=" d-flex justify-content-between col-3">
          <p>Location</p>
          <p>:</p>
        </div>
        <div className="col-9">
          <p className="fw-bold">
            {introduction?.provenience}||{introduction?.city}
          </p>
        </div>
        <div className=" d-flex justify-content-between col-3">
          <p>Professional Description</p>
          <p>:</p>
        </div>
        <div className="col-9">
          <p className="fw-bold">{introduction?.description}</p>
        </div>
      </Box>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ProfessionalMIntroduction onClose={handleModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default ProfessionalIntroduction;
