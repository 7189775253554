import DoneIcon from "@mui/icons-material/Done";
import { Chip, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "97vw", sm: 400 },
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
};

const ServicesModal = ({ openServices, handleCloseServices, offers }) => {
  return (
    <Modal
      open={openServices}
      onClose={handleCloseServices}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Showing all services
        </Typography>

        <div className="d-flex flex-wrap gap-2">
          {offers?.map((item, index) => {
            return (
              <Chip
                icon={
                  <DoneIcon
                    sx={{
                      color: "green",
                    }}
                  />
                }
                key={item}
                label={item}
                variant="outlined"
                size="large"
              />
            );
          })}
        </div>
        {/* <div className="d-flex gap-4 mt-3">
          <div>
            <div className="d-flex gap-3">
              <Wifi size={30} />
              <p>Free Wifi</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <BikeScooterTwoTone size={30} />
              <p>2 Wheelers parking</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <LocalParking size={30} />
              <p>4 Wheelers parking</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <Nature size={30} />
              <p>Natural Enviroment</p>
            </div>
          </div>
          
          <div>
            <div className="d-flex gap-3">
              <FoodBank size={30} />
              <p>Thakali Set</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <Water size={30} />
              <p>Riverside Hotel</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <MdOutlineTrain size={30} />
              <p>Nearby Railway</p>
            </div>

            <div className="d-flex gap-3 mt-2">
              <Bedtime size={30} />
              <p>Deluxe Session</p>
            </div>
          </div>
        </div> */}
      </Box>
    </Modal>
  );
};

export default ServicesModal;
