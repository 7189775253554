import { createSlice } from "@reduxjs/toolkit";

import {
  LoginAction,
  google_login,
  post_bookmark,
  EditProfileAction,
} from "../actions/authAction";

import { adminLoginAction } from "../actions/admin/authAction";
const initialState = {
  isAuth: false,
  isAdmin: false,
  user_data: null,
  admin_data: null,

  editProfileLoading: false,

  error: "",
};

const AuthenticateSlice = createSlice({
  name: "Authenticate",
  initialState,
  reducers: {
    showtoast: (state, action) => {
      state.error = action.payload.message;
    },
    setAuth: (state, action) => {
      state.user_data = action.payload.user;
      if (state.user_data === null) {
        state.isAuth = false;
      } else {
        state.isAuth = true;
      }
    },

    setAdmin: (state, action) => {
      state.admin_data = action.payload.user;
      if (state.admin_data === null) {
        state.isAdmin = false;
      } else {
        state.isAdmin = true;
      }
    },
    adminLogut: (state, action) => {
      state.admin_data = null;
      state.isAdmin = false;
    },
    logout: (state, action) => {
      state.user_data = null;
      state.isAuth = false;
    },
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(LoginAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(LoginAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // admin login
      .addCase(adminLoginAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminLoginAction.fulfilled, (state, action) => {
        state.loading = false;
        state.admin_data = action.payload.admin;
        state.isAdmin = true;
      })
      .addCase(adminLoginAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      // google login
      .addCase(google_login.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(google_login.fulfilled, (state, action) => {
        state.loading = false;
        // state.user = action.payload;
      })
      .addCase(google_login.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(post_bookmark.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(post_bookmark.fulfilled, (state, action) => {
        state.loading = false;
        state.user_data.bookmarks = action.payload.bookmarks;
      })
      .addCase(post_bookmark.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.payload.message;
      })

      .addCase(EditProfileAction.pending, (state, action) => {
        state.editProfileLoading = true;
      })
      .addCase(EditProfileAction.fulfilled, (state, action) => {
        state.editProfileLoading = false;
        const { updateuser } = action.payload;
        state.user_data = updateuser;
      })
      .addCase(EditProfileAction.rejected, (state, action) => {
        state.editProfileLoading = false;
        state.error = action.payload.message;
      });
  },
});

export const { showtoast, setAuth, setAdmin, adminLogut, logout, clearError } =
  AuthenticateSlice.actions;
export default AuthenticateSlice.reducer;
