import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/professionalApi";

export const adminCreateProfessionalAction = createAsyncThunk(
  "admin/create_professional",
  async ({ formData, toast, naviagte}, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateProfessional(formData);
      toast.success("Professional created successfully");
      naviagte("/admin/professionals");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//adminEditProfessional
export const adminEditProfessionalAction = createAsyncThunk(
  "admin/edit_professional",
  async ({ id, formData, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditProfessional(id, formData);
      const response2 = { data: response.data, activeIndex: activeindex };
      toast.success("Professional updated successfully");
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



// admin_get_all_professional
export const adminGetAllProfessionalAction = createAsyncThunk(
  "admin/get_all_professional",
  async ({ keyword, page, status }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllProfessional(
        keyword,
        page,
        status
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//adminChangeProfessionalStatus
export const adminChangeProfessionalStatusAction = createAsyncThunk(
  "admin/change_professional_status",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminChangeProfessionalStatus(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured Professional

export const adminUpdateFeaturedProfessionalAction = createAsyncThunk(
  "admin/update_featured_professional",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateFeaturedProfessional(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



//adminVerifyProfessional
export const adminVerifyProfessionalAction = createAsyncThunk(
  "admin/verify_professional",

  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminVerifyProfessional(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//adminGiveReviewProfessional
export const adminGiveReviewProfessionalAction = createAsyncThunk(
  "admin/give_review_professional",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGiveReviewProfessional(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

