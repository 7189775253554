import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/authApi";

import { setAdmin } from "../../reducers/authSlice";

export const adminLoginAction = createAsyncThunk(
  "admin/login",
  async ({ data, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminLogin(data);
      localStorage.setItem("token", response.data.token);
      toast.success("Login Successful");
      navigate("/admin");
      return response.data;
    } catch (err) {
      if (err.message === "Invalid response data") {
        toast.error("Unable to login. Please try again later.");
      } else {
        toast.error(err.response?.data ?? "An error occurred");
      }
      return rejectWithValue(err.response?.data ?? err.message);
    }
  }
);

//register admin
export const adminRegisterAdminAction = createAsyncThunk(
  "admin/register",
  async ({ data, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.registerAdmin(data);
      toast.success(response.data.message);
      navigate("/admin/members");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data ?? err.message);
    }
  }
);


//get all admin
export const adminGetAllAdminAction = createAsyncThunk(
  "admin/",
  async ({keyword, page}, { rejectWithValue }) => {
    try {
      const response = await admin_api.getAllAdmin(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data ?? err.message);
    }
  }
);