import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Drawer,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Tab,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  adminGetAllBlogAction,
  adminGetAllFeaturedBlogAction,
} from "../../../../../context/actions/admin/blogAction";
import AddBlogs from "../addblog/AddBlog";
import AllBlogs from "./components/AllBlogs";

import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { SearchbarB } from "../../../../../components/Index";
import DraftBlogTab from "./DraftBlogTab";
import PublishedBlogTab from "./PublishedBlogTab";
import EditFeatured from "./components/EditFeatured";
import FeaturedBlogTab from "./FeaturedBlogTab";
import { ArrowBack, ArrowDropDown, ArrowForward } from "@mui/icons-material";

const AllBlog = () => {
  const dispatch = useDispatch();
  // handle search
  const [search, setsearch] = React.useState("");

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setsearch("");
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setsearch(event.target.value);
    }
  };
  // drawer toggle
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  const [category, setcategory] = React.useState("");
  const [value, setValue] = React.useState("1");

  useEffect(() => {
    dispatch(
      adminGetAllBlogAction({
        category: category,
        keyword: search,
      })
    );
  }, [category, search]);

  useEffect(() => {
    dispatch(adminGetAllFeaturedBlogAction({}));
  }, []);

  const handleChangez = (event, newValue) => {
    setValue(newValue);
  };

  const {
    blogs,
    resultperpage,
    filterblogcount,
    totalBlogCount,
    blog_loading,
    featured_blogs,
  } = useSelector((state) => ({
    ...state.admin_reducer,
  }));

  

  return (
    <div className="">
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AddBlogs closemodal={toggleDrawer} />
      </Drawer>

      <div className="custom-container">
        <Box
          className="headerimg position-relative rounded rounded-5 overflow-hidden"
          sx={{
            height: "300px",
          }}
        >
          <Box
            className="headerimg position-relative rounded rounded-5 overflow-hidden mt-2"
            sx={{
              height: "350px",
            }}
          >
            <Box
              className="w-100 h-100 position-absolute top-0 start-0"
              sx={{
                zIndex: "1",
                background:
                  "linear-gradient(90deg, #000000 -1.46%, rgba(138, 91, 91, 0) 79%);",
              }}
            ></Box>
            <div
              id="carouselExampleControls"
              className="carousel slide h-100"
              data-mdb-ride="carousel"
            >
              <div className="carousel-inner h-100">
                {featured_blogs.map((blog, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${blog?.thumbnail}`}
                      className="d-block w-100"
                      alt={blog.alt}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-mdb-target="#carouselExampleControls"
                data-mdb-slide="prev"
              >
                <ArrowBack />
                <span aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-mdb-target="#carouselExampleControls"
                data-mdb-slide="next"
              >
                <ArrowForward />
                <span aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <Box
              className="d-flex flex-column position-absolute"
              sx={{
                top: "35%",
                left: "3%",
                zIndex: "2",
                fontSize: "1.3rem",
              }}
            >
              <h5 className="fw-bold text-white">Featured.</h5>
              <p className="text-white fs-6">
                Lorem ipsum dolor sit amet consectetur
              </p>
              <button
                type="button"
                className="btn btn-outline-white w-50 mt-3"
                data-mdb-ripple-color="dark"
              >
                Show More
              </button>
            </Box>
          </Box>
        </Box>
        <section className="search-section d-flex flex-wrap align-items-center gap-1 my-2">
          <Typography
            className="display-6 mt-3 mb-3  text-nowrap  text-black"
            variant={"span"}
          >
            Admin | Blogs
          </Typography>
          <Box
            sx={{
              height: "50px",
              width: { xs: "100%", sm: "100%", md: "50%" },
            }}
            className="ms-auto "
          >
            <OutlinedInput
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className="h-100"
              defaultValue=""
              fullWidth
              placeholder="Search Blogs..."
              sx={{
                border: "none",
                borderRadius: "10px",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          </Box>
          <Link to={"create"} className="btn btn-outline-black h-100">
            Add Blog ✍️
          </Link>
        </section>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangez}
                aria-label="lab API tabs example"
              >
                <Tab label="Published" value="1" />
                <Tab label="Draft BLogs" value="2" color="error" />
                <Tab label="Featured" value="3" />
              </TabList>
            </Box>
            <TabPanel className="m-0 p-0 mt-3" value="1">
              <PublishedBlogTab blog_loading={blog_loading} blogs={blogs} />
            </TabPanel>
            <TabPanel value="2" className="m-0 p-0 mt-3">
              <DraftBlogTab blog_loading={blog_loading} blogs={blogs} />
            </TabPanel>
            <TabPanel value="3" className="m-0 p-0 mt-3">
              <FeaturedBlogTab blog_loading={blog_loading} blogs={blogs} />
            </TabPanel>
          </TabContext>
        </Box>
      </div>

      <div
        className="modal fade  "
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <Box className="modal-dialog w-100 rounded rounded-3  border border-4 ">
          <Box
            className="modal-content  "
            sx={{
              width: { sm: "90%", lg: "60vw" },
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Featured Blog
              </h5>
              <button
                type="button"
                className="btn-close"
                data-mdb-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-sub w-100  px-2 mb-2">
              <SearchbarB placeholder={"Search"} />
            </div>
            <Box
              className="modal-body "
              sx={{
                height: 600,
                overflowY: "scroll",
              }}
            >
              <EditFeatured />
            </Box>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-mdb-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default AllBlog;
