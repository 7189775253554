import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import {
  ProfessionalInfo,
  ProfessionalType,
  ProfessionalDescription,
  ProfessionalContactAndLinks,
  ProfessionalTermsAndCondtions,
} from "./formsteppers";
import { useDispatch, useSelector } from "react-redux";
import { CreateProfessionalAction } from "../../context/actions/professionalAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../context/reducers/professionalSlice";
const AddProfessional = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = {
    profession: "",
    userPicFile: "",
    firstName: "",
    lastName: "",
    proveniece: "",
    city: "",
    email: "",
    gender: "",
    age: "",
    offers: [],
    description: "",
    personalLink: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
  };
  const formerrors = {
    profession: "",
    userPic: "",
    city: "",
    proveniece: "",
    email: "",
    gender: "",
    age: "",
    offers: "",
    description: "",
    agreement: "",
  };

  // useSelector to get error from professional_reducer
  const { createProfessionalLoading,error } = useSelector((state) => ({
    error: state.professional_reducer.error,
    createProfessionalLoading:
      state.professional_reducer.createProfessionalLoading,
  }));

  // toast error
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError())
  }, [error]);


  const [activeStep, setActiveStep] = React.useState(0);
  const theme = useTheme();

  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formValues);

  const [userage, setAge] = React.useState(18);

  const [agreements, setAgreements] = React.useState(false);
  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);
  // on back button click
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // change value base on e.target.name
  const handleFormChange = (e) => {
    const name = e.target.name;
    switch (name) {
      case "userPic":
        const file = e.target.files[0];
        let blobURL = URL.createObjectURL(file);
        e.target.value = "";

        setformvalues({
          ...formvalue,
          userPic: blobURL,
          userPicFile: file,
        });
        break;
      case "firstName":
        setformvalues({ ...formvalue, [e.target.name]: e.target.value.trim() });

        break;
      case "lastName":
        setformvalues({ ...formvalue, [e.target.name]: e.target.value.trim() });

        break;
      default:
        setformvalues({ ...formvalue, [e.target.name]: e.target.value });

        break;
    }
  };

  // handles PhoneNumber
  const handlePhoneNumber = (phone) => [
    setformvalues({ ...formvalue, contact: phone }),
  ];

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "addoffer":
        const offer = value.trim();
        if (offer !== "" && !formvalue.offers.includes(offer)) {
          setformvalues({
            ...formvalue,
            offers: [...formvalue.offers, offer],
          });
        }

        break;
      case "removeoffer":
        const lst = formvalue.offers;
        const newlst = lst.filter((item) => item !== value);
        setformvalues({
          ...formvalue,
          offers: newlst,
        });
        break;

      case "agreement":
        if (agreements) {
          setAgreements(false);
        } else {
          setAgreements(true);
        }
        break;
      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

  // on province select
  const onprovinceselect = (value) => {
    setformvalues({ ...formvalue, proveniece: value, city: "" });
  };

  // on city select
  const oncityselect = (value) => {
    setformvalues({ ...formvalue, city: value });
  };
  const formvalidation = () => {
    let isValid = false;

    const newerror = { ...formerrors };

    if (activeStep === 0) {
      if (
        !formvalue.firstName ||
        !formvalue.lastName ||
        !formvalue.gender ||
        !formvalue.proveniece ||
        !formvalue.city ||
        !formvalue.userPic ||
        (formvalue.age && formvalue.age < 18) ||
        !formvalue.age
      ) {
        if (!formvalue.firstName) {
          newerror.firstName = "Please provide firstName *";
        }

        if (!formvalue.lastName) {
          newerror.lastName = "Please provide lastName *";
        }

        if (!formvalue.proveniece) {
          newerror.proveniece = "Please provide proveniece *";
        }
        if (!formvalue.city) {
          newerror.city = "Please provide city *";
        }
        if (!formvalue.gender) {
          newerror.gender = "Please provide gender *";
        }

        if (!formvalue.age) {
          newerror.age = "Please provide your age *";
        }
        if (formvalue.age && formvalue.age < 18) {
          newerror.age = "Please provide valid age (18 or older) *";
        }
        if (!formvalue.userPic) {
          newerror.userPic = "Please provide Your Image *";
        }
        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }

    if (activeStep === 1) {
      if (formvalue.offers.length === 0 || !formvalue.profession) {
        if (!formvalue.profession) {
          newerror.profession = "Please provide profession *";
        }
        if (formvalue.offers.length === 0) {
          newerror.offers = "Please provide offers *";
        }
        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }
    if (activeStep === 2) {
      if (!formvalue.description) {
        newerror.description = "Please provide description *";

        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }
    if (activeStep === 3) {
      const phoneRegex = /^\d{10}$/;
      const isphone = phoneRegex.test(formvalue.contact);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)?$/;

      const isValidEmail = emailRegex.test(formvalue.email);
      if (!formvalue.contact || !formvalue.email || !isphone || !isValidEmail) {
        if (!formvalue.email) {
          newerror.email = "Please provide email *";
        }
        if (!formvalue.contact) {
          newerror.contact = "Please provide contact *";
        }

        if (!isphone && formvalue.contact) {
          newerror.contact = "please provide valid candian number *";
        }
        if (!isValidEmail && formvalue.email) {
          newerror.email = "please provide valid email";
        }
        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }

    if (activeStep === 4) {
      if (!agreements) {
        newerror.agreement = "please accept agreement *";
        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }

    setErrors(newerror);

    return isValid;
  };

  const handleNext = () => {
    const isValid = formvalidation();
    if (isValid) {
      if (activeStep < 4) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        const data = new FormData();
        data.append("profession", formvalue.profession);
        data.append("city", formvalue.city);
        data.append("provenience", formvalue.proveniece);
        data.append("images", formvalue.userPicFile);
        data.append("firstName", formvalue.firstName);
        data.append("lastName", formvalue.lastName);
        data.append("email", formvalue.email); 
        data.append("gender", formvalue.gender);
        data.append("age", formvalue.age);
        data.append("description", formvalue.description);
        data.append("personalLink", formvalue.personalLink);
        data.append("facebookLink", formvalue.facebookLink);
        data.append("instagramLink", formvalue.instagramLink);
        data.append("twitterLink", formvalue.twitterLink);
        data.append("offers", JSON.stringify(formvalue.offers));
        dispatch(CreateProfessionalAction({data:data,tost:toast,navigate:navigate}));
      }
    } else {
    }
  };

  return (
    <div className="container">
      <div>
        {/* form components are arraged with the help of mui tabs */}
        <TabContext value={activeStep.toString()}>
          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="0"
          >
            <ProfessionalInfo
              handleFormChange={handleFormChange}
              errors={errors}
              formvalue={formvalue}
              onprovinceselect={onprovinceselect}
              oncityselect={oncityselect}
            />
          </TabPanel>
          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="1"
          >
            <ProfessionalType
              formvalue={formvalue}
              handleFormValue={handleFormValue}
              errors={errors}
            />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <ProfessionalDescription
              errors={errors.description}
              handleFormValue={handleFormValue}
              formvalue={formvalue.description}
            />
          </TabPanel>
          <TabPanel
            value="3"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <ProfessionalContactAndLinks
              errors={errors}
              handleFormValue={handleFormValue}
              handleFormChange={handleFormChange}
              formvalue={formvalue}
            />
          </TabPanel>
          <TabPanel
            value="4"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <ProfessionalTermsAndCondtions
              errors={errors.agreement}
              handleFormValue={handleFormValue}
              formvalue={agreements}
            />
          </TabPanel>
        </TabContext>
      </div>

      <progress
        value={activeStep}
        className="w-100 my-2 text-black"
        max={4}
      ></progress>
      <div className="d-flex w-100  align-items-center justify-content-between">
        <Button size="medium" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>

        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <Button
          size="large"
          className="btn-dark text-white text-capitalize"
          onClick={handleNext}
        >
          {activeStep === 4 ? "Publish" : "Next"}
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddProfessional;
