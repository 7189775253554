import customInstance from "./manage_access_route";
import API from "./common_api";


// get professional detail
export const GetProfessionalDetail = (id) =>
  API.get(`/api/professional/get_professional_details/${id}`);


// crete professional Review
export const CreateProfessionalReview = (review, id) =>
  customInstance.post(
    `/api/professional/create_professional_review/${id}`,
    review
  );

export const GetAllProfessinals = (keyword = "", page=0, city="", provenience="") =>
  API.get(
    `/api/professional/get_all_professionals?keyword=${keyword}&page=${page}&city=${city}&provenience=${provenience}`
  );

  // create professional
export const CreateProfessional = (data) =>
  customInstance.post("/api/professional/create_professional", data);

  