import React from "react";
import { FormSelectB, FormInputB } from "../../../../components/Index";
import allcity from "../../../../utils/all_city";
import ReactQuill from "react-quill";
import { AddressAutofill } from "@mapbox/search-js-react";
import { allProvince, Category } from "../../../../utils/orgSettings";

const Introduction = ({
  value,
  handleformvalue,
  activestepper,
  handleSubmit,
  changeStepper,
  errors,
}) => {
  const filteredcity = React.useMemo(() => {
    return allcity
      .filter((option) => option.province_name === value.province)
      .sort((a, b) => a.city.localeCompare(b.city));
  }, [value.province]);
  return (
    <div
      className={
        activestepper === 0
          ? "   py-3 needs-validation"
          : "d-none"
      }
    >
      <h4>Basic information</h4>
      <div className="bg-hover p-lg-5 p-3  rounded rounded-6 ">
        <FormInputB
          name={"nameofcompany"}
          handleChange={handleformvalue}
          value={value.nameofcompany}
          label={"Name of company (Ex: ONF Canada Public Group)"}
          required={true}
          classname={"w-100 form-control"}
          placeholder={"Enter Company Name"}
          error={errors.nameofcompany ? true : false}
          helpertext={errors.nameofcompany}
        />

        {/* category select box */}
        <div className="mt-2">
          <label htmlFor="category">Select Category (Ex.Educational, IT)</label>
          <select
            className={
              errors.category
                ? "form-select form-select-lg rounded-0 py-2 rounded-2 is-invalid"
                : "form-select form-select-lg rounded-0 py-2 rounded-2 "
            }
            aria-label=".form-select-lg example"
            value={value.category}
            name="category"
            id="category"
            onChange={handleformvalue}
          >
            <option value="">Select Category</option>
            {Category.map((category) => {
              return (
                <option value={category} key={category}>
                  {category}
                </option>
              );
            })}
          </select>
          <small className={errors.category ? "form-text text-danger" : ""}>
            {errors.category}
          </small>
        </div>
     

        <div className="mt-3 mb-3">
          <label htmlFor="description">
            Description (Introduce your company in brief)
          </label>
          <ReactQuill
            name={"description"}
            value={value.description}
            onChange={(content) => {
              handleformvalue({
                target: { name: "description", value: content },
              });
            }}
            modules={Introduction.modules}
            theme="snow"
          />
          {errors.description && (
            <div className="invalid-feedback d-block">{errors.description}</div>
          )}
        </div>

        <div className="d-flex gap-1 gap-md-5 flex-column flex-md-row">
          <FormSelectB
            value={value.province}
            handleChange={handleformvalue}
            list={allProvince}
            name={"province"}
            error={errors.province ? true : false}
            helpertext={errors.province}
            label={"Province"}
            disabled={false}
            placeholder={"Open To Select Province"}
          />

          <div className="w-100">
            <label htmlFor="">city</label>
            <select
              className={
                errors.city
                  ? "form-select form-select-lg rounded-0 py-2 rounded-2 is-invalid"
                  : "form-select form-select-lg rounded-0 py-2 rounded-2 "
              }
              aria-label=".form-select-lg example"
              value={value.city}
              name="city"
              onChange={handleformvalue}
              disabled={value.province.length > 1 ? false : true}
            >
              <option value="">Open To City</option>
              {value.province &&
                filteredcity.map((city, index) => {
                  return (
                    <option value={city.city} key={index}>
                      {city.city}
                    </option>
                  );
                })}
            </select>
            <small className={errors.city ? "form-text text-danger" : ""}>
              {errors.city}
            </small>
          </div>
        </div>
        <div className="d-flex gap-1 gap-md-5 flex-column flex-md-row">
          <FormInputB
            name={"establishedDate"}
            handleChange={handleformvalue}
            label={"Established date (Ex.2014)"}
            required={true}
            value={value.establishedDate}
            error={errors.establishedDate ? true : false}
            helpertext={errors.establishedDate}
            type={"date"}
            classname={" w-100  form-control"}
          />
          <FormInputB
            name={"postalcode"}
            handleChange={handleformvalue}
            label={"Postal Code"}
            type={"number"}
            required={true}
            error={errors.postalcode ? true : false}
            helpertext={errors.postalcode}
            value={value.postalcode}
            classname={" w-100  form-control"}
            placeholder={"Enter Postal Code"}
          />
        </div>
      </div>
      <h3 className="mt-3">Document upload</h3>
      <div className="bg-hover p-lg-5 p-3 rounded-4 w-100 m-0 rounded   row row-cols-1 row-cols-md-2 row-cols-lg-2">
        <div className="col d-flex flex-column">
          <label htmlFor="panfront">Company registration proof (Front)</label>
          <input
            type="file"
            name="panfront"
            id="panfront"
            accept="image/*"
            onChange={handleformvalue}
            className="py-2"
          />
          <img
            src={value.panfront}
            alt=""
            srcSet=""
            className={
              value.panfront ? "w-100 object-cover h-100 grid-box mt-2 " : "d-none"
            }
          />
          <small
            className={errors.panfrontfile ? "form-text text-danger mt-2" : ""}
          >
            {errors.panfrontfile}
          </small>
        </div>
        <div className="col d-flex flex-column">
          <label htmlFor="panback">Company registration proof (Back)</label>
          <input
            type="file"
            name="panback"
            id="panback"
            accept="image/*"
            onChange={handleformvalue}
            className="py-2"
          />
          <img
            src={value.panback}
            alt=""
            srcSet=""
            className={
              value.panback
                ? "w-100 object-cover h-100 grid-box mt-2"
                : "d-none"
            }
          />
          <small
            className={errors.panbackfile ? "form-text text-danger mt-2" : ""}
          >
            {errors.panbackfile}
          </small>
        </div>
      </div>
      <div className=" d-flex justify-content-end mt-3">
        <button className="btn-dark  ms-auto " onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Introduction;
