const pictureModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "rgba(0, 0, 0, 0.388)",
  boxShadow: 24,
  backdropFilter: "blur(20px)",
};
export {pictureModal}