import { createSlice } from "@reduxjs/toolkit";
import {
  RegisterAction,
  
  get_all_rental_data_by_user,
  get_flatmate_by_user,
  getProfessionalProfile,
  userEditProfessionalAction,
  getOrganizationProfile,
  userEditOrganizationAction,
  createHelpSupport,
  createContactUs,
  getBusinessProfile,
  userEditBusinessAction,
  editRentalByUser,
  editFlatemateByUser,
  ChangePasswordAction,
  ResetPasswordAction,
  sendOtpCodeAction,
  verifyOtpCodeAction,
} from "../actions/authAction";

import {
 
  adminRegisterAdminAction,
} from "../actions/admin/authAction";

const initialState = {
  user_rental_data: [],
  business_data: [],
  user_flatmate_data: {},
  organization_profile: {},
  professional_profile: {},
  organization_profile_loading: false,
  business_profile_loading: false,
  professional_profile_loading: false,
  edit_professional_by_user_loading: false,
  rental_loading: false,
  editRentalLoading: false,
  edit_flatemate_loading: false,
  flatemate_loading: false,
  loading: false,
  help_request_loading: false,
  contact_us_loading: false,
  change_password_loading: false,
  reset_password_loading: false,
  send_otp_verification_loading: false,
  verify_otp_verification_loading: false,
  editBusinessUserLoading: false,
  userEditOrganizationLoading: false,
 
  totalrentalViews: 0,
  totalbusinessViews: 0,
  error: "",
};

const AuthSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    showtoast: (state, action) => {
      state.error = action.payload.message;
    },

    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RegisterAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(RegisterAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(RegisterAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      .addCase(adminRegisterAdminAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminRegisterAdminAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(adminRegisterAdminAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })

      // change password
      .addCase(ChangePasswordAction.pending, (state, action) => {
        state.change_password_loading = true;
      })
      .addCase(ChangePasswordAction.fulfilled, (state, action) => {
        state.change_password_loading = false;
      })
      .addCase(ChangePasswordAction.rejected, (state, action) => {
        state.change_password_loading = false;
        state.error = action.payload.message;
      })

     

      // ResetPasswordAction
      .addCase(ResetPasswordAction.pending, (state, action) => {
        state.reset_password_loading = true;
      })
      .addCase(ResetPasswordAction.fulfilled, (state, action) => {
        state.reset_password_loading = false;
      })
      .addCase(ResetPasswordAction.rejected, (state, action) => {
        state.reset_password_loading = false;
        state.error = action.payload.message;
      })

      // sendOtpCodeAction
      .addCase(sendOtpCodeAction.pending, (state, action) => {
        state.send_otp_verification_loading = true;
      })
      .addCase(sendOtpCodeAction.fulfilled, (state, action) => {
        state.send_otp_verification_loading = false;
      })
      .addCase(sendOtpCodeAction.rejected, (state, action) => {
        state.send_otp_verification_loading = false;
        state.error = action.payload.message;
      })

      // verifyOtpCodeAction
      .addCase(verifyOtpCodeAction.pending, (state, action) => {
        state.verify_otp_verification_loading = true;
      })
      .addCase(verifyOtpCodeAction.fulfilled, (state, action) => {
        state.verify_otp_verification_loading = false;
      })
      .addCase(verifyOtpCodeAction.rejected, (state, action) => {
        state.verify_otp_verification_loading = false;
        state.error = action.payload.message;
      })

      // get all rental data by user
      .addCase(get_all_rental_data_by_user.pending, (state, action) => {
        state.rental_loading = true;
      })
      .addCase(get_all_rental_data_by_user.fulfilled, (state, action) => {
        const { rental, totalViewCount } = action.payload;
        state.rental_loading = false;
        state.user_rental_data = rental;
        state.totalrentalViews = totalViewCount;
      })
      .addCase(get_all_rental_data_by_user.rejected, (state, action) => {
        state.rental_loading = false;
        state.error = action.payload.message;
        state.user_rental_data = [];
      })

      // edit rental by user
      .addCase(editRentalByUser.pending, (state, action) => {
        state.editRentalLoading = true;
      })
      .addCase(editRentalByUser.fulfilled, (state, action) => {
        const { activeIndex } = action.payload;
        const { rental } = action.payload.data;
        state.user_rental_data[activeIndex] = rental;
        state.editRentalLoading = false;
      })
      .addCase(editRentalByUser.rejected, (state, action) => {
        state.editRentalLoading = false;
        state.error = action.payload.message;
      })

      // edit flatemate by user
      .addCase(editFlatemateByUser.pending, (state, action) => {
        state.edit_flatemate_loading = true;
      })
      .addCase(editFlatemateByUser.fulfilled, (state, action) => {
        const { flatmate } = action.payload;

        state.user_flatmate_data = flatmate;
        state.edit_flatemate_loading = false;
      })
      .addCase(editFlatemateByUser.rejected, (state, action) => {
        state.edit_flatemate_loading = false;
        state.error = action.payload.message;
      })

      // get flatmate by user
      .addCase(get_flatmate_by_user.pending, (state, action) => {
        state.flatemate_loading = true;
      })
      .addCase(get_flatmate_by_user.fulfilled, (state, action) => {
        const { flatmate } = action.payload;
        state.flatemate_loading = false;
        state.user_flatmate_data = flatmate;
      })
      .addCase(get_flatmate_by_user.rejected, (state, action) => {
        state.flatemate_loading = false;
        state.error = action.payload.message;
        state.user_flatmate_data = {};
      })

      // get professional profile
      .addCase(getProfessionalProfile.pending, (state, action) => {
        state.professional_profile_loading = true;
      })
      .addCase(getProfessionalProfile.fulfilled, (state, action) => {
        const { professional } = action.payload;
        state.professional_profile_loading = false;
        state.professional_profile = professional;
      })
      .addCase(getProfessionalProfile.rejected, (state, action) => {
        state.professional_profile_loading = false;
        state.professional_profile = {};
      })

      //userEditProfessionalAction
      .addCase(userEditProfessionalAction.pending, (state, action) => {
        state.edit_professional_by_user_loading = true;
      })
      .addCase(userEditProfessionalAction.fulfilled, (state, action) => {
        state.edit_professional_by_user_loading = false;
        const { professional } = action.payload;
        state.professional_profile = professional;
      })
      .addCase(userEditProfessionalAction.rejected, (state, action) => {
        state.edit_professional_by_user_loading = false;
        state.error = action.payload.message;
      })

      // get organization profile
      .addCase(getOrganizationProfile.pending, (state, action) => {
        state.organization_profile_loading = true;
      })
      .addCase(getOrganizationProfile.fulfilled, (state, action) => {
        const { organization } = action.payload;
        state.organization_profile_loading = false;
        state.organization_profile = organization;
      })
      .addCase(getOrganizationProfile.rejected, (state, action) => {
        state.organization_profile_loading = false;
        state.organization_profile = {};
      })

      //userEditOrganizationAction
      .addCase(userEditOrganizationAction.pending, (state, action) => {
        state.userEditOrganizationLoading = true;
      })
      .addCase(userEditOrganizationAction.fulfilled, (state, action) => {
        state.userEditOrganizationLoading = false;
        const { organization } = action.payload;
        state.organization_profile = organization;
      })
      .addCase(userEditOrganizationAction.rejected, (state, action) => {
        state.userEditOrganizationLoading = false;
        state.error = action.payload.message;
      })

      // get business profile
      .addCase(getBusinessProfile.pending, (state, action) => {
        state.business_profile_loading = true;
      })
      .addCase(getBusinessProfile.fulfilled, (state, action) => {
        state.business_profile_loading = false;
        const { business, totalViewCount } = action.payload;
        state.business_data = business;
        state.totalbusinessViews = totalViewCount;
      })
      .addCase(getBusinessProfile.rejected, (state, action) => {
        state.business_profile_loading = false;
        state.error = action.payload.message;
        state.business_data = [];
      })
      //userEditBusinessAction
      .addCase(userEditBusinessAction.pending, (state, action) => {
        state.editBusinessUserLoading = true;
      })
      .addCase(userEditBusinessAction.fulfilled, (state, action) => {
        state.editBusinessUserLoading = false;
        const { activeIndex } = action.payload;
        const { business } = action.payload.data;
        state.business_data[activeIndex] = business;
      })
      .addCase(userEditBusinessAction.rejected, (state, action) => {
        state.editBusinessUserLoading = false;
        state.error = action.payload.message;
      })

      // cretae help request
      .addCase(createHelpSupport.pending, (state, action) => {
        state.help_request_loading = true;
      })
      .addCase(createHelpSupport.fulfilled, (state, action) => {
        state.help_request_loading = false;
      })
      .addCase(createHelpSupport.rejected, (state, action) => {
        state.help_request_loading = false;
        state.error = action.payload.message;
      })

      //cretae contact us

      .addCase(createContactUs.pending, (state, action) => {
        state.contact_us_loading = true;
      })
      .addCase(createContactUs.fulfilled, (state, action) => {
        state.contact_us_loading = false;
      })
      .addCase(createContactUs.rejected, (state, action) => {
        state.contact_us_loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { showtoast, clearError } = AuthSlice.actions;
export default AuthSlice.reducer;
