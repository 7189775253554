import React from "react";
import styled from "styled-components";
import { Box, List, ListItem } from "@mui/material";
import { useMemo, useRef, useEffect } from "react";
const Wrapper = styled.div`
  position: relative;
  width: 200px;
  & > .select-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 2009;
    & > .select-list__item {
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
      }
    }
  }

  & > input {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    padding: 8px;
    border: none;
    border-left: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    font-size: 16px;
  }
`;
const SelectProfessional = React.memo(
  ({
    handleChange,
    currentvalue,
    error,
    placeholder,
    name,
    list,
    havelabel,
    label,
  }) => {
    const options = {
      value: "",
      open: false,
      search: "",
    };
    const [selected, setselect] = React.useState(options);
    const menuRef = useRef(null);

    useEffect(() => {
      // add event listener to document when component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // remove event listener when component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
    const toggleopen = (e) => {
      e.stopPropagation();
      setselect({ ...selected, open: true });
    };

    const handleClickOutside = (event) => {
      event.stopPropagation();

      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setselect({ ...selected, open: false });
      }
    };
    function handleSearchInputChange(event) {
      event.stopPropagation();

      const value = event.target.value;
      setselect({ ...selected, search: value });
    }

    const toggleclose = (e) => {
      e.stopPropagation();

      setselect({ ...selected, open: false, value: "" });
    };

    const onOptionSelect = (e, item) => {
      handleChange(name, item);
      toggleclose(e);
    };

    const filteredOptions = useMemo(() => {
      return list.filter((option) =>
        option.value.toLowerCase().includes(selected.search.toLowerCase())
      );
    }, [list, selected.search]);

    return (
      <div className="form-group w-100 mt-2">
        {havelabel && (
          <label htmlFor={name} className="mb-1">
            {label}
          </label>
        )}
        <Wrapper className="select-container w-100" onClick={toggleopen}>
          <Box
            className={
              error
                ? "d-flex py-2 form-select justify-content-between align-items-center is-invalid"
                : "d-flex py-2 form-select justify-content-between align-items-center"
            }
            sx={{
              outline: selected.open ? (error ? "" : "2px solid #799aed") : "",
              zIndex: 200,
            }}
          >
            <span className=" w-100">
              {currentvalue.length > 0 ? currentvalue : placeholder}
            </span>
            {/* <ArrowDropDownIcon /> */}
          </Box>
          <div
            className={
              selected.open
                ? "m-0 p-1  shadow shadow-lg select-list "
                : "d-none"
            }
            ref={menuRef}
          >
            <input
              type="text"
              placeholder="Search..."
              className="form-control mt-1 py-2"
              onChange={handleSearchInputChange}
              onFocus={(e) => e.stopPropagation()}
              autoFocus={true}
            />
            <List
              className="m-0 p-0 select-list__item bg-white"
              sx={{
                maxHeight: "100px",
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              {filteredOptions.map((item) => {
                return (
                  <ListItem
                    key={item.id}
                    onClick={(e) => {
                      const value = item.value;
                      onOptionSelect(e, value);
                    }}
                    role="button"
                    className="m-0 p-1"
                    sx={{
                      "&:hover": {
                        background: "#797979",
                        color: "white",
                      },
                    }}
                  >
                    {item.value}
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Wrapper>
        <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
          {error}
        </small>
      </div>
    );
  }
);

export default SelectProfessional;
