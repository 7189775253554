import React, { memo } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtectedRoute = memo(() => {
  const { isAuth } = useSelector((state) => state.auth_slice);

  return isAuth ? <Outlet /> : <Navigate to="/login" replace />;
});

export default AdminProtectedRoute;
