import { AddressAutofill } from "@mapbox/search-js-react";
import { Input, List, ListItem } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useRef, useEffect } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const InputLocation = ({
  onLocationSelect,
  location,
  placeholder,
  isBorder,
}) => {
  const [results, setResults] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);

  const [isFocused, setIsFocused] = useState(false);
  const boxRef = useRef(null);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleSearch = (query) => {
    onLocationSelect("location", query);

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // If search query is empty or null, clear the results
    if (!query) {
      setResults([]);
      return;
    }

    // Set a new timeout to trigger the request after 3 seconds
    const newTimeoutId = setTimeout(() => {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=ca&proximity=ip&types=postcode&access_token=pk.eyJ1Ijoicm9hZHdheW1hbiIsImEiOiJjbGY3ejR3ZjkwYnlrM3NudjJkYzgxcnRtIn0.jdReqoWAgSK93Ruy1iPRSQ`
        )
        .then((res) => {
          console.log(res.data.suggestions);
          setResults(res.data.suggestions);
        })
        .catch((error) => {
          console.log("Request error:", error.message);
        });
    }, 1500);

    // Store the timeout ID for later reference
    setTimeoutId(newTimeoutId);
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>

      <Box
        className={"position-relative w-100 custom-card "}
        sx={{
          zIndex: 1,
        }}
      >
        <Input
          disableUnderline
          autoComplete="shipping address-line1"
          onChange={(e) => handleSearch(e.target.value)}
          value={location}
          className="w-100 h-100"
          onFocus={handleFocus}
          type="text"
          placeholder={placeholder}
          ref={boxRef}
          sx={
            isBorder
              ? {
                  outline: "none",
                  border: isFocused ? "2px solid blue" : "1.5px solid black",
                  transition: "border-bottom 0.3s ease",
                  borderRadius: "5px",
                  padding: 0.5,
                }
              : {}
          }
        />
        <List
          className="position-absolute top-100 start-0 p-0 m-0 w-100"
          sx={{
            backgroundColor: "white",
          }}
        >
          {isFocused &&
            results.map((result, index) => (
              <ListItem
                key={result.id}
                role="button"
                className="m-0 p-1 d-flex w-100  align-items-center gap-1 "
                sx={{
                  minHeight: "3rem",
                  fontSize: "0.8rem",
                  "&:hover": {
                    background: "#797979",
                    color: "white",
                  },
                }}
                onClick={(e) => {
                  const value = result.place_name
                    ? result.place_name
                    : result.place_name;
                  e.stopPropagation();
                  setIsFocused(false);
                  onLocationSelect("location", value);
                }}
              >
                <div className="d-flex  align-items-start gap-1 w-100">
                  <LocationOnOutlinedIcon
                    sx={{
                      fontSize: "1.2em",
                    }}
                  />{" "}
                  <span>
                    {result.full_address
                      ? result.full_address
                      : result.place_formatted}
                  </span>
                </div>
              </ListItem>
              // <p key={result.id}>{result.full_address}</p>
            ))}
        </List>
      </Box>
    </>
  );
};

export default InputLocation;
