import { createAsyncThunk } from "@reduxjs/toolkit";

import * as event_api from "../../api/event_api";

// get all event
export const getAllEventAction = createAsyncThunk(
    "getAllEvent",
    async ({ keyword = "", page = 0 }, { rejectWithValue }) => {
        try {
            const response = await event_api.get_all_event(keyword, page);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// get event by id
export const getEventByIdAction = createAsyncThunk(
    "getEventById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await event_api.get_event_by_id(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);


