import { Box, Button, ButtonGroup } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAbroadStudyRequirementsAction } from "../../context/actions/abordStudyAction";
import { AfterCountry, BeforeCountry } from "./tabs/index";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const StudentRequirementsUser = () => {
  const [data, setData] = React.useState([]);

  // -----------------------------
  const dispatch = useDispatch();
  const { id } = useParams();

  React.useEffect(() => {
    dispatch(getAbroadStudyRequirementsAction({ id }));
  }, [id,dispatch]);

  const { abroadStudyRequirements } = useSelector((state) => ({
    abroadStudyRequirements: state.abroad_study_reducer.abroadStudyRequirements,
  }));

  React.useEffect(() => {
    if (abroadStudyRequirements?.country) {
      setData(abroadStudyRequirements?.categories);
    }
  }, [abroadStudyRequirements]);

  console.log(data);

  // -----------------------------

  const [activeButton, setActiveButton] = React.useState("Apply");


  const afterlist = React.useMemo(() => {
    return data?.filter((item) => item.phase === "after");
  }, [data]);

  const beforelist = React.useMemo(() => {
    return data?.filter((item) => item.phase === "before");
  }, [data]);
  if (
    (abroadStudyRequirements &&
      Object?.keys(abroadStudyRequirements).length === 0) ||
    abroadStudyRequirements === undefined
  ) {
    return <NotFoundPage />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
      className="container "
    >
      <div className="d-flex align-items-center fw-bold justify-content-between w-100 mt-3">
        <h4 className="fw-bold">
          Requirements for studying in {abroadStudyRequirements?.country}
        </h4>

        <div className="d-flex align-items-center gap-1">
          <ButtonGroup
            className="text-black mb-2"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button
              variant={activeButton === "Apply" ? "contained" : "outlined"}
              onClick={() => {
                setActiveButton("Apply");
              }}
            >
              <div className="comments d-flex align-items-center gap-1">
                Before {abroadStudyRequirements?.country}
              </div>
            </Button>
            <Button
              variant={activeButton === "After" ? "contained" : "outlined"}
              onClick={() => {
                setActiveButton("After");
              }}
            >
              <div className="clapped d-flex align-content-center gap-1">
                After {abroadStudyRequirements?.country}
              </div>
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <section>
        <div className={activeButton === "Apply" ? "" : "d-none"}>
          <BeforeCountry
            list={beforelist}
            country={abroadStudyRequirements?.country}
          />
        </div>
        <div className={activeButton === "After" ? "" : "d-none"}>
          <AfterCountry
            list={afterlist}
            country={abroadStudyRequirements?.country}
          />
        </div>
      </section>
    </Box>
  );
};

export default StudentRequirementsUser;
