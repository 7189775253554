import { LocationOn } from "@mui/icons-material";
import { Rating, Typography } from "@mui/material";
import React from "react";

const FeaturedBusiness = () => {
  return (
    <div className="d-flex align-items-center w-100 gap-2 mt-3 ">
      <div className="col-3 p-0 m-0  rounded rounded-3 overflow-hidden">
        <img
          src="https://images.unsplash.com/photo-1566073771259-6a8506099945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
          alt=""
          className=" object-cover  w-100 h-100"
          height={50}
          width={50}
        />
      </div>

      <div className="d-flex flex-column">
        <h5 className="m-0 p-0">Hotel York Corporation</h5>
        <span>
          <LocationOn fontSize="small" /> 123 Street America, New York
        </span>
        <div className="d-flex">
          <Rating
            size="small"
            name="half-rating-read"
            defaultValue={2.5}
            precision={0.5}
            readOnly
          />

          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginLeft: "0.2rem",
            }}
          >
            2.5 | <span className="text-decoration-underline ">6 REVIEWS</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBusiness;
