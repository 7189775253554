import { VerifiedOutlined } from "@mui/icons-material";
import { FormControl, OutlinedInput } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  sendOtpCodeAction,
  verifyOtpCodeAction,
} from "../../context/actions/authAction";
import { clearError } from "../../context/reducers/authSlice";

const Verification = () => {

     const dispatch = useDispatch();
  // open verification field
  const [open, setOpen] = React.useState(false);

  const { user_data, error } = useSelector((state) => ({
    user_data: state.user_reducer.user_data,
    error: state.user_reducer.error,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearError())
    }
  }, [error, dispatch]);

 

  const [sphone, setPhone] = React.useState({
    phone: "",
  });

  const handlePhoneChnage = (e) => {
    setPhone({
      ...sphone,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("phone", sphone.phone);
    dispatch(sendOtpCodeAction({ formData: formData, toast: toast }));
  };

  const [otp, setOtp] = React.useState({
    otp: "",
  });

  const handleOtpChnage = (e) => {
    setOtp({
      ...otp,
      [e.target.name]: e.target.value,
    });
  };

  const handleOtpSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("otp", otp.otp);
    formData.append("phone", sphone.phone);
    dispatch(verifyOtpCodeAction({ formData: formData, toast: toast }));
  };

  return (
    <div className="container mt-3">
      <span className="fs-1 fw-bold">Verification Status</span>

      <div class="card border border-secondary shadow-0 mb-3 w-75">
        <div class="card-body d-flex justify-content-between">
          <div>
            <h5>{user_data?.email}</h5>
            <p>Your email is verified</p>
          </div>
          <VerifiedOutlined fontSize="large" />
        </div>
      </div>
      <div class="card border border-secondary shadow-0 mb-3 w-75">
        <div class="card-body d-flex justify-content-between">
          <div>
            <h5>Phone number verification</h5>
            +1 |{" "}
            <span className="text-danger">Only support canadian number.</span>
            <div className="d-flex justify-content-between gap-2">
              <form onSubmit={handlePhoneSubmit}>
                <FormControl sx={{ width: "25ch" }}>
                  <OutlinedInput
                    onChange={handlePhoneChnage}
                    name="phone"
                    placeholder="+1 (266) 889 245"
                    size="small"
                  />
                </FormControl>
                <button
                  className="btn btn-outline-black"
                  onClick={() => setOpen(!open)}
                >
                  Send Code
                </button>
              </form>
            </div>
            {open && (
              <div className="mt-2">
                <FormHelperText id="my-helper-text">
                  OTP has been sent.
                </FormHelperText>
                <form onSubmit={handleOtpSubmit}>
                  <div className="d-flex gap-2">
                    <FormControl className="d-flex" sx={{ width: "25ch" }}>
                      <OutlinedInput
                        onChange={handleOtpChnage}
                        name="otp"
                        placeholder="Enter Code"
                        size="small"
                      />
                    </FormControl>
                    {/* <button className="btn btn-sm shadow-0 ">
                      Resend
                      <span className="text-danger ms-2">0:30</span>
                    </button> */}
                  </div>
                  <button className="btn btn-outline-black">Send Code</button>
                </form>
              </div>
            )}
          </div>
          {/* <VerifiedOutlined fontSize='large' /> */}
        </div>
      </div>
    </div>
  );
};

export default Verification;
