import React, { useEffect, useMemo, useCallback } from "react";
import {
  InputAdornment,
  OutlinedInput,
  SvgIcon,
  Typography,
} from "@mui/material";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import BlogSearchCard from "../../components/cards/blogcard/BlogSearchCard";
import Chip from "@mui/material/Chip";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getALLBlogsAction } from "../../context/actions/blogAction";

const BlogSearchResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const query = queryParams.get("q");
  const tags = queryParams.get("tags");


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newQuery = event.target.value;
      queryParams.set("q", newQuery);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  const topiclst = [
    "HTML",
    "html5",
    "Html Css",
    "Html5 Development",
    "Html5 Games",
    "Html Templates",
    "Html Tags",
    "Html Attributes",
  ];

  const [value, setValue] = React.useState("0");
  const [topics, setTopic] = React.useState(topiclst);
  const [recommended, setrecommended] = React.useState(topiclst);
  const [seealltopics, setseeall] = React.useState(false);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleSeeAll = () => {
    setseeall((prev) => !prev);
  };

  const tablst = ["Blogs"];
  const getBlogs = useCallback(
    () => dispatch(getALLBlogsAction({ keyword: query })),
    [dispatch, query]
  );

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  const { blogs, resultperpage, filterblogcount, loading, recommendedTags } =
    useSelector((state) => ({
      ...state.blog_reducer,
    }));

    const handleClear = () => {
      queryParams.delete("q");

      // clear the input
      

      getBlogs();
      
    }

  return (
    <div className="container">
      <section className="search-section d-flex flex-column  align-items-center gap-2 py-4">
        <h4 className="fw-bold">Blogs | Search your interested blogs 🍁</h4>
        <Box
          sx={{
            height: "50px",
            width: { xs: "100%", md: "40%" },
          }}
          className="mt-0 "
        >
          <OutlinedInput
            onKeyPress={handleKeyPress}
            className="h-100"
            defaultValue=""
            fullWidth
            placeholder="Search blogs, topics, questions, or news"
            sx={{
              border: "none",
              borderRadius: "40px",
            }}
            startAdornment={
              <InputAdornment position="start">
                <SvgIcon color="action" fontSize="small">
                  <MagnifyingGlassIcon />
                </SvgIcon>
              </InputAdornment>
            }
          />
        </Box>
      </section>

      <div className="row-cols-1  row-cols-lg-2 row  ">
        <section className="userblog-left col-lg-9 pe-lg-5">
          <h4>
            {
              // if qury has value then show the result else show show all blogs
              query ? (
                <>
                  Showing {filterblogcount} result for {query} <button onClick={handleClear} className="btn btn-outline-danger btn-sm shadow-0">
                    Clear search
                  </button>
                </>
              ) : (
                "Showing all blogs"
              )
            }
          </h4>

          <TabContext value={value} className="m-0 p-0">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                {tablst.map((item, index) => {
                  const value = index.toString();
                  return <Tab key={item} label={item} value={value} />;
                })}
              </TabList>
            </Box>
            <TabPanel value="0" className="m-0 p-0">
              <div className="d-flex flex-column w-100 ">
                {blogs.map((item) => {
                  return <BlogSearchCard item={item} key={item._id} />;
                })}
              </div>
            </TabPanel>
          </TabContext>
        </section>

        <Box
          sx={{
            borderLeft: "1px solid #e2dfdf",
          }}
          className="userblog-right col-lg-3 px-lg-4"
        >
          <Box
            className="position-sticky h-maxcontent d-none d-lg-block "
            sx={{
              top: "10%",
            }}
          >
            <Box>
              <span className="fs-6 fw-bold">Topic matching html</span>
              <div className="d-flex flex-wrap gap-2 mt-4">
                {recommendedTags
                  .slice(0, seealltopics ? topics.length : 6)
                  .map((item, index) => {
                    return (
                      <Chip
                        key={index}
                        label={item}
                        size="medium"
                        variant="filled"
                      />
                    );
                  })}
              </div>
              {recommendedTags.length > 6 && (
                <button
                  className="fs-bold text-success mt-4 border-0 bg-transparent"
                  onClick={toggleSeeAll}
                >
                  {!seealltopics ? "See all" : "See less"}
                </button>
              )}
            </Box>
            <Box className="mt-4">
              <span className="fs-6 fw-bold">Recommendations </span>
              <div className="d-flex flex-wrap gap-2 mt-4">
                {recommendedTags.map((item, index) => {
                  return (
                    <Chip
                      key={index}
                      label={item}
                      size="medium"
                      variant="filled"
                    />
                  );
                })}
              </div>
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default BlogSearchResult;
