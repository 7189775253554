import { createAsyncThunk } from "@reduxjs/toolkit";

import * as professional_api from "../../api/professional_api";


// create professional


export const CreateProfessionalAction = createAsyncThunk(
  "professional/create_professional",
  async ({data, toast, navigate}, { rejectWithValue }) => {
    try {
      const response = await professional_api.CreateProfessional(data);
      toast.success(response.data.message||"Create professional successfully");
      navigate("/profile");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);










// get professional detail
export const GetProfessionalDetailAction = createAsyncThunk(
    "professional/get_professional_detail",
    async (id , { rejectWithValue }) => {
        try {
            const response = await professional_api.GetProfessionalDetail(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


// crete professional Review
export const CreateProfessionalReviewAction = createAsyncThunk(
  "professional/create_professional_review",
  async ({ review, id }, { rejectWithValue }) => {
    try {
      const response = await professional_api.CreateProfessionalReview(
        review,
        id
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get all professinals
export const GetAllProfessionalAction = createAsyncThunk(
  "professional/get_professionals",
  async ({ keyword, page, city, provenience }, { rejectWithValue }) => {
    try {
      const response = await professional_api.GetAllProfessinals(
        keyword,
        page,
        city,
        provenience
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
