import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { MoreVert, CheckOutlined, CancelOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllAdminAction } from "../../../../context/actions/admin/authAction";
import { Drawer } from "@mui/material";
import AdminAdminDetail from "./adminAdminDetail/AdminAdminDetail";
export const AdminAdminsTable = ({ keyword }) => {
  const [activeindex, setActive] = React.useState(0);

  const columns = [
    {
      field: "profile",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
      headerName: "Profile",
    },
    { field: "FullName", headerName: "FullName", flex: 1 },
    { field: "email", headerName: "email", flex: 1 },
    { field: "Country", headerName: "Country", flex: 1 },
    

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <div>
            <Button
              size="medium"
              variant="outlined"
              onClick={handleButtonClick}
            >
              <MoreVert color="error" />
            </Button>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  // drawer toggle
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    dispatch(adminGetAllAdminAction({ keyword, page }));
  }, [dispatch, keyword, page]);

  // use useSelector to get data from redux store
  const {
    all_admins,
    resultperpage,
    totaladmincount,
    getAllAdminSuperAdminLoading,
  } = useSelector((state) => ({
    ...state.admin_reducer,
  }));

  // map user data to rows
  const all_user = all_admins.map((item, index) => ({
    id: item._id,
    index: index,
    profile: "https://i.pravatar.cc/300",
    FullName: `${item.firstName} ${item.lastName}`,
    email: item.email,
    Country: item.country,
  
  }));

  // return table
  return (
    <div className="">
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminAdminDetail
          data={all_admins[activeindex]}
          onClose={toggleDrawer}
        />
      </Drawer>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={all_user}
          columns={columns}
          pageSize={resultperpage}
          loading={getAllAdminSuperAdminLoading}
          page={page}
          rowCount={totaladmincount}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};
