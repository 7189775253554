import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";

const LayoutBox = styled("div")(({ theme }) => ({
  display: "none",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));
const RentalFilterSearch = ({
  handleSearch,

  rtype,
  handleOpen,
}) => {
  return (
    <LayoutBox
      className=" align-item-center justify-content-between rounded rounded-2"
      sx={{
        backgroundColor: "var(--hover-color)",
        padding: "1%  2% ",
        position: "sticky",
        top: "8.5vh",
        left: "3%",
        zIndex: "4",
      }}
    >
      <Box
        sx={{
          // borderRight: "1px solid black",
          borderRadius: "5px",
        }}
        className="d-flex flex-column px-2 w-maxcontent"
      >
        <span className="fs-6">I'm looking for</span>
        <div className="d-flex gap-1 align-items-center">
          <Typography
            variant="span"
            className="px-2 py-0 fw-bold"
            onClick={() => handleSearch("rentalType", "Rental")}
            role="button"
            sx={{
              borderRadius: "4px",
              border: rtype === "Rental" ? "1.5px solid black" : "",
            }}
          >
            Rental
          </Typography>
          <Typography
            variant="span"
            className="px-2 py-0 fw-bold"
            onClick={() => handleSearch("rentalType", "FlateMates")}
            role="button"
            sx={{
              borderRadius: "4px",
              border: rtype === "FlateMates" ? "1.5px solid black" : "",
            }}
          >
            FlateMates
          </Typography>

          <Typography
            onClick={() => handleSearch("rentalType", "TeamUp")}
            role="button"
            variant="span"
            className="px-2 py-0 fw-bold text-danger"
            sx={{
              borderRadius: "4px",
              border: rtype === "TeamUp" ? "1.5px solid black" : "",
            }}
          >
            Teamup
          </Typography>
        </div>
      </Box>
      <Box
        sx={{
          border: "2px solid grey",
        }}
        className="w-100  rounded rounded-3 d-flex align-items-center justify-content-between p-2 px-3"
        role="button"
        onClick={handleOpen}
      >
        <p>Filter rental, flatmates, teamup, price, location and many more</p>
        <Box className=" d-flex justify-content-center gap-1 align-items-center ">
          <Button
            sx={{
              blackgroundColor: "white",
              color: "black",
              border: "1.5px solid grey",
              borderRadius: "5px",
              padding: "10px 20px",
              fontSize: "16px",
              fontWeight: 600,
              boxShadow: "none",
            }}
            className="d-flex gap-1 align-items-center px-2 py-2  rounded rounded-2 h-maxcontent bg-white btn text-black  shadow-1 "
          >
            <TuneIcon />
            <Typography
              variant="span"
              sx={{
                fontSize: "0.8rem",
              }}
            >
              Filter
            </Typography>
          </Button>
          {/* <button className="btn-dark w-maxcontent" onClick={onSearch}>
            Search
          </button> */}
        </Box>
      </Box>
    </LayoutBox>
  );
};

export default RentalFilterSearch;
