import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "100vh",
      }}
      className="d-flex align-items-center justify-content-center  w-100 "
    >
      <Box
        sx={{
          width: { xs: "97vw", md: "70vw", lg: "50vw" },
        }}
        className=""
      >
        <Typography
          sx={{
            fontSize: {
              xs: "calc(1.7rem + 5vw)",
              sm: "3rem",
              md: "6rem",
              lg: "7rem",
            },
            fontWeight: "700",
          }}
          className=" text-black-50 p-0 m-0"
        >
          404
        </Typography>
        <div className="d-flex flex-column gap-2">
          <Typography
            sx={{
              fontSize: {
                xs: "clamp(0.7rem, 0.8rem + 3vw , 1.5rem)",
                lg: "1.5rem",
              },
            }}
            className="fw-bold text-black"
          >
            Opps! this Page cound not be found
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: "clamp(0.5rem, 0.7rem + 3vw , 1rem)",
                lg: "1rem",
              },
            }}
          >
            {" "}
            SORRY BUT THE PAGE YOU ARE LOOKING FOR DOES NOT EXIST,HAVE BEEN
            REMOVED NAME CHANGED OR IS TEMPORARILY UNAVAIALABLE
          </Typography>

          <button
            style={{
              backgroundColor: "#5c91fe",
              color: "white",
              fontSize: { xs: "0.8rem", lg: "1rem" },
            }}
            className="w-maxcontent btn"
            onClick={() => navigate("/")}
          >
            GO TO HOMEPAGE
          </button>
        </div>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
