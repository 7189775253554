import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
// import { createTransform } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import user_reducer from "../context/reducers/authSlice";
import organization_reducer from "../context/reducers/organizationSlice";
import admin_reducer from "../context/reducers/adminSlice";
import business_reducer from "../context/reducers/businessSlice";
import blog_reducer from "../context/reducers/blogSlice";
import rental_reducer from "../context/reducers/rentalSlice";
import professional_reducer from "../context/reducers/professionalSlice";
import abroad_study_reducer from "../context/reducers/abrodStudySlice";
import event_reducer from "../context/reducers/eventSlice";
import faq_reducer from "../context/reducers/faqSlice";
import auth_slice from "../context/reducers/authenticateSlice";
import ads_reducer from "../context/reducers/alladsSlice";
import notification_reducer from "../context/reducers/notificationSlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth_slice"],
};

const reducers = combineReducers({
  ads_reducer,
  auth_slice,
  user_reducer,
  organization_reducer,
  admin_reducer,
  business_reducer,
  blog_reducer,
  rental_reducer,
  professional_reducer,
  abroad_study_reducer,
  event_reducer,
  faq_reducer,
  notification_reducer,
  
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools:false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
