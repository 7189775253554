import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Button, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormSelectB from "../../../../../components/Input/FormSelectB";
import {
  adminCreateBlogAction,
} from "../../../../../context/actions/admin/blogAction";

import { toast } from "react-toastify";
import { adminblogPic } from "../../../../../api/admin/blogApi";
import blogcategories from "../../../../../utils/all_blog_category";

class EditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),

    };
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    const contentState = editorState.getCurrentContent();
    const contentHtml = stateToHTML(contentState);
    this.props.handleChangeContent(contentHtml);
  };

  render() {
    const { editorState } = this.state;

    async function uploadImageCallBack(file) {
      try {
        const formData = new FormData();
        formData.append("image", file);
        const response = await adminblogPic(formData);
        const fullURL = `${process.env.REACT_APP_IMAGE_PATH}${response.data}`;
        console.log(response.data);
        return Promise.resolve({ data: { link: fullURL } });
      } catch (error) {
        console.error(error.response.data);
        return Promise.reject(error.response.data);
      }
    }

    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: true },
          },
        }}
      />
    );
  }
}

const AddBlog = ({ closemodal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const value = {
    title: "",
    category: "",
    thumbnail: "",
    description: "",
    content: "",
    tags: [],
    status: "draft",
    visibility: "public",
  };

  const [formvalue, setformvalue] = React.useState(value);
  const [errors, seterrors] = React.useState({});
  const [thumbnailpreview, setthumbnailpreview] = React.useState(null);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "thumbnail") {
      const file = e.target.files[0];
      const blobURL = URL.createObjectURL(file);
      setformvalue({ ...formvalue, [name]: file });
      setthumbnailpreview(blobURL);
    } else if (name === "tags") {
      const tag = value.trim();
      if (tag !== "" && !formvalue.tags.includes(tag) && e.key === "Enter") {
        setformvalue({
          ...formvalue,
          tags: [...formvalue.tags, tag],
        });
        e.target.value = "";
      }
    } else {
      setformvalue({ ...formvalue, [name]: value });
    }
  };

  const removetags = (value) => {
    const lst = formvalue.tags;
    const newlst = lst.filter((item) => item !== value);
    setformvalue({
      ...formvalue,
      tags: newlst,
    });
  };

  const createBlog = (e) => {
    e.preventDefault();

    if (
      formvalue.title === "" ||
      formvalue.content === "" ||
      formvalue.category === ""
    ) {
    } else {
      dispatch(
        adminCreateBlogAction({
          formvalue: formvalue,
          toast: toast,
          navigate: navigate,
        })
      );
    }
  };

  const publishBlog = (e) => {
    e.preventDefault();
    if (
      formvalue.title === "" ||
      formvalue.content === "" ||
      formvalue.category === ""
    ) {
    } else {
      const formdata = new FormData();
      formdata.append("title", formvalue.title);
      formdata.append("category", formvalue.category);
      formdata.append("thumbnail", formvalue.thumbnail);
      formdata.append("description", formvalue.description);
      formdata.append("content", formvalue.content);
      formdata.append("tags", formvalue.tags);
      formdata.append("status", status);
      dispatch(
        adminCreateBlogAction({
          formvalue: formdata,
          toast: toast,
          navigate: navigate,
        })
      );
    }
  }

  const handleChangeContent = (value) => {
    setformvalue({ ...formvalue, content: value });
  };

  // change status
  const [status, setstatus] = React.useState("draft");
  const handleStatus = (e) => {
    setstatus(e.target.value);
  }

  return (
    <Box className="custom-container">
      <section className="mt-2">
        <div className="d-flex justify-content-between align-items-center w-100">
          <span className="display-6 text-black fw-bold">Create a blogs</span>
          <div className="d-flex align-items-center gap-2">
            <select className="form-control" onChange={handleStatus} name="" id="">
              <option value="draft">Draft</option>
              <option value="published">Publish</option>
            </select>
            <button onClick={publishBlog} className="btn shadow-0 btn-danger">
              Save
            </button>
          </div>
        </div>
      </section>

      <Box
        className="w-100 bg-black my-3"
        sx={{
          height: "1.5px",
        }}
      ></Box>
      <section className="row-cols-lg-2 row-cols-1 row">
        <div className="blog-left col-lg-8">
          <label htmlFor="title">Create title</label>
          <TextField
            name="title"
            id="title"
            placeholder="Create Your title"
            className="w-100 "
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <label className="mt-2" htmlFor="title">
            Short description
          </label>
          <TextField
            name="description"
            placeholder="Short description"
            className="w-100"
            size="small"
            onChange={(e) => handleChange(e)}
          />
          <div className="mt-2">
            <label htmlFor="category">Content of your blog ...</label>
            <EditorContainer handleChangeContent={handleChangeContent} />
          </div>
        </div>

        <div className="blog-right col-lg-4">
          <Box className="bg-hover rounded rounded-4 pb-2">
            <Box
              sx={{
                height: "170px",
              }}
            >
              <img
                src={
                  thumbnailpreview ??
                  "https://www.contentviewspro.com/wp-content/uploads/2017/07/default_image.png"
                }
                alt=""
                className="w-100 h-100 object-cover"
              />
            </Box>
            <input
              type="file"
              accept="image/*"
              name="thumbnail"
              onChange={(e) => handleChange(e)}
              className="d-none"
              id="thumbnail"
            />
            <label
              htmlFor="thumbnail"
              role="button"
              className=" d-flex text-center rounded rounded-4 d-flex justify-content-center  text-white mx-auto mt-2 bg-redish align-items-center gap-2 text-capitalize text-white "
              style={{
                height: "30px",
                backgroundColor: "#FF6464",
                width: "90%",
                "&:hover": {
                  backgroundColor: "#ff6464",
                },
              }}
            >
              Upload Thumbnail
            </label>
          </Box>

          <Box className="mt-3">
            <FormSelectB
              name="category"
              label={"category"}
              value={formvalue.category}
              list={blogcategories}
              error={errors.category ? true : false}
              disabled={false}
              handleChange={(e) => handleChange(e)}
              helpertext={errors.category}
              placeholder={"Select Category"}
            />
          </Box>

          <Box className="d-flex flex-column bg-hover p-2 mt-2 rounded rounded-4">
            <span className="fs-4 fw-bold">Status</span>
            <ul className="m-0 p-0">
              <li className="row row-cols-3">
                <FavoriteIcon /> <span>Blog status:</span> <span>Draft</span>{" "}
              </li>
              <li className="row row-cols-3">
                <FavoriteIcon /> <span>Visibility:</span> <span>Public</span>{" "}
              </li>
              <li className="row row-cols-3">
                <FavoriteIcon /> <span>Date:</span> <span>2023/03/28</span>{" "}
              </li>
            </ul>
          </Box>

          <Box className=" p-2 mt-2 rounded rounded-4">
            <div className="d-flex w-100 justify-content-between">
              <span className="fs-5 ">Create Tags</span>
              <TextField
                id=""
                label=""
                size="small"
                name="tags"
                placeholder="Enter Tag"
                className="rounded rounded-2"
                onKeyPress={(e) => handleChange(e)}
              />
            </div>
            <div className="d-flex flex-wrap gap-2">
              {formvalue.tags.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    label={item}
                    size="small"
                    variant="outlined"
                    onDelete={() => removetags(item)}
                  />
                );
              })}
            </div>
          </Box>
        </div>
      </section>
    </Box>
  );
};

export default AddBlog;
