import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import { FormInputB } from "../../../components/Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "97vw", sm: 400 },
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function ChangePassword({
  open,
  formvalue,
  handleClose,
  handleFormChnage,
  handleSubmit,
  perrors,
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">Change Password</span>
              <small>
                Give your <span className="text-danger">old password</span>,
                take a new one
              </small>
            </div>
            <div className="mt-2" noValidate>
              <FormInputB
                label={"Old password"}
                name={"oldpassword"}
                type={"password"}
                handleChange={handleFormChnage}
                value={formvalue.oldpassword}
                placeholder={"***************"}
                error={perrors.oldpassword ? true : false}
                helpertext={perrors.oldpassword}
              />
              <FormInputB
                label={"New password"}
                name={"newpassword"}
                type={"password"}
                handleChange={handleFormChnage}
                value={formvalue.newpassword}
                placeholder={"***************"}
                error={perrors.newpassword ? true : false}
                helpertext={perrors.newpassword}
              />
              <FormInputB
                label={"Confirm password"}
                name={"confirmPassword"}
                type={"password"}
                handleChange={handleFormChnage}
                value={formvalue.confirmPassword}
                placeholder={"***************"}
                error={perrors.confirmPassword ? true : false}
                helpertext={perrors.confirmPassword}
              />
              <button
                onClick={handleSubmit}
                className="btn btn-dark mt-2 w-100"
              >
                Save Changes
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
