import { Box } from "@mui/material";
import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import { GiBroccoli } from "react-icons/gi";
import { ImCross } from "react-icons/im";

const FeatureCard = ({ item, features, handleFormValue }) => {
  const { icon, name } = item;
  const selected = React.useMemo(
    () => features.some((value) => value === name),
    [name, features]
  );

  const addOffer = () => {
    const newfeatures = [...features];
    if (selected) {
      const index = newfeatures.indexOf(name);
      if (index > -1) {
        newfeatures.splice(index, 1);
      }
    } else {
      newfeatures.push(name);
    }
    handleFormValue("features", newfeatures);
  };

  return (
    <Box
      onClick={addOffer}
      sx={{
        height: { xm: "100px", lg: "100px" },
        width: "200px",
      }}
      role="button"
      className={
        selected
          ? "d-flex flex-column position-relative  border-dark text-lg-start border border-3 justify-content-center align-items-start text-center p-2"
          : "d-flex flex-column position-relative  text-lg-start border border-3 justify-content-center align-items-start text-center p-2"
      }
    >
      <div className="">{icon}</div>
      <p>{name}</p>

      <CheckCircleOutlineIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};
const Feature = ({ feature, onClose, id }) => {
  const value = {
    features: ["Have Children", "Have Pets", "None Smoker"],
  };
  const evalue = {
    features: "",
  };
  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);

  const handleFormValue = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const onSubmit = () => {
  
  };

  const list = [
    {
      id: "1",
      name: "Have Children",
      icon: <FamilyRestroomIcon style={{ fontSize: "3em" }} />,
    },

    {
      id: "2",
      name: "Have Pets",
      icon: <PetsIcon style={{ fontSize: "3em" }} />,
    },

    {
      id: "3",
      name: "None Smoker",
      icon: <SmokingRoomsIcon style={{ fontSize: "3em" }} />,
    },

    {
      id: "4",
      name: "Smoker",
      icon: <SmokeFreeIcon style={{ fontSize: "3em" }} />,
    },

    {
      id: "5",
      name: "Vegetarian",
      icon: <GiBroccoli style={{ fontSize: "3em" }} />,
    },
  ];
  return (
    <div className="d-flex flex-column gap-2 ">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Personal FlateMate Dashboard
        </span>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>
      <div className="d-flex flex-wrap mt-2 gap-2">
        {list.map((item) => {
          return (
            <FeatureCard
              item={item}
              key={item.id}
              features={formValue.features}
              handleFormValue={handleFormValue}
            />
          );
        })}
      </div>

      <button
        className="btn-dark btn ms-auto justify-self-end"
        onClick={onSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default Feature;
