

import ProtectApi from "./adminAuthorizeApi";

export const adminCreateBlog = (data) => ProtectApi.post("/api/admin/blog/create", data);

export const adminEditBlog = (id, data) =>
  ProtectApi.put(`/api/admin/blog/edit_blog/${id}`, data);
export const adminGetAllBlog = (keyword = "", category = "", page=1) =>
  ProtectApi.get(
    `/api/admin/blog/get_all_blogs/?keyword=${keyword}&category=${category}&page=${page}`
  );
export const adminblogPic = (data) =>ProtectApi.post("/api/admin/blog/uploadblogpic", data);

// get blog by id
export const adminGetBlogById = (id) => ProtectApi.get(`/api/admin/blog/get_blog_details/${id}`);
// featured admin blog
export const adminFeaturedBlog = (id,data) =>
  ProtectApi.put(`/api/admin/blog/featured_blog/${id}`,{
   featured: data,
  }
);

// get all featured blogs

export const adminGetAllFeaturedBlog = () =>
  ProtectApi.get(`/api/admin/blog/get_all_featured_blogs`);
