import React from "react";
import { Box } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import { HouseOutlined } from "@mui/icons-material";
const LookingForCard = ({ name }) => {
  const list = [
    {
      id: "1",
      name: "Whole Property",
      icon: <HomeOutlinedIcon style={{ fontSize: "2.6em" }} />,
    },

    {
      id: "2",
      name: "Shared Room",
      icon: <BedOutlinedIcon style={{ fontSize: "2.6em" }} />,
    },

    {
      id: "3",
      name: "Student Accomondation",
      icon: <SchoolOutlinedIcon style={{ fontSize: "2.6em" }} />,
    },

    {
      id: "4",
      name: "Separate bed room",
      icon: <HotelOutlinedIcon style={{ fontSize: "2.6em" }} />,
    },
  ];
  // const icon = list.find((item) => item.name === name)?.icon;

  return (
    <Box
      sx={{
        fontSize: "1em",
        height: "80px",
      }}
      role="button"
      className="d-flex p-3 rounded rounded-4 flex-column align-items-start justify-content-center border border-2 border-dark"
    >
      <span><HouseOutlined/></span>
      <span className="text-nowrap">{name}</span>
    </Box>
  );
};

export default LookingForCard;
