import { createAsyncThunk } from "@reduxjs/toolkit";

import * as rental_api from "../../api/rental_api";

//create rental
export const createRentalAction = createAsyncThunk(
  "rental/create_rental",
  async ({ data, toast , navigate}, { rejectWithValue }) => {
    try {
      const response = await rental_api.create_rental(data);
      toast.success(response.data.message||"Create rental successfully");
      navigate("/profile");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all rental
export const getAllRentalAction = createAsyncThunk(
  "rental/get_all_rental",
  async (
    {
      keyword,
      page,
      houseType,
      minPrice,
      maxPrice,
      accessTo,
      minimumStay,
      availableDate,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await rental_api.get_all_rental(
        keyword,
        page,
        houseType,
        minPrice,
        maxPrice,
        accessTo,
        minimumStay,
        availableDate
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//get_all_featured_rental
export const getAllFeaturedRentalAction = createAsyncThunk(
  "rental/get_all_featured_rental",
  async (_, { rejectWithValue }) => {
    try {
      const response = await rental_api.get_all_featured_rental();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get rental detail
export const getRentalDetailAction = createAsyncThunk(
  "rental/get_rental_detail",
  async (id, { rejectWithValue }) => {
    try {
      const response = await rental_api.get_rental_detail(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// send rentalmessage request

export const sendRentalMessageAction = createAsyncThunk(
  "rental/send_rental_message",
  async ({ formData ,toast }, { rejectWithValue }) => {
    try {
      const response = await rental_api.send_rental_mail_request(formData);
      toast.success(response.data.message || "Message sent successfully");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// create flatemate
export const createFlatemateAction = createAsyncThunk(
  "rental/create_flatemate",
  async ({ data, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await rental_api.create_flatemate(data);
      toast.success("Create flatemate successfully");
      navigate("/profile");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all flatemate
export const getAllFlatemateAction = createAsyncThunk(
  "rental/get_all_flatemate",
  async (
    {
      houseType,
      minPrice,
      maxPrice,
      minAge,
      maxAge,
      lookingFor,
      lengthOfStay,
      moveInDate,
      accomondationType,
      preferedLocations,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await rental_api.get_all_flatemate(
        houseType,
        minPrice,
        maxPrice,
        minAge,
        maxAge,
        lookingFor,
        lengthOfStay,
        moveInDate,
        accomondationType,
        preferedLocations
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get flatemate detail
export const getFlatemateDetailAction = createAsyncThunk(
  "rental/get_flatmate_details",
  async (id, { rejectWithValue }) => {
    try {
      const response = await rental_api.get_flatmate_details(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
