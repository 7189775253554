import { MoreVert } from "@mui/icons-material";
import { Button, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllHelpSupportAction } from "../../../../context/actions/admin/helpsupportAction";
import AdminHelpAndSupportDetail from "./adminHelpAndSupportDetail/AdminHelpAndSupportDetail"
const AdminHelpAndSupportTable = ({ keyword }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);

  const [activeindex, setActive] = React.useState(0);
  const [status, setStatus] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const {
    help_support,
    resultperpage,
    totalhelpandsupportcount,
    help_and_support_loading,
  } = useSelector((state) => ({
    ...state.admin_reducer,
  }));

  const mappedRows = help_support?.map((help, index) => ({
    id: help._id,
    index: index,
    FullName: help?.firstName,
    Topic: help?.topic,
    Email: help?.email,
    Phone: help?.phone,
    Severity: help?.severity,
    Status: help?.status || "Pending",
  }));

  React.useEffect(() => {
    dispatch(adminGetAllHelpSupportAction({ keyword, page, status }));
  }, [dispatch, page, keyword]);

  const columns = [
    { field: "FullName", headerName: "FullName", flex: 1 },
    { field: "Topic", headerName: "Topic", flex: 1 },
    { field: "Email", headerName: "Email", flex: 1 },
    { field: "Phone", headerName: "Phone", flex: 1 },
    { field: "Severity", headerName: "Severity", flex: 1 },
    { field: "Status", headerName: "Status", flex: 1 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <Button
            size="medium"
            variant="outlined"
            onClick={handleButtonClick}
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];
  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={help_and_support_loading}
          page={page}
          rowCount={totalhelpandsupportcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminHelpAndSupportDetail
          data={help_support[activeindex]}
          activeindex={activeindex}
          id={help_support[activeindex]?._id}
          onClose={toggleDrawer}
        />
      </Drawer>
    </div>
  );
};

export default AdminHelpAndSupportTable;
