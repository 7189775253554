import { createSlice } from "@reduxjs/toolkit";

import {
  ShowBlogDetailsAction,
  getALLBlogsAction,
  blogPostComment,
  postLikeInBlog,
  getAllFeaturedBlogsAction
} from "../actions/blogAction";

const initialState = {
  blogs: [],
  featured_blogs: [],
  blogs_details: {},
  comments: [],
  like: [],
  recommendedTags: [],
  recommendedBlogs: [],
  resultperpage: 0,
  filterblogcount: 0,
  totalBlogCount: 0,
  loading: false,
  commentloading: false,
  likeLoading: false,
  allBlogsLoading: false,
  allFeaturedBlogsLoading: false,

  error: "",
  msg: "",
  new_data: {},
};

const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getALLBlogsAction.pending, (state) => {
        state.allBlogsLoading = true;
      })
      .addCase(getALLBlogsAction.fulfilled, (state, action) => {
        const {
          blogs,
          resultperpage,
          totalBlogCount,
          filterblogcount,
          recommendedTags,
        } = action.payload;
        state.blogs = blogs;
        state.resultperpage = resultperpage;
        state.totalBlogCount = totalBlogCount;
        state.filterblogcount = filterblogcount;
        state.recommendedTags = recommendedTags;
        state.allBlogsLoading = false;
      })
      .addCase(getALLBlogsAction.rejected, (state, action) => {
        state.allBlogsLoading = false;
        state.blogs = [];
        state.error = action.payload.message;
      })
      .addCase(ShowBlogDetailsAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(ShowBlogDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        const { blog, recommendedBlogs } = action.payload;
        state.blogs_details = blog;
        state.comments = blog.comments;
        state.like = blog.likes;
        state.recommendedBlogs = recommendedBlogs;
      
      })
      .addCase(ShowBlogDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(blogPostComment.pending, (state) => {
        state.commentloading = true;
      })
      .addCase(blogPostComment.fulfilled, (state, action) => {
        state.commentloading = false;
        state.comments = action.payload.comments;
      })
      .addCase(blogPostComment.rejected, (state) => {
        state.commentloading = false;
      })
      .addCase(postLikeInBlog.pending, (state) => {
        state.likeLoading = true;
      })
      .addCase(postLikeInBlog.fulfilled, (state, action) => {
        state.likeLoading = false;
        state.like = action.payload.likes;
      })
      .addCase(postLikeInBlog.rejected, (state) => {
        state.likeLoading = false;
      })

    // get all featured blogs
    .addCase(getAllFeaturedBlogsAction.pending, (state) => {
      state.allFeaturedBlogsLoading = true;
    })
    .addCase(getAllFeaturedBlogsAction.fulfilled, (state, action) => {
      state.allFeaturedBlogsLoading = false;
      const { featured_blogs } = action.payload;
      state.featured_blogs = featured_blogs;
    })
    .addCase(getAllFeaturedBlogsAction.rejected, (state, action) => {
      state.allFeaturedBlogsLoading = false;
      state.featured_blogs = [];
      state.error = action.payload.message;
    });




  },
});

export default blogSlice.reducer;
