import React from 'react'
import Skeleton from 'react-loading-skeleton'

const BusinessCardSkeleton = ({index}) => {
    return (
        <div key={index} className="p-2">
            <div className="shadow rounded rounded-1 col position-relative">
                <Skeleton height={200} />

                <div className="m-2 p-2">
                    <div>
                        <div className="d-flex">
                            <Skeleton width={60} height={15} />
                            <Skeleton
                                width={70}
                                height={15}
                                style={{ marginLeft: "0.2rem" }}
                            />
                        </div>
                    </div>

                    <h5 className="text-black fw-semibold pt-2">
                        <Skeleton width={200} height={20} />
                    </h5>

                    <Skeleton
                        width={200}
                        height={20}
                        style={{ marginTop: "0.1rem" }}
                    />

                    <div className="d-flex gap-1 flex-wrap">
                        <Skeleton width={70} height={20} />
                        <Skeleton width={100} height={20} />
                        <Skeleton width={80} height={20} />
                        <Skeleton width={100} height={20} />
                        <Skeleton width={80} height={20} />
                        <Skeleton width={80} height={20} />
                    </div>

                    <hr className="my-2" />

                    <div className="d-flex justify-content-between mb-2">
                        <span>
                            <Skeleton width={80} height={20} />
                        </span>

                        <div className="org-two_interaction d-flex gap-2 align-items-center">
                            <div className="d-flex text-black">
                                <Skeleton width={20} height={20} />
                            </div>
                            <div className="d-flex">
                                <Skeleton width={30} height={20} />
                                <Skeleton
                                    width={50}
                                    height={20}
                                    style={{ marginLeft: "0.2rem" }}
                                />
                            </div>
                        </div>
                    </div>

                    <Skeleton
                        className="d-none align-items-center justify-content-center likeicon"
                        width={35}
                        height={35}
                    />
                </div>
            </div>
        </div>
    )
}

export default BusinessCardSkeleton