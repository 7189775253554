import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/contactusApi";


export const adminGetAllContactAction = createAsyncThunk(
    "admin/get_all_contact",
    async ({ keyword = "", page = 0 }, { rejectWithValue }) => {
        try {
            const response = await admin_api.adminGetAllContactUs(keyword, page);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const adminChangeStatusContactAction = createAsyncThunk(
  "admin/change_status_contact",
  async ({ id, data, toast, activeIndex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminChangeStatusContactUs(id, data);
     toast.success("Status updated successfully");
      return { data: response.data, activeIndex: activeIndex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);




