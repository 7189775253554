import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Box,
  InputAdornment,
  OutlinedInput,
  Pagination,
  SvgIcon,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useCallback, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getALLBlogsAction, getAllFeaturedBlogsAction } from "../../context/actions/blogAction";

import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import BlogCard from "../../components/cards/blogcard/BlogCard";
import { ArrowBack, ArrowDropDown, ArrowForward } from "@mui/icons-material";
import blogcategories from "../../utils/all_blog_category";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.section`
  display: grid;
  gap: 1.5%;
  grid-template-columns: repeat(4, 1fr);



  & > .child-item:nth-child(1) {
    grid-column: 1 / span 2;
    height: 100%;
  }

  @media screen and (max-width: 940px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & > .child-item:nth-child(1) {
      grid-column: 1 / span 2;
      height: 100%;
      width: 100%;
    }
  }
`;

const BlogHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.value === "") {
      e.preventDefault();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const value = encodeURIComponent(event.target.value);
      navigate(`/blogs/result?q=${value}`);
    }
  };


  const sortbylst = ["Popularity", "Latest", "Asen", "Desc"];
  const [category, setcategory] = React.useState("");
  const [value, setValue] = React.useState("0");
  const [sortby, setsortby] = React.useState("Popularity");

  const [page, setPage] = React.useState(1);

  const handlePagechange = (event, value) => {
    setPage(value);
  };

  const tabchange = (index, newvalue) => {
    const tab = index.toString();
    if (newvalue === "All") {
      setValue(tab);
      setcategory("");
    } else {
      setValue(tab);
      setcategory(newvalue);
    }
  };

  // use callback to it will only re-created when the dispatch or category changes
  const getBlogs = useCallback(
    () => dispatch(getALLBlogsAction({ category, page })),
    [dispatch, page, category]
  );

  // call the getBlogs function when the component mounts
  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  useEffect(() => {
    dispatch(getAllFeaturedBlogsAction({}));
  },[]);

  // use useselector to get the blogs from the store and destructure it
  const {
    featured_blogs,
    blogs,
    resultperpage,
    totalBlogCount,
    allBlogsLoading,
  } = useSelector((state) => ({
    ...state.blog_reducer,
  }));

  return (
    <>
     <Helmet>
      <title>ONF CANADA Blogs: Study Permits, Studying in Canada, Documents, Immigration News</title>

      <meta name="description"
      content="Stay informed with the latest news and insights on study permits, studying in Canada, essential documents, immigration updates, and more through ONF CANADA Blogs. Get valuable information and updates to enhance your Canadian education journey." />

        <link rel="canonical" href="/register" />
      </Helmet>
      <div className="container">
        <Box
            className="headerimg position-relative rounded rounded-5 overflow-hidden mt-2"
            sx={{
              height: "350px",
            }}
          >
            <Box
              className="w-100 h-100 position-absolute top-0 start-0"
              sx={{
                zIndex: "1",
                background:
                  "linear-gradient(90deg, #000000 -1.46%, rgba(138, 91, 91, 0) 79%);",
              }}
            ></Box>
            <div
              id="carouselExampleControls"
              className="carousel slide h-100"
              data-mdb-ride="carousel"
            >
              <div className="carousel-inner h-100">
                {featured_blogs.map((blog, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                  >
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${blog?.thumbnail}`}
                      className="d-block w-100"
                      alt={blog.alt}
                    />
                  </div>
                ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-mdb-target="#carouselExampleControls"
                data-mdb-slide="prev"
              >
                <ArrowBack />
                <span aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-mdb-target="#carouselExampleControls"
                data-mdb-slide="next"
              >
                <ArrowForward />
                <span aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <Box
              className="d-flex flex-column position-absolute"
              sx={{
                top: "35%",
                left: "3%",
                zIndex: "2",
                fontSize: "1.3rem",
              }}
            >
              <h5 className="fw-bold text-white">Featured.</h5>
              <p className="text-white fs-6">
                Lorem ipsum dolor sit amet consectetur
              </p>
              <button
                type="button"
                className="btn btn-outline-white w-50 mt-3"
                data-mdb-ripple-color="dark"
              >
                Show More
              </button>
            </Box>
            


          
          </Box>
        <section className="search-section d-flex flex-wrap align-items-center gap-3 my-2">
          <Typography
            className="display-6 mt-3  text-nowrap  text-black"
            variant={"span"}
            sx={{
              fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
            }}
            
          >
            Blogs & News | Articles
            <p className="fs-6 fw-bold text-danger">Empower your knowledge</p>
          </Typography>
          <Box
            sx={{
              height: "50px",
              width : {xs : '100%' , sm : '100%' , md : '50%'}
            }}
            className="ms-auto "
          >
            <OutlinedInput
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              className="h-100"
              defaultValue=""
              fullWidth
              placeholder="Search Blogs..."
              sx={{
                border: "none",
                borderRadius: "10px",
              }}
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          </Box>
        </section>
        <section className="categories mb-2 d-flex flex-lg-row flex-column justify-content-between">
        <div className="d-flex gap-2 flex-wrap align-items-center">
            <Chip
              className="me-2"
              label={"For you"}
              variant={"All" !== category ? "outlined" : "filled"}
              onClick={() => tabchange(0, "All")}
            />
           
          </div>
          <div className="d-flex gap-2 flex-wrap align-items-center">
            {blogcategories.slice(0, 8).map((item, index) => (
              <Chip
                label={item}
                key={index}
                variant={item !== category ? "outlined" : "filled"}
                onClick={() => tabchange(index, item)}
              />
            ))}
            {blogcategories.length > 8 && (
              <div class="btn-group shadow-0 me-auto me-md-0">
                <Chip
                  type="button"
                  label="View More"
                  icon={<ArrowDropDown />}
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                </Chip>
                <ul class="dropdown-menu">
                  <Typography sx={{
                    overflowY: "scroll",
                    maxHeight: "200px",
                  }} >
                    {blogcategories.map((item, index) => {
                      return (
                        <li className="dropdown-item" key={index} onClick={() => tabchange(index, item)}>
                          {item}
                        </li>
                      );
                    })}
                  </Typography>
                </ul>

              </div>
            )}
          </div>

          <div className="ms-auto p-1">
            <div className="d-flex align-items-center text-nowrap gap-2">
              <span>Sort by:</span>{" "}
              <div className="dropdown">
                <strong
                  className="border-0 bg-transparent dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-mdb-toggle="dropdown"
                  aria-expanded="false"
                >
                  {sortby}
                </strong>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                  {sortbylst.map((item) => {
                    return (
                      <li key={item}>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setsortby(item)}
                        >
                          {item}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>


        <section>
          {allBlogsLoading ? (
            <>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4   ">
                {Array.from({ length: 8 }, (_, index) => (
                  <div key={index} className="p-1 col grid-box">
                    <div className="position-relative">
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="200px"
                      />
                      <div className="mt-3">
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="60%" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : blogs && blogs.length > 0 ? (
            <Wrapper>
              {blogs.map((blog, index) => (
                <div className="pt-3 child-item" key={blog._id}>
                  <BlogCard key={blog._id} item={blog} index={index} />
                </div>
              ))}
            </Wrapper>
          ) : (
            <NoResultFound />
          )}
        </section>
      </div>

      <Box
        className="d-flex justify-content-center mt-5"
        sx={{
          marginRight: { xs: '10px', sm: '6%', md: '6%' },
        }}
      >
        <Pagination
          count={Math.ceil(totalBlogCount / resultperpage)}
          page={page}
          onChange={handlePagechange}
          variant="outlined"
          className="ms-auto"
          shape="rounded"
        />
      </Box>
    </>
  );
};

export default BlogHome;
