import { createSlice } from "@reduxjs/toolkit";

import {
  GetAllCountry,
  getAbroadStudyDetailsAction,
  getAbroadStudyRequirementsAction,
} from "../actions/abordStudyAction";

const initialState = {
  countries: [],
  abroadStudyDetails: {},
  abroadStudyRequirements: {},
  totalCountryCount: 0,
  filterCountryCount: 0,
  resultPerPage: 0,
  loading: false,
  aborad_study_loading: false,
  error: "",
};

const abroadStudySlice = createSlice({
  name: "abroad_study",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllCountry.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetAllCountry.fulfilled, (state, action) => {
      const {
        countries,
        totalCountryCount,
        filterCountryCount,
        resultPerPage,
      } = action.payload;
      state.countries = countries;
      state.totalCountryCount = totalCountryCount;
      state.filterCountryCount = filterCountryCount;
      state.resultPerPage = resultPerPage;

      state.loading = false;
    });
    builder.addCase(GetAllCountry.rejected, (state, action) => {
      state.countries = [];
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(getAbroadStudyDetailsAction.pending, (state, action) => {
      state.aborad_study_loading = true;
    });
    builder.addCase(getAbroadStudyDetailsAction.fulfilled, (state, action) => {
      state.aborad_study_loading = false;
      const { studyabord } = action.payload;
      state.abroadStudyDetails = studyabord;
    });
    builder.addCase(getAbroadStudyDetailsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.aborad_study_loading = false;
    });

    // getAbroadStudyRequirementsAction
    builder.addCase(getAbroadStudyRequirementsAction.pending, (state, action) => {
      state.aborad_study_loading = true;
    });

    builder.addCase(getAbroadStudyRequirementsAction.fulfilled, (state, action) => {
      state.aborad_study_loading = false;
      const { abroad_study_requirements } = action.payload;
      state.abroadStudyRequirements = abroad_study_requirements;
    });

    builder.addCase(getAbroadStudyRequirementsAction.rejected, (state, action) => {
      state.error = action.payload;
      state.aborad_study_loading = false;
    });
  },
});

export default abroadStudySlice.reducer;
