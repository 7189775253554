import React from 'react'

const SetNewPassword = () => {
    const [newPassword, setNewPassword] = React.useState('')
    return (
        <div className='container'>
            <h4>
                Set New Password
            </h4>

            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">New password</label>
                        <input type="text" className="form-control" placeholder="New Password" onChange={
                            (e) => setNewPassword(e.target.value)
                        } />
                        <button className='btn btn-dark'>Save </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetNewPassword