import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SubjectIcon from "@mui/icons-material/Subject";
import WorkIcon from "@mui/icons-material/Work";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { Rating, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WcIcon from "@mui/icons-material/Wc";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
const AdminProfessionalIntro = ({ data }) => {
  const [readmore, setreadmore] = React.useState(false);

  const togglereadmore = () => {
    setreadmore((prev) => !prev);
  };
  return (
    <div>
      <h5 className="fw-bold">General Information</h5>
      <section className="d-flex flex-column gap-2 fs-5">
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <WcIcon />
            <span className="fs-6 ">Gender</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data?.gender} </p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <WorkIcon />
            <span className="fs-6 ">Profession</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data?.profession}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PermContactCalendarIcon />
            <span className="fs-6 ">Age</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data?.age}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <LocationOnIcon />
            <span className="fs-6">Province | City</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">
            {data?.provenience} | {data?.city}
          </p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <ReviewsIcon />
            <span className="fs-6">Ratings | Reviews</span>
            <span className="ms-auto">:</span>
          </div>
          <div className="d-flex align-items-center ">
            <Rating
              size="small"
              name="half-rating-read"
              value={data?.average_rating}
              precision={0.5}
              readOnly
            />
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "0.2rem",
              }}
            >
              {data?.average_rating} |{" "}
              <span className="text-decoration-underline ">
                {data?.totalReview} Reviews
              </span>
            </Typography>
          </div>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <VisibilityIcon />
            <span className="fs-6">Views</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data.viewCount}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-start">
            <SubjectIcon />
            <span className="fs-6">Description</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1">
            {!readmore ? data?.description.slice(0, 300) : data?.description}

            {!readmore ? (
              <strong role="button" onClick={togglereadmore}>
                ...readmore
              </strong>
            ) : (
              <strong role="button" onClick={togglereadmore}>
                ...readless
              </strong>
            )}
          </p>
        </div>
      </section>
    </div>
  );
};

export default AdminProfessionalIntro;
