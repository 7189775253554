import React from "react";

import { Box } from "@mui/material";
import BookmarkBlogCard from "../cards/BookmarkBlogCard";
const BlogBookmarks = ({ item, removeblog }) => {
  return (
    <Box
      sx={{
        minHeight: "90vh",
      }}
      className=" container w-100  p-0 "
    >
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gx-1 gx-sm-2 gx-md-3 gx-lg-4 gy-2 gy-sm-2 gy-md-3 gy-lg-4 ">
        {item.map((item) => {
          return (
            <div className="col" key={item._id}>
              <BookmarkBlogCard item={item} />
            </div>
          );
        })}
      </div>
      <div className="d-flex w-100 justify-content-end mt-auto">
        {/* <Stack spacing={2}>
          <Pagination count={10} variant="outlined" shape="rounded" />
        </Stack> */}
      </div>
    </Box>
  );
};

export default BlogBookmarks;
