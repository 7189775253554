import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { Button, ButtonGroup } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos, Delete, Edit } from '@mui/icons-material'


const AdminEventCard = ({ event, index, isActive }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const navigate = useNavigate();

  const formattedTime = (time) => {
    const originalDate = new Date(time);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(originalDate);
    return formattedDate;
  };


  return (
    <Box
      sx={{
        aspectRatio: "1/0.7",
      }}
      className="position-relative "
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        // to={'/events/details'}
        className="col"
      >
        <div
          id={`carouselBasicExample${index}`}
          className="carousel slide carousel-fade"
          data-mdb-ride="carousel"
        >
          <div className="carousel-inner">
           

            {event && event?.photos && (
              <div className="carousel-inner">
                {event?.photos?.map((photo, index) => (
                  <div
                    key={index}
                    className={
                      index === 0 ? "carousel-item active" : "carousel-item"
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${photo}`}
                      className="d-block w-100 rounded-2 object-cover"
                      alt="Sunset Over the City"
                      height={'240px'}
                      width={'180px'}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-mdb-target={`#carouselBasicExample${index}`}
            data-mdb-slide="prev"
          >
            <span
              aria-hidden="true"
            ><ArrowBackIos /></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-mdb-target={`#carouselBasicExample${index}`}
            data-mdb-slide="next"
          >
            <span

              aria-hidden="true"
            >
              <ArrowForwardIos />
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="card-body">
          <h6 className="card-title mt-2 text-black">{event?.eventName}</h6>
          <small>
          Date: {formattedTime(event?.date)}
          </small>
        </div>
      </Link>
      {isHovered && (
        <ButtonGroup
          className="mt-2"
          variant="outlined"
          aria-label="card-actions"
        >
          <Button>
            <Edit onClick={() => isActive(index)} />
          </Button>
          <Button>
            <Delete />
          </Button>
        </ButtonGroup>
      )}
    </Box>
  );
}

export default AdminEventCard