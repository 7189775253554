import { Button } from "@mui/material";
import { Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { CheckOutlined, CancelOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllContactAction } from "../../../../context/actions/admin/contactAction";
import { MoreVert } from "@mui/icons-material";
import AdminContactDetail from "./adminContactDetail/AdminContactDetail";


const AdminContactTable = ({ keyword }) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);
  const [status, setStatus] = React.useState(true);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const { contact, resultperpage, totalcontactcount, contact_loading } =
    useSelector((state) => ({
      ...state.admin_reducer,
    }));
  const mappedRows = contact?.map((contact, index) => ({
    id: contact._id,
    index: index,
    Fullname: contact?.fullname,
    Email: contact?.email,
    Phone: contact?.phone,
    Status: contact?.status || "Pending",
  }));
  React.useEffect(() => {
    dispatch(adminGetAllContactAction({ keyword, page, status }));
  }, [dispatch, page, keyword]);
  const columns = [
    { field: "Fullname", headerName: "Fullname", flex: 1 },
    { field: "Email", headerName: "Email", flex: 1 },
    { field: "Phone", headerName: "Phone", flex: 1 },
    { field: "Status", headerName: "Status", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <Button
            size="medium"
            variant="outlined"
            onClick={handleButtonClick}
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];
  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={contact_loading}
          page={page}
          rowCount={totalcontactcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminContactDetail
          data={contact[activeindex]}
         id={contact[activeindex]?._id}
         activeindex={activeindex}
          onClose={toggleDrawer}
        />
      </Drawer>
    </div>
  );
};

export default AdminContactTable;
