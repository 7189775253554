import React from "react";
import { Input, Typography, Box } from "@mui/material";
const AvailableDate = ({ availabeDate, handleFormValue, name, errors }) => {
  return (
    <div className="w-100">
      <div
        className={
          errors.length > 0
            ? "row-cols-2 row w-100 rounded-2 border-danger rounded border border-1 shadow shadow-danger py-2 px-0 m-0 bg-hover"
            : "row-cols-2 row w-100 rounded-2 rounded border border-2 py-2 px-0 m-0 bg-hover"
        }
      >
        <Typography
          variant="span"
          className="col-3  text-start p-0 ps-3"
          sx={{
            borderRight: "1px solid black ",
          }}
        >
          From
        </Typography>
        <div className="row row-cols-1 col-9">
          <Input
            className="  "
            value={availabeDate}
            type="date"
            name=""
            id=""
            disableUnderline
            onChange={(e) => handleFormValue(name, e.target.value)}
          />
        </div>
      </div>

      {errors.length > 0 ? (
        <small className="text-danger mt-1 ">{errors}</small>
      ) : null}
    </div>
  );
};

export default AvailableDate;
