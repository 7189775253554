import React from "react";
import {
  FormTextAreaB,
  FormInputList,
} from "../../../../../../components/Index";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ImCross } from "react-icons/im";
import tutionFeeFor from "../../../../../../utils/tutionFeeFor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CountryOverviewModal from "./CountryOverviewModal";
import { BiPencil } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { adminCreateStudentRequirementAction, adminCreateCountryOverviewAction } from "../../../../../../context/actions/admin/studentAction";
const CountryReviewRequirement = ({ country }) => {

  const dispatch = useDispatch();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: 400 },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  // ------------------------
  const countryOvereviews = {
    description: "",
    whyStudy: [{ text: "" }],
    requirements: [{ text: "" }],
    avgTution: [],
    costOfLiving: [],
    popularCourse: [{ text: "" }],
  };



  const [reviews, setReview] = React.useState(countryOvereviews);

  const handleCountryRequirement = (name, value) => {
    setReview({ ...reviews, [name]: value });
  };
  // -----------------------------------

  const [open, setOpen] = React.useState({
    state: "Add Tution Fee",
    open: false,
    index: 0,
  });

  const openTuitionModal = () => {
    setOpen({ ...open, open: true, state: "Add Tution Fee" });
  };
  const openCostModal = () => {
    setOpen({ ...open, open: true, state: "Add Cost of living" });
  };

  const [avgFees, setAvgFees] = React.useState({
    avgFees: "",
    course: "",
  });

  const handleClose = () => {
    setOpen({ ...open, open: false, state: "" });
    setAvgFees({ avgFees: "", course: "" });
  };

  const filterReviewCost = tutionFeeFor.filter(
    (item) =>
      !reviews.costOfLiving.some((value) => value.course === item.course)
  );
  console.log(filterReviewCost);
  const handleAvgFee = (e) => {
    setAvgFees({ ...avgFees, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (open.state === "Add Tution Fee") {
      let newlst = structuredClone(reviews.avgTution);
      newlst.push(avgFees);
      handleCountryRequirement("avgTution", newlst);
      handleClose();
    }
    if (open.state === "Edit Tution Fee") {
      let newlst = structuredClone(reviews.avgTution);
      newlst[open.index] = avgFees;
      handleCountryRequirement("avgTution", newlst);
      handleClose();
    }
    if (open.state === "Add Cost of living") {
      let newlst = structuredClone(reviews.costOfLiving);
      newlst.push(avgFees);
      handleCountryRequirement("costOfLiving", newlst);
      handleClose();
    }
    if (open.state === "Edit Cost of living") {
      let newlst = structuredClone(reviews.costOfLiving);
      newlst[open.index] = avgFees;
      handleCountryRequirement("costOfLiving", newlst);
      handleClose();
    }
  };
  const deleteAvgTution = (index) => {
    let newlst = structuredClone(reviews.avgTution);
    newlst.splice(index, 1);
    handleCountryRequirement("avgTution", newlst);
  };

  const onEditAvgTution = (item, index) => {
    const { avgFees, course } = item;
    setAvgFees({ avgFees: avgFees, course: course });
    setOpen({ ...open, open: true, state: "Edit Tution Fee", index: index });
  };
  const deleteCostofLiving = (index) => {
    let newlst = structuredClone(reviews.costofLiving);
    newlst.splice(index, 1);
    handleCountryRequirement("costofLiving", newlst);
  };

  const onEditCostofLiving = (item, index) => {
    const { avgFees, course } = item;
    setAvgFees({ avgFees: avgFees, course: course });
    setOpen({ ...open, open: true, state: "Edit Tution Fee", index: index });
  };
  const handleDescription = (e) => {
    if (reviews.description.length <= 200) {
      handleCountryRequirement("description", e.target.value);
    }
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleSaveChnage = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("description", reviews.description);
    data.append("whyStudy", JSON.stringify(reviews.whyStudy));
    data.append("requirements", JSON.stringify(reviews.requirements));
    data.append("avgTution", JSON.stringify(reviews.avgTution));
    data.append("costOfLiving", JSON.stringify(reviews.costOfLiving));
    data.append("popularCourse", JSON.stringify(reviews.popularCourse));
    data.append("country", country);
    dispatch(adminCreateCountryOverviewAction({ data }));



  }

  return (
    <Box sx={{
      height: "70vh",
    }} className="d-flex flex-column gap-2 position-relative">
      <hr />
      <div className="form-group">
      <Typography
          variant="label"
          color="initial"
          className="fw-bold text-black "
        >
          Description
        </Typography>
        <textarea
          className={"form-control mt-2"}
          id="textAreaExample"
          rows="4"
          maxLength={1000}
          data-mdb-showcounter="true"
          placeholder={"Enter Description"}
          onChange={handleDescription}
          name={"description"}
          type="text"
          value={reviews.description}
        ></textarea>

        <div className="d-flex justify-content-between align-content-center">
          <span className={"form-helper text-end ms-auto"}>
            {reviews.description?.length} / {1000}
          </span>
        </div>
      </div>

      <div className="">
        <Typography
          variant="label"
          color="initial"
          className="fw-bold text-black "
        >
          Why Study in {country}
        </Typography>
        <FormInputList
          name={"whyStudy"}
          list={reviews.whyStudy}
          handleChange={handleCountryRequirement}
          errors={{ description: "" }}
          listType={"disc"}
          minHeight={"100px"}
          placeholder={"why ?"}
        />
      </div>
      <div className="">
        <Typography
          variant="label"
          color="initial"
          className="fw-bold text-black "
        >
          Requirments
        </Typography>
        <FormInputList
          name={"requirements"}
          list={reviews.requirements}
          handleChange={handleCountryRequirement}
          errors={{ description: "" }}
          listType={"disc"}
          minHeight={"100px"}
          placeholder={"Enter requirments"}
        />
      </div>

      <section>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <Typography
            variant="label"
            color="initial"
            className="fw-bold text-black "
          >
            Average Tution
          </Typography>
          <button
            onClick={openTuitionModal}
            className="btn bg-black text-white h-maxcontent rounded-pill rounded"
          >
            + Add
          </button>
        </div>

        {reviews.avgTution.length > 0 && (
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "scroll",
              width: "100%",
            }}
          >
            <Table
              sx={{
                minWidth: "700px",
              }}
              className="table table-bordered text-center table-striped  table-hover"
            >
              <thead>
                <tr style={{ backgroundColor: "#6C7AE0", color: "white" }}>
                  <th scope="col-2">S.no.</th>
                  <th scope="col">
                    Average Tution Fee in {country} for international students
                  </th>
                  <th scope="col">AverageAnual fee</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reviews.avgTution.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.course}</td>
                      <td>{item.avgFees}</td>
                      <td className="d-flex align-items-center gap-1 justify-content-center align-items-start">
                        <Box
                          role="button"
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                          onClick={() => onEditAvgTution(item, index)}
                          className="bg-primary rounded rounded-2 text-white p-2 d-flex align-items-center justify-content-center"
                        >
                          <EditIcon className="text-white" />
                        </Box>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                          className="bg-danger rounded rounded-2 text-white p-2 d-flex align-items-center justify-content-center"
                          role="button"
                        >
                          <DeleteIcon onClick={() => deleteAvgTution(index)} />
                        </Box>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        )}
      </section>

      <section>
        <div className="d-flex w-100 align-items-center justify-content-between">
          <Typography
            variant="label"
            color="initial"
            className="fw-bold text-black "
          >
            Cost of living
          </Typography>
          <button
            onClick={openCostModal}
            className="btn btn-primary h-maxcontent rounded-pill rounded"
          >
            + Add
          </button>
        </div>

        {reviews.costOfLiving.length > 0 && (
          <Box
            sx={{
              overflow: "hidden",
              overflowX: "scroll",
              width: "100%",
            }}
          >
            <Table
              sx={{
                minWidth: "700px",
              }}
              className="table table-bordered text-center table-striped  table-hover"
            >
              <thead>
                <tr style={{ backgroundColor: "#6C7AE0", color: "white" }}>
                  <th scope="col-2">S.no.</th>
                  <th scope="col">
                    Average Tution Fee in {country} for international students
                  </th>
                  <th scope="col">AverageAnual fee</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {reviews.costOfLiving.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{index + 1}</th>
                      <td>{item.course}</td>
                      <td>{item.avgFees}</td>
                      <td className="d-flex align-items-center gap-1 justify-content-center align-items-start">
                        <Box
                          role="button"
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                          onClick={() => onEditCostofLiving(item, index)}
                          className="bg-primary rounded rounded-2 text-white p-2 d-flex align-items-center justify-content-center"
                        >
                          <EditIcon className="text-white" />
                        </Box>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                          className="bg-danger rounded rounded-2 text-white p-2 d-flex align-items-center justify-content-center"
                          role="button"
                        >
                          <DeleteIcon
                            onClick={() => deleteCostofLiving(index)}
                          />
                        </Box>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        )}
      </section>

      <section>
        <div className="">
          <Typography
            variant="label"
            color="initial"
            className="fw-bold text-black "
          >
            Popular course in {country}
          </Typography>
          <FormInputList
            name={"popularCourse"}
            list={reviews.popularCourse}
            handleChange={handleCountryRequirement}
            errors={{ description: "" }}
            listType={"disc"}
            minHeight={"100px"}
            placeholder={"Enter popular courses"}
          />
        </div>
      </section>

      <Modal
        open={open.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex align-items-center justify-content-center">
            <Typography
              id="modal-modal-title"
              className="text-center ms-auto"
              variant="h6"
              component="h2"
            >
              {open.state}
            </Typography>

            <Box
              className="bg-hover rounded rounded-circle ms-auto p-2 d-flex justify-content-center align-items-center"
              sx={{
                width: 40,
                height: 40,
              }}
              onClick={handleClose}
            >
              <ImCross className=" bg-hover rounded rounded-circle fs-5" />
            </Box>
          </div>

          <form
            action=""
            className="d-flex flex-column gap-2"
            onSubmit={onSubmit}
          >
            <div className=" form-group w-100">
              <label htmlFor="">Course</label>
              <select
                required={true}
                className={
                  "form-select rounded-0 form-select-lg  py-2 rounded-2"
                }
                aria-label=".form-select-lg example"
                value={avgFees.course}
                name={"course"}
                onChange={handleAvgFee}
              >
                <option value="">Select Course</option>
                {filterReviewCost.map((province) => {
                  return (
                    <option value={province} key={province}>
                      {province}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group mt-2 w-100">
              <label htmlFor="avgFee" className="mb-1 text-black">
                Average Tution Fee
              </label>
              <input
                type={"text"}
                className={"form-control py-2"}
                id={"avgFee"}
                name={"avgFees"}
                aria-describedby="emailHelp"
                placeholder={"Enter Average Tution Fee"}
                onChange={handleAvgFee}
                required={true}
                value={avgFees.avgFees}
              />
            </div>

            <button
              className="btn bg-black text-white w-100 mt-3 "
              type="submit"
            >
              Submit
            </button>
          </form>
        </Box>
      </Modal>
      <div className="position-sticky bottom-0 start-0 w-100 bg-white py-2">
        <button className="btn btn-dark w-100" onClick={handleSaveChnage}>
          Save Changes
        </button>
      </div>
      {/* <CountryOverviewModal handleModalOpen={openModal} /> */}
    </Box>
  );
};

export default CountryReviewRequirement;
