import { createAsyncThunk } from "@reduxjs/toolkit";
import * as blog_api from "../../api/blog_api";


// get all blogs
export const getALLBlogsAction = createAsyncThunk(
  "getAllBlogs",
  async ({ keyword,category,page }, { rejectWithValue }) => {
    try {
      const response = await blog_api.get_all_blogs(keyword, category, page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get blog details
export const ShowBlogDetailsAction = createAsyncThunk(
  "blog/show_blog_details",
  async ( id , { rejectWithValue }) => {
      try {
         
          const response = await blog_api.get_blog_details(id);
          return response.data;
      } catch (err) {
          return rejectWithValue(err.response.data);
      }
  }
);




export const blogPostComment = createAsyncThunk(
  "blog/post_comment_in_blog",
  async ({ id, comment }, { rejectWithValue }) => {
    try {
      const response = await blog_api.post_comment_in_blog(id, comment);
       return response.data.blog;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// post like in blog
export const postLikeInBlog = createAsyncThunk(
  "blog/post_like_in_blog",
  async(id, { rejectWithValue }) => {
    try {
      const response = await blog_api.post_like(id);
      return response.data.blog;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get all featured blogs

export const getAllFeaturedBlogsAction = createAsyncThunk(
  "blog/get_all_featured_blogs",
  async ( { rejectWithValue }) => {
    try {
      const response = await blog_api.get_all_featured_blogs();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);



