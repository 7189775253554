import React, { useEffect } from "react";

import { Button, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { AddAfterRequirements, AddBeforeRequirements } from "./tabs/index";

import { useDispatch } from "react-redux";
import { adminCreateStudentRequirementAction, adminGetTableByCuntryAction } from "../../../../../context/actions/admin/studentAction";
import CountryReviewRequirement from "./components/CountryReviewRequirement";
import { adminGetAllStudentRequirement } from "../../../../../api/admin/studentApi";

const AddStudentRequirements = () => {
  // modal style
  const dispatch = useDispatch();
  const { country } = useParams();

  useEffect(() => {
    dispatch(adminGetTableByCuntryAction({country:country}));
  }, [dispatch]);

  const editState = {
    isEdit: false,
    index: 0,
    files: [],
    title: "",
    description: [{ text: "" }],
  };

  const countryOvereviews = {
    description: "",
    whyStudy: [{ text: "" }],
    requirements: [{ text: "" }],
    avgTution: [],
    costOfLiving: [],
    popularCourse: [{ text: "" }],
  };
  const [reviews, setReview] = React.useState(countryOvereviews);

  const handleCountryRequirement = (name, value) => {
    setReview({ ...reviews, [name]: value });
  };

  const [activeButton, setActiveButton] = React.useState("Before");

  // stores Add/Edit Modal  state
  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState({
    country: country,
    category: [],
    phase: "Before",
  });
  const [afterlist, setAfterList] = React.useState({
    country: country,
    category: [],
    phase: "After",
  });
  const [editvalue, setEdit] = React.useState(editState);

  // open modal
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const addToList = (value) => {
    if (activeButton === "Before") {
      const newlist = structuredClone(list.category);
      newlist.unshift(value);
      const data = new FormData();
      data.append("title", value.title);
      data.append("description", JSON.stringify(value.description));
      data.append("country", country);
      data.append("name", value.name);
      for (let i = 0; i < value.attachment.length; i++) {
        data.append("attachment", value.attachment[i]);
      }
      data.append("phase", "before");
      dispatch(adminCreateStudentRequirementAction({ data }));

      setList((prev) => ({ ...prev, category: newlist }));
    } else {
      const newlist = structuredClone(afterlist.category);
      newlist.unshift(value);
      const data = new FormData();
      data.append("title", value.title);
      data.append("description", JSON.stringify(value.description));
      data.append("country", country);
      data.append("name", value.name);
      for (let i = 0; i < value.attachment.length; i++) {
        data.append("attachment", value.attachment[i]);
      }
      data.append("phase", "after");
      dispatch(adminCreateStudentRequirementAction({ data }));
      setAfterList((prev) => ({ ...prev, category: newlist }));
    }
    closeModal();
  };

  const onEdit = ({ isEdit, index, files, title, description, country }) => {
    setEdit({
      isEdit: isEdit,
      index: index,
      files: files,
      description: description,
      title: title,
      country: country,
    });

    handleClose();
  };

  const closeModal = () => {
    setOpen(false);
    setEdit(editState);
  };

  // value is a object
  const onEditSave = (value) => {
    if (activeButton === "Before") {
      const newlist = structuredClone(list.category);
      newlist[editvalue.index] = value;
      setList((prev) => ({ ...prev, category: newlist }));
      // dispatch(updateStudentRequirements(value));
    } else {
      const newlist = structuredClone(afterlist.category);
      newlist[editvalue.index] = value;
      setAfterList((prev) => ({ ...prev, category: newlist }));
    }
    closeModal();
  };

  return (
    <Container
      sx={{
        height: "90vh",
      }}
      className="d-flex flex-column gap-2 position-relative"
    >
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="heading">
          <h5 className="m-0 p-0">Requirements for</h5>
          <h3 className="fw-bold m-0 p-0">
            Studying in
            <span className="ms-1 text-danger">{country}</span>
          </h3>
        </div>

        <div className="d-flex align-items-center">
          <Button
            variant={activeButton === "Before" ? "contained" : "outlined"}
            onClick={() => {
              setActiveButton("Before");
            }}
            sx={{
              borderTopRightRadius: 0,
              borderbottomRightRadius: 0,
            }}
          >
            Before {country}
          </Button>
          <Button
            sx={{
              borderTopLeftRadius: 0,
              borderbottomLeftRadius: 0,
            }}
            variant={activeButton === "After" ? "contained" : "outlined"}
            onClick={() => {
              setActiveButton("After");
            }}
          >
            AFTER {country}
          </Button>
          <Button
            sx={{
              borderTopLeftRadius: 0,
              borderbottomLeftRadius: 0,
            }}
            variant={
              activeButton === "CountryOverview" ? "contained" : "outlined"
            }
            onClick={() => {
              setActiveButton("CountryOverview");
            }}
          >
            {country} Overview
          </Button>
        </div>
      </div>

      <div className={activeButton === "Before" ? "" : "d-none"}>
        <AddBeforeRequirements
          list={list.category}
          country={country}
          onEdit={onEdit}
          onEditSave={onEditSave}
          requirementType={activeButton}
          editvalue={editvalue}
          setEdit={setEdit}
          addToList={addToList}
          open={open}
          handleClose={handleClose}
          closeModal={closeModal}
          reviews={reviews}
          handleCountryRequirement={handleCountryRequirement}
        />
      </div>

      {activeButton === "After" && (
        <AddAfterRequirements
          list={afterlist.category}
          country={country}
          onEdit={onEdit}
          onEditSave={onEditSave}
          requirementType={activeButton}
          editvalue={editvalue}
          setEdit={setEdit}
          addToList={addToList}
          open={open}
          handleClose={handleClose}
          closeModal={closeModal}
          reviews={reviews}
          handleCountryRequirement={handleCountryRequirement}
        />
      )}
      <div className={activeButton === "CountryOverview" ? "" : "d-none"}>
        <CountryReviewRequirement country={country} />
      </div>
    </Container>
  );
};

export default AddStudentRequirements;
