import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, List, ListItem } from "@mui/material";
import { MdEditSquare } from "react-icons/md";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Link } from "react-router-dom";

const AccordionRequirementCard = ({ item, onEdit, index }) => {
  const { title, description, attachment } = item;
  console.log("item", item);

  const editvalue = () => {
    onEdit({
      isEdit: true,
      title: title,
      description: description,
      files: attachment,
      index: index,
    });
  };

  // const blobUrl = attachment?.map((item) => {
  //   return URL.createObjectURL(item);
  // });

  return (
    <div className="border border-1 shadow-2 rounded-2 w-100 p-0 m-0 border-dark ">
      <Accordion
        className="w-100 m-0 p-0"
        sx={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="fw-bold text-black">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className="position-relative"
          sx={{
            borderTop: "1px solid black",
          }}
        >
          <Box
            className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
            role="button"
            sx={{
              top: "10px",
              right: "2%",
              width: "1.9rem",
              height: "1.9rem",
              backgroundColor: "black",
              color: "white",
              zIndex: 3,
            }}
            onClick={editvalue}
          >
            <MdEditSquare />
          </Box>
          <List component="ol">
            {description.map((item, index) => {
              return <ListItem key={index}>{item.text}</ListItem>;
            })}
          </List>

          {attachment.length > 0 ? (
            <div className="">
              <Typography
                sx={{
                  color: "#10A37F",
                }}
                className="text-sucess d-flex align-items-center gap-2"
              >
                <AttachmentIcon /> Attachments
              </Typography>
              <div className="d-flex flex-column gap-2">
                {attachment.map((item, index) => {
                  return (
                    <Link
                      to={item}
                      className="w-maxcontent "
                      role="button"
                      target="_blank"
                      alt=""
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AccordionRequirementCard;
