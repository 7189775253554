// // import API from "./adminAuthorizeApi";

// import AuthApi from "../manage_access_route";

// import API from "../common_api";

import ProtectApi from "./adminAuthorizeApi";

export const adminCreateProfessional = (data) =>
  ProtectApi.post("/api/admin/professional/admin_create_professional", data);
export const adminGetAllProfessional = (keyword = "", page = 0 ,status ) =>
  ProtectApi.get(
    `/api/admin/professional/admin_get_all_professionals?keyword=${keyword}&page=${page}&status=${status}`
  );

// edit professional
export const adminEditProfessional = (id, data) =>
  ProtectApi.put(
    `/api/admin/professional/admin_update_professional/${id}`,
    data
  );


  //admin_change_status_professional
export const adminChangeProfessionalStatus = (id, data) =>
ProtectApi.put(`/api/admin/professional/admin_change_status_professional/${id}`, {
  active: data,
});

// featured professional

export const adminUpdateFeaturedProfessional = (id, data) =>
  ProtectApi.put(`/api/admin/professional/admin_featured_professional/${id}`, {
    featured: data,
  });

//admin_verify_professional
export const adminVerifyProfessional = (id, data) =>
ProtectApi.put(`/api/admin/professional/admin_verify_professional/${id}`, {
  status: data.status,
  active: data.active,
});

//admin_give_review_message_professional
export const adminGiveReviewProfessional = (id, data) =>
ProtectApi.put(`/api/admin/professional/admin_give_review_message_professional/${id}`, {
  reviewMessage: data,
});

