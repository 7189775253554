import React from "react";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";
import { TypeofProperty } from "./formstepper";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  TenantAccess,
  TentantLocation,
  PropertyOffer,
  RentalImageUpload,
  AboutProperty,
  PropertyPrice,
  RentalReview,
} from "./formstepper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createRentalAction } from "../../context/actions/rentalAction";
import { toast } from "react-toastify";
import { clearError } from "../../context/reducers/rentalSlice";

const AddRental = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useslector to get error from rental_reducer
  const { createRentalLoading, error } = useSelector((state) => ({
    error: state.rental_reducer.error,
    createRentalLoading: state.rental_reducer.createRentalLoading,
  }));

  // show toast when rental error is encountered
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  const formvalues = {
    location: "",
    title: "",
    houseType: "",
    accessTo: "",
    offers: [],
    bathroom: 0,
    kitchen: 0,
    bedroom: 0,
    price: 0,
    minimumStay: "6 month",
    availableDate: "",
    description: "",
  };

  const formerrors = {
    images: "",
    description: "",
    title: "",
    price: "",
    minimumStay: " ",
    availableDate: "",
    message: "",
  };

  // activesteo indcates current step of the form ( 1 - 7)
  const [activeStep, setActiveStep] = React.useState(0);

  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formvalues);
  // all the uploaded images are in images state
  const [images, setImages] = React.useState({ files: [], pImages: [] });
  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);
  // first uploaded image are saved in reviewImg state
  const [reviewImg, setReviewImg] = React.useState("");

  // handle house type
  const handleHouseType = (value) => {
    setformvalues({ ...formvalue, houseType: value });
  };
  // form validation returns true or false when next button clicked
  const formvalidation = () => {
    let isValid = false;
    if (activeStep === 0) {
      if (!formvalue.houseType) {
        setErrors({ ...errors, message: "Please Select House Types" });
      } else {
        isValid = true;
        setErrors(formerrors);
      }
    }
    if (activeStep === 1) {
      if (!formvalue.accessTo) {
        setErrors({ ...errors, message: "Please Select Any One Access" });
      } else {
        isValid = true;
        setErrors(formerrors);
      }
    }

    if (activeStep === 2) {
      if (!formvalue.location) {
        setErrors({ ...errors, message: "Please Select Location" });
      } else {
        isValid = true;
        setErrors(formerrors);
      }
    }

    if (activeStep === 3) {
      isValid = true;
      setErrors(formerrors);
    }
    if (activeStep === 4) {
      if (images.files.length < 5) {
        setErrors({ ...errors, message: "Please Provide at leaset 5 images" });
      } else {
        isValid = true;
        setReviewImg(images.pImages[1]);
        setErrors(formerrors);
      }
    }

    if (activeStep === 5) {
      if (
        !formvalue.title ||
        !formvalue.description ||
        !formvalue.availableDate
      ) {
        setErrors({ ...errors, message: "Please all the Info" });
      } else {
        isValid = true;
        setErrors(formerrors);
      }
    }

    if (activeStep === 6) {
      if (formvalue.price === 0 || !formvalue.price) {
        setErrors({ ...errors, message: "Please provide price" });
      } else {
        isValid = true;
        setErrors(formerrors);
      }
    }
    if (activeStep === 7) {
      isValid = true;
    }

    return isValid;
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "price":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setformvalues({ ...formvalue, price: parseInt(value) });
        }
        break;

      case "addprice":
        if (value === "") {
          setformvalues({ ...formvalue, price: 1 });
        }

        if (value <= 100000000) {
          const addedprice = parseInt(value) + 1;
          setformvalues({ ...formvalue, price: addedprice });
        }
        break;

      case "minusprice":
        if (value === "") {
          setformvalues({ ...formvalue, price: 0 });
        }
        if (value > 0) {
          const minusprice = parseInt(value) - 1;
          setformvalues({ ...formvalue, price: minusprice });
        }
        break;

      case "addbedroom":
        if (value <= 5) {
          const addedbedroom = parseInt(value) + 1;
          setformvalues({ ...formvalue, bedroom: addedbedroom });
        }
        break;

      case "minusbedroom":
        if (value === 0) {
        } else {
          const minusbedroom = value - 1;
          setformvalues({ ...formvalue, bedroom: minusbedroom });
        }
        break;

      case "addkitchen":
        if (value <= 5) {
          const addedkitchen = value + 1;
          setformvalues({ ...formvalue, kitchen: addedkitchen });
        }
        break;

      case "minuskitchen":
        if (value === 0) {
        } else {
          const minuskitchen = value - 1;
          setformvalues({ ...formvalue, kitchen: minuskitchen });
        }
        break;

      case "addbathroom":
        if (value <= 5) {
          const addedbathroom = value + 1;
          setformvalues({ ...formvalue, bathroom: addedbathroom });
        }
        break;

      case "minusbathroom":
        if (value === 0) {
        } else {
          const minusbathroom = value - 1;
          setformvalues({ ...formvalue, bathroom: minusbathroom });
        }
        break;
      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

  // handle image upload and remove images
  const handleimages = React.useCallback(({ files, pImages }) => {
    setImages({ files: files, pImages: pImages });
  }, []);

  // on next clicked formvalidation  is checked according to current steps
  const handleNext = () => {
    const isValid = formvalidation();
    if (isValid) {
      if (activeStep === 7) {
        const data = new FormData();
        for (let i = 0; i < images.files.length; i++) {
          data.append("images", images.files[i]);
        }
        for (let i = 0; i < formvalue.offers.length; i++) {
          data.append("offers", formvalue.offers[i]);
        }
        data.append("title", formvalue.title);
        data.append("description", formvalue.description);
        data.append("availableDate", formvalue.availableDate);
        data.append("price", formvalue.price);
        data.append("houseType", formvalue.houseType);
        data.append("accessTo", formvalue.accessTo);
        data.append("bedroom", formvalue.bedroom);
        data.append("kitchen", formvalue.kitchen);
        data.append("bathroom", formvalue.bathroom);
        data.append("lat", formvalue.location.lat);
        data.append("lng", formvalue.location.lng);
        data.append("place_name", formvalue.location.place_name);
        data.append("minimumStay", formvalue.minimumStay);
        dispatch(createRentalAction({ data, toast, navigate }));
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  // on location set
  const handleLocation = (location) => {
    setformvalues((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[1],
        lng: location.geometry.coordinates[0],
        place_name: location.place_name,
      },
    }));
  };

  // on back button click
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="container">
      <div>
        {/* form components are arraged with the help of mui tabs */}
        <TabContext value={activeStep.toString()}>
          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="0"
          >
            <TypeofProperty
              handleHouseType={handleHouseType}
              houseType={formvalue.houseType}
            />
          </TabPanel>
          <TabPanel
            value="1"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <TenantAccess
              handleAccessType={handleFormValue}
              selected={formvalue.accessTo}
            />
          </TabPanel>

          <TentantLocation
            handleLocation={handleLocation}
            errors={errors}
            active={activeStep === 2 ? true : false}
            location={formvalue.location}
          />

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="3"
          >
            <PropertyOffer
              errors={errors}
              offers={formvalue.offers}
              handleFormValue={handleFormValue}
              bedroom={formvalue.bedroom}
              kitchen={formvalue.kitchen}
              bathroom={formvalue.bathroom}
            />
          </TabPanel>

          <RentalImageUpload
            errors={errors.images}
            handleimages={handleimages}
            active={activeStep === 4 ? true : false}
            pImages={images.pImages}
            imageFile={images.files}
          />

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="5"
          >
            <AboutProperty
              errors={errors}
              values={formvalue}
              handleFormValue={handleFormValue}
            />
          </TabPanel>

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="6"
          >
            <PropertyPrice
              errors={errors}
              price={formvalue.price}
              handleFormValue={handleFormValue}
            />
          </TabPanel>

          <TabPanel value="7">
            <RentalReview reviewImg={reviewImg} values={formvalue} />
          </TabPanel>
        </TabContext>
      </div>

      <progress
        value={activeStep}
        className="w-100 my-2 text-black"
        max={7}
      ></progress>
      <div className="d-flex w-100  align-items-center justify-content-between">
        <Button size="medium" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>

        {errors.message && <p className="text-danger">{errors.message}</p>}
        <Button
          size="large"
          className="btn-dark text-white text-capitalize"
          onClick={handleNext}
          disabled={activeStep === 8}
        >
          {activeStep === 7 ? "Publish" : "Next"}
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddRental;
