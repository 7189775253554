import { Box, Chip, Modal } from "@mui/material";
import React from "react";
import { MdEditSquare } from "react-icons/md";
import ProfessionalMOffers from "../modals/ProfessionalMOffers";
const ProfessionalOffers = ({ professional }) => {
  const [open, setOpen] = React.useState(false);

  const handleModal = () => {
    setOpen((prev) => !prev);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "95%", md: "70%", lg: "50%" },
    bgcolor: "var(--secondary-color)",
    borderRadius: "15px",

    boxShadow: 24,
    p: 4,
  };
  return (
    <div className="d-flex flex-column gap-2">
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "100%", lg: "100%" },
        }}
        className="d-flex   align-items-center justify-content-between "
      >
        <h4 className="text-black fw-bold">Offered Services</h4>
        <Box
          className="d-flex justify-content-center align-items-center  rounded-circle"
          role="button"
          sx={{
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={handleModal}
        >
          <MdEditSquare />
        </Box>
      </Box>

      <section className="d-flex flex-wrap gap-2">
        {professional?.offers?.map((item, index) => {
          return (
            <Chip
              key={index}
              sx={{
                backgroundColor: "#F2F7F2",
                color: "#13544E",
              }}
              label={item}
              size="medium"
            />
          );
        })}
      </section>

      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ProfessionalMOffers onClose={handleModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default ProfessionalOffers;
