import { Typography } from "@mui/material";
import { SvgIcon } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import React, { useState } from "react";

import { saveAs } from "file-saver";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import { DataGrid } from "@mui/x-data-grid";
import { MoreVert, CheckOutlined, CancelOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllUserAction } from "../../../../../context/actions/admin/userAction";
import CustomSearch from "../../../../../components/admin/CustomSearch";
import { Card } from "@mui/material";
import AdsDropDown from "../components/AdsDropDown";
export const AdminUsersTable = ({ keyword, setOpen }) => {
  const [activeindex, setActive] = React.useState(0);
  const onEdit = (id, page) => {
    setOpen(true);
  };

  const columns = [
    {
      field: "profile",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35%", height: "auto", borderRadius: "50%" }}
        />
      ),
      headerName: "Profile",
    },
    { field: "FullName", headerName: "FullName", flex: 1 },
    { field: "email", headerName: "email", flex: 1 },
    { field: "Country", headerName: "Country", flex: 1 },
    {
      field: "verified",
      headerName: "Verified",
      width: 150,
      renderCell: (params) => {
        if (params.value === true) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return <AdsDropDown onEdit={onEdit} id={params.row.id} />;
      },
    },
  ];

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);

  React.useEffect(() => {
    dispatch(adminGetAllUserAction({ keyword, page }));
  }, [dispatch, keyword, page]);

  // use useSelector to get data from redux store
  const { user, resultperpage, totalusercount, loading } = useSelector(
    (state) => ({
      ...state.admin_reducer,
    })
  );

  // map user data to rows
  const all_user = user.map((item, index) => ({
    id: item._id,
    index: index,
    profile: "https://i.pravatar.cc/300",
    FullName: `${item.firstname} ${item.lastname}`,
    email: item.email,
    Country: item.country,
    verified: item.verified,
  }));

  // return table
  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={all_user}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalusercount}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};
const AdminOrgAdsTable = ({setOpen}) => {
  const [keyword, setKeyword] = useState("");

  const handleChangeKeyword = (event) => {
    if (event.target.value === "") {
      setKeyword("");
    }
  };
  const handleChangeKey = (event) => {
    if (event.key === "Enter") {
      setKeyword(event.target.value);
    }
  };
  const handleExport = async () => {
    try {
      const response = await fetch(
        "http://192.168.101.4:5000/api/admin/get_all_user?keyword=&page=1"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch customer data");
      }
      const data = await response.json();

      // Convert the customer data to CSV format
      const csvData = convertToCSV(data.user);

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Save the exported data as a file
      saveAs(blob, "orgads.csv");
    } catch (error) {
      console.error("Export failed:", error);
      // Handle the error accordingly (e.g., show an error message)
    }
  };
  const convertToCSV = (data) => {
    const csvHeader = Object.keys(data[0]).join(",") + "\n";
    const csvRows = data.map((customer) => {
      const values = Object.values(customer);
      return values.join(",");
    });

    return csvHeader + csvRows.join("\n");
  };

  return (
    <>
      <Box className=" d-flex flex-column gap-3">
        <Card className="w-100 py-2 d-flex align-items-center justify-content-between">
          <CustomSearch
            handleChange={handleChangeKeyword}
            handleKeyPress={handleChangeKey}
            placeholder={"Search Ads"}
            sx={{
              maxWidth: "400px",
              width: "100%",
            }}
          />
          <Box className="d-flex align-items-center">
            <Button
              color="inherit"
              startIcon={
                <SvgIcon fontSize="small">
                  <ArrowUpOnSquareIcon />
                </SvgIcon>
              }
            >
              Import
            </Button>
            <Button
              color="inherit"
              startIcon={
                <SvgIcon fontSize="small">
                  <ArrowDownOnSquareIcon />
                </SvgIcon>
              }
              onClick={handleExport}
            >
              Export
            </Button>
          </Box>
        </Card>
        <AdminUsersTable keyword={keyword} setOpen={setOpen} />
      </Box>
    </>
  );
};

export default AdminOrgAdsTable;
