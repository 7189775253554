import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SubjectIcon from "@mui/icons-material/Subject";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const AdminContactIntro = ({ data }) => {
  const [readmore, setreadmore] = React.useState(false);

  const togglereadmore = () => {
    setreadmore((prev) => !prev);
  };
  const description = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi nostrum earum fugit expedita numquam in quasi consequuntur repellat eligendi voluptatem voluptas, quaerat quia laborum excepturi tempore nam nihil labore natus corrupti voluptates sunt."
  return (
    <div>
      <h5 className="fw-bold">General Information</h5>
      <section className="d-flex flex-column gap-2 fs-5">
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PersonIcon />
            <span className="fs-6 ">Full Name</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data?.fullname}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <LocationOnIcon />
            <span className="fs-6">location</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">British | Colombia</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <LocalPhoneIcon />
            <span className="fs-6">Phone</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{data?.phone}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-start">
            <SubjectIcon />
            <span className="fs-6">Description</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1">
            {!readmore ? data?.description?.slice(0, 300) : data?.description}

            {!readmore ? (
              <strong role="button" onClick={togglereadmore}>
                ...readmore
              </strong>
            ) : (
              <strong role="button" onClick={togglereadmore}>
                ...readless
              </strong>
            )}
          </p>
        </div>
      </section>
    </div>
  );
};

export default AdminContactIntro;
