import { MoreVert } from "@mui/icons-material";
import { Button, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllOrganizationAction } from "../../../../context/actions/admin/organizationAction";
import AdminOrganizationDetailReview from "./adminOrganizationDetailReview/AdminOrganizationDetailReview";
import moment from "moment";

export default function OrganizationReviewTable({ keyword }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);
  const [status, setStatus] = React.useState(false);
 

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);



  React.useEffect(() => {
    dispatch(adminGetAllOrganizationAction({ keyword, page, status }));
  }, [dispatch, page, keyword, status]);

  const columns = [
    {
      field: "logo",
      headerName: "Org's Logo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Organization Name", flex: 1 },
    { field: "address", headerName: "Prevenince | City", flex: 1 },
    { field: "establishedDate", headerName: "Estd. Date", flex: 1 },
    {
      field: "document",
      headerName: "Document Received",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,

      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);
          toggleDrawer();
        };

        return (
          <Button
            size="medium"
            variant="outlined"
            onClick={handleButtonClick}
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];

  const { organization, resultperpage, totalorganizationcount, loading } =
    useSelector((state) => ({
      ...state.admin_reducer,
    }));

  const mappedRows = organization.map((organization, index) => ({
    id: organization._id,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${organization.introduction.profile}`,
    name: organization.introduction.organizationName,
    address: `${organization.introduction.provenience} | ${organization.introduction.city}`,
    establishedDate: moment(organization?.introduction?.establishedDate).format("MMMM Do YYYY"),
    document: organization?.introduction?.documentFrontpage?"Received ✔️":"Not Received",
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalorganizationcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminOrganizationDetailReview
          data={organization[activeindex]}
          activeindex={activeindex}
          closeModal={toggleDrawer}
        />
      </Drawer>
    </div>
  );
}
