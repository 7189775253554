import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import React from "react";
import { IconInputB } from "../../../components/Index";
import FormInputB from "../../../components/Input/FormInputB";

import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import AddLocation from "../../organizationupgrade/components/stepperform/location/AddLocation";
const BusinessContactAndLinks = React.memo(
  ({
    handleLocation,
    handleSubmit,
    handleformvalue,
    prevstep,
    errors,
    value,
  }) => {
    return (
      <form
        noValidate
        onSubmit={handleSubmit}
        className={" py-3 needs-validation"}
      >
        <span className="fs-2 fw-bolder">Contacts</span>
        <div className="bg-hover p-1 p-lg-5 rounded rounded-2 border border-2">
          <FormInputB
            name={"email"}
            handleChange={handleformvalue}
            label={"Email"}
            required={true}
            type={"email"}
            classname={" w-100  form-control"}
            placeholder={"Enter Email Address"}
            error={errors.email ? true : false}
            helpertext={errors.email}
            value={value.email}
          />
          <FormInputB
            name={"telephone"}
            handleChange={handleformvalue}
            label={"Telephone Number"}
            required={true}
            type={"number"}
            classname={" w-100  "}
            placeholder={"Enter Telephone Number"}
            error={errors.telephone ? true : false}
            helpertext={errors.telephone}
            value={value.telephone}
          />
          <FormInputB
            name={"phone"}
            handleChange={handleformvalue}
            label={"Phonenumber"}
            required={true}
            type={"number"}
            classname={" w-100  form-control"}
            placeholder={"Enter PhoneNumber"}
            error={errors.phone ? true : false}
            helpertext={errors.phone}
            value={value.phone}
          />
          <p className="mt-2 3-0">Where is your business located</p>

          <div className="form-group">
            <div className="d-flex align-items-center gap-1">
              <RoomOutlinedIcon />
              <span>{value.location.place_name}</span>
            </div>
            <AddLocation handleLocation={handleLocation} />
            <small
              id="emailHelp"
              className={errors.location ? "form-text text-danger" : ""}
            >
              {errors.location}
            </small>
          </div>
        </div>

        <p className="fs-2 mt-3 fw-bolder">Links</p>

        <div className="row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-5 rounded rounded-2 border border-2">
          <IconInputB
            icon={<LanguageIcon />}
            required={true}
            name={"websiteLink"}
            placeholder={"enter website link"}
            label={"Personal Website"}
            handleChange={handleformvalue}
            error={errors.websiteLink ? true : false}
            helpertext={errors.websiteLink}
            value={value.websiteLink}
          />
          <IconInputB
            icon={<FacebookIcon />}
            required={true}
            name={"facebookLink"}
            placeholder={"enter FaceBook Link"}
            label={"FaceBook Link"}
            handleChange={handleformvalue}
            error={errors.facebookLink ? true : false}
            helpertext={errors.facebookLink}
            value={value.facebookLink}
          />
          <IconInputB
            icon={<InstagramIcon />}
            required={true}
            name={"instagramLink"}
            placeholder={"enter Instagram Link"}
            label={"Instagram Link"}
            handleChange={handleformvalue}
            error={errors.instagramLink ? true : false}
            helpertext={errors.instagramLink}
            value={value.instagramLink}
          />
          <IconInputB
            icon={<TwitterIcon />}
            required={true}
            name={"twitterLink"}
            placeholder={"enter Twitter Link"}
            label={"Twitter Link"}
            handleChange={handleformvalue}
            error={errors.twitterLink ? true : false}
            helpertext={errors.twitterLink}
            value={value.twitterLink}
          />
        </div>
        <div className=" d-flex justify-content-end gap-2  mt-3">
          <button className="bg-dark btn text-white   " onClick={prevstep}>
            Back
          </button>
          <button className="btn-dark btn  " type="submit">
            Next
          </button>
        </div>
      </form>
    );
  }
);

export default BusinessContactAndLinks;
