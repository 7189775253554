const staticPImg = "/assets/images/userImg.jpg";
const staticCImg = "/assets/images/coverpage.jpg";
const maxSize = 3;
const popularCities = [
  "Toronto",
  "Québec City",
  "Montreal",
  "Vancouver",
  "Ottawa",
  "Victoria",
  "Edmonton",
];
export { staticPImg, staticCImg, maxSize, popularCities };
