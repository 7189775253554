const category = [
  {
    name: "Entertainment",
    offer: [
      { name: "Men Football", selected: false },
      { name: "Women Football", selected: false },
      { name: "Tennis", selected: false },
      { name: "Men Basketball", selected: false },
      { name: "World Cup", selected: false },
      { name: "Golf", selected: false },
      { name: "Table Tennis", selected: false },
      { name: "EA Sports", selected: false },
    ],
  },
  {
    name: "News",
    offer: [
      { name: "Football News", selected: false },
      { name: "Breaking News", selected: false },
      { name: "Tennis", selected: false },
      { name: "Basketball News", selected: false },
      { name: "Sports News", selected: false },
      { name: "Daily News", selected: false },
      { name: "Stock News", selected: false },
      { name: "Country News", selected: false },
    ],
  },
  {
    name: "Music",
    offer: [
      { name: "Pop Music", selected: false },
      { name: "Rock Music", selected: false },
      { name: "Hip Hop Music", selected: false },
      { name: "Jazz Music", selected: false },
      { name: "Classical Music", selected: false },
      { name: "Country Music", selected: false },
      { name: "Electronic Music", selected: false },
      { name: "R&B Music", selected: false },
    ],
  },
  {
    name: "Technology",
    offer: [
      { name: "Mobile Phones", selected: false },
      { name: "Laptops", selected: false },
      { name: "Gaming Consoles", selected: false },
      { name: "Cameras", selected: false },
      { name: "Smart Home Devices", selected: false },
      { name: "Wearable Technology", selected: false },
      { name: "Virtual Reality", selected: false },
      { name: "Artificial Intelligence", selected: false },
    ],
  },
  {
    name: "Fashion",
    offer: [
      { name: "Women's Clothing", selected: false },
      { name: "Men's Clothing", selected: false },
      { name: "Shoes", selected: false },
      { name: "Accessories", selected: false },
      { name: "Jewelry", selected: false },
      { name: "Bags", selected: false },
      { name: "Beauty Products", selected: false },
      { name: "Designer Brands", selected: false },
    ],
  },
  {
    name: "Food",
    offer: [
      { name: "Italian Cuisine", selected: false },
      { name: "Asian Cuisine", selected: false },
      { name: "Vegetarian/Vegan", selected: false },
      { name: "Fast Food", selected: false },
      { name: "Desserts", selected: false },
      { name: "Beverages", selected: false },
      { name: "Fine Dining", selected: false },
      { name: "Barbecue", selected: false },
    ],
  },
  {
    name: "Travel",
    offer: [
      { name: "Beach Destinations", selected: false },
      { name: "Adventure Travel", selected: false },
      { name: "City Tours", selected: false },
      { name: "Cultural Experiences", selected: false },
      { name: "Nature Exploration", selected: false },
      { name: "Luxury Travel", selected: false },
      { name: "Cruise Vacations", selected: false },
      { name: "Road Trips", selected: false },
    ],
  },

  {
    name: "Art",
    offer: [
      { name: "Painting", selected: false },
      { name: "Sculpture", selected: false },
      { name: "Photography", selected: false },
      { name: "Drawing", selected: false },
      { name: "Printmaking", selected: false },
      { name: "Mixed Media", selected: false },
      { name: "Digital Art", selected: false },
      { name: "Installation Art", selected: false },
    ],
  },
  {
    name: "Home Decor",
    offer: [
      { name: "Furniture", selected: false },
      { name: "Lighting", selected: false },
      { name: "Home Accessories", selected: false },
      { name: "Rugs and Carpets", selected: false },
      { name: "Wall Decor", selected: false },
      { name: "Kitchenware", selected: false },
      { name: "Bedding and Linens", selected: false },
      { name: "Bathroom Essentials", selected: false },
    ],
  },
  {
    name: "Health",
    offer: [
      { name: "Fitness Supplements", selected: false },
      { name: "Vitamins and Minerals", selected: false },
      { name: "Weight Loss", selected: false },
      { name: "Sports Nutrition", selected: false },
      { name: "Natural Remedies", selected: false },
      { name: "Personal Care Products", selected: false },
      { name: "Health Monitoring Devices", selected: false },
      { name: "Wellness Retreats", selected: false },
    ],
  },
  {
    name: "Education",
    offer: [
      { name: "Online Courses", selected: false },
      { name: "Language Learning", selected: false },
      { name: "Professional Development", selected: false },
      { name: "Test Preparation", selected: false },
      { name: "Art and Music Lessons", selected: false },
      { name: "Coding Bootcamps", selected: false },
      { name: "College Applications", selected: false },
      { name: "Tutoring Services", selected: false },
    ],
  },
  {
    name: "Beauty",
    offer: [
      { name: "Skincare", selected: false },
      { name: "Makeup", selected: false },
      { name: "Hair Care", selected: false },
      { name: "Nail Care", selected: false },
      { name: "Fragrances", selected: false },
      { name: "Beauty Tools", selected: false },
      { name: "Body Care", selected: false },
      { name: "Spa Treatments", selected: false },
    ],
  },
  {
    name: "Finance",
    offer: [
      { name: "Personal Budgeting", selected: false },
      { name: "Investing", selected: false },
      { name: "Retirement Planning", selected: false },
      { name: "Tax Services", selected: false },
      { name: "Debt Management", selected: false },
      { name: "Insurance", selected: false },
      { name: "Credit Cards", selected: false },
      { name: "Mortgages", selected: false },
    ],
  },
  {
    name: "Pets",
    offer: [
      { name: "Dog Supplies", selected: false },
      { name: "Cat Supplies", selected: false },
      { name: "Bird Supplies", selected: false },
      { name: "Fish Supplies", selected: false },
      { name: "Small Animal Supplies", selected: false },
      { name: "Reptile Supplies", selected: false },
      { name: "Pet Services", selected: false },
      { name: "Pet Grooming", selected: false },
    ],
  },
  {
    name: "Sports",
    offer: [
      { name: "Cricket", selected: false },
      { name: "Basketball", selected: false },
      { name: "Baseball", selected: false },
      { name: "Hockey", selected: false },
      { name: "Golf", selected: false },
      { name: "Swimming", selected: false },
      { name: "Athletics", selected: false },
      { name: "Rugby", selected: false },
    ],
  },
  {
    name: "Technology",
    offer: [
      { name: "Smartphones", selected: false },
      { name: "Laptops", selected: false },
      { name: "Gaming Consoles", selected: false },
      { name: "Drones", selected: false },
      { name: "Virtual Reality", selected: false },
      { name: "Robotics", selected: false },
      { name: "Artificial Intelligence", selected: false },
      { name: "Internet of Things", selected: false },
    ],
  },

  {
    name: "Books",
    offer: [
      { name: "Fiction", selected: false },
      { name: "Non-Fiction", selected: false },
      { name: "Biography", selected: false },
      { name: "Science Fiction", selected: false },
      { name: "Mystery", selected: false },
      { name: "Self-Help", selected: false },
      { name: "Romance", selected: false },
      { name: "Fantasy", selected: false },
    ],
  },
  {
    name: "Home and Garden",
    offer: [
      { name: "Furniture", selected: false },
      { name: "Appliances", selected: false },
      { name: "Home Decor", selected: false },
      { name: "Kitchenware", selected: false },
      { name: "Gardening Tools", selected: false },
      { name: "Patio and Outdoor", selected: false },
      { name: "Home Improvement", selected: false },
      { name: "Cleaning Supplies", selected: false },
    ],
  },
  {
    name: "Automotive",
    offer: [
      { name: "Cars", selected: false },
      { name: "Motorcycles", selected: false },
      { name: "Trucks", selected: false },
      { name: "SUVs", selected: false },
      { name: "Auto Parts", selected: false },
      { name: "Car Accessories", selected: false },
      { name: "Tires", selected: false },
      { name: "Car Care", selected: false },
    ],
  },

  {
    name: "Fitness",
    offer: [
      { name: "Gym Memberships", selected: false },
      { name: "Yoga Classes", selected: false },
      { name: "Pilates", selected: false },
      { name: "CrossFit", selected: false },
      { name: "Zumba", selected: false },
      { name: "Personal Training", selected: false },
      { name: "Cycling Classes", selected: false },
      { name: "Group Fitness", selected: false },
    ],
  },
  {
    name: "Electronics",
    offer: [
      { name: "Televisions", selected: false },
      { name: "Cameras", selected: false },
      { name: "Audio Equipment", selected: false },
      { name: "Smart Home Devices", selected: false },
      { name: "Computer Accessories", selected: false },
      { name: "Video Games", selected: false },
      { name: "Headphones", selected: false },
      { name: "Wearable Technology", selected: false },
    ],
  },
  {
    name: "Wellness",
    offer: [
      { name: "Meditation", selected: false },
      { name: "Mindfulness", selected: false },
      { name: "Aromatherapy", selected: false },
      { name: "Herbal Remedies", selected: false },
      { name: "Fitness Equipment", selected: false },
      { name: "Wellness Books", selected: false },
      { name: "Healthy Cooking", selected: false },
      { name: "Natural Skincare", selected: false },
    ],
  },

  {
    name: "Food and Drink",
    offer: [
      { name: "Restaurants", selected: false },
      { name: "Cafes", selected: false },
      { name: "Fast Food", selected: false },
      { name: "Fine Dining", selected: false },
      { name: "Wine and Spirits", selected: false },
      { name: "Bakeries", selected: false },
      { name: "Food Delivery", selected: false },
      { name: "Cooking Classes", selected: false },
    ],
  },
  {
    name: "Art and Design",
    offer: [
      { name: "Paintings", selected: false },
      { name: "Sculptures", selected: false },
      { name: "Photography", selected: false },
      { name: "Digital Art", selected: false },
      { name: "Interior Design", selected: false },
      { name: "Fashion Design", selected: false },
      { name: "Graphic Design", selected: false },
      { name: "Art Supplies", selected: false },
    ],
  },
  {
    name: "Healthcare",
    offer: [
      { name: "Medical Consultations", selected: false },
      { name: "Dental Care", selected: false },
      { name: "Vision Care", selected: false },
      { name: "Pharmacy", selected: false },
      { name: "Health Insurance", selected: false },
      { name: "Alternative Medicine", selected: false },
      { name: "Physical Therapy", selected: false },
      { name: "Home Healthcare", selected: false },
    ],
  },
  {
    name: "Home Services",
    offer: [
      { name: "Plumbing", selected: false },
      { name: "Electrical Services", selected: false },
      { name: "HVAC Services", selected: false },
      { name: "Home Cleaning", selected: false },
      { name: "Painting", selected: false },
      { name: "Moving Services", selected: false },
      { name: "Pest Control", selected: false },
      { name: "Renovation Services", selected: false },
    ],
  },
  {
    name: "Online Marketplace",
    offer: [
      { name: "Electronics", selected: false },
      { name: "Fashion", selected: false },
      { name: "Home and Garden", selected: false },
      { name: "Books", selected: false },
      { name: "Toys and Games", selected: false },
      { name: "Sports and Outdoors", selected: false },
      { name: "Beauty and Personal Care", selected: false },
      { name: "Pet Supplies", selected: false },
    ],
  },
  {
    name: "Education",
    offer: [
      { name: "Schools", selected: false },
      { name: "Universities", selected: false },
      { name: "Online Courses", selected: false },
      { name: "Tutoring Services", selected: false },
      { name: "Educational Books", selected: false },
      { name: "Language Learning", selected: false },
      { name: "Professional Development", selected: false },
      { name: "Educational Toys", selected: false },
    ],
  },
  {
    name: "Beauty and Wellness",
    offer: [
      { name: "Spas", selected: false },
      { name: "Hair Salons", selected: false },
      { name: "Nail Salons", selected: false },
      { name: "Beauty Products", selected: false },
      { name: "Massage Therapy", selected: false },
      { name: "Skincare", selected: false },
      { name: "Makeup Services", selected: false },
      { name: "Wellness Retreats", selected: false },
    ],
  },
  {
    name: "Finance",
    offer: [
      { name: "Banking Services", selected: false },
      { name: "Investment Services", selected: false },
      { name: "Insurance Services", selected: false },
      { name: "Tax Preparation", selected: false },
      { name: "Mortgage Services", selected: false },
      { name: "Financial Planning", selected: false },
      { name: "Credit Cards", selected: false },
      { name: "Retirement Planning", selected: false },
    ],
  },
  {
    name: "Pets Care",
    offer: [
      { name: "Pet Food", selected: false },
      { name: "Pet Supplies", selected: false },
      { name: "Pet Grooming", selected: false },
      { name: "Veterinary Services", selected: false },
      { name: "Pet Boarding", selected: false },
      { name: "Pet Training", selected: false },
      { name: "Pet Adoption", selected: false },
      { name: "Pet Accessories", selected: false },
    ],
  },
];

export { category };
