import VerifiedIcon from "@mui/icons-material/Verified";
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { staticCImg, staticPImg } from "../../../../../utils/globalSettings";

const ProfessionalOverview = ({ data }) => {
  return (
    <div>
      <Box
        className="  w-100  overflow-hidden position-relative d-flex justify-content-center align-items-end"
        sx={{
          height: "30vh",
        }}
      >
        <Box
          sx={{
            height: "20vh",
          }}
          className="  w-100 rounded rounded-4 overflow-hidden  position-absolute top-0 start-0"
        >
          <img
            src={staticCImg}
            alt=""
            srcSet=""
            className="object-cover w-100 h-100  "
          />
        </Box>
        <Box
          className=" position-relative rounded rounded-circle "
          sx={{
            overflow: "hidden",
            cursor: "pointer",
            height: "175px",
            width: "175px",
            border: "10px solid #f1f1f1",
          }}
        >
          <img
            alt="Remy Sharp"
            src={
              data.userPic
                ? `${process.env.REACT_APP_IMAGE_PATH}${data.userPic}`
                : staticPImg
            }
            style={{}}
            className="object-cover object-center  w-100 h-100"
          />
        </Box>
      </Box>
      <h3 className="text-center">
        {data?.firstName} {data?.lastName}
      </h3>
      {data.active ? (
        <section className="info d-flex flex-column text-center align-items-center ">
         
          <span>
            {data?.provenience}|{data?.city}
          </span>
          <button className="bg-hover border-0 w-maxcontent m-2 p-2 rounded rounded-4 py-1 d-flex align-items-center gap-1 text-black-50">
            <span>Professional | </span>
            <span>{data?.profession}</span>
            {true ? (
              <VerifiedIcon
                sx={{
                  color: "#16BFFF",
                }}
              />
            ) : null}
          </button>

          <p>
            <span className="fw-bold">{/* {avg_rating} */} 4.5</span> out of 5
            based on |{" "}
            <span className="fw-bold text-decoration-underline">
              {/* {business_comments?.length} REVIEWS */}5 REVIEWS
            </span>
          </p>
        </section>
      ) : (
        <p className="text-center w-100 fw-bold ">
          {data?.firstName ? data.reviewMessage ? (
            <span>
              <p>
              {data.reviewMessage}
              </p>
              <span className="btn btn-sm btn-outline-danger">RESUBMIT APPLICATION</span>
            </span>

          ) : !data?.active && data?.status ? (
            <span class="badge fs-6 rounded-pill badge-danger">
              Deactivated | Please contact admin
            </span>
          ): (
            <button className="btn btn-sm btn-outline-warning">
              Your application is under review
              </button>
          ) : (
            <div className="d-flex align-items-center flex-column">
              <span className="text-danger">You are not a professional yet</span>

              <small>
                Expand your network and get hired by adding your expertise
              </small>
              <Link
                to={`/professionals/add`}
                className="btn bg-black text-white w-25 mt-2"
              >
                Be a Professional
              </Link>
            </div>
          )
          }
        </p>
      )}
    </div>
  );
};

export default ProfessionalOverview;
