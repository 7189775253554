import React from "react";
import { allProvince } from "../../../utils/orgSettings";
import { category } from "../../../utils/bussinessSettings";
import { FormSelectCityB } from "../../../components/Index";
const BusinessSmFilter = ({
  filtered,
  onClose,
  onSMfilter,
}) => {
  const [filters, setFilters] = React.useState({
    category: "",
    city: "",
    province: "",
  });

  React.useEffect(() => {
    setFilters({
      category: filtered.category,
      city: filtered.city,
      province: filtered.province,
    });
  }, [filtered.category, filtered.city, filtered.province]);
  const handleFilter = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const onFilter = (e) => {
    e.stopPropagation();
    onSMfilter({
      city: filters.city,
      province: filters.province,
      category: filters.category,
    });

    onClose();
  };
  return (
    <div className="">
      <h4>Search & Filtering</h4>
      <div className="w-100">
        <label htmlFor="category">category</label>
        <select
          className={" w-100 form-select rounded-0 py-2 rounded-2 "}
          aria-label=".form-select-lg example"
          value={filters.category}
          name="category"
          id="category"
          onChange={handleFilter}
        >
          <option value="" className="w-100">
            Open To Category
          </option>
          {category.map((item, index) => {
            return (
              <option value={item.name} key={index} className="w-100">
                {item.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="w-100">
        <label htmlFor="">province</label>
        <select
          className={" w-100 rounded-0 py-2 rounded-2 form-select"}
          aria-label=".form-select-lg example"
          value={filters.province}
          name="province"
          onChange={handleFilter}
        >
          <option value="" className="w-100">
            Open To Province
          </option>
          {allProvince.map((item, index) => {
            return (
              <option value={item} key={index} className="w-100">
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <FormSelectCityB
        province={filters.province || filtered.province}
        city={filters.city}
        handleFilter={handleFilter}
      />

      <div className="d-flex justify-content-center w-100">
        <button
          className="btn bg-black text-white w-75 mx-auto mt-2"
          onClick={onFilter}
        >
          Filter
        </button>
      </div>
    </div>
  );
};

export default BusinessSmFilter;