import React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";

const LayoutBox = styled("div")(({ theme }) => ({
  display: "none",

  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
}));
const RentalSearch = ({ rtype, onPageTabChange, handleOpen }) => {
  return (
    <form>
      <Box
        className="position-absolute "
        sx={{
          top: "calc(50vh/3)",
          left: "10%",
          width: "80%",
        }}
      >
        <Typography
          sx={{
            color: "white",
            width: "max-content",
            height: "max-content",
          }}
          variant="p"
          className="fs-1 fw-bold "
        >
          ONF Canada | Rental Section
        </Typography>
        <div className="d-flex align-items-center gap-1">
          <Typography
            variant="span"
            sx={{
              color: "white",
            }}
            className="fs-5"
          >
            Start renting and earning.
          </Typography>
          <Link to="/rental/add">
            <button className="btn btn-sm bg-white text-capitalize btn-outline-black text-nowrap">
              Add Rental
            </button>
          </Link>
        </div>
        <LayoutBox
          className="mt-2 rounded rounded-3 align-item-center justify-content-between "
          sx={{
            backgroundColor: "var(--secondary-color)",
            padding: "1%  2% ",
          }}
        >
          <Box
            sx={{
              borderRadius: "6px",
            }}
            className="d-flex flex-column px-2 w-maxcontent"
          >
            <span className="fs-6">I'm looking for</span>
            <div className="d-flex gap-1 align-items-center">
              <Typography
                variant="span"
                className="px-2 py-0 fw-bold"
                onClick={() => onPageTabChange("Rental")}
                role="button"
                sx={{
                  borderRadius: "4px",
                  border: rtype === "Rental" ? "1.5px solid black" : "",
                }}
              >
                Rental
              </Typography>
              <Typography
                variant="span"
                className="px-2 py-0 fw-bold"
                onClick={() => onPageTabChange("FlateMates")}
                role="button"
                sx={{
                  borderRadius: "4px",
                  border: rtype === "FlateMates" ? "1.5px solid black" : "",
                }}
              >
                FlateMates
              </Typography>

              <Typography
                onClick={() => onPageTabChange("TeamUp")}
                role="button"
                variant="span"
                className="px-2 py-0 fw-bold text-danger"
                sx={{
                  borderRadius: "4px",
                  border: rtype === "TeamUp" ? "1.5px solid black" : "",
                }}
              >
                Teamup
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              border: "2px solid grey",
            }}
            className="w-100  rounded rounded-3 d-flex align-items-center justify-content-between p-2 px-3"
            role="button"
            onClick={handleOpen}
          >
            <p>
              Filter rental, flatmates, teamup, price, location and many more
            </p>
            <Box className=" d-flex justify-content-center gap-1 align-items-center ">
              <Button
                sx={{
                  color: "black",
                  border: "1.5px solid grey",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  fontWeight: 600,
                  boxShadow: "none",
                }}
                className="d-flex gap-1 bg-black align-items-center px-2 py-2  rounded rounded-2 h-maxcontent  btn text-black  shadow-1 "
              >
                <TuneIcon
                  sx={{
                    color: "white",
                  }}
                />
                <Typography
                  variant="span"
                  sx={{
                    fontSize: "0.8rem",
                  }}
                  className="text-white"
                >
                  Filter
                </Typography>
              </Button>
              {/* <button className="btn-dark w-maxcontent" onClick={onSearch}>
            Search
          </button> */}
            </Box>
          </Box>
        </LayoutBox>
      </Box>
    </form>
  );
};

export default RentalSearch;
