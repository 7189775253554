import TuneIcon from "@mui/icons-material/Tune";
import { Box, Modal, Pagination, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FlateMateUserCard, RentalUserCard } from "../../components/Index";
import {
  getAllFlatemateAction,
  getAllRentalAction,
} from "../../context/actions/rentalAction";
import RentalSearch from "./components/RentalSearch";
import AllRentalFilterModal from "./modal/AllRentalFilterModal";
const LayoutBox = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));

const  AllRental = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "40vw", xs: "95vw", md: "40vw", sm: 400 },
    bgcolor: "background.paper",
    boxShadow: 24,
    height: "fit-content",
    borderRadius: "10px",
    padding: { xs: 2, md: 4 },
    
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const options = {
    rentalType: "Rental",
    location: "",
    minPrice: "",
    maxPrice: "",
    propertyType: "House",
    sortby: "Popularity",
  };
  const sortbys = ["Popularity", "latest"];
  const [page, setPage]= React.useState(1);



  const handlePagechange = (event, value) => {
    setPage(value);
  };


  const [search, setsearch] = React.useState(options);
  const [state, setState] = React.useState(false);
  const [rtype, setType] = React.useState("Rental");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onPageTabChange = (value) => {
    setType(value);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  useEffect(() => {
    Promise.all([
      dispatch(getAllRentalAction({page:page})),
      dispatch(getAllFlatemateAction({})),
    ]);
  }, [dispatch, page]);

  const handleSearch = (name, value) => {
    switch (name) {
      case "minPrice":
        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(value)) {
          setsearch({ ...search, [name]: value });
        }

        break;

      case "maxPrice":
        const onlyNumbersmax = /^[0-9]*$/;
        if (onlyNumbersmax.test(value)) {
          setsearch({ ...search, [name]: value });
        }
        break;
      case "location":
        setsearch({ ...search, [name]: value });

        break;
      default:
        setsearch({ ...search, [name]: value });
    }
  };

  const onPropertySelect = (e) => {
    setsearch({ ...search, [e.target.name]: e.target.value });
  };

  const onSearch = () => {
    // Encode the search parameters
    const location = encodeURIComponent(search.location);
    const minPrice = encodeURIComponent(search.minPrice);
    const maxPrice = encodeURIComponent(search.maxPrice);
    const propertyType = encodeURIComponent(search.propertyType);
    // Construct the URL with the encoded values
    const url = `/rental/filter/?search=${location}&minPrice=${minPrice}&maxPrice=${maxPrice}&propertyType=${propertyType}`;
    navigate(url);
  };

  const { loading, property, flatemate, totalrentalcount, resultperpage } =
    useSelector((state) => ({
      loading: state.rental_reducer.loading,
      property: state.rental_reducer.property,
      flatemate: state.rental_reducer.flatemate,
      totalrentalcount: state.rental_reducer.totalrentalcount,
      resultperpage: state.rental_reducer.resultperpage,
    }));

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          height: { xs: "40dvh", md: "50dvh", lg: "60dvh" },
        }}
        className="position-relative"
      >
        <img
          src="/assets/images/rentalbg.png"
          alt="rentalbg"
          className="position-aboslute w-100 h-100"
        />
        <RentalSearch
          rtype={rtype}
          onPageTabChange={onPageTabChange}
          handleOpen={handleOpen}
        />
      </Box>

      <LayoutBox
        sx={{
          // position: "sticky",

          top: "8.5vh",
          left: "0",
          zIndex: "4",
        }}
        role="button"
        className="  w-100 bg-white  py-2 container "
      >
        <div className="w-100 d-flex flex-column gap-2">
          <Box
            className="border border-dark rounded rounded-3 d-flex   "
            sx={{
              height: "40px",
            }}
          >
            <Typography
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center "
              onClick={() => onPageTabChange("Rental")}
              role="button"
              sx={{
                background: rtype === "Rental" ? "#474141" : "",
                color: rtype === "Rental" ? "#f1ebeb" : "",
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",
                borderRight: "1px solid black",
              }}
            >
              Rental
            </Typography>
            <Typography
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
              onClick={() => onPageTabChange("FlateMates")}
              role="button"
              sx={{
                backgroundColor: rtype === "FlateMates" ? "#474141" : "",
                color: rtype === "FlateMates" ? "#f1ebeb" : "",
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",

                borderRight: "1px solid black",
              }}
            >
              FlateMates
            </Typography>

            <Typography
              onClick={() => onPageTabChange("TeamUp")}
              role="button"
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
              sx={{
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",

                backgroundColor: rtype === "TeamUp" ? "#474141" : "",
                color: rtype === "TeamUp" ? "#f1ebeb" : "",
              }}
            >
              Teamup
            </Typography>
          </Box>
          <Box
            sx={{
              border: "2px solid grey",
            }}
            className="w-100  rounded rounded-3 d-flex gap-2  align-items-center justify-content-between p-2 px-3"
            onClick={handleOpen}
            role="button"
          >
            <p className="w-100 text-overflow-hidden">
              Filter rental, flatmates, teamup, price, location and many more
            </p>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "var(--hover-color)",
              }}
              role="button"
              className="d-flex justify-content-center align-items-center rounded  rounded-2 p-2"
            >
              <TuneIcon />
            </Box>
          </Box>
        </div>
      </LayoutBox>

      <div className="container mt-3">
        <div className="d-flex justify-content-between w-100 mb-3">
          <div className="d-flex flex-column">
            <span className="fs-4 fw-bold text-black">
              <span className="text-danger">
                {search.sortby === "Popularity" ? "Trending" : "Latest"}
              </span>{" "}
              {rtype === "Rental"
                ? "Rental"
                : rtype === "FlateMates"
                ? "Flatmates"
                : rtype === "TeamUp"
                ? "Team-up"
                : "None"}{" "}
              Listing
            </span>
            <small>
              Showing {search.sortby === "Popularity" ? "Trending" : "Latest"}{" "}
              listing around Canada till now
            </small>
          </div>

          <select
            id=""
            className="border-0 bg-transparent text-black"
            name="sortby"
            value={search.sortby}
            onChange={onPropertySelect}
          >
            {sortbys.map((item, index) => {
              return (
                <option value={item} key={index}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
        {rtype === "Rental" ? (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-4 gy-4">
            {property?.length ? (
              property.map((item, index) => {
                return (
                  <div className="" key={index}>
                    <RentalUserCard item={item} />
                  </div>
                );
              })
            ) : (
              <>
                {Array.from({ length: 8 }, (_, index) => (
                  <div key={index} className="p-2">
                    <div className="shadow-3 rounded rounded-4 overflow-hidden">
                      <div style={{ height: "200px" }}>
                        <Skeleton height={"100%"} width={"100%"} />
                      </div>
                      <div className="rental-info p-1 px-2">
                        <div className="rental-info_price d-flex align-items-center justify-content-between">
                          <p className="fs-5">
                            <strong>
                              <Skeleton width={100} />
                            </strong>
                            <small>
                              <Skeleton width={30} />
                            </small>
                          </p>
                          <Skeleton width={40} height={40} circle={true} />
                        </div>

                        <h5 className="fw-bold">
                          <Skeleton width={"80%"} />
                        </h5>

                        <div className="d-flex gap-1 align-items-center">
                          <Skeleton width={20} height={20} circle={true} />
                          <small>
                            <Skeleton width={"60%"} />
                          </small>
                        </div>
                      </div>
                      <hr className="mt-0" />
                      <div className="d-flex justify-content-between px-2 align-items-center pb-3 ">
                        <div className="d-flex gap-1 align-items-center">
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                        </div>
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : null}

        {rtype === "FlateMates" ? (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-4 gy-4">
            {flatemate?.length > 0 ? (
              flatemate
                ?.filter((item) => !item.teamUp)
                .map((item, index) => (
                  <div className="" key={index}>
                    <FlateMateUserCard item={item} />
                  </div>
                ))
            ) : (
              <>
                {Array.from({ length: 8 }, (_, index) => (
                  <div className="m-2 shadow-3 rounded rounded-4 overflow-hidden">
                    <div style={{ height: "200px" }}>
                      <Skeleton height={"100%"} width={"100%"} />
                    </div>
                    <div className="rental-info p-1 px-2">
                      <div className="rental-info_price d-flex align-items-center justify-content-between">
                        <p className="fs-5">
                          <strong>
                            <Skeleton width={100} />
                          </strong>
                          <small>
                            <Skeleton width={30} />
                          </small>
                        </p>
                        <Skeleton width={40} height={40} circle={true} />
                      </div>

                      <h5 className="fw-bold">
                        <Skeleton width={"80%"} />
                      </h5>

                      <div className="d-flex gap-1 align-items-center">
                        <Skeleton width={20} height={20} circle={true} />
                        <small>
                          <Skeleton width={"60%"} />
                        </small>
                      </div>
                    </div>
                    <hr className="mt-0" />
                    <div className="d-flex justify-content-between px-2 align-items-center pb-3 ">
                      <div className="d-flex gap-1 align-items-center">
                        <Skeleton width={40} height={40} circle={true} />
                        <Skeleton width={30} />
                        <Skeleton width={40} height={40} circle={true} />
                        <Skeleton width={30} />
                        <Skeleton width={40} height={40} circle={true} />
                        <Skeleton width={30} />
                      </div>
                      <Skeleton width={100} />
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : null}
        {rtype === "TeamUp" ? (
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 gx-4 gy-4">
            {flatemate?.length > 0 ? (
              flatemate
                ?.filter((item) => item.teamUp)
                .map((item, index) => {
                  return (
                    <div className="" key={index}>
                      <FlateMateUserCard item={item} />
                    </div>
                  );
                })
            ) : (
              <>
                {Array.from({ length: 8 }, (_, index) => (
                  <div key={index} className="p-2">
                    <div className="shadow-3 rounded rounded-4 overflow-hidden">
                      <div style={{ height: "200px" }}>
                        <Skeleton height={"100%"} width={"100%"} />
                      </div>
                      <div className="rental-info p-1 px-2">
                        <div className="rental-info_price d-flex align-items-center justify-content-between">
                          <p className="fs-5">
                            <strong>
                              <Skeleton width={100} />
                            </strong>
                            <small>
                              <Skeleton width={30} />
                            </small>
                          </p>
                          <Skeleton width={40} height={40} circle={true} />
                        </div>

                        <h5 className="fw-bold">
                          <Skeleton width={"80%"} />
                        </h5>

                        <div className="d-flex gap-1 align-items-center">
                          <Skeleton width={20} height={20} circle={true} />
                          <small>
                            <Skeleton width={"60%"} />
                          </small>
                        </div>
                      </div>
                      <hr className="mt-0" />
                      <div className="d-flex justify-content-between px-2 align-items-center pb-3 ">
                        <div className="d-flex gap-1 align-items-center">
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                          <Skeleton width={40} height={40} circle={true} />
                          <Skeleton width={30} />
                        </div>
                        <Skeleton width={100} />
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : null}
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          position: "fixed",
          zIndex: "888",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box sx={style}>
          <AllRentalFilterModal rtype={rtype} />
        </Box>
      </Modal>

      <Box
        className="d-flex justify-content-center mt-5"
        sx={{
          marginRight: { xs: '10px', sm: '6%', md: '6%' },
        }}
      >
        <Pagination
          count={Math.ceil(totalrentalcount / resultperpage)}
          page={page}
          onChange={handlePagechange}
          variant="outlined"
          className="ms-auto"
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default AllRental;
