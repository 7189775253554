import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React from "react";
import FormInput from "../../../../components/Input/FormInput";
import Wrapper from "./OverviewStyle";

import { RemoveRedEye } from "@mui/icons-material";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, ButtonGroup } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SetOrganizationOtpAction,
  VerifyOrganizationOtpAction,
} from "../../../../context/actions/organizationAction";
import { isUpgrade } from "../../../../context/reducers/organizationSlice";
import { staticCImg, staticPImg } from "../../../../utils/globalSettings";
const Overview = ({
  organization_profile,
  reviewModal,
  toggleReviewModal,
  open,
  handleOpen,
  handleClose,
}) => {
  const option = {
    email: "",
    otp: "",
  };

  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [options, setOption] = React.useState(option);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "20px",
    p: 4,
  };
  const reviewStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: 800, xs: "97vw" },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: { xs: 1, sm: 2 },
  };
  const navigate = useNavigate();
  const handleChange = (e) => {
    setOption({ ...options, [e.target.name]: e.target.value });
  };

  // for send otp
  const onGetCode = (e) => {
    e.preventDefault();
    dispatch(SetOrganizationOtpAction({ email: options.email })).then((res) => {
      if (res.payload.success) {
        toast.success(res.payload.message);
        setValue(2);
      } else {
        toast.error(res.payload.message);
      }
    });
  };

  // this is the verify function that varify the otp
  const onverify = (e) => {
    e.preventDefault();
    dispatch(VerifyOrganizationOtpAction(options)).then((res) => {
      if (res.payload.success) {
        toast.success(res.payload.message);
        dispatch(isUpgrade());
        navigate("/organizations/upgrade");
      } else {
        toast.error(res.payload.message);
      }
    });
  };

  return (
    <Wrapper className="w-100 ">
      <div className="overview-header   w-100">
        <div className="overview-img  w-100 ">
          <div className="edit-icons position-absolute top-0   end-0 p-2 "></div>
          <img
            src={
              organization_profile?.introduction?.coverPage
                ? `${process.env.REACT_APP_IMAGE_PATH}${organization_profile?.introduction?.coverPage}`
                : staticCImg
            }
            alt=""
            srcSet=""
            className="object-cover w-100 h-100 "
          />
        </div>

        <div className="w-100 mt-5 d-flex justify-content-center">
          <Box
            className="profilepic position-relative  "
            sx={{
              height: "calc(50px + 7vw)",
              width: "calc(50px + 7vw)",
              maxHeight: "180px",
              maxWidth: "180px",
              cursor: "pointer",
              margin: "auto",
              backgroundColor: "white",
              border: { xs: "4px solid #f1f1f1", md: "10px solid #f1f1f1" },

              borderRadius: "50%",

              overflow: "hidden",
            }}
          >
            <img
              alt=""
              src={
                organization_profile?.introduction?.profile
                  ? `${process.env.REACT_APP_IMAGE_PATH}${organization_profile?.introduction?.profile}`
                  : staticPImg
              }
              className="object-cover object-center w-100 h-100"
            />

            <Box
              className="bg-black hover-section w-100 text-white d-flex  align-items-center justify-content-center fw-bold bg-opacity-50 position-absolute bottom-0  start-0  w-100"
              sx={{
                borderBottomLeftRadius: "300px",
                borderBottomRightRadius: "300px",
                visibility: "hidden",
                cursor: "pointer",
                height: "0",
                transition: "height 1s ",
              }}
              id="child-element"
            ></Box>
          </Box>
        </div>
      </div>

      <div className="overview-info text-center">
        {organization_profile?.introduction?.organizationName && (
          <h3>{organization_profile?.introduction?.organizationName}</h3>
        )}
        {organization_profile?.introduction?.organizationName && (
          <p className="my-2">
            {organization_profile?.introduction?.provenience} |{" "}
            {organization_profile?.introduction?.city}{" "}
          </p>
        )}
        {!organization_profile?.active &&
          organization_profile?.reviewMessage && (
            <div className="d-flex align-items-center gap-1  justify-content-center w-100">
              <MailOutlineIcon />
              <p>{organization_profile?.reviewMessage}</p>
            </div>
          )}
        <div className="d-flex gap-2 justify-content-center w-100 my-2">
          {!organization_profile?.introduction ? (
            <button
              className="btn  d-flex gap-2 align-items-center bg-black text-white"
              onClick={handleOpen}
            >
              <ArrowCircleUpIcon />
              upgrade
            </button>
          ) : organization_profile?.active ? (
            <ButtonGroup className="text-black" variant="outlined">
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <RemoveRedEye />
                  {organization_profile?.viewCount}
                </div>
              </Button>
              <Button>
                <div className="clapped d-flex align-content-center gap-1">
                  {/* <FacebookShareButton
                        url={'https://www.example.com'}
                        quote={'Dummy text!'}
                        hashtag="#muo"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton> */}
                  Share
                </div>
              </Button>
              <Button>
                <Link
                  to={`/organizations/${organization_profile?._id}`}
                  className="clapped d-flex align-content-center gap-1 align-items-center text-black"
                >
                  {/* <FacebookShareButton
                        url={'https://www.example.com'}
                        quote={'Dummy text!'}
                        hashtag="#muo"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton> */}
                  <ArrowOutwardIcon
                    sx={{
                      color: "black",
                    }}
                  />
                </Link>
              </Button>
            </ButtonGroup>
          ) : organization_profile?.reviewMessage && !organization_profile?.status  ? (
            <button
              className="btn  d-flex gap-2 align-items-center bg-danger text-white"
              onClick={toggleReviewModal}
            >
              Review Submit Application
            </button>
          ) : !organization_profile?.active && organization_profile?.status ? (
            <button
              className="btn  d-flex gap-2 align-items-center bg-danger text-white"
            >
              Disabled | Please contact admin
            </button>
          ): (
              <button className="btn btn-sm btn-outline-warning mt-2">
              We are reviewing your application. We will get back to you
            </button>
        
          )}
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="">
          {value === 1 ? (
            <Box sx={style}>
              <form onSubmit={onGetCode}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h1"
                  style={{ textAlign: "center", color: "black" }}
                >
                  Verify organization email
                </Typography>
                <FormInput
                  required={true}
                  label={"Email"}
                  type={"email"}
                  classname={"w-100 mt-2"}
                  handleChange={handleChange}
                  name="email"
                />

                <button className="btn-dark btn w-100 mt-2" type="submit">
                  Get code
                </button>
              </form>
            </Box>
          ) : null}
          {value === 2 ? (
            <Box sx={style}>
              <form className="">
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h1"
                  style={{ textAlign: "center", color: "black" }}
                >
                  Enter the Verification Code
                </Typography>
                <p>{options.value}</p>
                <FormInput
                  required={true}
                  label={"otp"}
                  type={"number"}
                  classname={"w-100 mt-2"}
                  handleChange={handleChange}
                  name={"otp"}
                />

                <p className="my-2">
                  Otp has send to{" "}
                  <strong className="text-success">{options.email}</strong>
                </p>
                <button className="btn-dark btn w-100" onClick={onverify}>
                  verify
                </button>
                <div className="d-flex justify-content-between w-100 mt-2">
                  <button
                    className="p-0 bg-transparent m-0 border-0 text-primary text-reset"
                    onClick={() => setValue(1)}
                  >
                    back
                  </button>
                </div>
              </form>
            </Box>
          ) : null}
        </div>
      </Modal>

      {/* <Modal
        open={reviewModal}
        onClose={toggleReviewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={reviewStyle}>
          {organization_profile?.user && (
            <UserEditOrg
              data={organization_profile}
              onClose={toggleReviewModal}
            />
          )}
        </Box>
      </Modal> */}
    </Wrapper>
  );
};

export default Overview;
