import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";

import React from "react";

const CustomSearch = ({ handleChange, handleKeyPress, placeholder, sx }) => {
  return (
    <OutlinedInput
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      defaultValue=""
      className="w-100"
      fullWidth
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <MagnifyingGlassIcon />
          </SvgIcon>
        </InputAdornment>
      }
      sx={sx}
    />
  );
};

export default CustomSearch;
