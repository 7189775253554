import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/eventApi";

// create event
export const adminCreateEventAction = createAsyncThunk(
  "admin/create_event",
  async ({ data , toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.createEvent(data);
      toast.success(response.data.message|| "Event created successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// edit event

export const adminEditEventAction = createAsyncThunk(
  "admin/edit_event",
  async ({ id, data, toast, index }, { rejectWithValue }) => {
    try {
      const response = await admin_api.editEvent(id, data);
      toast.success(response.data.message || "Event updated successfully");
      return { data: response.data, activeIndex:index };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get all event
export const adminGetAllEventAction = createAsyncThunk(
  "admin/get_all_event",
  async ({ keyword, page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.getAllEvent(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);






