import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { List, ListItem } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { Link } from "react-router-dom";
const CheckRequirementCard = ({ item, onCompleted }) => {
  const { title, description, attachment } = item;

  const [isChecked, setChecked] = React.useState(false);

  const toggleCheck = (e) => {
    e.stopPropagation();
    if (isChecked) {
      setChecked(false);
      onCompleted(false);
    } else {
      setChecked(true);
      onCompleted(true);
    }
  };
  return (
    <div className="w-100 p-0 m-0">
      <Accordion
        className="w-100 m- p-0"
        sx={{
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="d-flex align-items center gap-3 w-100"
        >
          {isChecked ? (
            <CheckBoxIcon onClick={toggleCheck} />
          ) : (
            <CheckBoxOutlineBlankIcon onClick={toggleCheck} />
          )}

          <Typography className="text-black">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails
          className="position-relative"
          sx={{
            borderTop: "1px solid black",
          }}
        >
          <ol component="ol">
            {description.map((item, index) => {
              return <li key={index}>{item.text}</li>;
            })}
          </ol>

          {attachment.length > 0 ? (
            <div className="">
              <Typography
                sx={{
                  color: "#10A37F",
                }}
                className="text-sucess d-flex align-items-center gap-2"
              >
                Attachments <AttachmentIcon />
              </Typography>
              <div className="d-flex flew-wrap gap-2">
                {attachment.map((item, index) => {
                  return (
                    <Link
                      to={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      key={index}
                      alt=""
                      className="text-text-decoration-underline "
                      role="button"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item
                        ? item.substring(item.lastIndexOf("/") + 1)
                        : "link"}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CheckRequirementCard;
