import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import moment from "moment";
import React, { useCallback, useEffect } from "react";
import { BsShareFill } from "react-icons/bs";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import BlogComment from "../../components/cards/comments/BlogComment";
import { post_bookmark } from "../../context/actions/authAction";
import {
  ShowBlogDetailsAction,
  blogPostComment,
  postLikeInBlog,
} from "../../context/actions/blogAction";
import formatViewCount from "../../utils/formatViewCount";
import BlogSuggestion from "../blogsearchresult/components/BlogSuggestion";

import {
  Favorite,
  FavoriteBorder,
  RemoveRedEye,
  SmsOutlined,
} from "@mui/icons-material";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import HTMLReactParser from "html-react-parser";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { Helmet } from "react-helmet-async";

const BlogDetail = () => {
  // get id for use Params

  const { id } = useParams();
  // use dispatch to trigger action
  const dispatch = useDispatch();
  // use useNavigate to navigate to another page
  const navigate = useNavigate();

  // use state hooks to set the state
  const [liked, setliked] = React.useState(false);
  const [bookmarked, setbookmarked] = React.useState(false);

  // use useState hooks to get the state data as needed
  const {
    blogs,
    isAuth,
    bookmarks,
    recommendedBlogs,
    loading,
    firstname,
    blogcomment,
    like,
    userid,
  } = useSelector((state) => ({
    loading: state.blog_reducer.loading,
    blogs: state.blog_reducer.blogs_details || {},
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
    recommendedBlogs: state.blog_reducer.recommendedBlogs || [],
    firstname: state.auth_slice.user_data?.firstname,
    userid: state.auth_slice.user_data?.id,
    blogcomment: state.blog_reducer.comments,
    like: state.blog_reducer.like,
  }));

  const togglelike = (e) => {
    e.stopPropagation();
    if (isAuth) {
      dispatch(postLikeInBlog(id));
    } else {
      navigate("/login");
    }
  };

  const initialState = {
    comment: "",
    name: firstname,
  };

  const [comment, setComment] = React.useState(initialState);
  const [error, setError] = React.useState('');

  // use callback  hooks to avoid infinite loop in umoseEffect
  const showblogDetails = useCallback(() => {
    dispatch(ShowBlogDetailsAction(id));
  }, [id]);

  // use to fetch the blog details
  useEffect(() => {
    showblogDetails();
  }, [showblogDetails]);

  // check if the like and bookmark is already there or not
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  const isLiked = like?.some((like) => like.id === id && like.user == userid);

  React.useEffect(() => {
    setliked(isLiked);
  }, [like, isLiked]);

  React.useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  // set the type of bookmark to initiate the bookmark
  const [type, setType] = React.useState("Blog");

  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
  });

  // set the bookmark
  React.useEffect(() => {
    if (blogs && bookmark.name !== blogs.title) {
      setBookmark({
        name: blogs.title,
        type: type,
        image: blogs.thumbnail,
      });
    }
  }, [blogs, bookmark.name, type]);

  // toogle the bookmark and if the bookmark is already there then return early
  const togglebookmark = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id, data: bookmark }));
    } else {
      navigate("/login");
    }
  };

  // handle comment change
  const handlecommentChnage = (e) => {
    e.preventDefault();
    setComment({ ...comment, [e.target.name]: e.target.value });
  };

  // push comment to server and remove the comment from the input field
  const handlecommentClick = (e) => {
    e.preventDefault();

    if (comment.comment === "") {
      setError('Please enter comment');
      return;
    }
    dispatch(blogPostComment({ id, comment }));
    // after commnet remove the comment from the input field
    setComment({ ...comment, comment: "" });
    setError('');

    //
  };

  const renderImg = (props) => {
    return (
      <img {...props} alt="" className="w-100 object-cover" height={450} />
    );
  };

  // copy link to share in clickboard
  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Your share link is ready!");
  };

  if ((blogs && Object?.keys(blogs).length === 0) || blogs === undefined) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>
          {blogs?.title}
        </title>
        <meta name="description"
          data-rh="true"
          content={blogs?.description} />
        <link rel="canonical" href="/register" />
      </Helmet>
      {loading ? (
        <div className="container mt-3">
          <div className="row row-cols-lg-2 row-cols-1 ">
            <section className="col-lg-8 m-2">
              <div className="blog-time">
                <div className="d-flex align-items-center">
                  <span className="fs-6 fw-bold">
                    <Skeleton variant="text" width={100} />
                  </span>
                  <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                    <Skeleton variant="circle" width={30} height={30} />
                    <Skeleton variant="rect" width={80} height={30} />
                  </div>
                </div>
                <Skeleton variant="text" width="100%" />
              </div>

              <div className="blog-info mt-3">
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="rect" width="100%" height={300} />
                <Skeleton variant="text" width="100%" />
              </div>

              <div className="action-section text-paragraph d-flex w-100 justify-content-between mt-3">
                <Skeleton variant="rect" width={150} height={30} />
              </div>

              <hr />
              <div className="d-flex align-items-center gap-2">
                <Skeleton variant="circle" width={45} height={40} />
                <Skeleton variant="rect" width="100%" height={50} />
              </div>

              <div className="comments-section mt-3">
                <Skeleton variant="text" width="50%" />
                <div className="d-flex flex-column gap-2">
                  <Skeleton variant="rect" width="100%" height={50} />
                </div>
              </div>
            </section>

            <section className="col-lg-3 m-2 ">
              <Box
                className="d-none d-lg-block h-maxcontent  position-sticky"
                sx={{
                  top: "10%",
                }}
              >
                <div className="">
                  <Skeleton variant="text" width="50%" />
                  <div className="d-flex flex-wrap gap-2 mt-4">
                    <Skeleton variant="rect" width={50} height={25} />
                  </div>
                </div>
                <div className="mt-3">
                  <Skeleton variant="text" width="50%" />
                  <div className="d-flex flex-column mt-2">
                    <Skeleton variant="rect" width="100%" height={50} />
                    <Skeleton variant="rect" width="100%" height={50} />
                  </div>
                </div>
              </Box>
            </section>
          </div>
        </div>
      ) : blogs && Object?.keys(blogs).length === 0 ? (
        <NotFoundPage />
      ) : (
        <div className="container mt-3">
          <div className="row row-cols-lg-2 row-cols-1 ">
            <section className="col-lg-8 m-2">
              <div className="blog-time">
                <div className="d-flex align-items-center">
                  <span className="fs-6 fw-bold">
                    {" "}
                    {moment(blogs?.createdAt).format("MMMM Do YYYY")}
                  </span>
                  <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                    {bookmarked ? (
                      <BookmarkIcon onClick={togglebookmark} role="button" />
                    ) : (
                      <MdOutlineBookmarkAdd
                        onClick={togglebookmark}
                        role="button"
                      />
                    )}
                    <Button
                      className="d-flex align-items-center gap-2 py-1"
                      onClick={copyLink}
                    >
                      <span>Share</span>
                      <BsShareFill />
                    </Button>
                  </div>{" "}
                </div>
                <p>
                  <Chip label={`${blogs.category}`} size="small" variant="filled" />

                  <span className="ms-3">
                    <RemoveRedEye /> {formatViewCount(blogs?.viewCount)}
                  </span>
                </p>
              </div>

              <div className="blog-info mt-3">
                <h3>{blogs?.title}</h3>
                <p className="mt-2 mb-2">{blogs?.description}</p>
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${blogs?.thumbnail}`}
                  alt="blog thumbnail"
                  className="object-cover rounded-4"
                  width="100%"
                />
                <p className="mt-2">
                  {typeof blogs?.content === "string" &&
                    HTMLReactParser(blogs?.content, {
                      transform: { img: renderImg },
                    })}
                </p>
              </div>

              <div className="action-section text-paragraph d-flex w-100 justify-content-between mt-3">
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button>
                    <div className="clapped d-flex align-content-center gap-1">
                      {!liked ? (
                        <FavoriteBorder
                          onClick={togglelike}
                          sx={{
                            color: "#C1C1C1",
                            fontSize: "clamp(0.7rem,1vw + 1em,1.6rem)",
                          }}
                        />
                      ) : (
                        <Favorite
                          onClick={togglelike}
                          sx={{
                            color: "#252020",
                            fontSize: "clamp(0.7rem,1vw = 1em,1.6rem)",
                          }}
                        />
                      )}
                      <span>{like?.length}</span>
                    </div>
                  </Button>
                  <Button>
                    {" "}
                    <div className="comments d-flex align-items-center gap-1">
                      <SmsOutlined />
                      <span>{blogcomment?.length}</span>
                    </div>
                  </Button>
                </ButtonGroup>
              </div>

              <hr />
              {isAuth ? (
                <div>
                  <div className="d-flex gap-2">
                    <img
                      src="https://images.unsplash.com/photo-1602030028438-4cf153cbae9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80"
                      width={45}
                      alt=""
                      height={40}
                      className="rounded rounded-circle object-cover"
                    />
                    <div className="form-outline d-flex flex-column">
                      <textarea
                        className="form-control border w-100"
                        id="textAreaExample"
                        rows="3"
                        name="comment"
                        placeholder="Write a comment..."
                        onChange={handlecommentChnage}
                      ></textarea>
                      {
                        error && <small className="text-danger">{error}</small>
                      }

                      <Typography
                        type='button'
                        fontSize={12}
                        onClick={handlecommentClick}
                        className="btn btn-sm btn-black shadow-none mt-2"
                        sx={{
                          width: { xs: '100%', md: '25%' }
                        }}
                      >
                        Post a comment
                      </Typography>

                    </div>

                  </div>


                </div>
              ) : (
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="https://images.unsplash.com/photo-1602030028438-4cf153cbae9e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=465&q=80"
                    width={45}
                    height={40}
                    alt=""
                    className="rounded rounded-circle object-cover"
                  />
                  <p>
                    Please <Link to="/login">Login</Link> to comment
                  </p>
                </div>
              )}

              <hr />

              <div className="comments-section mt-3">
                <h6>Showing all comments</h6>
                <div className="d-flex flex-column gap-2">
                  {blogcomment?.map((comment, index) => {
                    return (
                      <BlogComment
                        comments={comment}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </section>

            <section className="col-lg-3 m-2 ">
              <Box
                className="d-none d-lg-block h-maxcontent  position-sticky  "
                sx={{
                  top: "10%",
                }}
              >
                <div className="">
                  <span className="fs-6 fw-bold">Recommendations </span>
                  <div className="d-flex flex-wrap gap-2 mt-4">
                    {blogs?.tags?.map((item, index) => {
                      return (
                        <Chip
                          role="button"
                          key={index}
                          label={item}
                          size="medium"
                          variant="filled"
                          onClick={() => navigate(`/blogs/result?q=${item}`)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="mt-3">
                  <span className="">Similar Article</span>

                  <div className="d-flex flex-column mt-2">
                    {recommendedBlogs?.map((blogs, index) => {
                      return <BlogSuggestion key={index} blogs={blogs} />;
                    })}
                  </div>
                </div>
              </Box>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetail;
