import * as React from 'react';

// import eye icon
// import { AiOutlineEye } from "react-icons/ai";
import { Link } from 'react-router-dom';
import StyledSection from './NewsCardStyle';

export default function NewsCard({ item }) {

    return (

        <>
            <StyledSection>
                <div className='mt-3 p-0 overflow-hidden newscard'>
                    <img className='w-100 object-cover rounded-2' height="150px" src={`${process.env.REACT_APP_IMAGE_PATH}${item.thumbnail}`} alt="" />
                    <p className='m-0 n-title text-black'>{item.title}</p>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='fw-bold text-black'>
                            by {item.author ? item.author : 'ONF Canada'}
                        </p>
                    </div>
                    <div>
                    </div>
                </div>
            </StyledSection>
        </>

    );
}