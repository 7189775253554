
import MapBoxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useControl } from 'react-map-gl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';


const GeoCoder = ({handleLocation}) => {
    const ctrl = new MapBoxGeocoder({
        accessToken: process.env.REACT_APP_MAP_TOKEN,
        marker: false,
        countries:"ca",
        placeholder: 'Search for places',
        collapsed: true,
        types: 'country,region,place,postcode,locality,neighborhood'
        

    });

    useControl(()=> ctrl)
    ctrl.on('result', (e) => {
        const location_details = e.result;
        handleLocation(location_details)
    });

  return null;
}

export default GeoCoder