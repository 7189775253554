import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/dashboradApi";

//

//count_documents_and_view_count
export const adminCountDocumentsAndViewsAction = createAsyncThunk(
    "admin/count_documents_and_view_count",
    async (__, { rejectWithValue }) => {
        try {
            const response = await admin_api.adminCountDocumentsAndViews();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

// get all logs
export const adminGetAllLogsAction = createAsyncThunk(
    "admin/get_all_logs",
    async (page, { rejectWithValue }) => {
        try {
            const response = await admin_api.adminGetAllLogs(page);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

