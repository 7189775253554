import EllipsisVerticalIcon from "@heroicons/react/24/solid/EllipsisVerticalIcon";
import {
  Box,
  Card,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  TablePagination,
  SvgIcon,
} from "@mui/material";
import moment from "moment";

export const OverviewActivityLog = (props) => {
  const {
    activity = [],
    sx,
    totalcount,
    resultperpage,
    handlePageChange,
    page,
    
  } = props;

  return (
    <Card sx={sx}>
      <div className="d-flex justify-content-between m-3 flex-wrap">
        <h3>Activity Log</h3>
        {/* input */}
        <input
          type="text"
          placeholder="Search activity"
          className="form-control w-25"
        />
      </div>
      <List>
        {activity.map((logs, index) => {
          const hasDivider = index < logs.length - 1;
          // const ago = formatDistanceToNow(activity.updatedAt);

          return (
            <ListItem divider={hasDivider} key={logs?._id}>
              <ListItemAvatar>
                <Box
                  component="img"
                  src="assets/images/admin.png"
                  sx={{
                    borderRadius: 1,
                    height: 48,
                    width: 48,
                  }}
                />
              </ListItemAvatar>
              {/* {moment(blogs?.createdAt).format("MMMM Do YYYY")} */}
              <ListItemText
                primary={logs.message}
                primaryTypographyProps={{ variant: "subtitle1" }}
                secondary={moment(logs?.timestamp).format("MMMM Do YYYY")}
                secondaryTypographyProps={{ variant: "body2" }}
              />
              <IconButton edge="end">
                <SvgIcon>
                  <EllipsisVerticalIcon />
                </SvgIcon>
              </IconButton>
            </ListItem>
          );
        })}
      </List>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <TablePagination
          page={page}
          count={totalcount}
          rowsPerPage={resultperpage}
          onPageChange={handlePageChange}
          
          
        />
      </CardActions>
    </Card>
  );
};
