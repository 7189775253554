import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VerifiedIcon from "@mui/icons-material/Verified";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

import DoneIcon from "@mui/icons-material/Done";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { BiPencil } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordAction } from "../../context/actions/authAction";
import EditProfile from "./modal/EditProfile";
import { OrganizationTab, RentalTab } from "./tabs";
import BusinessTab from "./tabs/business/BusinessTab";
import ProfessionalTab from "./tabs/professional/ProfessionalTab";
import Compressor from "compressorjs";
import { BsKey } from "react-icons/bs";
import { toast } from "react-toastify";
import { clearError } from "../../context/reducers/authSlice";
import ChangePassword from "./modal/ChangePassword";

const Profile = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [coverimg, setCoverimg] = React.useState({
    isEditing: false,
    preview: null,
    file: null,
  });
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // change password modal
  const [openChangePassword, setOpenChangePassword] = React.useState(false);

  const handleOpenChangePassword = React.useCallback(() => {
    setOpenChangePassword(true);
  }, []);

  const handleCloseChangePassword = React.useCallback(() => {
    setOpenChangePassword(false);
  }, []);

  const onEdit = React.useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      new Compressor(file, {
        quality: 0.1,

        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(result) {
          // The third parameter is required for server
          console.log(result);
          const blobURL = URL.createObjectURL(result);

          setCoverimg((prevImg) => ({
            ...prevImg,
            isEditing: true,
            preview: blobURL,
            file: result,
          }));
        },
        error(err) {
          console.log(err.message);
        },
      });

      e.target.value = "";
    }
  }, []);

  const onsave = React.useCallback(() => {
    setCoverimg((prevImg) => ({
      ...prevImg,
      isEditing: false,
      coverimg: prevImg.preview,
      preview: null,
    }));
  }, []);

  const oncancel = React.useCallback(() => {
    setCoverimg((prevImg) => ({
      ...prevImg,
      isEditing: false,
      preview: null,
    }));
  }, []);

  const { user_data, error } = useSelector((state) => ({
    user_data: state.auth_slice.user_data,
    error: state.user_reducer.error,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  const [resetpassword, setresetpassword] = React.useState({
    oldpassword: "",
    newpassword: "",
    confirmPassword: "",
  });
  const [perrors, setpErrors] = React.useState({
    oldpassword: "",
    newpassword: "",
    confirmPassword: "",
  });

  const handleRestPasswordChange = (e) => {
    setresetpassword((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  //handleFormSubmit

  const handleFormSubmit = (e) => {
    const { oldpassword, newpassword, confirmPassword } = resetpassword;
    let newerror = {
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
    };
    if (
      !oldpassword ||
      !newpassword ||
      !confirmPassword ||
      !newpassword ||
      (newpassword.length > 8 && newpassword !== confirmPassword) ||
      newpassword.length < 8
    ) {
      if (!oldpassword) {
        newerror.oldpassword = "Please provide old password *";
      }
      if (!newpassword) {
        newerror.newpassword = "Please provide new password *";
      }
      if (!confirmPassword) {
        newerror.confirmPassword = "Please provide confirm password *";
      }
      if (!newpassword) {
        newerror.newpassword = "Please provide new password *";
      }
      if (newpassword.length < 8) {
        newerror.newpassword = "password should be at least 8 character *";
      }
      if (newpassword.length > 8 && newpassword !== confirmPassword) {
        newerror.confirmPassword = "password do not match *";
      }
      setpErrors(newerror);
    } else {
      const formData = new FormData();
      formData.append("oldPassword", resetpassword.oldpassword);
      formData.append("newPassword", resetpassword.newpassword);

      dispatch(ChangePasswordAction({ formData: formData, toast: toast }));
    }
  };

  return (
    <Box className="container profile">
      <Box
        sx={{
          display: "flex",
          height: { xs: "max-content", md: "30vh" },
          maxHeight: { md: "35vh" },
          minHeight: { md: "35vh" },
          alignItems: "center",
          position: "relative",
        }}
        className=" profile-one d-flex flex-column justify-content-end"
      >
        <div className="position-relative profile-one-info w-100 h-100  d-flex align-items-end position-relative">
          <Box
            sx={{
              height: { xs: "40%", md: "61%" },
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              borderRadius: "10px",
            }}
            className="profile-one-cover overflow-hidden "
          >
            <img
              src={
                coverimg.preview ??
                coverimg.coverimg ??
                "./assets/images/coverpage.jpg"
              }
              alt=""
              srcSet=""
              className="w-100 h-100 object-cover position-absolute top-0 start-0"
            />
          </Box>
          
          <Box
            className="  profile-one-i  gap-3 "
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "end" },
              zIndex: "1",
              marginTop: { xs: "15%", md: 0 },
            }}
          >
            <Box
              sx={{
                paddingLeft: { md: "4%" },
              }}
            >
              <Box
                className="  "
                sx={{
                  height: { xs: "calc(6em + 6vw)", md: "calc(6em + 7vw)" },
                  width: { xs: "calc(6em + 6vw)", md: "calc(6em + 7vw)" },
                  maxHeight: "180px",
                  maxWidth: "180px",
                  margin: "auto",
                  cursor: "pointer",

                  borderRadius: "50%",
                  border: "10px solid #f1f1f1",

                  overflow: "hidden",
                }}
              >
                <img
                  alt="Remy Sharp"
                  src={
                    `${process.env.REACT_APP_IMAGE_PATH}${user_data?.profilepic}` ??
                    "/assets/images/userImg.jpg"
                  }
                  className="object-cover object-center w-100 h-100"
                />
              </Box>
            </Box>
            <Box
              xs={{
                display: "flex",

                flexDirection: { xs: "column", md: "row" },
              }}
              className=" w-100 align-items-center    "
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { md: "space-between" },
                  alignItems: { xs: "center", md: "start" },
                  gap: "2%",
                }}
                className=""
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column" },

                    alignItems: { xs: "center", md: "start" },
                  }}
                  className=""
                >
                  <Typography
                    variant="h6"
                    color="black"
                    sx={{
                      zIndex: "1",
                      whiteSpace: "nowrap",
                    }}
                    className="p-0 me-2"
                  >
                    {user_data?.firstname} {user_data?.lastname}
                    {user_data?.isverified ? (
                      <VerifiedIcon fontSize="25px" color="blue" />
                    ) : (
                      <VerifiedIcon fontSize="25px" color="blue" />
                    )}
                  </Typography>
                  <p>{user_data?.email}</p>
                  <div className="d-flex mt-2">
                    {/* Circular image flag */}
                    <img
                      src="https://www.countryflags.com/wp-content/uploads/nepal-flag-png-large.png"
                      alt="flag"
                      className="rounded-circle"
                      width="20"
                      height="20"
                    />
                    <p className="ms-2">Nepal</p>
                  </div>
                </Box>
                <div>
                  <button
                    aria-label=""
                    className="btn btn-outline-black w-maxcontent "
                    onClick={handleOpenChangePassword}
                  >
                    Change Password <BsKey />
                  </button>
                  <button
                    aria-label=""
                    className="btn btn-outline-black w-maxcontent ms-2"
                    onClick={handleOpen}
                  >
                    Edit Profile <BiPencil />
                  </button>
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>

      <section>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                // flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                flexWrap: "wrap",
              }}
              className="d-flex justify-content-between align-items-center"
            >
              <Tabs
                className="m-0 p-0 mb-3 "
                value={value}
                onChange={handleChange}
                centered
                indicatorcolor={""}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                <Tab className="fw-bold " label="Rental" value="1" />
                <Tab className="fw-bold" label="Organization" value="2" />
                <Tab className="fw-bold" label="Bussiness" value="3" />
                <Tab
                  className="fw-bold"
                  label="Professinal Profile"
                  value="4"
                />
              </Tabs>
            </Box>
            <TabPanel value="1" className="py-1 px-0 m-0">
              <RentalTab />
            </TabPanel>
            <TabPanel value="2" className="py-1 px-0 m-0">
              <OrganizationTab />
            </TabPanel>
            <TabPanel value="3" className="py-1 px-0 m-0">
              <BusinessTab />
            </TabPanel>
            <TabPanel value="4" className="py-1 px-0 m-0">
              <ProfessionalTab />
            </TabPanel>
          </TabContext>
        </Box>
      </section>

      <EditProfile open={open} handleClose={handleClose} data={user_data} />
      <ChangePassword
        open={openChangePassword}
        handleClose={handleCloseChangePassword}
        handleFormChnage={handleRestPasswordChange}
        formvalue={resetpassword}
        handleSubmit={handleFormSubmit}
        perrors={perrors}
      />
    </Box>
  );
};

export default Profile;
