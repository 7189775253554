import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
    InputAdornment,
    OutlinedInput,
    SvgIcon
} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NewsSearchCard from "./NewsSearchCard";
import allnews from "../../utils/all_news";

const NewsSearchResult = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [news, setNews] = React.useState([]);
    // loading
    const [loading, setLoading] = React.useState(true);


    const location = useLocation();
    const queryParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    );
    const query = queryParams.get("q");

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const newQuery = event.target.value;
            queryParams.set("q", newQuery);
            navigate(`${location.pathname}?${queryParams.toString()}`);
        }
    };

    useEffect(() => {
        const getNews = async ({ query }) => {
            try {
                const response = await fetch(
                    `https://newsapi.org/v2/everything?q=${query}&apiKey=b1264a9c238c48f0b2cd188bd6cb0e08`
                );
                const data = await response.json();
                setNews(data.articles);
                setLoading(false);

            } catch (error) {
               
            }
        };
        getNews({ query });
    }, [query, dispatch]);


    const [value, setValue] = React.useState("0");
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const tablst = ["Latest News"];


    return (
        <div className="container mt-2">
            <section className="userblog-left col-lg-12">
                <Box className="d-flex justify-content-between" sx={{
                    flexDirection: {xs:"column", md:"row"},
                }}>
                    <h2 className="col-lg-6">
                        Latest news for <strong className="fw-bold text-black">{query}</strong>{" "}
                    </h2>
                    <OutlinedInput
                        onKeyPress={handleKeyPress}
                        className="h-100"
                        defaultValue=""
                        fullWidth
                        placeholder="Search immigration, visa, jobs, news, etc."
                        sx={{
                            border: "none",
                            borderRadius: "10px",
                        }}
                        startAdornment={
                            <InputAdornment position="start">
                                <SvgIcon color="action" fontSize="small">
                                    <MagnifyingGlassIcon />
                                </SvgIcon>
                            </InputAdornment>
                        }
                    />
                </Box>

                <TabContext value={value} className="m-0 p-0">
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={handleTabChange}
                            aria-label="lab API tabs example"
                        >
                            {tablst.map((item, index) => {
                                const value = index.toString();
                                return <Tab key={item} label={item} value={value} />;
                            })}
                        </TabList>
                    </Box>
                    <TabPanel value="0" className="m-0 p-0">
                        {
                            loading ? (
                                <>
                                    {Array.from({ length: 8 }, (_, index) => (
                                        <div 
                                        key={index}
                                        
                                        className="card mb-3 shadow-4">
                                            <div className="g-0 d-flex">
                                                <Skeleton width={200} height={200} className="object-cover rounded-3" />
                                                <div className="col-md-8">
                                                    <div className="card-body">
                                                        <h5 className="card-title fw-bold">
                                                            <Skeleton height={24} width={300} />
                                                        </h5>
                                                        <p className="card-text">
                                                            <Skeleton count={3} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            ) : (
                                allnews ? news.map((item, index) => (
                                allnews?.map((item, index) => {
                                    return <div 
                                    key={index}
                                    onClick={() => {
                                        window.open(item.url);
                                    }} className="d-flex flex-column mt-2">
                                        <NewsSearchCard key={index} news={item} />
                                    </div>
                                })
                               )): <h1>No news found</h1>
                            )
                        }
                    </TabPanel>
                </TabContext>
            </section>


        </div>
    );
};

export default NewsSearchResult;
