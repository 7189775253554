import React from "react";
import styled from "styled-components";
import { Box, Drawer } from "@mui/material";
import { ImCross } from "react-icons/im";
import { CgMenuGridR } from "react-icons/cg";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

// test data

import { BiPencil } from "react-icons/bi";
import { TabList } from "@mui/lab";

import {
  AdminBusinessDetailIntro,
  AdminBusinessDetailContact,
  AdminBusinessDetailOffers,
} from "./tabs";
import EditBusiness from "./editbusiness/EditBusiness";

import { useDispatch,  } from "react-redux";
import { pictureModal } from "../../../../utils/modalSettings";

import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;
    gap: 5px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;

const UserBuinessEdit = ({ data, closeModal, activeindex }) => {
  const dispatch = useDispatch();
  // get the id from the url

  // dispatch the action

  const { contactAndLinks, introduction, images, offers, active } = data;
  const business_comments = [];

  const average_rating = 5;
  const avg_rating = 5;

  // open image modal
  const [open, setOpen] = React.useState(false);

  const [id] = React.useState(data?._id);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  // tab change
  const [value, setValue] = React.useState("0");

  // like state

  // on tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

 

  // render the jsx

  const tabs = ["Information", "Contact Details", "Offers"];
  return (
    <Box
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="container  d-flex flex-column gap-3"
    >
      <section className="rental-header w-100 ">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Preview for your business listing
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
              role="button"
              onClick={closeModal}
            />
          </Box>
        </div>
        <div className="d-flex w-100 justify-content-between mt-1 align-items-center">
          {data?.status && data?.active ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-success h-maxcontent text-nowrap"
              data-mdb-ripple-color="dark"
            >
              satus : Approved
            </button>
          ) : data?.status && !data?.active ? (
            <p
              type="button"
              className="btn btn-sm btn-outline-danger h-maxcontent text-nowrap"
              data-mdb-ripple-color="dark"
            >
              Deactivated | Please contact admin
            </p>
          ) : data?.reviewMessage ? (
            <button
              type="button"
              className="btn btn-sm btn-outline-danger h-maxcontent text-nowrap"
              data-mdb-ripple-color="dark"
              onClick={toggleDrawer}
            >
              RESUBMIT APPLICATION
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-outline-dark h-maxcontent text-nowrap"
              data-mdb-ripple-color="dark"
            >
              STATUS : pending
            </button>
          )}
          {data?.status && data?.active ? (
            <div className="rental-actionbtn d-flex gap-2 align items-center h-50 mt-2">
              <button
                type="button"
                className="btn btn-sm btn-outline-dark"
                data-mdb-ripple-color="dark"
                onClick={toggleDrawer}
              >
                <BiPencil /> Edit Business
              </button>

            </div>
          ) : data?.status && !data?.active ? (
            <p
             className="text-danger"
            >
              Your business is no longer visible
            </p>
          ): (
            data?.reviewMessage && (
              <div className="d-flex align-items-center gap-1  ">
                <MailOutlineIcon className="text-danger" />
                <p>{data?.reviewMessage}</p>
              </div>
            )
          )}
        </div>
      </section>
      {/* Images Section */}
      <Wrapper className="w-100">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridAutoRows: { xs: "140px", md: "170px", lg: "200px" },
            borderRadius: "25px",
          }}
          className="grid-images position-relative rounded rounded-3 overflow-hidden"
        >
          {images.map((item, index) => {
            return (
              <div className="w-100 h-100 item" key={index}>
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  className="w-100 object-cover h-100"
                />
              </div>
            );
          })}
          {/* open modal button */}
          <Box
            sx={{
              position: "absolute",
              bottom: "3%",
              right: "1%",
            }}
            className="btn d-flex gap-2 align-items-center bg-white"
            onClick={handleOpen}
          >
            <CgMenuGridR className="position bottom-0" />
            <span>Show all photos</span>3
          </Box>
        </Box>
      </Wrapper>

      <TabContext value={value.toString()} className="w-100">
        <TabList
          onChange={handleChange}
          aria-label="lab API tabs example"
          sx={{
            background: "#F6F6F6",
          }}
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                label={item}
                value={index.toString()}
                key={item}
                className="text-capitalize"
                indicatorcolor={""}
                sx={{
                  "&.Mui-selected": {
                    color: "#797979",
                    fontWeight: "bolder",
                    border: "none",
                  },
                }}
              />
            );
          })}
        </TabList>

        <TabPanel className="m-0 p-0" value="0">
          <AdminBusinessDetailIntro
            average_rating={average_rating}
            avg_rating={avg_rating}
            business_comments_count={business_comments?.length}
            introduction={introduction}
          />
        </TabPanel>
        <TabPanel className="m-0 p-0" value="1">
          <AdminBusinessDetailContact item={contactAndLinks} />
        </TabPanel>
        <TabPanel className="m-0 p-0" value="2">
          <AdminBusinessDetailOffers offers={offers} />
        </TabPanel>
      </TabContext>

      {/* Image slider Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={pictureModal} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            role="button"
            onClick={handleClose}
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <TabContext value={value}>
            {images.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      className=" object-contain"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <Tabs
              onChange={handleChange}
              className=" mx-auto "
              sx={{
                maxWidth: "100%",

                width: "max-content",
                "&.Mui-selected": {
                  color: "#797979",
                  fontWeight: "bolder",
                  border: "none",
                },
              }}
              centered
              indicatorcolor={""}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={value}
            >
              {images.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0"
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center"
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          widht={90}
                          className="object-cover w-100 mx-auto p-0"
                        />
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          </TabContext>
        </Box>
      </Modal>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <EditBusiness
          data={data}
          activeindex={activeindex}
          closeModal={toggleDrawer}
        />
      </Drawer>
    </Box>
  );
};

export default UserBuinessEdit;
