import React, { useState } from "react";

import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfessionalReviewTable from "./ProfessionalReviewTable";
import ProfessionalTable from "./ProfessionalTable";
import AdminAddProfessional from "./adminProfessionalDetail/AdminProfessionalDetail";

const AdminProfessional = () => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("all");
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  const [activeComponent, setActiveComponent] = useState(<ProfessionalTable />);
  const [page, setPage] = useState(0);
  const [status, setStatus] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  // open add drawer
  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const buttons = [
    <Button key="one">Added By User</Button>,
    <Button key="two">Added By Admin</Button>,
  ];
  // useEffect(() => {
  //   dispatch(
  //     adminGetAllProfessionalAction({
  //       page: page,
  //       keyword: keyword,
  //       status: status,
  //     })
  //   );
  // }, [dispatch, page, keyword, activeButton]);

  // const {
  //   professional_loading,
  //   professional,
  //   resultperpage,
  //   totalprofessionalcount,
  // } = useSelector((state) => state.admin_reducer);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
            <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  Showing all experts and professionals
                </span>
                <small>
                  Skilled and experienced professionals and experts listed by users and admins
                </small>
              </div>
              <div>
                <Button
                  sx={{ mr: 1 }}
                  variant={activeButton === "all" ? "contained" : "outlined"}
                  onClick={() => {
                    setActiveButton("all");
                    setActiveComponent(<ProfessionalTable />);
                  }}
                >
                  All Professional
                </Button>
                <Badge badgeContent={4} color="error" sx={{ mr: 1 }}>
                  <Button
                    variant={
                      activeButton === "review" ? "contained" : "outlined"
                    }
                    onClick={() => {
                      setActiveButton("review");
                      setActiveComponent(<ProfessionalReviewTable />);
                    }}
                  >
                    Review Professionals
                  </Button>
                </Badge>

                <Button
                  variant="outlined"
                  onClick={() => navigate("/admin/professionals/add")}
                >
                  ADD
                </Button>
              </div>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OutlinedInput
                sx={{}}
                size="small"
                defaultValue=""
                fullWidth
                placeholder="Search rental"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(event) => {
                  if (event.target.value === "") {
                    setKeyword("");
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setKeyword(event.target.value);
                  }
                }}
              />
              <ButtonGroup color="primary" size="small" variant="outlined">
                {buttons}
              </ButtonGroup>
            </Stack>

            {activeButton === "all" ? (
              <ProfessionalTable
                keyword={keyword}
               
                page={page}
                setPage={setPage}
              />
            ) : (
              <ProfessionalReviewTable keyword={keyword} />
            )}
          </Stack>
        </Container>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          <AdminAddProfessional onClose={toggleDrawer} />
        </Drawer>
      </Box>
    </>
  );
};

export default AdminProfessional;
