import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, Chip } from "@mui/material";
import React from "react";
import { ImCross } from "react-icons/im";

const ProfessionalMOffers = ({ onClose }) => {
  const [errors, setErrors] = React.useState({});
  const [offering, setOffering] = React.useState("");
  const [formvalue, setformvalues] = React.useState({ offers: [] });

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "addoffer":
        const offer = value.trim();
        if (offer !== "" && !formvalue.offers.includes(offer)) {
          setformvalues({
            ...formvalue,
            offers: [...formvalue.offers, offer],
          });
        }

        break;
      case "removeoffer":
        const lst = formvalue.offers;
        const newlst = lst.filter((item) => item !== value);
        setformvalues({
          ...formvalue,
          offers: newlst,
        });
        break;

      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };
  const handleOffering = (e) => {
    setOffering(e.target.value);
  };

  const onkeyPress = (e) => {
    if (e.key === "Enter") {
      handleFormValue("addoffer", e.target.value);
      setOffering("");
    }
  };

  const addIconPress = () => {
    handleFormValue("addoffer", offering);
    setOffering("");
  };

  const onSubmit = () => {
  
  };
  return (
    <Box
      sx={{
        minHeight: "60vh",
      }}
      className="d-flex flex-column gap-3"
    >
      <Box className="d-flex align-items-center justify-content-between m-0">
      <div className="d-flex flex-column">
        <h5 className="text-black fw-bold m-0 p-0">Offered services</h5>
        <small className="text-black">
          create your offering and hit enter
        </small>
        </div>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>

      <hr />

      <section>
        <Box
          sx={{
            width: { lg: "80%" },
          }}
          className="form-group"
        >
          <div className="d-flex align-items-center gap-2">
            <input
              type="text"
              className={
                errors.offers
                  ? "form-control is-invalid py-2 m-0"
                  : "form-control py-2"
              }
              placeholder="Create your offering and hit enter"
              onChange={handleOffering}
              value={offering}
              onKeyPress={onkeyPress}
            />
            <AddCircleIcon role="button" onClick={addIconPress} />
          </div>
          <small
            id="offerhelp"
            className={errors.offers ? "form-text text-danger" : ""}
          >
            {errors.offers}
          </small>
        </Box>

        <div className="d-flex flex-wrap gap-1 mt-2">
          {formvalue.offers.map((item, index) => {
            return (
              <Chip
                key={index}
                onDelete={() => handleFormValue("removeoffer", item)}
                label={item}
              />
            );
          })}
        </div>
      </section>

      <button
        className="btn-dark btn ms-auto mt-auto align-self-end justify-self-end"
        onClick={onSubmit}
      >
        Save
      </button>
    </Box>
  );
};

export default ProfessionalMOffers;
