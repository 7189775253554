import { Box } from "@mui/material";
import React from "react";
import { FormInputB, FormSelectB } from "../../../components/Index";
import { occupations } from "../../../utils/flateMateSettings";
import { genderlst, Ages } from "../../../utils/flateMateSettings";
const FlateMateInfo = ({
  errors,
  formvalue,
  handlePhoneNumber,
  handleFormChange,
}) => {
  return (
    <div className="mt-3">
      {/* Heading */}
      <div>
        <h4 className="text-black fw-bold p-0 m-0">General Information</h4>
        <small>
          This information will be displayed publicly on your listing
        </small>
      </div>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", lg: "row-reverse" },
          justifyContent: "space-between",
          gap: "2%",
          width: "100%",
        }}
        className="  gap-1 w-100 "
      >
        <div className="">
          <Box
            className="position-relative  "
            sx={{
              aspectRatio: "1/1",
              height: "50vh",
              marginInline: { xs: "auto", md: "" },
              border:
                errors.userPic.length > 0
                  ? "2.5px solid var(--invalid-border)"
                  : "",
            }}
          >
            <input
              type="file"
              name="userPic"
              id="userPic"
              accept="images/*"
              className="d-none"
              onChange={handleFormChange}
            />
            <img
              src={
                formvalue.userPic.length > 0
                  ? formvalue.userPic
                  : "/assets/images/userPic.png"
              }
              alt=""
              srcSet=""
              className="object-cover w-100 h-100 rounded rounded-3"
            />
            <label
              htmlFor="userPic"
              className="position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center"
            >
              Edit Profile Picture
            </label>
          </Box>

          <small
            id="emailHelp"
            className={errors.userPic ? "form-text text-danger" : ""}
          >
            {errors.userPic}
          </small>
        </div>
        <Box
          sx={{
            width: { xm: "95%", lg: "55%" },
          }}
          className="row-cols-lg-2 row-cols-1 row"
        >
          <div className="">
            <FormInputB
              name="firstName"
              type="text"
              label="First name"
              placeholder="Enter Your First Name"
              value={formvalue.firstName}
              handleChange={handleFormChange}
              error={errors.firstName ? true : false}
              helpertext={errors.firstName}
            />
          </div>
          <div className="">
            <FormInputB
              name="lastName"
              label="Last name"
              type="text"
              placeholder="Enter Your Last Name"
              value={formvalue.lastName}
              handleChange={handleFormChange}
              error={errors.lastName ? true : false}
              helpertext={errors.lastName}
            />
          </div>
          <div className="mt-2">
            <FormSelectB
              name="age"
              label={"Age"}
              list={Ages}
              placeholder="Select Your Age"
              value={formvalue.age}
              handleChange={handleFormChange}
              error={errors.age ? true : false}
              helpertext={errors.age}
            />
          </div>
          <Box className="mt-2">
            <FormSelectB
              name="gender"
              label={"Gender"}
              list={genderlst}
              placeholder="Select Your Gender"
              value={formvalue.gender}
              handleChange={handleFormChange}
              error={errors.gender ? true : false}
              helpertext={errors.gender}
            />
          </Box>
          <div className="">
            <FormInputB
              name="email"
              type="text"
              label="Email"
              placeholder="Enter Your Email"
              value={formvalue.email}
              handleChange={handleFormChange}
              error={errors.email ? true : false}
              helpertext={errors.email}
            />
          </div>
          <div className="">
            <FormInputB
              name="contact"
              type={"number"}
              label="Contact"
              placeholder="613-555-5555"
              value={formvalue.contact}
              handleChange={handleFormChange}
              error={errors.contact ? true : false}
              helpertext={errors.contact}
            />
          </div>
          <Box
            className=""
            sx={{
              marginTop: "10px",
            }}
          >
            <FormSelectB
              name="occupation"
              label={"Occupation"}
              list={occupations}
              placeholder="Select Your Occupation"
              value={formvalue.occupation}
              handleChange={handleFormChange}
              error={errors.occupation ? true : false}
              helpertext={errors.occupation}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FlateMateInfo;
