import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Drawer, Tab, Typography } from "@mui/material";
import React from "react";
import { BiPencil } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { adminChangeUserStatusAction } from "../../../../../context/actions/admin/userAction";
import AdminUserEdit from "../AdminUserEdit/AdminUserEdit";
import AdminUserDetailInformation from "./tabs/AdminUserDetailInformation";
const AdminUserDetail = ({ onClose, data, id, activeindex }) => {
  const { active } = data;
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const dispatch = useDispatch();
  const tabs = ["Information"];

  const [tabValue, setTab] = React.useState("Information");

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => setOpen((prev) => !prev);
  // change tabs
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const handleActiveStatusFalse = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeUserStatusAction({
        data: false,
        id: id,
        toast: toast,
        activeIndex: activeindex,
      })
    );
  };

  const handleActiveStatusTrue = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeUserStatusAction({
        data: true,
        id: id,
        toast: toast,
        activeIndex: activeindex,
      })
    );
  };

  return (
    <Container
      sx={{
        minHeight: "100vh",
        width: { xs: "100vw", lg: "70vw", md: "80vw", sm: "90vw" },
        paddingTop: "2vh",
      }}
      className=""
    >
      <section className="option-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-6 text-black"></span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <section className="  d-flex text-center flex-column gap-1 align-items-center w-100 mb-4">
        <Box
          className="profilepic"
          sx={{
            height: "calc(50px + 7vw)",
            width: "calc(50px + 7vw)",
            maxHeight: "180px",
            maxWidth: "180px",
            margin: "auto",
            cursor: "pointer",
            borderRadius: "50%",
            border: "10px solid #f1f1f1",
            overflow: "hidden",
          }}
        >
          <img
            alt="Remy Sharp"
            src={`${process.env.REACT_APP_IMAGE_PATH}${data.profilepic}`}
            className="object-cover object-center w-100 h-100"
          />
        </Box>

        <Typography
          variant="span"
          sx={{
            fontSize: { lg: "2.6em", xs: "1em" },
          }}
        >
          {data.firstname} {data.lastname}
        </Typography>
        <Typography
          variant="span"
          className="d-flex align-items-center gap-1 bg-hover px-2"
          sx={{
            fontSize: { lg: "0.8em", xs: "0.6em" },
          }}
        >
          <span className="fw-bold">{data.email}</span>
          <CheckBoxIcon
            sx={{
              color: data.verified ? "#00D26A" : "",
            }}
          />
        </Typography>

        <div className="d-flex align-items-center gap-1">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark me-2"
            data-mdb-ripple-color="dark"
            onClick={toggleModal}
          >
            <BiPencil /> Edit
          </button>
          {active ? (
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusFalse}
            >
              Deactive
            </button>
          ) : (
            <button
              type="button"
              class="btn btn-sm btn-success"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusTrue}
            >
              Active
            </button>
          )}
        </div>
      </section>

      <TabContext value={tabValue} className=" ">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{
              background: "#F6F6F6",
            }}
            centered
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  indicatorcolor={"transparent"}
                  className="text-capitalize"
                  sx={{
                    "&.Mui-selected": {
                      color: "#797979",
                      fontWeight: "bolder",
                      border: "none",
                    },
                    "&.MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  label={item}
                  key={index}
                  value={item}
                />
              );
            })}
          </TabList>
        </Box>

        <TabPanel
          className="d-flex flex-column gap-2 m-0 p-0 pt-3"
          value="Information"
        >
          <AdminUserDetailInformation data={data} />
        </TabPanel>
      </TabContext>
      <Drawer open={isOpen} onClose={toggleModal} anchor="right">
        <AdminUserEdit
          data={data}
          activeindex={activeindex}
          id={id}
          onClose={toggleModal}
        />
      </Drawer>
    </Container>
  );
};

export default AdminUserDetail;
