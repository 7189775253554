
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/studentApi";


export const adminCreateStudentRequirementAction = createAsyncThunk(
  "admin/create_student_requirement",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateStudentRequiremnet(data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//get_all_student_requirement
export const adminGetAllStudentRequirementAction = createAsyncThunk(
  "admin/get_all_student_requirement",
  async ({ keyword = "", page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllStudentRequirement(
        keyword,
        page
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


//adminCreateCountryOverview
export const adminCreateCountryOverviewAction = createAsyncThunk(
  "admin/create_country_overview",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateCountryOverview(data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get table by country
export const adminGetTableByCuntryAction = createAsyncThunk(
  "admin/get_table_by_country",
  async ({ country }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetTableByCountry(country);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);