import {
  Email,
  Facebook,
  RemoveRedEye,
  ShareOutlined,
  Twitter
} from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FacebookIcon from "@mui/icons-material/Facebook";
import LaunchIcon from "@mui/icons-material/Launch";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, ButtonGroup, Chip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { post_bookmark } from "../../context/actions/authAction";
import { CreateProfessionalReviewAction, GetProfessionalDetailAction } from "../../context/actions/professionalAction";
import { staticCImg } from "../../utils/globalSettings";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import ProfessionalReview from "./components/ProfessionalReview";
import ReviewModal from "./components/ReviewModal";
import SellAllReviewModal from "./components/SellAllReviewModal";
import ProfessionalDetailSkeleton from "./ProfessionalDetailSkeleton";
const ProfessionalDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const initialState = {
    comment: "",
    name: "firstname",
  };
  const [comment, setComment] = React.useState(initialState);

  const [type, setType] = React.useState("Professional");
  const [bookmarked, setbookmarked] = React.useState(false);

  const [readmore, setreadmore] = React.useState(false);

  const toggleReadmore = () => {
    setreadmore((prev) => !prev);
  };


  const reviewoption = {
    comment: "",
    rating: 0,
  };
  const [review, setReview] = React.useState(reviewoption);

  // open reviews modal
  const [openReviews, setOpenReviews] = React.useState(false);
  const handleOpenReviews = () => setOpenReviews(true);
  const handleCloseReviews = () => setOpenReviews(false);

  // handle commnet chnage
  const handleCommentChange = (e) => {
    setReview({ ...review, comment: e.target.value });
  };
  // handle rating chnage
  const handleRatingChange = (e) => {
    setReview({ ...review, rating: e.target.value });
  };

  // handle submitReview
  const submitReview = (e) => {
    e.preventDefault();
    dispatch(CreateProfessionalReviewAction({ review, id }));
  };

  useEffect(() => {
    dispatch(GetProfessionalDetailAction(id));
  }, [id,dispatch]);

  const {
    professional_detail,
    loading_professional_detail,
    professional_comments,
    isAuth,
    bookmarks,
  } = useSelector((state) => ({
    professional_detail: state.professional_reducer.professional_detail,
    loading_professional_detail:
      state.professional_reducer.loading_professional_detail,
    professional_comments: state.professional_reducer.professional_comments,
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  console.log(professional_detail);

  // open see all reviews modal
  const [openSeeAllReviews, setOpenSeeAllReviews] = React.useState(false);
  const handleOpenSeeAllReviews = () => setOpenSeeAllReviews(true);
  const handleCloseSeeAllReviews = () => setOpenSeeAllReviews(false);

  // get the bookmarked data

  // check if the bookmark is already there
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  // // set the bookmarked state
  React.useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  // set the type of bookmark to initiate the bookmark
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    location: "",
    category: "",
  });

  // set the bookmark
  React.useEffect(() => {
    if (professional_detail && bookmark.id !== professional_detail._id) {
      setBookmark({
        name: professional_detail.profession,
        type: type,
        image: professional_detail.userPic,
        category: professional_detail.profession,
        location: professional_detail.provenience,
      });
    }
  }, [professional_detail, bookmark.id, type]);

  const togglebookmark = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id, data: bookmark }));
    } else {
      navigate("/login");
    }
  };

  if(loading_professional_detail){
    return <ProfessionalDetailSkeleton/>
  }
  
  if (
    (professional_detail && Object?.keys(professional_detail).length === 0) ||
    professional_detail === undefined
  ) {
    return <NotFoundPage />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        paddingBottom: "3vh",
      }}
      className="container d-flex flex-column"
    >
      <Box
        className="  w-100  overflow-hidden position-relative d-flex align-items-end"
        sx={{
          minHeight: "35vh",
          height: "max-content",
        }}
      >
        <Box
          sx={{
            height: { xs: "37%", md: "55%" },
          }}
          className="  w-100 rounded rounded-4 overflow-hidden  position-absolute top-0 start-0"
        >
          <img
            src={staticCImg}
            alt=""
            srcSet=""
            className="object-cover w-100 h-100  "
          />
        </Box>

        <Box
          sx={{
            paddingLeft: { lg: "7%", md: "5%", xs: "0" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center" },
            justifyContent: "center",
            width: "100%",
          }}
          className=""
        >
          <Box
            sx={{
              height: "max-content",
              display: "flex",
              alignItems: "center",
              gap: "2%",
              flexDirection: { xs: "column", md: "row" },
              width: "100%",
            }}
            className=""
          >
            <Box
              className=" position-relative rounded rounded-circle "
              sx={{
                overflow: "hidden",
                maxHeight: "175px",
                height: { xs: "calc(50px + 15vw)", md: "175px" },
                width: { xs: "calc(50px + 15vw)", md: "175px" },
                maxWidth: "175px",
                border: "10px solid #f1f1f1",
              }}
            >
              <img
                alt="Remy Sharp"
                src={`${process.env.REACT_APP_IMAGE_PATH}${professional_detail?.userPic}`}
                style={{
                  cursor: "pointer",
                }}
                className="object-cover object-center w-100 h-100"
              />
            </Box>
            <Box
              sx={{
                lineHeight: "1em",
              }}
              className="h-maxcontent d-flex flex-column justify-content-start pt-md-5 text-center text-md-start "
            >
              <h3 className="fw-bold m-1">
                {professional_detail?.firstName} {professional_detail?.lastName}
              </h3>

              <p className="mb-1 ms-1 ">{professional_detail?.profession}</p>
              <div className="d-flex align-items-center  mt-1 text-black p-0">
                <p>
                  <LocationOnIcon
                    fontSize="small"
                    className="m-0 p-0 w-maxcontent"
                  />
                  {professional_detail.provenience},{professional_detail?.city}
                </p>
              </div>
            </Box>
          </Box>

          <ButtonGroup
            className="text-black"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button>
              <div className="comments d-flex align-items-center gap-1">
                <RemoveRedEye />
                {professional_detail?.viewCount}
              </div>
            </Button>
            <Button>
              <div className="clapped d-flex align-content-center gap-1">
                <ShareOutlined />
                Share
              </div>
            </Button>
            <Button onClick={togglebookmark}>
              <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                {bookmarked ? (
                  <BookmarkIcon onClick={togglebookmark} role="button" />
                ) : (
                  <MdOutlineBookmarkAdd role="button" />
                )}
              </div>
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <section className="row row-cols-1 row-cols-lg-2 gx-2 mt-2">
        <div className="left col-lg-8">
          <section className="about d-flex flex-column">
            <h5 className="fw-bold">About</h5>
            <Typography
              variant="p"
              sx={{
                fontSize: "clamp(0.7rem , 1.5vw , 1rem)",
              }}
            >
              {readmore
                ? professional_detail?.description
                : professional_detail?.description?.slice(0, 700)}
              {professional_detail?.description?.length > 700 && !readmore
                ? "..."
                : null}{" "}
            </Typography>
            <hr />
            {professional_detail?.description?.length > 400 ? (
              !readmore ? (
                <Typography
                  onClick={toggleReadmore}
                  className="w-100 text-center text-black fw-bold "
                  role="button"
                  variant="p"
                >
                  ShowMore
                </Typography>
              ) : (
                <Typography
                  onClick={toggleReadmore}
                  className="w-100 text-center text-black fw-bold"
                  role="button"
                  variant="p"
                >
                  ShowLess
                </Typography>
              )
            ) : null}
          </section>

          <section className="offers">
            <h5 className="fw-bold">Offered Services</h5>
            <div className="d-flex flex-wrap gap-1">
              {professional_detail?.offers?.slice(0, 4).map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      backgroundColor: "#F2F7F2",
                      color: "#13544E",
                    }}
                    label={item}
                    size="medium"
                  />
                );
              })}
            </div>
          </section>

          <section>
            <div className="d-flex justify-content-between mt-4">
              <h5 className="fw-bold">Rating and Reviews ✨</h5>
              <h5>
                {isAuth ? (
                  <button
                    onClick={handleOpenReviews}
                    className="btn btn-sm btn-outline-black"
                  >
                    Write a review
                  </button>
                ) : (
                  <p>
                    Please <Link to="/login">Login</Link> to comment
                  </p>
                )}

                <ReviewModal
                  openReview={openReviews}
                  handleCloseReview={handleCloseReviews}
                  handleCommnetchnage={handleCommentChange}
                  handleRatingChnage={handleRatingChange}
                  review={review}
                  auth={isAuth}
                  submitCommnet={submitReview}
                />
              </h5>
            </div>
            <p>
              <span className="fw-bold">{/* {avg_rating} */} 4.5</span> out of 5
              based on |{" "}
              <span
                onClick={handleOpenReviews}
                className="fw-bold text-decoration-underline"
              >
                {/* {business_comments?.length} REVIEWS */}5 REVIEWS
              </span>
            </p>

            <div className="row row-cols-2">
              {professional_comments?.map((comments, index) => {
                return <ProfessionalReview comments={comments} index={index} />;
              })}
            </div>

            <button
              onClick={handleOpenSeeAllReviews}
              className="btn btn-sm btn-outline-black mt-3"
            >
              See all reviews
            </button>
            <SellAllReviewModal
              openSeeAllReview={openSeeAllReviews}
              handleCloseSeeAllReview={handleCloseSeeAllReviews}
            />
          </section>
        </div>
        <Box
          sx={{
            height: "max-content",
          }}
          className="right-section col-lg-4 d-flex flex-column gap-2 rounded p-2 "
        >
          <section>
            <h5 className="fw-bold">Get in touch | Contacts</h5>
            <div className="d-flex gap-2 mt-1">
              <Chip
                icon={<FacebookIcon />}
                label="Facebook"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
              <Chip
                icon={<TwitterIcon />}
                label="Twitter"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
              <Chip
                icon={<LaunchIcon />}
                label="Website"
                variant="outlined"
                onClick={() => {}}
                size="small"
              />
            </div>
            <div className="d-flex gap-3 mt-3">
              <Email
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <div>
                <p className="m-0 p-0">General Email</p>
                <p className=" fw-bold">{professional_detail?.email}</p>
              </div>
            </div>

            <div className="d-flex gap-3 mt-3">
              <Facebook
                sx={{
                  fontSize: "3.5rem",
                  color: "#4267B2",
                }}
              />
              <div className="d-flex flex-column overflow-hidden">
                <p className="m-0 p-0">Facebook | Social Media</p>
                <div className="d-flex overflow-hidden">
                  <h6 className=" fw-bold text-overflow-hidden">
                    {professional_detail?.socialMediaLinks?.facebookLink}
                  </h6>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Twitter
                sx={{
                  fontSize: "3.5rem",
                  color: "#1DA1F2",
                }}
              />
              <div className="overflow-hidden d-flex flex-column">
                <p className="p-0">Twitter | Social Media</p>
                <p className="fw-bold text-overflow-hidden overflow-hidden">
                  {professional_detail?.socialMediaLinks?.twitterLink}
                </p>
              </div>
            </div>
          </section>
        </Box>
      </section>
    </Box>
  );
};

export default ProfessionalDetail;
