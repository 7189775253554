import moment from "moment";
import React from "react";

const AdminContactStatusLog = ({data}) => {
  const now = new Date();

  return (
    <div>
      <h6>
        Follow up log upto <span className="text-muted">({now.toLocaleDateString()})</span>
      </h6>
      {
        data.map((item, index) => (
          <div class="card shadow-3 mt-2" index={index}>
        <div class="card-body">
          <h5 class="card-title d-flex justify-content-between">
            <h6 className="text-danger">Status: {item?.status}</h6>
            <h6 className="text-danger"> {moment(item?.timestamp).format("MMMM Do YYYY, h:mm:ss a")}</h6>
          </h5>
          <p class="card-text">{item?.message}</p>
        </div>
      </div>
        ))
      }
    </div>
  );
};

export default AdminContactStatusLog;
