import TuneIcon from "@mui/icons-material/Tune";
import { Box, Modal, Pagination } from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProfessionalCard, Searchbar } from "../../components/Index";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import { GetAllProfessionalAction } from "../../context/actions/professionalAction";
import ProfessionalFilterSidebar from "./components/ProfessionalFilterSidebar";
import ProfessionalSmFilter from "./components/ProfessionalSmFilter";
import { Stack } from "@mui/system";

const ProfessionalFilter = () => {
  // use dspatch to dispatch action
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const query = queryParams.get("search")?.trim() || "";
  const filter = queryParams.get("province")?.trim() || "";
  const cityfilter = queryParams.get("city")?.trim() || "";
  const categoryfilter = queryParams.get("category")?.trim() || "";

  // intilize the useState property

  const [page, setPage] = React.useState(1);

  // intial state of filter options
  const filteroption = {
    sortby: "",
    city: cityfilter?.length > 0 ? cityfilter : "",
    province: filter?.length > 0 ? filter : "",
    category: categoryfilter?.length > 0 ? categoryfilter : "",
    search: query?.length > 0 ? query : "",
  };
  // set the filter option filtered useState
  const [filtered, setfilter] = React.useState(filteroption);

  // use useSelector to get data from store from business_reducer

  // use useEffect to call the function
  useEffect(() => {
    dispatch(
      GetAllProfessionalAction({
        keyword: filtered.search,
        page,
        city: filtered.city,
        provenience: filtered.province,
      })
    );
  }, [filtered.search, page, filtered.city, filtered.province, dispatch]);

  const {
    professional,
    loading_professional,
    totalprofessionalcount,
    filterprofessionalcount,
    resultperpage,
  } = useSelector((state) => state.professional_reducer);

  // handle change in city
  const handlecityChange = (e, value) => {
    setfilter({ ...filtered, city: value });
    const newQuery = value;
    queryParams.set("city", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleSMcityChange = (value) => {
    setfilter({ ...filtered, city: value });
    const newQuery = value;
    queryParams.set("city", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // handle change in category
  const handlecategorychanage = (value) => {
    setfilter({ ...filtered, category: value });
    const newQuery = value;
    queryParams.set("category", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  // handle change in province
  const selectprovince = (item) => {
    setfilter({ ...filtered, province: item });
    const newQuery = item;
    queryParams.set("province", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  // clear value from filter
  const clearvalue = (name) => {
    setfilter({ ...filtered, [name]: "" });
  };

  // handle change in searchbar keyword

  // handle keypress in searchbar for seach keyword
  const handlekeypress = (e) => {
    if (e.key === "Enter") {
      setfilter({ ...filtered, search: e.target.value });
      const newQuery = e.target.value;
      queryParams.set("search", newQuery);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  // handle page change function
  const handlePagechange = (e, value) => {
    setPage(value);
  };

  const [isOpen, setOpen] = React.useState(false);

  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };
  const onSMfilter = ({ city, province, category }) => {
    setfilter({
      ...filtered,
      province: province,
      city: city,
      category: category,
    });

    queryParams.set("province", province);
    queryParams.set("city", city);
    queryParams.set("category", category);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: 400 },
    bgcolor: "var(--hover-color)",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };
  return (
    <Box>
      <div className="row row-cols-lg-2 container w-100 mx-auto mt-3 ">
        <Box className="filter p-0 col col-lg-3 d-none d-lg-block position-sticky">
          <ProfessionalFilterSidebar
            filtered={filtered}
            handlecityChange={handlecityChange}
            selectprovince={selectprovince}
            selectcategory={handlecategorychanage}
          />
        </Box>
        <Box className=" d-block d-lg-none ">
          <Modal
            open={isOpen}
            onClose={toggleFilter}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <ProfessionalSmFilter
                filtered={filtered}
                onSMfilter={onSMfilter}
                onClose={toggleFilter}
              />
            </Box>
          </Modal>
        </Box>
        <section className="col p-0 col-lg-9">
          <p className="fw-bold">Filtering,</p>
          <Box className="w-100 bg-white d-flex justify-content-between flex-column flex-md-row">
            <div className="col-auto d-flex gap-2 align-items-center">
              {filtered.province.length > 0 ? (
                <Chip
                  label={filtered.province}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("province")}
                />
              ) : (
                <Chip
                  label="All Experts & Professional"
                  size="small"
                  variant="outlined"
                />
              )}

              {filtered.city.length > 0 ? (
                <Chip
                  label={filtered.city}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("city")}
                />
              ) : null}

              {filtered.category.length > 0 ? (
                <Chip
                  label={filtered.category}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("category")}
                />
              ) : null}

              {filtered.search.length > 0 ? (
                <Chip
                  label={filtered.search}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("search")}
                />
              ) : null}
            </div>
            <div className=" ms-md-auto mt-2 mt-md-0 d-flex align-items-center justify-content-between">
              <div className="">
                <Searchbar
                  handlekeypress={handlekeypress}
                  placeholder={"Enter Search"}
                />
              </div>
              <button
                onClick={toggleFilter}
                className="d-flex align-items-center gap-1 d-lg-none btn bg-black text-white ms-auto"
              >
                <TuneIcon /> <span>filter</span>
              </button>
            </div>
          </Box>
          <div className="row row-cols-3 gx-3">
            {professional && professional.length > 0 ? (
              professional.map((professional, index) => (
                <Box
                  sx={{
                    aspectRatio: { xs: "1/1.5", lg: "1/1.3" },
                  }}
                  key={professional._id}
                  className="p-2 col grid-box"
                >
                  <Link to={`/business/${professional._id}`}>
                    <ProfessionalCard professional={professional} />
                  </Link>
                </Box>
              ))
            ) : (
              <div
                className="w-100"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <NoResultFound />
              </div>
            )}
          </div>

          <Box className="d-flex justify-content-center mt-2">
            <Pagination
              count={Math.ceil(totalprofessionalcount/resultperpage)}
              page={page}
              onChange={handlePagechange}
              variant="outlined"
              className="ms-auto"
              shape="rounded"
            />
          </Box>
        </section>
      </div>
    </Box>
  );
};

export default ProfessionalFilter;
