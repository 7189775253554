import { Box } from "@mui/material";
import React from "react";
import { ImCross } from "react-icons/im";
import {
  SelectProvince,
  SelectCity,
  FormInputB,
  FormSelectB,
  SelectProfessional,
} from "../../../../../components/Index";
import professions from "../../../../../utils/all_professions";

const ProfessionalMIntroduction = ({ onClose }) => {
  const Ages = Array.from(Array(60 - 18 + 1).keys(), (num) => num + 18);

  const formerrors = {
    city: "",
    proveniece: "",
    email: "",
    gender: "",
    age: "",
    description: "",
    profession: "",
  };

  const formvalues = {
    city: "",
    proveniece: "",
    email: "",
    gender: "",
    age: "",
    description: "",
    profession: "",
  };
  const genderTypes = ["Male", "Female"];

  const [formvalue, setformvalues] = React.useState(formvalues);
  const [errors, setErrors] = React.useState(formerrors);
  // on province select
  const onprovinceselect = (value) => {
    setformvalues({ ...formvalue, proveniece: value, city: "" });
  };

  // on city select
  const oncityselect = (value) => {
    setformvalues({ ...formvalue, city: value });
  };

  const formvalidation = () => {
    let isValid = false;
    const newerror = { ...formerrors };
    if (
      !formvalue.firstName ||
      !formvalue.lastName ||
      !formvalue.gender ||
      !formvalue.age ||
      !formvalue.proveniece ||
      !formvalue.city ||
      (formvalue.age && formvalue.age < 18) ||
      !formvalue.description ||
      !formvalue.profession
    ) {
      if (!formvalue.firstName) {
        newerror.firstName = "Please provide firstName *";
      }

      if (!formvalue.lastName) {
        newerror.lastName = "Please provide lastName *";
      }

      if (!formvalue.proveniece) {
        newerror.proveniece = "Please provide proveniece *";
      }
      if (!formvalue.city) {
        newerror.city = "Please provide city *";
      }
      if (!formvalue.gender) {
        newerror.gender = "Please provide gender *";
      }

      if (!formvalue.age) {
        newerror.age = "Please provide your age *";
      }
      if (formvalue.age && formvalue.age < 18) {
        newerror.dob = "Please valid age (18 or older) *";
      }
      if (!formvalue.description) {
        newerror.description = "Please provide description *";
      }
      if (!formvalue.profession) {
        newerror.profession = "Please provide profession *";
      }

      newerror.message = "Invalid credential please check again *";
    } else {
      isValid = true;
    }

    setErrors(newerror);

    return isValid;
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };
  // change value base on e.target.name
  const handleFormChange = (e) => {
    const name = e.target.name;
    switch (name) {
      default:
        setformvalues({ ...formvalue, [e.target.name]: e.target.value });

        break;
    }
  };

  const onSubmit = () => {
    const isValid = formvalidation();

    if (isValid) {
    }
  };
  return (
    <Box
      sx={{ minHeight: "60vh" }}
      className="d-flex flex-column gap-2  m-0 p-0"
    >
      <Box className="d-flex align-items-center justify-content-between m-0">
        <div className="d-flex flex-column">
        <h5 className="text-black fw-bold m-0 p-0">Basic Information</h5>
        <small className="text-black">
          required professional Information to complete your profile
        </small>
        </div>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>

      <Box className="row-cols-lg-2 row-cols-1 row h-maxcontent">
        <div className="">
          <FormInputB
            name="firstName"
            type="text"
            label="Firstname"
            placeholder="Enter Your First Name"
            value={formvalue.firstName}
            handleChange={handleFormChange}
            error={errors.firstName ? true : false}
            helpertext={errors.firstName}
          />
        </div>
        <div className="">
          <FormInputB
            name="lastName"
            label="Lastname"
            type="text"
            placeholder="Enter Your Last Name"
            value={formvalue.lastName}
            handleChange={handleFormChange}
            error={errors.lastName ? true : false}
            helpertext={errors.lastName}
          />
        </div>
        <Box
          className=""
          sx={{
            marginTop: "10px",
          }}
        >
          {/* <FormInputB
              name="dob"
              type="date"
              label="Date Of Birth"
              placeholder="Enter Your DOB"
              value={formvalue.dob}
              handleChange={handleFormChange}
              error={errors.dob ? true : false}
              helpertext={errors.dob}
            /> */}

          <FormSelectB
            name="age"
            label={"Age"}
            list={Ages}
            placeholder="Select Your Age"
            value={formvalue.age}
            handleChange={handleFormChange}
            error={errors.age ? true : false}
            helpertext={errors.age}
          />
        </Box>
        <Box
          className=""
          sx={{
            marginTop: "10px",
          }}
        >
          <FormSelectB
            name="gender"
            label={"Gender"}
            list={genderTypes}
            placeholder="Select Your Gender"
            value={formvalue.gender}
            handleChange={handleFormChange}
            error={errors.gender ? true : false}
            helpertext={errors.gender}
          />
        </Box>
        <div className="">
          <SelectProvince
            onprovinceselect={onprovinceselect}
            name="proveniece"
            handleChange={handleFormChange}
            placeholder={"Select Province"}
            error={errors.proveniece ? true : false}
            helpertext={errors.proveniece}
          />
        </div>
        <div className="">
          <SelectCity
            province={formvalue.proveniece}
            city={formvalue.city}
            handleChange={oncityselect}
            error={errors.city ? true : false}
            helpertext={errors.city}
          />
        </div>
      </Box>

      <div className="form-group">
        <label htmlFor="profession" className="mb-1">
          Profession
        </label>
        <SelectProfessional
          list={professions}
          name={"profession"}
          placeholder={"select your profession"}
          havelabe={false}
          error={errors.profession}
          handleChange={handleFormValue}
          currentvalue={formvalue.profession}
        />
      </div>
      <div className="form-group ">
        <label htmlFor="description" className="mb-1">
          Description
        </label>
        <textarea
          className={
            errors.description ? "form-control is-invalid" : "form-control"
          }
          id="textAreaExample"
          rows="4"
          maxLength={800}
          data-mdb-showcounter="true"
          placeholder={"Write something about yourself"}
          name={"description"}
          type="text"
          onChange={handleFormChange}
          value={formvalue.description}
        ></textarea>
        <div className="d-flex justify-content-between align-content-center">
          <small
            id="emailHelp"
            className={
              errors.description.length > 0 ? "form-text text-danger" : ""
            }
          >
            {errors.description}
          </small>
          <span
            className={
              formvalue.description.length === 800
                ? "form-helper text-end text-danger ms-auto "
                : "form-helper text-end ms-auto"
            }
          >
            {formvalue.description.length} / {800}
          </span>
        </div>
      </div>
      <button
        className="btn-dark btn ms-auto mt-auto align-self-end justify-self-end"
        onClick={onSubmit}
      >
        Save
      </button>
    </Box>
  );
};

export default ProfessionalMIntroduction;
