import React from "react";
import { Box, TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import allprovince from "../../../../utils/all_city_province";
import FacebookIcon from "@mui/icons-material/Facebook";
import PublicIcon from "@mui/icons-material/Public";
import BoardMembers from "./component/BoardMembers";
const ActionPage = () => {
  const formvalues = {
    description: "",
    email: "",
    postalcode: "",
    phonenumber: "",
    telephonenumber: "",
    nameofowner: "",
    nameofcompany: "",
    members: [],

    panfront: "",
    panback: "",

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "",
    city: "",
    agreement: false,
    expdate: "",
  };
  const [value, setValue] = React.useState("1");
  const [formvalue, setFormValue] = React.useState(formvalues);
  const [active, setActive] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFormValue = (e) => {
    setFormValue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const onmemberchange = (newlist) => {
    setFormValue({ ...formvalue, members: newlist });
  };

  // handle form value on input change
  const handleformvalue = (e) => {
    const name = e.target.name;
    switch (name) {
      case "province":
        setValue({ ...value, province: e.target.value, city: "" });

        break;

      case "panfront":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panfrontfile: file });
        }

        break;

      case "panback":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panbackfile: file });
        }
        break;

      case "agreement":
        setValue({ ...value, [e.target.name]: !value.agreement });

        break;

      default:
        setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  const tabs = [
    "Info",
    "Application",
    "Groups",
    "Account Activity",
    "Security Policies",
  ];
  return (
    <div className="container px-5">
      <main>
        <div className="d-flex">
          <span className="fs-4 fw-bold">Action For</span>
        </div>
        <div className="d-flex gap-2  w-100 align-items-center ">
          <img
            src="https://images.unsplash.com/photo-1616683693504-3ea7e9ad6fec?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
            alt=""
            srcSet=""
            className="rounded-circle rounded object-cover"
            style={{
              border: "10px solid #F1F1F1",
              height: 100,
              width: 100,
            }}
          />

          <div className="d-flex flex-column">
            <span className="fs-2 fw-bold text-black">
              {" "}
              Onf Canada Public Group
            </span>
            <p className="p-2 bg-hover text-center d-inline" width={20}>
              money@gmail.com
            </p>
          </div>

          <div className="d-flex gap-2 ms-auto">
            <button
              className="btn btn-danger"
              type="button"
              data-mdb-toggle="offcanvas"
              data-mdb-target="#offcanvasRight"
              aria-controls="offcanvasRight"
            >
              Edit
            </button>
            <button className="btn btn-danger">Deactivate</button>
          </div>
        </div>

        <section>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value.toString()}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {tabs.map((item, index) => {
                    return (
                      <Tab label={item} value={index.toString()} key={item} />
                    );
                  })}
                </TabList>
              </Box>
              <TabPanel value="1">Item One</TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
              <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
          </Box>
        </section>

        <section>
          <button
            className="btn btn-primary"
            type="button"
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            Toggle right offcanvas
          </button>
        </section>
      </main>

      <Box
        className={active === 1 ? "offcanvas offcanvas-end w-75": "d-none"}
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            Edit Organization
          </h5>
          <button
            type="button"
            className="btn-close"
            data-mdb-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <h2>Basic Information</h2>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            autoComplete="off"
            className="w-100"
          >
            <TextField
              label="Name of Organization"
              name="nameoforganization"
              id="standard-size-normal"
              variant="standard"
              className="w-100"
              type="String"
              placeholder="Enter Name of Organization"
              onChange={handleFormValue}
            />

            <TextField
              id="standard-multiline-static"
              label="Description"
              multiline
              rows={4}
              className="w-100"
              name="description"
              placeholder="Enter Description"
              variant="standard"
              type="String"
              onChange={handleFormValue}
            />
            <div className="d-flex w-100 justify-content-between gap-3">
              <TextField
                id="standard-select-currency-native"
                select
                label="Province"
                defaultValue=""
                className="w-100"
                SelectProps={{
                  native: true,
                }}
                helperText=""
                variant="standard"
                onChange={handleFormValue}
                name="province"
              >
                <option value="">Select Province</option>
                {Object.keys(allprovince).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                id="standard-select-currency-native"
                select
                label="City"
                defaultValue=""
                SelectProps={{
                  native: true,
                }}
                helperText=""
                variant="standard"
                className="w-100"
                disabled={formvalue.province.length > 0 ? false : true}
                onChange={handleFormValue}
                name="city"
              >
                <option value="">Select city</option>
                {formvalue.province &&
                  Object.values(allprovince[formvalue.province]).map(
                    (option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    )
                  )}
              </TextField>
            </div>

            <div className="d-flex w-100 gap-3">
              <TextField
                label="Exp Date"
                name="expdate"
                id="standard-size-normal"
                variant="standard"
                className="w-100"
                type="date"
                placeholder="Enter Exp Date"
                onChange={handleFormValue}
              />

              <TextField
                label="Postal Code"
                name="postalcode"
                id="standard-size-normal"
                variant="standard"
                className="w-100"
                type="number"
                placeholder="Enter Postal Code"
                onChange={handleFormValue}
              />
            </div>

            <h2>Document Upload</h2>

            <div className="bg-hover w-100 p-lg-5 p-3 rounded-4 rounded row row-cols-1 row-cols-md-2 row-cols-lg-2">
              <div className="col d-flex flex-column">
                <label htmlFor="panfront">
                  Company registration proof (Front)
                </label>
                <input
                  type="file"
                  name="panfront"
                  id=""
                  accept="image/*"
                  onChange={handleformvalue}
                />
                <img
                  src={value.panfront}
                  alt=""
                  srcSet=""
                  className={
                    value.panfront
                      ? "w-100 object-cover grid-box mt-2"
                      : "d-none"
                  }
                />
              </div>
              <div className="col d-flex flex-column">
                <label htmlFor="panback">
                  Company registration proof (Back)
                </label>
                <input
                  type="file"
                  name="panback"
                  id=""
                  accept="image/*"
                  onChange={handleformvalue}
                />
                <img
                  src={value.panback}
                  alt=""
                  srcSet=""
                  className={
                    value.panback
                      ? "w-100 object-cover  grid-box mt-2"
                      : "d-none"
                  }
                />
              </div>
            </div>

            <h2>Contacts</h2>

            <TextField
              label="Email"
              name="email"
              id="standard-size-normal"
              variant="standard"
              className="w-100"
              type="String"
              placeholder="Enter Your Email"
              onChange={handleFormValue}
            />

            <TextField
              label="PhoneNumber"
              name="phonenumber"
              id="standard-size-normal"
              variant="standard"
              className="w-100"
              type="tel"
              placeholder="Enter Your PhoneNumber"
              onChange={handleFormValue}
            />

            <TextField
              label="TelePhone Number"
              name="telephonenumber"
              id="standard-size-normal"
              variant="standard"
              className="w-100"
              type="tel"
              placeholder="Enter Your Telephone Number"
              onChange={handleFormValue}
            />

            <h2>Links</h2>

            <div className="d-flex w-100 gap-3">
              <Box
                className="w-100"
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  Width: "100%",
                }}
              >
                <PublicIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  label="Personal Website"
                  name="websitelink"
                  id="standard-size-normal"
                  variant="standard"
                  className="w-100"
                  type="tel"
                  placeholder="Enter Website Link"
                  onChange={handleFormValue}
                />
              </Box>
              <Box
                className="w-100"
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  Width: "100%",
                }}
              >
                <FacebookIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                <TextField
                  label="FaceBook Link"
                  name="fblink"
                  id="standard-size-normal"
                  variant="standard"
                  className="w-100"
                  type="String"
                  placeholder="Enter FaceBook Link"
                  onChange={handleFormValue}
                />
              </Box>
            </div>

            <BoardMembers value={formvalue} onmemberchange={onmemberchange} />
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default ActionPage;
