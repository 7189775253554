import { useCallback } from "react";
// import { useRouter } from "next/navigation";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";

import { adminLogut } from "../../../../context/reducers/authenticateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { admin_data, isAdmin } = useSelector((state) => ({
    admin_data: state.auth_slice.admin_data,
    isAdmin: state.auth_slice.isAdmin,
  }));

  console.log("admin_data", admin_data);

  //nabin bhattarai
  const handleSignOut = useCallback(() => {
    dispatch(adminLogut());
    navigate("/admin/login");

    onClose?.();
  }, [onClose, dispatch]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {admin_data?.firstname} {admin_data?.lastname}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        {isAdmin ? <MenuItem onClick={handleSignOut}>Sign out</MenuItem> : null}
      </MenuList>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
