import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography } from "@mui/material";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { GrCapacity } from "react-icons/gr";
import { AddLocation } from "../../../components/Index";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ImCross } from "react-icons/im";
import { editFlatemateByUser } from "../../../context/actions/authAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  accomondationTypes,
  capacityOfPropery,
} from "../../../utils/flateMateSettings";

// select card
const AccomondationSelectCard = ({
  value,
  name,
  handleChange,
  options,
  placeholder,
  label,
  icon,
  errors,
}) => {
  return (
    <div className="d-flex gap-2 align-items-start w-100">
      <div className="icons">{icon}</div>
      <div className="info w-100">
        <label className="text-capitalize">{label}</label>
        <select
          className={
            errors
              ? "form-select is-invalid rounded-0 form-select-lg  py-2 rounded-2 w-100"
              : "form-select rounded-0 form-select-lg  py-2 rounded-2 w-100"
          }
          aria-label=".form-select-lg example"
          value={value}
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <option value="">{placeholder}</option>
          {options.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        {errors.length > 0 ? (
          <small className="text-danger mt-1">{errors}</small>
        ) : null}
      </div>
    </div>
  );
};

const AccomondationCard = ({ item, selected, handleFormValue, list }) => {
  const { name, icon } = item;

  const addaccomondationType = () => {
    let newfeatures = [...list];
    if (selected) {
      const index = newfeatures.indexOf(name);
      if (index > -1) {
        newfeatures.splice(index, 1);
      }
    } else {
      newfeatures.push(name);
    }
    handleFormValue("accomondationType", newfeatures);
  };
  return (
    <Box
      sx={{
        height: { xm: "100px", lg: "100px" },
        width: "200px",
      }}
      role="button"
      onClick={addaccomondationType}
      className={
        selected
          ? "d-flex p-2 grid-box-half justify-content-center flex-column border border-2 border-dark rounded rounded-3 text-start position-relative"
          : "-flex p-2 grid-box-half justify-content-center flex-column border border-2 rounded rounded-3 text-start position-relative"
      }
    >
      <div className="">{icon}</div>
      <Typography
        variant="p"
        sx={{
          fontSize: "0.9rem",
        }}
      >
        {name}
      </Typography>

      <CheckCircleOutlineIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};

const ToggleCard = ({
  handleFormValue,
  name,
  value,
  label,
  icon,
  selected,
}) => {
  return (
    <div
      className={
        selected
          ? " border border-1 border-dark text-black d-flex p-3 w-100 rounded rounded-3 justify-content-between"
          : " border border-1 d-flex p-3 w-100 rounded rounded-3 justify-content-between"
      }
      role="button"
      onClick={() => handleFormValue(name, value)}
    >
      <span className={selected ? "fw-bold text-black" : ""}>{label}</span>
      <div className="">{icon}</div>
    </div>
  );
};
const AccomondationType = ({
  accomondationType,
  teamUp,
  haveProperty,
  typeOfProperty,
  propertyCapacity,
  propertyLocation,
  id,
  onClose,
}) => {
  const value = {
    accomondationType: accomondationType,
    teamUp: teamUp,
    haveProperty: haveProperty,
    typeOfProperty: typeOfProperty,
    propertyCapacity: propertyCapacity,
    propertyLocation: propertyLocation,
  };

  const evalue = {
    accomondationType: "",
    teamUp: "",
    haveProperty: "",
    typeOfProperty: "",
    propertyCapacity: "",
    propertyLocation: "",
  };

  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);

  const propertyDetails = {
    id: "1",
    value: formValue.typeOfProperty,
    options: ["appartment", "warehouse", "cottage"],
    label: "Type of Property",
    icon: (
      <HomeWorkOutlinedIcon
        style={{
          fontSize: "2.5em",
        }}
      />
    ),
    name: "typeOfProperty",
    placeholder: "Select Property Type",
  };

  const formValidation = () => {
    let isValid = true;
    let newerror = { ...evalue };
    if (
      formValue.accomondationType.length === 0 ||
      formValue.teamUp === "" ||
      (formValue.teamUp && formValue.haveProperty === "") ||
      (formValue.haveProperty && !formValue.typeOfProperty) ||
      (formValue.haveProperty && !formValue.propertyCapacity) ||
      (formValue.haveProperty && !formValue.propertyLocation)
    ) {
      if (formValue.accomondationType.length === 0) {
        newerror.accomondationType = "Please provide accomondation type *";
      }
      if (formValue.teamUp === "") {
        newerror.teamUp = "Please choose one of the options * ";
      }
      if (formValue.teamUp && formValue.haveProperty === "") {
        newerror.haveProperty = "Please choose property one of the options * ";
      }
      if (formValue.haveProperty && !formValue.typeOfProperty) {
        newerror.typeOfProperty = "Please provide property type *";
      }
      if (formValue.haveProperty && !formValue.propertyCapacity) {
        newerror.propertyCapacity = "Please provide property capacity *";
      }

      if (formValue.haveProperty && !formValue.propertyLocation) {
        newerror.propertyLocation = "Please provide property location *";
      }

      newerror.message = "Invalid credential please check again";
      isValid = false;
      setError(newerror);
    }
    setError(newerror);

    return isValid;
  };

  const handleLocation = (location) => {
    setFormValue((prevValue) => ({
      ...prevValue,
      propertyLocation: location.place_name,
    }));
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    const isValid = formValidation();
    if (isValid) {
      const formData = new FormData();
      for (let i = 0; i < formValue.accomondationType.length; i++) {
        formData.append("accomondationType", formValue.accomondationType[i]);
      }
      formData.append("teamUp", formValue.teamUp);
      formData.append("haveProperty", formValue.haveProperty);
      formData.append("typeOfProperty", formValue.typeOfProperty);
      formData.append("propertyCapacity", formValue.propertyCapacity);
      formData.append("propertyLocation", formValue.propertyLocation);
      dispatch(
        editFlatemateByUser({ id: id, formData: formData, toast: toast })
      );
      onClose();
    }
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "teamUp":
        setFormValue({ ...formValue, teamUp: true, haveProperty: "" });

        break;

      case "noTeamUp":
        setFormValue({
          ...formValue,
          teamUp: false,
          haveProperty: false,
        });

        break;

      case "haveProperty":
        setFormValue({ ...formValue, haveProperty: true });
        break;

      case "noProperty":
        setFormValue({
          ...formValue,
          haveProperty: false,
          typeOfProperty: "",
          propertyCapacity: "",
          propertyLocation: "",
        });
        break;

      default:
        setFormValue({ ...formValue, [name]: value });
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "70vh",
        overflow: "hidden",
        overflowY: "auto",
      }}
      className="p-0 m-0 d-flex flex-column gap-2 position-relative "
    >
      <Box className="p-0 m-0 d-flex flex-column gap-2 position-relative">
        <Box
          sx={{
            borderBottom: "1px solid var(--hover-color)",
          }}
          className="d-flex align-items-center justify-content-between m-0"
        >
          <div className="d-flex flex-column">
            <span className="text-decoration-underline fs-5 fw-bold text-black">
              Accomondation types
            </span>
            <small>Preferred accomondation type, team up, have property</small>
          </div>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </Box>
        <div className="d-flex flex-wrap gap-2">
          {accomondationTypes.map((item, index) => {
            const selected = formValue.accomondationType.some(
              (name) => name === item.name
            );
            return (
              <AccomondationCard
                key={item.id}
                item={item}
                selected={selected}
                handleFormValue={handleFormValue}
                list={formValue.accomondationType}
              />
            );
          })}
        </div>
        {errors.accomondationType.length > 0 ? (
          <small className="text-danger mt-1">{errors.accomondationType}</small>
        ) : null}
        <div>
          <h4 className="text-black fw-bold p-0 m-0">Accomondation type</h4>
          <small>Select the type of accomondation you want</small>
        </div>
        <div className="d-flex flex-column flex-lg-row gap-3  w-100">
          <ToggleCard
            label={"I am interested in teaming Up"}
            handleFormValue={handleFormValue}
            name={"teamUp"}
            value={true}
            icon={<PeopleAltOutlinedIcon />}
            selected={formValue.teamUp === true ? true : false}
          />
          <ToggleCard
            label={"Not Interested "}
            handleFormValue={handleFormValue}
            name={"noTeamUp"}
            value={false}
            icon={<RemoveCircleOutlinedIcon />}
            selected={formValue.teamUp === false ? true : false}
          />
        </div>
        {errors.teamUp.length > 0 ? (
          <small className="text-danger mt-1">{errors.teamUp}</small>
        ) : null}
        {formValue.teamUp === true ? (
          <div className=" show">
            <p className="fs-6  mt-3  mb-1">
              As you are interested in teaming up
            </p>
            <div className="d-flex flex-column flex-lg-row gap-3">
              <ToggleCard
                label={"I have a Property"}
                handleFormValue={handleFormValue}
                name={"haveProperty"}
                value={true}
                icon={<PeopleAltOutlinedIcon />}
                selected={formValue.haveProperty === true ? true : false}
              />
              <ToggleCard
                label={"I don't have a property"}
                handleFormValue={handleFormValue}
                name={"noProperty"}
                value={false}
                icon={<RemoveCircleOutlinedIcon />}
                selected={formValue.haveProperty === false ? true : false}
              />
            </div>
          </div>
        ) : null}
        {errors.haveProperty.length > 0 ? (
          <small className="text-danger mt-1">{errors.haveProperty}</small>
        ) : null}
        {formValue.haveProperty === true ? (
          <div className="">
            <p className="fs-6 fw-bold  mt-3  mb-1 text-black">
              As you have property
            </p>
            <div className="row-cols-1  row mt-3">
              <div className=" ps-3 d-flex w-100 gap-3 flex-column flex-lg-row justify-content-between w-100 align-items-center">
                <AccomondationSelectCard
                  {...propertyDetails}
                  handleChange={handleFormValue}
                  errors={errors.typeOfProperty}
                />
                <div className="d-flex gap-2 align-items-start w-100">
                  <div className="icons">
                    <GrCapacity
                      style={{
                        fontSize: "2.5em",
                      }}
                    />
                  </div>
                  <div className="info w-100 mt-2">
                    <label>Capacity of property</label>
                    <select
                      className={
                        errors.typeOfProperty.length > 0
                          ? "form-select rounded-0 form-select-lg is-invalid  py-2 rounded-2 w-100"
                          : "form-select rounded-0 form-select-lg  py-2 rounded-2 w-100"
                      }
                      aria-label=".form-select-lg example"
                      value={formValue.propertyCapacity}
                      name={"propertyCapacity"}
                      onChange={(e) =>
                        handleFormValue(e.target.name, e.target.value)
                      }
                    >
                      <option value="">{"Select Max Property Capacity"}</option>
                      {capacityOfPropery.map((item, index) => {
                        return (
                          <option value={item} key={index}>
                            Max {item} Others
                          </option>
                        );
                      })}
                    </select>
                    {errors.propertyCapacity.length > 0 ? (
                      <small className="text-danger mt-1">
                        {errors.propertyCapacity}
                      </small>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className=" mt-2">
                <div className="d-flex gap-2">
                  <LocationOnIcon />
                  <span>
                    {formValue.propertyLocation?.length > 0
                      ? formValue.propertyLocation
                      : "Select property location"}
                  </span>
                </div>
                <AddLocation height={200} handleLocation={handleLocation} />
                {errors.propertyLocation.length > 0 ? (
                  <small className="text-danger mt-1">
                    {errors.propertyLocation}
                  </small>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        <Box
          style={{
            zIndex: "2",
          }}
          className=" mt-3 position-sticky d-flex gap-1 align-items-center start-0 bottom-0 "
        >
          {errors.message && (
            <div className="bg-danger text-center w-75 mx-auto rounded rounded-2 text-white fw-bold">
              <small>{errors.message}</small>
            </div>
          )}
          <button
            onClick={onSubmit}
            className="btn-dark btn ms-auto justify-self-end"
          >
            Save
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default AccomondationType;
