import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Drawer } from "@mui/material";
import AdminProfessionalDetail from "./adminProfessionalDetail/AdminProfessionalDetail";
import AdminProfessionalEdit from "./AdminProfessionalEdit";
import { CancelOutlined, CheckOutlined } from "@mui/icons-material";
import { adminGetAllProfessionalAction } from "../../../../context/actions/admin/professionalAction";
import { useDispatch, useSelector } from "react-redux";
export default function ProfessionalTable({ keyword, page, setPage }) {
  const [activeIndex, setActive] = React.useState(0);
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const [status, setStatus] = React.useState(true);

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    dispatch(
      adminGetAllProfessionalAction({
        page: page,
        keyword: keyword,
        status: status,
      })
    );
  }, [dispatch, page, keyword, status]);

  const {
    professional_loading,
    professional,
    resultperpage,
    totalprofessionalcount,
  } = useSelector((state) => state.admin_reducer);

  // open edit drawer

  // open detail drawer
  const toggleDrawerDetail = () => setIsOpenDetail((prev) => !prev);

  const columns = [
    {
      field: "logo",
      headerName: "Logo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "Provenience", headerName: "Provenience", flex: 1 },
    { field: "Profession", headerName: "Profession", flex: 1 },
    { field: "Views", headerName: "Views", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },
    {
      field: "featured",
      headerName: "Featured",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { id } = params.row;

        return (
          <div className="d-flex align-items-center gap-2">
            <Box
              sx={{
                height: "40px",
                width: "40px",
              }}
              className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
            >
              <VisibilityIcon
                onClick={() => {
                  setActive(id);
                  toggleDrawerDetail();
                }}
                role="button"
              />
            </Box>
          </div>
        );
      },
    },
  ];

  const mappedRows = professional?.map((professional, index) => ({
    id: index,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${professional?.userPic}`,
    name: `${professional?.firstName} ${professional?.lastName}`,
    Provenience: professional?.provenience,
    Profession: professional?.profession,
    Views: professional?.viewCount,
    active: professional?.active,
    featured: professional?.featured,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={professional_loading}
          page={page}
          rowCount={totalprofessionalcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpenDetail} onClose={toggleDrawerDetail}>
        <AdminProfessionalDetail
          data={professional[activeIndex]}
          activeindex={activeIndex}
          id={professional[activeIndex]?._id}
          onClose={toggleDrawerDetail}
        />
      </Drawer>
    </div>
  );
}