import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { ScrollToTop } from "./components/Index";
import "./index.css";
import {
  AddBusiness,
  AddFlateMate,
  AddProfessional,
  AddRental,
  AdminNavLayout,
  AllOrg,
  AllRental,
  Blog,
  BlogDetail,
  BlogHome,
  BlogSearchResult,
  Bookmarks,
  BusinessDetail,
  FlateMateDetail,
  FlateMateProfile,
  Homepage,
  ListofOrg,
  Login,
  OrganizationDetail,
  ProfessionalAll,
  ProfessionalDetail,
  ProfessionalFilter,
  Profile,
  ProfileUpgrade,
  RentDetail,
  RentalEdit,
  RentalFilter,
  SharedNavLayout,
  SignUp,
  StudentAll,
  StudentCountryDetail,
  UserRentalDetail,
  NotFoundPage,
} from "./pages";
// import Page from "./pages/admin/pages";
import ErrorPage from "./pages/admin/pages/404";
import AdminBusiness from "./pages/admin/pages/AdminBusiness";
import Account from "./pages/admin/pages/account";
import ActionPage from "./pages/admin/pages/action/ActionPage";
import OrganizationReview from "./pages/admin/pages/organizationreview/OrganizationReview";
import AdminOrganizations from "./pages/admin/pages/organizations";
import SettingPage from "./pages/admin/pages/settings";
import AddBlog from "./pages/admin/sections/blog/addblog/AddBlog";
import AllBlog from "./pages/admin/sections/blog/allblog/AllBlog";
import AdminAddBusiness from "./pages/admin/sections/business/addbusiness/AdminAddBusiness";
import AdminAddOrg from "./pages/admin/sections/organization/addorg/AdminAddOrg";
import AllBusiness from "./pages/allbusiness/AllBusiness";
import BusinessFilterPage from "./pages/allbusiness/BusinessFilterPage";
import Test from "./pages/TestPage";
import AdminRental from "./pages/admin/sections/rental/AdminRental";
import EditFeatured from "./pages/admin/sections/blog/allblog/components/EditFeatured";
import AdminDashboard from "./pages/admin/sections/dashboard/AdminDashboard";
import AdminFlateMate from "./pages/admin/sections/flateMate/AdminFlateMate";
import AdminProfessional from "./pages/admin/sections/professional/AdminProfessional";
import AdminStudents from "./pages/admin/sections/students/AdminStudents";
import ProtectedRoute from "./pages/protectedroute/ProtectedRoute";
import AddProfessionalProtectedRoute from "./pages/protectedroute/ProfessionalPostProtectedRoute";
import AddFlatemateProtectedRoute from "./pages/protectedroute/FlatematePostProtectedRoute";
import AddOrganizationProtectedRoute from "./pages/protectedroute/OrganizationPostProtctedRoute";

import NewsHome from "./pages/newsPage/NewsHome";
import OnlyNavLayout from "./pages/sharednavlayout/OnlyNavLayout";

import {
  AddStudentsRequirements,
  AdminAddAdmin,
  AdminAddProfessional,
  AdminAdmin,
  AdminAdvertisements,
  AdminEditBlog,
  AdminEditEvent,
  AdminEventsAndGallary,
  AdminFaqs,
  AdminHelpAndSupport,
  AdminUserAdd,
  AdminUsers,
} from "./pages/admin/sections";
import AdminContact from "./pages/admin/sections/contact/AdminContact";

import EventsAndGallary from "./pages/EventsAndGallary/EventsAndGallary";
import EventsDetails from "./pages/EventsAndGallary/EventsDetails";
import Notification from "./pages/Notification/Notification";
import AdminLogin from "./pages/admin/pages/auth/AdminLogin";
import AdminAddEvent from "./pages/admin/sections/EventsAndGallary/AdminAddEvent";
import EditStudentRequirements from "./pages/admin/sections/students/editStudentRequirements/EditStudentRequirements";
import ContactusPage from "./pages/contactus/contactus";
import FaqsHome from "./pages/faqs/faqs";
import HelpHome from "./pages/help/HelpHome";
import NewsSearchResult from "./pages/newsPage/NewsSearchResult";
import ResetSuccess from "./pages/passwordReset/ResetSuccess";
import SendPasswordResetLink from "./pages/passwordReset/SendPasswordResetLink";
import SetNewPassword from "./pages/passwordReset/SetNewPassword";
import AdminPrivateRoute from "./pages/protectedroute/AdminProtectedRoute";
import StudentRequirementsUser from "./pages/studentRequirementsUser/StudentRequirementsUser";
import Verification from "./pages/verification/Verification";
import AdminNotification from "./pages/admin/sections/notification/AdminNotification";
import ReactGA from "react-ga";
import RentalContact from "./pages/rental_contact/RentalContact";
ReactGA.initialize('G-TS9KX646BH');
const theme = createTheme({
  typography: {
    hover: {
      color: "red",
    },
  },

  palette: {
    primary: {
      main: "#2c3e50",
    },
    secondary: {
      main: "#f1c40f",
    },
    error: {
      main: "#e53e3e",
    },
    warning: {
      main: "#dd6b20",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <ToastContainer
          position="top-right"
          autoClose={800}
          limit={1}
          hideProgressBar={true}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover={false}
          theme="light"
          transition={Zoom}
          closeButton={false}
        />

        <Routes>
          <Route path="/" element={<SharedNavLayout />}>
            <Route index element={<Homepage />} />
            <Route path="notification" element={<Notification />} />
            <Route path="verification" element={<Verification />} />
            <Route path="blog" element={<Blog />} />
            <Route path="help" element={<HelpHome />} />
            <Route path="faqs" element={<FaqsHome />} />\
            <Route path="contactus" element={<ContactusPage />} />
            <Route
              path="password/send-email"
              element={<SendPasswordResetLink />}
            />
            <Route path="password/set-password" element={<SetNewPassword />} />
            <Route path="password/confirmed" element={<ResetSuccess />} />
          </Route>

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/" element={<SharedNavLayout />}>
            <Route index path={"bookmarks"} element={<Bookmarks />} />
          </Route>

          <Route path="/organizations" element={<SharedNavLayout />}>
            <Route path=":id" element={<OrganizationDetail />} />
            <Route element={<ProtectedRoute />}>
              <Route element={<AddOrganizationProtectedRoute />}>
                <Route path="upgrade" element={<ProfileUpgrade />} />
              </Route>
            </Route>

            <Route path="filter" element={<AllOrg />} />
            <Route path="" element={<ListofOrg />} />
          </Route>

          <Route path="/rental" element={<SharedNavLayout />}>
            <Route path="contact" element={<RentalContact />} />
            <Route index element={<AllRental />} />
            <Route path="filter" element={<RentalFilter />} />
            <Route path=":id" element={<RentDetail />} />
            <Route path="userdetail" element={<UserRentalDetail />} />
            <Route path="upgrade" element={<ProfileUpgrade />} />
            <Route path="add" element={<AddRental />} />
            <Route path="edit" element={<RentalEdit />} />
          </Route>
          <Route path="/flatemate" element={<SharedNavLayout />}>
            <Route element={<ProtectedRoute />}>
              <Route element={<AddFlatemateProtectedRoute />}>
                <Route path="add" element={<AddFlateMate />} />
              </Route>
            </Route>
            <Route path="profile" element={<FlateMateDetail />} />
            <Route path=":id" element={<FlateMateProfile />} />
          </Route>
          <Route path="/blogs" element={<SharedNavLayout />}>
            <Route index element={<BlogHome />} />
            <Route path="result" element={<BlogSearchResult />} />
            <Route path=":id" element={<BlogDetail />} />
          </Route>
          <Route path="test" element={<Test />} />
          <Route path="drag" element={<EditFeatured />} />
          <Route path="admin/login" element={<AdminLogin />} />
          <Route path="admin" element={<AdminNavLayout />}>
            <Route element={<AdminPrivateRoute />}>
              <Route index element={<AdminDashboard />} />
              <Route path="members" element={<AdminAdmin />} />
              <Route path="add" element={<AdminAddAdmin />} />
              <Route path="contactform" element={<AdminContact />} />
              <Route path="account" element={<Account />} />
              <Route path="companies" element={<AdminOrganizations />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="user/add" element={<AdminUserAdd />} />
              <Route path="404" element={<ErrorPage />} />
              <Route path="settings" element={<SettingPage />} />
              <Route path="review" element={<OrganizationReview />} />
              <Route path="action" element={<ActionPage />} />
              <Route path="business" element={<AdminBusiness />} />
              <Route path="business/add" element={<AdminAddBusiness />} />
              <Route path="organization/add" element={<AdminAddOrg />} />
              <Route path="events" element={<AdminEventsAndGallary />} />
              <Route path="events/add" element={<AdminAddEvent />} />
              <Route path="event/edit" element={<AdminEditEvent />} />
              {/* help and support */}
              <Route path="helpandsupport" element={<AdminHelpAndSupport />} />
              {/* blog */}
              <Route path="blogs" element={<AllBlog />} />
              <Route path="blogs/create" element={<AddBlog />} />
              <Route path="blog/edit/:id" element={<AdminEditBlog />} />
              <Route path="rental" element={<AdminRental />} />
              <Route path="flateMate" element={<AdminFlateMate />} />
              <Route path="professionals" element={<AdminProfessional />} />
              <Route
                path="professionals/add"
                element={<AdminAddProfessional />}
              />
              {/* students */}
              <Route path="students" element={<AdminStudents />} />
              <Route
                path="students/edit/:country"
                element={<AddStudentsRequirements />}
              />
              <Route
                path="students/editing/:country"
                element={<EditStudentRequirements />}
              />
              {/* faqs */}
              <Route path="faqs" element={<AdminFaqs />} />

              {/* featured */}
              <Route path="featuredAds" element={<AdminAdvertisements />} />

              {/* notification */}
              <Route path="notification" element={<AdminNotification />} />
            </Route>
          </Route>
          {/* // End Admin Private Route */}

          <Route path="/profile" element={<SharedNavLayout />}>
            <Route element={<ProtectedRoute />}>
              <Route index element={<Profile />} />
            </Route>
          </Route>



          <Route path="/professionals" element={<SharedNavLayout />}>
            <Route index element={<ProfessionalAll />} />

            <Route element={<ProtectedRoute />}>
              <Route element={<AddProfessionalProtectedRoute />}>
                <Route path="add" element={<AddProfessional />} />
              </Route>
            </Route>

            <Route path=":id" element={<ProfessionalDetail />} />
            <Route path="filter" element={<ProfessionalFilter />} />
          </Route>
          <Route path="/abroad" element={<SharedNavLayout />}>
            <Route index element={<StudentAll />} />
            <Route path=":id" element={<StudentCountryDetail />} />
            <Route
              path="requirements/:id"
              element={<StudentRequirementsUser />}
            />
          </Route>

          {/* news */}
          <Route path="/news" element={<SharedNavLayout />}>
            <Route index element={<NewsHome />} />
            <Route path="result" element={<NewsSearchResult />} />
          </Route>

          <Route path="/business" element={<SharedNavLayout />}>
            <Route index element={<AllBusiness />} />
            <Route path=":id" element={<BusinessDetail />} />
            <Route path="filter" element={<BusinessFilterPage />} />
            <Route element={<ProtectedRoute />}>
              <Route path="add" element={<AddBusiness />} />
            </Route>
          </Route>

          {/* events and gallary */}
          <Route path="/events" element={<SharedNavLayout />}>
            <Route index element={<EventsAndGallary />} />
            <Route path=":id" element={<EventsDetails />} />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
