import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { Card, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";

export const AdminUsersSearch = ({ handleChange, handleKeyPress,placeholder }) => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      defaultValue=""
      fullWidth
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <MagnifyingGlassIcon />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);
