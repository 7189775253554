import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Tab } from "@mui/material";
import { Box, Typography, InputLabel, Input } from "@mui/material";
import React from "react";
import {
  AdminOrgAdsTable,
  AdminProfessionalsAdsTable,
  AdminBusinessAdsTable,
  AdminRentalAdsTable,
} from "./tabs";
import { Modal } from "@mui/material";
import { ImCross } from "react-icons/im";
import {useDispatch, useSelector} from "react-redux";
import {adminCreateAdsBannerAction} from "../../../../context/actions/admin/featuredAdsAction";
import { toast } from "react-toastify";
import { clearError } from "../../../../context/reducers/adminSlice";

const AdminAdvertisements = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", lg: "70%" },
    bgcolor: "#E9E9E9",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const imageslst = [
    {
      id: 1,
      preview: "/assets/images/default_banner.jpg",
      file: "",
      url: "",
    },
    {
      id: 2,
      preview: "/assets/images/default_banner.jpg",
      file: "",
      url: "",
    },
    {
      id: 3,
      preview: "/assets/images/default_banner.jpg",
      file: "",
      url: "",
    },
  ];

  const dispatch = useDispatch();

  // use selector to get the state from redux store of admin
  const { adminCreateAdsBannerLoading, error } = useSelector((state) => ({
    error: state.admin_reducer.error,
    adminCreateAdsBannerLoading:
      state.admin_reducer.adminCreateAdsBannerLoading,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);


  const tabs = ["Organization", "Business", "Rental", "Professional"];
  const [value, setValue] = React.useState("Organization");

  const [banner, setBanner] = React.useState(imageslst);
  const [imgError, setErrors] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setBanner(imageslst);
    setErrors(false);
    setOpen(false);
  };
  // change tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const bannerChange = (e, index) => {
    const imageFile = e.target.files[0];
    const blobUrl = URL.createObjectURL(imageFile);

    // Check file size
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (imageFile.size > maxSize) {
      // Handle file size error
      setErrors(true);

      return;
    }

    // Create an image element to get the image dimensions
    const imageElement = new Image();
    imageElement.src = blobUrl;

    imageElement.onload = () => {
      // Check image resolution
      const maxWidth = 1300;
      const maxHeight = 260;
      const { width, height } = imageElement;

      if (width < maxWidth || height < maxHeight) {
        // Handle resolution error
        setErrors(true);
        return;
      }

      setErrors(false);

      setBanner(
        banner.map((item, i) =>
          i === index ? { ...item, file: imageFile, preview: blobUrl } : item
        )
      );
    };
  };

  const handleBannerUrlChange = (e, index) => {
    setBanner(
      banner.map((item, i) =>
        i === index ? { ...item, url: e.target.value } : item
      )
    );
  };



  const onBannerSave = () => {
    // e.preventDefault();
   const formData = new FormData();
    banner.forEach((item) => {
      formData.append("image", item.file);
      formData.append("url", item.url);
    }
    );
    dispatch(adminCreateAdsBannerAction({formData:formData, toast:toast}));


  };

  return (
    <div className="custom-container d-flex flex-column gap-2">
      <Box
        className="headerimg position-relative rounded rounded-5 overflow-hidden"
        sx={{
          height: "300px",
        }}
      >
        <Box
          className="w-100 h-100 position-absolute top-0 start-0"
          sx={{
            background:
              "linear-gradient(90deg, #000000 -1.46%, rgba(138, 91, 91, 0) 79%);",
          }}
        ></Box>
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-mdb-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/assets/images/default_banner.jpg"
                className="d-block w- object-cover"
                alt="Wild Landscape"
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/default_banner.jpg"
                className="d-block w-100 object-cover"
                alt="Camera"
              />
            </div>
            <div className="carousel-item">
              <img
                src="/assets/images/default_banner.jpg"
                className="d-block w-100 object-cover"
                alt="Exotic Fruits"
              />
            </div>
          </div>
          <Button
            className="btn text-white text-capitalize position-absolute"
            data-mdb-toggle="modal"
            data-mdb-target="#exampleModal"
            onClick={handleOpen}
            sx={{
              width: "150px",
              top: "2%",
              right: "1%",
              backgroundColor: "#13131384",
              backdropFilter: "blur(25px)",
            }}
          >
            Edit Featured
          </Button>
        </div>
        <Box
          className="d-flex flex-column position-absolute"
          sx={{
            top: "35%",
            left: "3%",
            fontSize: "1.3rem",
          }}
        >
          <h5 className="fw-bold text-white">Featured.</h5>
          <p className="text-white fs-6">
            Lorem ipsum dolor sit amet consectetur
          </p>
          <button
            type="button"
            className="btn btn-outline-white w-50 mt-3"
            data-mdb-ripple-color="dark"
          >
            Show More
          </button>
        </Box>
        <button>sahhh</button>
      </Box>

      <section>
        <div className="d-flex align-items-center justify-content-between">
          <Typography
            variatn="span"
            className="fw-bold"
            sx={{
              fontSize: { xs: "1em", lg: "2em" },
            }}
          >
            Featured Ads
          </Typography>

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {tabs.map((item, index) => {
                  return (
                    <Tab
                      label={item}
                      key={index}
                      value={item}
                      indicatorcolor={"transparent"}
                      className="text-capitalize"
                      sx={{
                        "&.Mui-selected": {
                          color: "#797979",
                          fontWeight: "bolder",
                          border: "none",
                          background: "#F6F6F6",
                        },
                        "&.MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                    />
                  );
                })}
              </TabList>
            </Box>
          </TabContext>
        </div>
      </section>

      <section className="mb-3">
        <TabContext value={value} className="p-0 m-0  ">
          <TabPanel value="Organization" className="p-0 m-0">
            <AdminOrgAdsTable setOpen={setOpen} closeModal={handleClose} />
          </TabPanel>
          <TabPanel value="Business" className="p-0 m-0">
            <AdminBusinessAdsTable setOpen={setOpen} closeModal={handleClose} />
          </TabPanel>
          <TabPanel value="Rental" className="p-0 m-0">
            <AdminRentalAdsTable setOpen={setOpen} closeModal={handleClose} />
          </TabPanel>

          <TabPanel value="Professional" className="p-0 m-0">
            <AdminProfessionalsAdsTable
              setOpen={setOpen}
              closeModal={handleClose}
            />
          </TabPanel>
        </TabContext>
      </section>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="position-relative">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">
                Homepage Featured Banner
              </span>
              <small
                className={imgError ? "text-danger mb-3" : "text-muted mb-3"}
              >
                Recommended size: 1920 x 1080 pixels. Maximum file size: 5MB.
                {imgError && "*"}
              </small>
            </div>
            <Box
              className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50px",
              }}
              role="button"
              onClick={handleClose}
            >
              <ImCross style={{ fontSize: "1.3em" }} />
            </Box>
          </div>
          <div className="d-flex flex-column gap-2 w-100">
            {banner.map((item, index) => {
              return (
                <Box
                  className="position-relative rounded rounded-3 overflow-hidden"
                  key={index}
                  sx={{
                    height: "150px",
                  }}
                >
                  <img
                    src={item.preview}
                    alt=""
                    srcSet=""
                    className="img-fluid"
                  />
                  <Input
                    type="file"
                    accept="images/*"
                    className="d-none"
                    name={`image${index}`}
                    id={`image${index}`}
                    onChange={(e) => bannerChange(e, index)}
                  />
                  <InputLabel
                    htmlFor={`image${index}`}
                    className="bg-hover p-1 rounded rounded-2 text-bold  "
                    sx={{
                      position: "absolute",
                      top: "2%",
                      right: "1%",
                    }}
                  >
                    Change
                  </InputLabel>

                  <Input
                    sx={{
                      position: "absolute",
                      top: "5%",
                      left: "0.5%",
                    }}
                    variant="standard"
                    name="title"
                    placeholder="Enter Navigation url"
                    className="bg-hover p-1 rounded text-bold w-50"
                    onChange={(e) => handleBannerUrlChange(e, index)}
                  ></Input>
                </Box>
              );
            })}
          </div>

          <div className="d-flex  justify-content-end w-100 gap-1 mt-2">
            <Button
              sx={{
                backgroundColor: "#414141",
                color: "white",
                minWidth: "150px",
                "&:hover": {
                  backgroundColor: "#414141",
                },
              }}
              variant="text"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "#414141",
                color: "white",
                minWidth: "150px",

                "&:hover": {
                  backgroundColor: "#414141",
                },
              }}
              variant="text"
              onClick={onBannerSave}
            >
              save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminAdvertisements;
