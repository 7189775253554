import {
  Bathroom,
  LocationOn,
  RemoveRedEye,
  SecurityRounded
} from "@mui/icons-material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import FlagIcon from "@mui/icons-material/Flag";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, ButtonGroup, Chip, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ChatBox, RentalOffers } from "../../components/Index";
import { getRentalDetailAction } from "../../context/actions/rentalAction";
// FaCommentDollar
import moment from "moment";
import { BsCalendar2Date } from "react-icons/bs";
import { FaCommentDollar, FaLayerGroup } from "react-icons/fa";
import { MdOutlineBedroomChild, MdOutlineBookmarkAdd } from "react-icons/md";
import { TbToolsKitchen } from "react-icons/tb";
import ShowLocation from "../../components/location/ShowLocation";
import { post_bookmark } from "../../context/actions/authAction";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import RentalDetailSkeleton from "./RentalDetailSkeleton";
import DoneIcon from "@mui/icons-material/Done";
import RentOfferModal from "./RentOfferModal";
import { useState } from "react";
import { Helmet } from "react-helmet-async";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;

    gap: 5px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;

const RentDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
    // open services modal
    const [openServices, setOpenServices] = useState(false);
    const handleOpenServices = () => setOpenServices(true);
    const handleCloseServices = () => setOpenServices(false);
    

  const [type] = React.useState("Rental");
  const [bookmarked, setbookmarked] = React.useState(false);
  // get the bookmarked data
  useEffect(() => {
    dispatch(getRentalDetailAction(id));
  }, [id]);

  const { rental_detail, isAuth, bookmarks, loading, senderId } = useSelector(
    (state) => ({
      rental_detail: state.rental_reducer.rental_detail,
      loading: state.rental_reducer.loading,
      isAuth: state.auth_slice.isAuth,
      bookmarks: state.auth_slice.user_data?.bookmarks || [],
      senderId: state.auth_slice.user_data?.id,
    })
  );

  // get the bookmarked data

  // check if the bookmark is already there
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  // // set the bookmarked state
  React.useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  // set the type of bookmark to initiate the bookmark
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  // set the bookmark
  React.useEffect(() => {
    if (rental_detail && bookmark.id !== rental_detail._id) {
      setBookmark({
        name: rental_detail?.title,
        type: type,
        image: rental_detail?.images[0],
        category: rental_detail?.houseType,
        location: rental_detail?.location?.place_name,
      });
    }
  }, [rental_detail, bookmark.id, type]);

  // toogle the bookmark and if the bookmark is already there then return early
  const togglebookmark = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id, data: bookmark }));
    } else {
      navigate("/login");
    }
  };

  // show more description
  const [showMore, SetshowMore] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  // tab change

  const [slider, setslider] = React.useState("0");

  // like state

  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  // on tab change
  const handleModalSlider = (newValue) => {
    setslider(newValue);
  };
  if (loading) {
    return <RentalDetailSkeleton />;
  }

  if (
    (rental_detail && Object?.keys(rental_detail).length === 0) ||
    rental_detail === undefined
  ) {
    return <NotFoundPage />;
  }


  return (
    <>
     <Helmet>
        <title>
          {rental_detail?.title}
        </title>
        <meta name="description"
          data-rh="true"
          content={rental_detail?.description} />
        <link rel="canonical" href="/register" />
      </Helmet>
      {loading ? (
        <RentalDetailSkeleton />
      ) : (
        <Wrapper className="container d-flex flex-column">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gridAutoRows: { xs: "140px", md: "170px", lg: "200px" },
              borderRadius: "25px",
            }}
            className="grid-images position-relative rounded rounded-3 overflow-hidden mt-3"
          >
            {/* For real */}
            {rental_detail?.images?.slice(0, 5).map((item, index) => {
              return (
                <div className="w-100 h-100 item" key={index}>
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                    className="w-100 object-cover h-100"
                  />
                </div>
              );
            })}

            {/* open modal button */}
            <Box
              sx={{
                position: "absolute",
                bottom: "3%",
                right: "1%",
              }}
              className="btn d-flex gap-2 align-items-center bg-white"
              onClick={handleOpen}
            >
              <CgMenuGridR className="position bottom-0" />
              <span>Show all photos</span>
              {rental_detail?.images?.length}
            </Box>
          </Box>

          <section className="d-flex flex-column text-black ">
            <div className="d-flex align-items-center w-100 justify-content-between">
              <Typography
                variant="span"
                sx={{
                  fontSize: { xs: "1.1em", md: "1.8em" },
                }}
              >
                {rental_detail?.title}
              </Typography>

              <div className="action-icons d-flex align-items-center">
                <ButtonGroup
                  className="text-black"
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button>
                    <div className="comments d-flex align-items-center gap-1">
                      <RemoveRedEye />
                      {rental_detail?.viewCount}
                    </div>
                  </Button>
                  <Button>
                    <div className="clapped d-flex align-content-center gap-1">
                      {/* <FacebookShareButton
                        url={'https://www.example.com'}
                        quote={'Dummy text!'}
                        hashtag="#muo"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton> */}
                      Share
                    </div>
                  </Button>
                  <Button>
                    <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                      {bookmarked ? (
                        <BookmarkIcon onClick={togglebookmark} role="button" />
                      ) : (
                        <MdOutlineBookmarkAdd
                          onClick={togglebookmark}
                          role="button"
                        />
                      )}
                    </div>
                  </Button>
                </ButtonGroup>
              </div>
            </div>

            <p>
              <span>
                <LocationOn fontSize="small" />{" "}
                {rental_detail?.location?.place_name}
              </span>
            </p>
          </section>

          <div className=" two-section row-cols-1 row-cols-lg-2 row w-100 justify-content-between">
            <div className="left-section col-lg-8 d-flex flex-column  col">
              <section className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex mt-2 w-100 gap-4 flex-wrap">
                  <div className="d-flex gap-3 ">
                    <FaCommentDollar size={40} />
                    <div>
                      <p className="m-0">Pricing</p>
                      <h5 className="fw-bold">
                        {rental_detail?.price} <small>/month</small>
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <MdOutlineBedroomChild size={45} />
                    <div>
                      <p className="m-0">Bedroom</p>
                      <h5 className="fw-bold">
                        {rental_detail?.bedroom} Available
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <TbToolsKitchen size={45} />
                    <div>
                      <p className="m-0">Kitchen</p>
                      <h5 className="fw-bold">
                        {rental_detail?.kitchen} Available
                      </h5>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <Bathroom size={45} />
                    <div>
                      <p className="m-0">Bathroom</p>
                      <h5 className="fw-bold">
                        {rental_detail?.bathroom} Available
                      </h5>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <p className="fw-bold fs-5 mt-3">About the property</p>
                <p>
                  {showMore
                    ? rental_detail?.description
                    : rental_detail?.description?.slice(0, 200)}
                  {rental_detail?.description?.length > 200 ? (
                    !showMore ? (
                      <span
                        onClick={toggleShowMore}
                        className="text-black fw-bold"
                        role="button"
                      >
                        ...ReadMore
                      </span>
                    ) : (
                      <span
                        onClick={toggleShowMore}
                        className="text-black fw-bold"
                        role="button"
                      >
                        ...ReadLess
                      </span>
                    )
                  ) : null}
                </p>
              </section>

              <section>
                <p className="fw-bold fs-5 mt-3 mb-2">Property overview</p>

                <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-2 align-items-center justify-content-between">
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
                      <div className="d-flex gap-2">
                        <BsCalendar2Date size={45} />
                        <div>
                          <p className="m-0">Min length of stay</p>
                          <h5 className="fw-bold m-0 p-0">
                            {rental_detail?.minimumStay}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
                      <div className="d-flex gap-2">
                        <BsCalendar2Date size={45} />
                        <div>
                          <p className="m-0">Available from</p>
                          <h5 className="fw-bold m-0 p-0">
                            {moment(rental_detail?.availableDate).format(
                              "MMMM Do YYYY"
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
                      <div className="d-flex gap-2">
                        <FaLayerGroup size={45} />
                        <div>
                          <p className="m-0">Tenent have access of</p>
                          <h5 className="fw-bold m-0 p-0">
                            {rental_detail?.accessTo}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section>
                <p className="fw-bold fs-5 mt-3 mb-2">Offerings</p>

                <div className="d-flex flex-wrap gap-2">
                  {rental_detail?.offers.slice(0, 7).map((item, index) => {
                    return (
                      <Chip
                        icon={
                          <DoneIcon
                            sx={{
                              color: "green",
                            }}
                          />
                        }
                        key={item}
                        label={item}
                        variant="outlined"
                        size="large"
                      />
                    );
                  })}
                </div>
                <button
                  onClick={handleOpenServices}
                  className="btn btn-sm btn-outline-black mt-2"
                >
                  show all services
                </button>
                <RentOfferModal
                  openServices={openServices}
                  handleCloseServices={handleCloseServices}
                  offers={rental_detail?.offers}
                />
              </section>

              <section>
                <p className="fw-bold fs-5 mt-3 mb-2">
                  Property is located at.
                </p>
                <ShowLocation location={rental_detail?.location} />
              </section>
            </div>

            <div className="right-section col-lg-4">
              <ChatBox user_data={rental_detail?.user} property_id={id} />
              <p className="fw-small text-danger">
                your information is only shared with the owner of the property,
                and fully secured. <SecurityRounded />
              </p>

              <div
                role="button"
                className="d-flex align-items-center gap-1 justify-content-center"
              >
                <span>
                  <FlagIcon />
                </span>
                <span className="text-decoration-underline text-black">
                  Report this listing
                </span>
              </div>
            </div>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="bg-transparent"
          >
            <Box sx={style} className="position-relative">
              <Box
                className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
                sx={{
                  position: "absolute",
                  top: "2%",
                  right: "2%",
                  width: 40,
                  height: 40,
                }}
                onClick={handleClose}
              >
                <ImCross
                  className=" bg-hover rounded rounded-circle fs-5"
                  role="button"
                />
              </Box>
              <TabContext value={slider}>
                {rental_detail?.images?.map((item, index) => {
                  const indexs = index.toString();
                  return (
                    <TabPanel
                      value={indexs}
                      key={index}
                      sx={{
                        Height: "80vh",
                      }}
                    >
                      <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          className=" object-contain"
                          height={480}
                        />
                      </div>
                    </TabPanel>
                  );
                })}
                <Tabs
                  onChange={handleModalSlider}
                  className=" d-flex  justify-content-center mx-auto  "
                  sx={{
                    justifyContent: "center",
                    width: "max-content",
                    "&.Mui-selected": {
                      color: "#797979",
                      fontWeight: "bolder",
                      border: "none",
                    },
                  }}
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                  value={slider}
                >
                  {rental_detail?.images?.map((item, index) => {
                    const indexs = index.toString();

                    return (
                      <Tab
                        key={index}
                        value={indexs}
                        className="p-0 m-0"
                        role="button"
                        sx={{
                          "&.Mui-selected": {
                            transform: "scale(1.5)",
                            borderBottom: "none",
                            zIndex: 20,
                          },
                        }}
                        icon={
                          <Box
                            className="w-100 p-1 d-flex align-item-center "
                            sx={{
                              height: 70,
                              "&.Mui-selected": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                              alt=""
                              srcSet=""
                              height={60}
                              widht={90}
                              className="object-cover w-100 mx-auto p-0 "
                            />
                          </Box>
                        }
                      />
                    );
                  })}
                </Tabs>
              </TabContext>
            </Box>
          </Modal>
        </Wrapper>
      )}
    </>
  );
};

export default RentDetail;
