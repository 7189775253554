import { BookmarkOutlined, LocationOn, LocationOnOutlined, NorthEastRounded } from "@mui/icons-material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HTMLReactParser from "html-react-parser";
import { ArrowRightAlt, BookmarkBorderOutlined } from "@mui/icons-material";


const BookmarkBusinessCard = ({ business }) => {
  console.log(business);
  const { name, image, location, category } = business;

  const dispatch = useDispatch();
  const [liked, setliked] = React.useState(false);

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  const togglelike = () => {
    if (isAuth) {
      // do something
    }
  };

  return (

    <Box
      className="shadow-4 rounded rounded-1 col position-relative custom-card"
      sx={{
        aspectRatio: "1/1.3",
      }}
    >
      <Link
        to={`/business/${business.id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>

      <Box
        sx={{
          height: "200px",
        }}
        className="rental-img w-100 position-relative overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${image}`}
          alt=""
          className="w-100 h-100 object-cover img-fluid"
        />

      </Box>

      <div className="m-1 p-1 m-sm-2 p-sm-2">




        <Typography
          variant="span"
          className="text-black p-1 fw-semibold pt-2 title"
        >
          {name}
        </Typography>

        <Box
          sx={{
            fontSize: { xs: "0.6em", md: "0.9rem" },
          }}
          className="d-flex align-items-center gap-1"
        >
          <LocationOn
            fontSize="small"
            sx={{
              fontSize: "inherit",
            }}
          />
          <Typography
            sx={{
              marginLeft: "0.2rem",
              fontSize: "inherit",
            }}
            className="text-overflow-hidden text-black "
          >
            {location}
          </Typography>
        </Box>


        {/* centre */}

        <div className="text-center">
          <button className="btn btn-danger btn-sm  mt-4">
            Show More <NorthEastRounded
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            />
          </button>
        </div>




        <Divider className="my-2" />

        <Box className="d-flex justify-content-between mt-4">

          <Typography
            variant="span"
            className="btn btn-sm  btn-outline-black category-box"
            sx={
              {
                // fontSize: { xs: "0.9em", sm: "0.8em", lg: "1.3em" },
              }
            }
          >
            {category}
          </Typography>
          <Box className="org_footer  d-flex gap-2 align-items-center">

            <Box
              sx={{
                zIndex: "4",
              }}
              className="text-black"
            >
              {liked ? (
                <BookmarkBorderOutlined
                  role="button"
                  className="cIcon"
                  onClick={togglelike}
                />
              ) : (
                <BookmarkOutlined
                  role="button"
                  className="cIcon"
                  onClick={togglelike}
                />
              )}
            </Box>
          </Box>
        </Box>

      </div>
    </Box>
  );
};

export default BookmarkBusinessCard;
