import styled from 'styled-components';

const StyledSection = styled.div`
    & > .newscard:hover {
        cursor: pointer;
        .n-title {
            text-decoration: underline;
          }
    } 
`;
export default StyledSection;
