import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AddOrganizationProtectedRoute = () => {
  const { organization_profile } = useSelector((state) => state.user_reducer);

  return Object.keys(organization_profile).length > 0 ? (
    <Navigate to="/profile" replace />
  ) : (
    <Outlet />
  );
};

export default AddOrganizationProtectedRoute;
