import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { maxSize } from "../../utils/globalSettings";

const ImageInput = React.memo(
  ({ handleimages, removeimages, errors, onError }) => {
    const togglestate = {
      images: [],
      filepath: [],
      selectedImages: "",
    };

    const [values, setValues] = React.useState(togglestate);

    var settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: values.images.length < 5 ? values.images.length : 5,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      arrows: true,
    };

    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      const imageFile = e.target.files || e.dataTransfer.files;

      const selectedFilesArray = Array.from(imageFile);
      const imageArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      handleimages(selectedFilesArray);

      setValues({
        ...values,
        images: imageArray,
        filepath: selectedFilesArray,
      });
    }

    function handleDragOver(e) {
      e.preventDefault();
    }

    function SampleNextArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "flex",
            alignItems: "center",
            background: "black",
            paddingTop: "2px",
            color: "black",
            borderRadius: "50px",
          }}
          onClick={onClick}
        />
      );
    }

    function SamplePrevArrow(props) {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{
            ...style,
            display: "flex",
            alignItems: "center",
            background: "black",
            paddingTop: "2px",
            borderRadius: "50px",
          }}
          onClick={onClick}
        />
      );
    }

    const onSelectedFile = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      let newfilearray = [...values.filepath];
      let imageArray = [...values.images];
      let errorFile = "";
      let isValid = true;
      for (let i = 0; i < selectedFilesArray.length; i++) {
        const file = selectedFilesArray[i];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB <= maxSize) {
          // Limit file size to 3MB
          imageArray?.push(URL.createObjectURL(file));
          newfilearray.push(file);
        } else {
          errorFile = `${errorFile} , ${file.name}`;
          isValid = false;
          onError({
            name: "images",
            msg: `"${errorFile}" files exceeds the size limit of 3MB. can not be uploaded`,
          });
          // console.log(`"${errorFile}" files exceeds the size limit of 3MB.`);
        }
      }

      if (isValid) {
        onError({ name: "images", msg: "" });
      }

      setValues({ ...values, images: imageArray, filepath: newfilearray });

      //h
      handleimages(newfilearray);
      e.target.value = "";
    };

    const removeimage = (index) => {
      let newimagearray = [...values.images];
      let newfilearray = [...values.filepath];
      newimagearray = newimagearray
        .slice(0, index)
        .concat(newimagearray.slice(index + 1));

      newfilearray = newfilearray
        .slice(0, index)
        .concat(newfilearray.slice(index + 1));

      setValues({ ...values, images: newimagearray, filepath: newfilearray });
      removeimages(newfilearray);
      onError({ name: "images", msg: "" });
    };
    return (
      <div onDrop={onSelectedFile} className={" d-flex flex-column gap-2"}>
        <div
          className="d-flex justify-content-between w-100 flex-column flex-md-row 
      align-items-center"
        >
          <div className="d-flex flex-column gap-0 w-maxcontent">
            <span className="fs-4 fw-bolder text-nowrap m-0 p-0 text-black">
              Add Photos of your business
            </span>
            <p className="">upload minimum of 5 photos of your listings</p>
          </div>
          <div className="d-flex w-maxcontent justify-content-between align-items-center h-maxcontent">
            <input
              type="file"
              name=""
              accept="image/*"
              id="photoupload"
              multiple
              onChange={(e) => onSelectedFile(e)}
              className="mb-3 d-none"
              title=" "
            />
            <label
              htmlFor="photoupload"
              className="d-flex flex-column text-nowrap h-maxcontent gap-0 btn fw-bold h-100 btn-dark text-capitalize "
            >
              Add Image
            </label>
          </div>
        </div>
        <Box
          sx={{
            border:
              errors.images && values.images.length > 0
                ? "2px dashed #ff6464"
                : values.images.length > 0
                ? "2px dashed grey"
                : "",

            maxHeight: "250px",
          }}
          className="py-2 px-1"
        >
          <Slider {...settings}>
            {values.images.map((item, index) => {
              return (
                <Box
                  className="position-relative  p-1 overflow-hidden  me-auto grid-box"
                  key={item}
                  sx={{
                    maxWidth: "220px",
                  }}
                >
                  <div className="position-relative top-0 start-0  h-100 w-100">
                    <img
                      src={item}
                      alt=""
                      srcSet=""
                      className="object-cover w-100 h-100"
                    />
                    <CancelIcon
                      className="position-absolute  "
                      sx={{
                        top: "-2%",
                        right: "-2%",
                        color: "white",
                      }}
                      onClick={() => removeimage(index)}
                    />
                  </div>
                </Box>
              );
            })}
          </Slider>
        </Box>

        {values.images.length === 0 && (
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="w-100 position-relative text-center d-flex justify-content-center"
            style={{ height: "200px", border: "2px dashed gray" }}
          >
            <Box
              className="position-absolute text-center "
              sx={{
                top: "50px",
              }}
            >
              <label
                htmlFor="photoupload"
                className="d-flex flex-column gap-0 btn fw-bold h-100 btn-dark text-capitalize "
              >
                upload
              </label>
              <p>Drag and drop an image here</p>
            </Box>
          </div>
        )}

        {errors.images && (
          <small className={errors.images ? "text-danger" : "d-none"}>
            {errors.images}
          </small>
        )}
      </div>
    );
  }
);

ImageInput.propTypes = {
  handleimages: PropTypes.func,
  removeimages: PropTypes.func,
  errors: PropTypes.object,
  inputFiles: PropTypes.array,
};

export default ImageInput;
