import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  FlateMateInfo,
  PreferedLocation,
  AboutYourSelf,
  YourBudget,
  PropertyPreferences,
  AccomendationTypes,
  LookingFor,
} from "../../../addflatemate/formstepps/index";
import { ImCross } from "react-icons/im";

import { adminCreateFlatemateAction } from "../../../../context/actions/admin/flatemateAction";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../../../context/reducers/adminSlice";
const AdminAddFlateMate = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formvalues = {
    firstName: "",
    lastName: "",
    contact: "",
    gender: "",
    occupation: "",
    email: "",
    preferedLocations: [],
    features: [],
    description: "",
    budgetPerWeek: 0,
    lengthOfStay: "6 months",
    moveInDate: "",
    roomFurnishings: "Not Required",
    internet: "Not Required",
    bathRoomType: "Flexible",
    parking: "Flexible",
    totalFlateMates: 1,
    accomondationType: [],
    teamUp: "",
    haveProperty: "",
    typeOfProperty: "",
    propertyCapacity: "",
    propertyLocation: "",
    age: "",
    userPic: "",
    lookingFor: "",
  };
  const formerrors = {
    firstName: "",
    lastName: "",
    contact: "",
    gender: "",
    occupation: "",
    email: "",
    preferedLocations: "",
    features: "",
    description: "",
    budgetPerWeek: "",
    lengthOfStay: "",
    moveInDate: "",
    roomFurnishings: "",
    internet: "",
    bathRoomType: "",
    parking: "",
    totalFlateMates: "",
    accomondationType: "",
    teamUp: "",
    haveProperty: "",
    typeOfProperty: "",
    propertyCapacity: "",
    propertyLocation: "",
    age: "",
    userPic: "",
    userPicFile: "",
    lookingFor: "",
  };
  // activesteo indcates current step of the form ( 0 - 6)
  

  const { createFlatemateLoading, error } = useSelector((state) => ({
    error: state.admin_reducer.error,
    createFlatemateLoading: state.admin_reducer.createFlatemateLoading,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);


  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formvalues);

  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);

  // change value base on e.target.name
  const handleFormChange = (e) => {
    if (e.target.name === "userPic") {
      const file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      e.target.value = "";

      setformvalues({ ...formvalue, userPic: blobURL, userPicFile: file });
    } else {
      setformvalues({ ...formvalue, [e.target.name]: e.target.value });
    }
  };
  // handles PhoneNumber
  const handlePhoneNumber = (phone) => [
    setformvalues({ ...formvalues, contact: phone }),
  ];
  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "budgetPerWeek":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: value });
        }
        break;

      case "addbudgetPerWeek":
        if (value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: 1 });
        }

        if (value <= 100000000) {
          const addedbudgetPerWeek = parseInt(value) + 1;
          setformvalues({ ...formvalue, budgetPerWeek: addedbudgetPerWeek });
        }
        break;

      case "minusbudgetPerWeek":
        if (value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: 0 });
        }
        if (value > 0) {
          const minusbudgetPerWeek = parseInt(value) - 1;
          setformvalues({ ...formvalue, budgetPerWeek: minusbudgetPerWeek });
        }
        break;

      case "teamUp":
        setformvalues({ ...formvalue, teamUp: true, haveProperty: "" });

        break;

      case "noTeamUp":
        setformvalues({
          ...formvalue,
          teamUp: false,
          haveProperty: false,
        });

        break;

      case "haveProperty":
        setformvalues({ ...formvalue, haveProperty: true });
        break;

      case "noProperty":
        setformvalues({
          ...formvalue,
          haveProperty: false,
          typeOfProperty: "",
          propertyCapacity: "",
          propertyLocation: "",
        });
        break;

      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

    const removelocation = (index) => {
      setformvalues((prevFormValues) => ({
        ...prevFormValues,
        preferedLocations: prevFormValues.preferedLocations.filter(
          (_, i) => i !== index
        ),
      }));
      if (errors.preferedLocations) {
        setErrors({ ...errors, preferedLocations: "" });
      }
    };

  // form validation returns true or false when next button clicked
  const formvalidation = () => {
    let isValid = false;

    const newerror = { ...formerrors };

    const phoneRegex = /^\d{10}$/;
    const isphone = phoneRegex.test(formvalue.contact);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)?$/;

    const isValidEmail = emailRegex.test(formvalue.email);
    if (
      !formvalue.firstName ||
      !formvalue.lastName ||
      !formvalue.email ||
      !formvalue.contact ||
      !formvalue.occupation ||
      !formvalue.gender ||
      !formvalue.age ||
      !isphone ||
      !isValidEmail ||
      !formvalue.userPic ||
      !formvalue.lookingFor ||
      formvalue.preferedLocations.length === 0 ||
      !formvalue.description ||
      formvalue.features.length === 0 ||
      formvalue.budgetPerWeek === 0 ||
      !formvalue.budgetPerWeek ||
      !formvalue.moveInDate ||
      formvalue.accomondationType.length === 0 ||
      formvalue.teamUp === "" ||
      (formvalue.teamUp && formvalue.haveProperty === "") ||
      (formvalue.haveProperty && !formvalue.typeOfProperty) ||
      (formvalue.haveProperty && !formvalue.propertyCapacity) ||
      (formvalue.haveProperty && !formvalue.propertyLocation)
    ) {
      if (formvalue.accomondationType.length === 0) {
        newerror.accomondationType =
          "Please select at leaset one accomondation type *";
      }
      if (formvalue.teamUp === "") {
        newerror.teamUp = "Please choose one of the options * ";
      }
      if (formvalue.teamUp && formvalue.haveProperty === "") {
        newerror.haveProperty = "Please choose property one of the options * ";
      }
      if (formvalue.haveProperty && !formvalue.typeOfProperty) {
        newerror.typeOfProperty = "Please provide property type *";
      }
      if (formvalue.haveProperty && !formvalue.propertyCapacity) {
        newerror.propertyCapacity = "Please provide property capacity *";
      }

      if (formvalue.haveProperty && !formvalue.propertyLocation) {
        newerror.propertyLocation = "Please provide property location *";
      }
      if (formvalue.budgetPerWeek === 0 || !formvalue.budgetPerWeek) {
        newerror.budgetPerWeek = "please provide your budget per week *";
      }
      if (!formvalue.moveInDate) {
        newerror.moveInDate = "please provide move in date *";
      }
      if (!formvalue.description) {
        newerror.description = "please provide description *";
      }
      if (formvalue.features.length === 0) {
        newerror.features = "select your features ";
      }
      if (formvalue.preferedLocations.length === 0) {
        newerror.preferedLocations = "Please provide prefered location *";
      }
      if (!formvalue.lookingFor) {
        newerror.lookingFor = "Please select what are you looking for *";
      }
      if (!formvalue.firstName) {
        newerror.firstName = "Please provide firstName *";
      }

      if (!formvalue.lastName) {
        newerror.lastName = "Please provide lastName *";
      }

      if (!formvalue.email) {
        newerror.email = "Please provide email *";
      }
      if (!formvalue.contact) {
        newerror.contact = "Please provide contact *";
      }
      if (!formvalue.occupation) {
        newerror.occupation = "Please provide occupation *";
      }
      if (!formvalue.gender) {
        newerror.gender = "Please provide gender *";
      }

      if (!formvalue.age) {
        newerror.age = "Please provide age *";
      }

      if (!formvalue.userPic) {
        newerror.userPic = "Please provide Your Image *";
      }

      if (!isphone && formvalue.contact) {
        newerror.contact = "please provide valid candian number *";
      }
      if (!isValidEmail && formvalue.email) {
        newerror.email = "please provide valid email";
      }
      newerror.message = "Invalid credential please check again *";
    } else {
      isValid = true;
    }

    /// on submit

    setErrors(newerror);

    return isValid;
  };

  // on next clicked formvalidation  is checked according to current steps
  const onSubmit = () => {
    const isValid = formvalidation();
    if (isValid) {
      const data = new FormData();
      for (let i = 0; i < formvalue.preferedLocations.length; i++) {
        const { place_name } = formvalue.preferedLocations[i];
        data.append("preferedLocations", place_name);
      }
      for (let i = 0; i < formvalue.features.length; i++) {
        data.append("features", formvalue.features[i]);
      }
      for (let i = 0; i < formvalue.accomondationType.length; i++) {
        data.append("accomondationType", formvalue.accomondationType[i]);
      }
      data.append("firstName", formvalue.firstName);
      data.append("lastName", formvalue.lastName);
      data.append("email", formvalue.email);
      data.append("contact", formvalue.contact);
      data.append("occupation", formvalue.occupation);
      data.append("phone", formvalue.phone);
      data.append("description", formvalue.description);
      data.append("budgetPerWeek", formvalue.budgetPerWeek);
      data.append("lengthOfStay", formvalue.lengthOfStay);
      data.append("moveInDate", formvalue.moveInDate);
      data.append("roomFurnishings", formvalue.roomFurnishings);
      data.append("internet", formvalue.internet);
      data.append("bathRoomType", formvalue.bathRoomType);
      data.append("parking", formvalue.parking);
      data.append("totalFlateMates", formvalue.totalFlateMates);
      data.append("teamUp", formvalue.teamUp);
      data.append("haveProperty", formvalue.haveProperty);
      data.append("typeOfProperty", formvalue.typeOfProperty);
      data.append("propertyCapacity", formvalue.propertyCapacity);
      data.append("propertyLocation", formvalue.propertyLocation.place_name);
      data.append("age", formvalue.age);
      data.append("gender", formvalue.gender);
      data.append("images", formvalue.userPicFile);
      data.append("lookingFor", formvalue.lookingFor);
      
      dispatch(adminCreateFlatemateAction({ data, toast }));
    }
  };

  // on location set
  const handleLocation = (location) => {
    setformvalues((prevValue) => ({
      ...prevValue,
      propertyLocation: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        place_name: location.place_name,
      },
    }));
  };

  const addLocation = React.useCallback((location) => {
    setformvalues((prevFormValues) => ({
      ...prevFormValues,
      preferedLocations: [...prevFormValues.preferedLocations, ...location],
    }));
  }, []);

  return (
    <Container
      sx={{
        paddingTop: "3vh",
        overflow: "hidden",
        overflowY: "scroll",
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="container h-maxcontent position-relative"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Professional Add Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <hr />{" "}
      <LookingFor
        selected={formvalue.lookingFor}
        handleFormValue={handleFormValue}
        errors={errors.lookingFor}
      />
      <FlateMateInfo
        handlePhoneNumber={handlePhoneNumber}
        handleFormChange={handleFormChange}
        errors={errors}
        formvalue={formvalue}
      />
      <PreferedLocation
        preferedLocations={formvalue.preferedLocations}
        errors={errors}
        removelocation={removelocation}
        setformvalues={setformvalues}
        setErrors={setErrors}
      />
      <AboutYourSelf
        errors={errors}
        handleFormValue={handleFormValue}
        values={formvalue}
      />
      <YourBudget
        errors={errors}
        handleFormValue={handleFormValue}
        values={formvalue}
      />
      <PropertyPreferences
        values={formvalue}
        handleFormValue={handleFormValue}
      />
      <AccomendationTypes
        values={formvalue}
        handleFormValue={handleFormValue}
        handleLocation={handleLocation}
        errors={errors}
      />
      <Box
        sx={{
          bottom: 0,
        }}
        className="position-sticky d-flex justify-content-end gap-1 py-2 align-items-center  start-0 w-100 bg-white "
      >
        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <button
          className="btn-dark btn ms-auto w-maxcontent "
          onClick={onSubmit}
        >
          Save
        </button>
      </Box>
    </Container>
  );
};

export default AdminAddFlateMate;
