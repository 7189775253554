import React, { useEffect, useState } from "react";

import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Box,
  Button,
  Container,
  Drawer,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SelectCountry } from "../../../../components/Index";
import {
  adminGetAllStudentRequirementAction,
  adminCreateStudentRequirementAction,
} from "../../../../context/actions/admin/studentAction";
import StudentRequirmentsTable from "./StudentRequirementsTable";
import AddStudentRequirements from "./addStudentRequirements/AddStudentRequirements";

const AdminStudents = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("all");
  const [keyword, setKeyword] = useState("");

  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [country, setCountry] = React.useState({ value: "", error: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // open add drawer
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const buttons = [
    <Button key="one">Added By User</Button>,
    <Button key="two">Added By Admin</Button>,
  ];

  const handleCountry = (event, value) => {
    setCountry({ value: value.label, error: "" });
  };
  useEffect(() => {
    dispatch(
      adminGetAllStudentRequirementAction({
        page: page,
        keyword: keyword,
      })
    );
  }, [dispatch, page, keyword]);

  const {
    student_requiremnet_loading,
    all_student_requirement,
    resultperpage,
    totalstudentrequirementcount,
  } = useSelector((state) => state.admin_reducer);

  const [previewImage, setPreviewImage] = useState({
    preview: "",
    file: "",
  });
  console.log(previewImage.file);
  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    const blogUrl = URL.createObjectURL(file);

    setPreviewImage({
      preview: blogUrl,
      file: file,
    });
  };

  const onSubmit = () => {
    if (!country.value) {
      setCountry({ ...country, error: "Please choose country *" });
    } else {
      const data = new FormData();
      data.append("country", country.value);
      data.append("coverimage", previewImage.file);
      dispatch(adminCreateStudentRequirementAction({ data: data }));
      navigate(`/admin/students/edit/${country.value}`);
    }
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  Student requirement for country
                </span>
                <small>
                  Country based student requirement for study abroad & migration
                </small>
              </div>
              <div>
                <Button
                  sx={{ mr: 1 }}
                  variant={activeButton === "all" ? "contained" : "outlined"}
                  onClick={() => {
                    setActiveButton("all");
                  }}
                >
                  All Requirements
                </Button>

                <Button variant="outlined" onClick={handleOpen}>
                  ADD
                </Button>
              </div>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OutlinedInput
                sx={{}}
                size="small"
                defaultValue=""
                fullWidth
                placeholder="Search country"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(event) => {
                  if (event.target.value === "") {
                    setKeyword("");
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setKeyword(event.target.value);
                  }
                }}
              />
            </Stack>

            {activeButton === "all" ? (
              <StudentRequirmentsTable
                keyword={keyword}
                requirements={all_student_requirement}
                resultperpage={resultperpage}
                totalstudentrequirementcount={totalstudentrequirementcount}
                loading={student_requiremnet_loading}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </Stack>
        </Container>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          <Box
            sx={{
              minHeight: "100vh",
              width: { lg: "70vw", xs: "100%", md: "80vw", sm: "90vw" },
            }}
          >
            <AddStudentRequirements
              onClose={toggleDrawer}
              beforeContry={[]}
              afterCountry={[]}
            />
          </Box>
        </Drawer>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select Country
            </Typography>

            <SelectCountry
              name={"Country"}
              selectedOptions={country.value}
              handleChange={handleCountry}
              label={false}
              error={country.error ? true : false}
            />
            <small className={country.error ? "form-text text-danger" : ""}>
              {country.error}
            </small>

            <p className="mt-2">Profile picture</p>
            <input
              className="form-control"
              type="file"
              onChange={handleImageUpload}
            />

            {previewImage.preview && (
              <img
                src={previewImage.preview}
                alt="Preview"
                className="rounded rounded-3 mt-2 object-cover"
                width={"100%"}
                height={"200px"}
              />
            )}

            <div className="d-flex justify-content-end w-100 position-sticky top-100 start-0 ">
              <button
                className="mt-2 btn-outline-black btn ms-auto justify-self-end "
                onClick={onSubmit}
              >
                Add Country
              </button>
            </div>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default AdminStudents;
