import {
  Box,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Scrollbar } from "../../components/scrollbar";
import { items } from "./config";
import { SideNavItem } from "./side-nav-item";

export const SideNav = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const pathname = location.pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const content = (
    <Scrollbar
      sx={{
        height: "100vh",
        "& .simplebar-content": {
          height: "100vh",
        },
        "& .simplebar-scrollbar:before": {
          background: "neutral.400",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            href="/"
            sx={{
              display: "inline-flex",
            }}
          >
            <img
              src="/assets/icons/onf.png"
              alt=""
              className="w-100 h-100 object-contain"
              srcSet=""
            />
          </Box>
          
        </Box>
        <Divider sx={{ borderColor: "var(--secondary-color)" }} />
        <Box
          component="nav"
          sx={{
            flexGrow: 1,
            px: 2,
            py: 3,
          }}
        >
          <Stack
            component="ul"
            spacing={0.5}
            sx={{
              listStyle: "none",
              p: 0,
              m: 0,
            }}
          >
            {items.map((item) => {
              const active = item.path ? pathname === item.path : false;

              return (
                <SideNavItem
                  active={active}
                  disabled={item.disabled}
                  external={item.external}
                  icon={item.icon}
                  key={item.title}
                  path={item.path}
                  title={item.title}
                />
              );
            })}
          </Stack>
        </Box>
        <Divider sx={{ borderColor: "var(--secondary-color)" }} />
        <Box
          sx={{
            px: 2,
            py: 3,
          }}
        >
          <Typography
            sx={{
              color: "var(--secondary-color)",
            }}
            variant="subtitle2"
          >
            Need more features?
          </Typography>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={false}
        PaperProps={{
          sx: {
            backgroundColor: "var(--sidenav-color)",
            color: "var(--secondary-color)",
            width: 280,
            maxHeight: "100vh",
            overflow: "hidden",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "var(--sidenav-color)",
          color: "common.white",
          width: 280,
          maxHeight: "100vh",
          overflow: "hidden",
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideNav.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
