import React from "react";
import rentalOffers from "../../../../../../utils/rentaloffers";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { Box } from "@mui/material";

const ModalOfferCard = ({ name, handleFormValue, offers }) => {
  const selected = React.useMemo(
    () => offers.some((value) => value === name),
    [name, offers]
  );
  const icon = rentalOffers.find((item) => item.name === name)?.icon;

  const addOffer = () => {
    const newoffers = [...offers];
    if (selected) {
      const index = newoffers.indexOf(name);
      if (index > -1) {
        newoffers.splice(index, 1);
      }
    } else {
      newoffers.push(name);
    }
    handleFormValue(newoffers);
  };

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        addOffer();
      }}
      sx={{
        height: { xm: "100px", lg: "100px" },
        width: "200px",
      }}
      role="button"
      className={
        selected
          ? "d-flex flex-column position-relative  border-dark text-lg-start border border-3 justify-content-center align-items-start text-center p-2"
          : "d-flex flex-column position-relative  text-lg-start border border-3 justify-content-center align-items-start text-center p-2"
      }
    >
      <div className="">{icon}</div>
      <p>{name}</p>

      <CheckCircleIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};

export default ModalOfferCard;