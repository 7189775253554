import ProtectApi from "./adminAuthorizeApi";

// for admin get all users
export const adminGetAllUser = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/user/get_all_user?keyword=${keyword}&page=${page}`
  );

// register user by admin
export const adminRegisterUser = (data) =>
  ProtectApi.post("/api/admin/user/register_user", data);

// change status of user by admin
export const adminChangeUserStatus = (data, id) =>
  ProtectApi.put(`/api/admin/user/change_user_status/${id}`, { active: data });


// edit user by admin
export const adminEditUser = (data, id) =>
  ProtectApi.put(`/api/admin/user/edit_user/${id}`, data);