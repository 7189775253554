import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Chip,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { useNavigate } from 'react-router-dom';

export const  OverviewHelp = (props) => {
  const { help_support_list = [], sx } = props;
  const navigate = useNavigate();

  return (
    <Card sx={sx}>
      <CardHeader className="fw-bold text-danger" title="Help & Support" />
      <Box sx={{ minWidth: 400 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Seveirity</TableCell>
              <TableCell>Status</TableCell>

              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {help_support_list.map((support) => {
              return (
                <TableRow hover key={support?._id}>
                  <TableCell>{support?.topic}</TableCell>
                  <TableCell>
                    <Chip
                      label={support?.severity}
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>{support?.status}</TableCell>

                  <TableCell>
                    <button className="btn btn-sm btn-outline-danger">
                      View
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          color="inherit"
          endIcon={
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          }
          size="small"
          variant="text"
          onClick={() => {
            navigate("/admin/helpandsupport")
          }}
        >
          View all
        </Button>
      </CardActions>
    </Card>
  );
};