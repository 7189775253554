import React from "react";
import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import styled, { css } from "styled-components";

const RentalImageUpload = React.memo(
  ({ handleimages, errors, active, pImages, imageFile }) => {
    const Wrapper = styled.div`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 150px;
      gap: 1%;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;

      & > .post {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:nth-child(1) {
          grid-row: auto / span 2;
          grid-column: auto / span 2;
        }
        ${createCSS()}
      }
    `;

    function createCSS() {
      let styles = "";
      let number = 0;

      for (let i = 1; i < 20; i += 1) {
        if (i % 5 === 1) {
          number += 5;
        } else {
          number = number + 3;
          styles += `
           &:nth-child(${number}) {
             grid-row: auto / span 2;
             grid-column: auto / span 2;

           }
         `;
        }
      }

      return css`
        ${styles}
      `;
    }

    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      const imageFile = e.target.files || e.dataTransfer.files;

      const selectedFilesArray = Array.from(imageFile);
      const imageArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      handleimages({ files: selectedFilesArray, pImages: imageArray });
    }

    function handleDragOver(e) {
      e.preventDefault();
    }

    const onSelectedFile = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      let newfilearray = [...imageFile];
      let imageArray = [...pImages];
     

      for (let i = 0; i < selectedFilesArray.length; i++) {
        imageArray?.push(URL.createObjectURL(selectedFilesArray[i]));
        newfilearray.push(selectedFilesArray[i]);
      }

      //h
      handleimages({ files: newfilearray, pImages: imageArray });
    };

    const removeimage = (index) => {
      let newimagearray = [...pImages];
      let newfilearray = [...imageFile];
      newimagearray = newimagearray
        .slice(0, index)
        .concat(newimagearray.slice(index + 1));

      newfilearray = newfilearray
        .slice(0, index)
        .concat(newfilearray.slice(index + 1));

      handleimages({ files: newfilearray, pImages: newimagearray });
    };
    return (
      <Box
        onDrop={handleDrop}
        className={
          active
            ? errors?.length && (pImages.length < 5 || pImages.length > 10)
              ? " mt-2 d-flex flex-column gap-2 border border-danger"
              : " mt-2  d-flex flex-column gap-2"
            : "d-none"
        }
      >
        <div
          className="d-flex justify-content-between w-100
      align-items-center flex-column flex-md-row"
        >
          <div className="d-flex flex-column gap-0 w-100">
            <p className="text-black fs-3 fw-bold my-0">
              Some clear photos of your property
            </p>
            <p className="w-100">upload minimum of 5 photos of your listings</p>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center h-maxcontent">
            <input
              type="file"
              name=""
              accept="image/*"
              id="photoupload"
              multiple
              onChange={(e) => onSelectedFile(e)}
              className="mb-3 invisible"
              title=" "
            />
            <label
              htmlFor="photoupload"
              className="d-flex flex-column gap-0 btn fw-bold h-100 btn-dark text-capitalize "
            >
              Add Images
            </label>
          </div>
        </div>
        <Box
          sx={{
            border: "3px dashed gray rounded rounded-3",
            maxHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <Wrapper>
            {pImages?.map((item, index) => {
              return (
                <Box
                  className="position-relative  p-1 overflow-hidden  post"
                  key={item}
                  sx={{}}
                >
                  <img
                    src={item}
                    alt=""
                    srcSet=""
                    className="object-cover w-100 h-100 rounded rounded-2"
                  />
                  <CancelIcon
                    className="position-absolute  "
                    sx={{
                      top: "2%",
                      right: "2%",
                      // color black blur
                      color: "rgba(255, 255, 255, 0.875)",
                      hover: {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => removeimage(index)}
                  />
                </Box>
              );
            })}
          </Wrapper>
        </Box>

        {pImages.length === 0 && (
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="w-100 position-relative text-center d-flex justify-content-center rounded rounded-3"
            style={{ height: "450px", border: "2px dashed gray" }}
          >
            <Box
              className="position-absolute text-center "
              sx={{
                // center
                top: "20%",
              }}
            >
              <img src="/assets/icons/upload.png" alt="" />
              <label
                htmlFor="photoupload"
                className="d-flex flex-column gap-0 btn fw-bold h-100 btn btn-outline-black"
              >
                upload images
              </label>
              <p>Drag and drop an image here</p>
            </Box>
          </div>
        )}

        <small
          className={
            errors?.length > 0 && (pImages.length < 5 || pImages.length > 10)
              ? "text-danger"
              : "d-none"
          }
        >
          {errors}
        </small>
      </Box>
    );
  }
);

export default RentalImageUpload;
