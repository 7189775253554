import customInstance from "./manage_access_route";
import API from "./common_api";
// cabda + tronoto
export const create_rental = (data) =>customInstance.post("/api/rental/create_rental", data);
export const get_all_rental = (
  keyword = "",
  page = 0,
  houseType = "",
  minPrice = "",
  maxPrice = "",
  accessTo = "",
  minimumStay = "",
  availableDate = ""
) =>
  API.get(
    `/api/rental/get_all_rentals?keyword=${keyword}&page=${page}&houseType=${houseType}&minPrice=${minPrice}&maxPrice=${maxPrice}&accessTo=${accessTo}&minimumStay=${minimumStay}&availableDate=${availableDate}`
  );

  // get all featured rental
  export const get_all_featured_rental = () =>
    API.get("/api/rental/get_all_featured_rentals");

  export const get_rental_detail = (id) => API.get(`/api/rental/get_rental_details/${id}`);

export const create_flatemate = (data) => customInstance.post("/api/rental/create_flatemate", data);
export const get_all_flatemate = (
  houseType = "",
  minPrice = "",
  maxPrice = "",
  minAge = "",
  maxAge = "",
  lookingFor = "",
  lengthOfStay = "",
  moveInDate = "",
  accomondationType = "",
  preferedLocations = ""
) =>
  API.get(
    `/api/rental/get_all_flatemates?typeOfProperty=${houseType}&minPrice=${minPrice}&maxPrice=${maxPrice}&minAge=${minAge}&maxAge=${maxAge}&lookingFor=${lookingFor}&lengthOfStay=${lengthOfStay}&moveInDate=${moveInDate}&accomondationType=${accomondationType}&preferedLocations=${preferedLocations}`
  );

  export const send_rental_mail_request = (data) =>
    customInstance.post("/api/rental/send_mail_rental_request", data);

  //get_flatmate_details
  export const get_flatmate_details = (id) => API.get(`/api/rental/get_flatmate_details/${id}`);

