import React from "react";
import { Link } from "react-router-dom";

import { Bathroom, KitchenOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { BsStars } from "react-icons/bs";
import { IoHeartCircleOutline } from "react-icons/io5";
import { MdOutlineBed, MdOutlineLocationOn } from "react-icons/md";
import { post_bookmark } from "../../../context/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

const RentalUserCard = ({ item }) => {
  const dispatch = useDispatch();

  const [liked, setliked] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [type, setType] = React.useState("Rental");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    if (item) {
      setBookmark({
        name: item?.title,
        type: type,
        image: item?.images[0],
        category: item?.houseType,
        location: item?.location?.place_name,
      });
    }
  }, [item]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === item?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, item?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: item?._id, data: bookmark }));
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",

        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "1",
          },
        },
      }}
      className="position-relative overflow-hidden p-0 m-0 shadow-3 rounded rounded-4 overflow-hidden"
    >
      <Link
        to={`/rental/${item._id}`}
        className=" position-absolute top-0 start-0  hover-link"
        style={{
          color: "inherit",
        }}
      ></Link>

      <Box
        sx={{
          height: "200px",
        }}
        className="rental-img w-100 position-relative overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${item?.images[0]}`}
          // src="https://images.pexels.com/photos/1612351/pexels-photo-1612351.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt=""
          className="w-100 h-100 object-cover img-fluid"
        />
        <Box
          className="position-absolute px-2 py-1 rounded rounded-1 d-flex align-items-center bg-danger"
          sx={{
            top: "2%",
            right: "1%",
            color: "var(--secondary-color)",
            fontSize: "0.8em",
          }}
        >
          <BsStars sx={{ color: "var(--secondary-color)" }} />
          <span className="text-white">New</span>
        </Box>
      </Box>
      <Box className="rental-info p-1 px-2">
        <div className="rental-info_price d-flex align-items-center justify-content-between">
          <p className="fs-5">
            <strong>$ {item?.price}</strong>
            <small>/month</small>
          </p>
          <Box
            sx={{
              zIndex: "2",
              cursor: "pointer",
            }}
            onClick={togglelike}
          >
            <IoHeartCircleOutline
              style={{
                color: liked ? "#FF0000" : "",
                fontSize: "1.8em",
              }}
            />
          </Box>
        </div>

        <h5 className="fw-bold text-overflow-hidden">{item?.title}</h5>

        <div className="d-flex gap-1 align-items-center">
          <MdOutlineLocationOn style={{ fontSize: "1em" }} />
          <small className="text-overflow-hidden ">
            {item?.location?.place_name}
          </small>
        </div>
      </Box>
      <hr className="mt-0" />
      <div className="d-flex justify-content-between px-2 align-items-center pb-3 ">
        <div className="d-flex gap-1 align-items-center">
          <MdOutlineBed fontSize={"22px"} />
          <span>{item.bedroom}</span>
          <Bathroom className="ms-1" fontSize={"22px"} />
          <span>{item.bathroom}</span>
          <KitchenOutlined fontSize={"22px"} className="ms-1" />
          <span>{item.kitchen}</span>
        </div>
        <button className="btn btn-sm btn-outline-black">Apartment</button>
      </div>
    </Box>
  );
};

export default RentalUserCard;
