import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";


const HouseTypes = ({ item, selected, handleHouseType }) => {
  const { name, icon } = item;
  return (
    <div
      onClick={() => handleHouseType(name)}
      className={
        selected === name
          ? "d-flex p-2 grid-box-half justify-content-center flex-column border border-2 border-dark rounded rounded-3 text-start position-relative"
          : "d-flex p-2 grid-box-half justify-content-center flex-column border border-2 rounded rounded-3 text-start position-relative"
      }
      role="button"
    >
      <div>{icon}</div>
      <p className="fw-bold mt-1">{name}</p>

      <CheckCircleOutlineIcon
        className={selected === name ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </div>
  );
};

export default HouseTypes;
