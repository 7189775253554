import { BookmarkOutlined, LocationOnOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

import { post_bookmark } from "../../../context/actions/authAction";
import { Typography } from "@mui/material";
const BookmarkRentalCard = ({ rental }) => {
  const { name, type, image, location, category } = rental;
  const dispatch = useDispatch();

  const [liked, setliked] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    if (rental) {
      setBookmark({
        name: name,
        type: type,
        image: image,
        category: category,
        location: location,
      });
    }
  }, [category, image, location, name, rental, type]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === rental?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, rental?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: rental?._id, data: bookmark }));
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        aspectRatio: { xs: "1/1.5", sm: "1/1.3" },
        overflow: "hidden",

        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "1",
          },
        },
      }}
      className="d-flex  flex-column justify-content-between   card custom-card"
    >
      <Link
        to={`/rental/${rental.id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>
      <Box
        sx={{
          height: "50%",
        }}
        className="bg-black"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${rental?.image}`}
          className="object-cover object-center w-100 h-100"
          alt=""
        />
      </Box>
      <Box sx={{
          height: "50%",
          mt:1
          
        }} >
        
        <div className="px-1 px-lg-2 py-1 py-lg-2  ">
        <span>4500/month</span>
        <small className="d-flex">
          <Typography
            variant="span"
            className="text-black text-overflow-hidden cTitle"
          >
            {rental?.name}
          </Typography>
        </small>
        <small className="mb-2 text-black  d-flex align-items-center">
          <LocationOnOutlined fontSize="small" />{" "}
          <span className="text-overflow-hidden">{rental?.location}</span>
        </small>
        <div className="d-flex justify-content-between mt-3">
          <Typography
            variant="span"
            className="btn  btn-outline-black bcategory-box"
          >
            {category}
          </Typography>
          <Box
            sx={{
              zIndex: "4",
            }}
            className="text-black"
          >
            {liked ? (
              <BookmarkBorderIcon role="button" onClick={togglelike} />
            ) : (
              <BookmarkOutlined role="button" onClick={togglelike} />
            )}
          </Box>
        </div>
      </div>
      </Box>
     
        
     
    </Box>
  );
};

export default BookmarkRentalCard;
