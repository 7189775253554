import React, { memo } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtectedRoute = memo(() => {
  const { isAdmin, admin_data } = useSelector((state) => state.auth_slice);
  return isAdmin && admin_data && admin_data?.role ==="Admin" ? <Outlet /> : <Navigate to="/admin/login" replace />;
}); 

export default AdminProtectedRoute;
