import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Container } from "@mui/material";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { BiPencil } from "react-icons/bi";
import { CgMenuGridR } from "react-icons/cg";
import { GoLocation } from "react-icons/go";
import { ImCross } from "react-icons/im";
import styled from "styled-components";
import { RentalInformation, RentalOfferings } from "./tabs";
import { Drawer } from "@mui/material";
import RentalEdit from "../RentalEdit/RentalEdit";
import { pictureModal } from "../../utils/modalSettings";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 200px;
  gap: 5px;
  border-radius: 25px;
  & > .item:nth-child(1) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
`;

const UserRentalDetail = ({ data, closeModal, activeindex, id }) => {
  const tabs = ["Information", "Offerings"];

  // show more description
  const [open, setOpen] = React.useState(false);

  // tab change
  const [value, setValue] = React.useState("0");

  const [slider, setslider] = React.useState("0");
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

  // on tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // on tab change
  const handleModalSlider = (event, newValue) => {
    setslider(newValue);
  };

 

  return (
    <Container
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="py-3 d-flex flex-column gap-2"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Preview for your listing
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
              role="button"
              onClick={closeModal}
            />
          </Box>
        </div>
        <div className="d-flex w-100 justify-content-between mt-1">
          <div className="d-flex flex-column">
            <span className="fs-2 text-black fw-bold m-0 p-0">
              {data.title}
            </span>
            <p className="badge badge-light">
              <GoLocation /> {data?.location?.place_name}
            </p>
          </div>

          <div className="rental-actionbtn d-flex gap-2 align items-center h-50 mt-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-dark"
              data-mdb-ripple-color="dark"
              onClick={toggleDrawer}
            >
              <BiPencil /> Edit
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-mdb-ripple-color="dark"
            >
              Delist property
            </button>
          </div>
        </div>
      </section>

      <Wrapper className="grid-images position-relative rounded rounded-3 overflow-hidden">
        {data.images.slice(0, 5).map((item, index) => {
          return (
            <div className="w-100 h-100 item" key={index}>
              <img
                // src="https://images.pexels.com/photos/463734/pexels-photo-463734.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                // src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                alt=""
                className="w-100 object-cover h-100"
              />
            </div>
          );
        })}

        {/* open modal button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "1%",
          }}
          className="btn d-flex gap-2 align-items-center bg-white"
          onClick={handleOpen}
        >
          <CgMenuGridR className="position bottom-0" />
          <span>Show all photos</span>
          {data?.images?.length}
        </Box>
      </Wrapper>
      <section className=" mt-2">
        <Tabs
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            background: "#F6F6F6",
          }}
          value={value}
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                label={item}
                value={index.toString()}
                key={index}
                className="text-capitalize  "
                sx={{
                  "&.Mui-selected": {
                    color: "#615a5a",
                    fontWeight: "bolder",
                    border: "",
                  },

                  "&.MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              />
            );
          })}
        </Tabs>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value.toString()}>
            <TabPanel value="0">
              <RentalInformation data={data} />
            </TabPanel>
            <TabPanel value="1">
              <RentalOfferings data={data} />
            </TabPanel>
          </TabContext>
        </Box>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={pictureModal} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            onClick={handleClose}
          >
            <ImCross
              className=" bg-hover rounded rounded-circle fs-5"
              role="button"
            />
          </Box>
          <TabContext value={slider}>
            {data.images.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      className=" object-contain"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <Tabs
              onChange={handleModalSlider}
              className=" d-flex  justify-content-center mx-auto  "
              sx={{
                justifyContent: "center",
                width: "max-content",
              }}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={slider}
            >
              {data.images.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0"
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center "
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          widht={90}
                          className="object-cover w-100 mx-auto p-0 "
                        />
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          </TabContext>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <RentalEdit
          data={data}
          closeModal={toggleDrawer}
          activeindex={activeindex}
          id={id}
        />
      </Drawer>
    </Container>
  );
};

export default UserRentalDetail;
