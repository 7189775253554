import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeaturedBusinessAction
} from "../../context/actions/businessAction";
import { getFeaturedOrganizationAction, getorganizationAction } from "../../context/actions/organizationAction";

import { getAllHomeAdsAction } from "../../context/actions/getAllAdsAction";


import {
  getAllFeaturedRentalAction
} from "../../context/actions/rentalAction";

import NewsCard from "./components/NewsCard";
// organizations icons
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { OrganizationCard, RentalUserCard } from "../../components/Index";

import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getALLBlogsAction } from "../../context/actions/blogAction";
import { getNotifications } from "../../context/actions/notificationAction";
import OrgCardSkeleton from "./components/OrgCardSkeleton";

// HiOutlineBuildingOffice2

const Homepage = () => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const [category, setcategory] = React.useState("");
  const [page, setPage] = React.useState(1);



  // call the function to get all organization
  useEffect(() => {
    Promise.all([
      dispatch(getFeaturedBusinessAction()),
      dispatch(getFeaturedOrganizationAction()),
      dispatch(getAllFeaturedRentalAction()),
      dispatch(getorganizationAction({})).then(() => {
        setLoaded(true);
      }),
      dispatch(getALLBlogsAction({ category, page }))
    ])
  }, []);

  useEffect(() => {
    dispatch(getAllHomeAdsAction());
  }, [dispatch]);



  const {
    featured_organization,
    featured_rental,
    loading,
    home_banner_ads,
    blogs,
  } = useSelector((state) => ({
    featured_organization: state.organization_reducer.featured_organization,
    featured_business: state.business_reducer.featured_business,
    featured_rental: state.rental_reducer.featured_property,
    loading: state.organization_reducer.featuredLoading,
    home_banner_ads: state.ads_reducer.home_banner_ads,
    blogs: state.blog_reducer.blogs,
  }));


  const [showMore, setshowMore] = React.useState(false);

  return (
    <>
      <div className="container d-flex flex-column gap-3">
        <Box

          id="HomepageCarousel"
          className="carousel slide"
          data-mdb-ride="carousel"
        >
          <Box className="carousel-inner rounded-4 mt-4 h-100 ">
            <div className="carousel">
              {home_banner_ads?.map((image, index) => (
                <div
                  key={index}
                  className={`carousel-item h-100 ${index === 0 ? "active" : ""
                    }`}
                >
                  <img
                    src="/assets/images/banner.jpeg"
                    // src={`${process.env.REACT_APP_IMAGE_PATH}${image?.file}`}
                    className="d-block w-100 object-cover"
                    alt={image.alt}
                    height="200"
                  />

                </div>
              ))}
            </div>
          </Box>

          <button
            className="carousel-control-prev"
            type="button"
            data-mdb-target="#HomepageCarousel"
            data-mdb-slide="prev"
          >
            <ArrowBack />
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-mdb-target="#HomepageCarousel"
            data-mdb-slide="next"
          >
            <ArrowForward />
          </button>
        </Box>

        <div className="row flex-wrap-reverse">
          <div className="col-md-8 col-lg-8 col-xl-8 col-12">
            <div className="">
              <Typography
                variant="h5"
                component="h5"
                gutterBottom
                sx={{ pt: 3 }}
              >
                Immigration News and Updates with ONF Canada
              </Typography>

              <div className=" row ">
                {blogs.slice(0, 3).map((item) => {
                  return (


                    <div
                      className=" new-item  col-md-4 col-sm-6 col-lg-6 col-xl-4 col-6"
                      key={item.id}
                    >
                      <Link to={`/blogs/${item._id}`}>
                        <NewsCard item={item} />
                      </Link>

                    </div>
                  );
                })}
              </div>
              <Link
                to={"/blogs"}
                type="button"
                className="btn btn-outline-secondary  w-100 "
                data-mdb-ripple-color="dark"
              >
                View all news and updates
              </Link>
            </div>
          </div>

          <div className="col-md-4 col-lg-4 col-xl-4 col-12 pt-3 gap-2">
            <Link
              to={"/organizations"}
              class="card shadow-3 text-white hover-zoom mb-2"
            >
              <div class="card-body d-flex justify-content-between">
                <div className="m-0 p-0">
                  <div className="d-flex gap-2">
                    <img
                      src="/assets/images/org_home.png"
                      alt="logo"
                      className="object-cover mt-1"
                      width={"12%"}
                      height={"12%"}
                    />
                    <div>
                      <h5 class="card-title m-0 p-0 text-black">
                        Organizations
                      </h5>
                      <small class="card-text m-0 p-0 text-black">
                        Find organizations that can help you
                      </small>
                    </div>
                  </div>
                </div>
                <BsFillArrowUpRightCircleFill color="black" />
              </div>
            </Link>
            <Link
              to={"/business"}
              class="card shadow-3 text-white hover-zoom mb-2"
            >
              <div class="card-body d-flex justify-content-between">
                <div className="m-0 p-0">
                  <div className="d-flex gap-2">
                    <img
                      src="/assets/images/business_home.png"
                      alt="logo"
                      className="object-cover mt-1"
                      width={"12%"}
                      height={"12%"}
                    />
                    <div>
                      <h5 class="card-title m-0 p-0 text-black">
                        Business & Services
                      </h5>
                      <small class="card-text m-0 p-0 text-black">
                        Find businesses and services around
                      </small>
                    </div>
                  </div>
                </div>
                <BsFillArrowUpRightCircleFill color="black" />
              </div>
            </Link>
            <Link
              to={"/rental"}
              class="card shadow-3 text-white hover-zoom mb-2"
            >
              <div class="card-body d-flex justify-content-between">
                <div className="m-0 p-0">
                  <div className="d-flex gap-2">
                    <img
                      src="/assets/images/rental_home.png"
                      alt="logo"
                      className="object-cover mt-1"
                      width={"12%"}
                      height={"12%"}
                    />
                    <div>
                      <h5 class="card-title m-0 p-0 text-black">
                        Rental & Flatmates
                      </h5>
                      <small class="card-text m-0 p-0 text-black">
                        Find rental and flatmates around
                      </small>
                    </div>
                  </div>
                </div>
                <BsFillArrowUpRightCircleFill color="black" />
              </div>
            </Link>

            {showMore ? (
              <div>
                <Link
                  to={"/rental"}
                  class="card shadow-3 text-white hover-zoom mb-2"
                >
                  <div class="card-body d-flex justify-content-between">
                    <div className="m-0 p-0">
                      <div className="d-flex gap-2">
                        <img
                          src="/assets/images/blog_home.png"
                          alt="logo"
                          className="object-cover mt-1"
                          width={"12%"}
                          height={"12%"}
                        />
                        <div>
                          <h5 class="card-title m-0 p-0 text-black">
                            News and Blogs
                          </h5>
                          <small class="card-text m-0 p-0 text-black">
                            Stay updated
                          </small>
                        </div>
                      </div>
                    </div>
                    <BsFillArrowUpRightCircleFill color="black" />
                  </div>
                </Link>
                <Link
                  to={"/rental"}
                  class="card shadow-3 text-white hover-zoom mb-2"
                >
                  <div class="card-body d-flex justify-content-between">
                    <div className="m-0 p-0">
                      <div className="d-flex gap-2">
                        <img
                          src="/assets/images/event_home.png"
                          alt="logo"
                          className="object-cover mt-1"
                          width={"12%"}
                          height={"12%"}
                        />
                        <div>
                          <h5 class="card-title m-0 p-0 text-black">
                            Events & Gallary
                          </h5>
                          <small class="card-text m-0 p-0 text-black">
                            Events & Gallary of ONF Canada
                          </small>
                        </div>
                      </div>
                    </div>
                    <BsFillArrowUpRightCircleFill color="black" />
                  </div>
                </Link>
              </div>
            ) : (
              <></>
            )}

            <button
              onClick={() => {
                setshowMore(!showMore);
              }}
              className="btn btn-sm btn-outline-secondary mt-1 w-100"
            >
              Show more
            </button>
          </div>
        </div>

        <section>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">
                <span className="text-danger">Featured</span> Organizations
              </span>
              <small>
                Showing {featured_organization.length} featured organizations on
                ONF Canada
              </small>
            </div>
            <div>
              <div
                class="btn-group shadow-0"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#carouselExampleControls"
                  data-mdb-slide="prev"
                  data-mdb-color="dark"
                >
                  Prev
                </button>
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#carouselExampleControls"
                  data-mdb-slide="next"
                  data-mdb-color="dark"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-mdb-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  {loading ? (
                    <>
                      {Array.from({ length: 4 }, (_, index) => (
                        <OrgCardSkeleton key={index} />
                      ))}
                    </>
                  ) : (
                    featured_organization?.slice(0, 4)?.map((organization) => (
                      <div class="col-md-3">
                        <OrganizationCard
                          item={organization}
                          key={organization.id}
                        />
                      </div>
                    ))
                  )}
                  ,
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  {featured_organization?.slice(4, 8)?.map((organization) => (
                    <div class="col-md-3">
                      <OrganizationCard
                        item={organization}
                        key={organization.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">
                <span className="text-danger">Featured</span> Business &
                Services
              </span>
              <small>
                Showing {featured_organization.length} featured organizations on
                ONF Canada
              </small>
            </div>
            <div>
              <div
                class="btn-group shadow-0"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#carouselBusiness"
                  data-mdb-slide="prev"
                  data-mdb-color="dark"
                >
                  Prev
                </button>
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#carouselBusiness"
                  data-mdb-slide="next"
                  data-mdb-color="dark"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            id="carouselBusiness"
            class="carousel slide"
            data-mdb-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  {loading ? (
                    <>
                      {Array.from({ length: 4 }, (_, index) => (
                        <OrgCardSkeleton key={index} />
                      ))}
                    </>
                  ) : (
                    featured_organization?.slice(0, 4)?.map((organization) => (
                      <div class="col-md-3">
                        <OrganizationCard
                          item={organization}
                          key={organization.id}
                        />
                      </div>
                    ))
                  )}
                  ,
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  {featured_organization?.slice(4, 8)?.map((organization) => (
                    <div class="col-md-3">
                      <OrganizationCard
                        item={organization}
                        key={organization.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr />
        <section>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">
                <span className="text-danger">Featured</span> Rental Property
              </span>
              <small>
                Showing {featured_rental.length} featured rental on ONF Canada
              </small>
            </div>
            <div>
              <div
                class="btn-group shadow-0"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#rentalCarousel"
                  data-mdb-slide="prev"
                  data-mdb-color="dark"
                >
                  Prev
                </button>
                <button
                  type="button"
                  class="btn btn-outline-black"
                  data-mdb-target="#rentalCarousel"
                  data-mdb-slide="next"
                  data-mdb-color="dark"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            id="rentalCarousel"
            class="carousel slide"
            data-mdb-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  {loading ? (
                    <>
                      {Array.from({ length: 4 }, (_, index) => (
                        <OrgCardSkeleton key={index} />
                      ))}
                    </>
                  ) : (
                    featured_rental?.slice(0, 4)?.map((organization) => (
                      <div class="col-md-3">
                        <RentalUserCard
                          item={organization}
                          key={organization.id}
                        />
                      </div>
                    ))
                  )}
                  ,
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                  {featured_rental?.slice(4, 8)?.map((organization) => (
                    <div class="col-md-3">
                      <RentalUserCard
                        item={organization}
                        key={organization.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Homepage;