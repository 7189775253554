import React from "react";
import { Box, Typography } from "@mui/material";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
const FlateMateReview = ({ values }) => {
  return (
    <div className="mt-3">
       <div>
        <h4 className="text-black fw-bold p-0 m-0">
          Review your flatemate profile
        </h4>
        <small>
          Review your flatemate profile before publishing it. You can edit it later
        </small>
      </div>
      <div className="row-cols-1 row-cols-lg-2 row mt-2">
        <Box
          sx={{
            height: "340px",
          }}
          className="rounded rounded-3 overflow-hidden col-lg-5"
        >
          <img
            src={values.userPic}
            alt=""
            srcSet=""
            className="rounded rounded-3 w-100 h-100 object-center object-cover"
            style={{ objectPosition: "center" }}
          />
        </Box>
        <div className="d-flex flex-column">
          <span className="fs-2 text-black fw-bold">{values.firstName} {values.lastName}</span>

          <div className="d-flex gap-2 align-items-center">
            <NotListedLocationOutlinedIcon />
            <Typography variant="span" >
              {
                values.preferedLocations.map((location, index) => {
                  return <span key={index}>{location.place_name}, </span>
                })
              }
      
            </Typography>
          </div>
          <Typography variant="span" className="fs-4">
            {values.budgetPerWeek}$ / Month
          </Typography>
          <p>{values.description}</p>
        </div>
      </div>
    </div>
  );
};

export default FlateMateReview;
