import React from "react";
import { Box, Modal } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FormInputB from "../../../components/Input/FormInputB";

const BusinessOwners = React.memo(
  ({ value, prevstep, onownerchange, handleSubmit, ownerslength, errors }) => {
    // modal style
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: { xs: "95vw", sm: 400 },
      bgcolor: "background.paper",
      borderRadius: "10px",
      boxShadow: 24,
      p: 4,
    };

    // member state when edit / add
    const member = {
      isedit: false,
      OwnerName: "",
      OwnerPhone: "",
    };

    // stores Add/Edit Modal  state
    const [open, setOpen] = React.useState(false);

    // stores forminput state of Modal
    const [newmember, setnewmember] = React.useState(member);

    // stores index of member to edit
    const [editindex, setindex] = React.useState(0);

    // open modal
    const handleOpen = () => {
      setOpen(true);
    };

    // close modal
    const handleClose = () => {
      setnewmember(member);

      setOpen(false);
    };

    // handle input change of edit/add modal
    const handlemember = (e) => {
      setnewmember({ ...newmember, [e.target.name]: e.target.value });
    };

    // handles add or edit members
    const addmember = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const isedit = newmember.isedit;

      const newlist = structuredClone(value);

      const newboardmember = newmember;

      // delete key from object
      delete newboardmember["isedit"];

      switch (isedit) {
        case false:
          if (newmember.OwnerName && newmember.OwnerPhone) {
            newlist.push(newboardmember);
            onownerchange(newlist);

            handleClose();
          }
          break;

        case true:
          if (newmember.OwnerName && newmember.OwnerPhone) {
            newlist[editindex] = newboardmember;

            handleClose();
            onownerchange(newlist);
          }
          break;

        default:
      }
    };

    //  remove member
    const deletemember = (e, index) => {
      const newlist = value;
      newlist.splice(index, 1);
      onownerchange(newlist);
    };

    // open edit modal
    const handleEditOpen = (index, item) => {
      setnewmember({
        isedit: true,
        OwnerName: item.OwnerName,
        OwnerPhone: item.OwnerPhone,
      });

      setindex(index);
      handleOpen();
    };

    return (
      <section className="">
        <div className="d-flex  mt-3 justify-content-between  ">
          <span className="fs-2 fw-bolder ms-auto ">
            Board Members &nbsp; ({ownerslength})
          </span>
          <button className="btn btn-danger ms-auto " onClick={handleOpen}>
            Add
          </button>
        </div>
        {errors && <small className="text-danger">{errors}</small>}

        <Box
          sx={{
            minHeight: "20vh",
          }}
          className="row row-cols-lg-4 row-cols-1 row-cols-sm-2 row-cols-md-3  g-3"
        >
          {value.map((item, index) => {
            return (
              <div className="p-2" key={index}>
                <Box
                  key={index}
                  className="d-flex align-items-center gap-3 bg-hover col p-3 justify-content-between"
                >
                  <div className="">
                    <p className="fw-blod fs-4">{item.OwnerName}</p>
                    <small>{item.OwnerPhone}</small>
                  </div>
                  <div className="dropdown">
                    <MoreHorizIcon
                      className="dropdown-toggle"
                      role="button"
                      id={`dropdownMenuLink${index}`}
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <ul
                      className="dropdown-menu"
                      aria-labelledby={`dropdownMenuLink${index}`}
                    >
                      <li
                        className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                        onClick={() => handleEditOpen(index, item)}
                      >
                        <EditIcon className=" " />
                        <span className="text-center col">Edit</span>
                      </li>

                      <li
                        className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                        onClick={(e) => deletemember(e, index)}
                      >
                        <DeleteIcon className=" " />
                        <span className="text-center col">Delete</span>
                      </li>
                    </ul>
                  </div>
                </Box>
              </div>
            );
          })}
        </Box>

        {/* Add and Edit Modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form
              action=""
              className="d-flex  align-items-center flex-column w-100  p-0"
              onSubmit={addmember}
            >
              <div className="w-100 d-flex justify-content-center">
                <h2 className="  ">
                  {newmember.isedit ? "Edit" : "Add"} Owners
                </h2>
              </div>
              <div className="d-flex bg-hover w-100 border justify-content-start border-0  ">
                <div className="w-100">
                  <FormInputB
                    name={"OwnerName"}
                    placeholder={"Enter  name"}
                    required={true}
                    label={"Name"}
                    handleChange={handlemember}
                    value={newmember.OwnerName}
                  />
                  <FormInputB
                    name={"OwnerPhone"}
                    placeholder={"Enter  Phone number"}
                    required={true}
                    label={"PhoneNumber"}
                    handleChange={handlemember}
                    value={newmember.OwnerPhone}
                    type={"number"}
                  />
                </div>
              </div>
              <button className="btn btn-dark mt-3" type="submit">
                submit
              </button>
            </form>
          </Box>
        </Modal>

        <div className=" d-flex justify-content-end gap-2  mt-3">
          <button className="bg-dark btn text-white   " onClick={prevstep}>
            Back
          </button>
          <button className="btn-dark btn  " onClick={handleSubmit}>
            Next
          </button>
        </div>
      </section>
    );
  }
);

export default BusinessOwners;
