import React from "react";
import { Box, Typography, Input } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const PropertyPrice = ({ handleFormValue, errors, price }) => {
  return (
    <div className="mt-3">
      <p className="text-black fs-3 fw-bold my-0">Set your price</p>
      <Box
        className="rounded rounded-3 bg-hover d-flex align-items-center flex-column justify-content-center "
        sx={{
          width: { sm: "90%", lg: "60%", xs: "97%" },
          height: "50vh",
          paddingInline: { lg: "8%", sm: "4%", xs: "2%" },
        }}
      >
        <div className="d-flex gap-4">
          <RemoveIcon
            className="bg-white text-black rounded rounded-circle p-3"
            sx={{ width: "100px", height: "100px" }}
            role="button"
            onClick={(e) => handleFormValue("minusprice", price)}
          />

          <div className="d-flex align-items-center  bg-white p-2">
            <Typography
              variant="span"
              sx={{
                fontSize: "3em",
                fontWeight: "bold",
              }}
            >
              $
            </Typography>
            <Input
              type="number"
              className="w-100 h-100 bg-white border-0 fw-bold"
              disableUnderline
              min={0}
              onChange={(e) => handleFormValue("price", e.target.value)}
              value={price}
              sx={{
                fontSize: "3em",
                "&:focus": {
                  border: "none",
                  outline: "none",
                },
              }}
            />
          </div>

          <AddIcon
            role="button"
            className="bg-white text-black rounded rounded-circle p-3"
            sx={{ width: "100px", height: "100px" }}
            onClick={(e) => handleFormValue("addprice", price)}
          />
        </div>
        <Typography
          variant="p"
          className="fs-5 mt-3 text-black-50 text-center fw-bold"
          sx={{ width: { lg: "65%" } }}
        >
          Adjust your Price based on your location
        </Typography>
      </Box>
      <small className={errors?.price ? "form-text text-danger" : "d-none"}>
        {errors?.price}
      </small>
    </div>
  );
};

export default PropertyPrice;
