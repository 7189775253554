import React from "react";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";

import Box from "@mui/material/Box";
const SearchbarB = ({ handleKeyPress, handleChange,placeholder }) => {
  return (
    <Box
      className="w-100 bg-hover"
      sx={{
        height: "40px",
      }}
    >
      <OutlinedInput
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        className="h-100"
        defaultValue=""
        fullWidth
        placeholder={placeholder}
        sx={{
          border: "none",
        }}
        startAdornment={
          <InputAdornment position="start">
            <SvgIcon color="action" fontSize="small">
              <MagnifyingGlassIcon />
            </SvgIcon>
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchbarB;
