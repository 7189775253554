import ProtectApi from "./adminAuthorizeApi";

// for admin get all organizations
export const adminCreateOrganization = (data) =>
  ProtectApi.post("/api/admin/organization/create_admin_organization", data);

  // admin_update_organization
export const adminUpdateOrganization = (id, data) =>
  ProtectApi.put(`/api/admin/organization/update_organization/${id}`, data);

export const adminGetAllOrganization = (keyword = "", page = 0, status) =>
  ProtectApi.get(
    `/api/admin/organization/get_all_organization?keyword=${keyword}&page=${page}&status=${status}`
  );

// change status of organization for admin to control organization
export const adminChangeOrganizationStatus = (id, data) =>
  ProtectApi.put(`/api/admin/organization/change_organization_status/${id}`, {
    active: data,
  });

// Update   featured_organization

export const adminUpdateFeaturedOrganization = (id, data) =>
  ProtectApi.put(`/api/admin/organization/featured_organization/${id}`, {
    featured: data,
  });








//verify_organization
export const adminVerifyOrganization = (id, data) =>
  ProtectApi.put(`/api/admin/organization/verify_organization/${id}`, {
    status: data.status,
    active: data.active,
  });

//give_review_message_organization
export const adminGiveReviewToOrganization = (id, data) =>
  ProtectApi.put(
    `/api/admin/organization/give_review_message_organization/${id}`,
    {
      reviewMessage: data,
    }
  );
