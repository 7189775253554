import React from "react";
import {
  FormInputB,
  FormSelectB,
  PhoneNumberInput,
} from "../../../components/Index";
import { Box } from "@mui/material";
import SelectProvince from "../../../components/Input/SelectProvince";
import SelectCity from "../../../components/Input/SelectCity";

const ProfessionalInfo = ({
  errors,
  formvalue,
  handleFormChange,
  onprovinceselect,
  oncityselect,
}) => {
  const genderTypes = ["Male", "Female", "Others"];
  const Ages = Array.from(Array(60 - 18 + 1).keys(), (num) => num + 18);
  const occupations = [
    "Accountant",
    "Actor",
    "Architect",
    "Astronomer",
    "Biologist",
    "Bartender",
    "Bus Driver",
    "Businessperson",
    "Butcher",
    "Carpenter",
    "Cashier",
    "Chef",
    "Dentist",
    "Dietitian",
    "Driver",
    "Doctor",
    "Diplomat",
    "Economist",
    "Electrician",
    "Engineer",
    "Farmer",
    "Fashion Designer",
    "Financial Analyst",
    "Fitness Instructor",
    "Firefighter",
    "Flight Attendant",
    "Graphic Designer",
    "Hairdresser",
    "Historian",
    "Interior Designer",
    "Interpreter",
    "Journalist",
    "Judge",
    "Lawyer",
    "Librarian",
    "Mechanic",
    "Model",
    "Meteorologist",
    "Musician",
    "Nurse",
    "Optometrist",
    "Painter",
    "Pharmacist",
    "Photographer",
    "Pilot",
    "Police Officer",
    "Professor",
    "Psychologist",
    "Physician",
    "Physicist",
    "Plumber",
    "Politician",
    "Programmer",
    "Receptionist",
    "Real Estate Agent",
    "Research Scientist",
    "Sales Representative",
    "Social Worker",
    "Software Developer",
    "Surgeon",
    "Surveyor",
    "Teacher",
    "Translator",
    "Travel Agent",
    "Veterinarian",
    "Web Developer",
    "Writer",
    "Waiter/Waitress",
    "Zoologist",
    "Others",
  ];
  return (
    <div className="mt-3">
      {/* Heading */}
      <div>
        <h5 className="text-black fw-bold p-0 m-0">General Information</h5>
        <small>
          This information will be displayed publicly on your listing
        </small>
      </div>

      <div className="d-flex flex-column flex-lg-row-reverse  gap-1 w-100 justify-content-between">
        <div className="col col-lg-4">
          <Box
            className="position-relative "
            sx={{
              aspectRatio: { xs: "1/1" },
              width: { xs: "50%", md: "100%" },
              border:
                errors.userPic.length > 0
                  ? "2.5px solid var(--invalid-border)"
                  : "",
              marginInline: { xs: "auto", md: 0 },
            }}
          >
            <input
              type="file"
              name="userPic"
              id="userPic"
              accept="images/*"
              className="d-none"
              onChange={handleFormChange}
            />
            <img
              src={
                formvalue?.userPic?.length > 0
                  ? formvalue?.userPic
                  : "/assets/images/userPic.png"
              }
              alt=""
              srcSet=""
              className="object-cover w-100 h-100 rounded rounded-3"
            />
            <label
              htmlFor="userPic"
              className="position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center"
            >
              upload profile
            </label>
          </Box>

          <small className={errors.userPic ? "form-text text-danger" : ""}>
            {errors.userPic}
          </small>
        </div>
        <Box
          sx={{
            width: { xm: "95%", lg: "55%" },
          }}
          className="row-cols-lg-2 row-cols-1 row h-maxcontent"
        >
          <div className="">
            <FormInputB
              name="firstName"
              type="text"
              label="First name"
              placeholder="Enter Your First Name"
              value={formvalue.firstName}
              handleChange={handleFormChange}
              error={errors.firstName ? true : false}
              helpertext={errors.firstName}
            />
          </div>
          <div className="">
            <FormInputB
              name="lastName"
              label="Last name"
              type="text"
              placeholder="Enter Your Last Name"
              value={formvalue.lastName}
              handleChange={handleFormChange}
              error={errors.lastName ? true : false}
              helpertext={errors.lastName}
            />
          </div>
          <Box
            className=""
            sx={{
              marginTop: "10px",
            }}
          >
            <FormSelectB
              name="age"
              label={"Age"}
              list={Ages}
              placeholder="Select Your Age"
              value={formvalue.age}
              handleChange={handleFormChange}
              error={errors.age ? true : false}
              helpertext={errors.age}
            />
          </Box>
          <Box
            className=""
            sx={{
              marginTop: "10px",
            }}
          >
            <FormSelectB
              name="gender"
              label={"Gender"}
              list={genderTypes}
              placeholder="Select Your Gender"
              value={formvalue.gender}
              handleChange={handleFormChange}
              error={errors.gender ? true : false}
              helpertext={errors.gender}
            />
          </Box>
          <div className="">
            <SelectProvince
              onprovinceselect={onprovinceselect}
              name="proveniece"
              handleChange={handleFormChange}
              placeholder={"Select Province"}
              error={errors.proveniece ? true : false}
              helpertext={errors.proveniece}
              value={formvalue.proveniece}
            />
          </div>
          <div className="">
            <SelectCity
              province={formvalue.proveniece}
              city={formvalue.city}
              handleChange={oncityselect}
              error={errors.city ? true : false}
              helpertext={errors.city}
            />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default ProfessionalInfo;
