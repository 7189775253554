import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  BusinessBookmarks,
  RentalBookmarks,
  OrganizationBookmarks,
  ProfessionalBookmarks,
  BlogBookmarks,
} from "./tabs";
import Tab from "@mui/material/Tab";
import { Box, Typography } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import allorg from "../../utils/all_org";
import allbusiness from "../../utils/all_business";
import allblog from "../../utils/all_blog";
import { useSelector } from "react-redux";
import FlatmateBookmarks from "./tabs/FlatmateBookmarks";

const Bookmarks = () => {
  const { bookmarks } = useSelector((state) => ({
    ...state.auth_slice.user_data,
  }));

  // filter the bookmarks with category
  const blog_bookmarks = bookmarks.filter((item) => item.type === "Blog");
  const org_bookmarks = bookmarks.filter(
    (item) => item.type === "Organization"
  );
  const business_bookmarks = bookmarks.filter(
    (item) => item.type === "Business"
  );
  const rental_bookmarks = bookmarks.filter((item) => item.type === "Rental");
  const flatmate_bookmarks = bookmarks.filter(
    (item) => item.type === "Flatemate"
  );
  const professional_bookmarks = bookmarks.filter(
    (item) => item.type === "Professional"
  );

  const allposts = {
    org: allorg,
    blogs: allblog,
    professionals: [],
    business: allbusiness,
    rental: [],
  };
  const [activestepper, setActiveStepper] = React.useState("1");
  const [posts, setposts] = React.useState(allposts);
  const handleChange = (event, newValue) => {
    setActiveStepper(newValue);
  };

  // const removeblog = (itemid) => {
  //   const newblogs = [...posts.blogs].filter((item) => item._id !== itemid);
  //   setposts({ ...posts, blogs: newblogs });
  // };

  const removeorg = (itemid) => {
    const neworg = [...posts.org].filter((item) => item._id !== itemid);
    setposts({ ...posts, org: neworg });
  };

  const removebusiness = (itemid) => {
    const newbusiness = [...posts.business].filter(
      (item) => item._id !== itemid
    );
    setposts({ ...posts, business: newbusiness });
  };
  return (
    <div>
      <Box
        sx={{
          height: "25vh",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        className="position-relative container overflow-hidden"
      >
        <img
          src="/assets/images/bookmarkcoverimg0.png"
          alt=""
          srcSet=""
          className="h-100 w-100 object-cover position-absolute top-0 start-0"
        />
        <img
          src="/assets/images/bookmarkcoverimg.png"
          alt=""
          srcSet=""
          className="h-100 w-100 object-cover position-absolute top-0 start-0"
        />
        <div
          className="w-100 d-flex justify-content-center h-100  align-items-center
        "
        >
          <Typography
            variant="span"
            color="initial"
            className="fs-1 position text-white text-center fw-bolder d-flex align-items-center mx-auto"
            sx={{
              color: "white",
              zIndex: "2",
            }}
          >
            Your Bookmarks <FavoriteIcon className="ms-2 fs-1" />
          </Typography>
        </div>
      </Box>
      <section className="mt-3">
        <Box
          sx={{ maxWidth: { xs: 420, sm: 880 }, bgcolor: "background.paper" }}
          className="mx-auto"
        >
          <Tabs
            value={activestepper.toString()}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={`Organization ( ${org_bookmarks.length} )`}
              value={"0"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />

            <Tab
              label={`Business (${business_bookmarks.length})`}
              value={"1"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />

            <Tab
              label={`Rental(${rental_bookmarks.length})`}
              value={"2"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />
            <Tab
              label={`Flatmates(${flatmate_bookmarks.length})`}
              value={"3"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />

            <Tab
              label={`Professionals (${professional_bookmarks.length})`}
              value={"4"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />
            {/* for blog section */}
            <Tab
              label={`Blogs (${blog_bookmarks?.length})`}
              value={"5"}
              className="fw-bold"
              sx={{
                fontSize: "clamp(0.5rem , 1vw + 0.5em , 0.9rem)",
              }}
            />
          </Tabs>
        </Box>

        <TabContext value={activestepper.toString()}>
          <TabPanel value="0" className="p-0 m-0 pt-2">
            <OrganizationBookmarks removeorg={removeorg} item={org_bookmarks} />
          </TabPanel>
          <TabPanel value="1" className="p-0 m-0 pt-2">
            <BusinessBookmarks
              item={business_bookmarks}
              removebusiness={removebusiness}
            />
          </TabPanel>
          <TabPanel className="p-0 m-0 pt-2" value="2">
            <RentalBookmarks item={rental_bookmarks} />
          </TabPanel>

          <TabPanel className="p-0 m-0 pt-2" value="3">
            <FlatmateBookmarks item={flatmate_bookmarks} />
          </TabPanel>

          {/* <TabPanel className="p-0 m-0 pt-2" value="4">
            <ProfessionalBookmarks item={professional_bookmarks} />
          </TabPanel> */}

          <TabPanel className="p-0 m-0 pt-2" value="5">
            <BlogBookmarks item={blog_bookmarks}  />
          </TabPanel>
        </TabContext>
      </section>
    </div>
  );
};

export default Bookmarks;
