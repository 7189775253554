// import AdminOrgProfile from "./component/AdminOrgProfile";
import { CancelOutlined, CheckOutlined, MoreVert } from "@mui/icons-material";
import { Box, Button, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllBusinessAction } from "../../../../context/actions/admin/businessAction";
import { FaEdit, FaTrash, FaEllipsisV } from "react-icons/fa";
import AdminBusinessDetail from "./adminBusinessDetail/AdminBusinessDetail";
import moment from "moment";

export default function BusinessTable({ keyword }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);

  const [status, setStatus] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  React.useEffect(() => {
    dispatch(adminGetAllBusinessAction({ keyword, page, status }));
  }, [dispatch, page, keyword]);


  
  const columns = [
    {
      field: "image",
      headerName: "Related Image",
      flex: 1,
      renderCell: (params) => {
        const { image } = params.row;
        return (
          <Box sx={{ display: "flex" }}>
            {image?.slice(0, 3).map((item, index) => {
              return (
                <img
                  className="col-4 object-cover  rounded-5 object-center "
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  key={index}
                />
              );
            })}
          </Box>
        );
      },
    },
    { field: "name", headerName: "Business Name", flex: 1 },
    { field: "address", headerName: "Proveince | City", flex: 1 },
    { field: "establishedDate", headerName: "Estd. Date", flex: 1 },

    { field: "view", headerName: "View", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },
    {
      field: "featured",
      headerName: "Featured",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,

      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <Button size="medium" variant="outlined" onClick={handleButtonClick}>
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];

  const { business, resultperpage, totalbusinesscount, business_loading } =
    useSelector((state) => ({
      ...state.admin_reducer,
    }));

  const mappedRows = business?.map((business, index) => ({
    id: business?._id,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${business?.images[0]}`,
    name: business?.introduction.businessName,
    address: `${business?.introduction.provenience} | ${business.introduction.city}`,
    active: business?.active,
    view: business?.viewCount,
    featured: business?.featured,
    image: business?.images,
    establishedDate: moment(business?.introduction.establishedDate).format("MMMM Do YYYY"),
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={business_loading}
          page={page}
          rowCount={totalbusinesscount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminBusinessDetail
          data={business[activeindex]}
          closeModal={toggleDrawer}
          activeindex={activeindex}
        />
      
      </Drawer>
    </div>
  );
}
