import React from "react";
import { BsHouseCheck } from "react-icons/bs";
import TenantAccessCard from "../components/TenantAccessCard";
import { Box } from "@mui/material";
import { RiChatPrivateLine } from "react-icons/ri";

const TenantAccess = ({ handleAccessType, selected, errors }) => {
  const list = [
    {
      id: "1",
      name: "Entire place",
      icon: <BsHouseCheck style={{ fontSize: "3em" }} />,
      description: "have access to entire property, manage by themselves",
    },

    {
      id: "2",
      name: "A private place or room",
      icon: <RiChatPrivateLine style={{ fontSize: "3em" }} />,
      description: "a  private space, access to garden water and other things",
    },
  ];
  return (
    <div className="mt-3">
      <p className="text-black fs-3 fw-bold">Tenant have access to</p>

      <Box
        sx={{
          maxWidth: { sm: "90%", md: "70%", lg: "50%" },
        }}
        className="mt-2"
      >
        {list.map((item) => {
          return (
            <TenantAccessCard
              item={item}
              key={item.id}
              handleAccessType={handleAccessType}
              selected={selected}
            />
          );
        })}
      </Box>

      <small className={errors ? "form-text text-danger" : "d-none"}>
        {errors}
      </small>
    </div>
  );
};

export default TenantAccess;
