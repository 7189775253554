import { createSlice } from "@reduxjs/toolkit";
import {
  createRentalAction,
  getAllRentalAction,
  getAllFeaturedRentalAction,
  getRentalDetailAction,
  createFlatemateAction,
  getAllFlatemateAction,
  getFlatemateDetailAction,
} from "../actions/rentalAction";

// hello
const initialState = {
  loading: false,
  createRentalLoading: false,
  getFeaturedPropertyLoading: false,
  createFlatemateLoading: false,
  flatemate_loading: false,
  property: [],
  featured_property:[],
  flatemate: [],
  rental_detail: {},
  flatemate_detail: {},
  //rental
  totalrentalcount: 0,
  filterrentalcount: 0,
  resultperpage: 0,
  //flatemate
  flatemateResultperpage: 0,
  totalflatmateCount: 0,
  filterflatmateCount: 0,
  error: "",
};

const rentalSlice = createSlice({
  name: "rental",
  initialState,
  reducers:{
    clearError: (state, action) => {
      state.error = "";
    }

  },
  extraReducers: (builder) => {
    builder
      // create rental
      .addCase(createRentalAction.pending, (state, action) => {
        state.createRentalLoading = true;
      })
      .addCase(createRentalAction.fulfilled, (state, action) => {
        state.createRentalLoading = false;
      })
      .addCase(createRentalAction.rejected, (state, action) => {
        state.createRentalLoading = false;
        state.error = action.payload.message;
      })

      .addCase(getAllRentalAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllRentalAction.fulfilled, (state, action) => {
        const { rental, resultperpage, filterrentalcount, totalrentalcount } =
          action.payload;
        state.loading = false;
        state.property = rental;
        state.resultperpage = resultperpage;
        state.filterrentalcount = filterrentalcount;
        state.totalrentalcount = totalrentalcount;
      })
      .addCase(getAllRentalAction.rejected, (state, action) => {
        state.loading = false;
        state.property = [];
      })
     
      // get all featured rental
      .addCase(getAllFeaturedRentalAction.pending, (state, action) => {
        state.getFeaturedPropertyLoading = true;
      })
      .addCase(getAllFeaturedRentalAction.fulfilled, (state, action) => {
        state.getFeaturedPropertyLoading = false;
        const { featuredRental } = action.payload;
        state.featured_property = featuredRental;
      })
      .addCase(getAllFeaturedRentalAction.rejected, (state, action) => {
        state.getFeaturedPropertyLoading = false;
        state.featured_property = [];
      })
      // get rental detail
      .addCase(getRentalDetailAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getRentalDetailAction.fulfilled, (state, action) => {
        const { rental } = action.payload;
        state.loading = false;
        state.rental_detail = rental;
      })
      .addCase(getRentalDetailAction.rejected, (state, action) => {
        state.loading = false;
        state.rental_detail = {};
      })
      // create flatemate
      .addCase(createFlatemateAction.pending, (state, action) => {
        state.createFlatemateLoading = true;
      })
      .addCase(createFlatemateAction.fulfilled, (state, action) => {
        state.createFlatemateLoading = false;
      })
      .addCase(createFlatemateAction.rejected, (state, action) => {
        state.createFlatemateLoading = false;
        state.error = action.payload.message;
      })

      // get all flatemate
      .addCase(getAllFlatemateAction.pending, (state, action) => {
        state.flatemate_loading = true;
      })
      .addCase(getAllFlatemateAction.fulfilled, (state, action) => {
        const {
          flatemate,
          totalFlatmateCount,
          filterFlatmateCount,
          resultperpage,
        } = action.payload;
        state.flatemate_loading = false;
        state.flatemate = flatemate;
        state.totalflatmateCount = totalFlatmateCount;
        state.filterflatmateCount = filterFlatmateCount;
        state.flatemateResultperpage = resultperpage;
      })
      .addCase(getAllFlatemateAction.rejected, (state, action) => {
        state.flatemate_loading = false;
        state.flatemate = [];
      })
      // get flatemate detail
      .addCase(getFlatemateDetailAction.pending, (state, action) => {
        state.loading = true;
        
      })
      .addCase(getFlatemateDetailAction.fulfilled, (state, action) => {
        const { flatemate } = action.payload;
        state.loading = false;
        state.flatemate_detail = flatemate;
      })
      .addCase(getFlatemateDetailAction.rejected, (state, action) => {
        state.loading = false;
        state.flatemate_detail = {};
      });
  },
});

export const { clearError } = rentalSlice.actions;

export default rentalSlice.reducer;
