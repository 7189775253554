import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { MoreVert, CheckOutlined, CancelOutlined } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllUserAction } from "../../../../context/actions/admin/userAction";
import { Drawer } from "@mui/material";
import AdminUserDetail from "./AdminUserDetail/AdminUserDetail";
export const AdminUsersTable = ({ keyword }) => {
  const [activeindex, setActive] = React.useState(0);

  const columns = [
    {
      field: "profile",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
      headerName: "Profile",
    },
    { field: "FullName", headerName: "FullName", flex: 1 },
    { field: "email", headerName: "email", flex: 1 },
    { field: "Country", headerName: "Country", flex: 1 },
    {
      field: "verified",
      headerName: "Verified",
      width: 150,
      renderCell: (params) => {
        if (params.value === true) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "activated",
      headerName: "activated",
      width: 150,
      renderCell: (params) => {
        if (params.value === true) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <div>
            <Button
              size="medium"
              variant="outlined"
              onClick={handleButtonClick}
            >
              <MoreVert color="error" />
            </Button>
          </div>
        );
      },
    },
  ];

  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  // drawer toggle
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    dispatch(adminGetAllUserAction({ keyword, page }));
  }, [dispatch, keyword, page]);

  // use useSelector to get data from redux store
  const { user, resultperpage, totalusercount, loading } = useSelector(
    (state) => ({
      ...state.admin_reducer,
    })
  );

  // map user data to rows
  const all_user = user.map((item, index) => ({
    id: item._id,
    index: index,
    profile: "https://i.pravatar.cc/300",
    FullName: `${item.firstname} ${item.lastname}`,
    email: item.email,
    Country: item.country,
    verified: item.verified,
    activated: item.active,
  }));

  // return table
  return (
    <div className="">
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminUserDetail
        activeindex={activeindex}
        id={user[activeindex]?._id}
        
        data={user[activeindex]} 
        onClose={toggleDrawer} />
      </Drawer>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={all_user}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalusercount}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};
