import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import { Tab, Tabs, Typography } from "@mui/material";
import { Chip } from "@mui/material";
import { ImCross } from "react-icons/im";
import { CgMenuGridR } from "react-icons/cg";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
    gap: 5px;
    border-radius: 25px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;

const EventsDetailsSkeleton = () => {
  return (
    <div className="container mt-2">
      <Box
        className="headerimg position-relative rounded rounded-5 overflow-hidden d-flex justify-content-center  "
        sx={{
          height: "250px",
        }}
      >
        <Box
          className="w-100 h-100 position-absolute top-0 start-0"
          sx={{
            backdropFilter: "blur(5px)",
          }}
        ></Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          animation="wave"
        />
        <Box
          className="d-flex flex-column position-absolute justify-content-center align-items-center text-white"
          sx={{
            height: "100%",
            inset: "0",
          }}
        >
          <Box
            className="text-center"
            sx={{
              width: "70%",
            }}
          >
            <Skeleton
              variant="text"
              width="100%"
              height={50}
              animation="wave"
            />
            <Skeleton
              variant="text"
              width="80%"
              height={30}
              animation="wave"
            />
          </Box>
        </Box>
      </Box>
      <Wrapper className="mt-2">
        <section className="grid-images position-relative rounded rounded-3 overflow-hidden">
          {[1, 2, 3, 4].map((item) => (
            <div className="w-100 h-100 item" key={item}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                animation="wave"
              />
            </div>
          ))}
          <Box
            sx={{
              position: "absolute",
              bottom: "3%",
              right: "1%",
            }}
            className="btn d-flex gap-2 align-items-center bg-white"
          >
            <CgMenuGridR className="position bottom-0" />
            <span>Show all photos</span>3
          </Box>
        </section>
      </Wrapper>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "100vh",
          backdropFilter: "blur(30px)",
        }}
      >
        <Tabs value={0} variant="scrollable">
          {[1, 2, 3, 4].map((item) => (
            <Tab
              key={item}
              className="p-0 m-0 overflow-hidden "
              icon={<Skeleton variant="rectangular" width={90} height={60} />}
            />
          ))}
        </Tabs>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="80vh"
          animation="wave"
        />
      </Box>
    </div>
  );
};

export default EventsDetailsSkeleton;
