import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Chip, Modal, Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect } from "react";
import { CgMenuGridR } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getEventByIdAction } from "../../context/actions/eventAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import EventsDetailsSkeleton from "./EventsDetailsSkeleton";
import { Helmet } from "react-helmet-async";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "100vh",
  backdropFilter: "blur(30px)",
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
    gap: 5px;
    border-radius: 25px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;

const EventsDetails = () => {
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);
  // to open modal
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch();

  // to close modal
  const handleClose = () => setOpen(false);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // -------------------------
  // use callback to get organization details to memoize the function
  const getEventDetails = useCallback(() => {
    dispatch(getEventByIdAction(id));
  }, [dispatch, id]);

  // call getEventDetails function on component mount
  useEffect(() => {
    getEventDetails();
  }, [getEventDetails]);

  const { events, loading } = useSelector((state) => ({
    events: state.event_reducer.event_details || {},
    loading: state.event_reducer.loading,
  }));

  if (loading) {
    <EventsDetailsSkeleton />;
  }

  return (
    <>
    <Helmet>
        <title>
          {events?.eventName}
        </title>
        {/* <meta name="description"
          data-rh="true"
          content={abroadStudyDetails?.countryOverview?.description} /> */}
        <link rel="canonical" href="/register" />
      </Helmet>
        <div className="container mt-2">
      <Box
        className="headerimg position-relative rounded rounded-5 overflow-hidden d-flex justify-content-center  "
        sx={{
          height: "250px",
        }}
      >
        <Box
          className="w-100 h-100 position-absolute top-0 start-0"
          sx={{
            backdropFilter: "blur(5px)",
          }}
        ></Box>
        <img
          loading="lazy"
          src="/assets/images/event_banner.jpeg"
          alt=""
          srcSet=""
          className="w-100 h-100 object-cover object-center"
        />
        <Box
          className="d-flex flex-column position-absolute justify-content-center align-items-center text-white"
          sx={{
            height: "100%",
            inset: "0",
          }}
        >
          <Box
            className="text-center"
            sx={{
              width: "70%",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  sm: "1.8rem",
                  md: "2rem",
                  lg: "2rem",
                },
              }}
              className="text-white"
            >
              {events.eventName}
            </Typography>

            <div className="d-flex gap-2 justify-content-center">
              <Chip
                className="mt-2"
                label={moment(events?.date).format("MMMM Do YYYY")}
                varient="outlined"
                color="warning"
              />
              <Chip
                className="mt-2"
                label={events?.viewCount}
                icon={<RemoveRedEyeOutlined />}
                varient="outlined"
                color="warning"
              />
            </div>
          </Box>
        </Box>
      </Box>
      <Wrapper className="mt-2">
        <section className="grid-images position-relative rounded rounded-3 overflow-hidden">
          {events?.photos?.map((item, index) => {
            return (
              <div
                role="button"
                className="w-100 h-100 item"
                key={index}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                  handleChange(e, index.toString());
                }}
              >
                <img
                  // src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  loading="lazy"
                  className="w-100 object-cover h-100"
                />
              </div>
            );
          })}

          {/* open modal button */}
          <Box
            sx={{
              position: "absolute",
              bottom: "3%",
              right: "1%",
            }}
            className="btn d-flex gap-2 align-items-center bg-white"
            onClick={handleOpen}
          >
            <CgMenuGridR className="position bottom-0" />
            <span>Show all photos</span>3
          </Box>
        </section>
      </Wrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={style} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            onClick={handleClose}
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <TabContext value={value}>
            {events?.photos?.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      loading="lazy"
                      className=" object-contain rounded-2"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <TabList
              onChange={handleChange}
              className=" mx-auto"
              sx={{
                width: "max-content",
                maxWidth: "100%",
                justifyContent: "center",

                "&.Mui-selected": {
                  color: "#797979",
                  fontWeight: "bolder",
                  border: "none",
                },
              }}
              centered
              indicatorcolor={""}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={value}
            >
              {events?.photos?.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0 overflow-hidden "
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center"
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          width={90}
                          className="object-cover w-100 mx-auto p-0 "
                        />
                      </Box>
                    }
                  />
                );
              })}
            </TabList>
          </TabContext>
        </Box>
      </Modal>
    </div>
    </>
  );
};

export default EventsDetails;
