import { Box, ButtonGroup, Drawer } from "@mui/material";
import React from "react";
import { BeforeCountry, AfterCountry } from "./tabs";
import { Button } from "@mui/material";
import { ImCross } from "react-icons/im";
import { BiPencil } from "react-icons/bi";
import EditStudentRequirments from "../editStudentRequirements/EditStudentRequirements";
import { useNavigate } from "react-router-dom";

const StudentRequirements = ({ onClose, data, country, activeIndex }) => {
  const [activeButton, setActiveButton] = React.useState("Apply");
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const afterlist = React.useMemo(() => {
    return data?.filter((item) => item.phase === "after");
  }, [data]);

  const beforelist = React.useMemo(() => {
    return data?.filter((item) => item.phase === "before");
  }, [data]);


  const navigate = useNavigate();
  

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: { lg: "70vw", xs: "100%", md: "80vw", sm: "90vw" },
      }}
      className="container "
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-6 text-black">
            Student Requirements for
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <div className="d-flex align-items-center fw-bold justify-content-between w-100">
        <h4>Studying in {country}</h4>

        <div className="d-flex align-items-center gap-1">
          <Button
            type="button"
            variant="outlined"
            className="text-black mb-2"
            onClick={()=> navigate(`/admin/students/editing/${country}`)}
          >
            <BiPencil /> Edit
          </Button>
          <ButtonGroup
            className="text-black mb-2"
            variant="outlined"
            aria-label="outlined button group"
          >
            <Button
              variant={activeButton === "Apply" ? "contained" : "outlined"}
              onClick={() => {
                setActiveButton("Apply");
              }}
            >
              <div className="comments d-flex align-items-center gap-1">
                Before {country}
              </div>
            </Button>
            <Button
              variant={activeButton === "After" ? "contained" : "outlined"}
              onClick={() => {
                setActiveButton("After");
              }}
            >
              <div className="clapped d-flex align-content-center gap-1">
                After {country}
              </div>
            </Button>
          </ButtonGroup>
        </div>
      </div>

      <section>
        <div className={activeButton === "Apply" ? "" : "d-none"}>
          <BeforeCountry list={beforelist} country={country} />
        </div>
        <div className={activeButton === "After" ? "" : "d-none"}>
          <AfterCountry list={afterlist} country={country} />
        </div>
      </section>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <EditStudentRequirments
          activeindex={activeIndex}
          onClose={toggleDrawer}
          data={data}
          country={country}
        />
      </Drawer>
    </Box>
  );
};

export default StudentRequirements;
