import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/faqApi";

// create faq

export const adminCreateFaqAction = createAsyncThunk(
  "admin/create_faq",
  async ({ data, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.createFaq(data);
      toast.success(response.data.message || "Faq created successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// edit faq

export const adminEditFaqAction = createAsyncThunk(
  "admin/edit_faq",
  async ({ id, data, toast, index }, { rejectWithValue }) => {
    // console.log(index, id, data);
    try {
      const response = await admin_api.editFaq(id, data);
      toast.success(response.data.message || "Faq updated successfully");
      return { data: response.data, index };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// get all faq
export const adminGetAllFaq = createAsyncThunk(
  "admin/get_all_faq",
  async ({ keyword, page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.getAllFaq(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
