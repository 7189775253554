import React, { useMemo } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import moment from "moment";

const BlogComment = React.memo(({ comments, index }) => {

  // show more description
  const [showMore, SetshowMore] = React.useState(false);

  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  return (
    <div className="row-cols-1 row">
      <div className="col-2 col-md-1 ">
        <img
          src="/assets/images/profile.png"
          alt=""
          className="object-cover rounded rounded-circle"
          width={50}
          height={50}
        />
      </div>
      <div className="comment-info d-flex flex-column col-6 col-md-8 ">
        <span className="comment-info_name text-paragraph fw-bold">
          {comments?.name}
        </span>

        <Typography
          variant="p"
          color="initial"
          sx={{
            color: "black",
            fontSize: "clamp(0.7rem ,1.5vw,0.9rem)",
            marginTop: "0.2rem",
          }}
        >
          {showMore
            ? comments?.comment
            : comments?.comment.split(" ").slice(0, 28).join(" ")}
          {comments?.comment.length > 200 ? (
            !showMore ? (
              <span
                onClick={toggleShowMore}
                className="text-black fw-bold"
                role="button"
              >
                ...ReadMore
              </span>
            ) : (
              <span
                onClick={toggleShowMore}
                className="text-black fw-bold"
                role="button"
              >
                ...ReadLess
              </span>
            )
          ) : null}
        </Typography>
      </div>

      <div className="comment-action col-4 d-flex justify-content-end col-md-3 ">
        <small>
          <i>
            {moment(comments?.time).
              fromNow()
            }
          </i>
        </small>
      </div>
    </div>
  );
});

export default BlogComment;

