import React from "react";

const PropertyPreferenceCard = ({
  value,
  name,
  handleChange,
  options,
  placeholder,
  label,
  icon,
}) => {
  return (
    <div className="d-flex gap-2 align-items-start">
      <div className="icons">{icon}</div>
      <div className="info w-100">
        <label className="text-capitalize">{label}</label>
        <select
          className="form-select rounded-0   py-2 rounded-2 w-100"
          aria-label=".form-select-lg example"
          value={value}
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {/* <option value="">{placeholder}</option> */}
          {options.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default PropertyPreferenceCard;
