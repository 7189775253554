import React from "react";
import { FormInputB } from "../../../../components/Index";
import AdminEventImage from "./AdminEventImage";
import { Box } from "@mui/material";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { adminEditEventAction } from "../../../../context/actions/admin/eventAction";
import { toast } from "react-toastify";
import { clearError } from "../../../../context/reducers/adminSlice";
const AdminEditEvent = ({ data, activeindex, id }) => {
  const dispatch = useDispatch();
  
  const [images, setImages] = React.useState({
    files: [],
    pImages: data.photos,
    nImages: data.photos,
  });

  const [errors, setErrors] = React.useState({});

  const [formValue, setFormValue] = React.useState({
    eventTitle: data.eventName,
    eventDate: data.date.split("T")[0],
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const { adminEditeEventLoding, error } = useSelector((state) => ({
    adminEditeEventLoding: state.admin_reducer.adminEditeEventLoding,
    error: state.admin_reducer.error,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  // handle image upload and remove images
  const handleimages = React.useCallback(
    ({ files, pImages }) => {
      setImages({ ...images, files: files, pImages: pImages });
    },
    [images]
  );

  // handle image upload and remove images
  const removeImages = React.useCallback(
    ({ files, pImages, nImages }) => {
      setImages({
        ...images,
        // send to api
        files: files,
        pImages: pImages,
        // send to api
        nImages: nImages,
      });
    },
    [images]
  );

  const onSubmit = () => {
    let isValid = true;
    const newerror = {};
    if (
      !formValue.eventTitle ||
      !formValue.eventDate ||
      images.pImages.length === 0
    ) {
      if (!formValue.eventTitle) {
        newerror.eventTitle = "Please provide title *";
      }
      if (!formValue.eventDate) {
        newerror.eventDate = "Please provide Date*";
      }
      if (images.pImages.length === 0) {
        newerror.images = "Please provide at lease one images*";
      }
      isValid = false;
    }

    setErrors(newerror);
    return isValid;
  };

  const submitEditEvent = () => {
    const isvalid = onSubmit();
    if (isvalid) {
      const data = new FormData();
      data.append("eventName", formValue.eventTitle);
      data.append("date", formValue.eventDate);
      for (let i = 0; i < images.nImages.length; i++) {
        data.append("nImages", images.nImages[i]);
      }
      for (let i = 0; i < images.files.length; i++) {
        data.append("photos", images.files[i]);
      }
      dispatch(adminEditEventAction({ data:data, index: activeindex,id:id, toast:toast }));
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
        padding: "10px",
      }}
      className="position-relative"
    >
      <div className="d-flex flex-column">
        <span className="fs-5 fw-bold text-black">
          Edit Event details with Gallary
        </span>
        <small>
          Commonly required by the university and immigration authorities
        </small>
      </div>
      <FormInputB
        name={"eventTitle"}
        label={"Title for the event"}
        required={true}
        classname={"form-control"}
        placeholder={"Enter Firstname"}
        value={formValue.eventTitle}
        handleChange={handleChange}
        error={errors.eventTitle ? true : false}
        helpertext={errors.eventTitle}
      />
      {/* select date */}
      <FormInputB
        name={"eventDate"}
        // handleChange={handleformvalue}
        label={"Event Date"}
        required={true}
        type={"date"}
        value={formValue.eventDate}
        handleChange={handleChange}
        error={errors.eventDate ? true : false}
        helpertext={errors.eventDate}
      />

      <small
        id="emailHelp"
        className={errors.images ? "form-text text-danger" : "d-none"}
      >
        {errors.images}
      </small>
      <AdminEventImage
        handleimages={handleimages}
        pImages={images.pImages}
        imageFile={images.files}
        nImages={images.nImages}
        removeImages={removeImages}
      />

      <div className="d-flex justify-content-end align-items-center position-fixed py-2 bottom-0 start-0 w-100 px-2">
        <button className="btn bg-black text-white" onClick={submitEditEvent}>
          Save
        </button>
      </div>
    </Container>
  );
};

export default AdminEditEvent;
