import { GiLovers, GiPerson } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { MdFamilyRestroom } from "react-icons/md";
const lookingForLst = [
  {
    id: "1",
    name: "Couple",
    icon: <GiLovers />,
    description: "Me and my patner",
  },
  {
    id: "2",
    name: "Myself",
    icon: <GiPerson />,
    description: "I am alone just roaming to find the rental property",
  },
  {
    id: "3",
    name: "Group",
    icon: <HiUserGroup />,

    description: "A group of people",
  },
  {
    id: "4",
    name: "Family",
    icon: <MdFamilyRestroom />,

    description: "Me and my family",
  },
];

export { lookingForLst };
