import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DropdownEditDelete } from "../../../../../../components/Index";
import formatViewCount from "../../../../../../utils/formatViewCount";
import {
  adminFeaturedBlogAction,
  
} from "../../../../../../context/actions/admin/blogAction";
import { clearError } from "../../../../../../context/reducers/adminSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";


const AdminBlogCard = React.memo(({ item, index }) => {
  const [liked, setliked] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isFeatured = item.isFeatured;

   

   const { loading, error } = useSelector((state) => ({
     error: state.admin_reducer.error,
   }));

   React.useEffect(() => {
     if (error) {
       toast.error(error);
     }
     dispatch(clearError());
   }, [error]);

  





  // const handleFeaturedBlog = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("featured", !isFeatured);
  //   dispatch(adminFeaturedBlogAction({
  //     id: item._id,
  //     formData,
  //     toast:toast
  //   }));
     
  // }
  const handleFeaturedStatusFalse = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("featured", false);
    dispatch(
      adminFeaturedBlogAction({
        id: item._id,
        data: false,
        toast: toast,
      })

    );
    // window reload
    window.location.reload();
  };

  const handleFeaturedStatusTrue = (e) => {
    e.preventDefault();
   
    dispatch(
      adminFeaturedBlogAction({
        id: item._id,
        data: true,
        toast: toast,
      })
    );
    window.location.reload();
  };
  



  return (
    <Box
      className="position-relative h-100"
      sx={{
        "&:hover > .bcard-option": {
          display: "flex",
        },
      }}
    >
      <Link to={`/blogs/${item._id}`}>
        <Box
          className="overflow-hidden w-100"
          sx={{
            height: "100%",
          }}
        >
          <Box
            className="carousel slide"
            data-mdb-interval="false"
            sx={{
              height: "min(190px , calc(130px + 5vw))",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 5,
                zIndex: 1,
              }}
            >
              {/* badge */}
              <span className="badge rounded-pill badge-light">
                {item.status}
              </span>
            </Box>
            {item.status === "published" ? (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                {/* <button
                  onClick={handleFeaturedBlog}
                  className="btn btn-sm btn-primary shadow-0"
                >
                  {item?.isFeatured ? "Featured" : "Not Featured"}
                </button> */}

                {item?.isFeatured ? (
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    data-mdb-ripple-color="dark"
                    onClick={handleFeaturedStatusFalse}
                  >
                    UnFeatured
                  </button>
                ) : (
                  <button
                    type="button"
                    class="btn btn-sm btn-success"
                    data-mdb-ripple-color="dark"
                    onClick={handleFeaturedStatusTrue}
                  >
                    Featured
                  </button>
                )}
              </Box>
            ) : null}

            {item?.thumbnail ? (
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}${item?.thumbnail}`}
                className="d-block w-100 h-100 rounded rounded-3 object-cover"
                alt="Wild Landscape"
              />
            ) : (
              <img
                src="/assets/images/blog.jpeg"
                className="d-block w-100 h-100 rounded rounded-3 object-cover"
                alt="Wild Landscape"
              />
            )}
          </Box>

          <Box
            className="d-flex flex-column gap-1 justify-content-between "
            sx={{
              lineHeight: "1.5rem",
            }}
          >
            <Typography
              className="mt-2 text-black"
              variant="p"
              sx={{
                lineHeight: "1.5",
                fontSize: "0.9rem",
                fontWeight: "400",
              }}
            >
              Posted on: {moment(item.createdAt).format("DD MMMM YYYY")}
            </Typography>
            <h5 className="fw-bold mt-1 text-black ">
              {index === 0 ? item?.title : item?.title?.slice(0, 60) + "..."}
            </h5>
            <Box
              sx={{
                height: "20px",
              }}
            ></Box>
          </Box>
        </Box>
      </Link>
      <Box
        className="d-flex align-items-center"
        sx={{
          position: "absolute",
          bottom: 0,
          right: "2%",
        }}
      >
        <VisibilityIcon
          sx={{
            color: "#C1C1C1",
            fontSize: "clamp(0.7rem,1vw + 2em,1.6rem)",
          }}
        />
        <Typography
          sx={{
            fontSize: "clamp(0.7rem,1vw,1.6rem)",
          }}
          className="text-black ms-1"
        >
          {formatViewCount(item.viewCount)}
        </Typography>
      </Box>

      <Box
        className=" bcard-option "
        sx={{
          position: "absolute",
          top: "2%",
          right: "2%",
          display: "none",
        }}
      >
        <DropdownEditDelete
          onEdit={() => navigate(`/admin/blog/edit/${item._id}`)}
        />
      </Box>

      <Box
        className=""
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <button
          className="btn btn-sm btn-outline-dark "
          data-mdb-ripple-color="dark"
        >
          {item.category}
        </button>
      </Box>
    </Box>
  );
});

export default AdminBlogCard;
