import HomeWorkIcon from "@mui/icons-material/HomeWork";
import React from "react";
import Wrapper from "./RentalStyle";

import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { RemoveRedEye } from "@mui/icons-material";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  get_all_rental_data_by_user,
  get_flatmate_by_user,
} from "../../../../context/actions/authAction";
import formatViewCount from "../../../../utils/formatViewCount";
import RentalTable from "./rentalTable";

const RentalTab = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = React.useState("");
  const [page, setPage] = React.useState(0);

  const addProperty = () => {
    navigate("/rental/add");
  };

  const addFlatmates = () => {
    navigate("/flatemate/add");
  };

  const gotoProfile = () => {
    navigate("/flatemate/profile");
  };

  React.useEffect(() => {
    Promise.all([
      dispatch(get_all_rental_data_by_user()),
      dispatch(get_flatmate_by_user()),
    ]);
  }, [dispatch]);

  const {
    user_rental_data,
    user_flatmate_data,
    totalrentalViews,
    rental_loading,
    flatemate_loading,
  } = useSelector((state) => ({
    user_rental_data: state.user_reducer.user_rental_data,
    user_flatmate_data: state.user_reducer?.user_flatmate_data,
    totalrentalViews: state.user_reducer.totalrentalViews,
    rental_loading: state.user_reducer.rental_loading,
    flatemate_loading: state.user_reducer.flatemate_loading,
  }));

  return (
    <Wrapper>
      <section className="d-flex align-items-center flex-column flex-md-row w-100 m-0 p-0">
        <h4 className=" fw-bold">
          Rental | Property and
          <span className="text-danger ms-2">Flatmates</span>
        </h4>

        <div className="ms-md-auto d-flex justify-content-end gap-2">
          {user_flatmate_data && Object.keys(user_flatmate_data).length > 0 ? (
            <button
              className="btn bg-success text-white text-nowrap"
              onClick={gotoProfile}
            >
              Activated | Go to Profile
            </button>
          ) : (
            <button
              className="btn bg-danger text-white text-nowrap"
              onClick={addFlatmates}
            >
              Add Flatmates
            </button>
          )}

          <button
            className="btn bg-black text-white text-nowrap"
            onClick={addProperty}
          >
            Add Property
          </button>
        </div>
      </section>
      <hr />
      <section className="row row-cols-2 w-100 p-0 m-0 ">
        <div className="rental-left p-2 d-flex flex-column  col-3 d-none d-md-block  h-maxcontent bg-hover rounded-6">
          <div className="d-flex justify-content-between align-items-center p-3">
            <HomeWorkIcon className="fs-1 fw-bold" />
            <p className="w-50">Total owned property</p>
            <span className="fs-1 fw-bold">{user_rental_data?.length}</span>
          </div>
          <hr className="m-0" />
          <div className="d-flex justify-content-between align-items-center p-3">
            <RemoveRedEye className="fs-1 " />
            <p className="w-50">Showing overall viewer count</p>
            <span className="fs-1 fw-bold">
              {formatViewCount(totalrentalViews)}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-9">
          {/* <RentalTable rental_data={user_rental_data} /> */}
          <Stack>
            <OutlinedInput
              className="mb-2"
              size="small"
              fullWidth
              placeholder="Search rental property by address, suburb, postcode, etc."
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          </Stack>

          <RentalTable
            keyword={keyword}
            loading={rental_loading}
            page={page}
            setPage={setPage}
            rental_data={user_rental_data}
          />
        </div>
      </section>
    </Wrapper>
  );
};

export default RentalTab;
