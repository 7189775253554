import React from 'react'
import { Link } from 'react-router-dom'
import moment from "moment";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';


const UserEventCard = ({ event }) => {

  return (
    <Link to={`/events/${event._id}`} class="col">
      <div
        id="carouselBasicExample"
        className="carousel slide carousel-fade"
        data-mdb-ride="carousel"
      >
        <div className="carousel-inner">
          {event && event?.photos && (
            <div className="carousel-inner">
              {event?.photos?.map((photo, index) => (
                <div
                  key={index}
                  className={
                    index === 0 ? "carousel-item active" : "carousel-item"
                  }
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_PATH}${photo}`}
                    className="d-block w-100 rounded-2 object-cover"
                    alt="Sunset Over the City"
                    height={"240px"}
                    width={"180px"}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-mdb-target="#carouselBasicExample"
          data-mdb-slide="prev"
        >
          <span className="" aria-hidden="true">
            <ArrowBackIos />
          </span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-mdb-target="#carouselBasicExample"
          data-mdb-slide="next"
        >
          <span className="" aria-hidden="true">
            <ArrowForwardIos />
          </span>
        </button>
      </div>
      <div className="card-body">
        <h5 className="card-title mt-2 text-black">{event?.eventName}</h5>
        <p className="card-text mt-2">
          Event date: {moment(event?.date).format("MMMM Do YYYY")}
        </p>
      </div>
    </Link>
  );
}

export default UserEventCard

