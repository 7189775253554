import { Box } from "@mui/material";
import React from "react";
// BsHouse
import { BsCalendar2Date, BsHouse } from "react-icons/bs";
import { FaLayerGroup } from "react-icons/fa";
// CiBadgeDollar
import { CiBadgeDollar } from "react-icons/ci";
import ShowLocation from "../../../../../../components/location/ShowLocation";

const RentalInformation = ({ data }) => {
  const [showMore, SetshowMore] = React.useState(false);
  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };
  return (
    <Box
      sx={{
        minHeight: "70vh",
      }}
      className="d-flex flex-column gap-3"
    >
      <section>
        <p className="fw-bold fs-5 mb-2">Property overview</p>

        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-2 align-items-center justify-content-between">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <BsHouse size={40} />
                <div>
                  <h6 className="m-0">Property type</h6>
                  <h5 className="fw-bold m-0 p-0">Apartment</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <BsCalendar2Date size={40} />
                <div>
                  <h6 className="m-0">Minimum length of stay</h6>
                  <h5 className="fw-bold m-0 p-0">{data.minmumStay}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <FaLayerGroup size={40} />
                <div>
                  <h6 className="m-0">Tenent have access of</h6>
                  <h5 className="fw-bold m-0 p-0">{data.accessTo}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <p className="fw-bold fs-5 mb-2">Availabiity & Pricing</p>

        <div className="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-2 align-items-center justify-content-between">
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <BsCalendar2Date size={40} />
                <div>
                  <h6 className="m-0">Available from</h6>
                  <h5 className="fw-bold m-0 p-0">19 JULY 2023</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <CiBadgeDollar size={40} />
                <div>
                  <h6 className="m-0">Pricing</h6>
                  <h5 className="fw-bold m-0 p-0">{data.minmumStay}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center gap-2 border border-1 border-dark rounded rounded-4 p-3 ">
              <div className="d-flex gap-2">
                <FaLayerGroup size={40} />
                <div>
                  <h6 className="m-0">Tenent have access of</h6>
                  <h5 className="fw-bold m-0 p-0">{data.accessTo}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <p className="fw-bold fs-5 mb-2">Property Description</p>
        <p>
          {showMore ? data?.description : data?.description?.slice(0, 200)}
          {data?.description?.length > 200 ? (
            !showMore ? (
              <span
                onClick={toggleShowMore}
                className="text-black fw-bold"
                role="button"
              >
                ...ReadMore
              </span>
            ) : (
              <span
                onClick={toggleShowMore}
                className="text-black fw-bold"
                role="button"
              >
                ...ReadLess
              </span>
            )
          ) : null}
        </p>
      </section>

      <section>
        <p className="fw-bold fs-5 mb-2">Location on Map</p>

        <ShowLocation location={data?.location} />
      </section>
    </Box>
  );
};

export default RentalInformation;
