import { MoreVert } from "@mui/icons-material";
import { Box, Button, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllOrganizationAction } from "../../../../context/actions/admin/organizationAction";
import ProfessionalReviewDetail from "./professionalReviewDetail/ProfessionalReviewDetail";
import { adminGetAllProfessionalAction } from "../../../../context/actions/admin/professionalAction";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ProfessionalReviewTable({ keyword }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);
  const [status, setStatus] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    dispatch(
      adminGetAllProfessionalAction({
        page: page,
        keyword: keyword,
        status: status,
      })
    );
  }, [dispatch, page, keyword,status ]);

  const {
    professional_loading,
    professional,
    resultperpage,
    totalprofessionalcount,
  } = useSelector((state) => state.admin_reducer);

  const columns = [
    {
      field: "logo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "Provenience", headerName: "Provenience", flex: 1 },
    { field: "Profession", headerName: "Profession", flex: 1 },
    { field: "Views", headerName: "Views", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { id } = params.row;

        return (
          <div className="d-flex align-items-center gap-2">
            <Box
              sx={{
                height: "40px",
                width: "40px",
              }}
              className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
            >
              <VisibilityIcon
                onClick={() => {
                  setActive(id);
                  toggleDrawer();
                }}
                role="button"
              />
            </Box>
          </div>
        );
      },
    },
  ];

  const mappedRows = professional?.map((professional, index) => ({
    id: index,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${professional?.userPic}`,
    name: professional?.firstName,
    Provenience: professional?.provenience,
    Profession: professional?.profession,
    Views: professional?.viewCount,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={professional_loading}
          page={page}
          rowCount={totalprofessionalcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <ProfessionalReviewDetail
          data={professional[activeindex]}
          activeindex={activeindex}
          closeModal={toggleDrawer}
        />
      </Drawer>
    </div>
  );
}
