const allorg = [
  {
    _id: "sdfsdf0",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },

  {
    _id: "sdfsdf9",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf8",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf7",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf6",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf5",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf4",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf3",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
  {
    _id: "sdfsdf2",
    introduction: {
      profilePic:
        "https://images.unsplash.com/photo-1502823403499-6ccfcf4fb453?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      coverimg:
        "https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      description:
        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Totam, ab repellat magnam delectus atque deleniti explicabo, eum eligendi voluptatibus praesentium nam deserunt.",

      email: "Nischal@gmail.com",
      postalcode: "20222",
      phonenumber: "9813447627",
      telephonenumber: "01222222",
      nameofowner: "Nabin",

      organizationName: "John Doe",

      members: [],
      provenience: "Toronto",
      city: "oralando",

      panfront: "",
      panback: "",
    },

    panfrontfile: "",
    panbackfile: "",

    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "kathmandu",
    city: "Baneshwor",
    agreement: false,
  },
];

export default allorg;
