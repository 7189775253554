import React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
const DropdownEditDelete = ({ onEdit, onDelete }) => {
  const [toggle, settoggle] = React.useState(false);
  const toggledropdown = (e) => {
    e.stopPropagation();
    settoggle((prev) => !prev);
  };
  const Wrapper = styled.div`
    & > .circle-bg {
      width: 2.5em;
      height: 2.5em;
      border-radius: 50px;
      background-color: var(--dropdowncircle-color);
      color: var(--secondary-color);

      display: flex;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(20px);
      transition: 1.5s all;
    }
  `;
  return (
    <Wrapper
      className="d-flex flex-column gap-2 position-relative"
      onMouseLeave={() => settoggle(false)}
    >
      <Box
        className="circle-bg"
        sx={{
          zIndex: "3",
        }}
      >
        {toggle ? (
          <CloseIcon
            sx={{
              color: "inherit",
              cursor: "pointer",
            }}
            onClick={toggledropdown}
          />
        ) : (
          <MoreHorizIcon
            sx={{
              color: "inherit",
              cursor: "pointer",
            }}
            onClick={toggledropdown}
          />
        )}
      </Box>

      <Box
        className={
          toggle ? "circle-bg" : "circle-bg position-absolute top-0 left-0"
        }
        sx={{
          zIndex: "2",
          transition: "2.5s position linear",
        }}
        role="button"
        onClick={onEdit}
      >
        <EditIcon
          sx={{
            color: "inherit",
            cursor: "pointer",
          }}
        />
      </Box>

      <Box
        className="circle-bg   top-0 left-0"
        sx={{
          zIndex: "1",
          position: toggle ? "static" : "absolute",
          transition: "1.5s all ",
        }}
        role="button"
      >
        <DeleteIcon
          sx={{
            color: "inherit",
            cursor: "pointer",
          }}
          onClick={onDelete}
        />
      </Box>
    </Wrapper>
  );
};

export default DropdownEditDelete;
