import styled from "styled-components";

const Wrapper = styled.aside`

  & > .overview-header {
    width: 100%;
    height: 30vh;

    position: relative;
    display: flex;
    align-items: center;
    & > .overview-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 55%;
      border-start-start-radius: 15px;
      border-top-right-radius: 15px;
      overflow: hidden;
      
    }
  }

  & > .overview-info {
    line-height: 5px;
  }
`;

export default Wrapper;
