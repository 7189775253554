import { createAsyncThunk } from "@reduxjs/toolkit";

import * as event_api from "../../api/faq_api";

// get all faq
export const getAllFaqAction = createAsyncThunk(
    "getAllFaq",
    async (__, { rejectWithValue }) => {
        try {
            const response = await event_api.get_all_faq();
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
