import Modal from "@mui/material/Modal";
import React, { useState } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { Suspense } from "react";
import { allProvince } from "../../../../../../utils/orgSettings";
import allcity from "../../../../../../utils/all_city";
import {
  FormInputB,
  FormSelectB,
  FormTextAreaB,
  InputTime,
} from "../../../../../../components/Index";
import { category } from "../../../../../../utils/bussinessSettings";

const BusinessIntroduction = ({
  value,
  handleformvalue,
  handleSubmit,
  errors,
  addoffer,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", md: 600 },
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    minHeight: 400,
    p: 4,
  };

  let fliteredcategorylst = category.find(
    (item) => item.name === value.category
  );
  const filteredcity = React.useMemo(() => {
    return allcity
      .filter((option) => option.province_name === value.proveniece)
      .sort((a, b) => a.city.localeCompare(b.city));
  }, [value.proveniece]);

  // modal close open state
  const [open, setOpen] = React.useState(false);

  // offer filtered according to category
  const [weoffer, setweoffer] = React.useState(fliteredcategorylst);

  const [saveWeoffer, setSaveWeOffer] = React.useState(fliteredcategorylst);

  // on modal open
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  // oncancel remove the changes
  const handleClose = (e) => {
    e.stopPropagation();
    const unsaveoffer = structuredClone(saveWeoffer);
    setweoffer(unsaveoffer);
    setlist(value.offers);
    setOpen(false);
  };

  // modal offers list
  const [list, setlist] = useState(value.offers);

  // remove or add items from modal list
  const addtolist = (value, index) => {
    const iscontained = list.includes(value);

    let newoffer = structuredClone(weoffer);
    let newlist = [];

    if (!iscontained) {
      newlist = [...list, value];
      newoffer.offer[index].selected = true;
    } else {
      newlist = list.filter((item) => item !== value);

      const objIndex = newoffer.offer.findIndex((obj) => obj.name === value);
      if (objIndex !== -1) {
        newoffer.offer[objIndex].selected = false;
      }

      // newoffer.offer[index].selected = false;
    }
    setweoffer({ ...newoffer, offer: [...newoffer.offer] });

    setlist(newlist);
  };
  // handle category change
  const changecategory = (e) => {
    handleformvalue(e);
    let fliteredcategorylst = category.find(
      (item) => item.name === e.target.value
    );
    setweoffer(fliteredcategorylst);
    setSaveWeOffer(fliteredcategorylst);
  };

  const addnewoffers = () => {
    addoffer(list);
    const savedoffer = structuredClone(weoffer);
    setSaveWeOffer(savedoffer);
    setOpen(false);
  };

  const removeOffers = (value, index) => {
    const iscontained = list.includes(value);

    let newoffer = { ...weoffer };
    let newlist = [];

    if (!iscontained) {
      newlist = [...list, value];
      newoffer.offer[index].selected = true;
    } else {
      newlist = list.filter((item) => item !== value);

      const objIndex = newoffer.offer.findIndex((obj) => obj.name === value);
      if (objIndex !== -1) {
        newoffer.offer[objIndex].selected = false;
      }

      // newoffer.offer[index].selected = false;
    }
    setweoffer({ ...newoffer, offer: [...newoffer.offer] });
    setSaveWeOffer({ ...newoffer, offer: [...newoffer.offer] });

    setlist(newlist);
    addoffer(newlist);
  };

  return (
    <Suspense fallback={<h1> ....loading</h1>}>
      <div className={" px-lg-5 py-lg-4 px-3 py-3 needs-validation"}>
        <h2>Basic information</h2>
        <div className="bg-hover p-lg-5 p-3  rounded rounded-6 ">
          <FormInputB
            name={"businessName"}
            handleChange={handleformvalue}
            label={"BusinessName (Ex: ONF Canada Public Group)"}
            required={true}
            classname={"w-100 form-control"}
            placeholder={"Enter Business Name"}
            error={errors.businessName ? true : false}
            helpertext={errors.businessName}
            value={value.businessName}
          />

          <FormInputB
            name={"ownerName"}
            handleChange={handleformvalue}
            label={"Business owner name"}
            required={true}
            placeholder={"Enter Business Owner Name"}
            type={"text"}
            classname={" w-100  form-control"}
            error={errors.ownerName ? true : false}
            helpertext={errors.ownerName}
            value={value.ownerName}
          />

          <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
            <FormInputB
              name={"openingHour"}
              handleChange={handleformvalue}
              label={"Opening Hour"}
              required={true}
              placeholder={"Enter Opening Hour"}
              type={"time"}
              classname={" w-100  form-control"}
              error={errors.openingHour ? true : false}
              helpertext={errors.openingHour}
              value={value.openingHour}
            />
            <FormInputB
              name={"closingHour"}
              handleChange={handleformvalue}
              label={"Closing Hour"}
              required={true}
              placeholder={"Enter Closing Hour"}
              type={"time"}
              classname={" w-100  form-control"}
              error={errors.closingHour ? true : false}
              helpertext={errors.closingHour}
              value={value.closingHour}
            />
          </div>

          <div className="mt-3">
            <FormSelectB
              name={"category"}
              label={"Category"}
              handleChange={changecategory}
              disabled={false}
              list={category.map((obj) => obj.name)}
              placeholder={"Select Categories"}
              error={errors.category ? true : false}
              helpertext={errors.category}
              value={value.category}
            />
          </div>

          <div className="d-flex flex-column">
            {value.category.length > 0 && (
              <div className="d-flex align-items-center gap-2">
                <span
                  onClick={handleOpen}
                  className="btn bg-black rounded w-maxcontent rounded-pill text-capitalize my-2 text-white"
                >
                  + Add Offers
                </span>{" "}
                {errors.offers && (
                  <span className="text-danger">{errors.offers}</span>
                )}
              </div>
            )}
            <div className="d-flex flex-wrap gap-2">
              {value.offers.map((item, index) => {
                return (
                  <Chip
                    label={item}
                    key={index}
                    size="small"
                    variant="outlined"
                    onDelete={() => removeOffers(item, index)}
                  />
                );
              })}
            </div>
          </div>

          <div className="mt-3">
            <FormTextAreaB
              name={"description"}
              handleformvalue={handleformvalue}
              label={"Description (Introduce your business in brief)"}
              required={true}
              placeholder={"Briefly describe your Bussiness"}
              maxlength={1000}
              value={value.description}
              className={"mt-3"}
              error={errors.description ? true : false}
              helpertext={errors.description}
            />
          </div>

          <FormInputB
            name={"establishedDate"}
            handleChange={handleformvalue}
            label={"Established date (Ex.2014)"}
            required={true}
            type={"date"}
            classname={" w-100  form-control"}
            error={errors.establishedDate ? true : false}
            helpertext={errors.establishedDate}
            value={value.establishedDate}
          />

          <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
            <FormSelectB
              value={value.province}
              handleChange={handleformvalue}
              list={allProvince}
              name={"proveniece"}
              error={errors.proveniece ? true : false}
              helpertext={errors.proveniece}
              label={"Proveniece"}
              disabled={false}
              placeholder={"Open To Select Province"}
            />
            <div className="w-100">
              <label htmlFor="city">city</label>
              <select
                id="city"
                className={
                  errors.city
                    ? "form-select form-select-lg rounded-0 py-2 rounded-2 is-invalid"
                    : "form-select form-select-lg rounded-0 py-2 rounded-2 "
                }
                aria-label=".form-select-lg example"
                value={value.city}
                name="city"
                onChange={handleformvalue}
                disabled={value.proveniece.length > 1 ? false : true}
              >
                <option value="">Open To City</option>
                {value.proveniece &&
                  filteredcity.map((city, index) => {
                    return (
                      <option value={city.city} key={index}>
                        {city.city}
                      </option>
                    );
                  })}
              </select>
              <small className={errors.city ? "form-text text-danger" : ""}>
                {errors.city}
              </small>
            </div>
          </div>
        </div>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="w-100">
              <SearchbarB placeholder={"Search offers"} />
            </div>
          </Box>
        </Modal> */}

        <div className=" d-flex justify-content-end mt-3">
          <button className="btn-dark  ms-auto " onClick={handleSubmit}>
            Next
          </button>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className="d-flex flex-column justify-content-between"
          >
            <section className="d-flex flex-column gap-2">
              <h2>{value.category}</h2>

              <div className="d-flex flex-wrap gap-1">
                {weoffer?.offer.map((item, index) => {
                  return (
                    <Box
                      sx={{
                        width: "max-content",
                      }}
                      key={index}
                      className="d-flex rounded rounded-pill  justify-content-center gap-2 py-2 px-3 bg-hover align-items-center"
                    >
                      {!item.selected ? (
                        <CheckBoxOutlineBlankIcon
                          onClick={() => addtolist(item.name, index)}
                        />
                      ) : (
                        <CheckBoxIcon
                          onClick={() => addtolist(item.name, index)}
                        />
                      )}
                      <span>{item.name}</span>
                    </Box>
                  );
                })}
              </div>
              <div className="d-flex flex-wrap gap-1 my-2">
                {list?.map((item, index) => {
                  return (
                    <Chip
                      label={item}
                      key={index}
                      size="small"
                      variant="outlined"
                      onDelete={() => addtolist(item, index)}
                    />
                  );
                })}
              </div>
            </section>
            <Box
              sx={{
                marginTop: "auto",
              }}
              className="d-flex justify-content-end gap-2 mt-auto"
            >
              <button className="btn btn-dark" onClick={handleClose}>
                cancel
              </button>
              <button className="btn btn-primary" onClick={addnewoffers}>
                Save
              </button>
            </Box>
          </Box>
        </Modal>
      </div>
    </Suspense>
  );
};

export default BusinessIntroduction;
