import { Box } from "@mui/material";
import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendRentalMessageAction } from "../../context/actions/rentalAction";

const ChatBox = ({ user_data, property_id }) => {
  const recieverId = user_data?._id;
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isAuth } = useSelector((state) => state.auth_slice);

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const email = data.get("email");
    const phone = data.get("phone");
    const message = data.get("message");

    const formData = {
      documentId: property_id,
      sender_email: email,
      sender_phone: phone,
      sender_message: message,
    };

    dispatch(sendRentalMessageAction({ formData, navigate, toast }));


  };

  return (
    <Box className="bg-hover p-3 rounded rounded-3 d-flex flex-column gap-2">
      <div className="d-flex align-items-center gap-2">
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${user_data?.profilepic}`}
          alt="profile"
          className="rounded-circle object-cover"
          height="50"
          width="50"
        />

        <div className="user-info">
          <p className=" fw-bold">
            {user_data?.firstname} {user_data?.lastname}
          </p>
          <span>Property Owner</span>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          name="email"
          placeholder="Enter your email"
          className="form-control"
          required={true}
        />
        <input
          onChange={(e) => setPhone(e.target.value)}
          type="text"
          name="phone"
          placeholder="Enter your phone number"
          className="form-control"
          required={true}
        />
        <textarea
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          rows="5"
          placeholder="Type your message here..."
          className="w-100"
          class="form-control"
          value={`Hi ${user_data?.firstname}, I am interested in your property. Please get in touch with me. Thanks & Regards`}
        ></textarea>

        {isAuth ? (
          <button className="btn btn-black w-100">Send Messagess</button>
        ) : (
          <button
            className="btn btn-primary w-100"
            onClick={() => navigate("/login")}
          >
            Login to Send Message
          </button>
        )}
      </form>
    </Box>
  );
};

export default ChatBox;
