import { createAsyncThunk } from "@reduxjs/toolkit";

import * as API from "../../api/adsApi";

// import API from "../../api/common_api";




export const getAllHomeAdsAction = createAsyncThunk(
  "getAllAdsaction",
  async (__,{ rejectWithValue }) => {
    console.log("hhh");
    try {
      const response = await API.getAllAdsBanner();
     
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
