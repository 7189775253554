import { Box, Chip } from "@mui/material";
import React from "react";
import { ImCross } from "react-icons/im";
import { FormInputB, SelectCountry } from "../../../../../components/Index";

const AddStudentModalRequirements = ({
  onClose,
  documentType,
  addToList,
  editvalue,
  onEditSave,
  requirementType,
}) => {
  const formvalues = {
    documentType: documentType,
    title: editvalue.title,
    description: editvalue.description,
    filepath: editvalue.files,
    requirementType: requirementType,
  };

  const formerrors = {
    title: "",
    description: "",
    filepath: "",
  };
  const [formvalue, setFormValue] = React.useState(formvalues);
  const [errors, setErrors] = React.useState(formerrors);
  const allfiles = React.useRef(null);
  const handleChange = (e) => {
    setFormValue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const removefile = (index) => {
    let newfilearray = [...formvalue.filepath];

    newfilearray = newfilearray
      .slice(0, index)
      .concat(newfilearray.slice(index + 1));

    // Update the file input with the new file list
    const files = allfiles.current.files;
    const updatedFiles = Array.from(files);
    updatedFiles.splice(index, 1);

    // Update the file input with the new file list
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach((file) => {
      dataTransfer.items.add(file);
    });
    allfiles.current.files = dataTransfer.files;

    setFormValue({ ...formvalue, filepath: newfilearray });
  };

  const onSelectedFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    let newfilearray = [...formvalue.filepath];

    for (let i = 0; i < selectedFilesArray.length; i++) {
      const file = selectedFilesArray[i];
      if (file.size <= 5 * 1024 * 1024) {
        // Check if file size is less than or equal to 5MB
        newfilearray.push(file);
      } else {
        const files = allfiles.current.files;
        const updatedFiles = Array.from(files);
        updatedFiles.splice(i, 1);

        // Update the file input with the new file list
        const dataTransfer = new DataTransfer();
        updatedFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
        allfiles.current.files = dataTransfer.files;
        setErrors({ ...errors, filepath: "max size 5mb allowed *" });
      }
    }

    setFormValue({ ...formvalue, filepath: newfilearray });
  };

  const [list, setList] = React.useState(editvalue.description);
  const itemRefs = React.useRef([]);

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter" && list[index].text.length > 0) {
      event.preventDefault();
      if (index < list.length - 1) {
        focusInput(list.length - 1);
      } else {
        addTextToList();
      }
    } else if (
      event.key === "Backspace" &&
      event.target.value === "" &&
      list.length > 1
    ) {
      event.preventDefault();
      removeEmptyListItem();
    }
  };

  const addTextToList = () => {
    const newItem = { text: "" };
    setList([...list, newItem]);
  };

  const removeEmptyListItem = () => {
    const lastItem = list[list.length - 1];
    if (lastItem && lastItem.text === "") {
      setList(list.slice(0, -1));
      focusInput(list.length - 2);
    }
  };

  const focusInput = React.useCallback(
    (index) => {
      if (index >= 0 && itemRefs.current[index]) {
        itemRefs.current[index].focus();
      }
    },
    [itemRefs]
  );

  React.useLayoutEffect(() => {
    const lastIndex = list.length - 1;
    const lastItem = list[lastIndex];
    if (lastItem && lastItem.text === "") {
      focusInput(lastIndex);
    }
  }, [list.length, focusInput, list]);

  const handleItemChange = (event, index) => {
    const updatedList = [...list];
    updatedList[index].text = event.target.value;
    setList(updatedList);
  };

  const formvalidation = () => {
    let newerrors = { ...formerrors };
    let isValid = true;
    if (list.length === 0 || !list[0]?.text) {
      newerrors.description = "Please provide descriptions *";
      isValid = false;
    }
    if (!formvalue.title) {
      newerrors.title = "Please provide title * ";
      isValid = false;
    }

    setErrors(newerrors);
    return isValid;
  };

  const onSubmit = () => {
    const isValid = formvalidation();
    if (isValid) {
      const newform = {
        title: formvalue.title,
        name: documentType,
        attachment: formvalue.filepath,
        description: list,
      };

      if (editvalue.isEdit) {
        onEditSave(newform);
      } else {

        // dispatch form here
        addToList(newform);
      }
    }
  };

  return (
    <div className="d-flex flex-column gap-2 position-relative">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <span className="fs-5 fw-bold text-black">
            Adding New <span className="text-danger">{documentType}</span> Requirement  
          </span>
          <small>
            Commonly required by the university and immigration authorities
          </small>
        </div>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          role="button"
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </div>

      <FormInputB
        handleChange={handleChange}
        label={"Create title"}
        name={"title"}
        type={"text"}
        value={formvalue.title}
        placeholder={"Ex : Relation Documents"}
        error={errors.title}
        helpertext={errors.title}
      />
      <div className="">
        <label htmlFor="">Describe requirement in point basis</label>
        <Box
          className={
            errors.description
              ? "form-control p-2 is-invalid "
              : "form-control p-2"
          }
        >
          <ol className={list.length === 1 ? "list-unstyled" : ""}>
            {list.map((item, index) => (
              <li key={index}>
                <input
                  placeholder={index === 0 ? "Enter Description" : ""}
                  type="text"
                  className="input-field-no-border w-100 bg-transparent text-black"
                  value={item.text}
                  ref={(el) => (itemRefs.current[index] = el)}
                  onChange={(event) => handleItemChange(event, index)}
                  onKeyDown={(event) => handleKeyPress(event, index)}
                />
              </li>
            ))}
          </ol>
        </Box>

        <small className={errors.description ? "form-text text-danger" : ""}>
          {errors.description}
        </small>
      </div>
      <div className="d-flex flex-column gap-2">
        <h6 className="m-0">Sample Attachment (Optional)</h6>
        <div className="form-group d-flex flex-column m-0 p-0">
          <input
            ref={allfiles}
            type="file"
            name="filepath"
            id="filepath"
            multiple
            onChange={onSelectedFile}
            className="form-control p-2 "
          />
          <small className={errors.filepath ? "form-text text-danger" : ""}>
            {errors.filepath}
          </small>
        </div>
      </div>
      <div className="d-flex align-items-center gap-1 flex-wrap">
        {formvalue.filepath.map((item, index) => {
          return (
            <Chip
              label={item.name}
              key={index}
              onDelete={() => removefile(index)}
            />
          );
        })}
      </div>
      <div className="d-flex justify-content-end w-100 position-sticky top-100 start-0 ">
        <button
          className="btn-dark btn ms-auto justify-self-end "
          onClick={onSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddStudentModalRequirements;
