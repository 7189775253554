import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box } from "@mui/system";
import { MdEditSquare } from "react-icons/md";
import ProfessionalMContact from "../modals/ProfessionalMContact";
import { Modal } from "@mui/material";
const ProfessionalContacts = ({ professional }) => {
  const [open, setOpen] = React.useState(false);

  const handleModal = () => {
    setOpen((prev) => !prev);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "95%", md: "70%", lg: "50%" },
    bgcolor: "var(--secondary-color)",
    borderRadius: "15px",

    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "100%", lg: "100%" },
        }}
        className="d-flex   align-items-center justify-content-between "
      >
        <h5 className="text-black fw-bold">Contact details</h5>
        <Box
          className="d-flex justify-content-center align-items-center  rounded-circle"
          role="button"
          sx={{
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={handleModal}
        >
          <MdEditSquare />
        </Box>
      </Box>

      <section className="row-cols-lg-2 row-cols-1 row gy-2">
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <EmailIcon />
              <span>Email Address</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.email}</span>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <PhoneIcon />
              <span>Phone Number</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.phone}</span>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <PublicIcon />
              <span>Personal Website</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.socialMediaLinks?.personalLink}</span>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <FacebookIcon />
              <span>FaceBook Link</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.socialMediaLinks?.facebookLink}</span>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <TwitterIcon />
              <span>Twitter Link</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.socialMediaLinks?.twitterLink}</span>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-between gap-1">
            <div className="d-flex gap-1 align-items-center">
              <InstagramIcon />
              <span>Instagram Link</span>
            </div>
            <span>:</span>
          </div>
        </div>
        <div className="col-lg-8">
          <span>{professional?.socialMediaLinks?.instagramLink}</span>
        </div>
      </section>

      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ProfessionalMContact onClose={handleModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default ProfessionalContacts;
