import TuneIcon from "@mui/icons-material/Tune";
import { Box, Divider, Modal, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { OrganizationCard, Searchbar } from "../../components/Index";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import { getorganizationAction } from "../../context/actions/organizationAction";
import OrgSmFilter from "./component/OrgSmFilter";
import Orgfilter from "./component/Orgfilter";
import OrganizationCardSkeleton from "./component/OrganizationCardSkeleton";

const AllOrg = () => {
  // get the query params
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("search")?.trim() || "";
  const filter = queryParams.get("province")?.trim() || "";
  const cityfilter = queryParams.get("city")?.trim() || "";
  const categoryfilter = queryParams.get("category")?.trim() || "";

  // intialize the the filter options
  const filteroption = {
    sortby: "",
    city: cityfilter,
    province: filter,
    search: query,
    category: categoryfilter,
  };
  const [filtered, setfilter] = React.useState(filteroption);

  // use useState to set the initial state
  const [page, setPage] = React.useState(1);

  // use useDispatch to dispatch the action
  const dispatch = useDispatch();

  // use useCallback to memoize the function
  const get_all_blogs = useCallback(() => {
    dispatch(
      getorganizationAction({
        keyword: filtered.search,
        page,
        city: filtered.city,
        province: filtered.province,
        category: filtered.category,
      })
    )
  }, [
    dispatch,
    page,
    filtered.search,
    filtered.city,
    filtered.province,
    filtered.category,
  ]);

  // use useEffect to call the function
  useEffect(() => {
    get_all_blogs();
  }, [get_all_blogs]);

  // use useSelector to get the data from the store
  const {
    loading,
    organization,
    resultperpage,
    totalorganizationcount,
    filteredOrganizationCount,
  } = useSelector((state) => state.organization_reducer);

  // handle the change of the city
  const handlecityChange = (e, value) => {
    e.stopPropagation();
    setfilter({ ...filtered, city: value });
    const newQuery = value;
    queryParams.set("city", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // handle the change of the province
  const selectprovince = (item) => {
    setfilter({ ...filtered, province: item });
    const newQuery = item;
    queryParams.set("province", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // Handle the change of the category
  const selectcategory = (item) => {
    setfilter({ ...filtered, category: item });
    const newQuery = item;
    queryParams.set("category", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  // clear the value of the filter
  const clearvalue = (name) => {
    setfilter({ ...filtered, [name]: "" });
  };

  // handle the keypress of the search bar
  const handlekeypress = (e) => {
    if (e.key === "Enter") {
      setfilter({ ...filtered, search: e.target.value });
      const newQuery = e.target.value;
      queryParams.set("search", newQuery);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };
  // handle the change of the pagination
  const handlePagechange = (e, value) => {
    setPage(value);
  };

  // // hide the pagination if the filtered organization count is less than the result per page
  // const shouldHidePagination = filteredOrganizationCount < resultperpage;

  const [isOpen, setOpen] = React.useState(false);

  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  // return the jsx

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: 400 },
    bgcolor: "var(--hover-color)",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };

  const onSMfilter = ({ city, province, category }) => {
    setfilter({
      ...filtered,
      province: province,
      city: city,
      category: category,
    });

    queryParams.set("province", province);
    queryParams.set("city", city);
    queryParams.set("category", category);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <Box>
      <div className="row row-cols-lg-2 container w-100 mx-auto mt-3 ">
        <Box className="filter p-0 col col-lg-3 d-none d-lg-block position-sticky">
          <Orgfilter
            filtered={filtered}
            handlecityChange={handlecityChange}
            selectprovince={selectprovince}
            selectcategory={selectcategory}
          />
        </Box>
        <Box className=" d-block d-lg-none ">
          <Modal
            open={isOpen}
            onClose={toggleFilter}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <OrgSmFilter
                filtered={filtered}
                onClose={toggleFilter}
                onSMfilter={onSMfilter}
              />
            </Box>
          </Modal>
        </Box>

        <section className="col p-0 col-lg-9">
          <p className="fw-bold">Filtering</p>

          <Box className=" w-100 bg-white d-flex justify-content-between flex-column flex-md-row">
            <div className="col-auto d-flex gap-2 align-items-center">
              {filtered.province.length > 0 ? (
                <Chip
                  label={filtered.province}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("province")}
                />
              ) : (
                <Chip
                  label="All Organizations"
                  size="small"
                  variant="outlined"
                />
              )}

              {filtered.city.length > 0 ? (
                <Chip
                  label={filtered.city}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("city")}
                />
              ) : null}

              {filtered.search.length > 0 ? (
                <Chip
                  label={filtered.search}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("search")}
                />
              ) : null}

              {filtered.category.length > 0 ? (
                <Chip
                  label={filtered.category}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("category")}
                />
              ) : null}
            </div>
            <div className=" ms-md-auto mt-2 mt-md-0 d-flex align-items-center justify-content-between">
              <div className="">
                <Searchbar
                  handlekeypress={handlekeypress}
                  placeholder={"Enter Search"}
                />
              </div>
              <button
                onClick={toggleFilter}
                className="d-flex align-items-center gap-1 d-lg-none btn bg-black text-white ms-auto"
              >
                <TuneIcon /> <span>filter</span>
              </button>
            </div>
          </Box>

          <Box
            sx={{
              minHeight: "50vh",
            }}
            className="row row-cols-2 row-cols-md-3  "
          >
            {loading ? (
              <>
              {Array.from({ length: 6 }, (_, index) => (
                <OrganizationCardSkeleton index={index}/>
              ))}
            </>
            ) : organization && organization.length > 0 ? (
              organization?.map((item) => (
                <Box
                  sx={{
                    aspectRatio: { xs: "1/1.5", lg: "1/1.3" },
                  }}
                  key={item._id}
                  className="p-2 col grid-box"
                >
                  <OrganizationCard item={item} />
                </Box>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
                className="w-100"
              >
                <NoResultFound />
              </div>
            )}
          </Box>

          <Stack spacing={2} className="d-flex w-100 Provenience">
            <Pagination
              count={Math.ceil(totalorganizationcount / resultperpage)}
              page={page}
              onChange={handlePagechange}
              variant="outlined"
              className="ms-auto"
              shape="rounded"
            />
          </Stack>
        </section>
      </div>
    </Box>
  );
};

export default AllOrg;
