import WifiIcon from "@mui/icons-material/Wifi";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";

const rentalOffers = [
  {
    id: "1",
    name: "Wifi",
    icon: <WifiIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "2",
    name: "Kitchen",
    icon: <RestaurantIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "3",
    name: "Garden",
    icon: <LocalFloristIcon style={{ fontSize: "3em" }} />,
  },
];

export default rentalOffers;
