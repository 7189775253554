import React from "react";
import { Box, Checkbox } from "@mui/material";

const BusinessTermsAndCondtions = ({
  value,
  handleSubmit,
  prevstep,
  handleformvalue,
  errors,
}) => {
  return (
    <form className={" "} onSubmit={handleSubmit}>
      <h2 className="text-center my-3">Terms and Conditions</h2>
      <Box
        sx={{
          height: "40vh",
          overflowY: "scroll",
        }}
      >
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aut
          reiciendis aliquid quae voluptatem dolorem sapiente ab doloribus
          consectetur voluptatibus quis nesciunt laboriosam nobis quasi,
          aspernatur tenetur! Aliquam velit numquam illum vel unde aperiam et
          voluptates, excepturi dolores ab consectetur blanditiis cumque, quae
          iusto, placeat incidunt. Vel ipsam cum vitae magnam nemo facere,
          aliquid ea deserunt. Lorem ipsum dolor sit amet, consectetur
          adipisicing elit. Accusamus repudiandae odit ducimus accusantium, at
          ullam, perferendis a officiis atque eum deserunt explicabo, libero
          dolorum non dolor? Distinctio laudantium fuga nemo perspiciatis in,
          amet, maiores ad quas veniam repellat hic explicabo corporis fugit
          excepturi omnis eveniet officiis incidunt ipsa ut doloribus id. Culpa,
          omnis harum quasi perferendis quod tempore blanditiis libero debitis
          dolor necessitatibus aperiam amet, accusantium laudantium totam
          suscipit ad magni hic odio! Odit eveniet aliquid quidem! Minus
          voluptatem inventore, consequatur, corrupti quae in ipsa neque veniam
          quod velit fuga quam! Repudiandae architecto itaque minima laudantium.
          Officiis facilis ea, rem vitae modi omnis. Error, quis tenetur,
          numquam vero nisi itaque repellat autem voluptates quibusdam quasi nam
          at accusantium. Totam nobis reiciendis eaque earum dignissimos quae
          qui ipsa commodi impedit aperiam possimus maiores excepturi nisi eum
          iure, voluptas voluptatibus! Aliquam, possimus modi alias perferendis
          quos iure, doloremque reprehenderit expedita quaerat, soluta totam!
          Ullam rem quae tempora eius magni assumenda non earum nisi sed quam,
          harum dolorum! Vero porro quidem illo minima totam placeat architecto.
          Consectetur dolorem deserunt eius, doloribus sapiente omnis distinctio
          velit, ratione et voluptatibus possimus accusamus hic perspiciatis,
          autem ad voluptas porro provident sint itaque dolorum harum unde est.
        </p>
      </Box>
      <div className="d-flex gap-2 fs-5">
        <Checkbox
          checked={value}
          onChange={handleformvalue}
          name={"agreement"}
          id="agreement2"
          value={value}
          size="small"
          className="m-0 p-0 "
          sx={{
            "&.Mui-checked": {
              color: "#005CE6",
            },
          }}
        />

        <label htmlFor="agreement2">I agree on terms and conditions</label>
      </div>
      <small id="emailHelp" className={errors ? "form-text text-danger" : ""}>
        {errors.termsandconditions}
      </small>

      <div className=" d-flex justify-content-end gap-2  mt-3">
        <button className="bg-dark btn text-white   " onClick={prevstep}>
          Back
        </button>
        <button className="btn-dark btn" type="submit">
          Submit
        </button>
      </div>
    </form>
  );
};

export default BusinessTermsAndCondtions;
