import React from "react";
import { Box } from "@mui/material";
import { ImCross } from "react-icons/im";
import { FormTextAreaB } from "../../../components/Index";
import { editFlatemateByUser } from "../../../context/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const EditDescription = ({ onClose, description, id }) => {
  const value = {
    description: description,
  };

  const evalue = {
    description: "",
  };
  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);

  const handleFormChange = (e) => {
    const name = e.target.name;

    if (e.target.value.length < 200) {
      setFormValue({ ...formValue, [name]: e.target.value });
    }
  };

  const formValidation = () => {
    const { description } = formValue;
    let isValid = false;
    let newerror = { ...evalue };
    if (!description) {
      newerror.description = "Please Enter Description";
      newerror.message = "PLease enter required fields. ";

      setError(newerror);
    } else {
      isValid = true;
    }
    return isValid;
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    const isValid = formValidation();
    if (isValid) {
      const formData = new FormData();
      formData.append("description", formValue.description);
      dispatch(
        editFlatemateByUser({ id: id, formData: formData, toast: toast })
      );
      onClose();
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Edit Description
        </span>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>

      <FormTextAreaB
        label={"Description"}
        name={"description"}
        placeholder={"Enter Description"}
        value={formValue.description}
        helpertext={errors.description}
        error={errors.description}
        maxlength={1000}
        handleformvalue={handleFormChange}
      />

      <button
        className="btn-dark btn ms-auto justify-self-end"
        onClick={onSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default EditDescription;
