import { createAsyncThunk } from "@reduxjs/toolkit";

import * as organization_api from "../../api/organization_api";

export const getorganizationAction = createAsyncThunk(
  "organization/get_organization",
  async (
    { keyword = "", page = 0, city = "", province = "", category = "" },
    { rejectWithValue }
  ) => {
    try {
      const response = await organization_api.get_organization(
        keyword,
        page,
        city,
        province,
        category
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// get all featured organization
export const getFeaturedOrganizationAction = createAsyncThunk(
  "organization/get_featured_organization",
  async (__, { rejectWithValue }) => {
    try {
      const response = await organization_api.get_featured_organization();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


// get organization details
export const GetOrganizationDetailsAction = createAsyncThunk(
  "organization/get_organization_details",
  async (id, { rejectWithValue }) => {
    try {
      const response = await organization_api.get_organization_details(id);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// set otp for organization
export const SetOrganizationOtpAction = createAsyncThunk(
  "organization/set_otp",
  async (email, { rejectWithValue }) => {
    try {
      const response = await organization_api.send_otp(email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const VerifyOrganizationOtpAction = createAsyncThunk(
  "organization/verify_otp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await organization_api.verify_otp(data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const CreateOrgAction = createAsyncThunk(
  "auth/create_org",
  async ({ value, navigate, toast }, { rejectWithValue }) => {
    const {
      members,
      filelst,
      description,
      email,
      postalcode,
      phonenumber,
      telephonenumber,
      profilepic,
      coverimage,
      nameofcompany,
      panfrontfile,
      panbackfile,
      fblink,
      websitelink,
      twitterlink,
      instagramlink,
      province,
      city,
      agreement,
      location_lat,
      location_lng,
      location_place_name,
      category,
    } = value;

    const formData = new FormData();

    for (let i = 0; i < filelst.length; i++) {
      formData.append("filelst", filelst[i]);
    }

    formData.append("members", JSON.stringify(members));
    formData.append("description", description);
    formData.append("email", email);
    formData.append("postalcode", postalcode);
    formData.append("phonenumber", phonenumber);
    formData.append("telephonenumber", telephonenumber);
    formData.append("nameofcompany", nameofcompany);
    formData.append("panfrontfile", panfrontfile);
    formData.append("panbackfile", panbackfile);
    formData.append("fblink", fblink);
    formData.append("websitelink", websitelink);
    formData.append("twitterlink", twitterlink);
    formData.append("instagramlink", instagramlink);
    formData.append("province", province);
    formData.append("city", city);
    formData.append("agreement", agreement);
    formData.append("profilepic", profilepic);
    formData.append("coverimg", coverimage);
    formData.append("location_lat", location_lat);
    formData.append("location_lng", location_lng);
    formData.append("location_place_name", location_place_name);
    formData.append("category", category);

    try {
      const response = await organization_api.register_org(formData);
      toast.success("Request sent to admin");
      navigate("/profile");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export default {
  GetOrganizationDetailsAction,
  getorganizationAction,
  SetOrganizationOtpAction,
};
