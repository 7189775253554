import React from "react";
import { Box } from "@mui/material";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GeoCoder from "./GeoCoder";

const AddLocation = ({ handleLocation }) => {
  return (
    <>
      <Box
        sx={{
          height: 300,
          position: "relative",
        }}
      >
        <ReactMapGL
          mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
          initialViewState={{
            latitude: 37.7577,
            longitude: -122.4376,
            zoom: 8,
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          <Marker
            latitude={37.77}
            longitude={-122.4376}
            draggable
            onDragEnd={(event) => handleLocation(event.lngLat)}
          />

          <NavigationControl position="bottom-right" showCompass="true" />
          <GeolocateControl
            position="top-left"
            trackUserLocation
            
          />

          <GeoCoder handleLocation={handleLocation} />
        </ReactMapGL>
      </Box>
    </>
  );
};

export default AddLocation;
