import React from "react";
import { StudentCard } from "../../components/Index";
import { Box, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { GetAllCountry } from "../../context/actions/abordStudyAction";

const StudentAll = () => {

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GetAllCountry());
  }, [dispatch]);

  const { countries } = useSelector((state) => state.abroad_study_reducer);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        paddingBottom: "3vh",
      }}
      className="container d-flex flex-column gap-3"
    >
       <div className="d-flex flex-column mt-3">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Abroad</span> Study
          </span>
          <small>
            Showing {countries.length} countries on ONF Canada
          </small>
        </div>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(3, 1fr)",
            md: "repeat(3, 1fr)",
            lg: "repeat(4, 1fr)",
          },
          rowGap: { xs: "1%", sm: "2%", lg: "2.5%" },
          columnGap: { xs: "1%", sm: "2%", lg: "2%" },
        }}
      >
        {countries?.map((country, index) => {
          return (
            <Box
              sx={{
                aspectRatio: "1/1.2",
              }}
              className="  m-0 p-0"
              key={index}
            >

              <StudentCard country={country} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StudentAll;
