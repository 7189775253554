import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import {
  AddBoardMember,
  ContactsAndLinks,
  Introduction,
  Agreements,
} from "./components";
import { useDispatch, useSelector } from "react-redux";
import { CreateOrgAction } from "../../context/actions/organizationAction";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../context/reducers/organizationSlice";

const UpgradeForm = ({ coverimg, profilepic, setpicError, setuserInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // set erros
  const [errors, setErrors] = React.useState({});
  // form steps
  const steps = [
    "Introduction",
    "Contacts and links",
    "board members",
    "Agreements",
  ];

  // form  values
  const formvalue = {
    description: "",
    email: "",
    postalcode: "",
    phonenumber: "",
    telephonenumber: "",
    nameofowner: "",
    nameofcompany: "",
    members: [],
    location: "",
    panfront: "",
    panback: "",
    panfrontfile: "",
    panbackfile: "",
    fblink: "",
    linkedinlink: "",
    twitterlink: "",
    instagramlink: "",
    websitelink: "",
    province: "",
    city: "",
    agreement: false,
    establishedDate: "",
    category: "",
    address: "",
  };

  const [value, setValue] = React.useState(formvalue);

  // useSelector to get the value from organization_reducer
  const { createOrganizationLoading, error } = useSelector((state) => ({
    error: state.organization_reducer.error,
    createOrganizationLoading:
      state.organization_reducer.createOrganizationLoading,
  }));

 

  // useEffect to get error

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  const [activestepper, setActiveStepper] = React.useState(0);

  const introductionValidate = () => {
    let isValid = true;
    let newerrors = {};
    if (
      !value.nameofcompany ||
      value.nameofcompany.length < 5 ||
      value.description.length < 200 ||
      !value.category ||
      !value.description ||
      !value.province ||
      !value.city ||
      !value.establishedDate ||
      !value.postalcode ||
      !value.panfrontfile ||
      !value.panbackfile
    ) {
      if (!value.nameofcompany) {
        newerrors.nameofcompany = "Please provide name of company *";
      }

      if (value.nameofcompany.length < 5) {
        newerrors.nameofcompany =
          "Name of company length should be more than 4";
      }
      if (!value.category) {
        newerrors.category = "Please provide category *";
      }
      if (!value.description) {
        newerrors.description = "Please provide description *";
      }

      if (value.description.length < 200) {
        newerrors.description =
          "Either description is empty or less than 200 characters *";
      }
      if (!value.province) {
        newerrors.province = "Please provide province *";
      }
      if (!value.city) {
        newerrors.city = "Please provide city *";
      }
      if (!value.establishedDate) {
        newerrors.establishedDate = "Please provide established date *";
      }
      if (!value.postalcode) {
        newerrors.postalcode = "Please provide postal code *";
      }
      if (!value.panfrontfile) {
        newerrors.panfrontfile = "Please provide pan front *";
      }
      if (!value.panbackfile) {
        newerrors.panbackfile = "Please provide pan back *";
      }
      newerrors.message = "PLease enter required fields. *";
      isValid = false;
    }
    setErrors(newerrors);
    return isValid;
  };

  const contactValidate = () => {
    let isValid = true;
    let newerrors = {};
    var pattern = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    // Regular expression pattern for a valid phone number
    const phonePattern = /^\d{10}$/;

    const cleanedNumber = value.phonenumber.replace(/\D/g, "");
    const istel = phonePattern.test(value.telephonenumber);
    // Check if the cleaned number matches the pattern
    let isPhone = phonePattern.test(cleanedNumber);
    let isemail = pattern.test(value.email);

    if (!isemail || !isPhone || !istel || !value.location || !value.email) {
      if (!isemail) {
        newerrors.email = "Please provide valid email *";
      }
      if (!value.email) {
        newerrors.email = "Please provide valid email *";
      }
      if (!isPhone) {
        newerrors.phonenumber = "Please provide valid phonenumber *";
      }
      if (!istel) {
        newerrors.telephonenumber = "Please provide valid telephonenumber *";
      }
      if (!value.location) {
        newerrors.location = "Please provide location *";
      }
      newerrors.message = "PLease enter required fields. *";
      isValid = false;
    }
    setErrors(newerrors);
    return isValid;
  };

  // handle form value on input change
  const handleformvalue = (e) => {
    const name = e.target.name;
    switch (name) {
      case "province":
        setValue({ ...value, province: e.target.value, city: "" });
        break;

      case "address-search":
        setValue({ ...value, address: e.target.value });
        break;

      case "panfront":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panfrontfile: file });
        }

        break;

      case "panback":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panbackfile: file });
        }
        break;

      case "agreement":
        setValue({ ...value, [e.target.name]: !value.agreement });
        break;

      default:
        setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  // if member is added or edited
  const onmemberchange = (newlist) => {
    setValue({ ...value, members: newlist });
  };

  // go to next step
  const changeStepper = () => {
    if (activestepper < 3) {
      setActiveStepper((prev) => prev + 1);
    }
  };

  // got to prev step
  const prevStepper = (e) => {
    e.preventDefault();
    if (activestepper !== 0) {
      setActiveStepper((prev) => prev - 1);
    }
  };

  // on form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (activestepper === 0) {
      const isValid = introductionValidate();
      if (isValid) {
        changeStepper();
        setuserInfo({ email: value.email, orgName: value.nameofcompany });
        window.scrollTo(0, 0);
      }
    }
    if (activestepper === 1) {
      const isValid = contactValidate();
      if (isValid) {
        changeStepper();
        setuserInfo({ email: value.email, orgName: value.nameofcompany });

        window.scrollTo(0, 0);
      }
    }
    if (activestepper === 2) {
      let newerrors = {};
      if (value.members.length < 1) {
        newerrors.members = "Please provide at least one member *";
        newerrors.message = "Invald credentials *";
        setErrors(newerrors);
      } else {
        changeStepper();
        window.scrollTo(0, 0);
      }
    }
    // const errors = validate();
    // if (Object.keys(errors).length === 0) {
    //   changeStepper();
    // }

    if (activestepper === 3) {
      let newerrors = {};

      if (!value.agreement || !profilepic) {
        if (!value.agreement) {
          newerrors.agreement = "Please agree on terms and condition *";
        }
        if (!profilepic) {
          window.scrollTo(0, 0);

          setpicError("Please provide profile pic");
        }
        newerrors.message = "Invald credentials *";
        setErrors(newerrors);
      } else {
        setpicError("");

        const newlst = structuredClone(value.members);
        const filelst = newlst.map((item) => {
          return item.image;
        });

        const members = newlst.map((item) => {
          const value = item;
          value.image = "";
          return value;
        });

        // profile pic and cover page was added to formvalue
        const values = {
          members: members,
          filelst: filelst,
          description: value.description,
          email: value.email,
          postalcode: value.postalcode,
          phonenumber: value.phonenumber,
          telephonenumber: value.telephonenumber,
          nameofowner: value.nameofowner,
          nameofcompany: value.nameofcompany,
          coverimage: coverimg,
          panfrontfile: value.panfrontfile,
          panbackfile: value.panbackfile,
          location_lat: value.location.lat,
          location_lng: value.location.lng,
          location_place_name: value.location.place_name,
          fblink: value.fblink,
          linkedinlink: value.linkedinlink,
          twitterlink: value.twitterlink,
          instagramlink: value.instagramlink,
          websitelink: value.websitelink,
          province: value.province,
          city: value.city,
          agreement: value.agreement,
          profilepic: profilepic,
          category: value.category,
          postalcode: value.postalcode,
        };

        dispatch(CreateOrgAction({ value: values, toast, dispatch, navigate }));
      }
    }
  };

  const handleLocation = (location) => {
    setValue((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[1],
        lng: location.geometry.coordinates[0],
        place_name: location.place_name,
      },
    }));
  };
  return (
    <div>
      <Box>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{
            textAlign: "center",
          }}
        >
          Upgrade Organization
        </Typography>

        <Stepper activeStep={activestepper} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className="steppers mt-3 px-lg-5">
          {/* step 1 = add basic info and upload pan document */}
          <Introduction
            value={value}
            handleformvalue={handleformvalue}
            activestepper={activestepper}
            handleSubmit={handleSubmit}
            changeStepper={changeStepper}
            errors={errors}
          />

          {/* step 2 = add contacts and links */}
          <ContactsAndLinks
            handleLocation={handleLocation}
            handleSubmit={handleSubmit}
            activestepper={activestepper}
            handleformvalue={handleformvalue}
            prevStepper={prevStepper}
            errors={errors}
            value={value}
          />

          {/* step 3 = add or edit board members */}
          <AddBoardMember
            value={value}
            activestepper={activestepper}
            prevStepper={prevStepper}
            onmemberchange={onmemberchange}
            handleSubmit={handleSubmit}
            errors={errors.members}
          />

          {/* step 4 agree on terms and conditions */}
          <Agreements
            value={value}
            activestepper={activestepper}
            handleSubmit={handleSubmit}
            prevStepper={prevStepper}
            handleformvalue={handleformvalue}
            errors={errors.agreement}
          />
        </div>
      </Box>
    </div>
  );
};

export default UpgradeForm;
