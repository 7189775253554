import React from "react";

import { Box } from "@mui/material";

const ProfilePic = React.memo(({ image, id, onfilesave, errors }) => {
  const options = {
    isedit: false,
    preview: null,
    coverimg: null,

    file: "",
  };

  const [option, setoptions] = React.useState(options);
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      setoptions({
        ...option,
        preview: blobURL,
        file: e.target.files[0],
        isedit: true,
      });
      onfilesave(e.target.files[0]);
      e.target.value = "";
    }
  };

  return (
    <div className="profilepic  position-relative ">
      <Box
        className="profilepic position-relative  "
        sx={{
          height: "calc(50px + 7vw)",
          width: "calc(50px + 7vw)",
          maxHeight: "180px",
          maxWidth: "180px",
          cursor: "pointer",
          margin: "auto",

          border: errors ? "10px solid #f75c5c" : "10px solid #f1f1f1",

          "&:hover > #child-element ": {
            visibility: "visible",
            height: "50%",
          },
          borderRadius: "50%",

          overflow: "hidden",
        }}
      >
        <img
          alt="Profile"
          src={option.preview ?? option.coverimg ?? image}
          className="object-cover object-center w-100 h-100"
        />

        <Box
          className="bg-black hover-section w-100 text-white d-flex  align-items-center justify-content-center fw-bold bg-opacity-50 position-absolute bottom-0  start-0  w-100"
          sx={{
            borderBottomLeftRadius: "300px",
            borderBottomRightRadius: "300px",
            visibility: "hidden",
            cursor: "pointer",
            height: "0",
            transition: "height 1s ",
          }}
          id="child-element"
        >
          <label
            htmlFor={id}
            className="h-100 w-100 d-flex align-items-center justify-content-center  "
            role="button"
          >
            Edit
          </label>
          <input
            type="file"
            accept="image/*"
            className="d-none"
            id={id}
            onChange={onEdit}
          />
        </Box>
      </Box>
    </div>
  );
});

export default ProfilePic;
