import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/notificationApi";

// create notification
export const adminCreateNotification = createAsyncThunk(
  "admin/create_notification",
  async ({ data, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.createNotification(data);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// get all notification
export const adminGetAllNotification = createAsyncThunk(
  "admin/get_all_notification",
  async ({ keyword, page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.getAllNotification(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);