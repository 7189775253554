
import ProtectApi from "./adminAuthorizeApi";


// create event
export const createEvent = (data)=>ProtectApi.post("api/admin/event/create", data);

    // get all event
export const getAllEvent = (keyword = "", page = 0) =>
  ProtectApi.get(
    `api/admin/event/get_all_event?keyword=${keyword}&page=${page}`
  );

// edit event
export const editEvent = (id, data) =>
  ProtectApi.put(`api/admin/event/edit_event/${id}`, data);

 