import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconInputB, FormInputB } from "../../../components/Index";

import TwitterIcon from "@mui/icons-material/Twitter";
const ProfessionalContactAndLinks = ({
  errors,
  formvalue,
  handleFormChange,
}) => {
  return (
    <div className="d-flex flex-column gap-3 align-items-center">
      <div className="  align-self-start">
        <h5 className="text-black fw-bold">Contact Information</h5>
      </div>
      <section className="row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-5 w-100 rounded rounded-2">
        <div className="">
          <FormInputB
            name="email"
            type="email"
            label="Email Address"
            placeholder="Enter Your Email"
            value={formvalue.email}
            handleChange={handleFormChange}
            error={errors.email ? true : false}
            helpertext={errors.email}
          />
        </div>
        <div className="">
          <FormInputB
            name="contact"
            type="number"
            label="Contact information"
            placeholder="613-555-5555"
            value={formvalue.contact}
            handleChange={handleFormChange}
            error={errors.contact ? true : false}
            helpertext={errors.contact}
          />
        </div>
      </section>
      <div className="row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-5 w-100  rounded rounded-2 ">
        <IconInputB
          icon={<LanguageIcon />}
          required={true}
          name={"personalLink"}
          placeholder={"enter website link"}
          label={"Personal Website"}
          value={formvalue.personalLink}
          handleChange={handleFormChange}
          error={errors.websiteLink ? true : false}
          helpertext={errors.websiteLink}
        />
        <IconInputB
          icon={<FacebookIcon />}
          required={true}
          name={"facebookLink"}
          placeholder={"enter FaceBook Link"}
          label={"FaceBook Link"}
          handleChange={handleFormChange}
          value={formvalue.facebookLink}
          error={errors.facebookLink ? true : false}
          helpertext={errors.facebookLink}
        />
        <IconInputB
          icon={<InstagramIcon />}
          required={true}
          name={"instagramLink"}
          placeholder={"enter Instagram Link"}
          label={"Instagram Link"}
          handleChange={handleFormChange}
          value={formvalue.instagramLink}
          error={errors.instagramLink ? true : false}
          helpertext={errors.instagramLink}
        />
        <IconInputB
          icon={<TwitterIcon />}
          required={true}
          name={"twitterLink"}
          placeholder={"enter Twitter Link"}
          value={formvalue.twitterLink}
          label={"Twitter Link"}
          handleChange={handleFormChange}
          error={errors.twitterLink ? true : false}
          helpertext={errors.twitterLink}
        />
      </div>
    </div>
  );
};

export default ProfessionalContactAndLinks;
