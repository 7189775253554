import React from "react";

import { DataGrid } from "@mui/x-data-grid";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Drawer } from "@mui/material";
import RentalEdit from "../../../RentalEdit/RentalEdit";
import AdminRentalDetail from "./adminRentalDetail/AdminRentalDetail";
import { CancelOutlined, CheckOutlined } from "@mui/icons-material";



const RentalTable = ({
  rental_data,
  page,
  setPage,
  resultperpage,
  totalrentalcount,
  loading,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const [isOpenDetail, setIsOpenDetail] = React.useState(false);

  const [activeIndex, setActive] = React.useState(0);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const toggleDrawerDetail = () => setIsOpenDetail((prev) => !prev);


  function formatPrice(price) {
    if (price < 1000) {
      return "$" + price.toFixed(2);
    } else if (price < 1000000) {
      return (price / 1000).toFixed(1) + "K";
    } else {
      return (price / 1000000).toFixed(1) + "M";
    }
  }

  const columns = [
    {
      field: "image",
      headerName: "Related Image",
      flex: 1,
      renderCell: (params) => {
        const { image } = params.row;
        return (
          <div style={{ display: "flex" }}>
            {image?.slice(0, 3).map((item, index) => {
              return (
                <img
                  className="col-4 object-cover  rounded-5  "
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  key={index}
                />
              );
            })}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Property Name",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
    },
    {
      field: "category",
      headerName: "Property Type",
      flex: 1,
    },
    {
      field: "Views",
      headerName: "Views",
      type: "number",
      flex: 1,
    },

    {
      field: "price",
      headerName: "Pricing",
      type: "number",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      type: "number",
      flex: 1,
    },

    {
      field: "featured",
      headerName: "Featured",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <div className="d-flex align-items-center gap-2">
            <Box
              sx={{
                height: "40px",
                width: "40px",
              }}
              className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
            >
              <VisibilityIcon
                onClick={() => {
                  setActive(id);
                  toggleDrawerDetail();
                }}
                role="button"
              />
            </Box>
          </div>
        );
      },
    },
    // Add other columns as needed
  ];

  const mappedRows = rental_data?.map((rental, index) => ({
    id: index,
    name: rental?.title,
    location: rental?.location?.place_name,
    category: rental?.houseType,
    price: rental?.price,
    image: rental?.images,
    Views: rental?.viewCount,
    featured: rental?.featured,
    active: rental?.active,
  }));
  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          page={page}
          loading={loading}
          pageSize={resultperpage}
          rowCount={totalrentalcount}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <RentalEdit
          data={rental_data[activeIndex]}
          closeModal={toggleDrawer}
          activeindex={activeIndex}
          id={rental_data[activeIndex]?._id}
        />
      </Drawer>
      <Drawer anchor="right" open={isOpenDetail} onClose={toggleDrawerDetail}>
        <AdminRentalDetail
          data={rental_data[activeIndex]}
          closeModal={toggleDrawerDetail}
          activeindex={activeIndex}
          id={rental_data[activeIndex]?._id}
        />
      </Drawer>
    </div>
  );
};

export default RentalTable;
