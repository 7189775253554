import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navbar } from "../../components/Index";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";

const SharedNavLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <Navbar />
      <div className="children">
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default SharedNavLayout;
