import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  BsArrowUpSquare,
  BsCheckCircle,
} from "react-icons/bs";
import { TbPigMoney } from "react-icons/tb";
import {
  ChatBox,
  FeatureChip,
  LookingForCard
} from "../../components/Index";

import {
  RemoveRedEye,
  Share,
} from "@mui/icons-material";
import FlagIcon from "@mui/icons-material/Flag";
import moment from "moment";
import {
  MdGroup,
  MdOutlineBathroom,
  MdOutlineBedroomChild,
  MdOutlineBookmarkAdd,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { getFlatemateDetailAction } from "../../context/actions/rentalAction";

import BookmarkIcon from "@mui/icons-material/Bookmark";
// FaWifi
import { FaWifi } from "react-icons/fa";
// RiParkingBoxLine
import { RiParkingBoxLine } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { post_bookmark } from "../../context/actions/authAction";
import FlatmateDetailSkeletion from "./FlatmateDetailSkeletion";


const FlateMateDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
 
  const [showMore, SetshowMore] = React.useState(false);

    const [type, setType] = React.useState("Flatemate");
    const [bookmarked, setbookmarked] = React.useState(false);
  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };
  useEffect(() => {
    dispatch(getFlatemateDetailAction(id));
  }, [dispatch, id]);

  const { flatemate_detail, isAuth, bookmarks, loading } = useSelector(
    (state) => ({
      flatemate_detail: state.rental_reducer.flatemate_detail,
      loading: state.rental_reducer.loading,
      isAuth: state.auth_slice.isAuth,
      bookmarks: state.auth_slice.user_data?.bookmarks || [],
    })
  );

  // get the bookmarked data

  // check if the bookmark is already there
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  // // set the bookmarked state
  React.useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  // set the type of bookmark to initiate the bookmark
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
    teamUp: false,
  });

  // set the bookmark
  React.useEffect(() => {
    if (flatemate_detail && bookmark.id !== flatemate_detail._id) {
      setBookmark({
        name: flatemate_detail.title,
        type: type,
        image: flatemate_detail?.userPic,
        teamUp: flatemate_detail?.teamUp,
        location: flatemate_detail?.preferedLocations[0]
      });
    }
  }, [flatemate_detail, bookmark.id, type]);

    const togglebookmark = () => {
      if (isAuth) {
        dispatch(post_bookmark({ id, data: bookmark }));
      } else {
        navigate("/login");
      }
    };

  if (loading) {
    return <FlatmateDetailSkeletion />;
  }

  return (
    <Box
      className="container d-flex flex-column gap-3 mt-2"
      sx={{
        minHeight: "100vh",
      }}
    >
      <section>
        <Box
          className="bg-hover rounded rounded-5 d-flex justify-content-center"
          sx={{
            height: "45vh",
            width: "100%",
          }}
        >
          <img
            src={`${process.env.REACT_APP_IMAGE_PATH}${flatemate_detail?.userPic}`}
            className="object-contain h-100 "
            alt=""
            srcSet=""
          />
        </Box>
      </section>

      <section>
        <div className="d-flex justify-content-between">
          <div>
            <div className="fs-3">
              <p>
                {flatemate_detail?.firstName} {flatemate_detail?.lastName}{" "}
                <small className="fs-6">
                  {" "}
                  {Date.now() - flatemate_detail?.dob} year old
                </small>
              </p>

              <div className="d-flex gap-2 align-items-center">
                <h6 className="m-0 p-0 h-maxcontent">looking for</h6>
                <button className="btn btn-sm btn-outline-black">
                  {flatemate_detail?.lookingFor}
                </button>
              </div>
            </div>
          </div>

          <div>
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <RemoveRedEye />
                  {flatemate_detail?.viewCount}
                </div>
              </Button>
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <Share />
                  Share
                </div>
              </Button>
              {flatemate_detail?.teamUp ? (
                <Button>
                  <div className="comments d-flex text-danger align-items-center gap-1">
                    <MdGroup />
                    team-up
                  </div>
                </Button>
              ) : null}
              <Button>
                <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                  {bookmarked ? (
                    <BookmarkIcon onClick={togglebookmark} role="button" />
                  ) : (
                    <MdOutlineBookmarkAdd
                      onClick={togglebookmark}
                      role="button"
                    />
                  )}
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </section>

      <div className="two-section row-cols-1 gy-3 row-cols-lg-2 row w-100 justify-content-between  p-0 m-0">
        <div className="left-section col-lg-8 d-flex flex-column gap-3 m-0 p-0 ">
          <section className="row row-cols-1 gy-3 row-cols-lg-3 row-cols-md-2 position-relative m-0 p-0">
            <div className="row row-cols-2  align-items-center ">
              <TbPigMoney
                style={{
                  fontSize: "4.5em",
                  // color: "var(--primary-color)",
                }}
                className="col-3"
              />
              <div className="d-flex flex-column col-8">
                <Typography variant="p" sx={{}}>
                  Budget
                </Typography>
                <p>
                  <strong>{flatemate_detail?.price}</strong>/week
                </p>
              </div>
            </div>

            <div className="row row-cols-2  align-items-center ">
              <BsCheckCircle
                style={{
                  fontSize: "4.5em",
                  // color: "var(--primary-color)",
                }}
                className="col-3"
              />
              <div className="d-flex flex-column col-8">
                <Typography variant="p" sx={{}}>
                  Length of stay
                </Typography>
                <p>
                  <strong>{flatemate_detail?.lengthOfStay}</strong>
                </p>
              </div>
            </div>
            <div className="row row-cols-2 align-items-center  ">
              <BsArrowUpSquare
                style={{
                  fontSize: "4.5em",
                  // color: "var(--primary-color)",
                }}
                className="col-3"
              />
              <div className="d-flex flex-column col-8">
                <Typography variant="p" sx={{}}>
                  Move in date
                </Typography>
                <p>
                  <strong>
                    {" "}
                    {moment(flatemate_detail?.moveInDate).format(
                      "MMMM Do YYYY"
                    )}
                  </strong>
                </p>
              </div>
            </div>
          </section>
          <hr className="mt-1 mb-1" />
          <section>
            <p className="fw-bold fs-5">About me</p>
            {showMore
              ? flatemate_detail?.description
              : flatemate_detail?.description?.slice(0, 400)}
            {flatemate_detail?.description?.length > 400 ? (
              !showMore ? (
                <span
                  onClick={toggleShowMore}
                  className="text-black fw-bold"
                  role="button"
                >
                  ...ReadMore
                </span>
              ) : (
                <span
                  onClick={toggleShowMore}
                  className="text-black fw-bold"
                  role="button"
                >
                  ...ReadLess
                </span>
              )
            ) : null}

            <div className="d-flex gap-1 flex-wrap mt-2">
              {flatemate_detail?.features?.map((item) => {
                return <FeatureChip name={item} />;
              })}
            </div>
          </section>
          <hr className="mt-1 mb-1" />

          <section>
            <p className="fw-bold fs-5">Preferred Accomodation type</p>
            <div className="row-cols-1 row-cols-lg-3 row gx-3 row-cols-md-2 gy-2">
              {flatemate_detail?.accomondationType?.map((item) => {
                return (
                  <div className="">
                    <LookingForCard key={item} name={item} />
                  </div>
                );
              })}
            </div>
          </section>

          <section>
            <p className="fw-bold fs-5">Property Preferences</p>

            <div className="row-cols-lg-3 row-cols-md-2 row row-cols-1 gy-2 gx-1">
              <div className="d-flex gap-5 mt-3 w-100">
                <div className="d-flex gap-2">
                  <MdOutlineBedroomChild size={50} />
                  <div>
                    <p className="m-0">Room furnishing</p>
                    <h5 className="fw-bold">
                      {flatemate_detail?.roomFurnishings}
                    </h5>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <FaWifi size={50} />
                  <div>
                    <p className="m-0">Internet</p>
                    <h5 className="fw-bold">{flatemate_detail?.internet}</h5>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <MdOutlineBathroom size={50} />
                  <div>
                    <p className="m-0">Bathroom</p>
                    <h5 className="fw-bold">
                      {flatemate_detail?.bathRoomType}
                    </h5>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <RiParkingBoxLine size={50} />
                  <div>
                    <p className="m-0">Parking</p>
                    <h5 className="fw-bold">{flatemate_detail?.parking}</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p className="fw-bold fs-5">Preferred Locations</p>
            <div className="d-flex flex-wrap gap-2">
              {flatemate_detail?.preferedLocations?.map((item, index) => {
                return (
                  <div
                    className="border d-flex align-items-center border-2 rounded rounded-pill px-3 py-1"
                    key={index}
                  >
                    <PlaceOutlinedIcon />
                    <span>{item}</span>
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="right-section col-lg-4 ">
          <ChatBox user_data={flatemate_detail?.user} />
          <div
            role="button"
            className="d-flex align-items-center gap-1 justify-content-center"
          >
            <span>
              <FlagIcon />
            </span>
            <span className="text-decoration-underline text-black">
              Report this listing
            </span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default FlateMateDetails;
