import ProtectApi from "./adminAuthorizeApi";

// get all conact us

export const adminGetAllContactUs = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/contact/get_all_contact?keyword=${keyword}&page=${page}`
  );

  //change statusand message of contact us
export const adminChangeStatusContactUs = (id, data) =>ProtectApi.put(`/api/admin/contact/change_status_and_message/${id}`, data);
