import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Modal } from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import AddStudentModalRequirements from "../AddStudentModalRequirements";
import AccordionRequirementCard from "../components/AccordionRequirementCard";
const AddBeforeRequirements = ({
  onEdit,
  list,
  country,
  editvalue,
  requirementType,
  onEditSave,
  addToList,
  open,
  handleClose,
  closeModal,
  reviews,
  handleCountryRequirement,
}) => {
  const tabs = [
    "Common",
    "Government",
    "Financial",
    "Other Document",
  ];

  const [documentType, setDocumentType] = React.useState("Common");
  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", sm: "90%", md: "70%", lg: "50%" },
    bgcolor: "background.paper",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  // change tabs
  const handleChange = (event, newValue) => {
    setDocumentType(newValue);
  };

  const commonList = React.useMemo(() => {
    return list?.filter((item) => item?.name === "Common");
  }, [list]);


  const financialList = React.useMemo(() => {
    return list?.filter((item) => item?.name === "Financial");
  }, [list]);
  const governmentList = React.useMemo(() => {
    return list?.filter((item) => item?.name === "Government");
  }, [list]);
  const otherDocumentsList = React.useMemo(() => {
    return list?.filter((item) => item?.name === "Other");
  }, [list]);

  return (
    <div>
      <TabContext value={documentType}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{
              background: "#F6F6F6",
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  indicatorcolor={"transparent"}
                  className="text-capitalize"
                  sx={{
                    "&.Mui-selected": {
                      color: "#797979",
                      fontWeight: "bolder",
                      border: "none",
                    },
                    "&.MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  label={item}
                  key={index}
                  value={item}
                />
              );
            })}
          </TabList>
        </Box>
        <div className="d-flex w-100 align-items-center justify-content-between mt-2">
          <h5 className="text-black-50 fw-bold text-decoration-underline">
            Applying for {country}
          </h5>
          <button className="btn btn-outline-black" onClick={handleClose}>
            Add New
          </button>
        </div>
        <TabPanel
          value="Common"
          className="d-flex flex-column gap-2 m-0 p-0 mt-3"
        >
          {commonList?.length > 0 &&
            commonList?.map((item, index) => {
              return (
                <div key={index}>
                  <AccordionRequirementCard
                    item={item}
                    index={index}
                    onEdit={onEdit}
                  />
                </div>
              );
            })}
        </TabPanel>
        <TabPanel
          className="d-flex flex-column gap-2 m-0 p-0"
          value="Government"
        >
          {governmentList.length > 0 &&
            governmentList?.map((item, index) => {
              return (
                <div className="" key={index}>
                  <AccordionRequirementCard
                    item={item}
                    index={index}
                    onEdit={onEdit}
                  />
                </div>
              );
            })}
        </TabPanel>
        <TabPanel
          className="d-flex flex-column gap-2 m-0 p-0 "
          value="Financial"
        >
          {financialList.length > 0 &&
            financialList?.map((item, index) => {
              return (
                <div className="" key={index}>
                  <AccordionRequirementCard
                    item={item}
                    key={index}
                    index={index}
                    onEdit={onEdit}
                  />
                </div>
              );
            })}
        </TabPanel>
        <TabPanel
          className="d-flex flex-column gap-2 m-0 p-0 "
          value="Other Document"
        >
          {otherDocumentsList.length > 0 &&
            otherDocumentsList?.map((item, index) => {
              return (
                <div className="" key={index}>
                  <AccordionRequirementCard
                    item={item}
                    key={index}
                    index={index}
                    onEdit={onEdit}
                  />
                </div>
              );
            })}
        </TabPanel>
        
      </TabContext>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddStudentModalRequirements
            onClose={closeModal}
            documentType={documentType}
            addToList={addToList}
            index={editvalue.index}
            editvalue={editvalue}
            onEditSave={onEditSave}
            requirementType={requirementType}
            country={country}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddBeforeRequirements;
