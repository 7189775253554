import axios from "axios";
const API = axios.create({
  baseURL: "https://backend-host-bdsj.onrender.com",
  // baseURL: "http://localhost:5000",
  withCredentials: true,
  
  credentials: "include",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export default API;