import React from "react";
import Skeleton from "react-loading-skeleton";


const OrganizationDetailSkeleton = () => {

  return (
    <div className="container">
      <section className=" profile image-section">
        <div className="overview-header w-100  p-0">
          <div className="overview-img  w-100 ">
            <Skeleton height={200} width={'100%'} className="object-cover w-100" />
          </div>
          
        </div>
      </section>

      <div className="row">
        <div className="col-md-8">
          <section className="org-three col-lg-10 mt-5 text-hover">
            <Skeleton height={20} width={'40%'} />
            <div className="d-flex gap-5 mt-4">
              <div className="d-flex gap-2">
                <Skeleton height={40} width={40} />
                <Skeleton height={20} width={'70%'} />
              </div>
              <div className="d-flex gap-2">
                <Skeleton height={40} width={40} />
                <Skeleton height={20} width={'70%'} />
              </div>
            </div>
          </section>

          <section className="mt-4">
            <Skeleton height={20} width={'100%'} />
            <Skeleton height={20} width={'90%'} />
            <Skeleton height={20} width={'80%'} />
            <Skeleton height={20} width={'70%'} />
            <Skeleton height={20} width={'60%'} />
          </section>

          <section className="mt-4">
            <Skeleton height={20} width={'100%'} />
            <div className="row row-cols-1 row-cols-md-4 g-2">
              <div className="col">
                <Skeleton height={200} />
              </div>
              <div className="col">
                <Skeleton height={200} />
              </div>
              <div className="col">
                <Skeleton height={200} />
              </div>
              <div className="col">
                <Skeleton height={200} />
              </div>
            </div>
          </section>

          <section>
            <Skeleton height={20} width={'100%'} />
          </section>

          <section className="mt-5">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <div className="col">
                <Skeleton height={200} />
              </div>
              <div className="col">
                <Skeleton height={200} />
              </div>
              <div className="col">
                <Skeleton height={200} />
              </div>
            </div>
          </section>
        </div>

        <div className="col-md-4">
          
          <section className="mt-3">
            <Skeleton height={200} width={'100%'} />
          </section>
        </div>
      </div>
    </div>
  );
};

export default OrganizationDetailSkeleton;
