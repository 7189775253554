import { Input, Radio, Typography } from "@mui/material";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
const MultipleRadioInput = ({
  item,
  selected,
  handleFormValue,

  index,
  name,
}) => {
  const onInputClick = (value) => {
    if (selected === value) {
      handleFormValue(name, "");
    } else {
      handleFormValue(name, value);
    }
  };
  return (
    <div className="d-flex align-items-center gap-1 ">
      <Radio
        checked={ selected === item }
        onChange={() => onInputClick(item)}
        value={item}
        name={name}
        id={`${name}${index}`}
        inputProps={{ "aria-label": `A${item}` }}
        size="small"
        className="m-0 p-0 "
        sx={{
          "&.Mui-checked": {
            color: "#005CE6",
          },
        }}
      />
      <label
        htmlFor={`${name}${index}`}
        // onClick={addaccomondationType}
        className="d-flex align-items-center"
      >
        <Typography
          variant="span"
          sx={{
            fontSize: { xs: "0.7em", sm: "0.9em", lg: "1em" },
          }}
          className="text-nowrap"
        >
          {item}
        </Typography>
      </label>
    </div>
  );
};

export default MultipleRadioInput;
