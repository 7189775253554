import ProtectApi from "./adminAuthorizeApi";

export const adminCreateFlatemate = (data) =>
  ProtectApi.post("/api/admin/rental/admin_create_flatemate", data);
export const adminGetAllFlatemate = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/rental/admin_get_all_flatemate?keyword=${keyword}&page=${page}`
  );
//admin_edit_flatemate
export const adminEditFlatemate = (id, data) =>
  ProtectApi.put(`/api/admin/rental/admin_edit_flatemate/${id}`, data);


//admin_change_status_rental
export const adminChangeFlatemateStatus = (id, data) =>
  ProtectApi.put(`/api/admin/rental/admin_change_status_flatemate/${id}`, {
    active: data,
  });

  // featured flatemate

export const adminUpdateFeaturedFlatemate = (id, data) =>
  ProtectApi.put(`/api/admin/rental/admin_featured_flatemate/${id}`, {
    featured: data,
  });

