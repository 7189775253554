import React from "react";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import { GiBroccoli } from "react-icons/gi";
import { Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { featurelst } from "../../../utils/flateMateSettings";
const FeatureCard = ({ item, features, handleFormValue }) => {
  const { icon, name } = item;
  const selected = React.useMemo(
    () => features.some((value) => value === name),
    [name, features]
  );

  const addOffer = () => {
    const newfeatures = [...features];
    if (selected) {
      const index = newfeatures.indexOf(name);
      if (index > -1) {
        newfeatures.splice(index, 1);
      }
    } else {
      newfeatures.push(name);
    }
    handleFormValue("features", newfeatures);
  };

  return (
    <Box
      onClick={addOffer}
      sx={{
        height: { xs: "100px", lg: "100px" },
      }}
      role="button"
      className={
        selected
          ? "d-flex rounded rounded-3 flex-column position-relative  border-dark text-lg-start border border-2 justify-content-center align-items-start text-center p-2"
          : "d-flex rounded rounded-3 flex-column position-relative  text-lg-start border border-2 justify-content-center align-items-start text-center p-2"
      }
    >
      <div className="">{icon}</div>
      <p>{name}</p>

      <CheckCircleOutlineIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};

const AboutYourSelf = ({ errors, handleFormValue, values }) => {
  return (
    <div className="mt-3">
      <div>
        <h4 className="text-black fw-bold p-0 m-0">
          Describe yourself in a few words
        </h4>
        <small>
          This will help your flatemate to know you better and make a decision
        </small>
      </div>
      <Box
        sx={{
        }}
        className="form-group"
      >
        <textarea
          className={
            errors.description ? "form-control is-invalid" : "form-control"
          }
          id="textAreaExample"
          rows="4"
          maxLength={800}
          data-mdb-showcounter="true"
          placeholder={"Write something about yourself"}
          name={"description"}
          type="text"
          onChange={(e) => handleFormValue("description", e.target.value)}
          value={values.description}
        ></textarea>
        <div className="d-flex justify-content-between align-content-center">
          <small
            id="emailHelp"
            className={errors.description ? "form-text text-danger" : ""}
          >
            {errors.description}
          </small>
          <span
            className={
              values.description.length === 800
                ? "form-helper text-end text-danger ms-auto "
                : "form-helper text-end ms-auto"
            }
          >
            {values.description.length} / {800}
          </span>
        </div>
      </Box>

      <div>
        <h4 className="text-black fw-bold p-0 m-0">Choose your features</h4>
        {errors.features ? (
          <small className="text-danger">Choose Your Features *</small>
        ) : (
          <small>What type of person are you</small>
        )}
      </div>
      <div className="row-cols-2 row row-cols-md-3 row-cols-lg-6 gx-2 gy-2">
        {featurelst.map((item) => {
          return (
            <div className="">
              <FeatureCard
                item={item}
                key={item.id}
                features={values.features}
                handleFormValue={handleFormValue}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AboutYourSelf;
