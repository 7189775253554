import React, { useState } from "react";
import {
  CountrySelect,
  FormInput,
  FormSelectInput,
} from "../../../../../components/Index";
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Stack,
} from "@mui/material";
import { Select } from "@mui/material";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { adminRegisterAdminAction } from "../../../../../context/actions/admin/authAction";
import { clearError } from "../../../../../context/reducers/adminSlice";
import { useNavigate } from "react-router-dom";


const AdminAddAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminRoleList = ["Super Admin", "Admin"];
  function generateStrongPassword(length) {
    var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    var password = "";
    // Add at least one symbol
    var symbolIndex = Math.floor(Math.random() * symbols.length);
    password += symbols[symbolIndex];

    // Add remaining characters
    for (var i = 0; i < length - 1; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Shuffle the password to make it more secure and memorable
    password = shuffleString(password);
    return password;
  }

  function shuffleString(string) {
    var shuffledString = "";
    string = string.split("");

    while (string.length) {
      var randomIndex = Math.floor(Math.random() * string.length);
      shuffledString += string.splice(randomIndex, 1);
    }

    return shuffledString;
  }

  // Example usage
  var Randompassword = generateStrongPassword(12);
  const [password, setPassword] = React.useState(Randompassword);
  const [isRole, setRole] = React.useState("");

  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const validateForm = ({
    firstname,
    lastname,
    email,
    password,
    selectedOptions,
  }) => {
    const errors = {};
    let formIsValid = true;
    if (!isRole) {
      formIsValid = false;
      errors.role = "Role is required *";
    }
    if (!firstname) {
      formIsValid = false;
      errors.firstname = "First name is required *";
    }

    if (!lastname) {
      formIsValid = false;
      errors.lastname = "Last name is required *";
    }

    if (typeof email !== "undefined" && email !== null) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors.email = "Please enter valid email-ID *";
      }
    }

    if (!selectedOptions) {
      formIsValid = false;
      errors.country = "Country is required *";
    }

    if (typeof password !== "undefined" && password !== null) {
      if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
        formIsValid = false;
        errors.password = "Create a strong password *";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const [selectedOptions, setSelectedOptions] = useState("");
  const handleChanges2 = (event, value) => setSelectedOptions(value.label);

  const [errors, setErrors] = useState({});

    const { loading, error } = useSelector((state) => ({
      error:state.user_reducer.error,
    }));

    // use effect
    React.useEffect(() => {
      if (error) {
        toast.error(error);
      }
      dispatch(clearError());
    }, [error]);
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const firstname = data.get("firstname");
    const lastname = data.get("lastname");
    const email = data.get("email");
    const password = data.get("password");
    const role = isRole;

    // change this to your own validation
    const isValid = validateForm({
      firstname,
      lastname,
      email,
      password,
      selectedOptions,
    });

    if (isValid) {
      const data = {
        firstName: firstname,
        lastName: lastname,
        email: email,
        country: selectedOptions,
        password: password,
        role: isRole,
      };
      dispatch(adminRegisterAdminAction({ data:data, toast:toast,navigate:navigate}));
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center r"
      maxWidth="sm"
      sx={{
        minHeight: "90vh",
      }}
    >
      <h6>New Member</h6>
      <h6 className="fw-bold text-black">
        Registering New <strong className="text-danger">Admin</strong>
      </h6>
      <hr />
      <div>
        <form onSubmit={onSubmit}>
          <div className="right-side">
            <Stack spacing={2} className="d-flex flex-column gap-2">
              <div className="d-flex gap-3 w-100">
                <FormInput
                  name={"firstname"}
                  label={"First name"}
                  type={"text"}
                  placeholder={"Enter FirstName"}
                  required={true}
                  error={errors.firstname ? true : false}
                  helperText={errors.firstname}
                  classname={"w-100 "}
                />
                <FormInput
                  name={"lastname"}
                  label={"Last name"}
                  placeholder={"Enter LastName"}
                  type={"text"}
                  required={true}
                  error={errors.lastname ? true : false}
                  helperText={errors.lastname}
                  classname={"w-100"}
                />
              </div>
              <FormInput
                name={"email"}
                label={"Email Address"}
                type={"email"}
                required={true}
                error={errors.email ? true : false}
                helperText={errors.email}
                placeholder={"Enter Email"}
              />

              <FormControl
                sx={{ marginTop: 2, minWidth: 120, width: "100%" }}
                error={errors.role ? true : false}
              >
                <InputLabel id="demo-simple-select-helper-label2">
                  Admin Role
                </InputLabel>
                <Select
                  name={"role"}
                  onChange={handleRoleChange}
                  labelId={"role"}
                  id={"role"}
                  label="Role"
                  inputProps={{ "aria-label": "Without label" }}
                  required={true}
                  defaultValue={""}
                  value={isRole}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {adminRoleList.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.role && <FormHelperText>{errors.role}</FormHelperText>}
              </FormControl>

              <CountrySelect
                label={"Choose your country"}
                handleChange={handleChanges2}
                required={true}
                error={errors.country ? true : false}
                helperText={errors.country}
              />

              <TextField
                name={"password"}
                label={"Create password"}
                required={true}
                type={"password"}
                error={errors.password ? true : false}
                helperText={errors.password}
                classname={"w-100"}
                value={password}
                handleChange={handleChange}
              />

              <Button
                className="btn-dark text-capitalize "
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save and send Email
              </Button>
            </Stack>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default AdminAddAdmin;
