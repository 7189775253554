import { BsHouses } from "react-icons/bs";
import { FaSchool } from "react-icons/fa";
import { MdOutlineChurch, MdOutlineWarehouse } from "react-icons/md";

import { AiOutlineHome } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";


const typerent = [
  {
    name: "House",
    icon: <AiOutlineHome style={{ fontSize: "3em" }} />,
  },
  { name: "Church", icon: <MdOutlineChurch style={{ fontSize: "3em" }} /> },
  {
    name: "Appartment",
    icon: <BiBuildingHouse style={{ fontSize: "3em" }} />,
  },
  {
    name: "Warehouse",
    icon: <MdOutlineWarehouse style={{ fontSize: "3em" }} />,
  },
  {
    name: "Farmhouse",
    icon: <BsHouses style={{ fontSize: "3em" }} />,
  },
  {
    name: "Student Accomondation",
    icon: <FaSchool style={{ fontSize: "3em" }} />,
  },
];

const tentantAccess = [
  { id: 1, name: "Entire Property" },
  { id: 2, name: "A private place or room" },
];
const mduration = [
  "1 months",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
];

const offerslist = [
  {
    id: "1",
    name: "WI-FI",
  },
  {
    id: "2",
    name: "Kitchen",
  },
  {
    id: "3",
    name: "Garden",
  },
  {
    id: "4",
    name: "Parking",
  },
  {
    id: "5",
    name: "Balcony",
  },
  {
    id: "6",
    name: "Swimming pool",
  },
  {
    id: "7",
    name: "Gym",
  },
  {
    id: "8",
    name: "Air conditioning",
  },
  {
    id: "9",
    name: "Heating",
  },
  {
    id: "10",
    name: "Fireplace",
  },
  {
    id: "11",
    name: "Security system",
  },
  {
    id: "12",
    name: "Elevator",
  },
  {
    id: "13",
    name: "Laundry facilities",
  },
  {
    id: "14",
    name: "Pet-friendly",
  },
  {
    id: "15",
    name: "Furnished",
  },
  {
    id: "16",
    name: "Unfurnished",
  },
  {
    id: "17",
    name: "Utilities included",
  },
  {
    id: "18",
    name: "Bike storage",
  },
  {
    id: "19",
    name: "Wheelchair accessible",
  },
  {
    id: "20",
    name: "Concierge service",
  },
  {
    id: "21",
    name: "24-hour maintenance",
  },
  {
    id: "22",
    name: "Roof deck",
  },
  {
    id: "23",
    name: "BBQ area",
  },
  {
    id: "24",
    name: "Community room",
  },
  {
    id: "25",
    name: "Playground",
  },
  {
    id: "26",
    name: "Tennis court",
  },
  {
    id: "27",
    name: "Basketball court",
  },
  {
    id: "28",
    name: "Sauna",
  },
  {
    id: "29",
    name: "Jacuzzi",
  },
  {
    id: "30",
    name: "Security guard",
  },
  {
    id: "31",
    name: "Cable/satellite TV",
  },
  {
    id: "32",
    name: "Storage space",
  },
  {
    id: "33",
    name: "Patio",
  },
  {
    id: "34",
    name: "Fitness center",
  },
  {
    id: "35",
    name: "Clubhouse",
  },
  {
    id: "36",
    name: "Business center",
  },
  {
    id: "37",
    name: "On-site maintenance",
  },
  {
    id: "38",
    name: "Garage",
  },
  {
    id: "39",
    name: "On-site management",
  },
  {
    id: "40",
    name: "Electric vehicle charging",
  },
  {
    id: "41",
    name: "Pet park",
  },
  {
    id: "42",
    name: "Yoga studio",
  },
  {
    id: "43",
    name: "Dog grooming station",
  },
  {
    id: "44",
    name: "Media room",
  },
  {
    id: "45",
    name: "Billiards room",
  },
  {
    id: "46",
    name: "Coffee bar",
  },
  {
    id: "47",
    name: "Parcel locker",
  },
  {
    id: "48",
    name: "Rooftop lounge",
  },
  {
    id: "49",
    name: "Dry cleaning service",
  },
  {
    id: "50",
    name: "Electric car sharing",
  }
];

export { mduration, offerslist, tentantAccess, typerent };

