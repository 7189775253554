import React from 'react';
import Skeleton from "react-loading-skeleton";
import AllBlogs from "./components/AllBlogs";

const FeaturedBlogTab = ({
    blogs,
    blog_loading
}) => {
    const [category, setCategory] = React.useState("");
    const isFeatured = false;

    const filteredBlogs = blogs.filter(blog => blog.status === 'published' && blog.isFeatured === true)

    return (
        <>
           
            {blog_loading ? (
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4   ">
                    {Array.from({ length: 8 }, (_, index) => (
                        <div key={index} className="col grid-box">
                            <div className="position-relative">
                                <Skeleton variant="rectangular" width="100%" height="200px" />
                                <div className="mt-3">
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="text" width="100%" />
                                    <Skeleton variant="text" width="60%" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <AllBlogs blogs={filteredBlogs} />
            )}
        </>
    )
}

export default FeaturedBlogTab