import { createSlice } from "@reduxjs/toolkit";
import { getAllHomeAdsAction } from "../actions/getAllAdsAction";

const initialState = {
  bannerloading: false,
  home_banner_ads: [],
 
  error: "",
};

const adsSlice = createSlice({
  name: "adsSlice",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllHomeAdsAction.pending, (state) => {
        state.bannerloading = true;
      })
      .addCase(getAllHomeAdsAction.fulfilled, (state, action) => {
        state.bannerloading = false;
        state.home_banner_ads = action.payload.featuredAds;
      })
      .addCase(getAllHomeAdsAction.rejected, (state, action) => {
        state.bannerloading = false;
      });
  },
});

export default adsSlice.reducer;
