const allnews = [
  {
    id: 1,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada to make visa easier for domestic workers",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "32k"
  },
  {
    id: 2,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canadian economy shows positive growth in Q2",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "18k"
  },
  {
    id: 3,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "New immigration policies announced for Canada",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "24k"
  },
  {
    id: 4,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada celebrates National Indigenous Peoples Day",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "14k"
  },
  {
    id: 5,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada ranked among the top countries for quality of life",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "20k"
  },
  {
    id: 6,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canadian athletes win multiple medals at the Olympic Games",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "28k"
  },
  {
    id: 7,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada launches new initiative to combat climate change",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "36k"
  },
  {
    id: 8,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "New study reveals the impact of climate change on Canadian wildlife",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "22k"
  },
  {
    id: 9,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada announces new trade agreement with Asian countries",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "26k"
  },
  {
    id: 10,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canadian researchers make breakthrough in cancer treatment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "38k"
  },
  {
    id: 11,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "New technology hub opens in Toronto, attracting global talent",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "30k"
  },
  {
    id: 12,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canadian government invests in infrastructure projects nationwide",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "16k"
  },
  {
    id: 13,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canada experiences record-breaking tourism numbers in 2023",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "34k"
  },
  {
    id: 14,
    urlToImage:
      "https://t4.ftcdn.net/jpg/02/09/53/11/360_F_209531103_vL5MaF5fWcdpVcXk5yREBk3KMcXE0X7m.jpg",
    title: "Canadian startup receives funding to develop innovative technology",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Rerum soluta officia similique",
    views: "28k"
  },
  // Add more news items here
];

export default allnews;
