import React from "react";
import styled from "styled-components";
import AdminBlogCard from "./AdminBlogCard";


const Wrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > .child-item:nth-child(1) {
    grid-column: 1 / span 2;
    height: 100%;
  }

  @media screen and (max-width: 940px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    & > .child-item:nth-child(1) {
      grid-column: 1 / span 2;
      height: 100%;
      width: 100%;
    }
  }
`
const AllBlogs = ({blogs}) => {


  
  return (
    <Wrapper className=" ">
      {blogs.map((item, index) => {
        return (
          <div key={index} className="p-1 child-item">
            <AdminBlogCard item={item} index={index} />
          </div>
        );
      })}
    </Wrapper>
  );
};

export default AllBlogs;
