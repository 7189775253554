

// import AuthApi from "../manage_access_route";

// import API from "../common_api";

import API from "./adminCommonApi.js";
import ProtectApi from "./adminAuthorizeApi"


// admin login
export const adminLogin = (data) => API.post("/api/admin/auth/login", data);
export const registerAdmin = (data) =>ProtectApi.post("/api/admin/auth/register", data);
// get all admin
export const getAllAdmin = (keyword="", page) => ProtectApi.get(`/api/admin/auth/get_all_admin_super_admin/?keyword=${keyword}&page=${page}`);
