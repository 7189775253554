import React from "react";
import { List, ListItem } from "@mui/material";

const Agreements = () => {
  return (
    <div>
      <span className="fs-5 fw-bold">Terms and Agreement</span>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores,
        veritatis iusto blanditiis, nobis aliquam ratione nesciunt, itaque
        quaerat assumenda quibusdam dolor magni. Lorem ipsum dolor sit, amet
        consectetur adipisicing elit. Velit ipsam magnam deleniti, quae enim
        sapiente, animi facilis totam adipisci voluptatem provident odit sit
        obcaecati porro est ullam beatae reprehenderit. Consequatur illum ex
        aliquam neque placeat laborum dolorum perspiciatis qui, quaerat,
        mollitia nam in. Nihil, neque facere nostrum possimus similique voluptas
        optio id deserunt dolorem nobis? Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Non repudiandae magnam ut nihil modi nisi ratione,
        numquam ad fugit mollitia recusandae. Nobis accusamus eum iste. Veniam
        voluptatem qui possimus ut totam amet eligendi sint atque! Pariatur,
        nihil laborum. Nesciunt nisi enim veniam, assumenda a id eaque
        distinctio deserunt molestias suscipit, hic dolores totam quos qui!
        Quaerat impedit magnam assumenda deserunt ipsam a eveniet alias modi,
        deleniti quis blanditiis excepturi dolore. Quos dolor labore amet
        dolores deserunt itaque facere, hic distinctio. Incidunt vel, magni
        quas, possimus autem unde maiores impedit dolore repudiandae aspernatur
        explicabo voluptas labore amet, maxime culpa excepturi sint iure.
        Quibusdam iure dicta voluptatem fuga! Cumque, reprehenderit laboriosam.
        Debitis id quae quisquam! Officiis enim, accusamus quaerat placeat
        impedit ad tenetur id facere non minima necessitatibus rem quos
        suscipit. Tempore dolorum, doloremque consectetur id error beatae
        inventore itaque quos molestias eum at deserunt iusto atque cupiditate,
        nihil magni repellat a officia ex quaerat temporibus, ipsam recusandae
        dicta doloribus. Odit voluptatem facilis exercitationem natus laborum
        enim explicabo velit iure quis commodi rerum, et cupiditate
        reprehenderit obcaecati repellendus, fugiat, veritatis placeat nesciunt
        illum nostrum vitae quibusdam perspiciatis quas culpa. Sunt cumque
        nostrum assumenda, nesciunt aspernatur esse sit eaque repudiandae ad
        dolore rem. Iste, exercitationem ratione! Distinctio consequuntur, autem
        vero totam fugiat ratione, aliquid laudantium tempore maiores excepturi
        quae saepe sunt quisquam facere! Veniam, culpa!
      </p>
      <List
        component="ol"
        sx={{
          margin: "5px",
          padding: 0,
          "& li": {
            marginBottom: "5px",
            paddingLeft: "5px",
            "&::before": {
              content: 'counter(number) "."',
              counterIncrement: "number",
              marginRight: "5px",
            },
          },
        }}
      >
        <ListItem>sdf</ListItem>
        <ListItem>sdf</ListItem>
        <ListItem>sfd</ListItem>
        <ListItem>sfd</ListItem>
        <ListItem>sdf</ListItem>
      </List>
    </div>
  );
};

export default Agreements;
