import React from "react";

const ProfessionalDescription = ({ errors, handleFormValue, formvalue }) => {
  return (
    <div>
      <h5 className="text-black fw-bold">Description</h5>
      <div className="form-group">
        <textarea
          className={errors ? "form-control is-invalid" : "form-control"}
          id="textAreaExample"
          rows="4"
          maxLength={800}
          data-mdb-showcounter="true"
          placeholder={"Write something about yourself"}
          name={"description"}
          type="text"
          onChange={(e) => handleFormValue("description", e.target.value)}
          value={formvalue}
        ></textarea>
        <div className="d-flex justify-content-between align-content-center">
          <small
            id="emailHelp"
            className={errors ? "form-text text-danger" : ""}
          >
            {errors}
          </small>
          <span
            className={
              formvalue.length === 800
                ? "form-helper text-end text-danger ms-auto "
                : "form-helper text-end ms-auto"
            }
          >
            {formvalue.length} / {800}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalDescription;
