import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { MoreVert } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllOrganizationAction } from "../../../../context/actions/admin/organizationAction";
import AdminOrgProfile from "./adminOrgProfile/AdminOrgProfile";
import { Drawer } from "@mui/material";
import { CheckOutlined, CancelOutlined } from "@mui/icons-material";

const formvalues = {
  description: "",
  email: "",
  postalcode: "",
  phonenumber: "",
  telephonenumber: "",
  nameofowner: "",
  nameofcompany: "",
  members: [],

  panfront: "",
  panback: "",

  panfrontfile: "",
  panbackfile: "",

  fblink: "",
  linkedinlink: "",
  twitterlink: "",
  instagramlink: "",
  websitelink: "",
  province: "",
  city: "",
  agreement: false,
  expdate: "",
};
export default function OrganizationTable({ keyword }) {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [activeindex, setActive] = React.useState(0);
  const [formvalue, setFormValue] = React.useState(formvalues);
  const [value, setValue] = React.useState("0");
  const [status, setStatus] = React.useState(true);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onmemberchange = (newlist) => {
    setFormValue({ ...formvalue, members: newlist });
  };

  // handle form value on input change
  const handleformvalue = (e) => {
    const name = e.target.name;
    switch (name) {
      case "province":
        setFormValue({ ...formvalue, province: e.target.value, city: "" });

        break;

      case "panfront":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setFormValue({
            ...formvalue,
            [e.target.name]: blobURL,
            panfrontfile: file,
          });
        }

        break;

      case "panback":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setFormValue({
            ...formvalue,
            [e.target.name]: blobURL,
            panbackfile: file,
          });
        }
        break;

      case "agreement":
        setFormValue({ ...formvalue, [e.target.name]: !formvalue.agreement });

        break;

      default:
        setFormValue({ ...formvalue, [e.target.name]: e.target.value });
    }
  };

  React.useEffect(() => {
    dispatch(adminGetAllOrganizationAction({ keyword, page, status }));
  }, [dispatch, page, keyword]);

  const columns = [
    {
      field: "logo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "view", headerName: "View", flex: 1 },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "featured",
      headerName: "Featured",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);

          toggleDrawer();
        };
        return (
          <Button
            size="medium"
            variant="outlined"
            onClick={handleButtonClick}
            data-mdb-toggle="offcanvas"
            data-mdb-target="#offcanvasRight"
            aria-controls="offcanvasRight"
          >
            <MoreVert color="error" />
          </Button>
        );
      },
    },
  ];

  const { organization, resultperpage, totalorganizationcount, loading } =
    useSelector((state) => ({
      ...state.admin_reducer,
    }));

  const mappedRows = organization?.map((organization, index) => ({
    id: organization._id,
    index: index,
    logo: `${process.env.REACT_APP_IMAGE_PATH}${organization.introduction.profile}`,
    name: organization.introduction.organizationName,
    address: `${organization.introduction.provenience}|${organization.introduction.city}`,
    active: organization.active,
    featured: organization.featured,
    view: organization.viewCount,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalorganizationcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminOrgProfile
          formvalue={formvalue}
          value={value}
          item={organization[activeindex]}
          handleChange={handleChange}
          handleformvalue={handleformvalue}
          onmemberchange={onmemberchange}
          activeindex={activeindex}
          closemodal={() => setIsOpen((prev) => !prev)}
        />
      </Drawer>
    </div>
  );
}
