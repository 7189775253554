import React from "react";
import { AddLocation } from "../../../components/Index";
import { Chip } from "@mui/material";
const PreferedLocation = ({
  setErrors,
  preferedLocations,
  errors,
  removelocation,
  setformvalues,
}) => {
  const addnewLocation = React.useCallback(
    (location) => {
      setformvalues((prev) => {
        const updatedPLocations = [
          ...prev.preferedLocations,
          {
            lat: location.geometry.coordinates[0],
            lng: location.geometry.coordinates[1],
            place_name: location.place_name,
          },
        ];

        if (updatedPLocations.length <= 5) {
          return {
            ...prev,
            preferedLocations: updatedPLocations,
          };
        } else {
          setErrors((latm) => {
            const iser = {
              ...latm,
              preferedLocations: "max 5 location *",
            };
            return iser;
          });

          return prev; // Return the previous state if the limit exceeds 5
        }
      });
    },
    [setformvalues]
  );

  return (
    <div className="row row-cols-1 row-cols-lg-2 mt-3">
      <div className="location-info d-flex flex-column gap-1">
        <div>
          <h4 className="text-black fw-bold p-0 m-0">
            Preferred location of stay
          </h4>
          <small>
            This information will be displayed publicly on your listing
          </small>
        </div>

        {errors.preferedLocations.length > 0 ? (
          <small className="text-danger my-1">{errors.preferedLocations}</small>
        ) : null}

        <div className="d-flex gap-1 flex-wrap">
          {preferedLocations?.map((item, index) => {
            return (
              <Chip
                label={item.place_name}
                key={index}
                variant="outlined"
                onDelete={() => removelocation(index)}
              />
            );
          })}
        </div>
      </div>

      <div className="location-input">
        <AddLocation height={500} handleLocation={addnewLocation} />
      </div>
    </div>
  );
};

export default PreferedLocation;
