import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
    Box,
    InputAdornment,
    OutlinedInput,
    SvgIcon,
    Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import React, { useEffect } from "react";

import { useNavigate } from "react-router";

import Skeleton from "react-loading-skeleton";
import allnews from "../../utils/all_news";
import { getALLBlogsAction } from "../../context/actions/blogAction";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";



const NewsHome = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChange = (e) => {
        if (e.target.value === "") {
            e.preventDefault();
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const value = encodeURIComponent(event.target.value);
            navigate(`/blogs/result?q=${value}`);
        }
    };

    const [category, setcategory] = React.useState();
    const { blogs, resultperpage, totalBlogCount, allBlogsLoading, loading } = useSelector(
        (state) => ({
            ...state.blog_reducer,
        })
    );


    useEffect(() => {
        getNews();
    }, []);

    const [page, setPage] = useState(1);

    const getNews = useCallback(
        () => dispatch(getALLBlogsAction({ category: 'News', page })),
        [dispatch, page, category]
    );


    return (
        <div className="">
            <div className="container">
                <section className="search-section d-flex align-items-center gap-3 my-2">
                    <Typography
                        className="display-6 mt-3 mb-3  text-nowrap  text-black"
                        variant={"span"}
                    >
                        News portal
                        <p className="fs-6 text-danger">Stay hydrated about everything</p>
                    </Typography>
                    <Box
                        sx={{
                            height: "50px",
                            "@media screen and (min-width: 940px )": {
                                width: "50%",
                            },
                        }}
                        className="ms-auto "
                    >
                        <OutlinedInput
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            className="h-100"
                            fullWidth
                            placeholder="Search any news immigartion, travel, students"
                            sx={{
                                border: "none",
                                borderRadius: "5px",
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SvgIcon color="action" fontSize="small">
                                        <MagnifyingGlassIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            }
                        />
                    </Box>
                </section>


                {loading ? (
                    <>
                        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4   ">
                            {Array.from({ length: 8 }, (_, index) => (
                                <div key={index} className="p-1 col grid-box">
                                    <div className="position-relative">
                                        <Skeleton
                                            variant="rectangular"
                                            width="100%"
                                            height="200px"
                                        />
                                        <div className="mt-3">
                                            <Skeleton variant="text" width="80%" />
                                            <Skeleton variant="text" width="100%" />
                                            <Skeleton variant="text" width="60%" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <div class="row row-cols-1 row-cols-md-4 g-4">
                        {
                            //  5 blogs
                            blogs.map((item, index) => {
                                return <>
                                    <Link to={`/blogs/${item._id}`}>

                                        <Box
                                            sx={{
                                                "&:hover": {
                                                    cursor: "pointer",
                                                },
                                                "&:hover .card-title": {
                                                    textDecoration: "underline",
                                                },

                                            }}
                                        >
                                            <div class="col">
                                                <div class="card">
                                                    <img src={`${process.env.REACT_APP_IMAGE_PATH}${item?.thumbnail}`} class="card-img-top object-cover" alt="News Image" height={'200px'} width={'100%'} />
                                                    <div class="card-body">
                                                        <h5 class="card-title text-black">
                                                            <p>{item.title}</p>
                                                        </h5>
                                                        <p class="card-text text-black">
                                                            by <span className="text-decoration-underline">{
                                                                item.author ? item.author : "ONF Admin"
                                                            }</span> - {moment(item.createdAt).format("DD MMMM YYYY")}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Box>
                                    </Link>
                                </>
                            })
                        }
                    </div>
                )}
            </div>
        </div>
    );
};

export default NewsHome;
