import React from "react";
import { Box, Typography, Input } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import AvailableDate from "../../addrental/components/AvailableDate";
import DurationSelect from "../../addrental/components/DurationSelect";
import { mduration } from "../../../utils/rentSettings";
const YourBudget = ({ handleFormValue, values, errors }) => {

  return (
    <Box
      className="mt-3"
      sx={{
        width: { sm: "90%", lg: "50%" },
      }}
    >
      <div>
        <h4 className="text-black fw-bold p-0 m-0">Budget per week</h4>
        <small>How much are you willing to spend per week on rent?</small>
      </div>
      <Box
        className="rounded rounded-3 bg-hover w-100 d-flex align-items-center flex-column justify-content-center "
        sx={{
          height: "30vh",
          paddingInline: { lg: "8%", sm: "4%", xs: "2%" },
          border:
            errors.budgetPerWeek.length > 0
              ? "1.5px solid var(--invalid-border)"
              : "",
        }}
      >
        <div className="d-flex gap-4 align-items-center">
          <RemoveIcon
            className="bg-white text-black rounded rounded-circle p-3 "
            sx={{ width: "70px", height: "70px" }}
            role="button"
            onClick={(e) =>
              handleFormValue("minusbudgetPerWeek", values.budgetPerWeek)
            }
          />

          <div className="d-flex align-items-center  bg-white p-2">
            <Typography
              variant="span"
              sx={{
                fontSize: "2.7em",
                fontWeight: "bold",
              }}
            >
              $
            </Typography>
            <Input
              type="text"
              name=""
              className="rounded rounded-3 w-100 h-100 bg-white border-0 fw-bold"
              id=""
              disableUnderline
              onChange={(e) => handleFormValue("budgetPerWeek", e.target.value)}
              value={values.budgetPerWeek}
              sx={{
                fontSize: "2.7em",
                "&:focus": {
                  border: "none",
                  outline: "none",
                },
              }}
            />
          </div>

          <AddIcon
            role="button"
            className="bg-white text-black rounded rounded-circle p-3"
            sx={{ width: "70px", height: "70px" }}
            onClick={(e) =>
              handleFormValue("addbudgetPerWeek", values.budgetPerWeek)
            }
          />
        </div>
        <Typography
          variant="p"
          color="initial"
          className="fs-4 mt-3 text-black text-center "
          sx={{ width: { lg: "65%" } }}
        >
          per week
        </Typography>
      </Box>

      {errors.budgetPerWeek.length > 0 ? (
        <small className="text-danger mt-1">{errors.budgetPerWeek}</small>
      ) : null}

      <div className="mt-3">
        <h4 className="text-black fw-bold p-0 m-0">Length of stay</h4>
        <small>How long are you planning to stay in this property?</small>
      </div>
      <DurationSelect
        minimumStay={values.lengthOfStay}
        handleFormValue={handleFormValue}
        name={"lengthOfStay"}
        durations={mduration}
        errors={errors.lengthOfStay}
      />

      <div className="mt-3">
        <h4 className="text-black fw-bold p-0 m-0">Move in date</h4>
        <small>
          When would you like to move in? You can change this later.
        </small>
      </div>

      <AvailableDate
        availabeDate={values.moveInDate}
        handleFormValue={handleFormValue}
        name={"moveInDate"}
        errors={errors.moveInDate}
      />
    </Box>
  );
};

export default YourBudget;
