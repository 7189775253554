import React from "react";
import { FormSelectCityB } from "../../../components/Index";
import allcity from "../../../utils/all_city";
import { allProvince } from "../../../utils/orgSettings";
import { professions } from "../../../utils/professionSettings";

const ProfessionalSmFilter = ({ filtered, onSMfilter, onClose }) => {
  const filteredcity = React.useMemo(() => {
    return allcity
      .filter((option) => option.province_name === filtered.province)
      .sort((a, b) => a.city.localeCompare(b.city));
  }, [filtered.province]);

  const [filters, setFilters] = React.useState({
    category: "",
    city: "",
    province: "",
  });

  React.useEffect(() => {
    setFilters({
      category: filtered.category,
      city: filtered.city,
      province: filtered.province,
    });
  }, [filtered.category, filtered.city, filtered.province]);
  const handleFilter = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };
  const onFilter = () => {
    onSMfilter({
      city: filters.city,
      province: filters.province,
      category: filters.category,
    });
    onClose();
  };
  return (
    <div className="">
      <h4>Search & Filtering</h4>

      <div className="w-100">
        <label htmlFor="category">category</label>
        <select
          className={" w-100 form-select rounded-0 py-2 rounded-2 "}
          aria-label=".form-select-lg example"
          value={filters.category}
          name="category"
          id="category"
          onChange={handleFilter}
        >
          <option value="" className="w-100">
            Open To Category
          </option>
          {professions.map((item, index) => {
            return (
              <option value={item.value} key={index} className="w-100">
                {item.value}
              </option>
            );
          })}
        </select>
      </div>
      <div className="w-100">
        <label htmlFor="province">province</label>
        <select
          className={" w-100 rounded-0 py-2 rounded-2 form-select"}
          aria-label=".form-select-lg example"
          value={filters.province}
          onChange={handleFilter}
          name="province"
          id="province"
        >
          <option value="" className="w-100">
            Open To Province
          </option>
          {allProvince.map((item, index) => {
            return (
              <option value={item} key={index} className="w-100">
                {item}
              </option>
            );
          })}
        </select>
      </div>
      <FormSelectCityB
        province={filters.province || filtered.province}
        city={filters.city}
        handleFilter={handleFilter}
      />

      <div className="d-flex justify-content-center w-100">
        <button
          className="btn bg-black text-white w-75 mx-auto mt-2"
          onClick={onFilter}
        >
          Filter
        </button>
      </div>
    </div>
  );
};

export default ProfessionalSmFilter;