import React from "react";
import PersonIcon from "@mui/icons-material/Person";

import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FlagIcon from "@mui/icons-material/Flag";
import EventIcon from "@mui/icons-material/Event";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box, Container } from "@mui/material";
const AdminAdminDetailInformation = ({ data }) => {
  return (
    <Box
      sx={{
        width: { xs: "100%", md: "80%", lg: "50%" },
      }}
      className=" mx-auto "
    >
      <h5 className="fw-bold text-center">General Information</h5>
      <section className="d-flex flex-column gap-2 fs-5 w-100 ">
        <div className="row-cols-2 row w-100  ">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <PersonIcon />
            <span className="fs-6 text-nowrap text-nowrap ">Full Name</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold">
            {data.firstname} {data.lastname}
          </p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <FlagIcon />
            <span className="fs-6 text-nowrap ">Country</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold d-flex align-items-center flex-nowrap ">
            <span className="me-2">{data.country}</span>

            <img
              src={`https://www.countryflags.com/wp-content/uploads/${data?.country?.toLowerCase()}-flag-png-large.png`}
              alt="flag"
              className="object-contain"
              width="20"
              height="20"
            />
          </p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <LocalPhoneIcon />
            <span className="fs-6 text-nowrap ">Phone</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold">{data.phone}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <EventIcon />
            <span className="fs-6 text-nowrap ">Created At</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold">John Doe</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <EventAvailableIcon />
            <span className="fs-6 text-nowrap ">LoggedIn Since</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold">JAN 1 2020</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center flex-nowrap">
            <LocationOnIcon />
            <span className="fs-6 text-nowrap"> City</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 text-nowrap mt-1 fw-bold">{data.city}</p>
        </div>
      </section>
    </Box>
  );
};

export default AdminAdminDetailInformation;
