import ProtectApi from "./adminAuthorizeApi";

export const adminCreateRental = (data) =>
  ProtectApi.post("/api/admin/rental/admin_create_rental", data);
export const adminGetAllRental = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/rental/admin_get_all_rentals?keyword=${keyword}&page=${page}`
  );
export const adminEditRental = (id, data) =>
  ProtectApi.put(`/api/admin/rental/admin_edit_rental/${id}`, data);

  //admin_change_status_rental
export const adminChangeRentalStatus = (id, data) =>
ProtectApi.put(`/api/admin/rental/admin_change_status_rental/${id}`, {
  active: data,
});

// featured rental
export const adminUpdateFeaturedRental = (id, data) =>
  ProtectApi.put(`/api/admin/rental/admin_featured_rental/${id}`, {
    featured: data,
});

