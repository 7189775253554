import { Typography } from "@mui/material";
import { SvgIcon } from "@mui/material";
import { Button } from "@mui/material";
import { Box, Container, Drawer, Stack } from "@mui/material";
import React, { useState } from "react";

import { saveAs } from "file-saver";
import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { AdminAdminsTable } from "./AdminAdminsTable";
import { AdminUsersSearch } from "../users/components/AdminUsersSearch";
import { useNavigate } from "react-router";
const AdminAdmin = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const handleChangeKeyword = (event) => {
    if (event.target.value === "") {
      setKeyword("");
    }
  };
  const handleChangeKey = (event) => {
    if (event.key === "Enter") {
      setKeyword(event.target.value);
    }
  };
  const handleExport = async () => {
    try {
      const response = await fetch(
        "http://192.168.101.4:5000/api/admin/get_all_user?keyword=&page=1"
      );
      if (!response.ok) {
        throw new Error("Failed to fetch customer data");
      }
      const data = await response.json();

      // Convert the customer data to CSV format
      const csvData = convertToCSV(data.user);

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: "text/csv" });

      // Save the exported data as a file
      saveAs(blob, "customers.csv");
    } catch (error) {
      console.error("Export failed:", error);
      // Handle the error accordingly (e.g., show an error message)
    }
  };
  const convertToCSV = (data) => {
    const csvHeader = Object.keys(data[0]).join(",") + "\n";
    const csvRows = data.map((customer) => {
      const values = Object.values(customer);
      return values.join(",");
    });

    return csvHeader + csvRows.join("\n");
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
              <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  Admin Management - <span className="text-danger">Superuser only</span>
                </span>
                <small>
                  Can provide role based access to other users
                </small>
              </div>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowUpOnSquareIcon />
                      </SvgIcon>
                    }
                  >
                    Import
                  </Button>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleExport}
                  >
                    Export
                  </Button>
                </Stack>
              </Stack>
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={() => navigate("/admin/add")}
                >
                  Add Admin
                </Button>
              </div>
            </Stack>
            <AdminUsersSearch
              handleChange={handleChangeKeyword}
              handleKeyPress={handleChangeKey}
              placeholder={"Search Admin"}
            />
            <AdminAdminsTable keyword={keyword} />
          </Stack>
        </Container>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          {/* <ActionPage /> */}
        </Drawer>
      </Box>
    </>
  );
};

export default AdminAdmin;
