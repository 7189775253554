import {
  Email,
  Facebook,
  LocationOn,
  Phone,
  RemoveRedEye,
  Twitter,
} from "@mui/icons-material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Rating,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import React, { useCallback, useEffect } from "react";
import { BsCalendar2Date, BsClock, BsPersonSquare } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  createBusinessReviewAction,
  getBusinessDetailsAction,
} from "../../context/actions/businessAction";

import BookmarkIcon from "@mui/icons-material/Bookmark";
import DoneIcon from "@mui/icons-material/Done";
import FacebookIcon from "@mui/icons-material/Facebook";
import LaunchIcon from "@mui/icons-material/Launch";
import TwitterIcon from "@mui/icons-material/Twitter";
import { TabList } from "@mui/lab";
import moment from "moment";
import { useState } from "react";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import BusinessCommnet from "../../components/comment_card/business_commnet";
import ShowLocation from "../../components/location/ShowLocation";
import { post_bookmark } from "../../context/actions/authAction";
import { pictureModal } from "../../utils/modalSettings";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import FeaturedBusiness from "./components/FeaturedBusiness";
import ReviewModal from "./components/ReviewModal";
import SellAllReviewModal from "./components/SellAllReviewModal";
import ServicesModal from "./components/ServicesModal";
import BusinessDetailSkeleton from "./BusinessDetailSkeleton";
import { Helmet } from "react-helmet-async";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;
    gap: 5px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;
const BusinessDetail = () => {
  // get the id from the url
  const { id } = useParams();

  //bcavjhvas

  // dispatch the action
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // type of bookmark
  const [type, setType] = React.useState("Business");

  // intail state of bookmarked
  const [bookmarked, setbookmarked] = React.useState(false);

  // use useCallback to memoize the function
  const get_business_details = useCallback(() => {
    dispatch(getBusinessDetailsAction(id));
  }, [dispatch, id]);

  // use useEffect to call the function
  useEffect(() => {
    get_business_details();
  }, [get_business_details]);

  const [bookmark, setBookmark] = useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  // use useSelector to get data from store from business_reducer
  const {
    loading,
    introduction,
    contactAndLinks,
    Owner,
    auth,
    images,
    business_comments,
    avg_rating,
    bookmarks,
    offers,
    viewCount,
  } = useSelector((state) => ({
    loading: state.business_reducer.loading,
    introduction: state.business_reducer.business_details?.introduction || {},
    viewCount: state.business_reducer.business_details?.viewCount || 0,
    contactAndLinks:
      state.business_reducer.business_details?.contactAndLinks || {},

    Owner: state.business_reducer.business_details?.Owner || [],
    images: state.business_reducer.business_details?.images || [],
    business_comments: state.business_reducer.business_comments || [],
    avg_rating: state.business_reducer.avg_rating || 0,
    auth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
    offers: state.business_reducer.business_details?.offers || [],
  }));

  // check is that is already bookmarket or not
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  // load the is isBookmarked
  React.useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  React.useEffect(() => {
    if (introduction && bookmark.name !== introduction.businessName) {
      setBookmark({
        name: introduction?.businessName,
        type: type,
        image: images[0],
        category: introduction?.category,
        location: contactAndLinks?.location?.location_place_name,
      });
    }
  }, [introduction, bookmark.name, type, images, contactAndLinks]);

  const togglebookmark = () => {
    if (auth) {
      dispatch(post_bookmark({ id, data: bookmark }));
    } else {
      navigate("/login");
    }
  };

  const average_rating = avg_rating;

  // show more description
  const [showMore, SetshowMore] = React.useState(false);
  // open modal
  const [open, setOpen] = React.useState(false);

  // tab change
  const [value, setValue] = React.useState("1");

  // like state
  const [liked, setLiked] = React.useState(false);

  // open services modal
  const [openServices, setOpenServices] = React.useState(false);
  const handleOpenServices = () => setOpenServices(true);
  const handleCloseServices = () => setOpenServices(false);

  // open reviews modal
  const [openReviews, setOpenReviews] = React.useState(false);
  const handleOpenReviews = () => setOpenReviews(true);
  const handleCloseReviews = () => setOpenReviews(false);

  const reviewoption = {
    comment: "",
    rating: 0,
  };
  const [review, setReview] = React.useState(reviewoption);

  // handle commnet chnage
  const handleCommentChange = (e) => {
    setReview({ ...review, comment: e.target.value });
  };
  // handle rating chnage
  const handleRatingChange = (e) => {
    setReview({ ...review, rating: e.target.value });
  };

  // handle submitReview
  const submitReview = (e) => {
    e.preventDefault();
    dispatch(createBusinessReviewAction({ review, id }));
  };

  // toggle like
  const togglelike = () => {
    if (liked) {
      setLiked(false);
    } else {
      setLiked(true);
    }
  };

  // on tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  // open see all reviews modal
  const [openSeeAllReviews, setOpenSeeAllReviews] = React.useState(false);
  const handleOpenSeeAllReviews = () => setOpenSeeAllReviews(true);
  const handleCloseSeeAllReviews = () => setOpenSeeAllReviews(false);
  if (loading) {
    return <BusinessDetailSkeleton />;
  }
  if (
    (introduction && Object?.keys(introduction).length === 0) ||
    introduction === undefined
  ) {
    return <NotFoundPage />;
  }

  return (
    <>
    <Helmet>
        <title>
          {introduction?.businessName}
        </title>
        <meta name="description"
          data-rh="true"
          content={introduction?.description} />
        <link rel="canonical" href="/register" />
      </Helmet>
        <Wrapper className="container mt-3">
      {/* Images Section */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridAutoRows: { xs: "140px", md: "170px", lg: "200px" },
          borderRadius: "25px",
        }}
        className="grid-images position-relative rounded rounded-3 overflow-hidden"
      >
        {images.slice(0, 5).map((item, index) => {
          return (
            <div className="w-100 h-100 item" key={index}>
              <img
                // src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                alt=""
                className="w-100 object-cover h-100"
              />
            </div>
          );
        })}

        {/* open modal button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "1%",
          }}
          className="btn d-flex gap-2 align-items-center bg-white"
          onClick={handleOpen}
        >
          <CgMenuGridR className="position bottom-0" />
          <span>Show all photos</span>
          {images?.length}
        </Box>
      </Box>

      <section className="d-flex flex-column text-black ">
        <div className="d-flex w-100 align-items-center justify-content-between">
          {/* <span className="fs-2 ">{introduction.businessName}</span> */}
          <Typography
            variant="span"
            sx={{
              fontSize: { xs: "1.1em", md: "1.8em" },
            }}
          >
            {introduction?.businessName}
          </Typography>

          <div className="action-icons d-flex gap-3 align-items-center">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <RemoveRedEye />
                  {viewCount}
                </div>
              </Button>
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  SHARE
                </div>
              </Button>
              <Button>
                <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                  {bookmarked ? (
                    <BookmarkIcon onClick={togglebookmark} role="button" />
                  ) : (
                    <MdOutlineBookmarkAdd
                      onClick={togglebookmark}
                      role="button"
                    />
                  )}
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <h6>
          <span className="fw-bold">
            <LocationOn fontSize="small" />{" "}
            {contactAndLinks?.location?.location_place_name}
          </span>
        </h6>

        <div className="d-flex">
          <Rating
            size="small"
            name="half-rating-read"
            value={average_rating}
            precision={0.5}
            readOnly
          />
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginLeft: "0.2rem",
            }}
          >
            {avg_rating} |{" "}
            <span className="text-decoration-underline ">
              {business_comments?.length} Reviews
            </span>
          </Typography>
        </div>
      </section>

      {/* left-right sider divider */}
      <div className="left-right-section d-flex row row-cols-lg-2 row-cols-1 w-100  ">
        {/* left section */}
        <div className="left-section col-lg-8 col pe-lg-5  ">
          {/* General Overview section */}
          <section className="org-three col-lg-10 mt-4 mb-4 text-hover w-100">
            <h5 className="fw-bold">General Information</h5>
            <Divider />
            <div className="d-flex gap-4 mt-2 w-100 flex-wrap">
              <div className="d-flex gap-2">
                <BsCalendar2Date size={40} />
                <div>
                  <h6 className="m-0">Founded</h6>
                  <h5 className="fw-bold">
                    {moment(introduction?.establishedDate).format(
                      "MMMM Do YYYY"
                    )}
                  </h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <BsPersonSquare size={40} />
                <div>
                  <h6 className="m-0">Founded by</h6>
                  <h5 className="fw-bold">{introduction?.ownerName}</h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <BsClock size={40} />
                <div>
                  <h6 className="m-0">Working Hours</h6>
                  <h5 className="fw-bold">{introduction?.openingHour}-{introduction?.closingHour}</h5>
                </div>
              </div>
            </div>
          </section>

          {/* About section */}
          <section className="d-flex flex-column w-100 mt-3">
            <h5 className="fw-bold">All you need to know</h5>
            <p>
              {showMore
                ? introduction?.description
                : introduction?.description.split(" ").slice(0, 100).join(" ")}
              {introduction?.description.length > 200 ? (
                !showMore ? (
                  <span
                    onClick={toggleShowMore}
                    className="text-black fw-bold"
                    role="button"
                  >
                    ...ReadMore
                  </span>
                ) : (
                  <span
                    onClick={toggleShowMore}
                    className="text-black fw-bold"
                    role="button"
                  >
                    ...ReadLess
                  </span>
                )
              ) : null}
            </p>
          </section>

          <section>
            <h5 className="fw-bold mt-4">We offers.</h5>
            {/* icons and text */}
            <div className="d-flex flex-wrap gap-2">
              {offers.slice(0, 7).map((item, index) => {
                return (
                  <Chip
                    icon={
                      <DoneIcon
                        sx={{
                          color: "green",
                        }}
                      />
                    }
                    key={item}
                    label={item}
                    variant="outlined"
                    size="large"
                  />
                );
              })}
            </div>
            <button
              onClick={handleOpenServices}
              className="btn btn-sm btn-outline-black mt-2"
            >
              show all services
            </button>
            <ServicesModal
              openServices={openServices}
              handleCloseServices={handleCloseServices}
              offers={offers}
            />
          </section>

          {/* location section */}
          <section>
            <h5 className="fw-bold mt-4">Location of business</h5>
            <ShowLocation location={contactAndLinks?.location} />
          </section>
          <hr />
          <section>
            <div className="d-flex justify-content-between mt-4">
              <h5 className="fw-bold">Rating and Reviews ✨</h5>
              <h5>
                {auth ? (
                  <button
                    onClick={handleOpenReviews}
                    className="btn btn-sm btn-outline-black"
                  >
                    Write a review
                  </button>
                ) : (
                  <p>
                    Please <Link to="/login">Login</Link> to comment
                  </p>
                )}

                <ReviewModal
                  openReview={openReviews}
                  handleCloseReview={handleCloseReviews}
                  handleCommnetchnage={handleCommentChange}
                  handleRatingChnage={handleRatingChange}
                  review={review}
                  auth={auth}
                  submitCommnet={submitReview}
                />
              </h5>
            </div>
            <p>
              <span className="fw-bold">{avg_rating}</span> out of 5 based on |{" "}
              <span
                onClick={handleOpenReviews}
                className="fw-bold text-decoration-underline"
              >
                {business_comments?.length} REVIEWS
              </span>
            </p>

            <div className="row row-cols-2">
              {business_comments.map((comments, index) => {
                return <BusinessCommnet comments={comments} index={index} />;
              })}
            </div>

            <button
              onClick={handleOpenSeeAllReviews}
              className="btn btn-sm btn-outline-black mt-3"
            >
              See all reviews
            </button>
            <SellAllReviewModal
              openSeeAllReview={openSeeAllReviews}
              handleCloseSeeAllReview={handleCloseSeeAllReviews}
            />
          </section>
        </div>

        <Box
          sx={{
            height: "max-content",
          }}
          className="right-section col-lg-4 d-flex flex-column gap-2 rounded p-2 "
        >
          <section>
            <h5 className="fw-bold">Get in touch | Contacts</h5>
            <div className="d-flex gap-2 mt-1">
              <Chip
                icon={<FacebookIcon />}
                label="Facebook"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
              <Chip
                icon={<TwitterIcon />}
                label="Twitter"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
              <Chip
                icon={<LaunchIcon />}
                label="Website"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
            </div>
            <div className="d-flex gap-3 mt-3">
              <Email
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <div>
                <p className="m-0 p-0">General Email</p>
                <p className=" fw-bold">{contactAndLinks.email}</p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Phone
                sx={{
                  fontSize: "3.5rem",
                  color: "#43a519",
                }}
              />
              <div>
                <p className="m-0 p-0">Phone No.</p>
                <p className="fw-bold">{contactAndLinks.phone}</p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Facebook
                sx={{
                  fontSize: "3.5rem",
                  color: "#4267B2",
                }}
              />
              <div className="overflow-hidden d-flex flex-column">
                <p className="m-0 p-0">Facebook | Social Media</p>
                <h6 className=" fw-bold text-overflow-hidden">
                  {contactAndLinks.twitterLink}
                </h6>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Twitter
                sx={{
                  fontSize: "3.5rem",
                  color: "#1DA1F2",
                }}
              />
              <div className="overflow-hidden d-flex flex-column">
                <p className="p-0">Twitter | Social Media</p>
                <p className="fw-bold text-overflow-hidden">
                  {contactAndLinks.twitterLink}
                </p>
              </div>
            </div>
          </section>

          <hr />

          <section>
            <h5 className="fw-bold">Featured Business</h5>
            <FeaturedBusiness />
          </section>
        </Box>
      </div>

      {/* Image slider Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={pictureModal} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            role="button"
            onClick={handleClose}
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <TabContext value={value}>
            {images.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      className=" object-contain"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <TabList
              onChange={handleChange}
              className=" mx-auto"
              sx={{
                width: "max-content",
                maxWidth: "100%",
                justifyContent: "center",

                "&.Mui-selected": {
                  color: "#797979",
                  fontWeight: "bolder",
                  border: "none",
                },
              }}
              centered
              indicatorcolor={""}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={value}
            >
              {images.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0"
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center"
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          widht={90}
                          className="object-cover w-100 mx-auto p-0"
                        />
                      </Box>
                    }
                  />
                );
              })}
            </TabList>
          </TabContext>
        </Box>
      </Modal>
    </Wrapper>
    </>
  );
};

export default BusinessDetail;
