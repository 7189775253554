import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const BlogSearchCard = ({ item }) => {
  return (
    <Link to={`/blogs/${item._id}`} className="w-100 m-0 p-0 ">
      <Box
        className="py-5  row-cols-2 row gx-2"
        sx={{
          borderBottom: "1px solid #e2dfdf",
          height: { xs: "200px", md: "300px" },

          "&:hover": {
            cursor: "pointer",
          },
          "&:hover .card-title": {
            textDecoration: "underline",
          },


        }}
      >
        <div className=" d-flex flex-column gap-2 col-9 ">
          <h5 className="card-title text-black fw-bold m-0 p-0">{item.title}</h5>
          <small>
            👉 Posted by Admin on {moment(item?.createdAt).format("MMMM Do YYYY")}
          </small>
          <Box sx={{
            height: { xs: "50px", md: "100px" },
            overflow: "hidden",


          }} className="text-black">
            {typeof item?.content === "string" &&
              HTMLReactParser(item?.content.slice(0, 250) + "...")}
          </Box>
          <div className="bs-cardfooter d-flex align-items-center mt-auto">
            <div className="d-flex gap-1">
              {item?.tags?.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    label={item}
                    size="small"
                    variant="filled"
                  />
                );
              })}
            </div>

          </div>
        </div>

        <div className="bs-pic col-3 p-0   align-items-center   p-0 m-0">
          <Box
            className=" p-0 m-0"
            sx={{
              aspectRatio: "1 / 1",
            }}
          >
            <img
              // src="https://media.istockphoto.com/id/637696304/photo/patan.jpg?s=612x612&w=0&k=20&c=-53aSTGBGoOOqX5aoC3Hs1jhZ527v3Id_xOawHHVPpg="
              src={`${process.env.REACT_APP_IMAGE_PATH}${item.thumbnail}`}
              alt=""
              className="object-cover object-center w-100 h-100 rounded rounded-3 m-0 p-0"
            />
          </Box>
        </div>
      </Box>
    </Link>
  );
};

export default BlogSearchCard;
