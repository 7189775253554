import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import * as React from "react";
import { FormInputB } from "../Index";
// import { FormInputB } from "../../../components/Index";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

export default function ForgotPassword({
  open,
  formvalue,
  handleClose,
  handleFormChnage,
  handleSubmit,
}) {
  return (


    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">Forgot Password</span>
              <small>
                <span className="text-danger">verification link</span>, will be
                sent to your email
              </small>
            </div>
            <form onSubmit={handleSubmit} className="mt-2">
              <FormInputB
                // handleChange={handleChange}
                label={"Registered email address"}
                name={"email"}
                type={"email"}
                handleChange={handleFormChnage}
                //value={formvalue.oldpassword}
                placeholder={"onf@example.com"}
                // error={errors.title}
                // helpertext={errors.title}
                required={true}
              />

              <button className="btn w-100 mt-2 btn-outline-black bg-black text-white">
                Send reset link
              </button>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
