import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageInput from "../../components/Input/ImageInput";
import { CreateBusinessAction } from "../../context/actions/businessAction";
import BusinessContactAndLinks from "./formsteps/BusinessContactAndLinks";
import BusinessIntroduction from "./formsteps/BusinessIntroduction";
import BusinessOwners from "./formsteps/BusinessOwners";
import BusinessTermsAndCondtions from "./formsteps/BusinessTermsAndConditions";
import { clearError } from "../../context/reducers/businessSlice";

const AddBusiness = () => {
  const intro = {
    businessName: "",
    description: "",
    proveniece: "",
    city: "",
    ownerName: "",
    establishedDate: "",
    category: "",
    weoffer: [],
    offers: [],
  };
  //

  const contacts = {
    email: "",
    telephone: "",
    phone: "",
    websiteLink: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    location: [],
    ownerName: "",
    openingHour: "",
  };

  // form steps
  const steps = ["Introduction", "Contacts and links", "Owners", "Agreements"];

  const [introduction, setIntroduction] = React.useState(intro);
  const [contactAndLinks, setContactLinks] = React.useState(contacts);

  const [Owners, setOwners] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [termsandconditions, settermsandconditions] = React.useState(false);
  const [activestepper, setActiveStepper] = React.useState(0);
  const [errors, setErrors] = React.useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();


  // useslector to get the business data
  const { createBusinessLoading, error } = useSelector((state) => ({
    createBusinessLoading: state.business_reducer.createBusinessLoading,
    error: state.business_reducer.error
  }));

  // show error message and clear error message
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError())
  }, [error]);

  // handle introduction form values
  const handleintroduction = (e) => {
    const name = e.target.name;
    switch (name) {
      case "proveniece":
        setIntroduction({
          ...introduction,
          proveniece: e.target.value,
          city: "",
        });

        break;

      case "category":
        setIntroduction({
          ...introduction,
          category: e.target.value,
          offers: [],
        });

        break;

      case "weoffer":
        const newoffer = e.target.value;
        const updateoffer = [...introduction.weoffer].push(newoffer);
        setIntroduction({ ...introduction, weoffer: updateoffer });
        break;

      default:
        setIntroduction({ ...introduction, [e.target.name]: e.target.value });
    }
  };

  // add offer to weoffer
  const addoffer = (value) => {
    setIntroduction({
      ...introduction,
      offers: value,
    });
  };

  //(ADD images) handle image upload on image form value
  const handleimages = React.useCallback(
    (files) => {
      setImages(files);
    },
    [setImages]
  );
  // (REMOVE IMAGES) remove images from introduction form value
  const removeimages = (files) => {
    setImages(files);
  };

  // on province select
  const onprovinceselect = (value) => {
    setIntroduction({ ...introduction, proveniece: value, city: "" });
  };

  // on city select
  const oncityselect = (value) => {
    setIntroduction({ ...introduction, city: value });
  };

  // (GO) Go to next step
  const nextstep = () => {
    setActiveStepper((prev) => prev + 1);
  };

  // (PREV) Go to prev stop
  const prevstep = () => {
    setActiveStepper((prev) => prev - 1);
  };
  // handle contactAnd Links
  const handleContactAndlinks = (e) => {
    setContactLinks({ ...contactAndLinks, [e.target.name]: e.target.value });
  };

  // on location set
  const handleLocation = (location) => {
    setContactLinks((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[1],
        lng: location.geometry.coordinates[0],
        place_name: location.place_name,
      },
    }));
  };

  // on owner change
  const onownerchange = (newlist) => {
    const newlst = [...newlist];
    setOwners(newlst);
  };

  // handle checkbox value
  const togglecheckboxvalue = () => {
    settermsandconditions((prev) => !prev);
  };

  const validateIntroductionForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!introduction.description) {
      formIsValid = false;
      errors.description = "Description is required *";
    }
    if (!introduction.ownerName) {
      formIsValid = false;
      errors.ownerName = "Owner Name is required *";
    }
    if (introduction.offers.length === 0) {
      formIsValid = false;
      errors.offers = "offers is required *";
    }

    if (!introduction.businessName) {
      formIsValid = false;
      errors.businessName = "BusinessName is required *";
    }
    if (!introduction.establishedDate) {
      formIsValid = false;
      errors.establishedDate = "Established Date is required *";
    }
    if (!introduction.openingHour) {
      formIsValid = false;
      errors.openingHour = "Opening Hour is  required *";
    }
    if (!introduction.closingHour) {
      formIsValid = false;
      errors.closingHour = "Closing Hour is  required *";
    }
    if (!introduction.proveniece) {
      formIsValid = false;
      errors.proveniece = "Provenice is  required *";
    }
    if (!introduction.city) {
      formIsValid = false;
      errors.city = "City is  required *";
    }

    if (!introduction.category) {
      formIsValid = false;
      errors.category = "Category is required *";
    }
    setErrors(errors);
    return formIsValid;
  };

  const validateContactAndLinks = () => {
    const errors = {};
    let formIsValid = true;
    if (
      typeof contactAndLinks.email !== "undefined" &&
      contactAndLinks.email !== null
    ) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(contactAndLinks.email)) {
        formIsValid = false;
        errors.email = "Please enter valid email-ID *";
      }
    }

    if (!contactAndLinks.email) {
      formIsValid = false;
      errors.email = "Email is required *";
    }

    if (!contactAndLinks.telephone) {
      formIsValid = false;
      errors.telephone = "Telephone Number is required *";
    }

    if (!contactAndLinks.phone) {
      formIsValid = false;
      errors.phone = "Phone Number is required *";
    }

    if (contactAndLinks.location.length === 0) {
      formIsValid = false;
      errors.location = "Location is required *";
    }
    setErrors(errors);
    return formIsValid;
  };

  const validateImageAndTerms = () => {
    const errors = {};
    let formIsValid = true;
    if (images.length < 5) {
      formIsValid = false;

      errors.images = "At least 5 images should be selected";
    }
    if (images.length > 10) {
      formIsValid = false;

      errors.images = "Max 10 image allowed";
    }

    if (!termsandconditions) {
      formIsValid = false;
      errors.termsandconditions = "Must agree on terms and conditions";
    }

    setErrors(errors);
    return formIsValid;
  };

  // (submit form)
  const handleSubmit = (e) => {
    e.preventDefault();

    // checks introduction part
    if (activestepper === 0) {
      const isvalid = validateIntroductionForm();
      console.log(isvalid);
      if (isvalid) {
        nextstep();
      }
    }

    // checks contactandlink values
    if (activestepper === 1) {
      const isvalid = validateContactAndLinks();
      if (isvalid) {
        nextstep();
      }
    }

    // check if owners are provided or not
    if (activestepper === 2) {
      if (Owners.length > 0) {
        nextstep();
        setErrors({ ...errors, owners: "" });
      } else {
        setErrors({ ...errors, owners: "Please add at leaset one owner *" });
      }
    }

    // checks images and terms and conditions
    if (activestepper === 3) {
      const valid = validateImageAndTerms();
      if (valid) {
        const formData = new FormData();

        // append the images to the images list and send it to the server
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i]);
        }
        formData.append("businessName", introduction.businessName);
        formData.append("ownerName", introduction.ownerName);
        formData.append("openingHour", introduction.openingHour);
        formData.append("closingHour", introduction.closingHour);
        formData.append("description", introduction.description);
        formData.append("establishedDate", introduction.establishedDate);
        formData.append("provenience", introduction.proveniece);
        formData.append("city", introduction.city);
        formData.append("category", introduction.category);
        formData.append("email", contactAndLinks.email);
        formData.append("telephone", contactAndLinks.telephone);
        formData.append("phone", contactAndLinks.phone);
        formData.append("websiteLink", contactAndLinks.websiteLink);
        formData.append("facebookLink", contactAndLinks.facebookLink);
        formData.append("twitterLink", contactAndLinks.twitterLink);
        formData.append("instagramLink", contactAndLinks.instagramLink);
        formData.append("location_lat", contactAndLinks.location.lat);
        formData.append("location_lng", contactAndLinks.location.lng);
        formData.append(
          "location_place_name",
          contactAndLinks.location.place_name
        );
        formData.append("Owner", JSON.stringify(Owners));
        formData.append("offers", JSON.stringify(introduction.offers));
        dispatch(CreateBusinessAction({ toast, formData, navigate }));
      }
    }
  };
  const onError = ({ name, msg }) => {
    setErrors({ ...errors, [name]: msg });
  };
  return (
    <div className="container pt-5">
      <section className="Image-Upload ">
        <ImageInput
          handleimages={handleimages}
          removeimages={removeimages}
          errors={errors}
          filesarray={images}
          onError={onError}
        />
      </section>

      <section className="mt-3 stepper">
        <Stepper activeStep={activestepper} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </section>

      <section className="tab-panel">
        <TabContext value={activestepper.toString()}>
          <TabPanel value="0" className="m-0 p-0">
            <BusinessIntroduction
              handleformvalue={handleintroduction}
              handleSubmit={handleSubmit}
              value={introduction}
              errors={errors}
              onprovinceselect={onprovinceselect}
              oncityselect={oncityselect}
              addoffer={addoffer}
            />
          </TabPanel>
          <TabPanel value="1" className="m-0 p-0">
            <BusinessContactAndLinks
              handleLocation={handleLocation}
              handleSubmit={handleSubmit}
              handleformvalue={handleContactAndlinks}
              errors={errors}
              prevstep={prevstep}
              value={contactAndLinks}
            />
          </TabPanel>
          <TabPanel value="2" className="m-0 p-0">
            <BusinessOwners
              ownerslength={Owners.length}
              value={Owners}
              prevstep={prevstep}
              handleSubmit={handleSubmit}
              onownerchange={onownerchange}
              errors={errors.owners}
            />
          </TabPanel>

          <TabPanel value="3" className="m-0 p-0">
            <BusinessTermsAndCondtions
              value={termsandconditions}
              prevstep={prevstep}
              handleformvalue={togglecheckboxvalue}
              handleSubmit={handleSubmit}
              errors={errors}
            />
          </TabPanel>
        </TabContext>
      </section>
    </div>
  );
};

export default AddBusiness;
