import { Typography, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import HTMLReactParser from "html-react-parser";
import { ArrowRightAlt, BookmarkBorderOutlined, BookmarkOutlined, NorthEastRounded } from "@mui/icons-material";

const BookmarkOrganizationCard = ({ item }) => {
  const dispatch = useDispatch();
  const [liked, setliked] = React.useState(false);

  const [type, setType] = React.useState("Organization");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    location: "",
    category: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));



  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === item?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, item?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: item?._id, data: bookmark }));
    }
  };
  return (
    <Box
      className=" position-relative custom-card"
      sx={{
        aspectRatio: { xs: "1/1.5", lg: "1/1.3" },

        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "3",
          },
        },
      }}
    >
      <Link
        to={`/organizations/${item.id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>
      <Box className="text-center card position-relative w-100 h-100">
        <Box
          sx={{
            width: "100%",
            height: "50%",
            position: "relative",
            top: "0",
            left: "0",
            borderRadius: "10px 10px 0 0 ",
          }}
          className="d-flex align-items-end justify-content-center"
        >
          <Box
            sx={{
              width: "100%",
              height: "70%",
              backgroundColor: "black",
              position: "absolute",
              top: "0",
              left: "0",
              borderRadius: "10px 10px 0 0 ",
            }}
          ></Box>
          <Box
            sx={{
              // height: { xs: "calc(40px + 12vw)", lg: "calc(50px + 6vw)" },
              // width: { xs: "calc(40px + 12vw)", lg: "calc(50px + 6vw)" },
              zIndex: "2",
              border: { xs: "5px solid #F1F1F1", lg: "10px solid #F1F1F1" },
              borderRadius: "50%",
            }}
            className="mx-auto overflow-hidden logo"
          >
            <img
              alt=""
              src={`${process.env.REACT_APP_IMAGE_PATH}${item?.image}`}
              style={{
                cursor: "pointer",
                zIndex: "3",
                backgroundColor: "white",
              }}
              className="object-cover h-100 w-100 "
            />
          </Box>
        </Box>
        <Typography
          variant="p"
          className="orgtitle text-black text-overflow-hidden px-2 px-lg-4"
        >
          {item?.name}
        </Typography>
        <Typography
          variant="p"
          className="border-0 bg-hover province border mx-auto px-2 px-lg-4 text-black text-nowrap"
        >
          {item?.location}
        </Typography>
        <Box className="mx-3 ">
          <button className="btn btn-danger btn-sm  mt-4">
            Show More <NorthEastRounded
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            />
          </button>
        </Box>
        <Divider className="my-2" />
        <Box
          sx={{
            fontSize: { xs: "0.5em" },
          }}
          className="d-flex justify-content-between align-items-center mt-2 px-1 px-lg-4 py-2 mt-auto"
        >
          <Typography
            variant="span"
            className="btn btn-sm  btn-outline-black category-box"
          >
            {item?.category}
          </Typography>
          <Box className="org_footer  d-flex gap-2 align-items-center">

            <Box
              sx={{
                zIndex: "4",
              }}
              className="text-black"
            >
              {liked ? (
                <BookmarkBorderOutlined
                  role="button"
                  className="cIcon"
                  onClick={togglelike}
                />
              ) : (
                <BookmarkOutlined
                  role="button"
                  className="cIcon"
                  onClick={togglelike}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookmarkOrganizationCard;
