
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";

import {
  Box,
  Container,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminHelpAndSupportTable from "./AdminHelpAndSupportTable";

const AdminHelpAndSupport = () => {
 
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
          <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  Help & Support Request
                </span>
                <small>
                  All Help & Support Request, you can update and reply to them
                </small>
              </div>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OutlinedInput
                sx={{}}
                size="small"
                defaultValue=""
                fullWidth
                placeholder="Search Help And Support"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(event) => {
                  if (event.target.value === "") {
                    setKeyword("");
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setKeyword(event.target.value);
                  }
                }}
              />
        
            </Stack>

            <AdminHelpAndSupportTable keyword={keyword} />
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default AdminHelpAndSupport;
