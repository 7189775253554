import React from "react";
import { FormInputB } from "../../../../components/Index";
import AdminEventImage from "./AdminEventImage";
import { Box } from "@mui/material";
import {toast} from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {adminCreateEventAction} from "../../../../context/actions/admin/eventAction";
import { clearError } from "../../../../context/reducers/adminSlice";

const AdminAddEvent = () => {

  const dispatch = useDispatch();
  const [images, setImages] = React.useState({
    files: [],
    pImages: [],
    nImages: [],
  });

  const [errors, setErrors] = React.useState({});

  const [formValue, setFormValue] = React.useState({
    eventTitle: "",
    establishedDate: "",
  });

  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  // handle image upload and remove images
  const handleimages = React.useCallback(
    ({ files, pImages }) => {
      setImages({ ...images, files: files, pImages: pImages });
    },
    [images]
  );

  // handle image upload and remove images
  const removeImages = React.useCallback(
    ({ files, pImages, nImages }) => {
      setImages({
        ...images,
        // send to api
        files: files,
        pImages: pImages,
        // send to api
        nImages: nImages,
      });
    },
    [images]
  );

  const onSubmit = () => {
    let isValid = true;
    const newerror = {};
    if (
      !formValue.eventTitle ||
      !formValue.establishedDate ||
      images.pImages.length === 0
    ) {
      if (!formValue.eventTitle) {
        newerror.eventTitle = "Please provide title *";
      }
      if (!formValue.establishedDate) {
        newerror.establishedDate = "Please provide Date*";
      }
      if (images.pImages.length === 0) {
        newerror.images = "Please provide at lease one images*";
      }
      isValid = false;
    }

    setErrors(newerror);
    return isValid;
  };

  const handleformSubmit = (e) => {
    e.preventDefault();
    const valid  = onSubmit(e);
    if(valid) {
     const data = new FormData();
      data.append("eventName", formValue.eventTitle);
      data.append("date", formValue.establishedDate);
      for (let i = 0; i < images.files.length; i++) {
        data.append("images", images.files[i])
      }
      dispatch(adminCreateEventAction({data, toast}))
      
    }
    return 
  }

  const { adminCreateEventLoding, error } = useSelector((state) => ({
    adminCreateEventLoding: state.admin_reducer.adminCreateEventLoding,
    error: state.admin_reducer.error,
  }));
   
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);



  return (
    <Box className="container position-relative">
      <div className="d-flex flex-column">
        <span className="fs-5 fw-bold text-black">
          Adding Event details with Gallary
        </span>
        <small>
          Commonly required by the university and immigration authorities
        </small>
      </div>
      <form onSubmit={handleformSubmit}>
        <FormInputB
          name={"eventTitle"}
          label={"Title for the event"}
          required={true}
          classname={"form-control"}
          placeholder={"Enter Firstname"}
          value={formValue.title}
          handleChange={handleChange}
          error={errors.eventTitle ? true : false}
          helpertext={errors.eventTitle}
        />
        {/* select date */}
        <FormInputB
          name={"establishedDate"}
          // handleChange={handleformvalue}
          label={"Event Date"}
          required={true}
          type={"date"}
          handleChange={handleChange}
          error={errors.establishedDate ? true : false}
          helpertext={errors.establishedDate}
        />

        <small
          id="emailHelp"
          className={errors.images ? "form-text text-danger" : "d-none"}
        >
          {errors.images}
        </small>
        <AdminEventImage
          handleimages={handleimages}
          pImages={images.pImages}
          imageFile={images.files}
          nImages={images.nImages}
          removeImages={removeImages}
        />

        <div className="d-flex justify-content-end align-items-center position-fixed py-2 bottom-0 start-0 w-100 px-2">
          <button className="btn bg-black text-white" onClick={onSubmit}>
            Save
          </button>
        </div>
      </form>
    </Box>
  );
};

export default AdminAddEvent;
