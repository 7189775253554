import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Typography from "@mui/material/Typography";
import { List, ListItem, Box } from "@mui/material";
const DurationSelect = ({
  minimumStay,
  handleFormValue,
  name,
  durations,
  errrors,
  height,
  placeholder,
}) => {
  const [selected, setselected] = React.useState(false);
  const menuRef = React.useRef(null);

  React.useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("click", closeselect);

    // remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", closeselect);
    };
  }, []);

  const toggleselect = (e) => {
    e.stopPropagation();
    setselected((prev) => !prev);
  };

  const closeselect = (e) => {
    e.stopPropagation();

    if (menuRef.current && !menuRef.current.contains(e.target)) {
      if (document.contains(menuRef.current)) {
        // check if component is still mounted
        setselected(false);
      }
    }
  };

  const ondurationSelect = (e, item) => {
    e.stopPropagation();
    if (item) {
      handleFormValue(name, item);
      setselected(false);
    }
  };
  return (
    <div
      className="d-flex flex-column row-cols-1 align-items-end p-0 m-0 w-100 position-relative"
      role="button"
    >
      <Box
        className="row-cols-2 row  rounded-2 rounded border border-2 py-2 px-0 m-0 "
        onClick={toggleselect}
        sx={{
          height: height,
        }}
      >
        <Typography
          variant="span"
          className="col-3  text-start p-0 ps-3"
          sx={{
            borderRight: "1px solid black ",
          }}
        >
          Duration
        </Typography>
        <div className="row row-cols-1 col-9 ">
          <select
            name=""
            id=""
            className="form-control border-0"
            value={minimumStay}
            onChange={(e) => {
              e.stopPropagation();
              ondurationSelect(e, e.target.value);
            }}
          >
            <option value="">{placeholder}</option>;
            {durations.map((item, index) => {
              return <option key={index}>{item}</option>;
            })}
          </select>
        </div>
      </Box>

      {/* <List
        ref={menuRef}
        className={
          selected
            ? "list-unstyled col-9   m-0 p-2 position-absolute top-100 end-0 bg-white shadow shadow-1"
            : "d-none"
        }
        sx={{
          zIndex: "3",
          border: "2px solid var(--hover-color)",
          borderTop: "none",
        }}
      >
        {durations.map((item, index) => {
          return (
            <ListItem
              role="button"
              sx={{
                "&:hover": {
                  backgroundColor: "var(--hover-color)",
                },
              }}
              className="py-2"
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                ondurationSelect(e, item);
              }}
            >
              {item}
            </ListItem>
          );
        })}
      </List> */}
    </div>
  );
};

export default DurationSelect;
