import ProtectApi from "./adminAuthorizeApi";

// create faq
export const createFaq = (data) =>
  ProtectApi.post("api/admin/faq/create_faq", data);


// get all faq
 
export const getAllFaq = (keyword="", page) =>
  ProtectApi.get(`api/admin/faq/get_all_faq?keyword=${keyword}&page=${page}`);

  // edit faq
export const editFaq = (id, data) =>
ProtectApi.put(`api/admin/faq/edit_faq/${id}`, data);
