import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  overflow: hidden;
  & > .preview-img {
    transition: transform 0.3s ease;
  }

  & > .preview-btn {
    transition: opacity 0.3s ease;
  }

  &:hover {
    & > .preview-btn {
      opacity: 1;
    }

    & > .preview-img {
      transform: scale(1.1);
    }
  }

  & > .preview-btn {
    opacity: 0;
    position: absolute;
    width: 30%;
    padding: 5px 10px;
    height: 45px;
    border-radius: 5px;
    background-color: rgba(112, 112, 112, 0.79);
    backdrop-filter: blur(15px);
    border: none;
    color: white;
  }
`;
const ImgPreivew = ({ img, ShowPreview }) => {
  return (
    <Wrapper>
      <img
        src={img}
        className="object-cover w-100 preview-img rounded rounded-3 mb-3"
        alt=""
        srcSet=""
        height={"300px"}
      />
      <button className="preview-btn" onClick={() => ShowPreview(img)}>
        Preview
      </button>
    </Wrapper>
  );
};

export default ImgPreivew;
