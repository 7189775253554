import styled from "styled-components";

const Wrapper = styled.div`
  width: 70vw;
  @media (max-width: 600px) {
    width: 100vw;
  }

  & > .profile {
    & > .overview-header {
      width: 100%;
      height: 30vh;

      position: relative;
      display: flex;
      align-items: center;
      & > .overview-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 55%;

        overflow: hidden;
      }
    }

    & > .overview-info {
      line-height: 5px;
    }
  }
`;

export default Wrapper;
