import React from "react";
import { FormInputB } from "../../components/Index";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createContactUs } from "../../context/actions/authAction";

function ContactusPage() {
  const dispatch = useDispatch();


  const [formData, setFormData] = React.useState({
    fullname: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleChnage = (e) => {

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createContactUs({ formData, toast }));
  };

  return (
    <div className="container mt-2">
      <div className="card shadow-0 mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between flex-wrap-reverse">
            <div className="p-5">
              <h5 className="card-title fs-3 fw-bold">
                Contact Us
              </h5>
              <p className="card-text">
                get in touch with us for any query
              </p>
              <hr />
              <p className="fw-bold">Our service is related to following settings.</p>
              <ul>
                  <li>
                      Studnet Visa and Work Permit
                  </li>
                  <li>
                      PR and Citizenship
                  </li>
              </ul>
              <hr />
              <p className="fw-bold">Where we are located?</p>
              <ul>
                <li>Cananda: 123 Steet</li>
                <li>Nepal: Putalisadak, Kathmandu Nepal</li>
              </ul>
            </div>

            <div class="col-md-10 mx-auto col-lg-5">
              <form onSubmit={handleSubmit}>
                <div className="card p-5 shadow-darken10">
                  <div className="mt-2">
                    <label htmlFor="">This question is about</label>
                    <select
                      className="form-select form-select-lg rounded-0 py-2 rounded-2 "
                      aria-label=".form-select-lg example"
                      name="category"
                    >
                      <option value="">This enquiry is about</option>
                      <option value="Student Visa">Student Visa</option>
                      <option value="Work Permit">Work Permit</option>
                      <option value="PR">PR & Citizenship</option>
                      <option value="Other">Other</option>

                    </select>
                  </div>
                  <div className="d-flex gap-2">
                    <FormInputB
                      name={"fullname"}
                      label={"FullName*"}
                      required={true}
                      classname={"form-control"}
                      placeholder={"Enter Your Name"}
                      handleChange={handleChnage}
                    />
                  </div>
                  <FormInputB
                    name={"email"}
                    label={"Email Address*"}
                    required={true}
                    classname={"form-control"}
                    placeholder={"Enter valid Email Address"}
                    handleChange={handleChnage}
                  />
                  <FormInputB
                    name={"phone"}
                    label={"Phone Number*"}
                    required={true}
                    classname={"form-control"}
                    placeholder={"Enter valid Phone Number"}
                    handleChange={handleChnage}
                  />

                  {/* textarea and lael for desription */}
                  <div className="mt-2">
                    <label htmlFor="">Explain your question in details*</label>
                    <textarea
                      className="form-control"
                      name="description"
                      rows="5"
                      placeholder="Write a message here..."
                      onChange={handleChnage}
                    ></textarea>
                  </div>

                  <div className="mt-3">
                    <button className="btn btn-primary w-100">
                      Submit Your Request
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactusPage;
