import React from "react";

import { Chip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

const AdminBusinessDetailOffers = ({ offers }) => {
  return (
    <section>
      <h5 className="fw-bold mt-4">We offers.</h5>
      {/* icons and text */}

      <div className="d-flex gap-2 flex-wrap">
        {offers.map((item, index) => (
          <Chip
            label={item}
            key={index}
            icon={
              <DoneIcon
                sx={{
                  color: "green",
                }}
              />
            }
          />
        ))}
      </div>
    </section>
  );
};

export default AdminBusinessDetailOffers;
