import { Chip, Input, List, ListItem } from "@mui/material";
import { Box } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useRef } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const MultipleLocationInput = ({
  handleLocation,
  placeholder,
  isBorder,

  locationlst,
}) => {
  const [results, setResults] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [value, setValue] = useState("");

  const [isFocused, setIsFocused] = useState(false);
  const boxRef = useRef(null);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleSearch = (query) => {
    setValue(query);

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // If search query is empty or null, clear the results
    if (!query) {
      setResults([]);
      return;
    }

    // Set a new timeout to trigger the request after 3 seconds
    const newTimeoutId = setTimeout(() => {
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?country=ca&proximity=ip&types=postcode&access_token=pk.eyJ1Ijoicm9hZHdheW1hbiIsImEiOiJjbGY3ejR3ZjkwYnlrM3NudjJkYzgxcnRtIn0.jdReqoWAgSK93Ruy1iPRSQ`
        )
        .then((res) => {
          console.log(res.data);
          setResults(res.data.features);
        })
        .catch((error) => {
          console.log("Request error:", error.message);
        });
    }, 10);

    // Store the timeout ID for later reference
    setTimeoutId(newTimeoutId);
  };

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onLocationTap = (item) => {
    setIsFocused(false);
    handleLocation("location", item);
    setValue("");
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      handleLocation("location", e.target.value);
      setValue("");
    }
  };

  return (
    <>
      <Box
        className={
          "position-relative w-auto custom-card  rounded rounded-1 p-2 "
        }
        onClick={(e) => {
          e.stopPropagation();
          boxRef.current.click();
          setIsFocused(true);
        }}
        sx={{
          zIndex: 1,

          outline: isFocused ? "2px solid black" : "1px solid grey",
        }}
      >
        <div className="d-flex flex-wrap gap-1">
          {locationlst?.map((item, index) => {
            return (
              <Chip
                label={item}
                onDelete={() => handleLocation("removelocation", index)}
              />
            );
          })}
          <Input
            disableUnderline
            autoComplete="shipping address-line1"
            onChange={(e) => handleSearch(e.target.value)}
            value={value}
            className="w-maxcontent"
            onFocus={handleFocus}
            type="text"
            placeholder={placeholder}
            ref={boxRef}
            onKeyPress={onEnter}
            sx={
              isBorder
                ? {
                    outline: "none",
                    border: isFocused ? "2px solid blue" : "1.5px solid black",
                    transition: "border-bottom 0.3s ease",
                    borderRadius: "5px",
                    padding: 0.5,
                  }
                : {}
            }
          />
        </div>

        {isFocused && value.length > 3 && (
          <List
            className="position-absolute top-100 start-0 p-0 m-0 w-100 border border-2 border-dark rounded rounded-2"
            sx={{
              backgroundColor: "white",
            }}
          >
            {results.map((result, index) => (
              <ListItem
                key={result.id}
                role="button"
                className="m-0 p-1 d-flex w-100  align-items-center gap-1 "
                sx={{
                  minHeight: "3rem",
                  fontSize: "0.8rem",
                  "&:hover": {
                    background: "#797979",
                    color: "black",
                  },
                }}
                onClick={(e) => {
                  const item = result.place_name
                    ? result.place_name
                    : result.place_name;
                  e.stopPropagation();
                  onLocationTap(item);
                }}
              >
                <div className="d-flex  align-items-start gap-1 w-100">
                  <LocationOnOutlinedIcon
                    sx={{
                      fontSize: "1.2em",
                    }}
                  />{" "}
                  <span>
                    {result.place_name
                      ? result.place_name
                      : result.place_name}
                  </span>
                </div>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </>
  );
};

export default MultipleLocationInput;
