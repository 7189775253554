import { createAsyncThunk } from "@reduxjs/toolkit";

import * as abroad_study_api from "../../api/abroad_study_api";

export const GetAllCountry = createAsyncThunk(
  "abroad_study/get_all_country",
  async (__, { rejectWithValue }) => {
    try {
      const response = await abroad_study_api.get_all_country();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


//get_abroad_study_details
export const getAbroadStudyDetailsAction = createAsyncThunk(
  "abroad_study/get_abroad_study_details",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await abroad_study_api.getAbroadStudyDetails(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get abroad study requirements
export const getAbroadStudyRequirementsAction = createAsyncThunk(
  "abroad_study/get_abroad_study_requirements",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await abroad_study_api.getAbroadStudyRequirements(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);