import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { SiNamecheap } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { get_all_rental_data_by_user } from "../../context/actions/authAction";
import { NorthEastOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

const RentalContact = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_all_rental_data_by_user());
  }, [dispatch]);

  const { user_rental_data } = useSelector((state) => ({
    user_rental_data: state.user_reducer.user_rental_data,
  }));

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showSidebar, setShowSidebar] = useState(true);

  const handlePropertyClick = (property) => {
    setSelectedProperty(property);
    setShowSidebar(false); // Hide the sidebar when a property is selected
  };

  const handleBackButtonClick = () => {
    setShowSidebar(true); // Show the sidebar when the back button is clicked
  };

  return (
    <Box
      className="container shadow-4 p-3 rounded-4 d-flex gap-2"
      sx={{ height: "100vh" }}
    >
      <Box
        sx={{
          display: { xs: showSidebar ? "block" : "none", sm: "block" },
          width: { xs: "100%", sm: "30%" },
        }}
      >
        <div className="d-flex flex-column mt-3 mb-3">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Rental</span> Contact
          </span>
          <small>
            All your rental property and contacts
          </small>
        </div>
        <hr />

        {user_rental_data?.map((property, index) => (
          <div
            className="card p-2 mb-2 shadow-3"
            key={index}
            onClick={() => handlePropertyClick(property)}
          >
            <div className="d-flex align-items-center gap-2">
              <img
                src={`${process.env.REACT_APP_IMAGE_PATH}${property?.images[0]}`}
                height="50"
                width="50"
                className="rounded-3 object-cover"
                alt=""
              />
              <div className="gap-0">
                <h6 className="m-0 p-0 fw-bold">{property?.title.slice(0, 25)}</h6>
                <small>{property?.location?.place_name.slice(0, 25)}</small>
              </div>
            </div>
          </div>
        ))}

      </Box>

      <Box
        className="w-100"
        sx={{ display: { xs: showSidebar ? "none" : "block", sm: "block" } }}
      >
        {selectedProperty ? (
          <div className="Detail border rounded-4 shadow-3 p-3">
            <div className="d-flex justify-content-between">
              <div>
                <h5 className="fw-bold fs-4 m-0 p-0">{selectedProperty?.title}</h5>
                <p>{selectedProperty?.location?.place_name}</p>
              </div>
              <Button
                sx={{
                  display: { xs: "block", sm: "none" },
                }}
                onClick={handleBackButtonClick}
              >
                Back
              </Button>
              <Box
                sx={{
                  display: { xs: "none", sm: "block" },
                }}

              >
                <Link
                  to={`/rental/${selectedProperty?._id}`}>
                  View property <NorthEastOutlined />
                </Link>
              </Box>

            </div> <hr />
            <Box sx={{
              // whatever the screen size is, the height should cover the whole screen 
              height: "80vh",

              overflowY: 'auto',
              scrollbarWidth: "thin",

            }}>
              {selectedProperty?.contact?.length > 0 ? (
                selectedProperty.contact.map((contact, index) => (
                  <div className="border rounded-4 mb-3 p-3" key={index}>
                    <div className="d-flex justify-content-between">
                      <h5> <SiNamecheap />{contact?.name}</h5>

                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id={`flexSwitchCheckChecked_${index}`}
                          checked
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`flexSwitchCheckChecked_${index}`}
                        >
                          Contacted
                        </label>
                      </div>
                    </div>
                    <p><MdEmail /> Email: {contact?.email}</p>
                    <p><FaPhoneAlt /> Phone: {contact?.phone}</p>
                    <p> <BsFillChatDotsFill /> Message: {contact?.message}</p>
                  </div>
                ))
              ) : (
                <p>No contact information available.</p>
              )}
            </Box>

          </div>
        ) : (
          <Box sx={{
            height: "85vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: "10px",
            backgroundColor: "#F1F8FE"

          }}>
            <div>
              <img src="/assets/icons/select.png" alt="" width={100} />
              <p className="fs-2 text-danger">
                Select a property to continue!
              </p>
              <p>
                Click on a property from the list to view its contact information
              </p>
            </div>



          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RentalContact;
