import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/flatemateApi";


export const adminEditFlatemateAction = createAsyncThunk(
  "admin/edit_flatemate",
  async ({ id, formData, activeindex, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditFlatemate(id, formData);
      const response2 = { data: response.data, activeIndex: activeindex };
      toast.success("Flatmate updated successfully");
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const adminCreateFlatemateAction = createAsyncThunk(
  "admin/create_flatemate",
  async ({ data, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateFlatemate(data);
      toast.success("Flatemate created successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//admin_get_all_flatemate
export const adminGetAllFlatemateAction = createAsyncThunk(
  "admin/get_all_flatemate",
  async ({ keyword = "", page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllFlatemate(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//admin_change_status_flatemate
export const adminChangeFlatemateStatusAction = createAsyncThunk(
  "admin/change_flatemate_status",

  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    
    try {
      const response = await admin_api.adminChangeFlatemateStatus(id, data);
      toast.success("Flatemate status updated successfully");
       return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured flatemate

export const adminUpdateFeaturedFlatemateAction = createAsyncThunk(
  "admin/update_featured_flatemate",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateFeaturedFlatemate(id, data);
      toast.success("Flatemate featured updated successfully");
      return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
