import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/rentalApi";


// adminCreateRental
export const adminCreateRentalAction = createAsyncThunk(
  "admin/create_rental",
  async ({ data, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateRental(data);
      toast.success("Rental created successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//admin_get_all_rentals
export const adminGetAllRentalAction = createAsyncThunk(
  "admin/get_all_rentals",
  async ({ keyword = "", page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllRental(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


//admin_edit_rental
export const adminEditRentalAction = createAsyncThunk(
  "admin/edit_rental",
  async ({ id, formData, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditRental(id, formData);
      const response2 = { data: response.data, activeIndex: activeindex };
      toast.success("Rental updated successfully");
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//admin_change_status_rental
export const adminChangeRentalStatusAction = createAsyncThunk(
  "admin/change_rental_status",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminChangeRentalStatus(id, data);
      toast.success("Rental status updated successfully");
      return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured Rental

export const adminUpdateFeaturedRentalAction = createAsyncThunk(
  "admin/update_featured_rental",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateFeaturedRental(id, data);
      toast.success(response.data.message);
      return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
