import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { OrganizationCard } from "../../components/Index";
import { getorganizationAction } from "../../context/actions/organizationAction";
import province from "../../utils/all_province";
import { Box, Divider, Typography } from "@mui/material";
import OrganizationCardSkeleton from "./component/OrganizationCardSkeleton";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    border: "2px solid #b4b4b4", // Add this line
    borderRadius: "5px", // Also add this line
  },
}));

const ListofOrg = () => {
  // make useState to set loading state
  const [loaded, setLoaded] = useState(false);

  // dispatch action initilized here
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // use useCallback to memoize the function
  const get_all_blogs = useCallback(() => {
    dispatch(getorganizationAction({})).then(() => {
      setLoaded(true);
    });
  }, []);

  // call the function to get all organization
  useEffect(() => {
    get_all_blogs();
  }, [get_all_blogs]);

  // select the data from organization reducer
  const { organization, loading, view } = useSelector(
    (state) => state.organization_reducer
  );

  const educational_organization = organization.filter(
    (org) => org.introduction.category === "Educational"
  );

  // handle keypress event
  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      navigate(`/organizations/filter/?search=${event.target.value}`);
    }
  };

  // return the jsx
  return (
    <Box sx={{ minHeight: "100vh" }} className="container mt-4  ">
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <div className="d-flex flex-column">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Featured</span> Organizations
          </span>
          <small>
            Showing {organization.length} featured organizations on ONF Canada
          </small>
        </div>
        <div className="d-flex justify-content-between  align-items-center gap-2 ">
          <div class="btn-group shadow-0 me-auto me-md-0">
            <button
              type="button"
              className="btn btn-light bg-greyx rounded text-nowrap h-maxcontent"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              Select Provenince
            </button>
            <ul class="dropdown-menu">
              {province.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="dropdown-item"
                      to={`/organizations/filter/?province=${item}`}
                    >
                      {item}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onKeyPress={handleKeypress}
              />
            </Search>
          </div>
        </div>
      </div>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "repeat(2, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, minmax(200px, 1fr))",
            lg: "repeat(4, minmax(250px, 1fr))",
          },
          gridAutoColumns: { sm: "150px" },
          gap: "2%",
          "@media (min-width: 767px) and (max-width: 960px)": {
            gridTemplateColumns: "repeat(3 , 1fr)",
          },
        }}
        className="  w-100 py-3 "
      >
        {loading || !loaded ? (
          <>
            {Array.from({ length: 8 }, (_, index) => (
              <OrganizationCardSkeleton index={index}/>
            ))}
          </>
        ) : (
          organization?.map((item) => {
            return (
              <Box sx={{}} key={item._id} className=" ">
                <OrganizationCard item={item} view={view} />
              </Box>
            );
          })
        )}
      </Box>

      <button
        type="button"
        className="btn btn-outline-black border-0  w-100  mt-2 mb-2"
        data-mdb-ripple-color="dark"
      >
        View all popular organization
      </button>

      <div className="d-flex justify-content-between mt-2">
      <div className="d-flex flex-column mb-2">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Educational</span> Organizations
          </span>
          <small>
            Showing Educational organizations for you based on ranking.
          </small>
        </div>

        <Typography
          variant="span"
          className="rounded rounded-2 h-maxcontent text-black"
          role="button"
          sx={{
            border: { xs: "none", md: "2px solid black" },
            padding: { md: "0.4em 1.5em" },
            textDecoration: { xs: "underline", md: "none" },
            fontWeight: { md: "bold" },
            fontSize: { md: "0.8rem" },
            textTransform: { md: "capitalize" },
          }}
        >
          see all
        </Typography>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4   ">
        {loading || !loaded ? ( // use loading state to render skeleton
          <>
            {Array.from({ length: 5 }, (_, index) => (
              <div key={index} className="col">
                <Box
                  sx={{
                    width: "100%",
                    height: "400px",
                  }}
                  className="text-center card col p-4 mt-3 position-relative"
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "38%",
                      backgroundColor: "#c8c8c8",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      borderRadius: "10px 10px 0 0 ",
                    }}
                  ></Box>
                  <Skeleton
                    circle={true}
                    height={"calc(50px + 7vw)"}
                    width={"calc(50px + 7vw)"}
                    className="object-cover mt-3 mx-auto"
                  />
                  <h5 className="text-black mt-3">
                    <Skeleton />
                  </h5>

                  <Typography
                    className="mt-2 text-black"
                    variant="p"
                    sx={{
                      lineHeight: "1.5",
                      fontSize: "clamp(0.7rem,1vw,1rem)",
                    }}
                  >
                    <Skeleton count={2} />
                  </Typography>
                  <Divider className="my-2" />
                  <div className="d-flex justify-content-between mt-2  mt-auto">
                    <span>
                      <Skeleton width={80} height={20} />
                    </span>
                    <div className="org-two_interaction d-flex gap-2 align-items-center">
                      <div className="d-flex">
                        <Skeleton circle={true} height={20} width={20} />
                        <span className="text-black">
                          <Skeleton />
                        </span>
                      </div>
                      <div className="d-flex text-black">
                        <Skeleton circle={true} height={20} width={20} />
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            ))}
          </>
        ) : (
          <>
            {educational_organization.map((item) => {
              return (
                <div key={item._id} className="row row-cols-2 row-cols-lg-4">
                  <Link to={`/organizations/${item._id}`}>
                    <OrganizationCard item={item} view={view} />
                  </Link>
                </div>
              );
            })}
          </>
        )}
      </div>
    </Box>
  );
};

export default ListofOrg;
