import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Button } from '@mui/material';
// import { FcFilledFilter } from 'react-icons/fc';

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function BlogNav() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="d-flex">
                <Box sx={{ width: '100%' }}>

                    <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                        <LinkTab label="All Blogs" href="/drafts" />
                        <LinkTab label="Business" href="/trash" />
                        <LinkTab label="Organizations" href="/spam" />
                        <LinkTab label="Student Permit" href="/spam" />
                    </Tabs>
                    
                </Box>
                < Button>
                    {/* Filter <FcFilledFilter size={20} /> */}
                </Button>
            </div>
        </>
    );
}