import { Box, List, ListItem } from "@mui/material";
import React from "react";
import SelectSearch from "../../../components/Input/SelectSearch";
import province from "../../../utils/all_province";
import { professions } from "../../../utils/professionSettings";

const ProfessionalFilterSidebar = ({
  filtered,
  handlecityChange,
  selectprovince,
  selectcategory,
}) => {
  return (
    <Box className="">
      <h4>Professional Filtering</h4>
      <div className="d-flex flex-column mt-3 w-75">
        <div
          className="accordion border-0 p-0 m-0 rounded-0"
          id="accordionExample"
        >
          <div className="accordion-item border-0 p-0 m-0">
            <h2 className="accordion-header p-0 m-0" id="headingOne">
              <button
                className="accordion-button p-0 m-0 border-0"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <h6 className="fw-bold">Provenience (13)</h6>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body p-0 m-0">
                <List>
                  {province.map((item) => {
                    return (
                      <ListItem
                        key={item}
                        variant="p"
                        className="p-0 m-0"
                        role="button"
                        onClick={() => {
                          selectprovince(item);
                        }}
                        sx={{
                          "&:hover": {
                            color: "#799aed",
                          },
                        }}
                      >
                        {item}
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </div>
          {/* --------------------------------------------- */}
          <div className="accordion-item border-0 ">
            <h2 className="accordion-header p-0 m-0" id="headingTwo">
              <button
                className="accordion-button collapsed accordion-button p-0 m-0 show"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6 className="mt-2 fw-bold">
                  Cities in '
                  {filtered.province ? filtered.province : "Select Province"}'
                </h6>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse"
              aria-labelledby="headingTwo"
              data-mdb-parent="#accordionExample"
            >
              <div className="accordion-body p-0 m-0 pt-2 border-0">
                {filtered.province ? (
                  <SelectSearch
                    province={filtered.province}
                    handleChange={handlecityChange}
                    city={filtered.city}
                  />
                ) : (
                  <>
                    <select
                      class="form-select p-1"
                      aria-label="Default select example"
                      disabled
                    >
                      <option selected>Select Cities</option>
                    </select>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* ssssssssssssssssssssss */}
          <div className="accordion-item border-0 p-0 mt-2">
            <h2 className="accordion-header p-0 m-0" id="headingThree">
              <button
                className="accordion-button collapsed accordion-button p-0 m-0 show"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6 className="mt-2 fw-bold">Category</h6>
              </button>
            </h2>
            <div
              id="collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-mdb-parent="#accordionExample"
            >
              <div class="accordion-body m-0 p-0">
                <List>
                  {professions.map((item) => {
                    return (
                      <ListItem
                        key={item.value}
                        variant="p"
                        className="p-0 m-0"
                        role="button"
                        sx={{
                          "&:hover": {
                            color: "#799aed",
                          },
                        }}
                        onClick={() => {
                          selectcategory(item.value);
                        }}
                      >
                        {item.value}
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default ProfessionalFilterSidebar;
