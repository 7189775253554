import React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import PublicIcon from "@mui/icons-material/Public";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import AddLocation from "../../../../../components/location/AddLocation";
import ShowLocation from "../../../../../components/location/ShowLocation";
const ContactDetails = ({ item }) => {
  return (
    <div className="d-flex flex-column gap-3 ">
      <section className="d-flex gap-2 flex-column fs-5">
      <span className="fs-5 fw-bold">Contact Information</span>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <EmailIcon />
            <span className="fs-6">Email Address</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.email}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PhoneIcon />
            <span className="fs-6">Phone Number</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.phone}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PhoneIcon />
            <span className="fs-6">Phone Number</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.telephone}</p>
        </div>
      </section>

      <section className="d-flex gap-2 flex-column fs-5">
        <span className="fs-5 fw-bold">Social Media Links</span>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <PublicIcon />
            <span className="fs-6">Personal Website</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.websiteLink}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <FacebookIcon />
            <span className="fs-6">FaceBook Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.facebookLink}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <TwitterIcon />
            <span className="fs-6">Twitter Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.twitterLink}</p>
        </div>

        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <InstagramIcon />
            <span className="fs-6">Instagram Link</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 fw-bold mt-1">{item?.instagramLink}</p>
        </div>
      </section>

      <section className="d-flex flex-column gap-2">
        <span className="fs-5 fw-bold">Location On Map</span>
        <ShowLocation
          location={item?.location}
        />
      </section>
    </div>
  );
};

export default ContactDetails;
