import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { adminCreateRentalAction } from "../../../../context/actions/admin/rentalAction";
import {
  TenantAccess,
  TentantLocation,
  PropertyOffer,
  RentalImageUpload,
  AboutProperty,
  PropertyPrice,
  TypeofProperty,
} from "../../../addrental/formstepper/index";
import { ImCross } from "react-icons/im";

import { Box, Container } from "@mui/material";
import { clearError } from "../../../../context/reducers/adminSlice";
const AdminAddRental = ({ onClose }) => {
  const dispatch = useDispatch();

  const formvalues = {
    location: "",
    title: "",
    houseType: "",
    accessTo: "",
    offers: [],
    bathroom: 0,
    kitchen: 0,
    bedroom: 0,
    price: 0,
    minimumStay: "6 month",
    availableDate: "",
    description: "",
  };

  const formerrors = {
    images: "",
    description: "",
    title: "",
    price: "",
    minimumStay: " ",
    availableDate: "",
    message: "",
    houseType: "",
  };


   const { adminCreateRentalLoading, error } = useSelector((state) => ({
     error: state.admin_reducer.error,
     adminCreateRentalLoading: state.admin_reducer.adminCreateRentalLoading,
   }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

    

  // activesteo indcates current step of the form ( 1 - 7)
  const [activeStep, setActiveStep] = React.useState(0);

  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formvalues);
  // all the uploaded images are in images state
  const [images, setImages] = React.useState({ files: [], pImages: [] });
  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);
  // first uploaded image are saved in reviewImg state

  // handle house type
  const handleHouseType = (value) => {
    setformvalues({ ...formvalue, houseType: value });
  };
  // form validation returns true or false when next button clicked
  const formvalidation = () => {
    let isValid = false;
    const newerror = { ...formerrors };

    if (
      !formvalue.houseType ||
      !formvalue.accessTo ||
      !formvalue.location ||
      images.files.length < 5 ||
      images.files.length > 10 ||
      !formvalue.title ||
      !formvalue.description ||
      !formvalue.availableDate ||
      formvalue.price === 0 ||
      !formvalue.price
    ) {
      if (!formvalue.houseType) {
        newerror.houseType = "Please select house types *";
      }
      if (!formvalue.accessTo) {
        newerror.accessTo = "Please select access to *";
      }
      if (!formvalue.location) {
        newerror.location = "Please provide location *";
      }
      if (images.files.length < 5) {
        newerror.images = "Please add 5 images *";
      }
      if (images.files.length > 10) {
        newerror.images = "Max 10 images allowed *";
      }
      if (!formvalue.title) {
        newerror.title = "Please provide title *";
      }
      if (!formvalue.description) {
        newerror.description = "Please provide description *";
      }
      if (!formvalue.availableDate) {
        newerror.availableDate = "Please provide available Date *";
      }

      if (formvalue.price === 0 || !formvalue.price) {
        newerror.price = "Please provide price *";
      }
      newerror.message = "PLease enter required fields. *";
    } else {
      isValid = true;
    }
    setErrors(newerror);

    return isValid;
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "price":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setformvalues({ ...formvalue, price: parseInt(value) });
        }
        break;

      case "addprice":
        if (value === "") {
          setformvalues({ ...formvalue, price: 1 });
        }

        if (value <= 100000000) {
          const addedprice = parseInt(value) + 1;
          setformvalues({ ...formvalue, price: addedprice });
        }
        break;

      case "minusprice":
        if (value === "") {
          setformvalues({ ...formvalue, price: 0 });
        }
        if (value > 0) {
          const minusprice = parseInt(value) - 1;
          setformvalues({ ...formvalue, price: minusprice });
        }
        break;

      case "addbedroom":
        if (value <= 5) {
          const addedbedroom = parseInt(value) + 1;
          setformvalues({ ...formvalue, bedroom: addedbedroom });
        }
        break;

      case "minusbedroom":
        if (value === 0) {
        } else {
          const minusbedroom = value - 1;
          setformvalues({ ...formvalue, bedroom: minusbedroom });
        }
        break;

      case "addkitchen":
        if (value <= 5) {
          const addedkitchen = value + 1;
          setformvalues({ ...formvalue, kitchen: addedkitchen });
        }
        break;

      case "minuskitchen":
        if (value === 0) {
        } else {
          const minuskitchen = value - 1;
          setformvalues({ ...formvalue, kitchen: minuskitchen });
        }
        break;

      case "addbathroom":
        if (value <= 5) {
          const addedbathroom = value + 1;
          setformvalues({ ...formvalue, bathroom: addedbathroom });
        }
        break;

      case "minusbathroom":
        if (value === 0) {
        } else {
          const minusbathroom = value - 1;
          setformvalues({ ...formvalue, bathroom: minusbathroom });
        }
        break;
      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

  // handle image upload and remove images
  const handleimages = React.useCallback(({ files, pImages }) => {
    setImages({ files: files, pImages: pImages });
  }, []);

  // on submit clicked formvalidation  is checked according to current steps
  const onSubmit = () => {
    const isValid = formvalidation();
    if (isValid) {
      const data = new FormData();
      for (let i = 0; i < images.files.length; i++) {
        data.append("images", images.files[i]);
      }
      data.append("offers", JSON.stringify(formvalue.offers));
      data.append("title", formvalue.title);
      data.append("description", formvalue.description);
      data.append("availableDate", formvalue.availableDate);
      data.append("price", formvalue.price);
      data.append("houseType", formvalue.houseType);
      data.append("accessTo", formvalue.accessTo);
      data.append("bedroom", formvalue.bedroom);
      data.append("kitchen", formvalue.kitchen);
      data.append("bathroom", formvalue.bathroom);
      data.append("lat", formvalue.location.lat);
      data.append("lng", formvalue.location.lng);
      data.append("place_name", formvalue.location.place_name);
      data.append("minimumStay", formvalue.minimumStay);
      dispatch(adminCreateRentalAction({ data, toast }));
    }
  };

  // on location set
  const handleLocation = (location) => {
    setformvalues((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        place_name: location.place_name,
      },
    }));
  };
  return (
    <Container
      sx={{
        paddingTop: "3vh",
        overflow: "hidden",
        overflowY: "scroll",
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Professional Add Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <hr />
      <TypeofProperty
        handleHouseType={handleHouseType}
        houseType={formvalue.houseType}
        errors={errors.houseType}
      />
      <TenantAccess
        handleAccessType={handleFormValue}
        selected={formvalue.accessTo}
        errors={errors.accessTo}
      />
      <TentantLocation
        handleLocation={handleLocation}
        errors={errors}
        active={true}
        location={formvalue.location}
      />
      <PropertyOffer
        errors={errors}
        offers={formvalue.offers}
        handleFormValue={handleFormValue}
        bedroom={formvalue.bedroom}
        kitchen={formvalue.kitchen}
        bathroom={formvalue.bathroom}
      />
      <RentalImageUpload
        errors={errors.images}
        handleimages={handleimages}
        active={true}
        pImages={images.pImages}
        imageFile={images.files}
      />
      <AboutProperty
        errors={errors}
        values={formvalue}
        handleFormValue={handleFormValue}
      />
      <PropertyPrice
        errors={errors}
        price={formvalue.price}
        handleFormValue={handleFormValue}
      />
      <Box
        sx={{
          bottom: 0,
        }}
        className="position-sticky d-flex justify-content-end gap-1 py-2 align-items-center  start-0 w-100 bg-white "
      >
        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <button
          className="btn-dark btn ms-auto w-maxcontent "
          onClick={onSubmit}
        >
          Save
        </button>
      </Box>
    </Container>
  );
};

export default AdminAddRental;
