import { Box, MenuItem, Select } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import React from "react";
const AdsDropDown = ({ onEdit, id }) => {
  const [selectedOption, setSelectedOption] = React.useState("more");
  const options = [
    {
      value: "more",
      icon: <MoreVert color="error" />,
    },
    {
      value: "HomePage",
      icon: <MoreVert color="error" />,
    },
    {
      value: "DetailPage",
      icon: <MoreVert color="error" />,
    },
  ];
  const handleOptionChange = (event) => {
    if (event.target.value === "edit") {
      //changes active
      //opens modal
    } else {
      onEdit(id, event.target.value);
    }
    // setSelectedOption(event.target.value);
    // onChange(event.target.value);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleOptionChange}
      sx={{
        margin: 0,
        padding: 0,
        "& .MuiSelect-select": {
          boxShadow: "none",
          display: "flex",
          alignItems: "center",
          margin: 0,
          padding: 0,
        },
      }}
      IconComponent={""}
      variant="standard"
      disableUnderline
      className="p-0 m-0 rounded rounded-3"
    >
      {options.map((option, index) => (
        <MenuItem
          key={option.value}
          value={option.value}
          className={
            selectedOption === option.value
              ? "d-none"
              : "border border m-0 border-dark "
          }
        >
          <Box
            className={
              selectedOption === option.value
                ? "p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
                : "d-flex justify-content-center align-items-center w-maxcontent fs-6"
            }
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: selectedOption === option.value ? "" : "1.5rem",

              // height: selectedOption === option.value ? "40px" : "100%",
              // width: selectedOption === option.value ? "40px" : "100%",
            }}
          >
            {index === 0 ? option.icon : option.value}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};

export default AdsDropDown;
