import { Box } from '@mui/system'
import React from 'react'

import { useDispatch } from "react-redux";
import { adminLoginAction } from '../../../../context/actions/admin/authAction';
import  {useNavigate} from 'react-router-dom';
import {toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { showtoast } from '../../../../context/reducers/authSlice';

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email,
      password,
    };
    dispatch(adminLoginAction({ data, navigate, toast }));
  };

  // use useSelector to get data from store from user_reducer
  const { loading, error } = useSelector((state) => ({
    ...state.user_reducer,
  }));

  // use useCallback to memoize the function
  React.useEffect(() => {
    error && dispatch(showtoast(toast.error(error)));
  }, [error]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <h2 className="mb-3 text-center text-danger">Superuser</h2>
            <form>
              <div className="form-group">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group mt-2">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                />
              </div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary btn-block mt-2"
              >
                Sign in
              </button>
            </form>
          </div>
        </div>
      </div>
    </Box>
  );
}

export default AdminLogin