import {

  Typography,
} from "@mui/material";
import React from "react";
import { Navbar } from "../../components/Index";
import BlogNav from "./BlogNav";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>ONF CANADA Blogs: Study Permits, Studying in Canada, Documents, Immigration News</title>

        <meta name="description"
          content="Stay informed with the latest news and insights on study permits, studying in Canada, essential documents, immigration updates, and more through ONF CANADA Blogs. Get valuable information and updates to enhance your Canadian education journey." />

        <link rel="canonical" href="/register" />
      </Helmet>
      <div className="container">
        <Typography variant="h5" component="h5" className="mt-4">
          ONF Canada | Blog
        </Typography>

        <div className="row row-cols-2 mt-3   ">
          <div className="col-8">
            <div className="card m-0">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/nature/182.webp"
                className="card-img-top rounded-6 h-100 object-cover"
                alt=""
              />
              <div className="card-img-overlay">
                <h5 className="card-title text-light">Blog Title 1</h5>
                <h6 className="card-subtitle mb-2 text-light">
                  Blog Subtitle 1
                </h6>
                <p className="card-text text-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <p className="card-text text-light">Views: 100 Likes: 10</p>
              </div>
            </div>
          </div>

          <div className="col-4 m-0">
            <div class="card">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/nature/182.webp"
                class="card-img-top rounded-6"
                alt=""
              />
              <div className="card-img-overlay">
                <h5 className="card-title text-light">Blog Title 1</h5>
                <h6 className="card-subtitle mb-2 text-light">
                  Blog Subtitle 1
                </h6>
                <p className="card-text text-light">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam.
                </p>
                <p className="card-text text-light">Views: 100 Likes: 10</p>
              </div>
            </div>

            <div className="card mt-3 w-100">
              <img
                src="https://mdbcdn.b-cdn.net/img/new/standard/nature/182.webp"
                className="card-img-top rounded-6"
                alt=""
              />
              <div className="card-img-overlay">
                <h5 className="card-title text-light">Blog Title 1</h5>
                <h6 className="card-subtitle mb-2 text-light">
                  Blog Subtitle 1
                </h6>
                <p className="card-text text-light">Lorem ipsum</p>
                <p className="card-text text-light">Views: 100 Likes: 10</p>
              </div>
            </div>
          </div>
        </div>

        <BlogNav />
      </div>
    </>
  );
};

export default Blog;
