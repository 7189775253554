import { Box, Chip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import qs from "qs";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FlateMateUserCard, RentalUserCard } from "../../components/Index";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import {
  getAllFlatemateAction,
  getAllRentalAction,
} from "../../context/actions/rentalAction";
import RentalFilterSearch from "./filter/RentalFilterSearch";

import TuneIcon from "@mui/icons-material/Tune";
import { Modal } from "@mui/material";

import RentalAllFilters from "./filter/RentalAllFilters";

const LayoutBox = styled("div")(({ theme }) => ({
  display: "flex",

  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
}));
const RentalFilter = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "50vw", xs: "95vw", md: "60vw" },
    height: "500px",
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
    borderRadius: "10px",
    paddingTop: { xs: 2, md: 4 },
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const locationv = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(locationv.search);
  const type = queryParams.get("type")?.trim() || "";
  const houseType = queryParams.get("houseType")?.trim() || "";

  // rental
  const accessTo = queryParams.get("accessTo")?.trim() || "";
  const minimumStay = queryParams.get("minimumStay")?.trim() || "";
  const availableDate = queryParams.get("availableDate")?.trim() || "";
  const minPrice = queryParams.get("minPrice")?.trim() || "";
  const maxPrice = queryParams.get("maxPrice")?.trim() || "";
  const location = queryParams.getAll("location");

  // flatemates
  const fminPrice = queryParams.get("fminPrice")?.trim() || "";
  const fmaxPrice = queryParams.get("fmaxPrice")?.trim() || "";
  const flookingFor = queryParams.get("flookingFor")?.trim() || "";
  const flengthOfStay = queryParams.get("flengthOfStay")?.trim() || "";
  const fmoveInDate = queryParams.get("fmoveInDate")?.trim() || "";
  const fminAge = queryParams.get("fminAge")?.trim() || "";
  const fmaxAge = queryParams.get("fmaxAge")?.trim() || "";
  const faccomondationTypes = queryParams.get("faccomondationTypes") || "";
  const flatemate_location = queryParams.getAll("flocation");

  const [rtype, setType] = React.useState({
    rentalType: "Rental",
  });

  useEffect(() => {
    const rentalType = type;
    setType({ ...rtype, rentalType: rentalType });
  }, [type]);

  const onPageTabChange = (value) => {
    setType({ ...rtype, rentalType: value });
  };

  const options = {
    keyword: location,
    minPrice: minPrice,
    maxPrice: maxPrice,
    propertyType: "",
    sortby: "popularity",
  };

  const [search, setsearch] = React.useState(options);

  useEffect(() => {
    dispatch(
      getAllRentalAction({
        keyword: location,
        houseType: houseType,
        minPrice: minPrice,
        maxPrice: maxPrice,
        accessTo: accessTo,
        minimumStay: minimumStay,
        availableDate: availableDate,
      })
    );
  }, [minPrice, maxPrice, houseType, accessTo, minimumStay, availableDate]);

  const [isload, setisload] = React.useState(true);

  // flatemate filter
  useEffect(() => {
    dispatch(
      getAllFlatemateAction({
        minPrice: fminPrice,
        maxPrice: fmaxPrice,
        minAge: fminAge,
        maxAge: fmaxAge,
        lookingFor: flookingFor,
        lengthOfStay: flengthOfStay,
        moveInDate: fmoveInDate,
        accomondationType: faccomondationTypes,
        preferedLocations: flatemate_location,
      })
    );
  }, [
    dispatch,
    fminPrice,
    fmaxPrice,
    fminAge,
    fmaxAge,
    flookingFor,
    flengthOfStay,
    fmoveInDate,
    faccomondationTypes,
    
  ]);

  const handleSearch = (name, value) => {
    switch (name) {
      case "minPrice":
        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(value)) {
          setsearch({ ...search, [name]: value });
        }

        break;

      case "maxPrice":
        const onlyNumbersmax = /^[0-9]*$/;
        if (onlyNumbersmax.test(value)) {
          setsearch({ ...search, [name]: value });
        }

        break;
      case "location":
        setsearch({ ...search, [name]: value });
        break;
      case "removelocation":
        setsearch({ ...search, location: "" });
        break;

      case "rentalType":
        setType({ ...rtype, [name]: value });
        break;

      case "propertyType":
        setsearch({ ...search, [name]: value });
        break;

      default:
        setsearch({ ...search, [name]: value });
    }
  };

  const onPropertySelect = (e) => {
    setsearch({ ...search, [e.target.name]: e.target.value });
  };

  // const onSearch = () => {
  //   const { location, minPrice, maxPrice, propertyType } = search;
  //   if (rtype.rentalType === "Rental") {
  //     queryParams.delete("fsearch");
  //     queryParams.delete("fminPrice");
  //     queryParams.delete("fmaxPrice");
  //     queryParams.delete("fpropertyType");
  //     queryParams.set("search", encodeURIComponent(location));
  //     queryParams.set("minPrice", encodeURIComponent(minPrice));
  //     queryParams.set("maxPrice", encodeURIComponent(maxPrice));
  //     queryParams.set("propertyType", encodeURIComponent(propertyType));
  //     navigate(`${locationv.pathname}?${queryParams.toString()}`);
  //   } else if (rtype.rentalType === "FlateMates") {
  //     queryParams.delete("search");
  //     queryParams.delete("minPrice");
  //     queryParams.delete("maxPrice");
  //     queryParams.delete("propertyType");
  //     queryParams.set("fsearch", encodeURIComponent(location));
  //     queryParams.set("fminPrice", encodeURIComponent(minPrice));
  //     queryParams.set("fmaxPrice", encodeURIComponent(maxPrice));
  //     queryParams.set("fpropertyType", encodeURIComponent(propertyType));
  //     navigate(`${locationv.pathname}?${queryParams.toString()}`);
  //   } else if (rtype.rentalType === "TeamUp") {
  //     queryParams.delete("fsearch");
  //     queryParams.delete("fminPrice");
  //     queryParams.delete("fmaxPrice");
  //     queryParams.delete("fpropertyType");
  //     queryParams.set("search", encodeURIComponent(location));
  //     queryParams.set("minPrice", encodeURIComponent(minPrice));
  //     queryParams.set("maxPrice", encodeURIComponent(maxPrice));
  //     navigate(`${locationv.pathname}?${queryParams.toString()}`);
  //   }
  // };

  const { loading, property, flatemate } = useSelector((state) => ({
    loading: state.rental_reducer.loading,
    property: state.rental_reducer.property,
    flatemate: state.rental_reducer.flatemate,
  }));

  let rentalTypeComponent;
  switch (rtype.rentalType) {
    case "Rental":
      rentalTypeComponent = (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 gx-lg-4 gy-lg-4 gx-2 gy-2">
          {property && property.length > 0 ? (
            property?.map((item) => (
              <div className="">
                <RentalUserCard item={item} />
              </div>
            ))
          ) : (
            <NoResultFound />
          )}
        </div>
      );
      break;
    case "FlateMates":
      rentalTypeComponent = (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 gx-lg-4 gy-lg-4 gx-2 gy-2">
          {flatemate && flatemate.length > 0 ? (
            flatemate.map((item) => (
              <div className="">
                <FlateMateUserCard item={item} />
              </div>
            ))
          ) : (
            <NoResultFound />
          )}
        </div>
      );
      break;
    case "TeamUp":
      rentalTypeComponent = (
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 gx-lg-4 gy-lg-4 gx-2 gy-2">
          {flatemate && flatemate.length > 0 ? (
            flatemate
              ?.filter((item) => item.teamUp)
              .map((item) => (
                <div className="">
                  <FlateMateUserCard item={item} />
                </div>
              ))
          ) : (
            <NoResultFound />
          )}
        </div>
      );
      break;
    default:
      rentalTypeComponent = null;
  }

  const handleLocationDelete = (label) => {
    const queryParams = new URLSearchParams(locationv.search);
    const values = [...location].filter((item) => item !== label);
    queryParams.delete("location");
    values.forEach((value) => {
      queryParams.append("location", value);
    });
    navigate(`${locationv.pathname}?${queryParams.toString()}`);
  };

  const handlefaccomondationTypesDelete = (label) => {
    const queryParams = new URLSearchParams(locationv.search);
    const values = [...faccomondationTypes].filter((item) => item !== label);
    queryParams.delete("faccomondationTypes");
    values.forEach((value) => {
      queryParams.append("faccomondationTypes", value);
    });
    navigate(`${locationv.pathname}?${queryParams.toString()}`);
  };

  const [chip, setChip] = React.useState(true);

  return (
    <div className=" d-flex flex-column gap-2 container">
      <LayoutBox
        sx={{
          // position: "sticky",

          top: "8.5vh",
          left: "0",
          zIndex: "4",
        }}
        role="button"
        className="  w-100 bg-white  py-2 "
      >
        <div className="w-100 d-flex flex-column gap-2">
          <Box
            className="border border-dark rounded rounded-3 d-flex   "
            sx={{
              height: "40px",
            }}
          >
            <Typography
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center "
              onClick={() => handleSearch("rentalType", "Rental")}
              role="button"
              sx={{
                background: rtype.rentalType === "Rental" ? "#474141" : "",
                color: rtype.rentalType === "Rental" ? "#f1ebeb" : "",
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",
                borderRight: "1px solid black",
              }}
            >
              Rental
            </Typography>
            <Typography
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
              onClick={() => handleSearch("rentalType", "FlateMates")}
              role="button"
              sx={{
                backgroundColor:
                  rtype.rentalType === "FlateMates" ? "#474141" : "",
                color: rtype.rentalType === "FlateMates" ? "#f1ebeb" : "",
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",

                borderRight: "1px solid black",
              }}
            >
              FlateMates
            </Typography>

            <Typography
              onClick={() => handleSearch("rentalType", "TeamUp")}
              role="button"
              variant="span"
              className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
              sx={{
                fontSize: "1.1rem",
                paddingBlock: "1.1rem",

                backgroundColor: rtype.rentalType === "TeamUp" ? "#474141" : "",
                color: rtype.rentalType === "TeamUp" ? "#f1ebeb" : "",
              }}
            >
              Teamup
            </Typography>
          </Box>
          <Box
            sx={{
              border: "2px solid grey",
            }}
            className="w-100  rounded rounded-3 d-flex gap-2  align-items-center justify-content-between p-2 px-3"
            onClick={handleOpen}
            role="button"
          >
            <p className="w-100 text-overflow-hidden">
              Filter rental, flatmates, teamup, price, location and many more
            </p>
            <Box
              sx={{
                width: "40px",
                height: "40px",
                backgroundColor: "var(--hover-color)",
              }}
              role="button"
              className="d-flex justify-content-center align-items-center rounded  rounded-2 p-2"
            >
              <TuneIcon />
            </Box>
          </Box>
        </div>
      </LayoutBox>
      {/* <span className="fs-2 text-black fw-bold ">Search & Filter property</span> */}
      <span className="mt-2"></span>
      <RentalFilterSearch
        rtype={rtype.rentalType}
        handleSearch={handleSearch}
        handleOpen={handleOpen}
      />
      <div className=" mt-2 mb-2 d-flex flex-column">
        <h5>Showing & filtering of property,</h5>
        <div className="d-flex flex-wrap gap-1 align-items-center">
          {location.map((item) => (
            <Chip
              key={item}
              label={item}
              size="small"
              variant="outlined"
              onDelete={() => handleLocationDelete(item)}
            />
          ))}
          {/* {
            faccomondationTypes.map((item) => (
              <Chip
              key={item}
              label={item}
              size="small"
              variant="outlined"
              onDelete={() => handlefaccomondationTypesDelete(item)}
            />
            ))
          } */}
          {chip ? (
            <div className="d-flex gap-1 flex-wrap w-100"></div>
          ) : (
            <Chip
              label="All property"
              size="small"
              variant="outlined"
              // onDelete={handleDelete}
            />
          )}
        </div>
      </div>

      {rentalTypeComponent}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <RentalAllFilters
            setisload={setisload}
            filterTab={rtype.rentalType}
            onPageTabChange={onPageTabChange}
            closeModal={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default RentalFilter;
