import React from "react";
import { Box } from "@mui/material";
import ReactMapGL, {
  GeolocateControl,
  Marker,
  NavigationControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import GeoCoder from "./GeoCoder";

const AddLocation = ({ handleLocation, height }) => {
  return (
    <>
      <Box
        sx={{
          height: height ? height : "300px",
          position: "relative",
        }}
        className="w-100"
      >
        <ReactMapGL
          mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
          initialViewState={{
            latitude: 57.422638,
            longitude: -101.423852,
            zoom: 2,
          }}
          
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          <Marker
            latitude={57.422638}
            longitude={-101.423852}
            draggable
            onDragEnd={(event) => handleLocation(event.lngLat)}
          />

          <NavigationControl position="bottom-right" showCompass="true" />
          <GeolocateControl
            position="top-left"
            trackUserLocation
        
          />

          <GeoCoder handleLocation={handleLocation} />
        </ReactMapGL>
      </Box>
    </>
  );
};

export default AddLocation;
