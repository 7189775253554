import { createSlice } from "@reduxjs/toolkit";
import { getAllEventAction, getEventByIdAction } from "../actions/eventAction";

const initialState = {
  loading: false,
  events: [],
  totalEventCount: 0,
  resultsPerPage: 0,
  event_details: {},
  error: "",
};

const eventSlice = createSlice({
  name: "eventSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEventAction.fulfilled, (state, action) => {
        state.loading = false;
        const { event, totaleventcount, resultperpage } = action.payload;
        state.events = event;
        state.totalEventCount = totaleventcount;
        state.resultsPerPage = resultperpage;
        
      })
      .addCase(getAllEventAction.rejected, (state, action) => {
        state.events = [];
        state.loading = false;
        state.error = action.error.message;
      })

      // get event details
      .addCase(getEventByIdAction.pending, (state, action) => {
        state.loading = true;
        state.event_details = null;
      })
      .addCase(getEventByIdAction.fulfilled, (state, action) => {
        state.loading = false;
        const { event } = action.payload;
        state.event_details = event;
      })
      .addCase(getEventByIdAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default eventSlice.reducer;
