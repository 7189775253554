import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/organizationApi";

//create admin organization
export const adminCreateOrganizationAction = createAsyncThunk(
  "admin/create_admin_organization",
  async ({ value, toast,navigate }, { rejectWithValue }) => {
    const {
      members,
      filelst,
      description,
      email,
      postalcode,
      phonenumber,
      telephonenumber,
      profilepic,
      coverimage,
      nameofcompany,
      panfrontfile,
      panbackfile,
      fblink,
      websitelink,
      twitterlink,
      instagramlink,
      province,
      city,
      agreement,
      location_lat,
      location_lng,
      location_place_name,
      category,
    } = value;

    const formData = new FormData();

    for (let i = 0; i < filelst.length; i++) {
      formData.append("filelst", filelst[i]);
    }
    formData.append("members", JSON.stringify(members));
    formData.append("description", description);
    formData.append("email", email);
    formData.append("postalcode", postalcode);
    formData.append("phonenumber", phonenumber);
    formData.append("telephonenumber", telephonenumber);
    formData.append("nameofcompany", nameofcompany);
    formData.append("panfrontfile", panfrontfile);
    formData.append("panbackfile", panbackfile);
    formData.append("fblink", fblink);
    formData.append("websitelink", websitelink);
    formData.append("twitterlink", twitterlink);
    formData.append("instagramlink", instagramlink);
    formData.append("province", province);
    formData.append("city", city);
    formData.append("agreement", agreement);
    formData.append("profilepic", profilepic);
    formData.append("coverimage", coverimage);
    formData.append("location_lat", location_lat);
    formData.append("location_lng", location_lng);
    formData.append("location_place_name", location_place_name);
    formData.append("category", category);

    try {
      const response = await admin_api.adminCreateOrganization(formData);
      toast.success("Request sent to admin");
      navigate("/admin/companies");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//adminUpdateOrganization

export const adminUpdateOrganizationAction = createAsyncThunk(
  "admin/update-organization",
  async ({ id, formData, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateOrganization(id, formData);
      toast.success(response.data.message || "Organization updated successfully");
      const response2 = { data: response.data, activeindex: activeindex, toast: toast };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// get all organization for admin account
export const adminGetAllOrganizationAction = createAsyncThunk(
  "admin/get_all_organization",
  async ({ keyword = "", page = 0, status }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllOrganization(
        keyword,
        page,
        status
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


// change status of organization for admin to control organization
export const adminChangeOrganizationStatusAction = createAsyncThunk(
  "admin/change_organization_status",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
     
      const response = await admin_api.adminChangeOrganizationStatus(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured organization

export const adminUpdateFeaturedOrganizationAction = createAsyncThunk(
  "admin/featured_organization",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminUpdateFeaturedOrganization(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


//verify_organization
export const adminVerifyOrganizationAction = createAsyncThunk(
  "admin/verify_organization",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
    
      const response = await admin_api.adminVerifyOrganization(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// give_review_organization
export const adminGiveReviewOrganizationAction = createAsyncThunk(
  "admin/give_review_organization",
  async ({ id, data, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGiveReviewToOrganization(id, data);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeindex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);