import customInstance from "./manage_access_route";
import API from "./common_api";


export const get_blog_details = (id) => API.get(`/api/blogs/get_blogs_details/${id}`);

// test blog

export const post_comment_in_blog = (id, data) => customInstance.post(`/api/blogs/create_comment/${id}`, data);

export const post_bookmark = (id, data) =>customInstance.post(`/api/user/add_to_favorite/${id}`, data);

export const get_all_blogs = (keyword = "", category = "", page = 1) =>API.get(`/api/blogs/get_all_blogs/?keyword=${keyword}&page=${page}&category=${category}`);

export const post_like = (id) => customInstance.post(`/api/blogs/like_blog/${id}`, {});

// get all featured blogs

export const get_all_featured_blogs = () =>
  API.get(`/api/blogs/get_all_featured_blogs`);