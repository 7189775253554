import { Box } from '@mui/system'
import React from 'react'

const NewsSearchCard = ({ news }) => {

    return (
        <Box sx={{
            
            "&:hover": {
                cursor: "pointer",
            },
            "&:hover .card-title": {
                textDecoration: "underline",
            },

           
        }}>
            <div class="card mb-3 shadow-4" >
                <div class=" g-0 d-flex">
                    <img src={news.urlToImage} width={'200px'} height={'200px'} className='object-cover rounded-3' alt="" />
                    <div class="col-md-8">
                        <div class="card-body">
                            <h5 class="card-title fw-bold">
                                {news.title}
                            </h5>
                            <p class="card-text">
                                {news.description}
                            </p>
                            <p class="card-text">
                                <small class="text-muted">
                                    by {news.author}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default NewsSearchCard