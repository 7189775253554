import React from "react";

import { Box, Typography } from "@mui/material";
import { BsStars } from "react-icons/bs";
import { IoHeartCircleOutline } from "react-icons/io5";
import Wrapper from "./FlateMateUserCardStyle";
import { Link } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

// RiGroupLine
import { MdOutlineGroup } from "react-icons/md";

const FlateMateUserCard = ({ item }) => {
  console.log(item);
  const dispatch = useDispatch();
  const [liked, setliked] = React.useState(false);

  const [type, setType] = React.useState("Flatemate");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
    teamUp: false,
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    if (item) {
      setBookmark({
        name: item.title,
        type: type,
        image: item?.userPic,
        teamUp: item?.teamUp,
        location: item?.preferedLocations[0],
      });
    }
  }, [item, type]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === item?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, item?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: item?._id, data: bookmark }));
    }
  };
  return (
    <Wrapper
      role="button"
      className="p-0 m-0 shadow-3 position-relative rounded rounded-4 overflow-hidden"
    >
      <Link
        to={`/flatemate/${item?._id}`}
        style={{
          color: "inherit",
        }}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>

      <Box
        sx={{
          height: "200px",
        }}
        className="rental-img w-100  position-relative overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${item?.userPic}`}
          // src="https://m0.her.ie/wp-content/uploads/2018/01/07093633/GettyImages-887815620.jpg"
          alt=""
          className="w-100 h-100 object-cover"
        />
        <Box
          className="position-absolute px-2 py-1 rounded rounded-1 d-flex align-items-center bg-black"
          sx={{
            top: "2%",
            right: "2%",
            fontSize: "0.9em",
          }}
        >
          <BsStars className="text-white" />
          <span className="text-white">New</span>
        </Box>
      </Box>
      <div className="rental-info p-1 px-2">
        <div className="rental-info_price d-flex justify-content-between">
          <div>
            <h5 className="fw-bold m-0">
              {item?.firstName} {item?.lastName}
            </h5>
          </div>
          <Box
            sx={{
              zIndex: "2",
              cursor: "pointer",
            }}
          >
            <IoHeartCircleOutline
              onClick={togglelike}
              style={{
                color: liked ? "#FF0000" : "",
                fontSize: "1.8em",
              }}
            />
          </Box>
        </div>
        <span className="d-flex m-0">
          <h5 className="m-0">${item?.price}</h5>
          <small>/month - {item?.age} years {item?.gender}</small>
        </span>
        <div className="d-flex gap-1 align-items-center">
          <Typography
            className="mt-2 text-black"
            variant="p"
            sx={{
              lineHeight: "1.5",
              fontSize: "clamp(0.7rem,1vw,1rem)",
            }}
          >
            {item?.description.slice(0, 60)}..
          </Typography>
        </div>
      </div>
      <hr className="mt-0" />
      <div className="d-flex justify-content-between px-2 align-items-center pb-3 ">
        {item?.teamUp ? (
          <p className="text-danger">
            <MdOutlineGroup size={20} /> team-up
          </p>
        ) : (
          <p>Available Now</p>
        )}
        <button className="btn btn-sm btn-outline-black">Free Message</button>
      </div>
    </Wrapper>
  );
};

export default FlateMateUserCard;
