import BookmarkIcon from "@mui/icons-material/Bookmark";
import FacebookIcon from "@mui/icons-material/Facebook";
import LaunchIcon from "@mui/icons-material/Launch";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import HTMLReactParser from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsCalendar2Date, BsPersonSquare } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { MdMarkEmailUnread, MdOutlineBookmarkAdd } from "react-icons/md";
import { SlPhone } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetOrganizationDetailsAction } from "../../context/actions/organizationAction";

import { ArrowDownward, ArrowUpward, RemoveRedEye } from "@mui/icons-material";
import moment from "moment";
import { FacebookShareButton } from "react-share";
import ShowLocation from "../../components/location/ShowLocation";
import { post_bookmark } from "../../context/actions/authAction";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import BoardMembers from "./components/BoardMembers";
import FeaturedOrganizations from "./components/FeaturedOrganizations";
import OrganizationDetailSkeleton from "./components/OrganizationDetailSkeleton";
import { Helmet } from "react-helmet-async";

const OrganizationDetail = () => {
  // get id from url
  const { id } = useParams();
  const [showMore, setShowMore] = useState(false);
  const [type, setType] = React.useState("Organization");
  // use dispatch to dispatch action
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // use callback to get organization details to memoize the function
  const getOrganizationDetails = useCallback(() => {
    dispatch(GetOrganizationDetailsAction(id));
  }, [dispatch, id]);

  // call getOrganizationDetails function on component mount
  useEffect(() => {
    getOrganizationDetails();
  }, [getOrganizationDetails]);

  const [bookmarked, setbookmarked] = React.useState(false);

  // use useSelector to get data from store organization details
  const {
    loading,
    introduction,
    contactAndLinks,
    boardMembers,
    view,
    recommendedOrganization,
    bookmarks,
    isAuth,
  } = useSelector((state) => ({
    loading: state.organization_reducer.loading,
    introduction: state.organization_reducer.org_details?.introduction || {},
    contactAndLinks:
      state.organization_reducer.org_details?.contactAndLinks || {},
    boardMembers: state.organization_reducer.org_details?.boardMembers || [],
    view: state.organization_reducer?.org_details?.viewCount || 0,
    recommendedOrganization:
      state.organization_reducer?.recommendedOrganization || [],
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
    isAuth: state.auth_slice.isAuth,
  }));

  // check if the like and bookmark is already there or not
  const isBookmarked = bookmarks?.some((bookmark) => bookmark.id === id);

  useEffect(() => {
    setbookmarked(isBookmarked);
  }, [bookmarks, isBookmarked]);

  // intialize bookmark state
  const [bookmark, setBookmark] = useState({
    name: "",
    type: "",
    image: "",
    location: "",
    category: "",
  });

  console.log(bookmark);

  // set the bookmarks
  React.useEffect(() => {
    if (introduction && bookmark.name !== introduction.organizationName) {
      setBookmark({
        name: introduction?.organizationName,
        type: type,
        image: introduction?.profile,
        category: introduction?.category,
      });
    }
  }, [introduction, bookmark.name, type, contactAndLinks]);

  const togglebookmark = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id, data: bookmark }));
    } else {
      navigate("/login");
    }
  };

  // toggle show more function
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (loading) {
    // render loading component until data is loaded
    return <OrganizationDetailSkeleton />;
  }

  if (
    (introduction && Object?.keys(introduction).length === 0) ||
    introduction === undefined
  ) {
    return <NotFoundPage />;
  }

  return (
    <>
     <Helmet>
        <title>
          {introduction?.organizationName}
        </title>
        <meta name="description"
          data-rh="true"
          content={introduction?.description} />
        <link rel="canonical" href="/register" />
      </Helmet>
        <Box className="container">
      <section className=" profile image-section h-maxcontent ">
        <Box
          sx={{
            display: "flex",
            position: "relative",
            alignItems: "end",
            justifyContent: "end",
            height: { xs: "max-content", md: "30vh" },
          }}
          className="overview-header w-100  p-0"
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "calc(30vh / 2.1 + 1.5vw)",
              overflow: "hidden",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
            className="overview-img  w-100 "
          >
            <img
              src={
                "/assets/images/coverpage.jpg"
              }
              alt=""
              srcSet=""
              className="object-cover w-100  "
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
              position: { md: "absolute" },
              top: { md: "calc(30vh / 2)" },
              marginTop: { xs: "10vh", md: "auto " },
            }}
            className="info  w-100  "
          >
            <Box
              className="profilepic   position-relative "
              sx={{
                transform: { xs: "translateY(-3vh)", md: " translateY(-5vh)" },
                marginLeft: { md: "4%" },
              }}
            >
              <Box
                sx={{
                  height: { xs: "calc(50px + 13vw)", md: "calc(50px + 7vw)" },
                  width: { xs: "calc(50px + 13vw)", md: "calc(50px + 7vw)" },
                  maxHeight: "180px",
                  maxWidth: "180px",
                  margin: "auto",
                  cursor: "pointer",
                  borderRadius: "50%",
                  border: "10px solid #f1f1f1",
                  overflow: "hidden",
                }}
              >
                <img
                  alt="Remy Sharp"
                  src={`${process.env.REACT_APP_IMAGE_PATH}${introduction.profile}`}
                  style={{}}
                  className="object-cover object-center w-100 h-100"
                />
              </Box>
            </Box>
            <Box
              className="  "
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", md: "row" },
                width: "100%",
                height: "max-content",

                marginLeft: { md: "10px" },
                gap: "2%",
              }}
            >
              <Box
                sx={{
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                <h3 className="p-0 m-0">{introduction.organizationName}</h3>
                <h6 className="p-0 pt-1">
                  {introduction.provenience} | {introduction.city}
                </h6>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "start" },
                    gap: "2%",
                    width: "100%",
                  }}
                  className=" mt-1"
                >
                  <Chip
                    icon={<FacebookIcon />}
                    label="Facebook"
                    variant="outlined"
                    onClick={() => {
                      window.open(contactAndLinks.facebookLink);
                    }}
                    size="small"
                  />
                  <Chip
                    icon={<TwitterIcon />}
                    label="Twitter"
                    variant="outlined"
                    onClick={() => {
                      window.open(contactAndLinks.twitterLink);
                    }}
                    size="small"
                  />
                  <Chip
                    icon={<LaunchIcon />}
                    label="Website"
                    variant="outlined"
                    onClick={() => {
                      window.open(contactAndLinks.websiteLink);
                    }}
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  marginLeft: { md: "10px" },
                  textAlign: { xs: "center", md: "start" },
                  marginTop: { xs: "10px", md: 0 },
                }}
                className=""
              >
                <ButtonGroup
                  className="text-black"
                  variant="outlined"
                  aria-label="outlined button group"
                >
                  <Button>
                    <div className="comments d-flex align-items-center gap-1">
                      <RemoveRedEye />
                      {view}
                    </div>
                  </Button>
                  <Button>
                    <div className="clapped d-flex align-content-center gap-1">
                      <FacebookShareButton
                        url={window.location.href}
                        quote={'Dummy text!'}
                        hashtag="#muo"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      Share
                    </div>
                  </Button>
                  <Button>
                    <div className="d-flex fs-5 align-items-center gap-1 ms-auto">
                      {bookmarked ? (
                        <BookmarkIcon onClick={togglebookmark} role="button" />
                      ) : (
                        <MdOutlineBookmarkAdd
                          onClick={togglebookmark}
                          role="button"
                        />
                      )}
                    </div>
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>

      <div className="row row-cols-lg-2 row-cols-1">
        <div className="col-lg-7 col col-xl-8">
          <section className="org-three col-lg-10 mt-5 text-hover">
            <h5 className="fw-bold">General Information</h5>
            <div className="d-flex gap-md-5 gap-2 mt-4 flex-wrap">
              <div className="d-flex gap-2">
                <BsCalendar2Date size={40} />
                <div>
                  <h6 className="m-0">Founded</h6>
                  <h5>{moment(introduction?.establishedDate).format("MMMM Do YYYY")}</h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <BsPersonSquare size={40} />
                <div>
                  <h6 className="m-0">Founded by</h6>
                  <h5>{boardMembers?.filter((member) => member.memberPosition === "Founder").map((member) => member?.memberName ? member?.memberName : "N/A")}</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-4">
            <h5 className="fw-bold">About organizations</h5>
            <Typography
              variant="p"
              sx={{
                textwrap: "balance",
              }}
            >
              {showMore
                ? typeof introduction?.description === "string" &&
                HTMLReactParser(introduction?.description)
                : typeof introduction?.description === "string" &&
                HTMLReactParser(introduction?.description.slice(0, 500))}
            </Typography>
            <Button
              onClick={toggleShowMore}
              disableRipple
              className="w-100"
              sx={{
                borderBlock: "1px solid grey",
                borderRadius: "0px",
              }}
            >
              {showMore ? (
                <p>
                  show less <ArrowUpward />
                </p>
              ) : (
                <p>
                  show more <ArrowDownward />
                </p>
              )}
            </Button>
          </section>

          <section className="mt-4">
            <h5 className="fw-bold">Actve Board Members</h5>

            <div class="row row-cols-1 row-cols-md-4 g-2">
              {boardMembers.map((boardMember) => {
                return (
                  <div key={boardMember} className="col">
                    <BoardMembers data={boardMember} />
                  </div>
                );
              })}
            </div>
          </section>
          <section>
            <h5 className="fw-bold mt-4">Location of organization</h5>
            <ShowLocation location={contactAndLinks?.location} />
          </section>
        </div>

        <div className="col-lg-5 col-xl-4  col d-flex flex-column align-items-lg-end align-items-start   ">
          <section className="mt-5  w-100 ">
            <h5 className="fw-bold">Get in touch | Contacts</h5>
            <div className="d-flex gap-3 mt-3 align-items-center">
              <span>
                <MdMarkEmailUnread size={40} color="#4e514d" />
              </span>
              <div>
                <h6 className="m-0">General Email</h6>
                <p className="fs-5 fw-bold text-overflow-hidden">
                  {contactAndLinks.email}
                </p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3 align-items-center">
              <span>
                <SlPhone size={40} color="#dd2727" />
              </span>
              <div>
                <h6 className="m-0">Phone Number</h6>
                <p className="fs-5 fw-bold">{contactAndLinks.phone}</p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3 w-100 align-items-center">
              <span>
                <FaFacebook size={40} color="#4267B2" />
              </span>
              <div className="d-flex flex-column overflow-hidden">
                <h6 className="m-0 p-0">Facebook | Social Media</h6>

                <Link className=" fw-bold text-wrap  text-overflow-hidden ">
                  {contactAndLinks.twitterLink}
                </Link>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3 w-100 align-items-center">
              <span>
                <AiFillTwitterCircle size={40} color="#1DA1F2" />
              </span>
              <div className="d-flex flex-column overflow-hidden">
                <h6 className="m-0 p-0">Twitter | Social Media</h6>
                <Link className=" fw-bold text-wrap text-overflow-hidden">
                  {contactAndLinks.twitterLink}
                </Link>
              </div>
            </div>
            {recommendedOrganization.length > 0 && (
              <div className="">
                <hr />
                <p className="fs-6 fw-bold my-2">Featured Org's</p>
              </div>
            )}
            {recommendedOrganization.length > 0 &&
              recommendedOrganization.slice(0, 4).map((item, index) => {
                return <FeaturedOrganizations item={item} key={index} />;
              })}
          </section>
          <hr />
          <div className="mt-3  "></div>
        </div>
      </div>
    </Box>
    </>
  );
};

export default OrganizationDetail;