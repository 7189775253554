import { LocationOn } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const FeaturedOrganizations = ({ item }) => {
  const { introduction } = item;
  return (
    <Link to={`/organizations/${item._id}`} className="d-flex align-items-center  gap-2  ">
      <Box
        sx={{
          height: 50,
          width: 60,
        }}
        className=" p-0 m-0  rounded rounded-3 overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${introduction.profile}`}
          alt=""
          className=" object-cover  w-100 h-100"
          height={50}
          width={50}
        />
      </Box>

      <div className="d-flex flex-column">
        <h5 className="m-0 p-0 text-black">{introduction.organizationName}</h5>
        <span className="text-black">
          <LocationOn fontSize="small" /> {introduction.provenience} |{" "}
          {introduction.city}
        </span>
      </div>
    </Link>
  );
};

export default FeaturedOrganizations;
