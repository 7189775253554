import React from "react";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { IconInputB, FormInputB } from "../../../../../components/Index";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box } from "@mui/material";
import { ImCross } from "react-icons/im";

const ProfessionalMContact = ({ onClose }) => {
  const [formvalue, setFormValue] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const formerrors = {
    email: "",
    contact: "",
  };

  // change value base on e.target.name
  const handleFormChange = (e) => {
    const name = e.target.name;
    switch (name) {
      default:
        setFormValue({ ...formvalue, [e.target.name]: e.target.value });

        break;
    }
  };

  const formvalidation = () => {
    let isValid = false;

    const newerror = { ...formerrors };

    const phoneRegex = /^\d{10}$/;
    const isphone = phoneRegex.test(formvalue.contact);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)?$/;

    const isValidEmail = emailRegex.test(formvalue.email);
    if (!formvalue.contact || !formvalue.email || !isphone || !isValidEmail) {
      if (!formvalue.email) {
        newerror.email = "Please provide email *";
      }
      if (!formvalue.contact) {
        newerror.contact = "Please provide contact *";
      }

      if (!isphone && formvalue.contact) {
        newerror.contact = "please provide valid candian number *";
      }
      if (!isValidEmail && formvalue.email) {
        newerror.email = "please provide valid email";
      }
      newerror.message = "Invalid credential please check again *";
    } else {
      isValid = true;
    }
    setErrors(newerror);

    return isValid;
  };

  const onSubmit = () => {
    const isValid = formvalidation();

    if (isValid) {
      onClose();
    }
  };
  return (
    <Box
      sx={{
        minHeight: "60vh",
      }}
      className="d-flex flex-column gap-3 align-items-center "
    >
      <Box className="d-flex align-items-center justify-content-between m-0 w-100">
      <div className="d-flex flex-column">
        <h5 className="text-black fw-bold m-0 p-0">
          Contact Information
        </h5>
        <small className="text-black">
          Please provide your contact information
        </small>
        </div>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>
      <section className="row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-3 w-100 rounded rounded-2 border border-2">
        <div className="">
          <FormInputB
            name="email"
            type="email"
            label="Email"
            placeholder="Enter Your Email"
            value={formvalue.email}
            handleChange={handleFormChange}
            error={errors.email ? true : false}
            helpertext={errors.email}
          />
        </div>
        <div className="">
          <FormInputB
            name="contact"
            type="number"
            label="Contact"
            placeholder="613-555-5555"
            value={formvalue.contact}
            handleChange={handleFormChange}
            error={errors.contact ? true : false}
            helpertext={errors.contact}
          />
        </div>
      </section>
      <div className="row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-3 w-100  rounded rounded-2 border border-2">
        <IconInputB
          icon={<LanguageIcon />}
          required={true}
          name={"websiteLink"}
          placeholder={"enter website link"}
          label={"Personal Website"}
          value={formvalue.personaLlink}
          handleChange={handleFormChange}
          error={errors.websiteLink ? true : false}
          helpertext={errors.websiteLink}
        />
        <IconInputB
          icon={<FacebookIcon />}
          required={true}
          name={"facebookLink"}
          placeholder={"enter FaceBook Link"}
          label={"FaceBook Link"}
          handleChange={handleFormChange}
          value={formvalue.facebookLink}
          error={errors.facebookLink ? true : false}
          helpertext={errors.facebookLink}
        />
        <IconInputB
          icon={<InstagramIcon />}
          required={true}
          name={"instagramLink"}
          placeholder={"enter Instagram Link"}
          label={"Instagram Link"}
          handleChange={handleFormChange}
          value={formvalue.InstagramLink}
          error={errors.instagramLink ? true : false}
          helpertext={errors.instagramLink}
        />
        <IconInputB
          icon={<TwitterIcon />}
          required={true}
          name={"twitterLink"}
          placeholder={"enter Twitter Link"}
          value={formvalue.twitterLink}
          label={"Twitter Link"}
          handleChange={handleFormChange}
          error={errors.twitterLink ? true : false}
          helpertext={errors.twitterLink}
        />
      </div>

      <button
        className="btn-dark btn ms-auto mt-auto align-self-end justify-self-end"
        onClick={onSubmit}
      >
        Save
      </button>
    </Box>
  );
};

export default ProfessionalMContact;
