import React from "react";
import { Box } from "@mui/material";
const IconInputB = ({
  error,
  name,
  label,
  handleChange,
  type,
  required,
  helpertext,
  classname,
  placeholder,
  icon,
  value
}) => {
  return (
    <div className={classname}>
      <label htmlFor={name}>{label}</label>
      <div className="d-flex align-items-center input-group ">
        <Box
          className="input-group-text h-100 py-3 shadow shadow-1-strong"
          id={name}
          sx={{
            borderEndEndRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          {icon}
        </Box>
        <input
          type={type}
          className="form-control py-3 h-100"
          placeholder={placeholder}
          aria-label={label}
          aria-describedby={name}
          required={required}
          onChange={handleChange}
          name={name}
          value={value}
        />
      </div>
      <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
        {helpertext}
      </small>
    </div>
  );
};

export default IconInputB;
