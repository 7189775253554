import HomeWorkIcon from "@mui/icons-material/HomeWork";
import React from "react";

import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { RemoveRedEye } from "@mui/icons-material";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBusinessProfile } from "../../../../context/actions/authAction";
import formatViewCount from "../../../../utils/formatViewCount";
import UserBusinessTable from "./UserBusinessTable";

const BusinessTab = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = React.useState("");
  const [page, setPage] = React.useState(0);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getBusinessProfile());
  }, [dispatch]);

  const { business_data, totalbusinessViews, business_profile_loading } =
    useSelector((state) => ({
      ...state.user_reducer,
    }));

  return (
    <div>
      <section className="d-flex align-items-center row-cols-3 m-0 p-0">
        <h4 className="col-4 fw-bold">
          Business
          <span className="text-danger ms-2">Business</span>
        </h4>

        <div className="col-8 d-flex justify-content-end gap-2">
          <button
            className="btn bg-black text-white "
            onClick={() => navigate("/business/add")}
          >
            Add Business
          </button>
        </div>
      </section>
      <hr />
      <section className="row row-cols-2 w-100 p-0 m-0 ">
        <div className="rental-left p-2 d-flex flex-column  col-3 d-none d-md-block  h-maxcontent bg-hover rounded-6">
          <div className="d-flex justify-content-between align-items-center p-3">
            <HomeWorkIcon className="fs-1 fw-bold" />
            <p className="w-50">Total owned business</p>
            <span className="fs-1 fw-bold">{business_data?.length}</span>
          </div>
          <hr className="m-0" />
          <div className="d-flex justify-content-between align-items-center p-3">
            <RemoveRedEye className="fs-1 " />
            <p className="w-50">Showing overall viewer count</p>
            <span className="fs-1 fw-bold">
              {formatViewCount(totalbusinessViews)}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-9">
          {/* <UserBusinessTable rental_data={user_rental_data} /> */}
          <Stack>
            <OutlinedInput
              className="mb-2"
              size="small"
              fullWidth
              placeholder="Search rental property by address, suburb, postcode, etc."
              startAdornment={
                <InputAdornment position="start">
                  <SvgIcon color="action" fontSize="small">
                    <MagnifyingGlassIcon />
                  </SvgIcon>
                </InputAdornment>
              }
            />
          </Stack>

          <UserBusinessTable
            keyword={keyword}
            page={page}
            setPage={setPage}
            business={business_data}
            resultperpage={2}
            totalbusinesscount={20}
            business_loading={business_profile_loading}
          />
        </div>
      </section>
    </div>
  );
};

export default BusinessTab;
