

import ProtectApi from "./adminAuthorizeApi";


//count_documents_and_view_count
export const adminCountDocumentsAndViews = () =>
  ProtectApi.get("api/admin/overview/count_documents_and_view_count");
export const adminGetAllLogs = (page = 0) =>
  ProtectApi.get(`api/admin/overview/get_all_logs/?page=${page}`);



