import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;

  .organization-tabs {
    list-style: none;

    & > li {
      cursor: pointer;

      &:hover {
        background-color: var(--hover-color);
      }
    }
  }
`;
export default Wrapper;
