import axios from "axios";

const instance = axios.create({
  // baseURL: "https://backend-host-bdsj.onrender.com",
  baseURL: "https://backend-host-bdsj.onrender.com",
  // baseURL: "http://localhost:5000",
  withCredentials: true,
  credentials: "include",
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/admin/login";
    }
    return Promise.reject(error);
  }
);
export default instance;
