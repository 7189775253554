import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/blogApi";

export const adminCreateBlogAction = createAsyncThunk(
  "admin/create_blog",
  async ({ formvalue, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminCreateBlog(formvalue);
      toast.success(response.data.message || "Blog Created Successfully");
      navigate("/admin/blogs");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//adminEditBlog
export const adminEditBlogAction = createAsyncThunk(
  "admin/edit_blog",
  async ({ id, formdata, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminEditBlog(id, formdata);
      toast.success(response.data.message || "Blog Updated Successfully");
      navigate("/admin/blogs");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// Get All Blog for admin
export const adminGetAllBlogAction = createAsyncThunk(
  "admin/get_all_blog",
  async ({keyword, category}, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllBlog(keyword, category);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//adminblogPic
export const adminblogPicAction = createAsyncThunk(
  "admin/blog_pic",
  async (data, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminblogPic(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get blog by id
export const adminGetBlogByIdAction = createAsyncThunk(
  "admin/get_blog_by_id",
  async (id, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetBlogById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// featured adminBlog
// export const adminFeaturedBlogAction = createAsyncThunk(
//   "admin/featured_blog",
//   async ({ id, status }, { rejectWithValue }) => {
//     try {
//       const response = await admin_api.adminFeaturedBlog(id, status);
//       return response.data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
export const adminFeaturedBlogAction = createAsyncThunk(
  "admin/change_blog_featured_status",
  async ({ id, data, toast }, { rejectWithValue }) => {
    try {
      const response = await admin_api.adminFeaturedBlog(id, data);
   
      toast.success(response.data.message);
      return response.data;
      // return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get all featured blogs
export const adminGetAllFeaturedBlogAction = createAsyncThunk(
  "admin/get_all_featured_blog",
  async ({ rejectWithValue }) => {
    try {
      const response = await admin_api.adminGetAllFeaturedBlog();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

