import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AddProfessionalProtectedRoute = () => {
  const { professional_profile } = useSelector((state) => state.user_reducer);

  return Object.keys(professional_profile).length > 0 ? (
    <Navigate to="/profile" replace />
  ) : (
    <Outlet />
  );
};

export default AddProfessionalProtectedRoute;
