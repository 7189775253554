import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Button } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

const Notification = () => {
    const {
        notifications
    } = useSelector((state) => ({
        notifications: state.notification_reducer.notifications,
    }));
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((prev) => !prev);
    }


    return (
        <div className='container mt-3'>
            <span className='fs-1 fw-bold'>
                News & Announcements <span>🔔</span>
            </span>

            {
                notifications?.map((item, index) => (

                    <div class="card border border-secondary shadow-0 mb-3 w-75" >
                        <div class="card-header d-flex justify-content-between">
                            <h5>
                                {item?.title}
                            </h5>
                            <small>
                                {moment(item?.scheduledDate).fromNow()}
                            </small>
                        </div>
                        <div class="card-body">
                            <p class="card-text">
                                {
                                    showMore
                                        ? item?.description
                                        : item?.description.split(" ").slice(0, 28).join(" ")
                                }
                                {
                                    item?.description.length > 200 ? (
                                        !showMore ? (
                                            <span
                                                onClick={toggleShowMore}
                                                className="text-black fw-bold"
                                                role="button"
                                            >
                                                ...Read More
                                            </span>
                                        ) : (
                                            <span
                                                onClick={toggleShowMore}
                                                className="text-black fw-bold"
                                                role="button"
                                            >
                                                ...Read Less
                                            </span>
                                        )
                                    ) : null
                                }

                            </p>

                        </div>

                    </div>
                ))
            }

        </div>
    )
}

export default Notification