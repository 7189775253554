import React from "react";
import { Typography } from "@mui/material";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
import AddLocation from "../../../components/location/AddLocation";
// CiLocationArrow1
import {CiLocationArrow1} from "react-icons/ci";
import { FaSearchLocation } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";

const TentantLocation = ({ errors, handleLocation, active,location }) => {
  return (
    <div className={active ? "w-100 mt-3" : "d-none mt-3"}>
      <p className="text-black fs-3 fw-bold my-0">Where exactly property is located?</p>

      <div className="d-flex gap-2 align-items-center my-3">
        <MdLocationPin />
        <Typography variant="span">
          {location?.place_name?.length > 0 ? location?.place_name:"No location selected"}
        </Typography>
      </div>
      <div className="form-group w-100">
        <AddLocation height={450} handleLocation={handleLocation} />
        <small
          id="emailHelp"
          className={errors.location ? "form-text text-danger" : ""}
        >
          {errors.location}
        </small>
      </div>
    </div>
  );
};

export default TentantLocation;
