import { createSlice } from "@reduxjs/toolkit";
import { getAllFaqAction } from "../actions/faqAction";

import { getAllHomeAdsAction } from "../actions/getAllAdsAction";
const initialState = {
  loading: false,
  faqs: [],
  error: "",
};

const faqSlice = createSlice({
  name: "faqSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFaqAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFaqAction.fulfilled, (state, action) => {
        state.loading = false;
        const { faq } = action.payload;
        state.faqs = faq;
      })
      .addCase(getAllFaqAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      

    
  },
});

export default faqSlice.reducer;
