import { TextField } from "@mui/material";
import React from "react";
import { FormInputB } from "../../components/Index";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { createHelpSupport } from "../../context/actions/authAction";
import { showtoast } from "../../context/reducers/authSlice";
import { Box } from "@mui/system";

const HelpHome = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    topic: "",
    seveirity: "",
    description: "",
    attachments: "",
  });

  const handleChnage = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    console.log(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createHelpSupport({ formData, toast }));
  };
  const { loading, error } = useSelector((state) => ({
    ...state.user_reducer,
  }));

  // use useCallback to memoize the function
  React.useEffect(() => {
    error && dispatch(showtoast(toast.error(error)));
  }, [error]);

  return (
    <div className="container mt-2">
      <div className="card shadow-0 mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between flex-wrap-reverse">
            <div className="p-4">
              <div className="d-flex gap-3">
                <img src="/assets/images/help_home.png" alt="" height={65} />

                <div>
                  <h5 className="card-title text-danger fs-3 fw-bold">
                    Help & Support within 24 Hours
                  </h5>
                  <p className="card-text">
                    Fill the form and we will get back to you within 24 hours
                  </p>
                </div>
              </div>
              <hr />
              <p className="fw-bold">Any Support & Help from us!</p>
              <ul>
                <li>If you have probem after landing in canada</li>
                <li>You face immigration issues for wages</li>
                <li>You have any other problem related to your job</li>
                <li>Might be problem finding rooms or accomodation</li>
                <li>Your employer is not paying you on time</li>
                <li>Visa extension or any other problem</li>
              </ul>
              <hr />
            </div>

            <form onSubmit={handleSubmit}>
              <div className="card p-5 shadow-darken10">
                <Box sx={{
                  display: 'flex',
                  flexWrap: { xs: 'wrap', md: 'nowrap' },
                }} className=" gap-2">
                  <FormInputB
                    name={"firstname"}
                    label={"Firstname"}
                    required={true}
                    classname={"form-control"}
                    placeholder={"Enter Firstname"}
                    handleChange={handleChnage}
                  />
                  <FormInputB
                    name={"lastname"}
                    label={"Lastname"}
                    required={true}
                    classname={"form-control"}
                    placeholder={"Enter Firstname"}
                    handleChange={handleChnage}
                  />
                </Box>
                <FormInputB
                  name={"email"}
                  label={"Email Address"}
                  required={true}
                  classname={"form-control"}
                  placeholder={"Enter valid Email Address"}
                  handleChange={handleChnage}
                />
                <FormInputB
                  name={"phone"}
                  label={"Phone Number"}
                  required={true}
                  classname={"form-control"}
                  placeholder={"Enter valid Phone Number"}
                  handleChange={handleChnage}
                />

                <div className="mt-2">
                  <label htmlFor="">Seveirity</label>
                  <select
                    className="form-select form-select-lg rounded-0 py-2 rounded-2 "
                    aria-label=".form-select-lg example"
                    name="seveirity"
                    onChange={handleChnage}
                  >
                    <option value="">Select Category</option>
                    <option value="Critical">Critical</option>
                    <option value="High">High</option>
                    <option value="Medium">Medium</option>
                    <option value="Low">Low</option>
                  </select>
                </div>
                <FormInputB
                  name={"topic"}
                  label={"Topic"}
                  required={true}
                  classname={"form-control"}
                  placeholder={"Enter Topic or Subject"}
                  handleChange={handleChnage}
                />

                {/* textarea and lael for desription */}
                <div className="mt-2">
                  <label htmlFor="">Describe your problem</label>
                  <textarea
                    className="form-control"
                    name="description"
                    rows="5"
                    placeholder="Enter Description"
                    onChange={handleChnage}
                  ></textarea>
                </div>

                {/* attachment */}
                <div className="mt-2">
                  <label htmlFor="">Attachment</label>
                  <input
                    className="form-control"
                    type="file"
                    name="attachments"
                    id=""
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>

                <div className="mt-3">
                  <button className="btn btn-danger w-100" type="submit">
                    Submit an issue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpHome;