const citiesByProvince = {
  Ontario: ["Toronto", "Ottawa", "Hamilton"],
  Quebec: ["Montreal", "Quebec City", "Sherbrooke"],
  BritishColumbia: ["Vancouver", "Victoria", "Kelowna"],
  NovaScotia: ["halifax"],
  NewBrunswick: ["Fredericton", "Moncton"],
  Manitoba: ["Winnipeg"],
  PrinceEdwardIsland: ["charlottedown"],
  Saskatchewan: ["Regina", "Saskatoon"],
  Alberta: ["Edmonton", "Calgary"],
  NewfoundlandandLabrador: ["st. Jon's"],

  // ... etc.
};
export default citiesByProvince