import React from "react";

import Wrapper from "../profile/tabs/organization/OverviewStyle";
import EditIcon from "@mui/icons-material/Edit";

import { ProfilePic } from "../profile/components";
import UpgradeForm from "./UpgradeForm";
const ProfileUpgrade = () => {
  // values use to make coverpage
  const options = {
    isedit: false,
    preview: null,
    coverimg: null,
    file: "",
  };
  const [coverimg, setcoverimg] = React.useState(options);
  const [profilepic, setPfile] = React.useState("");
  const [userInfo, setuserInfo] = React.useState({
    email: "",
    orgName: "",
  });
  const [picError, setpicError] = React.useState();

  // save profilepic
  const onfilesave = (file) => {
    setPfile(file);
  };

  // on coverpage edit
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      setcoverimg({
        ...coverimg,
        isedit: true,
        preview: blobURL,
        file: e.target.files[0],
      });
      e.target.value = "";
    }
  };
  return (
    <Wrapper
      className="container profile"
      coverpage={
        coverimg.preview ??
        coverimg.coverimg ??
        "https://wallpapercave.com/wp/wp2823447.jpg"
      }
    >
      <div className="overview-header  w-100 ">
        <div className="overview-img  w-100 ">
          <div className="edit-icons position-absolute top-0   end-0 p-2 ">
            <input
              type="file"
              name=""
              id="orgcoverimg"
              accept="image/*"
              className="d-none"
              onChange={onEdit}
            />

            <label htmlFor="orgcoverimg">
              <EditIcon className="text-black-25 bg-hover p-1  rounded-circle" />
            </label>
          </div>
          <img
            src={
              coverimg.preview ??
              coverimg.coverimg ??
              "https://images.unsplash.com/photo-1530625243345-797b4c1836ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80"
            }
            alt=""
            srcSet=""
            className="object-cover w-100 h-100 "
          />
        </div>

        <div className="d-flex w-100 justify-content-center">
          <ProfilePic
            image={
              "https://images.unsplash.com/photo-1488161628813-04466f872be2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
            }
            id={"organizationpic"}
            onfilesave={onfilesave}
          />
        </div>
      </div>

      <div className="text-center ">
        <small className={picError ? "text-danger text-center" : ""}>
          {picError}
        </small>
        <h4>
          {userInfo.orgName.length === 0
            ? "Organization name"
            : userInfo.orgName}
        </h4>
        <p className="btn  shadow-0  w-auto bg-hover">
          {userInfo.email.length === 0 ? "email@gmail.com" : userInfo.email}
        </p>
      </div>

      <section>
        {/* profilepic and coverpage is passed as props in upgrade form */}
        <UpgradeForm
          coverimg={coverimg.file}
          profilepic={profilepic}
          setuserInfo={setuserInfo}
          setpicError={setpicError}
        />
      </section>
    </Wrapper>
  );
};

export default ProfileUpgrade;
