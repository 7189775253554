import ProtectApi from "./adminAuthorizeApi";

//change status of business for admin to control business
export const adminChangeBusinessStatus = (id, data) =>
  ProtectApi.put(`/api/admin/business/chanage_status_business/${id}`, {
    active: data,
  });

  // featured business

export const adminUpdateFeaturedBusiness = (id, data) =>
  ProtectApi.put(`/api/admin/business/featured_business/${id}`, {
    featured: data,
  });
  

// verify business
export const adminVerifyBusiness = (id, data) =>
  ProtectApi.put(`/api/admin/business/verify_business/${id}`, {
    status: data.status,
    active: data.active,
  });

// give_review_message_business
export const adminGiveReviewBusiness = (id, data) =>
  ProtectApi.put(`/api/admin/business/give_review_message_business/${id}`, {
    reviewMessage: data,
  });

// for admin get all business
export const adminGetAllBusiness = (keyword = "", page = 0, status) =>
  ProtectApi.get(
    `/api/admin/business/get_all_business?keyword=${keyword}&page=${page}&status=${status}`
  );

export const adminCreateBusinessAdmin = (data) =>
  ProtectApi.post("/api/admin/business/create_business", data);


  // edit business by admin
export const adminEditBusiness = (data, id) =>
ProtectApi.put(`/api/admin/business/edit_business/${id}`, data);