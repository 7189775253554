import React from "react";

import {
  Bedtime,
  BikeScooterTwoTone,
  FoodBank,
  LocalParking,
  Nature,
  Water,
  Wifi,
} from "@mui/icons-material";
import { MdOutlineTrain } from "react-icons/md";

const AdminBusinessDetailOffers = () => {
  return (
    <section>
      <h5 className="fw-bold mt-4">We offers.</h5>
      {/* icons and text */}

      <div className="d-flex gap-4">
        <div>
          <div className="d-flex gap-3">
            <Wifi size={30} />
            <p>Free Wifi</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <BikeScooterTwoTone size={30} />
            <p>2 Wheelers parking</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <LocalParking size={30} />
            <p>4 Wheelers parking</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <Nature size={30} />
            <p>Natural Enviroment</p>
          </div>
        </div>
        {/* --------------------- */}
        <div>
          <div className="d-flex gap-3">
            <FoodBank size={30} />
            <p>Thakali Set</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <Water size={30} />
            <p>Riverside Hotel</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <MdOutlineTrain size={30} />
            <p>Nearby Railway</p>
          </div>

          <div className="d-flex gap-3 mt-2">
            <Bedtime size={30} />
            <p>Deluxe Session</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminBusinessDetailOffers;
