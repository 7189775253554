import API from "./common_api";

// get all event
export const get_all_event = (keyword, page = 1) =>
API.get(`/api/event/get_all_events?keyword=${keyword}&page=${page}`);

// get event by id
export const get_event_by_id = (id) =>
API.get(`/api/event/get_events_details/${id}`);






