import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect } from "react";
import { ImCross } from "react-icons/im";
import {
  AdminEditAddBoardMember,
  AdminEditContactsAndLinks,
  AdminEditIntroduction
} from "./components/index";

import { Box, Container } from "@mui/material";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { adminUpdateOrganizationAction } from "../../../../../context/actions/admin/organizationAction";
import { useSelector } from "react-redux";
import { clearError } from "../../../../../context/reducers/adminSlice";
const AdminEditOrg = ({ data, onClose, activeindex }) => {

  // form  values
  const networkmem = data.boardMembers.map((item, index) => {
    const new_key = "fileUpload";
    return { ...item, [new_key]: false };
  });

  

  const formvalue = {
    description: data?.introduction?.description,
    email: data?.contactAndLinks.email,
    postalcode: data?.introduction?.postalCode,
    phonenumber: data?.contactAndLinks.phone,
    telephonenumber: data?.contactAndLinks.telephone,
    profilePic: `${process.env.REACT_APP_IMAGE_PATH}${data?.introduction?.profile}`,
    nameofowner: "",
    nameofcompany: data?.introduction?.organizationName,
    members: networkmem,
    location: data?.contactAndLinks.location,
    panfront: `${process.env.REACT_APP_IMAGE_PATH}${data?.introduction?.documentFrontpage}`,
    panback: `${process.env.REACT_APP_IMAGE_PATH}${data?.introduction?.documentBackpage}`,
    panfrontfile: "",
    panbackfile: "",
    fblink: data?.contactAndLinks.facebookLink,
    linkedinlink: "",
    twitterlink: data?.contactAndLinks.twitterLink,
    instagramlink: data?.contactAndLinks.instagramLink,
    websitelink: data?.contactAndLinks.websiteLink,
    province: data?.introduction?.provenience,
    city: data?.introduction?.city,
    establishedDate: data?.introduction?.establishedDate?.split("T")[0],
    category: data?.introduction?.category,
  };
  const options = {
    pImg: `${process.env.REACT_APP_IMAGE_PATH}${data.introduction?.profile}`,
    file: "",
  };

  const [value, setValue] = React.useState(formvalue);
  const [profilepic, setPfile] = React.useState(options);
  const [coverimg, setCoverimg] = React.useState({
    isEditing: false,
    preview: `${process.env.REACT_APP_IMAGE_PATH}${data.introduction.coverPage}`,
    file: null,
  });
  const [errors, setErrors] = React.useState({});

  const dispatch = useDispatch();

  // get state from store of admin_reducer
  const { adminUpdateOrganzationLoading, error } = useSelector((state) => ({
    error: state.admin_reducer.error,
    adminUpdateOrganzationLoading:
      state.admin_reducer.adminUpdateOrganzationLoading,
  }));

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error, dispatch]);

  

  const introductionValidate = () => {
    let isValid = true;
    let newerrors = {};
    var pattern = new RegExp(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    // Regular expression pattern for a valid phone number
    const phonePattern = /^\d{10}$/;

    const cleanedNumber = value.phonenumber.replace(/\D/g, "");
    const istel = phonePattern.test(value.telephonenumber);
    // Check if the cleaned number matches the pattern
    let isPhone = phonePattern.test(cleanedNumber);
    let isemail = pattern.test(value.email);
    if (
      !value.nameofcompany ||
      value.nameofcompany.length < 5 ||
      value.description.length < 200 ||
      !value.category ||
      !value.description ||
      !value.province ||
      !value.city ||
      !value.establishedDate ||
      !value.postalcode ||
      !value.panfront ||
      !value.panback ||
      !isemail ||
      !isPhone ||
      !istel ||
      !value.location ||
      !value.email ||
      value.members.length < 1
    ) {
      if (!value.nameofcompany) {
        newerrors.nameofcompany = "Please provide name of company *";
      }

      if (value.nameofcompany.length < 5) {
        newerrors.nameofcompany =
          "Name of company length should be more than 4";
      }
      if (!value.category) {
        newerrors.category = "Please provide category *";
      }
      if (!value.description) {
        newerrors.description = "Please provide description *";
      }

      if (value.description.length < 200) {
        newerrors.description =
          "Either description is empty or less than 200 characters *";
      }
      if (!value.province) {
        newerrors.province = "Please provide province *";
      }
      if (!value.city) {
        newerrors.city = "Please provide city *";
      }
      if (!value.establishedDate) {
        newerrors.establishedDate = "Please provide established date *";
      }
      if (!value.postalcode) {
        newerrors.postalcode = "Please provide postal code *";
      }
      if (!value.panfront) {
        newerrors.panfrontfile = "Please provide pan front *";
      }
      if (!value.panback) {
        newerrors.panbackfile = "Please provide pan back *";
      }
      if (!isemail) {
        newerrors.email = "Please provide valid email *";
      }
      if (!value.email) {
        newerrors.email = "Please provide valid email *";
      }
      if (!isPhone) {
        newerrors.phonenumber = "Please provide valid phonenumber *";
      }
      if (!istel) {
        newerrors.telephonenumber = "Please provide valid telephonenumber *";
      }
      if (!value.location) {
        newerrors.location = "Please provide location *";
      }
      if (value.members.length < 1) {
        newerrors.members = "Please provide at least one member *";
      }
      newerrors.message = "PLease enter required fields. *";
      isValid = false;
    }
    setErrors(newerrors);
    return isValid;
  };

  // if member is added or edited
  const onmemberchange = (newlist) => {
    setValue({ ...value, members: newlist });
  };

  const handleLocation = (location) => {
    setValue((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        location_place_name: location.place_name,
      },
    }));
  };

  // handle form value on input change
  const handleformvalue = (e) => {
    const name = e.target.name;
    switch (name) {
      case "province":
        setValue({ ...value, province: e.target.value, city: "" });
        break;

      case "address-search":
        setValue({ ...value, address: e.target.value });
        break;

      case "panfront":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panfrontfile: file });
        }

        break;

      case "panback":
        if (e.target.files && e.target.files[0]) {
          let file = e.target.files[0];
          let blobURL = URL.createObjectURL(file);
          setValue({ ...value, [e.target.name]: blobURL, panbackfile: file });
        }
        break;

      case "agreement":
        setValue({ ...value, [e.target.name]: !value.agreement });
        break;

      default:
        setValue({ ...value, [e.target.name]: e.target.value });
    }
  };

  
  // on form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = introductionValidate();

    if (isValid) {
      const formData = new FormData();
      formData.append("nameofcompany", value.nameofcompany);
      formData.append("description", value.description);
      formData.append("category", value.category);
      formData.append("province", value.province);
      formData.append("city", value.city);
      formData.append("establishedDate", value.establishedDate);
      formData.append("postalCode", value.postalcode);
      formData.append("documentFrontpage", value.panfrontfile);
      formData.append("documentBackpage", value.panbackfile);
      formData.append("coverPage", coverimg.file);
      formData.append("profile", profilepic.file);
      formData.append("email", value.email);
      formData.append("phonenumber", value.phonenumber);
      formData.append("telephonenumber", value.telephonenumber);
      formData.append("fblink", value.fblink);
      formData.append("websitelink", value.websitelink);
      formData.append("twitterlink", value.twitterlink);
      formData.append("instagramlink", value.instagramlink);
      formData.append("websiteLink", value.websitelink);
      formData.append("profilepic", profilepic.file);
      formData.append("coverimg", coverimg.file);
      formData.append("panfrontfile", value.panfrontfile);
      formData.append("panbackfile", value.panbackfile);
      formData.append("location_lat", value.location.lat);
      formData.append("location_lng", value.location.lng);
      formData.append(
        "location_place_name",
        value.location.location_place_name
      );

      dispatch(
        adminUpdateOrganizationAction({
      
          formData: formData,
          id: data?._id,
          toast: toast,
          activeindex: activeindex,
        })
      );
    }

    // const errors = validate();
    // if (Object.keys(errors).length === 0) {
    //   changeStepper();
    // }
  };


  const handleProfilePic = (value) => {
    let file = value.target.files[0];

    const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes

    if (file.size <= fileSizeLimit) {
      let blobURL = URL.createObjectURL(value.target.files[0]);
      setPfile({
        ...profilepic,
        pImg: blobURL,

        file: value.target.files[0],
      });
      value.target.value = "";
      setErrors({
        ...errors,
        image: "",
      });
    } else {
      setErrors({
        ...errors,
        image: "Please select a file smaller than 2MB *",
      });
    }
  };

  const onEdit = React.useCallback((e) => {
    if (e.target.files && e.target.files[0]) {
      const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes
      const file = e.target.files[0];
      if (file.size <= fileSizeLimit) {
        const blobURL = URL.createObjectURL(file);
        setCoverimg((prevImg) => ({
          ...prevImg,
          isEditing: true,
          preview: blobURL,
          file,
        }));
        e.target.value = "";
        setErrors({
          ...errors,
          cimage: "",
        });
      } else {
        setErrors({
          ...errors,
          cimage: "Please select a file smaller than 2MB *",
        });
      }
    }
  }, []);
  return (
    <Container
      className="h-maxcontent position-relative"
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
    >
      <section className="option-header py-2">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Editing Organization
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>

      <div>
        <Box
          className="  w-100  overflow-hidden position-relative d-flex justify-content-center align-items-end"
          sx={{
            height: "30vh",
          }}
        >
          <Box
            sx={{
              height: "20vh",
            }}
            className="  w-100 rounded rounded-4 overflow-hidden  position-absolute top-0 start-0 position-relative"
          >
            <img
              src={coverimg.preview}
              alt=""
              srcSet=""
              className="object-cover w-100 h-100  "
            />

            <small className="text-danger position-absolute bottom-0 end-0 pe-2">
              {errors.cimage}
            </small>

            <div className="edit-icons position-absolute top-0   end-0 p-2 ">
              <input
                type="file"
                name=""
                id="coverimg"
                accept="image/*"
                className="d-none"
                onChange={onEdit}
              />
              <label htmlFor="coverimg">
                <EditIcon className="text-black-25 bg-hover p-1  rounded-circle" />
              </label>
            </div>
          </Box>
          <div className="d-flex flex-column align-items-center">
            <Box
              className=" position-relative  "
              sx={{
                overflow: "hidden",
                height: "175px",
                width: "175px",
                borderRadius: "50%",
                border: errors.image
                  ? "10px solid #DC4C64"
                  : "10px solid #f1f1f1",
              }}
            >
              <img
                alt="Organization Profile Pic"
                src={profilepic.pImg}
                className="object-cover object-center w-100 h-100"
              />
              <input
                type="file"
                name="userPic"
                id="userPic"
                accept="images/*"
                className="d-none"
                onChange={(e) => {
                  handleProfilePic(e);
                }}
              />

              <label
                htmlFor="userPic"
                className="position-absolute bottom-0 start-0 w-100 p-2 bg-dark bg-opacity-50  text-white text-center"
              >
                Edit
              </label>
            </Box>
            <small className={errors.image ? "text-danger text-center" : ""}>
              {errors.image}
            </small>
          </div>
        </Box>
      </div>

      <div className="steppers mt-3">
        {/* step 1 = add basic info and upload pan document */}
        <AdminEditIntroduction
          value={value}
          handleformvalue={handleformvalue}
          handleSubmit={handleSubmit}
          errors={errors}
        />

        {/* step 2 = add contacts and links */}
        <AdminEditContactsAndLinks
          handleLocation={handleLocation}
          handleSubmit={handleSubmit}
          handleformvalue={handleformvalue}
          errors={errors}
          value={value}
        />

        {/* step 3 = add or edit board members */}
        <AdminEditAddBoardMember
          value={value}
          onmemberchange={onmemberchange}
          handleSubmit={handleSubmit}
          errors={errors.members}
        />

        {/* step 4 agree on terms and conditions */}
      </div>

      <Box
        sx={{
          bottom: 0,
          zIndex: 3,
          color: "black",
        }}
        className="position-sticky d-flex justify-content-end gap-1 py-2 align-items-center  start-0 w-100 bg-white "
      >
        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <button
          className="btn btn-sm btn-dark text-white fw-bold"
          onClick={handleSubmit}
        >
          Update Organization
        </button>
      </Box>
    </Container>
  );
};

export default AdminEditOrg;
