import React from "react";
import positionlist from "../../../../../../../utils/all_position";

import { Box, Modal } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { FormSelectB, FormInputB } from "../../../../../../../components/Index";

const UserEditAddBoardMember = ({
  value,
  onmemberchange,
  handleSubmit,
  errors,
}) => {
  // image edit option

  const newlst = value?.members?.map((item) => item.memberPhoto);
  const options = {
    isedit: false,
    preview: null,
    file: "",
  };

  // modal style
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95%", md: 400 },

    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: { xs: 2 },
  };

  // member state when edit / add
  const member = {
    isedit: false,
    memberName: "",
    memberPhoto: null,
    memberPosition: "",
    fileUpload: true,
  };

  // stores image state
  const [option, setoptions] = React.useState(options);

  const [merrors, setErrors] = React.useState({});

  // stores Add/Edit Modal  state
  const [open, setOpen] = React.useState(false);

  // stores forminput state of Modal
  const [newmember, setnewmember] = React.useState(member);

  // stores index of member to edit
  const [editindex, setindex] = React.useState(0);

  // stores list of blob images to preview
  const [imglst, setimglst] = React.useState([...newlst]);

  // on image upload or edit
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      const fileSizeLimit = 2 * 1024 * 1024; // 2MB in bytes

      if (file.size <= fileSizeLimit) {
        let blobURL = URL.createObjectURL(file);
        setoptions({
          ...option,
          file: file,
          isedit: true,
          preview: blobURL,
        });
        setnewmember({ ...newmember, memberPhoto: file, fileUpload: true });
        setErrors({
          ...merrors,
          image: "",
        });
      } else {
        // Handle file size exceeding limit
        setErrors({
          ...merrors,
          image: "Please select a file smaller than 2MB *",
        });
      }
    }
  };

  // open modal
  const handleOpen = () => {
    setOpen(true);
  };

  // close modal
  const handleClose = () => {
    setnewmember(member);
    setoptions(options);
    setErrors({});

    setOpen(false);
  };

  // handle input change of edit/add modal
  const handlemember = (e) => {
    setnewmember({ ...newmember, [e.target.name]: e.target.value });
  };

  // handles add or edit members
  const addmember = (e) => {
    e.stopPropagation();
    const isedit = newmember.isedit;

    const newlist = value.members;

    const newboardmember = { ...newmember };

    // delete key from object
    delete newboardmember["isedit"];

    let newerrors = {};
    if (isedit) {
      if (
        !newmember.memberName ||
        !option.preview ||
        !newmember.memberPosition
      ) {
        if (!newmember.memberName) {
          newerrors.memberName = "Please provide name *";
        }
        if (!option.preview) {
          newerrors.image = "Please provide image *";
        }
        if (!newmember.memberPosition) {
          newerrors.memberPosition = "Please provide position *";
        }
        newerrors.message = "PLease enter required fields.";
      } else {
        newlist[editindex] = newboardmember;
        const uploadimg = imglst;
        uploadimg[editindex] = option.preview;
        setimglst(uploadimg);

        onmemberchange(newlist);
        handleClose();
      }
    } else {
      if (
        !newmember.memberName ||
        !newmember.memberPhoto ||
        !newmember.memberPosition
      ) {
        if (!newmember.memberName) {
          newerrors.memberName = "Please provide member Name *";
        }
        if (!newmember.memberPhoto) {
          newerrors.memberPhoto = "Please provide member Photo *";
        }
        if (!newmember.memberPosition) {
          newerrors.memberPosition = "Please provide member Position *";
        }
        newerrors.message = "PLease enter required fields.";
      } else {
        newlist.push(newboardmember);
        onmemberchange(newlist);
        const uploadimg = imglst;
        uploadimg.push(option.preview);
        setimglst(uploadimg);

        handleClose();
        setoptions(options);
      }
    }
    setErrors(newerrors);
  };

  //  remove member
  const deletemember = (index) => {
    const newlst = value.members;
    const newimglst = imglst;

    imglst.splice(index, 1);
    newlst.splice(index, 1);
    setimglst(newimglst);
    onmemberchange(newlst);
  };

  // open edit modal
  const handleEditOpen = (index, item) => {
    setnewmember({
      isedit: true,
      memberName: item.memberName,
      memberPosition: item.memberPosition,
    });

    setoptions({ ...option, preview: imglst[index] });
    setindex(index);
    handleOpen();
  };

  return (
    <Box className="">
      <div className="d-flex  mt-3 justify-content-between  ">
        <span className="fs-4 fw-bold ms-auto ">
          Board Members &nbsp; ({value.members.length})
        </span>
        <button className="btn btn-danger ms-auto " onClick={handleOpen}>
          Add New
        </button>
      </div>
      <small className={errors ? "form-text text-danger my-2 text-center" : ""}>
        {errors}
      </small>
      <Box
        sx={{
          minHeight: "40vh",
        }}
        className="row row-cols-lg-4 row-cols-1 row-cols-sm-2 row-cols-md-3 h-100 g-3"
      >
        {value.members.map((item, index) => {
          return (
            <div className="p-2" key={index}>
              <Box
                key={index}
                className="d-flex align-items-center gap-3 bg-hover col p-3 justify-content-between"
              >
                <img
                  src={item.fileUpload ? imglst[index] : imglst[index]}
                  alt=""
                  srcSet=""
                  height={70}
                  width={70}
                  className="rounded-circle object-cover"
                />
                <div className="">
                  <p className="fw-blod text-nowrap">{item.memberName}</p>
                  <small>{item.memberPosition}</small>
                </div>
                <div className="dropdown">
                  <MoreHorizIcon
                    className="dropdown-toggle"
                    role="button"
                    id={`dropdownMenuLink${index}`}
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul
                    className="dropdown-menu"
                    aria-labelledby={`dropdownMenuLink${index}`}
                  >
                    <li
                      className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                      onClick={() => handleEditOpen(index, item)}
                    >
                      <EditIcon className=" " />
                      <span className="text-center col">Edit</span>
                    </li>

                    <li
                      className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                      onClick={() => deletemember(index)}
                    >
                      <DeleteIcon className=" " />
                      <span className="text-center col">Delete</span>
                    </li>
                  </ul>
                </div>
              </Box>
            </div>
          );
        })}
      </Box>

      {/* Add and Edit Modal */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex  align-items-center flex-column bg-hover p-2 rounded rounded-3">
            <div className="w-100 d-flex justify-content-center">
              <h3 className=" fw-bold text-dark ">
                {newmember.isedit ? "Edit" : "Add"} Board Memebers
              </h3>
            </div>
            <div className="d-flex  w-100 border justify-content-start border-0 flex-column rounded rounded-3 align-items-center">
              <div className="profilepic text-center m-auto position-relative ">
                <Box
                  className="profilepic text-center m-auto position-relative  "
                  sx={{
                    height: { xs: "calc(10em + 5vw)", md: "calc(50px + 7vw)" },
                    width: { xs: "calc(10em + 5vw)", md: "calc(50px + 7vw)" },
                    maxHeight: "180px",
                    maxWidth: "180px",
                    border: merrors.image
                      ? "10px solid #DD566C"
                      : "10px solid #F1F1F1",
                    margin: "auto",
                    cursor: "pointer",
                    borderRadius: "50%",

                    "&:hover > #child-element ": {
                      visibility: "visible",
                      height: "50%",
                    },

                    overflow: "hidden",
                  }}
                >
                  <img
                    alt="Remy Sharp"
                    src={option.preview ?? "/assets/images/userPic.png"}
                    className="object-cover object-center w-100 h-100"
                  />

                  <Box
                    className="bg-black hover-section w-100 text-white d-flex  align-items-center justify-content-center fw-bold bg-opacity-50 position-absolute bottom-0  start-0  w-100"
                    sx={{
                      borderBottomLeftRadius: "300px",
                      borderBottomRightRadius: "300px",
                      visibility: "hidden",
                      cursor: "pointer",
                      height: "0",
                      transition: "height 1s ",
                    }}
                    id="child-element"
                  >
                    <label
                      htmlFor="uploadphoto"
                      className="h-100 w-100 d-flex align-items-center justify-content-center  "
                      role="button"
                    >
                      Edit
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      className="d-none"
                      id="uploadphoto"
                      onChange={onEdit}
                    />
                  </Box>
                </Box>
                <small className={merrors.image ? "text-danger" : ""}>
                  {merrors.image}
                </small>
              </div>

              <div className="w-100">
                <FormInputB
                  name={"memberName"}
                  placeholder={"Enter  name"}
                  required={true}
                  label={"Name"}
                  handleChange={handlemember}
                  value={newmember.memberName}
                  helpertext={merrors.name}
                  error={merrors.name ? true : false}
                />
                <FormSelectB
                  name={"memberPosition"}
                  placeholder={"Enter  position"}
                  label={"position"}
                  handleChange={handlemember}
                  list={positionlist}
                  value={newmember.memberPosition}
                  error={merrors.position ? true : false}
                  helpertext={merrors.position}
                />
              </div>
            </div>
            <button className="btn btn-dark mt-3 w-75" onClick={addmember}>
              submit
            </button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default UserEditAddBoardMember;
