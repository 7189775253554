
import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../actions/notificationAction";

const initialState = {
    notifications: [],
    loading: false,
    error: "",
    msg: "",
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        getNotification: (state, action) => {
            state.notifications = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder 
        .addCase(getNotifications.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getNotifications.fulfilled, (state, action) => {
            const { notifications } = action.payload;
            state.notifications = notifications;
            state.loading = false;
        })
        .addCase(getNotifications.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.message;
        })
    },
});

export const { getNotification } = notificationSlice.actions;
export default notificationSlice.reducer;