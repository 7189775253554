import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const AddFlatemateProtectedRoute = () => {
  const { user_flatmate_data } = useSelector((state) => state.user_reducer);

  return Object.keys(user_flatmate_data).length > 0 ? (
    <Navigate to="/profile" replace />
  ) : (
    <Outlet />
  );
};

export default AddFlatemateProtectedRoute;
