import React from "react";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
const MultipleCheckInput = ({
  item,
  selected,
  handleFormValue,
  list,
  index,
  name,
}) => {
  const addaccomondationType = () => {
    const newfeatures = [...list];
    if (selected) {
      const index = newfeatures.indexOf(item);
      if (index > -1) {
        newfeatures.splice(index, 1);
      }
    } else {
      newfeatures.push(item);
    }
    handleFormValue(name, newfeatures);
  };
  return (
    <div className="d-flex align-items-center gap-1 ">
      <Checkbox
        checked={selected}
        onChange={addaccomondationType}
        value={item}
        name={name}
        id={`${name}${index}`}
        inputProps={{ "aria-label": `A${item}` }}
        size="small"
        className="m-0 p-0 "
        sx={{
          "&.Mui-checked": {
            color: "#005CE6",
          },
        }}
      />

      <label
        htmlFor={`${name}${index}`}
        // onClick={addaccomondationType}
        className="d-flex align-items-center"
      >
        <Typography
          variant="span"
          sx={{
            fontSize: { xs: "0.7em", sm: "0.9em", lg: "1em" },
          }}
          className="text-nowrap"
        >
          {item}
        </Typography>
      </label>
    </div>
  );
};

export default MultipleCheckInput;
