import { Box } from "@mui/material";
import React from "react";

const FormInputList = ({
  errors,
  list,
  handleChange,
  name,
  listType,
  minHeight,
  placeholder,
}) => {
  const itemRefs = React.useRef([]);
  const textAreaRef = React.useRef(null);
  const [isFocused, setFocused] = React.useState(false);

  let listStyle = {};

  const defaultlistStyle = {
    listStyle: "none",
    listType: "none",
    listStylePosition: "inside",
  };

  if (listType === "disc") {
    listStyle = { listStyleType: "disc", listStylePosition: "inside" };
  } else if (listType === "circle") {
    listStyle = { listStyleType: "circle", listStylePosition: "inside" };
  } else if (listType === "square") {
    listStyle = { listStyleType: "square", listStylePosition: "inside" };
  }

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter" && list[index].text.length > 0) {
      event.preventDefault();
      if (index < list.length - 1) {
        focusInput(list.length - 1);
      } else {
        addTextToList();
      }
    } else if (
      event.key === "Backspace" &&
      event.target.value === "" &&
      list.length > 1
    ) {
      event.preventDefault();
      removeEmptyListItem();
    }
  };

  const addTextToList = () => {
    const newItem = { text: "" };
    handleChange(name, [...list, newItem]);
  };

  const removeEmptyListItem = () => {
    const lastItem = list[list.length - 1];
    if (lastItem && lastItem.text === "") {
      handleChange(name, list.slice(0, -1));
      focusInput(list.length - 2);
    }
  };

  const focusInput = React.useCallback(
    (index) => {
      if (index >= 0 && itemRefs.current[index]) {
        itemRefs.current[index].focus();
      }
    },
    [itemRefs]
  );

  React.useLayoutEffect(() => {
    const lastIndex = list.length - 1;
    const lastItem = list[lastIndex];
    if (lastItem && lastItem.text === "") {
      if (isFocused) {
        focusInput(lastIndex);
        if (!isFocused) {
          setFocused(true);
        }
      }
    }
  }, [list.length, focusInput, list]);

  const handleItemChange = (event, index) => {
    const updatedList = [...list];
    updatedList[index].text = event.target.value;
    handleChange(name, updatedList);
  };

  React.useEffect(() => {
    // add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    event.stopPropagation();

    if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
      setFocused(false);
    }
  };

  const onFocused = () => {
    if (!isFocused) {
      setFocused(true);
      const lastIndex = list.length - 1;
      focusInput(lastIndex);
    }
  };

  return (
    <div className="f">
      <Box
        ref={textAreaRef}
        onClick={onFocused}
        sx={{
          minHeight: minHeight,
        }}
        className={
          errors.description
            ? "form-control cursor-text p-2 is-invalid "
            : isFocused
            ? "form-control cursor-text border border-primary border-2"
            : "form-control cursor-text p-2"
        }
      >
        <ol
          className={list.length === 1 ? "p-1 px-1 m-0" : "p-1 px-3 m-0 "}
          style={list.length === 1 ? defaultlistStyle : listStyle}
        >
          {list.map((item, index) => (
            <li key={index} className=" ">
              <input
                placeholder={index === 0 ? placeholder : ""}
                type="text"
                className="input-field-no-border w-100 bg-transparent text-black"
                value={item.text}
                ref={(el) => (itemRefs.current[index] = el)}
                onChange={(event) => handleItemChange(event, index)}
                onKeyDown={(event) => handleKeyPress(event, index)}
              />
            </li>
          ))}
        </ol>
      </Box>

      <small className={errors.description ? "form-text text-danger" : ""}>
        {errors.description}
      </small>
    </div>
  );
};

export default FormInputList;
