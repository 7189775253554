import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { Box, Drawer } from "@mui/material";
import StudentRequirements from "./StudentRequirements/StudentRequirements";
export default function StudentRequirmentsTable({
  keyword,
  requirements,
  loading,
  totalstudentrequirementcount,
  resultperpage,
  page,
  setPage,
}) {
  const [activeIndex, setActive] = React.useState(0);

  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const toggleDrawerDetail = () => setIsOpenDetail((prev) => !prev);


  const columns = [
    {
      field: "flag",
      width: 150,
      renderCell: (params) => (
        <img
          src="https://flagcdn.com/48x36/np.png"
          alt="Flag"
          style={{ width: "35%", height: "auto", borderRadius: "50%" }}
        />
      ),
    },
    { field: "country", headerName: "Country Name", flex: 1 },
    { field: "requrements", headerName: "Total Requirements", flex: 1 },
    { field: "Views", headerName: "Views", flex: 1 },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const { id } = params.row;
        // const {country} = params.row


        return (
          <div className="d-flex align-items-center gap-2">
            <Box
              sx={{
                height: "40px",
                width: "40px",
              }}
              className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
            >
              <VisibilityIcon
                onClick={() => {
                  setActive(id);
                  // setCountry(country)
                  toggleDrawerDetail();
                }}
                role="button"
              />
            </Box>
           
          </div>
        );
      },
    },
  ];

  const mappedRows = requirements?.map((requiremnet, index) => ({
    id: index,
    index: index,
    flag : requiremnet?.coverpage,
    country: requiremnet?.country,
    requrements: requiremnet?.categories?.length,
    Price: 12,
    Category: requiremnet?.country,
    Views: 11,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalstudentrequirementcount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpenDetail} onClose={toggleDrawerDetail}>
        <StudentRequirements
          data={requirements[activeIndex]?.categories}
          country={requirements[activeIndex]?.country}
          closeModal={toggleDrawerDetail}
          onClose={toggleDrawerDetail}
          activeindex={activeIndex}
        />
      </Drawer>
    </div>
  );
}
