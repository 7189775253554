import { Box, Input } from "@mui/material";
import React from "react";
import { AvailableDate, DurationSelect } from "../../../../components/rental";
import {
  mduration,
  tentantAccess,
  typerent,
} from "../../../../utils/rentSettings";

import { useLocation, useNavigate } from "react-router-dom";
import MultipleLocationInput from "../../components/MultipleLocationInput";
import MultipleRadioInput from "../../components/MultipleRadioInput";
const RentalFilterModal = ({ onPageTabChange, closeModal }) => {
  const rentalValues = {
    houseType: "Any",
    accessTo: "Any",
    minimumStay: "",
    availableDate: "",
    title: "",
    locations: [],
    minPrice: "",
    maxPrice: "",
  };

  const [rentalValue, setrentalValues] = React.useState(rentalValues);
  const locationv = useLocation();
  const queryParams = new URLSearchParams(locationv.search);

  React.useEffect(() => {
    if (queryParams.get("type") === "Rental") {
      const location = queryParams.getAll("location") || [];
      console.log(location);
      setrentalValues({
        ...rentalValue,
        locations: location,
      });
    }
  }, [queryParams]);

  const clearall = () => {
    setrentalValues(rentalValues);
  };

  const navigate = useNavigate();
  //filter
  const onFilter = () => {
    queryParams.delete("fminPrice");
    queryParams.delete("fmaxPrice");
    queryParams.delete("flookingFor");
    queryParams.delete("flengthOfStay");
    queryParams.delete("fmoveInDate");
    queryParams.delete("fminAge");
    queryParams.delete("fmaxAge");
    queryParams.delete("faccomondationTypes");

    queryParams.set("type", "Rental");
    queryParams.set("houseType", rentalValue.houseType);
    queryParams.set("accessTo", rentalValue.accessTo);
    queryParams.set("minimumStay", rentalValue.minimumStay);
    queryParams.set("availableDate", rentalValue.availableDate);
    queryParams.set("title", rentalValue.title);
    queryParams.set("minPrice", rentalValue.minPrice);
    queryParams.set("maxPrice", rentalValue.maxPrice);
    navigate(`/rental/filter/?${queryParams.toString()}`);
    closeModal();
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "price":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setrentalValues({ ...rentalValue, price: parseInt(value) });
        }
        break;

      case "addprice":
        if (value === "") {
          setrentalValues({ ...rentalValue, price: 1 });
        }

        if (value <= 100000000) {
          const addedprice = parseInt(value) + 1;
          setrentalValues({ ...rentalValue, price: addedprice });
        }
        break;

      case "minPrice":
        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(value)) {
          setrentalValues({ ...rentalValue, [name]: value });
        }

        break;

      case "maxPrice":
        const onlyNumbersmax = /^[0-9]*$/;
        if (onlyNumbersmax.test(value)) {
          setrentalValues({ ...rentalValue, [name]: value });
        }

        break;

      case "location":
        const newlst = [...rentalValue.locations];
        newlst.push(value);
        setrentalValues({ ...rentalValue, locations: newlst });
        break;

      case "removelocation":
        const updatedlst = [...rentalValue.locations];
        updatedlst.splice(value, 1);

        setrentalValues({ ...rentalValue, locations: updatedlst });
        break;

      case "minusprice":
        if (value === "") {
          setrentalValues({ ...rentalValue, price: 0 });
        }
        if (value > 0) {
          const minusprice = parseInt(value) - 1;
          setrentalValues({ ...rentalValue, price: minusprice });
        }
        break;

      default:
        setrentalValues({ ...rentalValue, [name]: value });
    }
  };

  return (
    <Box
      className="h-100 "
      sx={{
        p: { xs: 2, md: 3 },
      }}
    >
      <div className="position-relative h-100 w-100 d-flex flex-column gap-2">
        <Box className="">
          <p className="fw-bold fs-6  text-dark mb-2">
            Search location - provenince, city, suburb
          </p>

          <MultipleLocationInput
            handleLocation={handleFormValue}
            placeholder={"Start with POSTAL CODE | M4B 2J8"}
            isBorder={false}
            locationlst={rentalValue?.locations}
          />
        </Box>
        <hr />

        <div className=" ">
          <p className="fw-bold fs-6  text-dark mb-2">Set your budget</p>

          <div className="d-flex gap-2">
            <div className="d-flex align-items-center gap-2">
              <span>$</span>
              <Input
                placeholder="Min price"
                type="number"
                value={rentalValue.minPrice}
                onChange={(e) => handleFormValue("minPrice", e.target.value)}
              />
            </div>
            <div className="d-flex align-items-center gap-1">
              <span>$</span>
              <Input
                placeholder="Max price"
                type="number"
                value={rentalValue.maxPrice}
                onChange={(e) => handleFormValue("maxPrice", e.target.value)}
              />
            </div>
          </div>
        </div>
        <hr />

        <section>
          <p className="fw-bold fs-6  text-dark mb-2">Select property type</p>

          <div className="row row-cols-2 gx-2 gy-2">
            <MultipleRadioInput
              item={"Any"}
              selected={rentalValue.houseType}
              name={"houseType"}
              index={"0101"}
              handleFormValue={handleFormValue}
            />
            {typerent.map((item, index) => {
              return (
                <MultipleRadioInput
                  item={item.name}
                  selected={rentalValue.houseType}
                  name={"houseType"}
                  index={index}
                  key={index}
                  handleFormValue={handleFormValue}
                />
              );
            })}
          </div>
        </section>
        <hr />
        <section>
          <p className="fw-bold fs-6  text-dark mb-2">Tenent can access</p>

          <div className="row row-cols-2 gx-2 gy-2">
            <MultipleRadioInput
              item={"Any"}
              selected={rentalValue.accessTo}
              name={"accessTo"}
              index={"index"}
              handleFormValue={handleFormValue}
            />
            {tentantAccess.map((item, index) => {
              return (
                <MultipleRadioInput
                  item={item.name}
                  selected={rentalValue.accessTo}
                  name={"accessTo"}
                  index={index}
                  key={index}
                  handleFormValue={handleFormValue}
                />
              );
            })}
          </div>
        </section>
        <hr />

        <div className="d-flex align-items-center gap-2">
          <div className="form-group w-100">
            <label htmlFor="Length of Stay">
              What is the minimum length of stay?
            </label>

            <DurationSelect
              minimumStay={rentalValue.minimumStay}
              handleFormValue={handleFormValue}
              name={"minimumStay"}
              durations={mduration}
              errors={false}
              placeholder={"Select Minimum Stay"}
            />
          </div>

          <div className="form-group w-100">
            <label htmlFor="availableDate">
              When is the property available for rent?
            </label>

            <AvailableDate
              availabeDate={rentalValue.availableDate}
              handleFormValue={handleFormValue}
              errors={false}
              name={"availableDate"}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between position-sticky bg-white py-2 bottom-0 end-0">
        <span
          role="button"
          className=" text-decoration-underline text-danger"
          onClick={clearall}
        >
          Clear
        </span>
        <button className="btn bg-black text-white " onClick={onFilter}>
          Filter
        </button>
      </div>
    </Box>
  );
};

export default RentalFilterModal;
