import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Rating,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { BsCalendar2Date, BsClock, BsPersonSquare } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  Bedtime,
  BikeScooterTwoTone,
  Email,
  Facebook,
  FavoriteBorderOutlined,
  FoodBank,
  LocalParking,
  LocationOn,
  Nature,
  Phone,
  RemoveRedEye,
  Twitter,
  Water,
  Wifi,
} from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LaunchIcon from "@mui/icons-material/Launch";
import TwitterIcon from "@mui/icons-material/Twitter";
import { MdOutlineTrain } from "react-icons/md";
import ServicesModal from "../../../../businessdetail/components/ServicesModal";

// test data

import { BiPencil } from "react-icons/bi";

import { toast } from "react-toastify";
import ShowLocation from "../../../../../components/location/ShowLocation";
import {
  adminGiveReviewBusinessAction,
  adminVerifyBusinessAction
} from "../../../../../context/actions/admin/businessAction";
const description =
  "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni sint inventore similique perspiciatis mollitia pariatur iure, delectus accusantium temporibus impedit earum fuga corporis ab nesciunt, inventore consequatur? Laudantium iste accusamus officiis repellendus exercitationem ipsa cupiditate neque placeat voluptatem, quas explicabo et molestias consequatur quod consectetur quia  .";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 200px;
  gap: 5px;
  border-radius: 25px;
  & > .item:nth-child(1) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 2;
  }
`;


const BusinessDetail = ({ data, closeModal,activeindex }) => {
  
  // dispatch the action
  const dispatch = useDispatch();


  // use useSelector to get data from store from business_reducer

  const { contactAndLinks, introduction, images } = data;
  const business_comments = [];

  const average_rating = 5;
  const avg_rating = 5;

  // show more description
  const [showMore, SetshowMore] = React.useState(false);
  // open modal
  const [open, setOpen] = React.useState(false);

  // tab change
  const [value, setValue] = React.useState("1");

  // like state

  // open services modal
  const [openServices, setOpenServices] = React.useState(false);
  const handleOpenServices = () => setOpenServices(true);
  const handleCloseServices = () => setOpenServices(false);


  const reviewoption = {
    comment: "",
    rating: 0,
  };

  const [comment, setcomment] = React.useState("");

  const commentchange = (e) => {
    if (comment.length < 300) {
      setcomment(e.target.value);
    }
  };

  const handleReview = (e) => {
    e.preventDefault();
    dispatch(
      adminGiveReviewBusinessAction({
        id: data._id,
        data: comment,
        toast,
        activeindex,
      })
    );
    setcomment("");
  };


  // on tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  const handleApprove = (e) => {
    e.preventDefault();
    const options = {
      status: true,
      active: true,
    };

    

    dispatch(
      adminVerifyBusinessAction({
        id: data._id,
        data: options,
        toast,
        activeindex,
      })
    );
  };
  // render the jsx
  return (
    <Box
      sx={{
        width: {
          xs: "100%",
          md: "80vw",
          lg: "70vw",
        },
      }}
      className="container mt-3 d-flex flex-column gap-3"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Preview for your listing
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
              role="button"
              onClick={closeModal}
            />
          </Box>
        </div>
        <div className="d-flex w-100 justify-content-end mt-1">
          <div className="rental-actionbtn d-flex gap-2 align items-center h-50 mt-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-dark"
              data-mdb-ripple-color="dark"
            >
              <BiPencil /> Edit
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-mdb-ripple-color="dark"
            >
              Delist property
            </button>
          </div>
        </div>
      </section>
      {/* Images Section */}
      <Wrapper className="grid-images position-relative rounded rounded-3 overflow-hidden">
        {images.map((item, index) => {
          return (
            <div className="w-100 h-100 item" key={index}>
              <img
                // src="https://images.unsplash.com/photo-1517248135467-4c7edcad34c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                alt=""
                className="w-100 object-cover h-100"
              />
            </div>
          );
        })}

        {/* open modal button */}
        <Box
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "1%",
          }}
          className="btn d-flex gap-2 align-items-center bg-white"
          onClick={handleOpen}
        >
          <CgMenuGridR className="position bottom-0" />
          <span>Show all photos</span>3
        </Box>
      </Wrapper>

      <section className="d-flex flex-column text-black ">
        <div className="d-flex w-100 justify-content-between">
          {/* <span className="fs-2 ">{introduction.businessName}</span> */}
          <span className="fs-3">Shrestha Hotal Inc, corporate office</span>

          <div className="action-icons d-flex gap-3 align-items-center">
            <ButtonGroup variant="outlined" aria-label="outlined button group">
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <RemoveRedEye />
                  235
                </div>
              </Button>
              <Button>
                <div className="clapped d-flex align-content-center gap-1">
                  <FavoriteBorderOutlined />
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <h6>
          <span className="fw-bold">
            <LocationOn fontSize="small" /> 123 Street America, New York
          </span>
        </h6>

        <div className="d-flex">
          <Rating
            size="small"
            name="half-rating-read"
            value={average_rating}
            precision={0.5}
            readOnly
          />
          <Typography
            sx={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginLeft: "0.2rem",
            }}
          >
            {avg_rating} |{" "}
            <span className="text-decoration-underline ">
              {business_comments?.length} Reviews
            </span>
          </Typography>
        </div>
      </section>

      {/* left-right sider divider */}
      <div className="left-right-section d-flex row row-cols-lg-3 row-cols-1 w-100  ">
        {/* left section */}
        <div className="left-section col-lg-8 col pe-lg-5  ">
          {/* General Overview section */}
          <section className="org-three col-lg-10 mt-4 mb-4 text-hover">
            <h5 className="fw-bold">General Information</h5>
            <Divider />
            <div className="d-flex gap-4 mt-2 w-100">
              <div className="d-flex gap-2">
                <BsCalendar2Date size={40} />
                <div>
                  <h6 className="m-0">Founded</h6>
                  <h5 className="fw-bold">Jan 10, 2014</h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <BsPersonSquare size={40} />
                <div>
                  <h6 className="m-0">Founded by</h6>
                  <h5 className="fw-bold">Ram Chandra</h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <BsClock size={40} />
                <div>
                  <h6 className="m-0">Working Hours</h6>
                  <h5 className="fw-bold">10AM - 5PM Everyday</h5>
                </div>
              </div>
            </div>
          </section>

          {/* About section */}
          <section className="d-flex flex-column w-100 mt-3">
            <h5 className="fw-bold">All you need to know</h5>
            <p>
              {showMore
                ? description
                : description.split(" ").slice(0, 100).join(" ")}
              {description.length > 200 ? (
                !showMore ? (
                  <span
                    onClick={toggleShowMore}
                    className="text-black fw-bold"
                    role="button"
                  >
                    ...ReadMore
                  </span>
                ) : (
                  <span
                    onClick={toggleShowMore}
                    className="text-black fw-bold"
                    role="button"
                  >
                    ...ReadLess
                  </span>
                )
              ) : null}
            </p>
          </section>

          <section>
            <h5 className="fw-bold mt-4">We offers.</h5>
            {/* icons and text */}

            <div className="d-flex gap-4">
              <div>
                <div className="d-flex gap-3">
                  <Wifi size={30} />
                  <p>Free Wifi</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <BikeScooterTwoTone size={30} />
                  <p>2 Wheelers parking</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <LocalParking size={30} />
                  <p>4 Wheelers parking</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <Nature size={30} />
                  <p>Natural Enviroment</p>
                </div>
              </div>
              {/* --------------------- */}
              <div>
                <div className="d-flex gap-3">
                  <FoodBank size={30} />
                  <p>Thakali Set</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <Water size={30} />
                  <p>Riverside Hotel</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <MdOutlineTrain size={30} />
                  <p>Nearby Railway</p>
                </div>

                <div className="d-flex gap-3 mt-2">
                  <Bedtime size={30} />
                  <p>Deluxe Session</p>
                </div>
              </div>
            </div>
            <button
              onClick={handleOpenServices}
              className="btn btn-sm btn-outline-black mt-2"
            >
              show all services
            </button>
            <ServicesModal
              openServices={openServices}
              handleCloseServices={handleCloseServices}
            />
          </section>

          {/* location section */}
          <section>
            <h5 className="fw-bold mt-4">Location of business</h5>
            <ShowLocation location={contactAndLinks?.location} />
          </section>
          <hr />
        </div>

        <Box
          sx={{
            height: "max-content",
          }}
          className="right-section col-lg-4 d-flex flex-column gap-2 rounded p-2 "
        >
          <section>
            <h5 className="fw-bold">Get in touch | Contacts</h5>
            <div className="d-flex gap-2 mt-1">
              <Chip
                icon={<FacebookIcon />}
                label="Facebook"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
              <Chip
                icon={<TwitterIcon />}
                label="Twitter"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
              <Chip
                icon={<LaunchIcon />}
                label="Website"
                variant="outlined"
                onClick={() => {
                  window.open(contactAndLinks.twitterLink);
                }}
                size="small"
              />
            </div>
            <div className="d-flex gap-3 mt-3">
              <Email
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <div>
                <p className="m-0 p-0">General Email</p>
                <p className=" fw-bold">{contactAndLinks.email}</p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Phone
                sx={{
                  fontSize: "3.5rem",
                  color: "#43a519",
                }}
              />
              <div>
                <p className="m-0 p-0">Phone No.</p>
                <p className="fw-bold">{contactAndLinks.phone}</p>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Facebook
                sx={{
                  fontSize: "3.5rem",
                  color: "#4267B2",
                }}
              />
              <div>
                <p className="m-0 p-0">Facebook | Social Media</p>
                <h6 className=" fw-bold">{contactAndLinks.twitterLink}</h6>
              </div>
            </div>
            <div className="d-flex gap-3 mt-3">
              <Twitter
                sx={{
                  fontSize: "3.5rem",
                  color: "#1DA1F2",
                }}
              />
              <div>
                <p className="p-0">Twitter | Social Media</p>
                <p className="fw-bold">{contactAndLinks.twitterLink}</p>
              </div>
            </div>
          </section>

          <hr />
        </Box>
      </div>

      <section className=" col-lg-10 w-100 mt-5 w-100 ">
        <h4 className="my-2 p-0 m-0">All information are reviewed?</h4>
        <div className="mt-2 text-end d-flex flex-column">
          <textarea
            className="form-control w-100 p-2"
            id="textAreaExample"
            rows="4"
            placeholder="Give your comments here..."
            value={comment}
            onChange={commentchange}
          ></textarea>
          <span className="my-2">{comment.length} / 300</span>
          <div className="d-flex w-100 justify-content-end gap-2 mt-2">
            <button
              className="btn shadow-0 "
              onClick={() => {
                setcomment("");
              }}
            >
              Clear
            </button>
            <button onClick={handleReview} className="btn shadow-0 btn-danger">
              Need More Info
            </button>
            <button
              onClick={handleApprove}
              className="btn shadow-0 btn-success "
            >
              Approve
            </button>
          </div>
        </div>
      </section>

      {/* Image slider Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={style} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            onClick={handleClose}
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <TabContext value={value}>
            {images.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      className=" object-contain"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <Tabs
              onChange={handleChange}
              className=" d-flex  justify-content-center mx-auto text-capitalize "
              sx={{
                justifyContent: "center",
                width: "max-content",
                "&.Mui-selected": {
                  color: "#797979",
                  fontWeight: "bolder",
                  border: "none",
                },
              }}
              indicatorcolor={""}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={value}
            >
              {images.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0"
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center"
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          widht={90}
                          className="object-cover w-100 mx-auto p-0"
                        />
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          </TabContext>
        </Box>
      </Modal>
    </Box>
  );
};

export default BusinessDetail;
