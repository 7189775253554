import React from "react";

import { Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import styled, { css } from "styled-components";

const AdminEventImage = React.memo(
  ({ handleimages, pImages, imageFile, nImages, removeImages, errors }) => {
    
    const Wrapper = styled.div`
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 150px;
      gap: 1%;
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
      border-radius: 20px;
      & > .post {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:nth-child(1) {
          grid-row: auto / span 2;
          grid-column: auto / span 2;
        }
        ${createCSS()}
      }
    `;

    function createCSS() {
      let styles = "";
      let number = 0;

      for (let i = 1; i < 20; i += 1) {
        if (i % 5 === 1) {
          number += 5;
        } else {
          number = number + 3;
          styles += `
           &:nth-child(${number}) {
             grid-row: auto / span 2;
             grid-column: auto / span 2;

           }
         `;
        }
      }

      return css`
        ${styles}
      `;
    }

    function handleDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      const imageFile = e.target.files || e.dataTransfer.files;

      const selectedFilesArray = Array.from(imageFile);
      const imageArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      handleimages({ files: selectedFilesArray, pImages: imageArray });
    }

    function handleDragOver(e) {
      e.preventDefault();
    }

    const onSelectedFile = (e) => {
      e.stopPropagation();
      e.preventDefault();
      const selectedFiles = e.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      let newfilearray = [...imageFile];
      let imageArray = [...pImages];

      for (let i = 0; i < selectedFilesArray.length; i++) {
        if (selectedFilesArray[i].size <= 5 * 1024 * 1024) {
          // Check if file size is less than or equal to 5MB
          imageArray?.push(URL.createObjectURL(selectedFilesArray[i]));
          newfilearray.push(selectedFilesArray[i]);
        }
      }

      //h
      handleimages({ files: newfilearray, pImages: imageArray });
    };

    const removeimage = (index) => {
      let newimagearray = [...pImages];
      let newfilearray = [...imageFile];
      let newNetworkarray = [...nImages];
      newimagearray = newimagearray
        .slice(0, index)
        .concat(newimagearray.slice(index + 1));

      newfilearray = newfilearray
        .slice(0, index)
        .concat(newfilearray.slice(index + 1));
      if (newNetworkarray.length + 1 > index) {
        newNetworkarray = newNetworkarray
          .slice(0, index)
          .concat(newNetworkarray.slice(index + 1));
      }
      removeImages({
        files: newfilearray,
        pImages: newimagearray,
        nImages: newNetworkarray,
      });
    };
    return (
      <Box
        onDrop={handleDrop}
        className={"mt-3  d-flex flex-column gap-2 w-100 "}
      >
        <div
          className="d-flex justify-content-between w-100
      align-items-center"
        >
          <div className="d-flex flex-column gap-0 w-100">
            <span className="mb-1 fw-bold fs-5">
              Some Photos of your listing
            </span>
            <p className="w-100">Most be 5+ photos</p>
          </div>
          <div className="d-flex w-100 justify-content-between align-items-center h-maxcontent">
            <input
              type="file"
              name=""
              accept="image/*"
              id="photoupload"
              multiple
              onChange={(e) => onSelectedFile(e)}
              className="mb-3 invisible"
              title=" "
            />
            <label
              htmlFor="photoupload"
              className="d-flex flex-column gap-0 btn fw-bold h-100 btn-dark text-capitalize "
            >
              Add
            </label>
          </div>
        </div>
        <Box
          className={
            pImages.length > 0 ? "py-2 px-1 rounded rounded-3" : "d-none "
          }
          sx={{
            border: pImages?.length > 0 ? "2px dashed gray" : "",
            minHeight: "350px",
          }}
        >
          <Wrapper>
            {pImages?.map((item, index) => {
              return (
                <Box
                  className="position-relative  p-1 overflow-hidden  post"
                  key={item}
                >
                  <img
                    src={
                      index >= nImages.length
                        ? `${item}`
                        : `${process.env.REACT_APP_IMAGE_PATH}${item}`
                    }
                    alt=""
                    srcSet=""
                    className="object-cover w-100 h-100 object-center"
                  />
                  <CancelIcon
                    role="button"
                    className="position-absolute  "
                    sx={{
                      top: "2%",
                      right: "2%",
                      color: "black",
                    }}
                    onClick={() => removeimage(index)}
                  />
                </Box>
              );
            })}
          </Wrapper>
        </Box>

        {pImages.length === 0 && (
          <div
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            className="w-100 position-relative text-center d-flex justify-content-center"
            style={{ height: "200px", border: "2px dashed gray" }}
          >
            <Box
              className="position-absolute text-center  "
              sx={{
                top: "50px",
              }}
            >
              <CollectionsOutlinedIcon sx={{ fontSize: "3em" }} />
              <label
                htmlFor="photoupload"
                className="d-flex flex-column gap-0 btn fw-bold h-100 btn-dark text-capitalize "
              >
                upload
              </label>
              <p>Drag and drop an image here</p>
            </Box>
          </div>
        )}
      </Box>
    );
  }
);

export default AdminEventImage;
