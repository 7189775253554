import React from "react";
import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { popularCities } from "../../../utils/globalSettings";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { useNavigate, useLocation} from "react-router-dom";
// import ExpandableLocationInput from "../components/ExpandableLocationInput";
import MultiPleLocationInput from "../../rentalFilter/components/MultipleLocationInput";
const AllRentalFilterModal = ({ rtype }) => {
  const formvalues = {
    locations: [],
  };
  const [flateMateValues, setFlateMateValues] = React.useState(formvalues);
  const [istab, setTab] = React.useState(rtype);

  const onTabChange = (value) => {
    setTab(value);
  };

  const navigate = useNavigate();
  const locationv = useLocation();

  const queryParams = new URLSearchParams(locationv.search);

  const onSubmit = (e) => {

   for(let i=0;i<flateMateValues.locations.length;i++){
      queryParams.append("location", flateMateValues.locations[i]);
    }
    queryParams.set("type", istab);
    navigate(`/rental/filter/?${queryParams.toString()}`);
   
  };

  const handleFormValue = (name, value) => {
    switch (name) {
      case "location":
        const newlst = [...flateMateValues.locations];
        newlst.push(value);
        setFlateMateValues({ ...flateMateValues, locations: newlst });
        break;

      case "removelocation":
        const updatedlst = [...flateMateValues.locations];
        updatedlst.splice(value, 1);

        setFlateMateValues({ ...flateMateValues, locations: updatedlst });
        break;

      default:
        setFlateMateValues({ ...flateMateValues, [name]: value });
    }
  };
  return (
    <div className="d-flex flex-column gap-3">
      <Box
        className="border border-dark rounded rounded-3 d-flex   "
        sx={{
          height: "40px",
        }}
      >
        <Typography
          variant="span"
          className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center "
          onClick={() => onTabChange("Rental")}
          role="button"
          sx={{
            background: istab === "Rental" ? "#474141" : "",
            color: istab === "Rental" ? "#f1ebeb" : "",
            fontSize: "1.1rem",
            paddingBlock: "1.1rem",
            borderRight: "1px solid black",
          }}
        >
          Rental
        </Typography>
        <Typography
          variant="span"
          className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
          onClick={() => onTabChange("FlateMates")}
          role="button"
          sx={{
            backgroundColor: istab === "FlateMates" ? "#474141" : "",
            color: istab === "FlateMates" ? "#f1ebeb" : "",
            fontSize: "1.1rem",
            paddingBlock: "1.1rem",

            borderRight: "1px solid black",
          }}
        >
          FlateMates
        </Typography>

        <Typography
          onClick={() => onTabChange("TeamUp")}
          role="button"
          variant="span"
          className=" py-0 fw-bold w-100 text-center d-flex align-items-center h-100 justify-content-center"
          sx={{
            fontSize: "1.1rem",
            paddingBlock: "1.1rem",

            backgroundColor: istab === "TeamUp" ? "#474141" : "",
            color: istab === "TeamUp" ? "#f1ebeb" : "",
          }}
        >
          Teamup
        </Typography>
      </Box>
      <MultiPleLocationInput
        handleLocation={handleFormValue}
        placeholder={"Start with POSTAL CODE | M4B 2J8"}
        isBorder={false}
        locationlst={flateMateValues.locations}
      />
      <button
        onClick={onSubmit}
        className="bg-black py-3 w-100 btn text-white d-flex align-items-center rounded rounded-2 h-maxcontent justify-content-center gap-2"
      >
        <SearchIcon />
        <span>Search & Filter</span>
      </button>
      <div className="d-flex flex-wrap gap-1 mt-3">
        {popularCities.map((item, index) => {
          return (
            <Typography
              key={item}
              variant="span"
              className="d-flex gap-1 align-items-center "
              sx={{
                cursor: "pointer",
                fontSize: "0.8rem",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <LocationOnOutlinedIcon
                sx={{
                  fontSize: "0.9em",
                }}
              />
              <span>{item}</span>
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default AllRentalFilterModal;
