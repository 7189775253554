import { NorthEastRounded } from "@mui/icons-material";
import { Box } from "@mui/material";
import "mapbox-gl/dist/mapbox-gl.css";
import React from "react";
import ReactMapGL, {
  Marker
} from "react-map-gl";

const ShowLocation = ({location}) => {
 
  var lat = location?.lat;
  var lng = location?.lng;
  var location_place_name = location?.location_place_name ?? location?.place_name;
  return (
    <>
      <p className="mb-2">
        {location_place_name}
        <a className="fw-bold ms-2" target="_blank" href={`https://www.google.com/maps/place/${location_place_name}`}>
          Show on Google Map
          <NorthEastRounded
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          />
        </a>
      </p>
      <Box
        sx={{
          height: 300,
        }}
      >
        <ReactMapGL
          mapboxAccessToken={process.env.REACT_APP_MAP_TOKEN}
          initialViewState={{
            latitude: lat,
            longitude: lng,
            zoom: 14,
          }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
        >
          {/* <Marker
            latitude={lat}
            longitude={lng}
            draggable
          /> */}

        </ReactMapGL>
      </Box>
    </>
  );
};

export default ShowLocation;
