import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ResetSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/login');
    }, 5000);

    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  useEffect(() => {
    if (countdown > 0) {
      const countdownInterval = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [countdown]);

  return (
    <div className='container'>
      <h4>password reset success</h4>
      <p>you will be redirected to the login page in {countdown} seconds</p>
    </div>
  );
}

export default ResetSuccess;
