import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/material";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import { GrCapacity } from "react-icons/gr";
import { AddLocation } from "../../../components/Index";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { HouseOutlined } from "@mui/icons-material";
import { accomondationTypes } from "../../../utils/flateMateSettings";
// select card
const AccomondationSelectCard = ({
  value,
  name,
  handleChange,
  options,
  placeholder,
  label,
  icon,
  errors,
}) => {
  return (
    <div className="d-flex gap-2 align-items-start">
      <div className="icons">{icon}</div>
      <div className="info w-100">
        <label>{label}</label>
        <select
          className={
            errors
              ? "form-select is-invalid rounded-0 form-select-lg  py-2 rounded-2 w-100"
              : "form-select rounded-0 form-select-lg  py-2 rounded-2 w-100"
          }
          aria-label=".form-select-lg example"
          value={value}
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          <option value="">{placeholder}</option>
          {options.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>

        {errors.length > 0 ? (
          <small className="text-danger mt-1">{errors}</small>
        ) : null}
      </div>
    </div>
  );
};

const AccomondationCard = ({ item, selected, handleFormValue, list }) => {
  const { name, icon } = item;

  const addaccomondationType = () => {
    const newfeatures = [...list];
    if (selected) {
      const index = newfeatures.indexOf(name);
      if (index > -1) {
        newfeatures.splice(index, 1);
      }
    } else {
      newfeatures.push(name);
    }
    handleFormValue("accomondationType", newfeatures);
  };
  return (
    <Box
      sx={{
        height: { xm: "100px", lg: "100px" },
        width: "200px",
      }}
      role="button"
      onClick={addaccomondationType}
      className={
        selected === name
          ? "d-flex p-2 grid-box-half justify-content-center flex-column border border-2 border-dark rounded rounded-3 text-start position-relative"
          : "d-flex p-2 grid-box-half justify-content-center flex-column border border-2 rounded rounded-3 text-start position-relative"
      }
    >
      <div className="">{icon}</div>
      <p>{name}</p>

      <CheckCircleOutlineIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};

const ToggleCard = ({
  handleFormValue,
  name,
  value,
  label,
  icon,
  selected,
}) => {
  return (
    <div
      className={
        selected
          ? " border border-2 border-dark text-black d-flex p-3 w-100 rounded rounded-3 justify-content-between"
          : " border border-2 d-flex p-3 w-100 rounded rounded-3 justify-content-between"
      }
      role="button"
      onClick={() => handleFormValue(name, value)}
    >
      <span className={selected ? "fw-bold text-black" : ""}>{label}</span>
      <div className="">{icon}</div>
    </div>
  );
};
const AccomendationTypes = ({
  values,
  handleFormValue,
  handleLocation,
  errors,
}) => {
  const propertyDetails = {
    id: "1",
    value: values.typeOfProperty,
    options: ["appartment", "warehouse", "cottage"],
    label: "Type of property",
    icon: (
      <HomeWorkOutlinedIcon
        style={{
          fontSize: "2.5em",
        }}
      />
    ),
    name: "typeOfProperty",
    placeholder: "Select Property Type",
  };

  return (
    <Box sx={{ width: { sm: "95%", lg: "90%" } }} className="mt-3 p-0 m-0">
      <div>
        <h4 className="text-black fw-bold p-0 m-0">Accomondation type</h4>
        <small>Select the type of accomondation you want</small>
      </div>

      <div className="d-flex flex-wrap gap-2">
        {accomondationTypes.map((item, index) => {
          const selected = values.accomondationType.some(
            (name) => name === item.name
          );
          return (
            <AccomondationCard
              key={item.id}
              item={item}
              selected={selected}
              handleFormValue={handleFormValue}
              list={values.accomondationType}
            />
          );
        })}
      </div>
      {errors.accomondationType.length > 0 ? (
        <small className="text-danger mt-1">{errors.accomondationType}</small>
      ) : null}

      <hr className="mt-3 mb-3" />

      <div className="mb-2">
        <h4 className="text-danger fw-bold p-0 m-0">
          Team up <PeopleAltOutlinedIcon />{" "}
        </h4>
        <small>
          Shared accomondation is cheaper. Select if you are interested in
        </small>
      </div>

      <div className="d-flex flex-column flex-lg-row gap-3  w-100">
        <ToggleCard
          label={"I am interested in teaming Up"}
          handleFormValue={handleFormValue}
          name={"teamUp"}
          value={true}
          icon={<PeopleAltOutlinedIcon />}
          selected={values.teamUp === true ? true : false}
        />

        <ToggleCard
          label={"Not Interested "}
          handleFormValue={handleFormValue}
          name={"noTeamUp"}
          value={false}
          icon={<RemoveCircleOutlinedIcon />}
          selected={values.teamUp === false ? true : false}
        />
      </div>
      {errors.teamUp.length > 0 ? (
        <small className="text-danger mt-1">{errors.teamUp}</small>
      ) : null}

      {values.teamUp === true ? (
        <div className=" show">
          <p className="fs-6  mt-3  mb-1">
            As you are interested in teaming up
          </p>
          <div className="d-flex flex-column flex-lg-row gap-3">
            <ToggleCard
              label={"I have a Property"}
              handleFormValue={handleFormValue}
              name={"haveProperty"}
              value={true}
              icon={<HouseOutlined />}
              selected={values.haveProperty === true ? true : false}
            />
            <ToggleCard
              label={"I don't have a property"}
              handleFormValue={handleFormValue}
              name={"noProperty"}
              value={false}
              icon={<RemoveCircleOutlinedIcon />}
              selected={values.haveProperty === false ? true : false}
            />
          </div>
        </div>
      ) : null}

      {errors.haveProperty.length > 0 ? (
        <small className="text-danger mt-1">{errors.haveProperty}</small>
      ) : null}

      {values.haveProperty === true ? (
        <div className="">
          <p className="fs-6 fw-bold  mt-3  mb-1 text-black">
            As you have property
          </p>
          <div className="row-cols-1 row-cols-lg-2 row mt-3">
            <div className="">
              <AccomondationSelectCard
                {...propertyDetails}
                handleChange={handleFormValue}
                errors={errors.typeOfProperty}
              />

              <div className="d-flex gap-2 align-items-start">
                <div className="icons">
                  <GrCapacity
                    style={{
                      fontSize: "2.5em",
                    }}
                  />
                </div>
                <div className="info w-100 mt-2">
                  <label>Capacity of property</label>
                  <select
                    className={
                      errors.typeOfProperty.length > 0
                        ? "form-select rounded-0 form-select-lg is-invalid  py-2 rounded-2 w-100"
                        : "form-select rounded-0 form-select-lg  py-2 rounded-2 w-100"
                    }
                    aria-label=".form-select-lg example"
                    value={values.propertyCapacity}
                    name={"propertyCapacity"}
                    onChange={(e) =>
                      handleFormValue(e.target.name, e.target.value)
                    }
                  >
                    <option value="">{"Select Max Property Capacity"}</option>
                    {[...Array(10).keys()].map((item, index) => {
                      return (
                        <option value={item} key={index}>
                          Max {item} Others
                        </option>
                      );
                    })}
                  </select>
                  {errors.propertyCapacity.length > 0 ? (
                    <small className="text-danger mt-1">
                      {errors.propertyCapacity}
                    </small>
                  ) : null}
                </div>
              </div>
            </div>
            <div className=" mt-2">
              <div className="d-flex gap-2 mb-2">
                <LocationOnIcon />
                <span>
                  {values.propertyLocation?.place_name?.length > 0
                    ? values.propertyLocation?.place_name
                    : "Select property location"}
                </span>
              </div>
              <AddLocation height={200} handleLocation={handleLocation} />

              {errors.propertyLocation.length > 0 ? (
                <small className="text-danger mt-1">
                  {errors.propertyLocation}
                </small>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

export default AccomendationTypes;
