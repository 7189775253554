import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import { Box } from "@mui/material";
import allboardmember from "../../../../../utils/all_boardMember";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const formatter = new Intl.DateTimeFormat(undefined);

export default function LstOfBoardMembers() {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.2,
        },
      }}
    >
      {allboardmember.map((item, index) => {
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="textSecondary">
              <div className="d-flex flex-column">
                {index === 0 ? (
                  <span className="fw-bold fs-6">Active Broad Members</span>
                ) : null}

                {index === 1 ? (
                  <span className="fw-bold fs-6">Historical Data</span>
                ) : null}
                <span>{formatter.format(item.time)}</span>
              </div>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div className="row-cols-2 row">
                {item.boardMembers.map((item, index) => {
                  return (
                    <div className="p-2 rounded rounded-2" key={index}>
                      <Box
                        key={index}
                        className=" rounded rounded-2 d-flex align-items-center gap-3 bg-hover col p-3 justify-content-between"
                      >
                        <img
                          src={item.img}
                          alt=""
                          srcSet=""
                          height={70}
                          width={70}
                          className="rounded-circle object-cover"
                        />
                        <div className="">
                          <p className="fw-blod fs-4">{item.name}</p>
                          <small>{item.position}</small>
                        </div>
                      </Box>
                    </div>
                  );
                })}
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
}
