import { Box } from "@mui/material";
import React from "react";
import BookmarkOrganizationCard from "../cards/BookmarkOrganizationCard";

const OrganizationBookmarks = ({ item, removeorg }) => {
  return (
    <Box
      sx={{
        minHeight: "90vh",
      }}
      className=" container  "
    >
      {item.length === 0 ? (
        <span className=" text-nowrap">No Organization bookmarks</span>
      ) : null}
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4">
        {item.map((org) => {
          return (
            <div className="gap-2 mb-3" key={org.id}>
              <BookmarkOrganizationCard item={org} />
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default OrganizationBookmarks;
