import { Box, Input, TextField } from "@mui/material";
import React from "react";
import { AvailableDate, DurationSelect } from "../../../../components/rental";
import {
  accomondationTypes,
  featurelst,
  lookingIconForLst,
} from "../../../../utils/flateMateSettings";
import { mduration } from "../../../../utils/rentSettings";
import MultipleCheckInput from "../../components/MultipleCheckInput";
import MultipleLocationInput from "../../components/MultipleLocationInput";
import MultipleRadioInput from "../../components/MultipleRadioInput";
import { useLocation, useNavigate } from "react-router-dom";

const FlateMateFilterModal = ({ onPageTabChange, closeModal, setisload }) => {
  const formvalues = {
    accomondationTypes: [],
    features: [],
    lookingFor: "AnyOne",
    minAge: 18,
    maxAge: 100,
    lengthOfStay: "",
    moveInDate: "",
    locations: [],
    minPrice: "",
    maxPrice: "",
  };
  const [search, handleSearch] = React.useState("");

  const [flateMateValues, setFlateMateValues] = React.useState(formvalues);

  const locationv = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(locationv.search);
  React.useEffect(() => {
    if (queryParams.get("type") === "FlateMates") {
      const location = queryParams.getAll("location") || [];

      setFlateMateValues({
        ...flateMateValues,
        locations: location,
      });
    }
  }, [queryParams]);
  const clearall = () => {
    setFlateMateValues(formvalues);
  };
  //filter
  const onFilter = () => {
    const queryParams = new URLSearchParams();

    queryParams.delete("accessTo");
    queryParams.delete("minimumStay");
    queryParams.delete("availableDate");
    queryParams.delete("minPrice");
    queryParams.delete("maxPrice");
    queryParams.delete("location");

    queryParams.set("type", "FlateMates");
    queryParams.set(
      "faccomondationTypes",
      flateMateValues.accomondationTypes.join(",")
    );
    queryParams.set("fminPrice", flateMateValues.minPrice);
    queryParams.set("fmaxPrice", flateMateValues.maxPrice);
    queryParams.set("flookingFor", flateMateValues.lookingFor);
    queryParams.set("flengthOfStay", flateMateValues.lengthOfStay);
    queryParams.set("fmoveInDate", flateMateValues.moveInDate);
    queryParams.set("fminAge", flateMateValues.minAge);
    queryParams.set("fmaxAge", flateMateValues.maxAge);
    queryParams.set("flocation", flateMateValues.locations.join("+"));
    navigate(`/rental/filter/?${queryParams.toString()}`);
    closeModal();
  };

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "minPrice":
        const onlyNumbers = /^[0-9]*$/;
        if (onlyNumbers.test(value)) {
          setFlateMateValues({ ...flateMateValues, [name]: value });
        }

        break;

      case "maxPrice":
        const onlyNumbersmax = /^[0-9]*$/;
        if (onlyNumbersmax.test(value)) {
          setFlateMateValues({ ...flateMateValues, [name]: value });
        }

        break;
      case "accomondationTypes":
        setFlateMateValues({ ...flateMateValues, accomondationTypes: value });

        break;
      case "minAge":
        const intvalue = parseInt(value);
        setFlateMateValues({
          ...flateMateValues,
          minAge: intvalue,
        });

        break;
      case "maxAge":
        const intmaxvalue = parseInt(value);
        setFlateMateValues({
          ...flateMateValues,
          maxAge: intmaxvalue,
        });

        break;
      case "lookingFor":
        setFlateMateValues({ ...flateMateValues, lookingFor: value });

        break;
      case "budgetPerWeek":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setFlateMateValues({ ...flateMateValues, budgetPerWeek: value });
        }
        break;

      case "addbudgetPerWeek":
        if (value === "") {
          setFlateMateValues({ ...flateMateValues, budgetPerWeek: 1 });
        }

        if (value <= 100000000) {
          const addedbudgetPerWeek = parseInt(value) + 1;
          setFlateMateValues({
            ...flateMateValues,
            budgetPerWeek: addedbudgetPerWeek,
          });
        }
        break;

      case "location":
        const newlst = [...flateMateValues.locations];
        newlst.push(value);
        setFlateMateValues({ ...flateMateValues, locations: newlst });
        break;

      case "removelocation":
        const updatedlst = [...flateMateValues.locations];
        updatedlst.splice(value, 1);

        setFlateMateValues({ ...flateMateValues, locations: updatedlst });
        break;

      case "minusbudgetPerWeek":
        if (value === "") {
          setFlateMateValues({ ...flateMateValues, budgetPerWeek: 0 });
        }
        if (value > 0) {
          const minusbudgetPerWeek = parseInt(value) - 1;
          setFlateMateValues({
            ...flateMateValues,
            budgetPerWeek: minusbudgetPerWeek,
          });
        }
        break;

      case "haveProperty":
        if (value === "Any") {
          setFlateMateValues({
            ...flateMateValues,
            haveProperty: "",
            typeofProperties: [],
            propertyCapacity: "",
            propertyLocation: "",
          });
        }
        if (value === "Don't have property") {
          setFlateMateValues({
            ...flateMateValues,
            haveProperty: false,
            typeofProperties: [],
            propertyCapacity: "",
            propertyLocation: "",
          });
        }
        if (value === "Have property") {
          setFlateMateValues({ ...flateMateValues, haveProperty: true });
        }
        break;

      case "noProperty":
        setFlateMateValues({
          ...flateMateValues,
          haveProperty: false,
          typeOfProperty: "",
          propertyCapacity: "",
          propertyLocation: "",
        });
        break;

      default:
        setFlateMateValues({ ...flateMateValues, [name]: value });
    }
  };
  return (
    <Box
      sx={{
        p: { xs: 2, md: 3 },
      }}
      className="d-flex flex-column gap-2"
    >
      <Box className="">
        <p className="fw-bold fs-6  text-dark mb-2">
          Preferred location - provenince, city, suburb
        </p>

        <MultipleLocationInput
          handleLocation={handleFormValue}
          placeholder={"Start with POSTAL CODE | M4B 2J8"}
          isBorder={false}
          locationlst={flateMateValues.locations}
        />
      </Box>
      <hr />

      <div className=" ">
        <p className="fw-bold fs-6  text-dark mb-2">Limit your budget</p>

        <div className="d-flex gap-2">
          <div className="d-flex align-items-center gap-2">
            <span>$</span>
            <Input
              placeholder="Min price"
              type="number"
              value={flateMateValues.minPrice}
              onChange={(e) => handleFormValue("minPrice", e.target.value)}
            />
          </div>
          <div className="d-flex align-items-center gap-1">
            <span>$</span>
            <Input
              placeholder="Max price"
              type="number"
              value={flateMateValues.maxPrice}
              onChange={(e) => handleFormValue("maxPrice", e.target.value)}
            />
          </div>
        </div>
      </div>
      <hr />
      <section>
        <p className="fw-bold fs-6  text-dark mb-2">Flatmate Preferences</p>

        <div className="row row-cols-2 gx-2 gy-1">
          <MultipleRadioInput
            item={"AnyOne"}
            selected={flateMateValues.lookingFor}
            name={"lookingFor"}
            index={"0110"}
            handleFormValue={handleFormValue}
          />
          {lookingIconForLst.map((item, index) => {
            return (
              <MultipleRadioInput
                item={item.name}
                selected={flateMateValues.lookingFor}
                name={"lookingFor"}
                index={index}
                key={index}
                handleFormValue={handleFormValue}
              />
            );
          })}
        </div>
      </section>
      <hr />
      <section>
        <p className="fw-bold fs-6 text-dark mb-2">Accomondation Types</p>
        <div className="row row-cols-2 gx-2 gy-2">
          {accomondationTypes.map((item, index) => {
            const selected = flateMateValues.accomondationTypes.some(
              (name) => name === item.name
            );
            return (
              <div className="" key={item.id}>
                <MultipleCheckInput
                  index={index}
                  list={flateMateValues.accomondationTypes}
                  item={item.name}
                  selected={selected}
                  handleFormValue={handleFormValue}
                  name={"accomondationTypes"}
                />
              </div>
            );
          })}
        </div>
      </section>
      <hr />
      <section>
        <p className="fw-bold fs-6 text-dark mb-2">Person preferences</p>
        <div className="row row-cols-2 gx-2 gy-2">
          {featurelst.map((item, index) => {
            const selected = flateMateValues.features.some(
              (name) => name === item.name
            );
            return (
              <div className="" key={item.id}>
                <MultipleCheckInput
                  index={index}
                  list={flateMateValues.features}
                  item={item.name}
                  selected={selected}
                  handleFormValue={handleFormValue}
                  name={"features"}
                />
              </div>
            );
          })}
        </div>
      </section>
      <hr />
      <section>
        <p className="fw-bold fs-6 text-dark mb-2">Age Preference</p>

        <div className="d-flex gap-2">
          <TextField
            type="number"
            placeholder="Min Age"
            name="minAge"
            value={flateMateValues.minAge}
            onChange={(e) => handleFormValue("minAge", e.target.value)}
          />
          <TextField
            onChange={(e) => handleFormValue("maxAge", e.target.value)}
            type="number"
            name="maxAge"
            placeholder="Max Age"
            value={flateMateValues.maxAge}
          />
        </div>
      </section>
      <hr />
      <section>
        <p className="fw-bold fs-6 text-dark mb-2">Other filtering</p>
        <div className="d-flex align-items-center gap-2">
          <div className="form-group w-100">
            <label htmlFor="Length of Stay">Length of Stay</label>
            <DurationSelect
              minimumStay={flateMateValues.lengthOfStay}
              handleFormValue={handleFormValue}
              name={"lengthOfStay"}
              durations={mduration}
              errors={false}
              placeholder={"Select Minimum Stay"}
            />
          </div>
          <div className="form-group w-100">
            <label htmlFor="availableDate">Move In Date</label>
            <AvailableDate
              availabeDate={flateMateValues.moveInDate}
              handleFormValue={handleFormValue}
              errors={false}
              name={"moveInDate"}
            />
          </div>
        </div>
      </section>

      <hr />
      <div className="d-flex justify-content-between position-sticky bg-white py-2 bottom-0 end-0">
        <span
          role="button"
          className=" text-decoration-underline text-danger"
          onClick={clearall}
        >
          Clear
        </span>
        <button className="btn bg-black text-white " onClick={onFilter}>
          Filter
        </button>
      </div>
    </Box>
  );
};

export default FlateMateFilterModal;
