import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
const StudentCard = ({ country }) => {
  const navigate = useNavigate();
  return (
    <Link to={`/abroad/${country.id}`}>
      <Box
        sx={{
          "&:hover  .icon": {
            display: "flex",
          },
        }}
        role="button"
        className="position-relative w-100 h-100 m-0 rounded rounded-4 overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${country?.coverpage}`}
          className="w-100 h-100 object-cover"
          alt=""
        />

        <Box
          sx={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.55) 60.57%)",
          }}
          className="w-100 h-50 position-absolute bottom-0 start-0 "
        ></Box>

        <Box
          className="position-absolute w-100 d-flex justify-content-between px-2  align-items-center"
          sx={{
            bottom: "3%",
            left: 0,
            lineHeight: "1.5em",
          }}
        >
          <div className="d-flex flex-column gap-0 icon-box">
            <Typography
              variant="p"
              className="fw-bold text-white m-0 p-0 mb-3"
              color="initial"
              sx={{
                fontSize: { xs: "clamp(1vw, 1em + 0.5vw,2.8vw )" },
              }}
            >
              {country?.country}
            </Typography>
          </div>

          <ArrowCircleRightOutlinedIcon
            className="icon"
            sx={{
              color: "var(--secondary-color)",
              fontSize: "2rem",
              display: "none",
            }}
          />
        </Box>
      </Box>
    </Link>
  );
};

export default StudentCard;
