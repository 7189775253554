import React from "react";
import { Box, TextField } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import positionlist from "../../../../../utils/all_position";
const UserBoardMembers = ({
  value,

  onmemberchange,
}) => {
  // image edit option
  const options = {
    isedit: false,
    preview: null,

    file: "",
  };

  // modal style

  // member state when edit / add
  const member = {
    isedit: false,
    name: "",
    image: null,
    position: "",
  };

  // stores image state
  const [option, setoptions] = React.useState(options);

  // stores Add/Edit Modal  state
  const [open, setOpen] = React.useState(false);

  // stores forminput state of Modal
  const [newmember, setnewmember] = React.useState(member);

  // stores index of member to edit
  const [editindex, setindex] = React.useState(0);

  // stores list of blob images to preview
  const [imglst, setimglst] = React.useState([]);

  // on image upload or edit
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      setoptions({
        ...option,
        file: e.target.files[0],
        isedit: true,
        preview: blobURL,
      });

      setnewmember({ ...newmember, image: file });
    }
  };

  // open modal
  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);
  };

  // close modal
  const handleClose = () => {
    setnewmember(member);
    setoptions(options);

    setOpen(false);
  };

  // handle input change of edit/add modal
  const handlemember = (e) => {
    setnewmember({ ...newmember, [e.target.name]: e.target.value });
  };

  // handles add or edit members
  const addmember = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isedit = newmember.isedit;

    const newlist = value.members;

    const newboardmember = newmember;

    // delete key from object
    delete newboardmember["isedit"];

    switch (isedit) {
      case false:
        if (newmember.name && newmember.image && newmember.position) {
          newlist.push(newboardmember);
          onmemberchange(newlist);
          const uploadimg = imglst;
          uploadimg.push(option.preview);
          setimglst(uploadimg);

          handleClose();
          setoptions(options);
        }
        break;

      case true:
        if (newmember.name && newmember.image && newmember.position) {
          newlist[editindex] = newboardmember;
          const uploadimg = imglst;
          uploadimg[editindex] = option.preview;
          setimglst(uploadimg);

          onmemberchange(newlist);
          handleClose();
        }

        break;

      default:
    }
  };

  //  remove member
  const deletemember = (index) => {
    const newlst = value.members;
    const newimglst = imglst;

    imglst.splice(index, 1);
    newlst.splice(index, 1);
    setimglst(newimglst);
    onmemberchange(newlst);
  };

  // open edit modal
  const handleEditOpen = (index, item) => {
    setnewmember({
      isedit: true,
      name: item.name,
      position: item.position,
    });

    setoptions({ ...option, preview: imglst[index] });
    setindex(index);
    handleOpen();
  };

  return (
    <section className="w-100">
      <div className="d-flex  mt-3 justify-content-between w-100 ">
        <span className="fs-2 fw-bolder ">
          Board Members &nbsp; ({value.members.length})
        </span>
        <button className="btn btn-danger ms-auto " onClick={handleOpen}>
          Add
        </button>
      </div>

      <div className="w-100">
        <div
          action=""
          className={
            open
              ? "d-flex  align-items-center flex-column bg-hover w-100"
              : "d-none"
          }
          onSubmit={addmember}
        >
          <div className="w-100 d-flex justify-content-center">
            <h2 className="  ">
              {newmember.isedit ? "Edit" : "Add"} Board Memebers
            </h2>
          </div>
          <div className="d-flex bg-hover p-2 border justify-content-start border-0  align-items-center">
            <div className="">
              <div className="profilepic text-center m-auto position-relative ">
                <Box
                  className="profilepic text-center m-auto position-relative  "
                  sx={{
                    height: "calc(50px + 7vw)",
                    width: "calc(50px + 7vw)",
                    maxHeight: "180px",
                    maxWidth: "180px",
                    "&:hover > #child-element ": {
                      visibility: "visible",
                      height: "50%",
                    },
                    borderRadius: "50%",

                    overflow: "hidden",
                  }}
                >
                  <img
                    alt="Remy Sharp"
                    src={
                      option.preview ??
                      "https://media.istockphoto.com/id/1451587807/vector/user-profile-icon-vector-avatar-or-person-icon-profile-picture-portrait-symbol-vector.jpg?s=612x612&w=0&k=20&c=yDJ4ITX1cHMh25Lt1vI1zBn2cAKKAlByHBvPJ8gEiIg="
                    }
                    style={{
                      height: "calc(50px + 7vw)",
                      width: "calc(50px + 7vw)",
                      maxHeight: "180px",
                      maxWidth: "180px",
                      margin: "auto",
                      cursor: "pointer",
                      borderRadius: "50%",

                      border: "10px solid #F1F1F1",
                    }}
                    className="object-cover object-center"
                  />

                  <Box
                    className="bg-black hover-section w-100 text-white d-flex  align-items-center justify-content-center fw-bold bg-opacity-50 position-absolute bottom-0  start-0  w-100"
                    sx={{
                      borderBottomLeftRadius: "300px",
                      borderBottomRightRadius: "300px",
                      visibility: "hidden",
                      cursor: "pointer",
                      height: "0",
                      transition: "height 1s ",
                    }}
                    id="child-element"
                  >
                    <label
                      htmlFor="uploadphoto"
                      className="h-100 w-100 d-flex align-items-center justify-content-center  "
                      role="button"
                    >
                      Edit
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      className="d-none"
                      id="uploadphoto"
                      onChange={onEdit}
                    />
                  </Box>
                </Box>
              </div>
            </div>
            <div className="d-flex flex-column gap-3 w-100">
              <TextField
                label="Name"
                name="name"
                id="standard-size-normal"
                variant="standard"
                className="w-100"
                type="String"
                placeholder="Enter Name"
                onChange={handlemember}
              />

              <TextField
                id="standard-select-currency-native"
                select
                label="Position"
                defaultValue=""
                SelectProps={{
                  native: true,
                }}
                helperText=""
                variant="standard"
                className="w-100 "
                onChange={handlemember}
                name="position"
              >
                <option value="">Select Position</option>
                {positionlist.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <button className="btn btn-dark mt-3 w-75" onClick={addmember}>
            submit
          </button>
        </div>
      </div>

      <Box
        sx={{
          minHeight: "20vh",
        }}
        className="row row-cols-lg-4 row-cols-1 row-cols-sm-2 row-cols-md-3  g-3"
      >
        {value.members.map((item, index) => {
          return (
            <div className="p-2" key={index}>
              <Box
                key={index}
                className="d-flex align-items-center gap-3 bg-hover col p-3 justify-content-between"
                sx={{
                  lineHeight: "3px",
                }}
              >
                <img
                  src={imglst[index]}
                  alt=""
                  srcSet=""
                  height={70}
                  width={70}
                  className="rounded-circle object-cover"
                />
                <div className="">
                  <p className="fw-blod fs-4">{item.name}</p>
                  <small>{item.position}</small>
                </div>
                <div className="dropdown">
                  <MoreHorizIcon
                    className="dropdown-toggle"
                    role="button"
                    id={`dropdownMenuLink${index}`}
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul
                    className="dropdown-menu"
                    aria-labelledby={`dropdownMenuLink${index}`}
                  >
                    <li
                      className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                      onClick={() => handleEditOpen(index, item)}
                    >
                      <EditIcon className=" " />
                      <span className="text-center col">Edit</span>
                    </li>

                    <li
                      className=" p-2 d-flex text-start justify-content-center  align-items-center dropdown-item"
                      onClick={() => deletemember(index)}
                    >
                      <DeleteIcon className=" " />
                      <span className="text-center col">Delete</span>
                    </li>
                  </ul>
                </div>
              </Box>
            </div>
          );
        })}
      </Box>

      {/* Add and Edit Modal */}
    </section>
  );
};

export default UserBoardMembers;
