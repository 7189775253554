import React from "react";
import { Button, CircularProgress } from "@mui/material";

const SubmitButton = ({ name, isLoading }) => {
  return (
    <Button
      className="btn-dark"
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      // disabled={isLoading}
    >
      {name}
     
      
       
    </Button>
  );
};

export default SubmitButton;
