import ProtectApi from "./adminAuthorizeApi";

export const createNotification = (data) => 
    ProtectApi.post("/api/admin/notification/create_notification", data);

export const getAllNotification = (keyword = "", page = 0) =>
    ProtectApi.get(`/api/admin/notification/get_all_notifications/?keyword=${keyword}&page=${page}`);



