import React from "react";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material/styles";

import {
  FlateMateInfo,
  PreferedLocation,
  AboutYourSelf,
  YourBudget,
  PropertyPreferences,
  AccomendationTypes,
  FlateMateReview,
  LookingFor,
} from "./formstepps";

import { createFlatemateAction } from "../../context/actions/rentalAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../context/reducers/rentalSlice";

const AddFlateMate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const formvalues = {
    firstName: "",
    lastName: "",
    contact: "",
    gender: "",
    occupation: "",
    email: "",
    preferedLocations: [],
    features: [],
    description: "",
    budgetPerWeek: 0,
    lengthOfStay: "6 months",
    moveInDate: "",
    roomFurnishings: "Not Required",
    internet: "Not Required",
    bathRoomType: "Flexible",
    parking: "Flexible",
    totalFlateMates: 1,
    accomondationType: [],
    teamUp: "",
    haveProperty: "",
    typeOfProperty: "",
    propertyCapacity: "",
    propertyLocation: "",
    age: "",
    userPic: "",
    lookingFor: "",
  };
  const formerrors = {
    firstName: "",
    lastName: "",
    contact: "",
    gender: "",
    occupation: "",
    email: "",
    preferedLocations: "",
    features: "",
    description: "",
    budgetPerWeek: "",
    lengthOfStay: "",
    moveInDate: "",
    roomFurnishings: "",
    internet: "",
    bathRoomType: "",
    parking: "",
    totalFlateMates: "",
    accomondationType: "",
    teamUp: "",
    haveProperty: "",
    typeOfProperty: "",
    propertyCapacity: "",
    propertyLocation: "",
    age: "",
    userPic: "",
    userPicFile: "",
    lookingFor: "",
  };

  // get the error uisng useSlector from rental_reducer

const { createFlatemateLoading, error } = useSelector((state) => ({
  createFlatemateLoading: state.rental_reducer.createFlatemateLoading,
  error: state.rental_reducer.error,
}));

  // if error occurs toast is shown
  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError())
  }, [error]);


  // activesteo indcates current step of the form ( 0 - 6)
  const [activeStep, setActiveStep] = React.useState(0);

  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formvalues);

  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);

  // change value base on e.target.name
  const handleFormChange = (e) => {
    if (e.target.name === "userPic") {
      const file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      e.target.value = "";

      setformvalues({ ...formvalue, userPic: blobURL, userPicFile: file });
    } else {
      setformvalues({ ...formvalue, [e.target.name]: e.target.value });
    }
  };
  // handles PhoneNumber
  const handlePhoneNumber = (phone) => [
    setformvalues({ ...formvalues, contact: phone }),
  ];
  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "budgetPerWeek":
        const isNumber = /^\d+$/.test(value);
        if (isNumber || value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: value });
        }
        break;

      case "addbudgetPerWeek":
        if (value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: 1 });
        }

        if (value <= 100000000) {
          const addedbudgetPerWeek = parseInt(value) + 1;
          setformvalues({ ...formvalue, budgetPerWeek: addedbudgetPerWeek });
        }
        break;

      case "minusbudgetPerWeek":
        if (value === "") {
          setformvalues({ ...formvalue, budgetPerWeek: 0 });
        }
        if (value > 0) {
          const minusbudgetPerWeek = parseInt(value) - 1;
          setformvalues({ ...formvalue, budgetPerWeek: minusbudgetPerWeek });
        }
        break;

      case "teamUp":
        setformvalues({ ...formvalue, teamUp: true, haveProperty: "" });

        break;

      case "noTeamUp":
        setformvalues({
          ...formvalue,
          teamUp: false,
          haveProperty: false,
        });

        break;

      case "haveProperty":
        setformvalues({ ...formvalue, haveProperty: true });
        break;

      case "noProperty":
        setformvalues({
          ...formvalue,
          haveProperty: false,
          typeOfProperty: "",
          propertyCapacity: "",
          propertyLocation: "",
        });
        break;

      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

  // form validation returns true or false when next button clicked
  const formvalidation = () => {
    let isValid = false;

    const newerror = { ...formerrors };
    if (activeStep === 0) {
      if (!formvalue.lookingFor) {
        newerror.lookingFor = "Please select what are you looking for *";
      } else {
        isValid = true;
      }
    }
    if (activeStep === 1) {
      const phoneRegex = /^\d{10}$/;
      const isphone = phoneRegex.test(formvalue.contact);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)?$/;

      const isValidEmail = emailRegex.test(formvalue.email);
      if (
        !formvalue.firstName ||
        !formvalue.lastName ||
        !formvalue.email ||
        !formvalue.contact ||
        !formvalue.occupation ||
        !formvalue.gender ||
        !formvalue.age ||
        !isphone ||
        !isValidEmail ||
        !formvalue.userPic
      ) {
        if (!formvalue.firstName) {
          newerror.firstName = "Please provide firstName *";
        }

        if (!formvalue.lastName) {
          newerror.lastName = "Please provide lastName *";
        }

        if (!formvalue.email) {
          newerror.email = "Please provide email *";
        }
        if (!formvalue.contact) {
          newerror.contact = "Please provide contact *";
        }
        if (!formvalue.occupation) {
          newerror.occupation = "Please provide occupation *";
        }
        if (!formvalue.gender) {
          newerror.gender = "Please provide gender *";
        }

        if (!formvalue.age) {
          newerror.age = "Please provide age *";
        }

        if (!formvalue.userPic) {
          newerror.userPic = "Please provide Your Image *";
        }

        if (!isphone && formvalue.contact) {
          newerror.contact = "please provide valid candian number *";
        }
        if (!isValidEmail && formvalue.email) {
          newerror.email = "please provide valid email";
        }
        newerror.message = "Invalid credential please check again *";
      } else {
        isValid = true;
      }
    }
    if (activeStep === 2) {
      if (formvalue.preferedLocations.length === 0) {
        newerror.message = "Please provide prefered location *";
      } else {
        isValid = true;
      }
    }

    if (activeStep === 3) {
      if (!formvalue.description || formvalue.features.length === 0) {
        if (!formvalue.description) {
          newerror.description = "please provide description *";
        }
        if (formvalue.features.length === 0) {
          newerror.features = "select your features ";
        }
        newerror.message = "Invalid credential please check again";
      } else {
        isValid = true;
      }
    }

    if (activeStep === 4) {
      if (
        formvalue.budgetPerWeek === 0 ||
        !formvalue.budgetPerWeek ||
        !formvalue.moveInDate
      ) {
        if (formvalue.budgetPerWeek === 0 || !formvalue.budgetPerWeek) {
          newerror.budgetPerWeek = "please provide your budget per week *";
        }
        if (!formvalue.moveInDate) {
          newerror.moveInDate = "please provide move in date *";
        }
        newerror.message = "Invalid credential please check again";
      } else {
        isValid = true;
      }
    }

    if (activeStep === 5) {
      isValid = true;
    }

    if (activeStep === 6) {
      if (
        formvalue.accomondationType.length === 0 ||
        formvalue.teamUp === "" ||
        (formvalue.teamUp && formvalue.haveProperty === "") ||
        (formvalue.haveProperty && !formvalue.typeOfProperty) ||
        (formvalue.haveProperty && !formvalue.propertyCapacity) ||
        (formvalue.haveProperty && !formvalue.propertyLocation)
      ) {
        if (formvalue.accomondationType.length === 0) {
          newerror.accomondationType =
            "Please select at leaset one accomondation type *";
        }
        if (formvalue.teamUp === "") {
          newerror.teamUp = "Please choose one of the options * ";
        }
        if (formvalue.teamUp && formvalue.haveProperty === "") {
          newerror.haveProperty =
            "Please choose property one of the options * ";
        }
        if (formvalue.haveProperty && !formvalue.typeOfProperty) {
          newerror.typeOfProperty = "Please provide property type *";
        }
        if (formvalue.haveProperty && !formvalue.propertyCapacity) {
          newerror.propertyCapacity = "Please provide property capacity *";
        }

        if (formvalue.haveProperty && !formvalue.propertyLocation) {
          newerror.propertyLocation = "Please provide property location *";
        }

        newerror.message = "Invalid credential please check again";
      } else {
        isValid = true;
      }
    }

    /// on submit

    if (activeStep === 7) {
      isValid = true;
    }
    setErrors(newerror);

    return isValid;
  };

  // on next clicked formvalidation  is checked according to current steps
  const handleNext = () => {
    const isValid = formvalidation();
    if (isValid) {
      if (activeStep < 7) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      if (activeStep === 7) {
        const data = new FormData();
        for (let i = 0; i < formvalue.preferedLocations.length; i++) {
          const { place_name } = formvalue.preferedLocations[i];
          data.append("preferedLocations", place_name);
        }
        for (let i = 0; i < formvalue.features.length; i++) {
          data.append("features", formvalue.features[i]);
        }
        for (let i = 0; i < formvalue.accomondationType.length; i++) {
          data.append("accomondationType", formvalue.accomondationType[i]);
        }
        data.append("firstName", formvalue.firstName);
        data.append("lastName", formvalue.lastName);
        data.append("email", formvalue.email);
        data.append("contact", formvalue.contact);
        data.append("occupation", formvalue.occupation);
        data.append("phone", formvalue.phone);
        data.append("description", formvalue.description);
        data.append("budgetPerWeek", formvalue.budgetPerWeek);
        data.append("lengthOfStay", formvalue.lengthOfStay);
        data.append("moveInDate", formvalue.moveInDate);
        data.append("roomFurnishings", formvalue.roomFurnishings);
        data.append("internet", formvalue.internet);
        data.append("bathRoomType", formvalue.bathRoomType);
        data.append("parking", formvalue.parking);
        data.append("totalFlateMates", formvalue.totalFlateMates);
        data.append("teamUp", formvalue.teamUp);
        data.append("haveProperty", formvalue.haveProperty);
        data.append("typeOfProperty", formvalue.typeOfProperty);
        data.append("propertyCapacity", formvalue.propertyCapacity);
        data.append("propertyLocation", formvalue.propertyLocation?.place_name);
        data.append("age", formvalue.age);
        data.append("gender", formvalue.gender);
        data.append("images", formvalue.userPicFile);
        data.append("lookingFor", formvalue.lookingFor);
        dispatch(createFlatemateAction({ data, toast, navigate }));
      }
    }
  };

  // on location set
  const handleLocation = (location) => {
    setformvalues((prevValue) => ({
      ...prevValue,
      propertyLocation: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        place_name: location.place_name,
      },
    }));
  };

  const removelocation = (index) => {
    setformvalues((prevFormValues) => ({
      ...prevFormValues,
      preferedLocations: prevFormValues.preferedLocations.filter(
        (_, i) => i !== index
      ),
    }));
    if (errors.preferedLocations) {
      setErrors({ ...errors, preferedLocations: "" });
    }
  };

  // on back button click
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className="container">
      <div>
        {/* form components are arraged with the help of mui tabs */}
        <TabContext value={activeStep.toString()}>
          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="0"
          >
            <LookingFor
              selected={formvalue.lookingFor}
              handleFormValue={handleFormValue}
              errors={errors.lookingFor}
            />
          </TabPanel>
          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="1"
          >
            <FlateMateInfo
              handlePhoneNumber={handlePhoneNumber}
              handleFormChange={handleFormChange}
              errors={errors}
              formvalue={formvalue}
            />
          </TabPanel>
          <TabPanel
            value="2"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <PreferedLocation
              preferedLocations={formvalue.preferedLocations}
              errors={errors}
              removelocation={removelocation}
              setformvalues={setformvalues}
              setErrors={setErrors}
            />
          </TabPanel>

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="3"
          >
            <AboutYourSelf
              errors={errors}
              handleFormValue={handleFormValue}
              values={formvalue}
            />
          </TabPanel>

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="4"
          >
            <YourBudget
              errors={errors}
              handleFormValue={handleFormValue}
              values={formvalue}
            />
          </TabPanel>

          <TabPanel
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
            value="5"
          >
            <PropertyPreferences
              values={formvalue}
              handleFormValue={handleFormValue}
            />
          </TabPanel>

          <TabPanel
            value="6"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <AccomendationTypes
              values={formvalue}
              handleFormValue={handleFormValue}
              handleLocation={handleLocation}
              errors={errors}
            />
          </TabPanel>

          <TabPanel
            value="7"
            sx={{
              minHeight: "74vh",
              padding: 0,
              margin: 0,
            }}
          >
            <FlateMateReview values={formvalue} />
          </TabPanel>
        </TabContext>
      </div>

      <progress
        value={activeStep}
        className="w-100 my-2 text-black"
        max={7}
      ></progress>
      <div className="d-flex w-100  align-items-center justify-content-between">
        <Button size="medium" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>

        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <Button
          size="large"
          className="btn-dark text-white text-capitalize"
          onClick={handleNext}
        >
          {activeStep === 7 ? "Publish" : "Next"}
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddFlateMate;
