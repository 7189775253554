import { createAsyncThunk } from "@reduxjs/toolkit";
import * as admin_api from "../../../api/admin/helpsupportApi";

// get all help support

export const adminGetAllHelpSupportAction = createAsyncThunk(
  "admin/get_all_help",
  async ({ keyword, page }, { rejectWithValue }) => {
    try {
      const response = await admin_api.getAllHelpSupport(keyword, page);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const adminChangeStatusHelpAndSupport = createAsyncThunk(
  "admin/change_status_event",
  async ({ id, data, toast, activeIndex, navigate }, { rejectWithValue }) => {
    try {
      const response = await admin_api.changeStatusHelpSupport(id, data);
      if (response.status === 200) {
        toast.success("Status updated successfully");
        navigate("/admin/helpandsupport");
        return { data: response.data, activeIndex: activeIndex };
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
