import { Box } from "@mui/material";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
const BlogSuggestion = React.memo(({ blogs }) => {
  const [bookmarked, setbookmarked] = React.useState(false);
  const navigate = useNavigate();

  const togglebookmark = () => {
    setbookmarked((prev) => !prev);
  };
  return (
    <div>
      <Link to={`/blogs/${blogs._id}`}>
      <Typography
        variant="p"
        color="black"
        sx={{
          fontSize: "clamp(0.8rem,1.3vw ,1.07rem)",
          fontWeight: "bold",
        }}

        hover
      >
        {blogs?.title}
      </Typography>
      </Link>

      <Box
        className="d-flex justify-content-between align-items-center w-100"
        sx={{
          fontSize: "clamp(0.7rem,1.3vw ,0.8rem)",
          color: "#000000",
        }}
      >
        <div className="d-flex align-items-center gap-1">
          <Chip label={`${blogs.category}`} size="small" variant="filled" />
          <span>7 min read</span>
        </div>
       
      </Box>
    </div>
  )

});

export default BlogSuggestion;
