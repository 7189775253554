import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";

import { Box, Drawer, MenuItem, Select } from "@mui/material";
import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";
import formatViewCount from "../../../../utils/formatViewCount";
import RentalEdit from "../../../RentalEdit/RentalEdit";
import UserRentalDetail from "../../../userrentaldetail/UserRentalDetail";
import { CancelOutlined, CheckOutlined } from "@mui/icons-material";

const DropdownCell = ({ options, onChange, openEdit, onEdit, id }) => {
  const [selectedOption, setSelectedOption] = React.useState("more");

  const handleOptionChange = (event) => {
    if (event.target.value === "edit") {
      //changes active
      onEdit(id);

      //opens modal
      openEdit();
    }
    // setSelectedOption(event.target.value);
    // onChange(event.target.value);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleOptionChange}
      sx={{
        "& .MuiSelect-select": {
          display: "flex",
          alignItems: "center",
        },
      }}
      IconComponent={null}
      variant="standard"
      disableUnderline
      className="p-0"
    >
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          className={selectedOption === option.value ? "d-none" : ""}
        >
          <Box
            className={
              selectedOption === option.value
                ? "p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
                : "d-flex justify-content-center align-items-center"
            }
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: selectedOption === option.value ? "" : "1.5rem",

              height: "40px",
              width: "40px",
            }}
          >
            {option.icon}
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
const RentalTable = ({ rental_data,loading }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);

  const [activeIndex, setActive] = React.useState(0);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const toggleDrawerDetail = () => setIsOpenDetail((prev) => !prev);

  const onEdit = (value) => setActive(value);
  const options = [
    {
      value: "more",
      label: "more ",
      icon: <FaEllipsisV />,
    },
    {
      value: "edit",
      label: "Edit",
      icon: <FaEdit />,
    },
    {
      value: "delete",
      label: "Delete",
      icon: <FaTrash />,
    },
  ];

  const columns = [
    {
      field: "image",
      headerName: "Related Image",
      flex: 1,
      renderCell: (params) => {
        const { image } = params.row;
        return (
          <div style={{ display: "flex" }}>
            {image?.slice(0, 3).map((item, index) => {
              return (
                <img
                  className="col-4 object-cover  rounded-5  "
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  key={index}
                />
              );
            })}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 2,
    },
    {
      field: "category",
      headerName: "category",
      flex: 1,
    },

    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 0.5,
    },
    {
      field: "views",
      headerName: "Views",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },

    {
      field: "Action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const { id } = params.row;
        return (
          <div className="d-flex align-items-center gap-2">
            <Box
              sx={{
                height: "40px",
                width: "40px",
              }}
              className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
            >
              <VisibilityIcon
                onClick={() => {
                  setActive(id);
                  toggleDrawerDetail();
                }}
                role="button"
              />
            </Box>
            <DropdownCell
              openEdit={toggleDrawer}
              value={params.value}
              options={options}
              onEdit={onEdit}
              id={id}
            />
          </div>
        );
      },
    },
    // Add other columns as needed
  ];

  const mappedRows = rental_data?.map((rental, index) => ({
    id: index,
    name: rental?.title,
    location: rental?.location?.place_name,
    category: rental?.houseType,
    price: rental?.price,
    image: rental?.images,
    views: formatViewCount(rental?.viewCount),
    active: rental?.active,
  }));

  return (
    <div className="">
      <Box sx={{ height: 500, width: 1 }}>
        <DataGrid rows={mappedRows} columns={columns} loading={loading} />
      </Box>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <RentalEdit
        id={rental_data[activeIndex]?._id}
        activeindex={activeIndex}
        data={rental_data[activeIndex]} />
      </Drawer>

      <Drawer anchor="right" open={isOpenDetail} onClose={toggleDrawerDetail}>
        <UserRentalDetail
          data={rental_data[activeIndex]}
          closeModal={toggleDrawerDetail}
          id={rental_data[activeIndex]?._id}
          activeindex={activeIndex}
        />
      </Drawer>
    </div>
  );
};

export default RentalTable;
