import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ProfessionalReview from "./ProfessionalReview";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 3,
  p: 4,
};

const SellAllReviewModal = ({ openSeeAllReview, handleCloseSeeAllReview}) => {

  return (
    <Modal
      open={openSeeAllReview}
      onClose={handleCloseSeeAllReview}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <p>
        <span className="fw-bold">5</span> out of 5 based on |{" "}
        <span
          className="fw-bold text-decoration-underline"
        >
          3 REVIEWS
        </span>
      </p>
      <hr className="mt-1 mb-1" />
      <p className="fw-bold">Showing all reviews</p>

      {/* comments */}
      <ProfessionalReview/>


    </Box>
    </Modal >
  );
};

export default SellAllReviewModal;
