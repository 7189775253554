import React, { useState } from 'react'

const TestPage = () => {
  const [tags, setTags] = useState([])
  const [inputValue, setInputValue] = useState('')
  const handleAddTag = () => {
    if(!inputValue) return
    setInputValue('')
    setTags([...tags, inputValue])
  }

  // handle remove
  const handleRemoveTag = (index) => { 
    const newTags = [...tags]
    newTags.splice(index, 1)
    setTags(newTags)
  }

  
  return (
    <div>
      <input type="text" className='form-control' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <button type='submit' className='btn' onClick={handleAddTag}>Add Tag</button>
      {
        tags.map((tag, index) => (
          <span key={index} class="badge text-bg-primary">
            {tag} <button
              onClick={handleRemoveTag.bind(null, index)}
            >X</button>
          </span>
        ))
      }
    </div>
  )
}

export default TestPage