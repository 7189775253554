import { createAsyncThunk } from "@reduxjs/toolkit";

import * as business_api from "../../api/business_api";

export const getAllBusinessAction = createAsyncThunk(
  "getAllBusiness",
  async (
    { keyword = "", page = 0, city = "", province = "",category="" },
    { rejectWithValue }
  ) => {
    try {
      const response = await business_api.get_all_business(
        keyword,
        page,
        city,
        province,
        category
      );
      
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all featured business
export const getFeaturedBusinessAction = createAsyncThunk(
  "getFeaturedBusiness",
  async (__, { rejectWithValue }) => {
    try {
      const response = await business_api.get_featured_business();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBusinessDetailsAction = createAsyncThunk(
  "getBusinessDetails",
  async (id, { rejectWithValue }) => {
    try {
      const response = await business_api.get_business_details(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const CreateBusinessAction = createAsyncThunk(
  "create_business",
  async ({ toast, formData,navigate }, { rejectWithValue }) => {
    try {
      const response = await business_api.create_business(formData);
      toast.success("Business Created Successfully");
      navigate("/profile");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// create business review
export const createBusinessReviewAction = createAsyncThunk(
  "create_business_review",
  async ({ review, id }, { rejectWithValue }) => {
    try {
      const response = await business_api.create_buiness_review(review, id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

