import React, { useState } from "react";
import { CountrySelect, FormInput } from "../../../../../components/Index";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import {toast } from 'react-toastify';
import  {useDispatch, useSelector} from 'react-redux';
import {adminRegisterUserAction} from "../../../../../context/actions/admin/userAction";
import { showtoast } from "../../../../../context/reducers/adminSlice";
import { clearError } from "../../../../../context/reducers/adminSlice";
import { useNavigate } from "react-router-dom";

const AdminUserAdd = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function generateStrongPassword(length) {
    var charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var symbols = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
    var password = "";
    // Add at least one symbol
    var symbolIndex = Math.floor(Math.random() * symbols.length);
    password += symbols[symbolIndex];

    // Add remaining characters
    for (var i = 0; i < length - 1; i++) {
      var randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }

    // Shuffle the password to make it more secure and memorable
    password = shuffleString(password);
    return password;
  }

  function shuffleString(string) {
    var shuffledString = "";
    string = string.split("");

    while (string.length) {
      var randomIndex = Math.floor(Math.random() * string.length);
      shuffledString += string.splice(randomIndex, 1);
    }

    return shuffledString;
  }

  // Example usage
  var Randompassword = generateStrongPassword(12);
  const [password, setPassword] = React.useState(Randompassword);

  const handleChange = (e) => {
    setPassword(e.target.value);
  };
  const validateForm = ({
    firstname,
    lastname,
    email,
    password,
    cpassword,
    selectedOptions,
  }) => {
    const errors = {};
    let formIsValid = true;

    if (!firstname) {
      formIsValid = false;
      errors.firstname = "First name is required *";
    }

    if (!lastname) {
      formIsValid = false;
      errors.lastname = "Last name is required *";
    }

    if (typeof email !== "undefined" && email !== null) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors.email = "Please enter valid email-ID *";
      }
    }

    if (!selectedOptions) {
      formIsValid = false;
      errors.country = "Country is required *";
    }

    if (typeof password !== "undefined" && password !== null) {
      if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
        formIsValid = false;
        errors.password = "Create a strong password *";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const [selectedOptions, setSelectedOptions] = useState("");
  const handleChanges2 = (event, value) => setSelectedOptions(value.label);

  const [errors, setErrors] = useState({});

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const firstname = data.get("firstname");
    const lastname = data.get("lastname");
    const email = data.get("email");
    const password = data.get("password");

    // change this to your own validation
    const isValid = validateForm({
      firstname,
      lastname,
      email,
      password,

      selectedOptions,
    });

    if (isValid) {
      const data = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        country: selectedOptions,
        password: password,
      };

      dispatch(adminRegisterUserAction({ data:data, toast:toast, navigate:navigate }));
    }
  };

    const { adminCreateUserLoading, error } = useSelector((state) => ({
      error: state.admin_reducer.error,
      adminCreateUserLoading: state.admin_reducer.adminCreateUserLoading,
    }));

    // use useCallback to memoize the function
    React.useEffect(() => {
      if(error){
        toast.error(error);
      }
      dispatch(clearError())
    }, [error]);

  return (
  <Container
      className="d-flex flex-column justify-content-center r"
      maxWidth="sm"
      sx={{
        minHeight: "90vh",
      }}
    >
      <h6>New Member</h6>
      <h6 className="fw-bold text-black">
        Registering New <strong className="text-danger">Users</strong>
      </h6>
      <hr />
      <div>
        <form onSubmit={onSubmit}>
          <div className="right-side">
            <Stack spacing={2} className="d-flex flex-column gap-2">
              <div className="d-flex gap-3 w-100">
                <FormInput
                  name={"firstname"}
                  label={"First name"}
                  type={"text"}
                  required={true}
                  error={errors.firstname ? true : false}
                  helperText={errors.firstname}
                  classname={"w-100 "}
                />
                <FormInput
                  name={"lastname"}
                  label={"Last name"}
                  type={"text"}
                  required={true}
                  error={errors.lastname ? true : false}
                  helperText={errors.lastname}
                  classname={"w-100"}
                />
              </div>
              <FormInput
                name={"email"}
                label={"Email Address"}
                type={"email"}
                required={true}
                error={errors.email ? true : false}
                helperText={errors.email}
              />

              <CountrySelect
                label={"Choose your country"}
                handleChange={handleChanges2}
                required={true}
                error={errors.country ? true : false}
                helperText={errors.country}
              />

              <FormInput
                name={"password"}
                label={"Create password"}
                required={true}
                type={"password"}
                error={errors.password ? true : false}
                helperText={errors.password}
                classname={"w-100"}
                value={password}
                handleChange={handleChange}
              />

              <Button

                className="btn-dark text-capitalize "
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Save and send Email
              </Button>
            </Stack>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default AdminUserAdd;
