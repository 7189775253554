import React from "react";
import { ImCross } from "react-icons/im";
import { Box, Typography } from "@mui/material";
import {
  MdAlternateEmail,
  MdEditSquare,
  MdOutlineBathroom,
  MdOutlineBedroomChild,
} from "react-icons/md";
import { FaUserAlt, FaWifi } from "react-icons/fa";
import { BiCalendar } from "react-icons/bi";
import { TbPigMoney } from "react-icons/tb";
import { BsCheckCircle, BsArrowUpSquare } from "react-icons/bs";

import Groups2Icon from "@mui/icons-material/Groups2";
import { RiParkingBoxLine } from "react-icons/ri";
import { AiOutlineGroup } from "react-icons/ai";

import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import Modal from "@mui/material/Modal";
import {
  PersonalInformation,
  BasicInformation,
  EditDescription,
  AccomondationType,
  PreferedLocations,
  Feature,
  PropertyPreference,
} from "./modals";
import { useDispatch } from "react-redux";
import {
  adminEditFlatemateAction,
  adminChangeFlatemateStatusAction,
  adminUpdateFeaturedFlatemateAction
} from "../../../../../context/actions/admin/flatemateAction";

import { toast } from "react-toastify";
import { accomondationTypes } from "../../../../../utils/flateMateSettings";
const AccomondationCard = ({ name }) => {
  const icon = accomondationTypes.find((item) => item.name === name)?.icon;

  return (
    <div className="d-flex h-maxcontent align-items-center px-2 gap-1 py-1 border border-2 rounded rounded-pill">
      <span>{icon}</span>
      <span>{name}</span>
    </div>
  );
};

const AdminFlateMateDetail = ({
  user_flatmate_data,
  activeindex,
  closeDrawer,
}) => {
  // const { user_flatmate_data, flatemate_loading } = useSelector(
  //   (state) => state.user_reducer
  // );
  console.log(user_flatmate_data);
  const [editImg, setEditImg] = React.useState({
    isEdit: false,
    pImg: "",
    file: "",
  });

  const dispatch = useDispatch();

  const handleActiveStatusFalse = (e) => {
    e.preventDefault();

    dispatch(
      adminChangeFlatemateStatusAction({
        id: user_flatmate_data?._id,
        data: false,
        toast: toast,
        activeindex: activeindex,
      })
    );
  };

  const handleActiveStatusTrue = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeFlatemateStatusAction({
        id: user_flatmate_data?._id,
        data: true,
        toast: toast,
        activeindex: activeindex,
      })
    );
  };

  const featuredTure = (e) => {
    e.preventDefault();
    dispatch(
      adminUpdateFeaturedFlatemateAction({
        id: user_flatmate_data?._id,
        data: true,
        toast,
        activeindex,
      })
    );
  };

  const featuredFalse = (e) => {
    e.preventDefault();
    dispatch(
      adminUpdateFeaturedFlatemateAction({
        id: user_flatmate_data?._id,
        data: false,
        toast,
        activeindex,
      })
    );
  };

  const handleUser_flatmate_data = (name, value) => {
    switch (name) {
      case "imgedit":
        let blobURL = URL.createObjectURL(value.target.files[0]);
        setEditImg({
          isEdit: true,
          pImg: blobURL,
          file: value.target.files[0],
        });
        value.target.value = "";

        break;

      case "userPic":
        const data = new FormData();
        data.append("images", editImg.file);
        dispatch(
          adminEditFlatemateAction({
            id: user_flatmate_data?._id,
            formData: data,
            activeindex: activeindex,
            toast: toast,
          })
        );
        setEditImg({ ...editImg, isEdit: false });
        break;

      case "cancelPic":
        setEditImg({
          ...editImg,
          isEdit: false,
          pImg: "",
          file: "",
        });

        break;

      default:
        setEditImg({ ...editImg, isEdit: false, pImg: "" });
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: "95vw", md: "70%", lg: "50%" },
    bgcolor: "white",
    borderRadius: "15px",

    boxShadow: 24,
    p: { xs: 2, md: 4 },
  };

  const modals = {
    personalInformation: false,
    basicInformation: false,
    editDescription: false,
    accomondationType: false,
    propertyPreference: false,
    feature: false,
    preferedLocations: false,
  };
  const [open, setOpen] = React.useState(modals);
  const handleModal = (name, value) => {
    setOpen({ ...open, [name]: value });
  };

  const [showMore, SetshowMore] = React.useState(false);
  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  const formattedTime = (time) => {
    const originalDate = new Date(time);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }).format(originalDate);
    return formattedDate;
  };

  return (
    <Box
      sx={{
        width: { lg: "70vw", xs: "100%", md: "80vw", sm: "90vw" },
      }}
      className="m-4"
    >
      <section className="rental-header">
        <div className="d-flex justify-content-between">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Personal FlateMate Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            role="button"
            onClick={closeDrawer}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
        <div className="d-flex w-100 justify-content-between mt-1">
          {user_flatmate_data?.featured ? (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-mdb-ripple-color="dark"
              onClick={featuredFalse}
            >
              unfeatured
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              data-mdb-ripple-color="dark"
              onClick={featuredTure}
            >
              featured
            </button>
          )}
          {user_flatmate_data?.active ? (
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusFalse}
            >
              Deactive
            </button>
          ) : (
            <button
              type="button"
              class="btn btn-sm btn-success"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusTrue}
            >
              Active
            </button>
          )}
        </div>
      </section>
      <hr />
      <section>
        <div className="d-flex flex-column flex-lg-row gap-5 w-100">
          <Box
            sx={{
              aspectRatio: "1/0.7",
              width: { xm: "100%", md: "80%", lg: "40%" },
            }}
            className=" rounded rounded-3 bg-black overflow-hidden position-relative"
          >
            <img
              src={
                editImg.pImg
                  ? editImg.pImg
                  : `${process.env.REACT_APP_IMAGE_PATH}${user_flatmate_data?.userPic}`
              }
              alt=""
              srcSet=""
              className="w-100 h-100 "
              style={{
                objectPosition: "center",
                objectFit: "contain",
              }}
            />

            <input
              type="file"
              name="userPic"
              id="userPic"
              accept="images/*"
              className="d-none"
              onChange={(e) => {
                handleUser_flatmate_data("imgedit", e);
              }}
            />
            {editImg.isEdit ? (
              <div className="row row-cols-2 gx-2 position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center">
                <button
                  className="btn bg-black text-white"
                  onClick={() => handleUser_flatmate_data("cancelPic", 0)}
                >
                  Cancel
                </button>
                <button
                  className="btn bg-success text-white"
                  onClick={() => handleUser_flatmate_data("userPic", 0)}
                >
                  Save
                </button>
              </div>
            ) : (
              <label
                htmlFor="userPic"
                className="position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center"
              >
                Edit Profile Picture
              </label>
            )}
          </Box>

          <Box className="w-100 fs-5 text-black d-flex flex-column gap-2 position-relative m-0 p-0">
            <Box
              className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
              role="button"
              sx={{
                top: "2%",
                right: "2%",
                width: "2.2rem",
                height: "2.2rem",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={() => handleModal("personalInformation", true)}
            >
              <MdEditSquare />
            </Box>
            <p className="fs-1 text-black fw-bold text-nowrap p-0 m-0">
              {user_flatmate_data?.firstName} {user_flatmate_data?.lastName}
            </p>
            <div className="d-flex gap-2 align-items-center">
              <h6 className="m-0 p-0 h-maxcontent">looking for</h6>
              <button className="btn btn-sm btn-outline-black">
                {user_flatmate_data?.lookingFor}
              </button>
            </div>
            <div className="d-flex align-items-center gap-1">
              <FaUserAlt />
              <span>{user_flatmate_data?.gender}</span>
              <span>-</span>
              <span className="text-nowrap">
                {user_flatmate_data?.age} years old
              </span>
            </div>

            <div className="d-flex align-items-center gap-1">
              <MdAlternateEmail />
              <span className="text-decoration-underline">
                {user_flatmate_data?.email}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <BiCalendar />
              <strong className="text-nowrap">Created At :</strong>
              <span className="text-nowrap">
                {formattedTime(user_flatmate_data?.createAt)}
              </span>
            </div>
          </Box>
        </div>
      </section>

      <hr />

      <section className="row row-cols-1 row-cols-lg-3 row-cols-md-2 position-relative m-0 p-0">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("basicInformation", true)}
        >
          <MdEditSquare
            sx={{
              fontSize: "1.5rem",
            }}
          />
        </Box>
        <div className="row row-cols-2 ">
          <TbPigMoney
            style={{
              fontSize: "3.5em",
              // color: "var(--primary-color)",
            }}
            className="col-2"
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Budget
            </Typography>
            <p>
              <strong>{user_flatmate_data?.price}</strong> per week
            </p>
          </div>
        </div>

        <div className="row row-cols-2 ">
          <BsCheckCircle
            style={{
              fontSize: "3.5em",
              // color: "var(--primary-color)",
            }}
            className="col-2"
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Length of stay
            </Typography>
            <h5>
              <strong>{user_flatmate_data?.lengthOfStay}</strong>
            </h5>
          </div>
        </div>
        <div className="row row-cols-2  ">
          <BsArrowUpSquare
            style={{
              fontSize: "3.5em",
              // color: "var(--primary-color)",
            }}
            className="col-2 "
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Move in date
            </Typography>
            <h5>
              <strong>{formattedTime(user_flatmate_data?.moveInDate)}</strong>
            </h5>
          </div>
        </div>
      </section>

      <hr />

      <section className="position-relative w-100 ">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("editDescription", true)}
        >
          <MdEditSquare />
        </Box>
        <p className="fs-5 fw-bold">About me</p>
        {showMore
          ? user_flatmate_data?.description
          : user_flatmate_data?.description?.slice(0, 400)}
        {user_flatmate_data?.description?.length > 400 ? (
          !showMore ? (
            <span
              onClick={toggleShowMore}
              className="text-black fw-bold"
              role="button"
            >
              ...ReadMore
            </span>
          ) : (
            <span
              onClick={toggleShowMore}
              className="text-black fw-bold"
              role="button"
            >
              ...ReadLess
            </span>
          )
        ) : null}
      </section>

      <hr />

      <section className="w-100 position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("propertyPreference", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Property Preference</span>
        <div className="d-flex gap-5 mt-3 w-100 flex-wrap">
          <div className="d-flex gap-2">
            <MdOutlineBedroomChild size={50} />
            <div>
              <p className="m-0">Room furnishing</p>
              <h6 className="fw-bold">{user_flatmate_data?.roomFurnishings}</h6>
            </div>
          </div>
          <div className="d-flex gap-2">
            <FaWifi size={50} />
            <div>
              <p className="m-0">Internet</p>
              <h6 className="fw-bold">{user_flatmate_data?.internet}</h6>
            </div>
          </div>
          <div className="d-flex gap-2">
            <MdOutlineBathroom size={50} />
            <div>
              <p className="m-0">Bathroom</p>
              <h6 className="fw-bold">{user_flatmate_data?.bathRoomType}</h6>
            </div>
          </div>
          <div className="d-flex gap-2">
            <RiParkingBoxLine size={50} />
            <div>
              <p className="m-0">Parking</p>
              <h6 className="fw-bold">{user_flatmate_data?.parking}</h6>
            </div>
          </div>
          <div className="d-flex gap-2">
            <AiOutlineGroup size={50} />
            <div>
              <p className="m-0">Max number of flatmates</p>
              <h6 className="fw-bold">{user_flatmate_data?.totalFlateMates}</h6>
            </div>
          </div>
        </div>
      </section>

      <hr />

      <section className="position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("accomondationType", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Preferred Accomondation</span>
        {user_flatmate_data?.teamUp ? (
          <div className="d-flex flex-wrap gap-2 align-items-center">
            {user_flatmate_data?.accomondationType?.map((item) => {
              return <AccomondationCard name={item} key={item} />;
            })}

            {user_flatmate_data?.haveProperty ? (
              <div className="d-flex align-items-center gap-2 flex-wrap">
                <div className="d-flex align-items-center px-2 h-maxcontent gap-1 py-1 border border-2 rounded rounded-pill text-danger border-danger">
                  <span>
                    <Groups2Icon />
                  </span>
                  <span>team-up</span>
                </div>
                <div className="d-flex align-items-center">
                  <Box
                    className="bg-hover shadow-1-primary shadow position-relative"
                    sx={{
                      width: "30px",
                      height: "30px",
                      clipPath: "polygon(0 50%, 100% 100%, 100% 0)",
                    }}
                  ></Box>
                  <Box className="bg-hover p-2">
                    I have property <strong>on</strong>{" "}
                    {user_flatmate_data?.propertyLocation}{" "}
                    <span>{user_flatmate_data?.propertyType}</span>
                    <span className="mx-1">-</span>
                    {user_flatmate_data?.propertyCapacity} persons
                  </Box>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </section>

      <hr />

      <section className="position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("preferedLocations", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Preferred location</span>
        <div className="d-flex gap-1 flex-wrap">
          {user_flatmate_data?.preferedLocations?.map((item, index) => {
            return (
              <div
                className="d-flex h-maxcontent align-items-center px-2 gap-1 py-1 border border-2 rounded rounded-pill"
                key={index}
              >
                <span>
                  <FmdGoodOutlinedIcon />
                </span>
                <span>{item}</span>
              </div>
            );
          })}
        </div>
      </section>

      <Modal
        open={open.personalInformation}
        onClose={() => handleModal("personalInformation", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PersonalInformation
            gender={user_flatmate_data?.gender}
            firstName={user_flatmate_data?.firstName}
            lastName={user_flatmate_data?.lastName}
            age={user_flatmate_data?.age}
            lookingFor={user_flatmate_data?.lookingFor}
            gener={user_flatmate_data?.gender}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("personalInformation", false)}
            activeindex={activeindex}
          />
        </Box>
      </Modal>
      <Modal
        open={open.basicInformation}
        onClose={() => handleModal("basicInformation", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BasicInformation
            budgetPerWeek={user_flatmate_data?.price}
            lengthOfStay={user_flatmate_data?.lengthOfStay}
            moveInDate={user_flatmate_data?.moveInDate}
            id={user_flatmate_data?._id}
            activeindex={activeindex}
            onClose={() => handleModal("basicInformation", false)}
          />
        </Box>
      </Modal>

      <Modal
        open={open.editDescription}
        onClose={() => handleModal("editDescription", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditDescription
            description={user_flatmate_data?.description}
            id={user_flatmate_data?._id}
            activeindex={activeindex}
            onClose={() => handleModal("editDescription", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.feature}
        onClose={() => handleModal("feature", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Feature
            feature={user_flatmate_data?.features}
            onClose={() => handleModal("feature", false)}
          />
        </Box>
      </Modal>

      <Modal
        open={open.propertyPreference}
        onClose={() => handleModal("propertyPreference", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PropertyPreference
            roomFurnishings={user_flatmate_data?.roomFurnishings}
            internet={user_flatmate_data?.internet}
            bathRoomType={user_flatmate_data?.bathRoomType}
            parking={user_flatmate_data?.parking}
            activeindex={activeindex}
            totalFlateMates={user_flatmate_data?.totalFlateMates}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("propertyPreference", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.accomondationType}
        onClose={() => handleModal("accomondationType", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AccomondationType
            accomondationType={user_flatmate_data?.accomondationType}
            teamUp={user_flatmate_data?.teamUp}
            haveProperty={user_flatmate_data?.haveProperty}
            typeOfProperty={user_flatmate_data?.typeOfProperty}
            propertyCapacity={user_flatmate_data?.propertyCapacity}
            propertyLocation={user_flatmate_data?.preferedLocations}
            id={user_flatmate_data?._id}
            activeindex={activeindex}
            onClose={() => handleModal("accomondationType", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.preferedLocations}
        onClose={() => handleModal("preferedLocations", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PreferedLocations
            activeindex={activeindex}
            preferedLocations={user_flatmate_data?.preferedLocations}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("preferedLocations", false)}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminFlateMateDetail;
