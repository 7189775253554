import { createAsyncThunk } from "@reduxjs/toolkit";
import * as user_api from "../../api/user_api";
import { setAuth } from "../reducers/authenticateSlice";

export const LoginAction = createAsyncThunk(
  "auth/login",
  async ({ formData, navigate, toast, dispatch }, { rejectWithValue }) => {
    try {
      const response = await user_api.Login(formData);
      navigate("/profile");
      localStorage.setItem("onf_canada", response.data.onf_canada);
      toast.success("Login successfully");
      dispatch(setAuth(response.data));
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const google_login = createAsyncThunk(
  "auth/google_login",
  async ({ formData, navigate, toast, dispatch }, { rejectWithValue }) => {
    try {
      const response = await user_api.google_login(formData);
      toast.success("google_login success");
      navigate("/profile");
      localStorage.setItem("onf_canada", response.data.onf_canada);
      try {
        dispatch(setAuth(response.data));
      } catch (error) {}
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const RegisterAction = createAsyncThunk(
  "auth/register",
  async ({ formData, navigate, toast }, { rejectWithValue }) => {
    try {
      toast.info(`.....Pending`);
      const response = await user_api.register_api(formData);
      toast.success("User is regitered Please verify your email");
      navigate("/login");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// chnage password

export const ChangePasswordAction = createAsyncThunk(
  "auth/change_password",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.ChangePassword(formData);
      toast.success("Password changed successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
// reset password
export const ResetPasswordAction = createAsyncThunk(
  "auth/reset_password",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.ResetPassword(formData);
      toast.success(
        response.data.message || "Reset password email sent successfully"
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// send otp
export const sendOtpCodeAction = createAsyncThunk(
  "auth/send_otp",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.sendOtpCode(formData);
      toast.success(response.data.message || "Otp sent successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message || "Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

//verify otp
export const verifyOtpCodeAction = createAsyncThunk(
  "auth/verify_otp",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.verifyOtpCode(formData);
      toast.success(response.data.message || "Otp verified successfully");
      return response.data;
    } catch (err) {
      toast.error(err.response.data.message || "Something went wrong");
      return rejectWithValue(err.response.data);
    }
  }
);

// post bookmark
export const post_bookmark = createAsyncThunk(
  "auth/post_bookmark",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await user_api.post_bookmark(id, data);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get all rental by user
export const get_all_rental_data_by_user = createAsyncThunk(
  "auth/get_all_rental_data_by_user",
  async (__, { rejectWithValue }) => {
    try {
      const response = await user_api.get_all_rental_data_by_user();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//edit_rental_data_by_user
export const editRentalByUser = createAsyncThunk(
  "auth/edit_rental_data_by_user",
  async ({ id, formData, toast, activeindex }, { rejectWithValue }) => {
    try {
      const response = await user_api.edit_rental_data_by_user(id, formData);
      toast.success("Rental updated successfully");
      return { data: response.data, activeIndex: activeindex };
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//edit_flatemate_by_user
export const editFlatemateByUser = createAsyncThunk(
  "auth/edit_flatemate_by_user",
  async ({ id, formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.edit_flatemate_by_user(id, formData);
      toast.success("Flatmate updated successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get get_flatmate_by_user
export const get_flatmate_by_user = createAsyncThunk(
  "auth/get_flatmate_by_user",
  async (__, { rejectWithValue }) => {
    try {
      const response = await user_api.get_flatmate_by_user();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//get_professional_data_by_user
export const getProfessionalProfile = createAsyncThunk(
  "auth/get_professional_data_by_user",
  async (__, { rejectWithValue }) => {
    try {
      const response = await user_api.get_professional_data_by_user();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
//get_organization_data_by_user
export const getOrganizationProfile = createAsyncThunk(
  "auth/get_organization_data_by_user",
  async (__, { rejectWithValue }) => {
    try {
      const response = await user_api.get_organization_data_by_user();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// get_business_by_user
export const getBusinessProfile = createAsyncThunk(
  "auth/get_business_by_user",
  async (__, { rejectWithValue }) => {
    try {
      const response = await user_api.get_business_by_user();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//edit_business_by_user

export const userEditBusinessAction = createAsyncThunk(
  "user/user_edit_business_by_user",
  async ({ formData, id, toast, activeindex }, { rejectWithValue }) => {
    console.log("id", id);
    try {
      const response = await user_api.edit_business_by_user(id, formData);
      toast.success(response.data.message);
      const response2 = { data: response.data, activeIndex: activeindex };
      return response2;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//edit_organization_by_user
export const userEditOrganizationAction = createAsyncThunk(
  "user/user_edit_organization_by_user",
  async ({ formData, id, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.edit_organization_by_user(id, formData);
      toast.success(response.data.message||"Organization updated successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// create help support
export const createHelpSupport = createAsyncThunk(
  "auth/createHelpSupport",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.createHelpSupport(formData);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// create contact us
export const createContactUs = createAsyncThunk(
  "auth/createContactUs",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.createContactUser(formData);
      toast.success(response.data.message);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

// edit profile
export const  EditProfileAction = createAsyncThunk(
  "auth/edit_profile",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.EditProfile(formData);
      toast.success("Profile updated successfully");
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

//edit_professional_by_user
export const userEditProfessionalAction = createAsyncThunk(
  "user/user_edit_professional_by_user",
  async ({ formData, id, toast }, { rejectWithValue }) => {
    try {
      const response = await user_api.edit_professional_by_user(id, formData);
      toast.success(
        response.data.message || "Professional updated successfully"
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export default {
  LoginAction,
  RegisterAction,
};
