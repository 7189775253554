import React from "react";
import {professions} from "../../../utils/professionSettings";
import { SelectProfessional } from "../../../components/Index";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Chip } from "@mui/material";
const ProfessionalType = ({ errors, handleFormValue, formvalue }) => {
  const [offering, setOffering] = React.useState("");
  const handleOffering = (e) => {
    setOffering(e.target.value);
  };

  const onkeyPress = (e) => {
    if (e.key === "Enter") {
      handleFormValue("addoffer", e.target.value);
      setOffering("");
    }
  };

  const addIconPress = () => {
    handleFormValue("addoffer", offering);
    setOffering("");
  };
  return (
    <div className="d-flex flex-column gap-3">
      <section>
        <h5 className="fw-bold text-black">Select a profession</h5>
        <SelectProfessional
          list={professions}
          name={"profession"}
          placeholder={"select your profession"}
          havelabe={false}
          error={errors.profession}
          handleChange={handleFormValue}
          currentvalue={formvalue.profession}
        />
      </section>
      <hr />
      <section>
        <h5 className="fw-bold text-black">What Type of Service You Offer ?</h5>

        <div className="form-group">
          <div className="d-flex align-items-center gap-2">
            <input
              type="text"
              className={
                errors.offers
                  ? "form-control is-invalid py-2 m-0"
                  : "form-control py-2"
              }
              placeholder="Create your offering and hit enter"
              onChange={handleOffering}
              value={offering}
              onKeyPress={onkeyPress}
            />
            <AddCircleIcon role="button" onClick={addIconPress} />
          </div>
          <small
            id="offerhelp"
            className={errors.offers ? "form-text text-danger" : ""}
          >
            {errors.offers}
          </small>
        </div>
        <small className="text-decoration-underline">Showng added services</small>
        <div className="d-flex flex-wrap gap-1 mt-2">
          {formvalue.offers.map((item, index) => {
            return (
              <Chip
                key={index}
                onDelete={() => handleFormValue("removeoffer", item)}
                label={item}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default ProfessionalType;
