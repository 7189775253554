import { Button } from '@mui/material';
import { Box, Container, Stack } from '@mui/system';
import React, { useState } from 'react';
import AddNotificationModal from './AddNotificationModal';
import { AdminNotificationTable } from './AdminNotificationTable';
import { useDispatch } from 'react-redux';
import { adminCreateNotification } from '../../../../context/actions/admin/notificationAction';
import { toast } from "react-toastify";

const AdminNotification = () => {
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState({
    title: '',
    description: '',
    broadcast: true,
    scheduledDate: null,
  });
  const [errors, setErrors] = useState({});

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNotificationData({
      title: '',
      description: '',
      broadcast: true,
      scheduledDate: null,
    });
    setErrors({});
  };
  const dispatch = useDispatch();


  const handleCreateNotification = () => {
    // Perform form validation
    const validationErrors = validateForm(notificationData);
    if (Object.keys(validationErrors).length === 0) {
      console.log(notificationData);
      dispatch(
        adminCreateNotification({
          data: notificationData,
          toast : toast
        })
      )
      handleClose();
    } else {
      setErrors(validationErrors);
    }
  };

  const handleNotificationDataChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setNotificationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBroadcastClick = () => {
    setNotificationData((prevState) => ({
      ...prevState,
      broadcast: true,
    }));
  };

  const handleScheduleClick = () => {
    setNotificationData((prevState) => ({
      ...prevState,
      broadcast: false,
    }));
  };

  const handleDateChange = (date) => {
    console.log(date);
    setNotificationData((prevState) => ({
      ...prevState,
      scheduledDate: date,
    }));
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.title.trim()) {
      errors.title = 'Title is required';
    }

    if (!data.description.trim()) {
      errors.description = 'Description is required';
    }

    return errors;
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <div className="d-flex flex-column">
            <span className="fs-3 fw-bold text-black">Notifications & Announcements</span>
            <small>Create, broadcast and manage notifications and announcements</small>
          </div>
          <div>
            <button className="btn btn-sm btn-outline-black" onClick={handleOpen}>
              Create Notification
            </button>
          </div>
        </Stack>
        <AdminNotificationTable />
        <AddNotificationModal
          open={open}
          handleClose={handleClose}
          notificationData={notificationData}
          errors={errors}
          handleNotificationDataChange={handleNotificationDataChange}
          handleCreateNotification={handleCreateNotification}
          handleBroadcastClick={handleBroadcastClick}
          handleScheduleClick={handleScheduleClick}
          handleDateChange={handleDateChange}
        />
      </Container>
    </Box>
  );
};

export default AdminNotification;
