import { useState, useEffect } from "react";
import {
  Container,
  FormControlLabel,
  Typography,
  Divider,
  Button,
  Stack,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
import { showtoast } from "../../context/reducers/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { RegisterAction } from "../../context/actions/authAction";
import useResponsive from "../../hooks/useResponsive";
import { styled } from "@mui/material/styles";
import { Google } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { FormInput, CountrySelect } from "../../components/Index";
import "./SignUp.css";
import { Helmet } from "react-helmet-async";

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
  maxWidth: "45%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error } = useSelector((state) => ({
    ...state.user_reducer,
  }));
  useEffect(() => {
    if (error) {
      dispatch(showtoast(toast.error(error)));
    }
  }, [error]);
  const [isChecked, setChecked] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState("");
  const handleChanges2 = (event, value) => setSelectedOptions(value.label);
  const [errors, setErrors] = useState({});

  const toggleCheck = () => {
    setChecked((prev) => !prev);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const firstname = data.get("firstname");
    const lastname = data.get("lastname");
    const email = data.get("email");
    const password = data.get("password");
    const cpassword = data.get("cpassword");

    // change this to your own validation
    const isValid = validateForm({
      firstname,
      lastname,
      email,
      password,
      cpassword,
      selectedOptions,
    });

    if (isValid) {
      const formData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        country: selectedOptions,
        password: password,
      };

      dispatch(RegisterAction({ formData, navigate, toast }));
    }
  };

  const validateForm = ({
    firstname,
    lastname,
    email,
    password,
    cpassword,
    selectedOptions,
  }) => {
    const errors = {};
    let formIsValid = true;

    if (!isChecked) {
      formIsValid = false;


      errors.isChecked = "Please agree on terms and conditions *";
    }

    if (!firstname) {
      formIsValid = false;
      errors.firstname = "First name is required *";
    }

    if (!lastname) {
      formIsValid = false;
      errors.lastname = "Last name is required *";
    }

    if (typeof email !== "undefined" && email !== null) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(email)) {
        formIsValid = false;
        errors.email = "Please enter valid email-ID *";
      }
    }

    if (!selectedOptions) {
      formIsValid = false;
      errors.country = "Country is required *";
    }

    if (typeof password !== "undefined" && password !== null) {
      if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/)) {
        formIsValid = false;
        errors.password = "Create a strong password *";
      }
    }

    if (!cpassword) {
      formIsValid = false;
      errors.cpassword = "Confirm your password *";
    }

    if (typeof cpassword !== "undefined" && cpassword !== null) {
      if (password !== cpassword) {
        formIsValid = false;
        errors.cpassword = "Password does not match *";
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const mdUp = useResponsive("up", "md");
  return (
    <>
      <Helmet>
        <title>
          Sign up for ONF CANADA - Your Gateway to Canada! Join our platform
        </title>
        <meta name="description"
          data-rh="true"
          content="Sign up for ONF CANADA - Your Gateway to Canada! Join our platform dedicated to organizations, businesses, rentals, flatmates, teaming up, blogs, and studying abroad in Canada. Be a part of our community, discover exciting opportunities, and access valuable resources for a fulfilling Canadian experience. Register today!" />
        <link rel="canonical" href="/register" />
      </Helmet>
      <div className="login-header">
        {mdUp && (
          <StyledSection>
            <div className="left">
              <img src="/assets/images/signup.png" alt="login" />
            </div>
          </StyledSection>
        )}

        <Container maxWidth="xs">
          <form onSubmit={onSubmit}>
            <div className="right-side">
              <Typography variant="h4" gutterBottom>
                Create Account
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                Already have an account? {""}
                <Link to="/login" style={{ textDecoration: "none" }}>
                  Login
                </Link>
              </Typography>

              <Stack direction="row" spacing={1}>
                <Button
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="outlined"
                  startIcon={<Google />}
                >
                  Sign up with Google
                </Button>
              </Stack>

              <Divider sx={{ my: 2 }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  OR
                </Typography>
              </Divider>

              <Stack spacing={2}>
                <div className="d-flex gap-3">
                  <FormInput
                    name={"firstname"}
                    label={"First name"}
                    type={"text"}
                    required={true}
                    error={errors.firstname ? true : false}
                    helperText={errors.firstname}
                  />
                  <FormInput
                    name={"lastname"}
                    label={"Last name"}
                    type={"text"}
                    required={true}
                    error={errors.lastname ? true : false}
                    helperText={errors.lastname}
                  />
                </div>
                <FormInput
                  name={"email"}
                  label={"Email Address"}
                  type={"email"}
                  required={true}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                />

                <CountrySelect
                  label={"Choose your country"}
                  handleChange={handleChanges2}
                  required={true}
                  error={errors.country ? true : false}
                  helperText={errors.country}
                />

                <div className="d-flex gap-3">
                  <FormInput
                    name={"password"}
                    label={"Create password"}
                    required={true}
                    type={"password"}
                    error={errors.password ? true : false}
                    helperText={errors.password}
                  />
                  <FormInput
                    name={"cpassword"}
                    label={"Confirm password"}
                    type={"password"}
                    required={true}
                    error={errors.cpassword ? true : false}
                    helperText={errors.cpassword}
                  />
                </div>
              </Stack>
              <FormControlLabel
                sx={{ my: 2 }}
                control={
                  <Checkbox checked={isChecked} onChange={toggleCheck} />
                }
                label="I agree to the terms of ONF Canada"
              />
              {errors.isChecked && (
                <p className="text-danger">{errors.isChecked}</p>
              )}

              <Button
                className="btn-dark"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Register
              </Button>
            </div>
          </form>
        </Container>
      </div>
    </>
  );
}
