const blogcategories = [
    "News",
    "Educational",
    "Study Abroad",
    "Immigration",
    "Scholarships",
    "Career Guidance",
    "Student Life",
    "Language Learning",
    "Visa Information",
    "University Reviews",
    "Student Budgeting",
    "Internship Opportunities",
    "Graduate School Applications",
    "Living Abroad",
    "Cultural Exchange",
    "Student Accommodations",
    "Travel Tips for Students",
    "Job Prospects",
    "Student Health and Wellness",
    "Student Networking",
    "Online Learning",
    "International Education",
    "Student Experiences",
    "Academic Resources",
    "Research Opportunities",
    "Volunteer Abroad",
    "Test Preparation",
    "Financial Aid",
    "Career Development",
    "Study Tips",
    "Student Visa Processes",
    "Educational",
    "Food",
    "Travel",
    "Personal",
    "Health And Fitness",
    "Technology",
    "Fashion",
    "Lifestyle",
    "Finance",
    "Sports",
    "Entertainment",
    "Beauty",
    "Parenting",
    "Home Decor",
    "Gardening",
    "DIY",
    "Photography",
    "Art and Design",
    "Books",
    "Music",
    "Career Development",
    "Self Improvement",
    "Product Reviews",
    "Motivation",
    "Social Media",
    "Marketing",
    "Business",
    "Science",
    "Environment",
    "History",
    "Immigration",
    "Others"
];


export default blogcategories;