import React from 'react'
import { Box, Button, ButtonGroup } from '@mui/material'
import Skeleton from 'react-loading-skeleton'



const RentalDetailSkeleton = () => {
  return (
    <div className="container d-flex flex-column">
      <section className="grid-images position-relative rounded rounded-3 overflow-hidden mt-3">
        <Skeleton height={400} />
        <Box
          sx={{
            position: "absolute",
            bottom: "3%",
            right: "1%",
          }}
          className="btn d-flex gap-2 align-items-center bg-white"
        >
          <Skeleton height={30} width={120} />
        </Box>
      </section>

      <section className="d-flex flex-column text-black ">
        <div className="d-flex w-100 justify-content-between">
          <Skeleton height={30} width={200} />
          <div className="action-icons d-flex align-items-center">
            <ButtonGroup
              variant="text"
              aria-label="text button "
            >
              <Button>
                <div className="comments d-flex align-items-center gap-1">
                  <Skeleton height={20} width={40} />
                </div>
              </Button>
              <Button>
                <div className="clapped d-flex align-items-center gap-1">
                  <Skeleton height={20} width={50} />
                </div>
              </Button>
              <Button>
                <div className="clapped d-flex align-items-center gap-1">
                  <Skeleton height={20} width={30} />
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <h6>
          <span className="fw-bold">
            <Skeleton height={20} width={200} />
          </span>
        </h6>
      </section>

      <div className="p-0 two-section row-cols-1 row-cols-lg-2 row w-100 justify-content-between">
        <div className="left-section col-lg-8 d-flex flex-column">
          <section className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex mt-2 w-100 gap-4">
              <div className="d-flex gap-3">
                <div>
                  <h5 className="fw-bold">
                    <Skeleton height={20} width={80} />
                  </h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div>
                  <h5 className="fw-bold">
                    <Skeleton height={20} width={80} />
                  </h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div>
                  <h5 className="fw-bold">
                    <Skeleton height={20} width={80} />
                  </h5>
                </div>
              </div>
              <div className="d-flex gap-2">
                <div>
                  <h5 className="fw-bold">
                    <Skeleton height={20} width={80} />
                  </h5>
                </div>
              </div>
            </div>
          </section>

          <section className="mt-3">
            <div>
              <Skeleton count={5} />
            </div>
          </section>

          <section className="mt-3">
            <div>
              <Skeleton count={3} />
            </div>
          </section>
        </div>

        <div className="right-section col-lg-4 d-flex flex-column">
          <section className="mt-2">
            <div>
              <Skeleton height={200} />
            </div>
          </section>

          <section className="mt-5">
            <div>
              <Skeleton height={60} />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default RentalDetailSkeleton