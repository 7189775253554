import { GiLovers, GiPerson } from "react-icons/gi";
import { HiUserGroup } from "react-icons/hi";
import { MdFamilyRestroom } from "react-icons/md";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import { GiBroccoli } from "react-icons/gi";

const maxLocation = 5;

const teamUpProperty = ["Have property", "Don't have property"];

const lookingIconForLst = [
  {
    id: "1",
    name: "Couple",
    icon: <GiLovers />,
    description: "Me and my patner",
  },
  {
    id: "2",
    name: "Myself",
    icon: <GiPerson />,
    description: "I am alone just roaming to find the rental property",
  },
  {
    id: "3",
    name: "Group",
    icon: <HiUserGroup />,

    description: "A group of people",
  },
  {
    id: "4",
    name: "Family",
    icon: <MdFamilyRestroom />,

    description: "Me and my family",
  },
];

const accomondationTypes = [
  {
    id: "1",
    name: "Whole Property",
    icon: <HomeOutlinedIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "2",
    name: "Shared Room",
    icon: <BedOutlinedIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "3",
    name: "Student Accomondation",
    icon: <SchoolOutlinedIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "4",
    name: "Separate bed room",
    icon: <HotelOutlinedIcon style={{ fontSize: "3em" }} />,
  },
];

const featurelst = [
  {
    id: "1",
    name: "Have Children",
    icon: <FamilyRestroomIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "2",
    name: "Have Pets",
    icon: <PetsIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "3",
    name: "None Smoker",
    icon: <SmokeFreeIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "4",
    name: "Smoker",
    icon: <SmokingRoomsIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "5",
    name: "Vegetarian",
    icon: <GiBroccoli style={{ fontSize: "3em" }} />,
  },
];
const occupations = [
  "Accountant",
  "Actor",
  "Architect",
  "Astronomer",
  "Biologist",
  "Bartender",
  "Bus Driver",
  "Businessperson",
  "Butcher",
  "Carpenter",
  "Cashier",
  "Chef",
  "Dentist",
  "Dietitian",
  "Driver",
  "Doctor",
  "Diplomat",
  "Economist",
  "Electrician",
  "Engineer",
  "Farmer",
  "Fashion Designer",
  "Financial Analyst",
  "Fitness Instructor",
  "Firefighter",
  "Flight Attendant",
  "Graphic Designer",
  "Hairdresser",
  "Historian",
  "Interior Designer",
  "Interpreter",
  "Journalist",
  "Judge",
  "Lawyer",
  "Librarian",
  "Mechanic",
  "Model",
  "Meteorologist",
  "Musician",
  "Nurse",
  "Optometrist",
  "Painter",
  "Pharmacist",
  "Photographer",
  "Pilot",
  "Police Officer",
  "Professor",
  "Psychologist",
  "Physician",
  "Physicist",
  "Plumber",
  "Politician",
  "Programmer",
  "Receptionist",
  "Real Estate Agent",
  "Research Scientist",
  "Sales Representative",
  "Social Worker",
  "Software Developer",
  "Surgeon",
  "Surveyor",
  "Teacher",
  "Translator",
  "Travel Agent",
  "Veterinarian",
  "Web Developer",
  "Writer",
  "Waiter/Waitress",
  "Zoologist",
  "Others",
];
const Ages = Array.from(Array(60 - 18 + 1).keys(), (num) => num + 18);
const genderlst = ["Male", "Female", "Others"];

const capacityOfPropery = [...Array(10).keys()];
const stayLst = [
  "6 months",
  "7 months",
  "8 months",
  "9 months",
  "10 months",
  "11 months",
  "12 months",
];
export {
  lookingIconForLst,
  accomondationTypes,
  featurelst,
  teamUpProperty,
  occupations,
  Ages,
  genderlst,
  capacityOfPropery,
  stayLst,
  maxLocation,
};
