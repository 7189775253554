import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import SchoolIcon from "@mui/icons-material/School";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import { SvgIcon } from "@mui/material";
import { ImBlogger } from "react-icons/im";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { FaUserAlt, FaHouseUser, FaRegImage } from "react-icons/fa";
import { BsBell, BsBellFill, BsHouseExclamationFill } from "react-icons/bs";
// FaBuysellads
import { FaBuysellads } from "react-icons/fa";
// RiAdminLine
import { RiAdminLine } from "react-icons/ri";
// IoMdHelpCircle
import { IoMdHelpCircle } from "react-icons/io";
// FaWpforms
import { FaWpforms } from "react-icons/fa";
import { HelpCenter } from "@mui/icons-material";

export const items = [
  {
    title: "Overview",
    path: "/admin",
    icon: (
      <SvgIcon fontSize="small">
        <ChartBarIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Featured Ads",
    path: "/admin/featuredAds",
    icon: (
      <SvgIcon fontSize="small">
        <FaBuysellads />
      </SvgIcon>
    ),
  },
  {
    title: "Notifications",
    path: "/admin/notification",
    icon: (
      <SvgIcon fontSize="small">
        <BsBellFill />
      </SvgIcon>
    ),
  },
  {
    title: "Help and Support",
    path: "/admin/helpandsupport",
    icon: (
      <SvgIcon fontSize="small">
        <IoMdHelpCircle />
      </SvgIcon>
    ),
  },
  {
    title: "Users",
    path: "/admin/users",
    icon: (
      <SvgIcon fontSize="small">
        <FaUserAlt />
      </SvgIcon>
    ),
  },
  {
    title: "Organization",
    path: "/admin/companies",
    icon: (
      <SvgIcon fontSize="small">
        <UsersIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Business",
    path: "/admin/business",
    icon: (
      <SvgIcon fontSize="small">
        <BusinessCenterIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Blogs",
    path: "/admin/blogs",
    icon: (
      <SvgIcon fontSize="small">
        <ImBlogger />
      </SvgIcon>
    ),
  },
  {
    title: "Rental",
    path: "/admin/rental",
    icon: (
      <SvgIcon fontSize="small">
        <BsHouseExclamationFill />
      </SvgIcon>
    ),
  },
  {
    title: "FlateMate",
    path: "/admin/flateMate",
    icon: (
      <SvgIcon fontSize="small">
        <FaHouseUser />
      </SvgIcon>
    ),
  },
  {
    title: "Professionals",
    path: "/admin/professionals",
    icon: (
      <SvgIcon fontSize="small">
        <UserIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Abroad Study",
    path: "/admin/students",
    icon: (
      <SvgIcon fontSize="small">
        <SchoolIcon />
      </SvgIcon>
    ),
  },
  {
    title: "Events & Gallery",
    path: "/admin/events",
    icon: (
      <SvgIcon fontSize="small">
        <FaRegImage />
      </SvgIcon>
    ),
  },
  {
    title: "Admin and Moderators",
    path: "/admin/members",
    icon: (
      <SvgIcon fontSize="small">
        <RiAdminLine />
      </SvgIcon>
    ),
  },
  {
    title: "Contact Forms",
    path: "/admin/contactform",
    icon: (
      <SvgIcon fontSize="small">
        <FaWpforms />
      </SvgIcon>
    ),
  },
  {
    title: "FAQs and Help",
    path: "/admin/faqs",
    icon: (
      <SvgIcon fontSize="small">
        <HelpCenter />
      </SvgIcon>
    ),
  },
  {
    title: "Settings",
    path: "/admin/settings",
    icon: (
      <SvgIcon fontSize="small">
        <CogIcon />
      </SvgIcon>
    ),
  },

  {
    title: "Error",
    path: "/admin/404",
    icon: (
      <SvgIcon fontSize="small">
        <XCircleIcon />
      </SvgIcon>
    ),
  },
];
