import { Rating, Typography } from '@mui/material'
import React from 'react'

const ProfessionalReview = ({comments}) => {
  return (
    <div>
    <div className="d-flex gap-2 mt-3 align-items-center">
      {/* <img
        src="https://i.pravatar.cc/300"
        alt=""
        width={"30px"}
        className="rounded-circle"
      /> */}

      <div className="namediv d-flex gap-2">
        <div>
          <h6 className="m-0">{comments?.name}</h6>
          <div className="d-flex">
            <Rating
              size="small"
              name="half-rating-read"
              value={comments?.rating}
              precision={0.5}
              readOnly
            />
            <Typography
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "0.2rem",
              }}
            >
            {comments?.rating}
            </Typography>
          </div>
        </div>
      </div>
    </div>
    <p>
      {comments?.comment}
    </p>
  </div>
  )
}

export default ProfessionalReview