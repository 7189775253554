import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Container, Drawer } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { Suspense, lazy } from "react";
import { BiPencil } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import AreYouSureModal from "../../../../../components/ConfirmationDialog/AreYouSureModal";
import { adminChangeOrganizationStatusAction,adminUpdateFeaturedOrganizationAction} from "../../../../../context/actions/admin/organizationAction";
import ContactDetails from "./ContactDetails";
import IntroductionTab from "./IntroductionTab";
import LstOfBoardMembers from "./LstOfBoardMembers";
import AdminEditOrg from "../adminEditOrg/AdminEditOrg";

const EditOrgForm = lazy(() =>
  import("./EditOrgForm").then((module) => {
    return { default: module.default };
  })
);
// import EditOrgForm from "./EditOrgForm";

const AdminOrgProfile = React.memo(
  ({
    value,
    formvalue,
    item,
    handleformvalue,
    onmemberchange,
    handleChange,
    activeindex,
    closemodal,
  }) => {
    const [active, setActive] = React.useState(0);
    const [isEdit, setEdit] = React.useState(false);
    const toggleEdit = () => {
      setEdit((prev) => !prev);
    };

    const [id, setId] = React.useState(item?._id);
    const dispatch = useDispatch();

    // state
    const [open, setOpen] = React.useState(false);
    const [isfeatured, setFeatured] = React.useState(false);

    const togglefeature = () => {
        
    };

  const featuredTure = (e) => {
    e.preventDefault();
    dispatch(
      adminUpdateFeaturedOrganizationAction({
        id: id,
        data: true,
        toast,
        activeindex,
      })
    );

  }

  const featuredFalse = (e) => {
    e.preventDefault();
     dispatch(
       adminUpdateFeaturedOrganizationAction({
         id: id,
         data: false,
         toast,
         activeindex,
       })
     );
  }

    // toggle deactive modal
    const toggle = () => setOpen(!open);

    const onDeactive = (e) => {
      toggle();
      if (item?.active === true) {
        handleChangesfalse(e);
      } else {
        handleChangestrue(e);
      }
    };

    const tabs = ["Information", "Contact Details", "BoardMembers"];
    // make handleChangesfalse function
    const handleChangesfalse = (e) => {
      e.preventDefault();
      dispatch(
        adminChangeOrganizationStatusAction({
          id: id,
          data: false,
          toast,
          activeindex,
        })
      );
    };
    const handleChangestrue = (e) => {
      e.preventDefault();
      dispatch(
        adminChangeOrganizationStatusAction({
          id: id,
          data: true,
          toast,
          activeindex,
        })
      );
    };

    return (
      <Container
        className=" position-relative "
        sx={{
          width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
        }}
      >
        <main className="position-absolute top-0 start-0  h-100 container  ">
          <Box
            className={active === 0 ? "w-100" : " d-none"}
            sx={{
              width: active === 0 ? "" : "97%",
            }}
          >
            <div className="pb-2">
              <span className="fs-4 fw-bold text-decoration-underline">
                Showing organization profile{" "}
              </span>
              <Box
                className="bg-hover rounded rounded-circle d-flex justify-content-center align-items-center"
                sx={{
                  position: "absolute",
                  top: "3%",
                  right: "1%",
                  width: 40,
                  height: 40,
                }}
                role="button"
                onClick={closemodal}
              >
                <ImCross className=" bg-hover rounded rounded-circle fs-5" />
              </Box>
            </div>

            <div className={active === 0 ? "h-100" : "h-100"}>
              <div className="d-flex gap-2  w-100 align-items-center ">
                <img
                  // src="https://png.pngtree.com/png-vector/20190225/ourmid/pngtree-circuit-logo-template-vector-png-image_704226.jpg"
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item?.introduction.profile}`}
                  alt=""
                  srcSet=""
                  className="rounded-circle rounded object-cover"
                  style={{
                    border: "6px solid #F1F1F1",
                    height: 80,
                    width: 80,
                  }}
                />
                <div className="d-flex flex-column">
                  <h4 className="fw-bold text-black m-0 p-0">
                    {item?.introduction?.organizationName}
                  </h4>
                  <span className="badge badge-light text-start">
                    {item?.contactAndLinks.email} ✅
                  </span>
                </div>
                
                <div className="d-flex gap-2 ms-auto ">
                  {item.featured ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      data-mdb-ripple-color="dark"
                      onClick={featuredFalse}
                    >
                      unfeatured
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-primary"
                      data-mdb-ripple-color="dark"
                      onClick={featuredTure}
                    >
                      featured
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-dark"
                    data-mdb-ripple-color="dark"
                    onClick={toggleEdit}
                  >
                    <BiPencil /> Edit
                  </button>
                  {item?.active ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      data-mdb-ripple-color="dark"
                      onClick={toggle}
                    >
                      Deactive
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-success"
                      data-mdb-ripple-color="dark"
                      onClick={toggle}
                    >
                      Active
                    </button>
                  )}
                </div>
              </div>

              <section className="mt-3">
                <TabContext value={value.toString()}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      background: "#F6F6F6",
                    }}
                  >
                    {tabs.map((item, index) => {
                      return (
                        <Tab
                          label={item}
                          value={index.toString()}
                          key={item}
                          className="text-capitalize"
                          indicatorcolor={""}
                          sx={{
                            "&.Mui-selected": {
                              color: "#797979",
                              fontWeight: "bolder",
                              border: "none",
                            },
                          }}
                        />
                      );
                    })}
                  </TabList>

                  <TabPanel className="m-0 p-0 mt-3" value="0">
                    <IntroductionTab introduction={item?.introduction} />
                  </TabPanel>
                  <TabPanel className="m-0 p-0 mt-3" value="1">
                    <ContactDetails item={item?.contactAndLinks} />
                  </TabPanel>
                  <TabPanel className="m-0 p-0 mt-3" value="2">
                    <LstOfBoardMembers />
                  </TabPanel>
                </TabContext>
              </section>
            </div>
          </Box>
        </main>
        {/* <Box
          sx={{
            zIndex: "99999",
          }}
        >
          <Suspense fallback={<h1>...loading</h1>}>
            {active === 1 ? (
              <EditOrgForm
                value={value}
                formvalue={formvalue}
                handleformvalue={handleformvalue}
                onmemberchange={onmemberchange}
                item={item}
                active={active}
                closeedit={() => {
                  setActive(0);
                }}
              />
            ) : null}
          </Suspense>
        </Box> */}
        <AreYouSureModal
          open={open}
          handleOpen={toggle}
          onToggleActive={onDeactive}
          isActive={item?.active}
        />

        <Drawer open={isEdit} onClose={toggleEdit} anchor="right">
          <AdminEditOrg
            activeindex={activeindex}
            data={item}
            onClose={toggleEdit}
          />
        </Drawer>
      </Container>
    );
  }
);

export default AdminOrgProfile;
