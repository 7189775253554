const allblog = [
  {
    _id: "1",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "2",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "3",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "4",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "5",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "6",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "7",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
  {
    _id: "8",
    title: "HTML CSS Full Course",
    description: "loremm 22 lores mshh mswww sdfsfs sdfsd dsfsdfsfs",
    thumbnail:
      "https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/86d4d82c-fa58-4ae0-9c6e-a14995598f74/dftomwd-13451cbd-654b-45c3-a6ee-dfeece83b370.png/v1/fill/w_977,h_651,q_80,strp/underwater_by_metataart_dftomwd-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NjUxIiwicGF0aCI6IlwvZlwvODZkNGQ4MmMtZmE1OC00YWUwLTljNmUtYTE0OTk1NTk4Zjc0XC9kZnRvbXdkLTEzNDUxY2JkLTY1NGItNDVjMy1hNmVlLWRmZWVjZTgzYjM3MC5wbmciLCJ3aWR0aCI6Ijw9OTc3In1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmltYWdlLm9wZXJhdGlvbnMiXX0.-4miGtoAwqDoqU2dS4MWAy0zXA9YYY9SqeGGvScutuI",
    author: "John Doe",
    category: "Education",
  },
];

export default allblog;
