import { createSlice } from "@reduxjs/toolkit";
import {
  CreateOrgAction,
  GetOrganizationDetailsAction,
  getFeaturedOrganizationAction,
  getorganizationAction,

} from "../actions/organizationAction";

const initialState = {
  loading: false,
  createOrganizationLoading: false,
  featuredLoading: false,
  isUpgrade: false,
  organization: [],
  featured_organization: [],
  recommendedOrganization: [],
  org_details: {},
  resultperpage: 0,
  filteredOrganizationCount: 0,
  totalorganizationcount: 0,
  error: "",
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,

  reducers: {
    isUpgrade: (state, action) => {
      state.isUpgrade = true;
    },
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create organization
      .addCase(CreateOrgAction.pending, (state, action) => {
        state.createOrganizationLoading = true;
      })
      .addCase(CreateOrgAction.fulfilled, (state, action) => {
        state.createOrganizationLoading = false;
      })
      .addCase(CreateOrgAction.rejected, (state, action) => {
        state.createOrganizationLoading = false;
        state.error = action.payload.message;
      })

      .addCase(getorganizationAction.pending, (state, action) => {
        state.loading = true;
        state.organization = [];
      })
      .addCase(getorganizationAction.fulfilled, (state, action) => {
        state.loading = false;
        const {
          organization,
          resultPerPage,
          filteredOrganizationCount,
          totalOrganizationCount,
        } = action.payload;
        state.organization = organization;
        state.resultperpage = resultPerPage;
        state.filteredOrganizationCount = filteredOrganizationCount;
        state.totalorganizationcount = totalOrganizationCount;
      })

      .addCase(getorganizationAction.rejected, (state, action) => {
        state.organization = [];
        state.loading = false;
      })

      // get all featured organization
      .addCase(getFeaturedOrganizationAction.pending, (state, action) => {
        state.featuredLoading = true;
        state.featured_organization = [];
      })
      .addCase(getFeaturedOrganizationAction.fulfilled, (state, action) => {
        state.featuredLoading = false;
        const { featuredOrganization } = action.payload;
        state.featured_organization = featuredOrganization;
      })
      .addCase(getFeaturedOrganizationAction.rejected, (state, action) => {
        state.featuredLoading = false;
        state.featured_organization = [];
      })

      // get organization details
      .addCase(GetOrganizationDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.org_details = null;
      })
      .addCase(GetOrganizationDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        const { organization, featured } = action.payload;
        state.org_details = organization;
        state.recommendedOrganization = featured;
      })
      .addCase(GetOrganizationDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export const { isUpgrade,clearError } = organizationSlice.actions;
export default organizationSlice.reducer;
