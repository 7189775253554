import React from "react";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PetsIcon from "@mui/icons-material/Pets";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import { GiBroccoli } from "react-icons/gi";
import { Chip } from "@mui/material";

const FeatureChip = ({ name }) => {
  const list = [
    {
      id: "1",
      name: "Have Children",
      icon: <FamilyRestroomIcon style={{ fontSize: "1.4em" }} />,
    },

    {
      id: "2",
      name: "Have Pets",
      icon: <PetsIcon style={{ fontSize: "1.7em" }} />,
    },

    {
      id: "3",
      name: "None Smoker",
      icon: <SmokingRoomsIcon style={{ fontSize: "1em" }} />,
    },

    {
      id: "4",
      name: "Smoker",
      icon: <SmokeFreeIcon style={{ fontSize: "1.4em" }} />,
    },

    {
      id: "5",
      name: "Vegetarian",
      icon: <GiBroccoli style={{ fontSize: "1.4em" }} />,
    },
  ];

  const icon = list.find((item) => item.name === name)?.icon;

  return (

    <Chip icon={icon} label={name} variant="outlined" />

  );
};

export default FeatureChip;
