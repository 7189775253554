import { CheckOutlined, MoreVert } from "@mui/icons-material";
import { Button, Drawer } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { BiTime } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { adminGetAllNotification } from "../../../../context/actions/admin/notificationAction";
import AdminUserDetail from "../users/AdminUserDetail/AdminUserDetail";
import moment from "moment";

export const AdminNotificationTable = ({ keyword }) => {
  const [activeindex, setActive] = React.useState(0);

  const columns = [
    {
      field: "Notification",
      width: 150,
      align: 'center',
      renderCell: (params) => (
        <img
          src='/assets/icons/notification.png'
          alt="Logo"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
      headerName: "Notification",
    },
    { field: "Title", headerName: "Title", flex: 1, headerAlign: 'center', align: 'left' },
    { field: "Description", headerName: "Description", flex: 1, headerAlign: 'center', align: 'left' },
    { field: "DateTime", headerName: "Date and Time", flex: 1, headerAlign: 'center', align: 'left' },
    {
      field: "Status",
      headerName: "Status",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <p>
              Broadcasted <CheckOutlined color="primary" />
            </p>
          );
        } else {
          return <p>
            Scheduled <BiTime color="primary" />
          </p>;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const handleButtonClick = () => {
          setActive(params.row.index);
          toggleDrawer();
        };
        return (
          <div>
            <Button size="medium" variant="outlined" onClick={handleButtonClick}>
              <MoreVert color="error" />
            </Button>
          </div>
        );
      },
    },
  ];
  
  const dispatch = useDispatch();

  const [page, setPage] = React.useState(0);
  // drawer toggle
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen(!isOpen);

  React.useEffect(() => {
    dispatch(adminGetAllNotification({ keyword, page }));
  }, [dispatch, keyword, page]);

  // use useSelector to get data from redux store
  const { notifications, resultperpage, totalusercount, loading } = useSelector(
    (state) => ({
      ...state.admin_reducer,
    })
  );

  // map user data to rows
  const notification = notifications.map((item, index) => ({
    id: item._id,
    index: index,
    Notification: "https://i.pravatar.cc/300",
    Title : item?.title,
    Description : item?.description,
    DateTime : moment(item?.scheduledDate).format("MMMM Do YYYY, h:mm:ss a"),
    Status : item?.broadcast,
   
  }));

  // return table
  return (
    <div className="">
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminUserDetail
        activeindex={activeindex}
        id={notification[activeindex]?._id}
        
        data={notification[activeindex]} 
        onClose={toggleDrawer} />
      </Drawer>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={notification}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalusercount}
          paginationMode="server"
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>
    </div>
  );
};
