import { HelpCenterOutlined } from "@mui/icons-material";
import { Button, Fade, Modal } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import { FormInputB } from "../../../../components/Index";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  adminCreateFaqAction,
  adminGetAllFaq,
  adminEditFaqAction
} from "../../../../context/actions/admin/faqAction";
import { clearError } from "../../../../context/reducers/adminSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const AdminFaqs = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setIsEdit(false);
  };
  const handleClose = () => setOpen(false);

  // question and answer
  const [question, setQuestion] = React.useState("");
  const [answer, setAnswer] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  //  list of questions and answers
  const [faq, setFaqs] = React.useState([]);
  const { faqs, adminCreateFaqLoading, error, adminUpdateFaqLoading } =
    useSelector((state) => ({
      faqs: state.admin_reducer.faqs,
      adminCreateFaqLoading: state.admin_reducer.adminCreateFaqLoading,
      adminUpdateFaqLoading: state.admin_reducer.adminUpdateFaqLoading,
      error: state.admin_reducer.error,
    }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  React.useEffect(() => {
    setFaqs(faqs);
  }, [faqs]);

  // on submit
  const handleSubmit = (e) => {
    if (isEdit) {
    
    const data = new FormData();
    data.append("title", question);
    data.append("description", answer);
    dispatch(adminEditFaqAction({ data, toast, id: faq[index]._id, index:index }));
      setQuestion("");
      setAnswer("");
      handleClose();
    } else {
      const data = new FormData();
      data.append("title", question);
      data.append("description", answer);
      dispatch(adminCreateFaqAction({ data, toast }));
      setQuestion("");
      setAnswer("");
      handleClose();
    }
  };
  

  // on edit
  const handleEdit = (item, index) => {
    setIndex(index);
    setIsEdit(true);
    setQuestion(item.title);
    setAnswer(item.description);
    setOpen(true);
  };

  const handleCloseModal = (e) => {
    setOpen(false);
  };

  React.useEffect(() => {
    dispatch(adminGetAllFaq({}));
  }, []);

  

  return (
    <div className="container mt-2">
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <div className="d-flex flex-column">
          <span className="fs-3 fw-bold text-black">
            FAQ's & Questions with Answers <HelpCenterOutlined />
          </span>
          <small>
            Frequently asked questions and answers for all kinds of queries
          </small>
        </div>
        <div>
          <Button variant="outlined" onClick={handleOpen}>
            ADD NEW FAQ
          </Button>
        </div>
      </Stack>
      <hr />

      {faq &&
        faq?.map((faq, index) => (
          <div className="mt-2">
            <div class="accordion" id="accordionPanelsStayOpenExample">
              <div class="accordion-item">
                <h2
                  class="accordion-header d-flex justify-content-between"
                  id={`heading${index}`}
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target={`#panelsStayOpen-collapse${index}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-collapse${index}`}
                  >
                    {faq?.title}
                  </button>
                  <button
                    className="btn btn-sm shadow-0"
                    onClick={() => handleEdit(faq, index)}
                  >
                    edit
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-collapse${index}`}
                  class="accordion-collapse collapse "
                  aria-labelledby={`heading${index}`}
                >
                  <div class="accordion-body">{faq?.description}</div>
                </div>
              </div>
            </div>
          </div>
        ))}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <span className="fs-5 fw-bold text-black">Adding new FAQ</span>
                <small>
                  Add new FAQ's and answers for all kinds of queries
                </small>
              </div>
              <Box
                className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50px",
                }}
                role="button"
                onClick={() => handleCloseModal()}
              >
                <ImCross
                  style={{
                    fontSize: "1.3em",
                  }}
                />
              </Box>
            </div>
            <hr />
            <FormInputB
              name={"eventTitle"}
              handleChange={(e) => setQuestion(e.target.value)}
              label={"New FAQ's Question"}
              required={true}
              classname={"form-control"}
              placeholder={"Enter Question"}
              value={question}
            />
            <small>Answer for the above question</small>
            <textarea
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              rows="4"
              placeholder="Explain in detail your answers"
            />

            <button
              className="btn btn-outline-black w-100 mt-2"
              onClick={handleSubmit}
            >
              Add FAQ
            </button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AdminFaqs;
