import React from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import LocalFloristIcon from "@mui/icons-material/LocalFlorist";
import { Box } from "@mui/material";

const list = [
  {
    id: "1",
    name: "Wifi",
    icon: <WifiIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "2",
    name: "Kitchen",
    icon: <RestaurantIcon style={{ fontSize: "3em" }} />,
  },

  {
    id: "3",
    name: "Garden",
    icon: <LocalFloristIcon style={{ fontSize: "3em" }} />,
  },
];
const OfferCard = ({ name }) => {
  const icon = list.find((item) => name === item.name)?.icon;

  return (
    <Box
      sx={{
        height: { xm: "100px", lg: "100px" },
        width: "200px",
      }}
      role="button"
      className={
        "d-flex flex-column position-relative  text-lg-start border border-2 rounded rounded-3 justify-content-center align-items-start text-center p-2"
      }
    >
      <div className="">{icon}</div>
      <p>{name}</p>
    </Box>
  );
};

const RentalOfferings = ({ data }) => {
  return (
    <Box
      sx={{
        minHeight: "60vh",
      }}
      className="d-flex flex-column gap-3"
    >
      <section className=" row-cols-1 row gy-2">
        <span className="fs-6 text-black fw-bold">Bathroom</span>
        <span>{data.bathroom}</span>
        <span className="fs-6 text-black fw-bold">kitchen</span>
        <span>{data.kitchen}</span>
        <span className="fs-6 text-black fw-bold">Bedrooms</span>
        <span>{data.bedroom}</span>
      </section>

      <section>
      <p className="fw-bold fs-5 mb-2">Basic offerings</p>

        <div className="d-flex flex-wrap gap-2">
          {data.offers.map((item) => {
            return <OfferCard name={item} key={item} />;
          })}
        </div>
      </section>
    </Box>
  );
};

export default RentalOfferings;
