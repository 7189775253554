 import React from "react";

const FormTextAreaB = ({
  label,
  name,
  placeholder,
  maxlength,
  handleformvalue,
  helpertext,
  error,
  required,
  value,
}) => {
  return (
    <div className="form-group">
      <label className="form-label m-0 pb-1" htmlFor="textAreaExample">
        {label}
      </label>
      <textarea
        className={error ? "form-control is-invalid" : "form-control"}
        id="textAreaExample"
        rows="4"
        maxLength={maxlength}
        data-mdb-showcounter="true"
        placeholder={placeholder}
        onChange={handleformvalue}
        name={name}
        required={required}
        type="text"
        value={value}
      ></textarea>
      <div className="d-flex justify-content-between align-content-center">
        <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
          {helpertext}
        </small>
        <span
          className={
            value?.length === maxlength
              ? "form-helper text-end text-danger ms-auto "
              : "form-helper text-end ms-auto"
          }
        >
          {value?.length} / {maxlength}
        </span>
      </div>
    </div>
  );
};

export default FormTextAreaB;
