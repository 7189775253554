import React from "react";
import { Box } from "@mui/material";
import province from "../../utils/all_province";

const SelectProvince = React.memo(
  ({ value, name, handleChange, placeholder, error, helpertext }) => {
    return (
      <Box className="w-100 form-group mt-2 w-100">
        <small id="demo-simple-select-helper-label2">
          Province
        </small>
        <select
          name={name}
          onChange={handleChange}
          id={name}
          required={true}
          placeholder={placeholder}
          className={
            error
              ? "form-select-lg form-select is-invalid py-2 m-0 rounded rounded-1"
              : "form-select-lg form-select py-2 rounded rounded-1"
          }
          aria-label="Default select example"
          value={value}
        >
          <option value="">Select Province</option>
          {province.map((province) => {
            return (
              <option value={province} key={province}>
                {province}
              </option>
            );
          })}
        </select>
        <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
          {helpertext}
        </small>
      </Box>
    );
  }
);

export default SelectProvince;
