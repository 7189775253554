import React from "react";
import BedIcon from "@mui/icons-material/Bed";
import WifiIcon from "@mui/icons-material/Wifi";
import ShowerIcon from "@mui/icons-material/Shower";
import { FaParking } from "react-icons/fa";
import { Box } from "@mui/material";
import { HiOutlineUserGroup } from "react-icons/hi";
import PropertyPreferenceCard from "../components/PropertyPreferenceCard";
const PropertyPreferences = ({ values, handleFormValue }) => {
  const preferencelist = [
    {
      id: "preferencelist1",
      value: values.roomFurnishings,
      options: ["Not Required", "Required"],
      label: "Room Furnishings",
      icon: (
        <BedIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "roomFurnishings",
      placeholder: "Select RoomFurnishings",
    },

    {
      id: "preferencelist2",
      value: values.internet,
      options: ["Not Required", "Required"],
      label: "Internet",
      icon: (
        <WifiIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "internet",
      placeholder: "Select Require Status",
    },
    {
      id: "preferencelist3",
      value: values.bathRoomType,
      options: ["Flexible", "Not Flexible"],
      label: "BathRoom Type",
      icon: (
        <ShowerIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "bathRoomType",
      placeholder: "Select Flexible Status",
    },
    {
      id: "preferencelist4",
      value: values.parking,
      options: ["Flexible", "Not Flexible"],

      label: "Parking",
      icon: (
        <FaParking
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "parking",
      placeholder: "Select Flexible Status",
    },
    {
      id: "preferencelist5",
      value: values.totalFlateMates,
      options: [1, 2, 3, 4, 5],
      label: "Maximum no. of faltemates",
      icon: (
        <HiOutlineUserGroup
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "totalFlateMates",
      placeholder: "Select No. of FlateMates",
    },
  ];
  return (
    <div className="mt-3">
       <div>
        <h4 className="text-black fw-bold p-0 m-0">
          Property Preferences
        </h4>
        <small>
          What are your preferences for the property?
        </small>
      </div>
      <div className="row row-cols-md-2 row-cols-1 ">
        {preferencelist.map((item, index) => {
          return (
            <Box className="pe-5 py-2" key={item.id}>
              <PropertyPreferenceCard
                {...item}
                handleChange={handleFormValue}
              />
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default PropertyPreferences;
