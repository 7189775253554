import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Chip, Divider, Rating, Typography } from "@mui/material";
import React from "react";
import formatViewCount from "../../../utils/formatViewCount";

import { LocationOn } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { post_bookmark } from "../../../context/actions/authAction";

const BusinessCard = React.memo(({ index, business }) => {
  const dispatch = useDispatch();
  const {
    introduction,
    viewCount,
    contactAndLinks,
    images,
    averageRating,
    totalReview,
    offers,
  } = business;
  const [liked, setliked] = React.useState(false);
  const [type, setType] = React.useState("Business");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
    category: "",
    location: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark
  React.useEffect(() => {
    setBookmark({
      name: introduction?.businessName,
      type: type,
      image: images[0],
      category: introduction?.category,
      location: contactAndLinks?.location?.location_place_name,
    });
  }, [introduction, type, images, contactAndLinks]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === business?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, business?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: business?._id, data: bookmark }));
    }
  };
  const navigate = useNavigate("");
  return (
    <Box
      className="shadow-4 rounded rounded-1 col position-relative custom-card"
      sx={{
        aspectRatio: "1/1.3",
        "&:hover": {
          " .hover-link": {
            display: "block",
            height: "100%",
            width: "100%",
            zIndex: "1",
          },
        },
      }}
    >
      <Link
        to={`/business/${business._id}`}
        className=" position-absolute top-0 start-0  hover-link"
      ></Link>
      <Box
        id={`businessCarasol${index}`}
        className="carousel slide"
        data-mdb-interval="false"
        sx={{
          height: "45%",
        }}
      >
        <div className="carousel-inner h-100 rounded-top">
          {images.map((image, index) => {
            return (
              <div
                key={index}
                className={`carousel-item borde ${
                  index === 0 ? "active" : ""
                } h-100`}
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${image}`}
                  className="d-block w-100 h-100 object-cover"
                  alt="Wild Landscape"
                  loading="lazy"
                />
              </div>
            );
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-mdb-target={`#businessCarasol${index}`}
          data-mdb-slide="prev"
        >
          <span className="" aria-hidden="true">
            <KeyboardArrowLeftIcon />
          </span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-mdb-target={`#businessCarasol${index}`}
          data-mdb-slide="next"
        >
          <span className="" aria-hidden="true">
            <KeyboardArrowRightIcon />
          </span>
          <span className="visually-hidden">Next</span>
        </button>
      </Box>

      <div className="m-1 p-1 m-sm-2 p-sm-2">
        <div>
          <div className="d-flex">
            <Rating
              size="small"
              name="half-rating-read"
              value={averageRating}
              precision={0.5}
              readOnly
            />
            <Typography
            className="text-black"
              sx={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                marginLeft: "0.2rem",
              }}
            >
              {averageRating} ({totalReview || 0})
            </Typography>
          </div>
        </div>

        {/* <h5 className="text-black">{introduction.businessName}</h5> */}
        <Typography
          variant="span"
          className="text-black p-1 fw-semibold pt-2 title"
        >
          {introduction.businessName}
        </Typography>

        <Box
          sx={{
            fontSize: { xs: "0.6em", md: "0.9rem" },
          }}
          className="d-flex align-items-center gap-1"
        >
          <LocationOn
            fontSize="small"
            sx={{
              fontSize: "inherit",
            }}
          />
          <Typography
            sx={{
              marginLeft: "0.2rem",
              fontSize: "inherit",
            }}
            className="text-overflow-hidden text-black "
          >
            {contactAndLinks.location?.location_place_name}
          </Typography>
        </Box>

        <Typography
        className="text-black "
          sx={{
            fontSize: { xs: "0.6em", md: "0.9rem" },
            
            fontWeight: "bold",
            marginTop: "0.5rem",
          }}
        >
          We offers,
        </Typography>

        <Box
          sx={{
            height: { xs: "30px", md: "70px" },
          }}
          className="d-flex gap-1 flex-wrap  overflow-hidden"
        >
          {offers.slice(0, 4)?.map((offer, index) => {
            return (
              <Chip
                key={index}
                size="small"
                label={offer}
                sx={{
                  backgroundColor: "#F2F2F2",
                  color: "#000000",
                  borderRadius: "5px",
                  fontSize: { xs: "0.6em", md: "0.9rem" },
                  marginTop: "0.5rem",
                }}
              />
            );
          })}
        </Box>
        <Divider className="my-2" />
        <Box
          sx={{
            fontSize: { xs: "0.6em", md: "0.9rem" },
          }}
          className="d-flex justify-content-between mb-2"
        >
          <Typography
            variant="span"
            sx={{
              fontSize: { xs: "0.6em", md: "0.7rem" },
            }}
            className="btn btn-sm btn-outline-black  rounded d-flex align-items-center justify-content-center"
          >
            {introduction.category}
          </Typography>

          <Box className="org-two_interaction d-flex gap-2 align-items-center">
            <Box
              sx={{
                zIndex: "2",
              }}
              className="d-flex text-black"
              onClick={togglelike}
            >
              <FavoriteIcon
                sx={{
                  color: liked ? "#ea3a3ad3" : "#C1C1C1",
                  fontSize: { xs: "1.5em", md: "1.5rem" },
                }}
              />
            </Box>
            <div className="d-flex align-items-center gap-1">
              <RemoveRedEyeIcon
                sx={{
                  color: "#C1C1C1",
                  fontSize: { xs: "1.5em", md: "1.5rem" },
                }}
              />
              <Typography
                sx={{
                  fontSize: { xs: "1.3em", md: "1.3rem" },
                }}
                variant="span"
                className="text-black"
              >
                {formatViewCount(business.viewCount)}
              </Typography>
            </div>
          </Box>
        </Box>

        {!liked ? (
          <FavoriteBorderIcon
            className="d-none align-items-center justify-content-center likeicon "
            sx={{
              position: "absolute",
              top: "2%",
              right: "2.5%",
              color: "white",
              backgroundColor: "rgba(0, 0, 0, 0.35)",
              borderRadius: "50px",
              padding: "2%",
              width: "35px",
              height: "35px",
              textAlign: "center",
              fontSize: "0.5rem",
              margin: "auto",
              zIndex: "3",
              backdropFilter: "blur(25px)",
              transition: " 1s display ",
            }}
            onClick={togglelike}
          />
        ) : (
          <FavoriteIcon
            className="d-none align-items-center justify-content-center likeicon"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2.5%",
              color: "#ea3a3ad3",
              backgroundColor: "rgba(0, 0, 0, 0.35)",
              borderRadius: "50px",
              padding: "2%",
              width: "35px",
              height: "35px",
              textAlign: "center",
              fontSize: "0.5rem",
              margin: "auto",
              zIndex: "3",
              transition: " 1s display ",
            }}
            onClick={togglelike}
          />
        )}
      </div>
    </Box>
  );
});

export default BusinessCard;
