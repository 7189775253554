import React, { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { offerslist } from "../../../utils/rentSettings";

const PropertyCard = ({ item, offers, handleFormValue }) => {
  const { icon, name } = item;
  const selected = React.useMemo(
    () => offers.some((value) => value === name),
    [name, offers]
  );

  const addOffer = () => {
    const newoffers = [...offers];
    if (selected) {
      const index = newoffers.indexOf(name);
      if (index > -1) {
        newoffers.splice(index, 1);
      }
    } else {
      newoffers.push(name);
    }
    handleFormValue("offers", newoffers);
  };

  return (
    <Box
      onClick={addOffer}
      sx={{
        height: { xm: "50px", lg: "50px" },
        width: "150px",
      }}
      role="button"
      className={
        selected
          ? "d-flex rounded rounded-3 flex-column position-relative  border-dark text-lg-start border border-2 justify-content-center align-items-start text-center p-2"
          : "d-flex rounded rounded-3 flex-column position-relative  text-lg-start border border-2 justify-content-center align-items-start text-center p-2"
      }
    >
      {/* <div className="">{icon}</div> */}
      <p>{name}</p>

      <CheckCircleOutlineIcon
        className={selected ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "2%",
        }}
      />
    </Box>
  );
};

const PropertyOffer = ({
  offers,
  handleFormValue,
  bedroom,
  kitchen,
  bathroom,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showAllOffers, setShowAllOffers] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setShowAllOffers(false);
  };

  const filteredOffers = offerslist.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mt-3">
      <p className="text-black fs-4 fw-bold my-0">
        Tell us more About Your Property
      </p>

      <Box
        className="d-flex align-items-center justify-content-between"
        sx={{
          width: { sm: "90%", lg: "50%" },
        }}
      >
        <p className="fs-bold">Available Bedrooms :</p>
        <div className="d-flex gap-4 align-items-center">
          <RemoveIcon
            className="bg-white text-black rounded border border-3 rounded-circle p-1"
            sx={{ width: "35px", height: "35px" }}
            role="button"
            onClick={() => handleFormValue("minusbedroom", bedroom)}
          />

          <Typography
            variant="p"
            className="fw-bold text-center"
            sx={{
              minWidth: "10px",
            }}
          >
            {bedroom}
          </Typography>

          <AddIcon
            role="button"
            className="bg-white text-black border border-3 rounded rounded-circle p-2"
            sx={{ width: "35px", height: "35px" }}
            onClick={() => handleFormValue("addbedroom", bedroom)}
          />
        </div>
      </Box>

      <Box
        className="d-flex align-items-center justify-content-between mt-3"
        sx={{
          width: { sm: "90%", lg: "50%" },
        }}
      >
        <p className="fs-bold">Available Kitchen :</p>
        <div className="d-flex gap-4 align-items-center">
          <RemoveIcon
            className="bg-white text-black rounded border border-3 rounded-circle p-2"
            sx={{ width: "35px", height: "35px" }}
            role="button"
            onClick={() => handleFormValue("minuskitchen", kitchen)}
          />

          <Typography
            variant="p"
            className="fw-bold text-center"
            sx={{
              minWidth: "10px",
            }}
          >
            {kitchen}
          </Typography>

          <AddIcon
            role="button"
            className="bg-white text-black border border-3 rounded rounded-circle p-2"
            sx={{ width: "35px", height: "35px" }}
            onClick={() => handleFormValue("addkitchen", kitchen)}
          />
        </div>
      </Box>

      <Box
        className="d-flex align-items-center justify-content-between mt-3"
        sx={{
          width: { sm: "90%", lg: "50%" },
        }}
      >
        <p className="fs-bold">Available Bathrooms :</p>
        <div className="d-flex gap-4 align-items-center">
          <RemoveIcon
            className="bg-white text-black rounded border border-3 rounded-circle p-2"
            sx={{ width: "35px", height: "35px" }}
            role="button"
            onClick={() => handleFormValue("minusbathroom", bathroom)}
          />

          <Typography
            variant="p"
            className="fw-bold text-center"
            sx={{
              minWidth: "10px",
            }}
          >
            {bathroom}
          </Typography>

          <AddIcon
            role="button"
            className="bg-white text-black border border-3 rounded rounded-circle p-2"
            sx={{ width: "35px", height: "35px" }}
            onClick={() => handleFormValue("addbathroom", bathroom)}
          />
        </div>
      </Box>

      <div className="mt-3">
        <div className="d-flex gap-2">
          <h5 className="text-black fw-bold my-0">Basic Amenities Available</h5>
          <input
            className="form-control w-50 ms-3"
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="Search offerings..."
          />
        </div>

        <div className="d-flex flex-wrap gap-2 mt-3">
          {showAllOffers
            ? filteredOffers.map((item) => (
                <PropertyCard
                  item={item}
                  key={item.id}
                  offers={offers}
                  handleFormValue={handleFormValue}
                />
              ))
            : filteredOffers.slice(0, 15).map((item) => (
                <PropertyCard
                  item={item}
                  key={item.id}
                  offers={offers}
                  handleFormValue={handleFormValue}
                />
              ))}
        </div>

        {!showAllOffers && filteredOffers.length > 15 && (
          <button
            className="btn btn-outline-black mt-3"
            onClick={() => setShowAllOffers(true)}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

export default PropertyOffer;
