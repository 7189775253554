import React, { useState } from "react";
import UserEventCard from "./UserEventCard";
import { useDispatch, useSelector } from "react-redux";
import { getAllEventAction } from "../../context/actions/eventAction";

const EventsAndGallary = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getAllEventAction({}));
  }, []);

  const {events,totalEventCount,resultsPerPage} = useSelector((state)=>({
    events:state.event_reducer.events,
    totalEventCount:state.event_reducer.totalEventCount,
    resultsPerPage:state.event_reducer.resultsPerPage,

  }))

 


  return (
    <div className="container mt-2">
      <div className="d-flex flex-column mt-3 mb-3">
        <span className="fs-4 fw-bold text-black">
          <span className="text-danger">Events</span> & Gallary
        </span>
        <small>
          Showing {events.length} events on ONF Canada
        </small>
      </div>
      <div class="row row-cols-1 row-cols-md-2 g-4">

        {events && events.map((event,index)=>(
            <div className="col">
            <UserEventCard index={index} event={event} />
            </div>
        ))
        }
      </div>
    </div>
  );
};

export default EventsAndGallary;
