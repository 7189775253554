import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Container } from "@mui/material";
import Modal from "@mui/material/Modal";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { BiPencil } from "react-icons/bi";
import { CgMenuGridR } from "react-icons/cg";
import { GoLocation } from "react-icons/go";
import { ImCross } from "react-icons/im";
import styled from "styled-components";
import { RentalInformation, RentalOfferings } from "./tabs";
import { Drawer } from "@mui/material";
import AdminRentalEdit from "../adminRentalEdit/AdminRentalEdit";
import { pictureModal } from "../../../../../utils/modalSettings";
import { toast } from "react-toastify";
import {adminChangeRentalStatusAction,adminUpdateFeaturedRentalAction} from "../../../../../context/actions/admin/rentalAction"
import { useDispatch } from "react-redux";
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > .grid-images {
    display: grid;
    gap: 5px;
    & > .item:nth-child(1) {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;
    }
  }
`;

const AdminRetnalDetail = ({ data, closeModal, activeindex, id }) => {
  const dispatch = useDispatch();

  const tabs = ["Information", "Offerings"];

  // show more description
  const [open, setOpen] = React.useState(false);

  // tab change
  const [value, setValue] = React.useState("0");

  const [slider, setslider] = React.useState("0");
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  // to open modal
  const handleOpen = () => setOpen(true);

  // to close modal
  const handleClose = () => setOpen(false);

  // on tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // on tab change
  const handleModalSlider = (event, newValue) => {
    setslider(newValue);
  };

  
   const handleActiveStatusFalse = (e) => {
     e.preventDefault();
     dispatch(
       adminChangeRentalStatusAction({
         id: id,
         data: false,
         toast: toast,
         activeindex: activeindex,
       })
     );
   };

    const handleActiveStatusTrue = (e) => {
      e.preventDefault();
      dispatch(
        adminChangeRentalStatusAction({
          id: id,
          data: true,
          toast: toast,
          activeindex: activeindex,
        })
      );
    };


    const featuredTure = (e) => {
      e.preventDefault();
      dispatch(
        adminUpdateFeaturedRentalAction({
          id: id,
          data: true,
          toast,
          activeindex,
        })
      );
    };

    const featuredFalse = (e) => {
      e.preventDefault();
      dispatch(
        adminUpdateFeaturedRentalAction({
          id: id,
          data: false,
          toast,
          activeindex,
        })
      );
    };



  

  return (
    <Container
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="m-3 d-flex flex-column gap-2"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Preview for your listing
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
              role="button"
              onClick={closeModal}
            />
          </Box>
        </div>
        <div className="d-flex w-100 justify-content-between mt-1">
          <div className="d-flex flex-column">
            <span className="fs-2 text-black fw-bold m-0 p-0">
              {data?.title}
            </span>
            <p className="badge badge-light">
              <GoLocation /> {data?.location?.place_name}
            </p>
          </div>

          <div className="rental-actionbtn d-flex gap-2 align items-center h-50 mt-2">
            <button
              type="button"
              className="btn btn-sm btn-outline-dark"
              data-mdb-ripple-color="dark"
              onClick={toggleDrawer}
            >
              <BiPencil /> Edit
            </button>

            {data?.featured ? (
              <button
                type="button"
                className="btn btn-sm btn-primary"
                data-mdb-ripple-color="dark"
                onClick={featuredFalse}
              >
                unfeatured
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-sm btn-outline-primary"
                data-mdb-ripple-color="dark"
                onClick={featuredTure}
              >
                featured
              </button>
            )}
            {data?.active ? (
              <button
                type="button"
                class="btn btn-sm btn-danger"
                data-mdb-ripple-color="dark"
                onClick={handleActiveStatusFalse}
              >
                Deactive
              </button>
            ) : (
              <button
                type="button"
                class="btn btn-sm btn-success"
                data-mdb-ripple-color="dark"
                onClick={handleActiveStatusTrue}
              >
                Active
              </button>
            )}
          </div>
        </div>
      </section>

      <Wrapper>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gridAutoRows: { xs: "140px", md: "170px", lg: "200px" },
            borderRadius: "25px",
          }}
          className="grid-images position-relative rounded rounded-3 overflow-hidden"
        >
          {data.images.slice(0, 5).map((item, index) => {
            return (
              <div className="w-100 h-100 item" key={index}>
                <img
                  // src="https://images.pexels.com/photos/463734/pexels-photo-463734.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                  // src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                  alt=""
                  className="w-100 object-cover h-100"
                />
              </div>
            );
          })}
          {/* open modal button */}
          <Box
            sx={{
              position: "absolute",
              bottom: "3%",
              right: "1%",
            }}
            className="btn d-flex gap-2 align-items-center bg-white"
            onClick={handleOpen}
          >
            <CgMenuGridR className="position bottom-0" />
            <span>Show all photos</span>3
          </Box>
        </Box>
      </Wrapper>
      <section className=" mt-2">
        <Tabs
          onChange={handleTabChange}
          aria-label="lab API tabs example"
          sx={{
            background: "#F6F6F6",
          }}
          value={value}
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                label={item}
                value={index}
                key={index}
                className="text-capitalize  "
                sx={{
                  "&.Mui-selected": {
                    color: "#615a5a",
                    fontWeight: "bolder",
                    border: "",
                  },

                  // "&.MuiTabs-indicator": {
                  //   display: "none",
                  // },
                }}
              />
            );
          })}
        </Tabs>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value.toString()}>
            <Box className=""></Box>

            <TabPanel value="0">
              <RentalInformation data={data} />
            </TabPanel>
            <TabPanel value="1">
              <RentalOfferings data={data} />
            </TabPanel>
          </TabContext>
        </Box>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="bg-transparent"
      >
        <Box sx={pictureModal} className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            role="button"
            onClick={handleClose}
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <TabContext value={slider}>
            {data.images.map((item, index) => {
              const indexs = index.toString();
              return (
                <TabPanel
                  value={indexs}
                  key={index}
                  sx={{
                    Height: "80vh",
                  }}
                >
                  <div className="w-100 h-100 overflow-hidden d-flex justify-content-center">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                      alt=""
                      srcSet=""
                      className=" object-contain"
                      height={480}
                    />
                  </div>
                </TabPanel>
              );
            })}
            <Tabs
              onChange={handleModalSlider}
              className=" mx-auto   "
              sx={{
                maxWidth: "100%",

                width: "max-content",
                "&.Mui-selected": {
                  color: "#797979",
                  fontWeight: "bolder",
                  border: "none",
                },
              }}
              centered
              indicatorcolor={""}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              value={slider}
            >
              {data.images.map((item, index) => {
                const indexs = index.toString();

                return (
                  <Tab
                    key={index}
                    value={indexs}
                    className="p-0 m-0"
                    role="button"
                    sx={{
                      "&.Mui-selected": {
                        transform: "scale(1.5)",
                        borderBottom: "none",
                        zIndex: 20,
                      },
                    }}
                    icon={
                      <Box
                        className="w-100 p-1 d-flex align-item-center "
                        sx={{
                          height: 70,
                          "&.Mui-selected": {
                            borderBottom: "none",
                          },
                        }}
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_PATH}${item}`}
                          alt=""
                          srcSet=""
                          height={60}
                          widht={90}
                          className="object-cover w-100 mx-auto p-0 "
                        />
                      </Box>
                    }
                  />
                );
              })}
            </Tabs>
          </TabContext>
        </Box>
      </Modal>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminRentalEdit
          data={data}
          closeModal={toggleDrawer}
          activeindex={activeindex}
          id={id}
        />
      </Drawer>
    </Container>
  );
};

export default AdminRetnalDetail;
