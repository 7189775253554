import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import { adminEditBusinessAction } from "../../../../../context/actions/admin/businessAction";
import { ImCross } from "react-icons/im";

import {
  EditBusinessIntroduction,
  EditBusinessContactAndLinks,
  EditBusinessImageInput,
  EditBusinessOwners,
} from "./components";
import { Box, Container } from "@mui/material";
import { clearError } from "../../../../../context/reducers/adminSlice";
const EditBusiness = ({ data, closeModal, activeindex, id }) => {
  console.log(data);
  const intro = {
    businessName: data?.introduction?.businessName,
    description: data?.introduction?.description,
    ownerName: data?.introduction?.ownerName,
    openingHour: data?.introduction?.openingHour,
    closingHour: data?.introduction?.closingHour,
    proveniece: data?.introduction?.provenience,
    city: data?.introduction?.city,
    establishedDate: data?.introduction?.establishedDate.split("T")[0],
    category: data?.introduction?.category,
    weoffer: [],
    offers: data?.offers,
  };

  const contacts = {
    email: data?.contactAndLinks.email,
    telephone: data?.contactAndLinks.telephone,
    phone: data?.contactAndLinks.phone,
    websiteLink: data?.contactAndLinks.websiteLink,
    facebookLink: data?.contactAndLinks.facebookLink,
    instagramLink: data?.contactAndLinks.instagramLink,
    twitterLink: data?.contactAndLinks.twitterLink,
    location: data?.contactAndLinks.location,
  };

  const [introduction, setIntroduction] = React.useState(intro);
  const [contactAndLinks, setContactLinks] = React.useState(contacts);
  const [Owners, setOwners] = React.useState(data?.Owner);
  // all the uploaded images are in images state
  const [images, setImages] = React.useState({
    files: [],
    pImages: data.images,
    nImages: data.images,
  });
  const [errors, setErrors] = React.useState({
    description: "",
  });
  const dispatch = useDispatch();

  // get the loading and error from the admin reducer
  const { adminBusinessEditLoading, error } = useSelector((state) => ({
    error: state.admin_reducer.error,
    adminBusinessEditLoading: state.admin_reducer.adminBusinessEditLoading,
  }));

  React.useEffect(() => {
    if (error) {
      toast.error(error);
    }
    dispatch(clearError());
  }, [error]);

  // handle introduction form values
  const handleintroduction = (e) => {
    const name = e.target.name;
    switch (name) {
      case "proveniece":
        setIntroduction({
          ...introduction,
          proveniece: e.target.value,
          city: "",
        });

        break;
      case "weoffer":
        const newoffer = e.target.value;
        const updateoffer = [...introduction.weoffer].push(newoffer);
        setIntroduction({ ...introduction, weoffer: updateoffer });
        break;
      default:
        setIntroduction({ ...introduction, [e.target.name]: e.target.value });
    }
  };

  const addoffer = (value) => {
    setIntroduction({
      ...introduction,
      offers: value,
    });
  };

  // handle image upload and remove images
  const handleimages = React.useCallback(
    ({ files, pImages }) => {
      setImages({ ...images, files: files, pImages: pImages });
    },
    [images]
  );

  // handle image upload and remove images
  const removeImages = React.useCallback(
    ({ files, pImages, nImages }) => {
      setImages({
        ...images,
        // send to api
        files: files,
        pImages: pImages,
        // send to api
        nImages: nImages,
      });
    },
    [images]
  );

  // on province select
  const onprovinceselect = (value) => {
    setIntroduction({ ...introduction, proveniece: value, city: "" });
  };

  // on city select
  const oncityselect = (value) => {
    setIntroduction({ ...introduction, city: value });
  };

  // handle contactAnd Links
  const handleContactAndlinks = (e) => {
    if (e.target.name === "telephone") {
      const isNumber = /^\d+$/.test(e.target.value);
      if (isNumber || !e.target.value === "" || !e.target.value === "e") {
        setContactLinks({
          ...contactAndLinks,
          [e.target.name]: parseInt(e.target.value),
        });
      }
    }
    if (e.target.name === "phoneNumber") {
      const isNumber = /^[0-9]+$/.test(e.target.value);
      const sanitizedValue = e.target.value.replace(/e/gi, "");
      if (isNumber || !e.target.value === "") {
        setContactLinks({
          ...contactAndLinks,
          [e.target.name]: parseInt(sanitizedValue),
        });
      }
    }
    setContactLinks({ ...contactAndLinks, [e.target.name]: e.target.value });
  };

  // on location set
  const handlelocation = (location) => {
    setContactLinks((prevValue) => ({
      ...prevValue,
      location: {
        lat: location.geometry.coordinates[0],
        lng: location.geometry.coordinates[1],
        location_place_name: location.place_name,
      },
    }));
  };

  const validateIntroductionForm = () => {
    const errors = {};
    let formIsValid = true;

    if (!introduction.description) {
      formIsValid = false;
      errors.description = "Description is required *";
    }
    if (!introduction.ownerName) {
      formIsValid = false;
      errors.ownerName = "Owner Name is required *";
    }

    if (!introduction.businessName) {
      formIsValid = false;
      errors.businessName = "BusinessName is required *";
    }
    if (!introduction.establishedDate) {
      formIsValid = false;
      errors.establishedDate = "Established Date is required *";
    }
    if (!introduction.openingHour) {
      formIsValid = false;
      errors.openingHour = "Opening Hour is  required *";
    }
    if (!introduction.closingHour) {
      formIsValid = false;
      errors.closingHour = "Closing Hour is  required *";
    }

    if (!introduction.proveniece) {
      formIsValid = false;
      errors.proveniece = "Provenice is  required *";
    }
    if (!introduction.city) {
      formIsValid = false;
      errors.city = "City is  required *";
    }

    if (!introduction.category) {
      formIsValid = false;
      errors.category = "Category is required *";
    }

    if (
      typeof contactAndLinks.email !== "undefined" &&
      contactAndLinks.email !== null
    ) {
      var pattern = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!pattern.test(contactAndLinks.email)) {
        formIsValid = false;
        errors.email = "Please enter valid email-ID *";
      }
    }

    if (!contactAndLinks.email) {
      formIsValid = false;
      errors.email = "Email is required *";
    }

    if (!contactAndLinks.telephone) {
      formIsValid = false;
      errors.telephone = "Telephone Number is required *";
    }

    if (!contactAndLinks.phone) {
      formIsValid = false;
      errors.phone = "Phone Number is required *";
    }

    if (contactAndLinks.location.length === 0) {
      formIsValid = false;
      errors.location = "Location is required *";
    }

    if (images.pImages.length < 5) {
      formIsValid = false;

      errors.images = "At least 5 images should be selected";
    }
    if (images.pImages.length > 10) {
      formIsValid = false;

      errors.images = "Max 10 image allowed";
    }
    if (Owners.length === 0) {
      formIsValid = false;

      errors.owners = "Please add at least one owner *";
    }

    setErrors(errors);
    return formIsValid;
  };
  const onownerchange = (newlist) => {
    const newlst = [...newlist];
    setOwners(newlst);
  };

  const onError = ({ name, msg }) => {
    setErrors({ ...errors, [name]: msg });
  };

  // (submit form)
  const handleSubmit = (e) => {
    e.preventDefault();
    let isValid = validateIntroductionForm();
    if (isValid) {
      const formData = new FormData();
      // append the images to the images list and send it to the server
      for (let i = 0; i < images.files.length; i++) {
        formData.append("images", images.files[i]);
      }

      for (let i = 0; i < images.nImages.length; i++) {
        formData.append("nImages", images.nImages[i]);
      }
      formData.append("businessName", introduction.businessName);
      formData.append("ownerName", introduction.ownerName);
      formData.append("openingHour", introduction.openingHour);
      formData.append("closingHour", introduction.closingHour);
      formData.append("description", introduction.description);
      formData.append("establishedDate", introduction.establishedDate);
      formData.append("registerDate", introduction.registerDate);
      formData.append("provenience", introduction.proveniece);
      formData.append("city", introduction.city);
      formData.append("category", introduction.category);
      formData.append("email", contactAndLinks.email);
      formData.append("telephone", contactAndLinks.telephone);
      formData.append("phone", contactAndLinks.phone);
      formData.append("websiteLink", contactAndLinks.websiteLink);
      formData.append("facebookLink", contactAndLinks.facebookLink);
      formData.append("twitterLink", contactAndLinks.twitterLink);
      formData.append("instagramLink", contactAndLinks.instagramLink);
      formData.append("offers", JSON.stringify(introduction.offers));
      formData.append("location_lat", contactAndLinks.location.lat);
      formData.append("location_lng", contactAndLinks.location.lng);
      formData.append(
        "location_place_name",
        contactAndLinks.location.location_place_name
      );
      formData.append("Owner", JSON.stringify(Owners));

      dispatch(
        adminEditBusinessAction({
          formData: formData,
          id: id,
          toast: toast,
          activeindex: activeindex,
        })
      );
    }
  };

  return (
    <Container
      sx={{
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
    >
      <div className="d-flex justify-content-center align-items-center w-100">
        <h1 className="text-center my-2 fw-bold ms-auto">Edit Business</h1>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center justify-self-end ms-auto  p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
            role="button"
            onClick={closeModal}
          />
        </Box>
      </div>
      <section className="Image-Upload px-lg-5 py-lg-4 px-3 py-3 d-flex flex-column gap-2 position-relative">
        <EditBusinessImageInput
          handleimages={handleimages}
          errors={errors.images}
          pImages={images.pImages}
          imageFile={images.files}
          nImages={images.nImages}
          removeImages={removeImages}
          onError={onError}
        />
        <EditBusinessIntroduction
          handleformvalue={handleintroduction}
          handleSubmit={handleSubmit}
          value={introduction}
          errors={errors}
          onprovinceselect={onprovinceselect}
          oncityselect={oncityselect}
          addoffer={addoffer}
        />

        <EditBusinessContactAndLinks
          handleLocation={handlelocation}
          handleSubmit={handleSubmit}
          handleformvalue={handleContactAndlinks}
          errors={errors}
          value={contactAndLinks}
        />

        <EditBusinessOwners
          ownerslength={Owners?.length}
          value={Owners}
          errors={errors.owners}
          handleSubmit={handleSubmit}
          onownerchange={onownerchange}
        />

        <Box
          sx={{
            bottom: 0,
            zIndex: 4,
          }}
          className="position-sticky d-flex justify-content-end gap-1 py-2 align-items-center  start-0 w-100 bg-white "
        >
          {errors.message && (
            <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
              <small>{errors.message}</small>
            </div>
          )}
          <button
            className="btn-dark btn ms-auto w-maxcontent "
            onClick={handleSubmit}
          >
            Save
          </button>
        </Box>
      </section>
    </Container>
  );
};

export default EditBusiness;
