import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, FormHelperText } from "@mui/material";
import countries from "../../utils/all_country";

export default function InputCountry({
  name,
  label,

  handleChange,
  require,
  selectedOptions,
  error,
  helperText,
}) {
  return (
    <>
      <Autocomplete
        name={name}
        placeholder="Error"
        value={selectedOptions}
        required={require}
        id="country-select-demo"
        options={countries}
        onChange={handleChange}
        autoHighlight
        getOptionLabel={(option) => option || ""}
        renderInput={(params) => (
          <TextField
            error={error}
            helperText={helperText}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",

              // disable autocomplete and autofill
            }}
          />
        )}
      />
    </>
  );
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
