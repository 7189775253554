import React from "react";

import { ImCross } from "react-icons/im";
import { useDispatch } from "react-redux";
import { userEditProfessionalAction } from "../../../../../context/actions/authAction";
import {
  ProfessionalContactAndLinks,
  ProfessionalDescription,
  ProfessionalInfo,
  ProfessionalType,
} from "../../../../addProfessional/formsteppers/index";

import { Box, Container } from "@mui/material";
import { toast } from "react-toastify";
const EditProfessional = ({ data, onClose }) => {
  const formValues = {
    firstName: data.firstName,
    lastName: data.lastName,
    profession: data.profession,
    userPic: `${process.env.REACT_APP_IMAGE_PATH}${data.userPic}`,
    userPicFile: "",
    proveniece: data.provenience,
    city: data.city,
    email: data.email,
    gender: data.gender,
    age: data.age,
    offers: data.offers,
    description: data.description,
    personalLink: data?.socialMediaLinks?.personalLink,
    facebookLink: data?.socialMediaLinks?.facebookLink,
    instagramLink: data?.socialMediaLinks?.instagramLink,
    twitterLink: data?.socialMediaLinks?.twitterLink,
  };
  const formerrors = {
    profession: "",
    userPic: "",
    city: "",
    proveniece: "",
    email: "",
    gender: "",
    age: "",
    offers: "",
    description: "",
    agreement: "",
  };

  // all the value except images contains in formvalue state
  const [formvalue, setformvalues] = React.useState(formValues);

  const [agreements, setAgreements] = React.useState(false);
  // all the errors are in errors state
  const [errors, setErrors] = React.useState(formerrors);
  // on back button click

  // change value base on e.target.name
  const handleFormChange = (e) => {
    const name = e.target.name;
    switch (name) {
      case "userPic":
        const file = e.target.files[0];
        let blobURL = URL.createObjectURL(file);
        e.target.value = "";

        setformvalues({
          ...formvalue,
          userPic: blobURL,
          userPicFile: file,
        });
        break;
      case "firstName":
        setformvalues({
          ...formvalue,
          [e.target.name]: e.target.value.trim(),
        });

        break;
      case "lastName":
        setformvalues({
          ...formvalue,
          [e.target.name]: e.target.value.trim(),
        });

        break;
      default:
        setformvalues({ ...formvalue, [e.target.name]: e.target.value });

        break;
    }
  };

  // handles PhoneNumber
  const handlePhoneNumber = (phone) => [
    setformvalues({ ...formvalue, contact: phone }),
  ];

  // formvalidation on input change
  const handleFormValue = (name, value) => {
    switch (name) {
      case "addoffer":
        const offer = value.trim();
        if (offer !== "" && !formvalue.offers.includes(offer)) {
          setformvalues({
            ...formvalue,
            offers: [...formvalue.offers, offer],
          });
        }

        break;
      case "removeoffer":
        const lst = formvalue.offers;
        const newlst = lst.filter((item) => item !== value);
        setformvalues({
          ...formvalue,
          offers: newlst,
        });
        break;

      case "agreement":
        if (agreements) {
          setAgreements(false);
        } else {
          setAgreements(true);
        }
        break;
      default:
        setformvalues({ ...formvalue, [name]: value });
    }
  };

  // on province select
  const onprovinceselect = (value) => {
    setformvalues({ ...formvalue, proveniece: value, city: "" });
  };

  // on city select
  const oncityselect = (value) => {
    setformvalues({ ...formvalue, city: value });
  };
  const formvalidation = () => {
    let isValid = false;

    const newerror = { ...formerrors };
    const phoneRegex = /^\d{10}$/;
    const isphone = phoneRegex.test(formvalue.contact);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+(\.[^\s@]+)?$/;

    const isValidEmail = emailRegex.test(formvalue.email);
    if (
      !formvalue.firstName ||
      !formvalue.lastName ||
      !formvalue.gender ||
      !formvalue.proveniece ||
      !formvalue.city ||
      !formvalue.userPic ||
      (formvalue.age && formvalue.age < 18) ||
      !formvalue.age ||
      formvalue.offers.length === 0 ||
      !formvalue.profession ||
      !formvalue.description ||
      !formvalue.contact ||
      !formvalue.email ||
      !isphone ||
      !isValidEmail
    ) {
      if (!formvalue.firstName) {
        newerror.firstName = "Please provide firstName *";
      }

      if (!formvalue.lastName) {
        newerror.lastName = "Please provide lastName *";
      }

      if (!formvalue.proveniece) {
        newerror.proveniece = "Please provide proveniece *";
      }
      if (!formvalue.city) {
        newerror.city = "Please provide city *";
      }
      if (!formvalue.gender) {
        newerror.gender = "Please provide gender *";
      }

      if (!formvalue.age) {
        newerror.age = "Please provide your age *";
      }
      if (formvalue.age && formvalue.age < 18) {
        newerror.age = "Please provide valid age (18 or older) *";
      }
      if (!formvalue.userPic) {
        newerror.userPic = "Please provide Your Image *";
      }

      if (!formvalue.profession) {
        newerror.profession = "Please provide profession *";
      }
      if (formvalue.offers.length === 0) {
        newerror.offers = "Please provide offers *";
      }

      if (!formvalue.description) {
        newerror.description = "Please provide description *";
      }

      if (!formvalue.email) {
        newerror.email = "Please provide email *";
      }
      if (!formvalue.contact) {
        newerror.contact = "Please provide contact *";
      }

      if (!isphone && formvalue.contact) {
        newerror.contact = "please provide valid candian number *";
      }
      if (!isValidEmail && formvalue.email) {
        newerror.email = "please provide valid email";
      }
      newerror.message = "Invalid credential please check again *";
    } else {
      isValid = true;
    }

    setErrors(newerror);

    return isValid;
  };

  const dispatch = useDispatch();

  const onSubmit = () => {
    const isValid = formvalidation();

    if (isValid) {
      const formData = new FormData();
      formData.append("firstName", formvalue.firstName);
      formData.append("lastName", formvalue.lastName);
      formData.append("profession", formvalue.profession);
      formData.append("provenience", formvalue.proveniece);
      formData.append("city", formvalue.city);
      formData.append("email", formvalue.email);
      formData.append("age", formvalue.age);
      formData.append("gender", formvalue.gender);
      formData.append("offers", JSON.stringify(formvalue.offers));
      formData.append("description", formvalue.description);
      formData.append("personalLink", formvalue.personalLink);
      formData.append("facebookLink", formvalue.facebookLink);
      formData.append("instagramLink", formvalue.instagramLink);
      formData.append("twitterLink", formvalue.twitterLink);
      formData.append("images", formvalue.userPicFile);
      formData.append("reviewMessage", '');
      dispatch(
        userEditProfessionalAction({
          formData: formData,
          id: data?._id,
          toast: toast,
        })
      );
    } else {
    }
  };

  return (
    <Container
      sx={{
        paddingTop: "3vh",
        overflow: "hidden",
        overflowY: "scroll",
        height: "80vh",
      }}
      className="h-maxcontent position-relative"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Re-apply application
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <hr />

      <div className="">
        <ProfessionalInfo
          handleFormChange={handleFormChange}
          errors={errors}
          formvalue={formvalue}
          onprovinceselect={onprovinceselect}
          oncityselect={oncityselect}
        />

        <ProfessionalType
          formvalue={formvalue}
          handleFormValue={handleFormValue}
          errors={errors}
        />

        <ProfessionalDescription
          errors={errors.description}
          handleFormValue={handleFormValue}
          formvalue={formvalue.description}
        />

        <ProfessionalContactAndLinks
          errors={errors}
          handleFormValue={handleFormValue}
          handleFormChange={handleFormChange}
          formvalue={formvalue}
        />
      </div>

      <Box
        sx={{
          bottom: 0,
        }}
        className="position-sticky d-flex justify-content-end gap-1 py-2 align-items-center  start-0 w-100 bg-white "
      >
        {errors.message && (
          <div className="bg-danger text-center w-75 rounded rounded-2 text-white fw-bold">
            <small>{errors.message}</small>
          </div>
        )}
        <button
          className="btn-dark btn-sm btn ms-auto w-maxcontent "
          onClick={onSubmit}
        >
          Re-apply
        </button>
      </Box>
    </Container>
  );
};

export default EditProfessional;
