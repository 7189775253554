import TuneIcon from "@mui/icons-material/Tune";
import { Box, Modal } from "@mui/material";
import Chip from "@mui/material/Chip";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Searchbar } from "../../components/Index";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import BusinessCard from "../../components/cards/business_card/BusinessCard";
import { getAllBusinessAction } from "../../context/actions/businessAction";
import BusinessFilterSidebar from "./components/BusinessFilterSidebar";
import BusinessSmFilter from "./components/BusinessSmFilter";
import BusinessCardSkeleton from "./components/BusinessCardSkeleton";

const BusinessFilterPage = () => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: 400 },
    bgcolor: "var(--hover-color)",
    boxShadow: 24,
    p: 4,
    borderRadius: "15px",
  };
  // use dspatch to dispatch action
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const query = queryParams.get("search")?.trim() || "";
  const filter = queryParams.get("province")?.trim() || "";
  const cityfilter = queryParams.get("city")?.trim() || "";
  const categoryfilter = queryParams.get("category")?.trim() || "";

  // intilize the useState property

  const [page, setPage] = React.useState(1);
  const [isOpen, setOpen] = React.useState(false);

  const toggleFilter = () => {
    setOpen((prev) => !prev);
  };

  // intial state of filter options
  const filteroption = {
    sortby: "",
    city: cityfilter?.length > 0 ? cityfilter : "",
    province: filter?.length > 0 ? filter : "",
    category: categoryfilter?.length > 0 ? categoryfilter : "",
    search: query?.length > 0 ? query : "",
  };
  // set the filter option filtered useState
  const [filtered, setfilter] = React.useState(filteroption);

  // use useSelector to get data from store from business_reducer
  const {
    loading,
    business,
    resultperpage,
    totalbusinesscount,
    filteredbusinessCount,
  } = useSelector((state) => state.business_reducer);

  // use useCallback to memoize the function
  useEffect(() => {
    // if error is come from useselector then return null

    dispatch(
      getAllBusinessAction({
        keyword: filtered.search,
        page,
        city: filtered.city,
        province: filtered.province,
        category: filtered.category,
      })
    );
  }, [
    dispatch,
    filtered.search,
    page,
    filtered.city,
    filtered.province,
    filtered.category,
  ]);

  // use useEffect to call the function

  // handle change in city
  const handlecityChange = (e, value) => {
    e.stopPropagation();

    setfilter({ ...filtered, city: value });
    const newQuery = value;
    queryParams.set("city", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  // handle change in category
  const handlecategorychanage = (value) => {
    setfilter({ ...filtered, category: value });
    const newQuery = value;
    queryParams.set("category", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  // handle change in province
  const selectprovince = (item) => {
    setfilter({ ...filtered, province: item });
    const newQuery = item;
    queryParams.set("province", newQuery);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  // clear value from filter
  const clearvalue = (name) => {
    setfilter({ ...filtered, [name]: "" });
  };

  // handle change in searchbar keyword

  // handle keypress in searchbar for seach keyword
  const handlekeypress = (e) => {
    if (e.key === "Enter") {
      setfilter({ ...filtered, search: e.target.value });
      const newQuery = e.target.value;
      queryParams.set("search", newQuery);
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  };

  // handle page change function
  const handlePagechange = (e, value) => {
    setPage(value);
  };


  const onSMfilter = ({ city, province, category }) => {
    setfilter({
      ...filtered,
      province: province,
      city: city,
      category: category,
    });

    queryParams.set("province", province);
    queryParams.set("city", city);
    queryParams.set("category", category);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };
  return (
    <Box>
      <div className="row row-cols-lg-2 container w-100 mx-auto mt-3 ">
        <Box className="filter p-0 col col-lg-3 d-none d-lg-block position-sticky">
          <BusinessFilterSidebar
            filtered={filtered}
            handlecityChange={handlecityChange}
            selectprovince={selectprovince}
            selectcategory={handlecategorychanage}
          />
        </Box>

        <Box className=" d-block d-lg-none ">
          <Modal
            open={isOpen}
            onClose={toggleFilter}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <BusinessSmFilter
                filtered={filtered}
                onClose={toggleFilter}
                onSMfilter={onSMfilter}
              />
            </Box>
          </Modal>
        </Box>
        <section className="col p-0 col-lg-9">
          <p className="fw-bold">Filtering,</p>
          <Box className=" w-100 bg-white d-flex justify-content-between flex-column flex-md-row">
            <div className="col-auto d-flex gap-2 align-items-center">
              {filtered.province.length > 0 ? (
                <Chip
                  label={filtered.province}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("province")}
                />
              ) : (
                <Chip label="All Business" size="small" variant="outlined" />
              )}

              {filtered.city.length > 0 ? (
                <Chip
                  label={filtered.city}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("city")}
                />
              ) : null}

              {filtered.category.length > 0 ? (
                <Chip
                  label={filtered.category}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("category")}
                />
              ) : null}

              {filtered.search.length > 0 ? (
                <Chip
                  label={filtered.search}
                  size="small"
                  variant="outlined"
                  onDelete={() => clearvalue("search")}
                />
              ) : null}
            </div>
            <div className=" ms-md-auto mt-2 mt-md-0 d-flex align-items-center justify-content-between">
              <div className="">
                <Searchbar
                  handlekeypress={handlekeypress}
                  placeholder={"Enter Search"}
                />
              </div>
              <button
                onClick={toggleFilter}
                className="d-flex align-items-center gap-1 d-lg-none btn bg-black text-white ms-auto"
              >
                <TuneIcon /> <span>filter</span>
              </button>
            </div>
          </Box>
          <div className="row row-cols-2 row-cols-md-3 ">
            {
              loading ? (
                <>
                  {Array.from({ length: 6 }, (_, index) => (
                    <BusinessCardSkeleton index={index} />
                  ))}
                </>
              ) : business && business?.length > 0 ? (
                  business?.map((business, index) => (
                    <div key={business._id} className="p-2 col grid-box">
                      <Link to={`/business/${business._id}`}>
                        <BusinessCard index={index} business={business} />
                      </Link>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                    className="d-flex justify-content-center w-100"
                  >
                    <NoResultFound />
                  </div>
                )}
          </div>
          {/* <div ref={sentinelRef} className="sentinel" /> */}

          <Stack spacing={2} className="d-flex w-100 Provenience">
            <Pagination
              count={Math.ceil(totalbusinesscount / resultperpage)}
              page={page}
              onChange={handlePagechange}
              variant="outlined"
              className="ms-auto"
              shape="rounded"
            />
          </Stack>
        </section>
      </div>
    </Box>
  );
};

export default BusinessFilterPage;
