import { Box } from "@mui/material";
import React from "react";
import BookmarkRentalCard from "../cards/BookmarkRentalCard";
const RentalBookmarks = ({ item }) => {
  return (
    <Box
      sx={{
        minHeight: "90vh",
      }}
      className=" container  "
    >
      {item.length === 0 ? (
        <span className=" text-nowrap">No Rental bookmarks</span>
      ) : null}
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 gx-1 gx-sm-2 gx-md-3 gx-lg-4 gy-2 gy-sm-2 gy-md-3 gy-lg-4">
        {item.map((item) => {
          return (
            <div className="col">
              <BookmarkRentalCard rental={item} key={item._id} />
            </div>
          );
        })}
      </div>
    </Box>
  );
};

export default RentalBookmarks;
