import { TabContext, TabList } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React from "react";
import {
  RentalFilterModal,
  FlateMateFilterModal,
  TeamUpFilterModal,
} from "./FilterModal";
const RentalAllFilters = ({
  filterTab,
  onPageTabChange,
  closeModal,
  setisload,
}) => {
  const tabs = ["Rental", "FlateMates", "TeamUp"];
  const [value, setValue] = React.useState(filterTab || "Rental");

  // change tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setValue(filterTab);
  }, [filterTab]);

  return (
    <TabContext
      value={value}
      sx={{
        height: "100%",
      }}
    >
      <Box>
        <TabList
          onChange={handleChange}
          centered
          aria-label="lab API tabs example"
        >
          {tabs.map((item, index) => {
            return (
              <Tab
                label={item}
                key={index}
                value={item}
                indicatorcolor={"transparent"}
                className="text-capitalize"
                sx={{
                  "&.Mui-selected": {
                    color: "#797979",
                    fontWeight: "bolder",
                    border: "none",
                    backgroundColor: "#F6F6F6",
                  },
                  "&.MuiTabs-indicator": {
                    display: "none",
                  },
                }}
              />
            );
          })}
        </TabList>
      </Box>

      <div className={value === "Rental" ? " py-2" : "d-none"}>
        <RentalFilterModal
          onPageTabChange={onPageTabChange}
          closeModal={closeModal}
        />
      </div>

      <div className={value === "FlateMates" ? " py-2" : "d-none"}>
        <FlateMateFilterModal
          setisload={setisload}
          onPageTabChange={onPageTabChange}
          closeModal={closeModal}
        />
      </div>
      <div className={value === "TeamUp" ? " py-2" : "d-none"}>
        <TeamUpFilterModal
          onPageTabChange={onPageTabChange}
          closeModal={closeModal}
        />
      </div>
    </TabContext>
  );
};

export default RentalAllFilters;
