import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import WifiIcon from "@mui/icons-material/Wifi";
import { Box } from "@mui/material";
import React from "react";
import { FaParking } from "react-icons/fa";
import { HiOutlineUserGroup } from "react-icons/hi";
import { ImCross } from "react-icons/im";
import { editFlatemateByUser } from "../../../context/actions/authAction"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PropertyPreferenceCard = ({
  value,
  name,
  handleChange,
  options,
  placeholder,
  label,
  icon,
}) => {
  return (
    <div className="d-flex gap-2 align-items-start">
      <div className="icons">{icon}</div>
      <div className="info w-100">
        <label className="text-capitalize mb-2">{label}</label>
        <select
          className="form-select rounded-0 form-select-lg rounded-2 w-100"
          aria-label=".form-select-lg example"
          value={value}
          name={name}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        >
          {/* <option value="">{placeholder}</option> */}
          {options.map((item, index) => {
            return (
              <option value={item} key={index}>
                {item}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
const PropertyPreference = ({
  roomFurnishings,
  internet,
  bathRoomType,
  parking,
  totalFlateMates,
  id,
  onClose,
}) => {
  const value = {
    roomFurnishings: roomFurnishings,
    internet: internet,
    bathRoomType: bathRoomType,
    parking: parking,
    totalFlateMates: totalFlateMates,
  };
  const evalue = {
    roomFurnishings: "",
    internet: "",
    bathRoomType: "",
    parking: "",
    totalFlateMates: "",
  };
  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);
  const handleFormChange = (name, value) => {
    setFormValue({ ...formValue, [name]: value });
  };

  const formValidation = () => {
    const {
      roomFurnishings,
      internet,
      bathRoomType,
      parking,
      totalFlateMates,
    } = formValue;
    let isValid = false;
    let newerror = { ...evalue };
    if (
      (!roomFurnishings || !internet || !bathRoomType || !parking,
      !totalFlateMates)
    ) {
      if (!roomFurnishings) {
        newerror.roomFurnishings = "Please Provide budgetPerWeek *";
      }
      if (!internet) {
        newerror.internet = "Please Provide Length of Stay *";
      }

      if (!bathRoomType) {
        newerror.bathRoomType = "Please Provide Move In Date*";
      }

      if (!parking) {
        newerror.parking = "Please Provide Move In Date*";
      }
      newerror.message = "Invalid creadintial please check again";
      setError(newerror);
    } else {
      isValid = true;
      return isValid;
    }
  };

  const dispatch = useDispatch();
  const onSubmit = () => {
    const isValid = formValidation();
    // if (isValid) {
     
    // }
      const formData = new FormData();
      formData.append("roomFurnishings", formValue.roomFurnishings);
      formData.append("internet", formValue.internet);
      formData.append("bathRoomType", formValue.bathRoomType);
      formData.append("parking", formValue.parking);
      formData.append("totalFlateMates", formValue.totalFlateMates);
      dispatch(editFlatemateByUser({id:id, formData: formData, toast: toast}))

  };
  const preferencelist = [
    {
      id: "preferencelist1",
      value: formValue.roomFurnishings,
      options: ["Not Required", "Required"],
      label: "Room Furnishings",
      icon: (
        <BedIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "roomFurnishings",
      placeholder: "Select RoomFurnishings",
    },

    {
      id: "preferencelist2",
      value: formValue.internet,
      options: ["Not Required", "Required"],
      label: "Internet",
      icon: (
        <WifiIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "internet",
      placeholder: "Select Require Status",
    },
    {
      id: "preferencelist3",
      value: formValue.bathRoomType,
      options: ["Flexible", "Not Flexible"],
      label: "BathRoom Type",
      icon: (
        <ShowerIcon
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "bathRoomType",
      placeholder: "Select Flexible Status",
    },
    {
      id: "preferencelist4",
      value: formValue.parking,
      options: ["Flexible", "Not Flexible"],

      label: "Parking",
      icon: (
        <FaParking
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "parking",
      placeholder: "Select Flexible Status",
    },
    {
      id: "preferencelist5",
      value: formValue.totalFlateMates,
      options: [1, 2, 3, 4, 5],
      label: "Maximum no. of faltemates",
      icon: (
        <HiOutlineUserGroup
          style={{
            fontSize: "2.9em",
          }}
        />
      ),
      name: "totalFlateMates",
      placeholder: "Select No. of FlateMates",
    },
  ];
  return (
    <div className="d-flex flex-column gap-2 ">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <div className="d-flex flex-column">
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Property preferences
        </span>
        <small>property should have some basic things</small>
        </div>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>
      
      <div className="row row-cols-2  ">
        {preferencelist.map((item, index) => {
          return (
            <Box className="pe-5 py-2" key={item.id}>
              <PropertyPreferenceCard
                {...item}
                handleChange={handleFormChange}
              />
            </Box>
          );
        })}
      </div>

      <button
        className="btn-dark btn ms-auto justify-self-end mt-3"
        onClick={onSubmit}
      >
        Save
      </button>
    </div>
  );
};

export default PropertyPreference;
