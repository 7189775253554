import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  /* cursor: pointer; */

  &:hover {
    box-shadow: 1px 9px 5px -4px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 1px 9px 5px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 9px 5px -4px rgba(0, 0, 0, 0.75);
    .hover-link {
      display: block;
      height: 100%;
      width: 100%;
      z-index: 2;
    }
  }

  & > .rental-img {
    width: 100%;

    & > .icon {
      position: absolute;
      top: 3%;
      right: 2%;
    }
  }
`;

export default Wrapper;
