import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BCard } from "../../components/Index";
import province from "../../utils/all_province";
import { Typography } from "@mui/material";
import { getAllBusinessAction } from "../../context/actions/businessAction";
import BusinessCardSkeleton from "./components/BusinessCardSkeleton";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
    border: "2px solid #b4b4b4", // Add this line
    borderRadius: "5px", // Also add this line
  },
}));

const AllBusiness = () => {
  // use dspatch to dispatch action
  const dispatch = useDispatch();

  // use useCallback to memoize the function
  const get_all_business = useCallback(() => {
    dispatch(getAllBusinessAction({}));
  }, [dispatch]);

  // use useEffect to call the function
  useEffect(() => {
    get_all_business();
  }, [get_all_business]);

  // use useSelector to get data from store from business_reducer
  const {
    business,
    resultperpage,
    totalbusinesscount,
    filteredbusinessCount,
    loading,
  } = useSelector((state) => state.business_reducer);
  const navigate = useNavigate();

  // handle keypress event
  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      navigate(`/business/filter/?search=${event.target.value}`);
    }
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between flex-column flex-md-row">
        <div className="d-flex flex-column">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Featured</span> Business & Services
          </span>
          <small>
            Showing {business.length} featured business & services listed in ONF Canada
          </small>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div class="btn-group shadow-0 me-auto me-md-0">
            <button
              type="button"
              class="btn btn-light bg-greyx rounded h-maxcontent text-nowrap"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              Select Provenince
            </button>
            <ul class="dropdown-menu">
              {province.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="dropdown-item"
                      to={`/business/filter/?province=${item}`}
                    >
                      {item}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="">
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
                onKeyPress={handleKeypress}
              />
            </Search>
          </div>
        </div>
      </div>

      <div className="row row-cols-2 row-cols-lg-4">
        {loading ? ( // use loading state to render skeleton
          <>
            {Array.from({ length: 8 }, (_, index) => (
              <BusinessCardSkeleton/>
            ))}
          </>
        ) : (
          <>
            {business?.map((business, index) => {
              return (
                <div key={index} className="p-2">
                  <BCard index={index} business={business} />
                </div>
              );
            })}
          </>
        )}
      </div>

      <button
        type="button"
        className="btn btn-outline-black border-0  w-100  mt-2 mb-2"
        data-mdb-ripple-color="dark"
      >
        View all popular business
      </button>

      <div className="d-flex justify-content-between mt-2">
        <div className="d-flex flex-column">
          <span className="fs-4 fw-bold text-black">
            <span className="text-danger">Trending</span> Business & Services
          </span>
          <small>
            Showing {business.length} trending business & services listed based on
          </small>
        </div>

        <Typography
          variant="span"
          className="rounded rounded-2 h-maxcontent text-black"
          role="button"
          sx={{
            border: { xs: "none", md: "2px solid black" },
            padding: { md: "0.4em 1.5em" },
            textDecoration: { xs: "underline", md: "none" },
            fontWeight: { md: "bold" },
            fontSize: { md: "0.8rem" },
            textTransform: { md: "capitalize" },
          }}
        >
          see all
        </Typography>
      </div>

      <div className="row row-cols-2 row-cols-lg-4">
        {
          loading ? (
            <>
              {Array.from({ length: 8 }, (_, index) => (
                <BusinessCardSkeleton index={index}/> 
              ))}
            </>
          ) : (<>
            {business?.map((business, index) => {
              return (
                <div key={index} className="p-2">
                  <BCard index={index} business={business} />
                </div>
              );
            })}
          </>)
        }
      </div>
    </div>
  );
};

export default AllBusiness;
