import React from "react";
import {
  ProfessionalOffers,
  ProfessionalOverview,
  ProfessionalIntroduction,
  ProfessionalContacts,
  ProfessioinalTerms,
} from "./tabs";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, List } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { getProfessionalProfile } from "../../../../context/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Modal } from "@mui/material";
import EditProfessional from "./editProfessional/EditProfessional";

const Wrapper = styled.div`
  display: flex;

  .organization-tabs {
    list-style: none;
    & > li {
      cursor: pointer;

      &:hover {
        background-color: var(--hover-color);
      }
    }
  }
`;
const ProfessionalTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [state, setState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reviewModal, setReviewModal] = React.useState(false);
  const toggleReviewModal = () => {
    setReviewModal((prev) => !prev);
  };
  const reviewStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: 800, xs: "97vw" },
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: { xs: 1, sm: 2 },
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(getProfessionalProfile());
  }, [dispatch]);

  const { professional_profile } = useSelector((state) => ({
    professional_profile: state.user_reducer.professional_profile,
  }));

  return (
    <>
      <section className="d-flex align-items-center row-cols-3 m-0 p-0">
        <h4 className="col-4 fw-bold">Your professional profile</h4>

        <div className="col-8 d-flex justify-content-end gap-2">
          {professional_profile?.active && (
            <button
              className="btn btn-outline-dark"
              onClick={toggleReviewModal}
            >
              Edit Professional Profile
            </button>
          )}

          {!professional_profile.firstName ? (
            <Link
              to={`/professionals/add`}
              className="btn bg-black text-white "
            >
              Be a Professional
            </Link>
          ) : professional_profile?.active && professional_profile?.status ? (
            <button className="btn  d-flex gap-2 align-items-center bg-success text-white">
              Approved 
            </button>
          ) : professional_profile?.reviewMessage ? (
            <button
              className="btn  d-flex gap-2 align-items-center bg-danger text-white"
              onClick={toggleReviewModal}
            >
              Review Submit Application
            </button>
          ) : !professional_profile?.active && professional_profile?.status ? (
            <span class="badge fs-6 rounded-pill badge-danger">
            Deactivated | Please contact admin
          </span>
          ): (
            <button className="btn  d-flex gap-2 align-items-center bg-warning text-white">
              pending
            </button>
          )}
        </div>
      </section>
      <hr />
      <Wrapper className="position-relative p-0 m-0">
        {professional_profile.active && (
          <MenuIcon
            className="position-absolute  d-md-none text-dark bg-hover p-1 "
            sx={{
              color: "black",
              zIndex: "2",
              right: "3%",
              top: "2%",
              border: "1px solid red",
            }}
            onClick={toggleDrawer(true)}
          />
        )}
        {professional_profile.active && (
          <SwipeableDrawer
            anchor={"left"}
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
              width: "300px",
              border: "1px solid red",
            }}
            className="d-md-none"
          >
            <List
              className="p-0 m-0 organization-tabs list-unstyled "
              sx={{
                listStyle: "none",
              }}
            >
              <li
                className={value === 1 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(1)}
              >
                Overview
              </li>
              <li
                className={value === 2 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(2)}
              >
                Introduction
              </li>
              <li
                className={value === 3 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(3)}
              >
                Overview
              </li>
              <li
                className={value === 4 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(4)}
              >
                Contact Details
              </li>
              <li
                className={value === 5 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(5)}
              >
                Social Media
              </li>
              <li
                className={value === 6 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(6)}
              >
                Other Information
              </li>
            </List>
          </SwipeableDrawer>
        )}
        {professional_profile.active && (
          <Box
            sx={{
              width: { lg: "20%" },
            }}
            className="professional-left col-md-2 me-2 border rounded rounded-3 d-none d-md-block  "
          >
            <div
              className="d-flex
          flex-column
        
          "
            >
              <ul className="p-0 m-0 organization-tabs ">
                <li
                  className={
                    value === 1
                      ? "px-2 py-2 rounded rouned-5 bg-hover"
                      : "px-2 py-2 rounded rouned-5"
                  }
                  onClick={() => handleChange(1)}
                >
                  Overview
                </li>
                <li
                  className={
                    value === 2
                      ? "px-2 py-2 rounded rouned-5 bg-hover"
                      : "px-2 py-2 rounded rouned-5"
                  }
                  onClick={() => handleChange(2)}
                >
                  Introduction
                </li>
                <li
                  className={
                    value === 3
                      ? "px-2 py-2 rounded rouned-5 bg-hover"
                      : "px-2 py-2 rounded rouned-5"
                  }
                  onClick={() => handleChange(3)}
                >
                  Services Offered
                </li>
                <li
                  className={
                    value === 4
                      ? "px-2 py-2 rounded rouned-5 bg-hover"
                      : "px-2 py-2 rounded rouned-5"
                  }
                  onClick={() => handleChange(4)}
                >
                  Contacts
                </li>
                <li
                  className={
                    value === 5
                      ? "px-2 py-2 rounded rouned-5 bg-hover"
                      : "px-2 py-2 rounded rouned-5"
                  }
                  onClick={() => handleChange(5)}
                >
                  Other Information
                </li>
              </ul>
            </div>
          </Box>
        )}
        <Box className="organization-right w-100 px-2 ">
          {value === 1 ? (
            <ProfessionalOverview
              data={professional_profile}
              reviewModal={reviewModal}
              toggleReviewModal={toggleReviewModal}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
            />
          ) : null}

          {value === 2 ? (
            <ProfessionalIntroduction introduction={professional_profile} />
          ) : null}
          {value === 3 ? (
            <ProfessionalOffers professional={professional_profile} />
          ) : null}
          {value === 4 ? (
            <ProfessionalContacts professional={professional_profile} />
          ) : null}
          {value === 5 ? <ProfessioinalTerms /> : null}
        </Box>

        <Modal
          open={reviewModal}
          onClose={toggleReviewModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={reviewStyle}>
            {professional_profile?.firstName && (
              <EditProfessional
                data={professional_profile}
                onClose={toggleReviewModal}
              />
            )}
          </Box>
        </Modal>
      </Wrapper>
    </>
  );
};

export default ProfessionalTab;
