import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

export default function AreYouSureModal({
  open,
  handleOpen,
  onToggleActive,
  isActive,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src="/assets/images/bin.png" alt="" />
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            color={"red"}
          >
            Are you sure you want to delete this?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be dangerous for visibility of date in the
            future.
          </Typography>
          <div className="mt-2 d-flex gap-2">
            <button className="btn btn-outline-black" onClick={handleOpen}>
              Calcel
            </button>

            {isActive ? (
              <button
                className="btn btn-danger shadow-0"
                onClick={onToggleActive}
              >
                Deactivate
              </button>
            ) : (
              <button
                className="btn btn-success shadow-0"
                onClick={onToggleActive}
              >
                Activate
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
