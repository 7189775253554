import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SubjectIcon from "@mui/icons-material/Subject";
import { Box } from "@mui/material";
import ImgPreivew from "../../../../../components/imgpreview/ImgPreivew";
import Modal from "@mui/material/Modal";
import { ImCross } from "react-icons/im";
import HTMLReactParser from "html-react-parser";

const IntroductionTab = ({ introduction }) => {
  const [open, setOpen] = React.useState({
    open: false,
    preview: "",
  });

  const [readmore, setreadmore] = React.useState(false);

  const togglereadmore = () => {
    setreadmore((prev) => !prev);
  };

  const ShowPreview = (img) => {
    setOpen({ open: true, preview: img });
  };

  const closePreview = () => {
    setOpen({ open: false, preview: "" });
  };

  return (
    <div>
      <h5 className="fw-bold">General Information</h5>
      <section className="d-flex flex-column gap-2 fs-5">
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <HomeIcon />
            <span className="fs-6 ">Organization name</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">{introduction?.organizationName}</p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-center">
            <LocationOnIcon />
            <span className="fs-6">Province | City</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1 fw-bold">
            {introduction?.provenience} | {introduction?.city}
          </p>
        </div>
        <div className="row-cols-2 row">
          <div className="d-flex gap-2 col-4 align-items-start">
            <SubjectIcon />
            <span className="fs-6">Organization Description</span>
            <span className="ms-auto">:</span>
          </div>
          <p className="fs-6 mt-1">
            {!readmore
              ? HTMLReactParser(introduction?.description?.slice(0, 300))
              : HTMLReactParser(introduction?.description)}

            {!readmore ? (
              <strong role="button" onClick={togglereadmore}>
                ...readmore
              </strong>
            ) : (
              <strong role="button" onClick={togglereadmore}>
                ...readless
              </strong>
            )}
          </p>
        </div>
      </section>

      <hr className="my-2 bg-hover " />
      <section>
      <h5 className="fw-bold">Verification Documents</h5>

        <div className="row-cols-lg-2 row gx-2 row-cols-1 overflow-hidden">
          <div className="">
            <p className="fs-6">Document One (Front)</p>
            <ImgPreivew
              // img="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              img={`${process.env.REACT_APP_IMAGE_PATH}${introduction?.documentFrontpage}`}
              ShowPreview={ShowPreview}
            />
          </div>

          <div className="">
            <p className="fs-6">Document Two (Back)</p>

            <ImgPreivew
              // img="https://images.pexels.com/photos/590037/pexels-photo-590037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              img={`${process.env.REACT_APP_IMAGE_PATH}${introduction?.documentBackpage}`}
              ShowPreview={ShowPreview}
            />
          </div>
        </div>
      </section>

      <Modal
        open={open.open}
        onClose={closePreview}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="position-relative">
          <Box
            className="bg-hover rounded rounded-circle d-flex justify-content-center align-items-center"
            sx={{
              position: "absolute",
              top: "2%",
              right: "2%",
              width: 40,
              height: 40,
            }}
            onClick={closePreview}
            role="button"
          >
            <ImCross className=" bg-hover rounded rounded-circle fs-5" />
          </Box>
          <img
            src={open.preview}
            alt=""
            className="w-100 h-100 object-center "
            style={{ objectFit: "contain" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default IntroductionTab;
