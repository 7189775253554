import React from 'react'

const BoardMembers = ({data}) => {
  const {memberName,memberPosition,memberPhoto} = data;
  return (
    <div class="card">
      <img
        src='/assets/images/user.webp'
        // src={`${process.env.REACT_APP_IMAGE_PATH}${memberPhoto}`}
        class="card-img-top object-cover" 
        alt="Hollywood Sign on The Hill"
        width={200}
        height={150}
      />
      <div class="card-body">
        <h5 class="card-title">{memberName}</h5>
        <p class="card-text">{memberPosition}</p>
      </div>
    </div>
  );
}

export default BoardMembers