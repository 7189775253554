import { createSlice } from "@reduxjs/toolkit";

import { adminCreateAdsBannerAction } from "../actions/admin/featuredAdsAction";

import {
  adminGetAllStudentRequirementAction,
  adminGetTableByCuntryAction,
} from "../actions/admin/studentAction";

import {
  adminCreateProfessionalAction,
  adminGetAllProfessionalAction,
  adminEditProfessionalAction,
  adminChangeProfessionalStatusAction,
  adminUpdateFeaturedProfessionalAction,
} from "../actions/admin/professionalAction";

import {
  adminCreateOrganizationAction,
  adminChangeOrganizationStatusAction,
  adminUpdateFeaturedOrganizationAction,
  adminGetAllOrganizationAction,
  adminUpdateOrganizationAction,
} from "../actions/admin/organizationAction";

import {
  adminCreateBusinessAdminAction,
  adminChangeBusinessStatusAction,
  adminUpdateFeaturedBusinessAction,
  adminGetAllBusinessAction,
  adminEditBusinessAction,
} from "../actions/admin/businessAction";

import {
  adminGetAllUserAction,
  adminRegisterUserAction,
  adminChangeUserStatusAction,
  adminEditUserAction,
} from "../actions/admin/userAction";

import {
  adminGetAllBlogAction,
  adminGetBlogByIdAction,
  adminFeaturedBlogAction,
  adminGetAllFeaturedBlogAction
} from "../actions/admin/blogAction";

import {
  adminCreateRentalAction,
  adminGetAllRentalAction,
  adminEditRentalAction,
  adminChangeRentalStatusAction,
  adminUpdateFeaturedRentalAction,
} from "../actions/admin/rentalAction";

import {
  adminCreateFlatemateAction,
  adminGetAllFlatemateAction,
  adminEditFlatemateAction,
  adminChangeFlatemateStatusAction,
  adminUpdateFeaturedFlatemateAction,
} from "../actions/admin/flatemateAction";

import {
  adminCountDocumentsAndViewsAction,
  adminGetAllLogsAction,
} from "../actions/admin/dashbaordAction";

import {
  adminGetAllHelpSupportAction,
  adminChangeStatusHelpAndSupport,
} from "../actions/admin/helpsupportAction";

import {
  adminGetAllContactAction,
  adminChangeStatusContactAction,
} from "../actions/admin/contactAction";

import { adminGetAllAdminAction } from "../actions/admin/authAction";

import {
  adminCreateEventAction,
  adminGetAllEventAction,
  adminEditEventAction,
} from "../actions/admin/eventAction";

import {
  adminCreateFaqAction,
  adminEditFaqAction,
  adminGetAllFaq,
} from "../actions/admin/faqAction";
import { adminGetAllNotification } from "../actions/admin/notificationAction";

const initialState = {
  loading: false,
  adminCreateUserLoading: false,
  adminCreateProfessionalLoading: false,
  business_loading: false,
  business_details_loading: false,
  blog_loading: false,
  flatemate_loading: false,
  flatemate_edit_loading: false,
  rental_loading: false,
  professional_loading: false,
  student_requiremnet_loading: false,
  change_org_status_loading: false,
  chnage_organization_feature_status_loading: false,
  change_business_status_business_loading: false,
  help_and_support_loading: false,
  contact_loading: false,
  create_event_loding: false,
  edit_event_loding: false,
  logs_loading: false,
  event_loading: false,
  create_faq_loading: false,
  get_faq_loading: false,
  update_faq_loading: false,
  chnage_user_status_loading: false,
  change_help_support_status_loading: false,
  chnage_contact_status_loading: false,
  edit_blog_loading: false,
  admin_user_edit_loading: false,
  admin_business_edit_loading: false,
  get_student_requirement_table_loading: false,
  admin_change_staus_of_professional_loading: false,
  admin_change_featured_loading: false,
  admin_chnage_status_of_rental_loading: false,
  admin_change_featured_loading: false,
  admin_chnage_status_of_flatemate_loading: false,
  admin_chnage_featured_flatemate_loading: false,
  admin_update_organzation_loading: false,
  admin_chnage_status_of_blog_loading: false,

  adminUpdateProfessionalLoading: false,
  adminCreateRentalLoading: false,
  adminEditRentalLoading: false,
  createFlatemateLoading: false,
  adminCreateOrganizationLoading: false,
  adminUpdateOrganzationLoading: false,
  adminCreateBusinessLoading: false,
  adminBusinessEditLoading: false,
  adminCreateAdsBannerLoading: false,
  adminCreateEventLoding: false,
  adminEditeEventLoding: false,
  adminCreateFaqLoading: false,
  adminUpdateFaqLoading: false,
  getAllAdminSuperAdminLoading: false,
  adminGetAllNotificationLoading: false,
  adminGetAllFeaturedBlogLoading: false,

  organization: [],
  user: [],
  all_admins: [],
  blogs: [],
  featured_blogs: [],
  blog_details: {},
  business: [],
  flatemate: [],
  rental: [],
  all_student_requirement: [],
  professional: [],
  help_support: [],
  contact: [],
  logs: [],
  events: [],
  faqs: [],
  notifications: [],

  blogOverview: {},
  userOverview: {},
  organizationOverview: {},
  businessOverview: {},
  flatemateOverview: {},
  rentalOverview: {},
  professionalOverview: {},
  eventOverview: {},
  sudent_requirements_table: {},

  //globel
  resultperpage: 0,
  //end

  //organization
  filteredOrganizationCount: 0,
  totalorganizationcount: 0,
  //end

  // user
  totalusercount: 0,
  filtereduserCount: 0,

  //end
  // organization
  totalbusinesscount: 0,
  filteredbusinesscount: 0,

  // blog
  filterblogcount: 0,
  totalBlogCount: 0,

  //flatemate
  totalflatematecount: 0,
  filterflatematecount: 0,

  //rental
  totalrentalcount: 0,
  filterrentalcount: 0,

  //professional
  filterprofessionalcount: 0,
  totalprofessionalcount: 0,

  //student requirement
  totalstudentrequirementcount: 0,
  filterstudentrequirementcount: 0,
  //end

  totalhelpandsupportcount: 0,

  totalcontactcount: 0,
  totallogscount: 0,
  logsresultperpage: 0,

  // event
  totaleventcount: 0,

  //faq
  totalfaqcount: 0,

  //admin
  totaladmincount: 0,
  error: "",
  msg: "",
};

const adminSlice = createSlice({
  name: "admin_controller",
  initialState,
  reducers: {
   
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    // adminCountDocumentsAndViewsAction

    builder
      // get all admin and super admin
      .addCase(adminGetAllAdminAction.pending, (state, action) => {
        state.getAllAdminSuperAdminLoading = true;
      })
      .addCase(adminGetAllAdminAction.fulfilled, (state, action) => {
        state.getAllAdminSuperAdminLoading = false;
        console.log(action.payload);
        const { admin, resultperpage, totaladmincount } = action.payload;
        state.all_admins = admin;
        state.resultperpage = resultperpage;
        state.totaladmincount = totaladmincount;
      })
      .addCase(adminGetAllAdminAction.rejected, (state, action) => {
        state.getAllAdminSuperAdminLoading = false;
        state.all_admins = [];
        state.error = action.payload.message;
      })

      // get all notification
      .addCase(adminGetAllNotification.pending, (state, action) => {
        state.adminGetAllNotificationLoading = true;
      })
      .addCase(adminGetAllNotification.fulfilled, (state, action) => {
        state.adminGetAllNotificationLoading = false;
        console.log(action.payload);
        const { notifications, resultperpage, totalnotificationcount } =
          action.payload;
        state.notifications = notifications;
        state.resultperpage = resultperpage;
        state.totalnotificationcount = totalnotificationcount;
      })
      .addCase(adminGetAllNotification.rejected, (state, action) => {
        state.adminGetAllNotificationLoading = false;
        state.notification = [];
        state.error = action.payload.message;
      })

      // adminCreateAdsBannerAction
      .addCase(adminCreateAdsBannerAction.pending, (state, action) => {
        state.adminCreateAdsBannerLoading = true;
      })
      .addCase(adminCreateAdsBannerAction.fulfilled, (state, action) => {
        state.adminCreateAdsBannerLoading = false;
      })
      .addCase(adminCreateAdsBannerAction.rejected, (state, action) => {
        state.adminCreateAdsBannerLoading = false;
        state.error = action.payload.message;
      })

      .addCase(adminCountDocumentsAndViewsAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(adminCountDocumentsAndViewsAction.fulfilled, (state, action) => {
        state.loading = false;
        const {
          blogs,
          users,
          organization,
          business,
          flatemate,
          rental,
          professional,
          events,
        } = action.payload;
        state.blogOverview = blogs;
        state.userOverview = users;
        state.organizationOverview = organization;
        state.businessOverview = business;
        state.flatemateOverview = flatemate;
        state.rentalOverview = rental;
        state.professionalOverview = professional;
        state.eventOverview = events;
      })
      .addCase(adminCountDocumentsAndViewsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      // admincreateorganization
      .addCase(adminCreateOrganizationAction.pending, (state, action) => {
        state.adminCreateOrganizationLoading = true;
      })
      .addCase(adminCreateOrganizationAction.fulfilled, (state, action) => {
        state.adminCreateOrganizationLoading = false;
      })
      .addCase(adminCreateOrganizationAction.rejected, (state, action) => {
        state.adminCreateOrganizationLoading = false;
        state.error = action.payload.message;
      })
      // get all organization extra reducers
      .addCase(adminGetAllOrganizationAction.pending, (state, action) => {
        state.loading = true;
        state.organization = [];
      })
      .addCase(adminGetAllOrganizationAction.fulfilled, (state, action) => {
        state.loading = false;
        const {
          organization,
          resultperpage,
          filteredOrganizationCount,
          totalorganizationcount,
        } = action.payload;
        state.organization = organization;
        state.resultperpage = resultperpage;
        state.filteredOrganizationCount = filteredOrganizationCount;
        state.totalorganizationcount = totalorganizationcount;
      })
      .addCase(adminGetAllOrganizationAction.rejected, (state, action) => {
        state.organization = [];
        state.loading = false;
        state.error = action.payload.message;
      })

      // adminUpdateOrganizationAction
      .addCase(adminUpdateOrganizationAction.pending, (state, action) => {
        state.adminUpdateOrganzationLoading = true;
      })
      .addCase(adminUpdateOrganizationAction.fulfilled, (state, action) => {
        state.adminUpdateOrganzationLoading = false;
        const { activeindex } = action.payload;
        const { organization } = action.payload.data;
        state.organization[activeindex] = organization;
      })

      .addCase(adminUpdateOrganizationAction.rejected, (state, action) => {
        state.adminUpdateOrganzationLoading = false;
        state.error = action.payload.message;
      })

      // get all business extra reducers
      .addCase(adminGetAllBusinessAction.pending, (state, action) => {
        state.business_loading = true;
        state.business = [];
      })
      .addCase(adminGetAllBusinessAction.fulfilled, (state, action) => {
        state.business_loading = false;
        const {
          business,
          resultperpage,
          filteredbusinesscount,
          totalbusinesscount,
        } = action.payload;
        state.business = business;
        state.resultperpage = resultperpage;
        state.filteredbusinesscount = filteredbusinesscount;
        state.totalbusinesscount = totalbusinesscount;
      })
      .addCase(adminGetAllBusinessAction.rejected, (state, action) => {
        state.business = [];
        state.business_loading = false;
        state.error = action.payload.message;
      })

      // adminEditBusinessAction
      .addCase(adminEditBusinessAction.pending, (state, action) => {
        state.adminBusinessEditLoading = true;
      })
      .addCase(adminEditBusinessAction.fulfilled, (state, action) => {
        state.adminBusinessEditLoading = false;
        const { activeIndex } = action.payload;
        const { business } = action.payload.data;
        state.business[activeIndex] = business;
      })
      .addCase(adminEditBusinessAction.rejected, (state, action) => {
        state.adminBusinessEditLoading = false;
        state.error = action.payload.message;
      })

      // register user by admin
      .addCase(adminRegisterUserAction.pending, (state, action) => {
        state.adminCreateUserLoading = true;
      })
      .addCase(adminRegisterUserAction.fulfilled, (state, action) => {
        state.adminCreateUserLoading = false;
      })
      .addCase(adminRegisterUserAction.rejected, (state, action) => {
        state.adminCreateUserLoading = false;
        state.error = action.payload.message;
      })

      // change status of user by admin
      .addCase(adminChangeUserStatusAction.pending, (state, action) => {
        state.chnage_user_status_loading = true;
      })
      .addCase(adminChangeUserStatusAction.fulfilled, (state, action) => {
        state.chnage_user_status_loading = false;
        const { activeIndex } = action.payload;
        const { active } = action.payload.data;
        state.user[activeIndex].active = active;
        // state.msg = action.payload.message;
      })
      .addCase(adminChangeUserStatusAction.rejected, (state, action) => {
        state.chnage_user_status_loading = false;
        state.error = action.payload.message;
      })
      // get all user extra reducers
      .addCase(adminGetAllUserAction.pending, (state, action) => {
        state.loading = true;
        state.user = [];
      })
      .addCase(adminGetAllUserAction.fulfilled, (state, action) => {
        state.loading = false;
        const { user, resultperpage, filtereduserCount, totalusercount } =
          action.payload;
        state.user = user;
        state.resultperpage = resultperpage;
        state.filtereduserCount = filtereduserCount;
        state.totalusercount = totalusercount;
      })
      .addCase(adminGetAllUserAction.rejected, (state, action) => {
        state.data = [];
        state.loading = false;
        state.error = action.payload.message;
      })

      // edit user by admin
      .addCase(adminEditUserAction.pending, (state, action) => {
        state.admin_user_edit_loading = true;
      })
      .addCase(adminEditUserAction.fulfilled, (state, action) => {
        state.admin_user_edit_loading = false;
        const { activeIndex } = action.payload;
        const { user } = action.payload.data;
        state.user[activeIndex] = user;
      })
      .addCase(adminEditUserAction.rejected, (state, action) => {
        state.admin_user_edit_loading = false;
        state.error = action.payload.message;
      })

      // get all blogs extra reducers
      .addCase(adminGetAllBlogAction.pending, (state, action) => {
        state.blog_loading = true;
      })
      .addCase(adminGetAllBlogAction.fulfilled, (state, action) => {
        state.blog_loading = false;
        const { blogs, resultperpage, filterblogcount, totalBlogCount } =
          action.payload;
        state.blogs = blogs;
        state.resultperpage = resultperpage;
        state.filterblogcount = filterblogcount;
        state.totalBlogCount = totalBlogCount;
      })
      .addCase(adminGetAllBlogAction.rejected, (state, action) => {
        state.blog_loading = false;
        state.blogs = [];
        state.error = action.payload.message;
      })

      // get all featured blog
      .addCase(adminGetAllFeaturedBlogAction.pending, (state, action) => {
        state.adminGetAllFeaturedBlogLoading = true;
      })
      .addCase(adminGetAllFeaturedBlogAction.fulfilled, (state, action) => {
        state.adminGetAllFeaturedBlogLoading = false;
        const { featured_blogs } = action.payload;
        state.featured_blogs = featured_blogs;
      })
      .addCase(adminGetAllFeaturedBlogAction.rejected, (state, action) => {
        state.adminGetAllFeaturedBlogLoading = false;
        state.featured_blogs = [];
        state.error = action.payload.message;
      })

      // get blogs by id extra reducers
      .addCase(adminGetBlogByIdAction.pending, (state, action) => {
        state.edit_blog_loading = true;
      })
      .addCase(adminGetBlogByIdAction.fulfilled, (state, action) => {
        state.edit_blog_loading = false;
        state.blog_details = action.payload.blogs;
      })
      .addCase(adminGetBlogByIdAction.rejected, (state, action) => {
        state.edit_blog_loading = false;
        state.blog_details = {};
        state.error = action.payload.message;
      })

      // handle the status change for organization
      .addCase(adminChangeOrganizationStatusAction.pending, (state, action) => {
        state.change_org_status_loading = true;
      })
      .addCase(
        adminChangeOrganizationStatusAction.fulfilled,
        (state, action) => {
          state.change_org_status_loading = false;
          const { activeindex } = action.payload;
          const { active } = action.payload.data;
          state.organization[activeindex].active = active;
          // state.msg = action.payload.message;
        }
      )
      .addCase(
        adminChangeOrganizationStatusAction.rejected,
        (state, action) => {
          state.change_org_status_loading = false;
          state.error = action.payload.message;
        }
      )

      // adminUpdateFeaturedOrganizationAction
      .addCase(
        adminUpdateFeaturedOrganizationAction.pending,
        (state, action) => {
          state.chnage_organization_feature_status_loading = true;
        }
      )
      .addCase(
        adminUpdateFeaturedOrganizationAction.fulfilled,
        (state, action) => {
          state.chnage_organization_feature_status_loading = false;
          const { activeindex } = action.payload;
          const { featured } = action.payload.data;
          state.organization[activeindex].featured = featured;
          // state.msg = action.payload.message;
        }
      )
      .addCase(
        adminUpdateFeaturedOrganizationAction.rejected,
        (state, action) => {
          state.chnage_organization_feature_status_loading = false;
          state.error = action.payload.message;
        }
      )

      // adminCreatebusiness
      .addCase(adminCreateBusinessAdminAction.pending, (state, action) => {
        state.adminCreateBusinessLoading = true;
      })
      .addCase(adminCreateBusinessAdminAction.fulfilled, (state, action) => {
        state.adminCreateBusinessLoading = false;
      })
      .addCase(adminCreateBusinessAdminAction.rejected, (state, action) => {
        state.adminCreateBusinessLoading = false;
        state.error = action.payload.message;
      })

      // handle the status change for business
      .addCase(adminChangeBusinessStatusAction.pending, (state, action) => {
        state.change_business_status_business_loading = true;
      })
      .addCase(adminChangeBusinessStatusAction.fulfilled, (state, action) => {
        state.change_business_status_business_loading = false;
        const { activeindex } = action.payload;
        const { active } = action.payload.data;
        state.business[activeindex].active = active;
        // state.msg = action.payload.message;
      })
      .addCase(adminChangeBusinessStatusAction.rejected, (state, action) => {
        state.change_business_status_business_loading = false;
        state.error = action.payload.message;
      })

      //adminUpdateFeaturedBusinessAction
      .addCase(adminUpdateFeaturedBusinessAction.pending, (state, action) => {
        state.change_business_status_business_loading = true;
      })
      .addCase(adminUpdateFeaturedBusinessAction.fulfilled, (state, action) => {
        state.change_business_status_business_loading = false;
        const { activeindex } = action.payload;
        const { featured } = action.payload.data;
        state.business[activeindex].featured = featured;
        // state.msg = action.payload.message;
      })
      .addCase(adminUpdateFeaturedBusinessAction.rejected, (state, action) => {
        state.change_business_status_business_loading = false;
        state.error = action.payload.message;
      })

      // admin create flatemate
      .addCase(adminCreateFlatemateAction.pending, (state, action) => {
        state.createFlatemateLoading = true;
      })
      .addCase(adminCreateFlatemateAction.fulfilled, (state, action) => {
        state.createFlatemateLoading = false;
      })
      .addCase(adminCreateFlatemateAction.rejected, (state, action) => {
        state.createFlatemateLoading = false;
        state.error = action.payload.message;
      })

      // get all flatemate extra reducers
      .addCase(adminGetAllFlatemateAction.pending, (state, action) => {
        state.flatemate_loading = true;
        state.flatemate = [];
      })
      .addCase(adminGetAllFlatemateAction.fulfilled, (state, action) => {
        state.flatemate_loading = false;
        const {
          flatemate,
          resultperpage,
          totalflatematecount,
          filterflatematecount,
        } = action.payload;
        state.flatemate = flatemate;
        state.resultperpage = resultperpage;
        state.filterflatematecount = filterflatematecount;
        state.totalflatematecount = totalflatematecount;
      })
      .addCase(adminGetAllFlatemateAction.rejected, (state, action) => {
        state.flatemate_loading = false;
        state.flatemate = [];
      })

      // change status of flatemate by admin
      .addCase(adminChangeFlatemateStatusAction.pending, (state, action) => {
        state.admin_chnage_status_of_flatemate_loading = true;
      })
      .addCase(adminChangeFlatemateStatusAction.fulfilled, (state, action) => {
        state.admin_chnage_status_of_flatemate_loading = false;
        const { activeIndex } = action.payload;
        const { active } = action.payload.data;
        state.flatemate[activeIndex].active = active;
        // state.msg = action.payload.message;
      })
      .addCase(adminChangeFlatemateStatusAction.rejected, (state, action) => {
        state.admin_chnage_status_of_flatemate_loading = false;
        state.error = action.payload.message;
      })

      //adminUpdateFeaturedFlatemateAction
      .addCase(adminUpdateFeaturedFlatemateAction.pending, (state, action) => {
        state.admin_chnage_featured_flatemate_loading = true;
      })
      .addCase(
        adminUpdateFeaturedFlatemateAction.fulfilled,
        (state, action) => {
          state.admin_chnage_featured_flatemate_loading = false;
          const { activeIndex } = action.payload;
          const { featured } = action.payload.data;
          state.flatemate[activeIndex].featured = featured;
          // state.msg = action.payload.message;
        }
      )
      .addCase(adminUpdateFeaturedFlatemateAction.rejected, (state, action) => {
        state.admin_chnage_featured_flatemate_loading = false;
        state.error = action.payload.message;
      })

      //admin create rental
      .addCase(adminCreateRentalAction.pending, (state, action) => {
        state.adminCreateRentalLoading = true;
      })
      .addCase(adminCreateRentalAction.fulfilled, (state, action) => {
        state.adminCreateRentalLoading = false;
      })
      .addCase(adminCreateRentalAction.rejected, (state, action) => {
        state.adminCreateRentalLoading = false;
        state.error = action.payload.message;
      })

      // get all rental extra reducers
      .addCase(adminGetAllRentalAction.pending, (state, action) => {
        state.rental_loading = true;
        state.rental = [];
      })
      .addCase(adminGetAllRentalAction.fulfilled, (state, action) => {
        state.rental_loading = false;
        const { rental, resultperpage, totalrentalcount, filterrentalcount } =
          action.payload;
        state.rental = rental;
        state.resultperpage = resultperpage;
        state.filterrentalcount = filterrentalcount;
        state.totalrentalcount = totalrentalcount;
      })
      .addCase(adminGetAllRentalAction.rejected, (state, action) => {
        state.rental_loading = false;
        state.rental = [];
      })

      // change status of rental by admin
      .addCase(adminChangeRentalStatusAction.pending, (state, action) => {
        state.admin_chnage_status_of_rental_loading = true;
      })
      .addCase(adminChangeRentalStatusAction.fulfilled, (state, action) => {
        state.admin_chnage_status_of_rental_loading = false;
        const { activeIndex } = action.payload;
        const { active } = action.payload.data;
        state.rental[activeIndex].active = active;
        // state.msg = action.payload.message;
      })
      .addCase(adminChangeRentalStatusAction.rejected, (state, action) => {
        state.admin_chnage_status_of_rental_loading = false;
        state.error = action.payload.message;
      })

      //adminFeaturedBlogAction
      .addCase(adminFeaturedBlogAction.pending, (state, action) => {
        state.admin_chnage_status_of_blog_loading = true;
      })
      .addCase(adminFeaturedBlogAction.fulfilled, (state, action) => {
        state.admin_chnage_status_of_blog_loading = false;
        // state.msg = action.payload.message;
      })
      .addCase(adminFeaturedBlogAction.rejected, (state, action) => {
        state.admin_chnage_status_of_blog_loading = false;
        state.error = action.payload.message;
      })

      //adminUpdateFeaturedRentalAction
      .addCase(adminUpdateFeaturedRentalAction.pending, (state, action) => {
        state.admin_change_featured_loading = true;
      })
      .addCase(adminUpdateFeaturedRentalAction.fulfilled, (state, action) => {
        state.admin_change_featured_loading = false;
        const { activeIndex } = action.payload;
        const { featured } = action.payload.data;
        state.rental[activeIndex].featured = featured;
        // state.msg = action.payload.message;
      })
      .addCase(adminUpdateFeaturedRentalAction.rejected, (state, action) => {
        state.admin_change_featured_loading = false;
        state.error = action.payload.message;
      })

      // get all rental edit extra reducers
      .addCase(adminEditRentalAction.pending, (state, action) => {
        state.adminEditRentalLoading = true;
      })
      .addCase(adminEditRentalAction.fulfilled, (state, action) => {
        state.adminEditRentalLoading = false;
        const { activeIndex } = action.payload;
        const { rental } = action.payload.data;
        state.rental[activeIndex] = rental;
      })
      .addCase(adminEditRentalAction.rejected, (state, action) => {
        state.adminEditRentalLoading = false;
        state.rental = [];
      })

      //AdminEditFlatemateAction
      .addCase(adminEditFlatemateAction.pending, (state, action) => {
        state.flatemate_edit_loading = true;
      })
      .addCase(adminEditFlatemateAction.fulfilled, (state, action) => {
        state.flatemate_edit_loading = false;
        const { activeIndex } = action.payload;
        const { flatemate } = action.payload.data;
        state.flatemate[activeIndex] = flatemate;
      })
      .addCase(adminEditFlatemateAction.rejected, (state, action) => {
        state.flatemate_edit_loading = false;
        state.flatemate = [];
      })

      // adminCreateProfessionalAction
      .addCase(adminCreateProfessionalAction.pending, (state, action) => {
        state.adminCreateProfessionalLoading = true;
      })
      .addCase(adminCreateProfessionalAction.fulfilled, (state, action) => {
        state.adminCreateProfessionalLoading = false;
      })
      .addCase(adminCreateProfessionalAction.rejected, (state, action) => {
        state.adminCreateProfessionalLoading = false;
        state.error = action.payload.message;
      })

      // AdminGetAllProfessionalAction
      .addCase(adminGetAllProfessionalAction.pending, (state, action) => {
        state.professional_loading = true;
        state.professional = [];
      })
      .addCase(adminGetAllProfessionalAction.fulfilled, (state, action) => {
        state.professional_loading = false;
        const {
          professional,
          resultperpage,
          totalprofessionalcount,
          filterprofessionalcount,
        } = action.payload;
        state.professional = professional;
        state.resultperpage = resultperpage;
        state.filterprofessionalcount = filterprofessionalcount;
        state.totalprofessionalcount = totalprofessionalcount;
      })
      .addCase(adminGetAllProfessionalAction.rejected, (state, action) => {
        state.professional_loading = false;
        state.professional = [];
      })

      // adminEditProfessionalAction
      .addCase(adminEditProfessionalAction.pending, (state, action) => {
        state.adminUpdateProfessionalLoading = true;
      })

      .addCase(adminEditProfessionalAction.fulfilled, (state, action) => {
        state.adminUpdateProfessionalLoading = false;
        const { activeIndex } = action.payload;
        const { professional } = action.payload.data;
        state.professional[activeIndex] = professional;
      })

      .addCase(adminEditProfessionalAction.rejected, (state, action) => {
        state.adminUpdateProfessionalLoading = false;
        state.error = action.payload.message;
      })

      // adminChangeProfessionalStatusAction
      .addCase(adminChangeProfessionalStatusAction.pending, (state, action) => {
        state.admin_change_staus_of_professional_loading = true;
      })
      .addCase(
        adminChangeProfessionalStatusAction.fulfilled,
        (state, action) => {
          state.admin_change_staus_of_professional_loading = false;
          const { activeIndex } = action.payload;
          const { active } = action.payload.data;
          state.professional[activeIndex].active = active;
          // state.msg = action.payload.message;
        }
      )
      .addCase(
        adminChangeProfessionalStatusAction.rejected,
        (state, action) => {
          state.admin_change_staus_of_professional_loading = false;
          state.error = action.payload.message;
        }
      )

      // adminUpdateFeaturedProfessionalAction
      .addCase(
        adminUpdateFeaturedProfessionalAction.pending,
        (state, action) => {
          state.admin_change_featured_loading = true;
        }
      )
      .addCase(
        adminUpdateFeaturedProfessionalAction.fulfilled,
        (state, action) => {
          state.admin_change_featured_loading = false;
          const { activeIndex } = action.payload;
          const { featured } = action.payload.data;
          state.professional[activeIndex].featured = featured;
          // state.msg = action.payload.message;
        }
      )
      .addCase(
        adminUpdateFeaturedProfessionalAction.rejected,
        (state, action) => {
          state.admin_change_featured_loading = false;
          state.error = action.payload.message;
        }
      )

      //GetAllStudentRequirementAction
      .addCase(adminGetAllStudentRequirementAction.pending, (state, action) => {
        state.student_requiremnet_loading = true;
        state.all_student_requirement = [];
      })
      .addCase(
        adminGetAllStudentRequirementAction.fulfilled,
        (state, action) => {
          state.student_requiremnet_loading = false;
          const {
            studentrequirement,
            resultperpage,
            totalstudentrequirement,
            filterstudentrequirementcount,
          } = action.payload;
          state.all_student_requirement = studentrequirement;
          state.resultperpage = resultperpage;
          state.filterstudentrequirementcount = filterstudentrequirementcount;
          state.totalstudentrequirementcount = totalstudentrequirement;
        }
      )
      .addCase(
        adminGetAllStudentRequirementAction.rejected,
        (state, action) => {
          state.student_requiremnet_loading = false;
          state.all_student_requirement = [];
        }
      )

      //adminGetAllHelpAndSupportAction
      .addCase(adminGetAllHelpSupportAction.pending, (state, action) => {
        state.help_and_support_loading = true;
        state.help_support = [];
      })
      .addCase(adminGetAllHelpSupportAction.fulfilled, (state, action) => {
        state.help_and_support_loading = false;
        const { helpandsupport, resultperpage, totalhelpandsupport } =
          action.payload;
        state.help_support = helpandsupport;
        state.resultperpage = resultperpage;
        state.totalhelpandsupportcount = totalhelpandsupport;
      })
      .addCase(adminGetAllHelpSupportAction.rejected, (state, action) => {
        state.help_and_support_loading = false;
        state.help_support = [];
      })

      //adminChangeStatusHelpAndSupport
      .addCase(adminChangeStatusHelpAndSupport.pending, (state, action) => {
        state.change_help_support_status_loading = true;
      })
      .addCase(adminChangeStatusHelpAndSupport.fulfilled, (state, action) => {
        state.change_help_support_status_loading = false;
        const { activeIndex } = action.payload;
        const { status, message } = action.payload.data;
        state.help_support[activeIndex].status = status;
        // state.help_support[activeIndex].message = message;
      })
      .addCase(adminChangeStatusHelpAndSupport.rejected, (state, action) => {
        state.change_help_support_status_loading = false;
        state.error = action.payload.message;
      })

      // adminGetAllContactAction
      .addCase(adminGetAllContactAction.pending, (state, action) => {
        state.contact_loading = true;
        state.contact = [];
      })
      .addCase(adminGetAllContactAction.fulfilled, (state, action) => {
        state.contact_loading = false;
        const { contact, resultperpage, totalcontactcount } = action.payload;
        state.contact = contact;
        state.resultperpage = resultperpage;
        state.totalcontactcount = totalcontactcount;
      })
      .addCase(adminGetAllContactAction.rejected, (state, action) => {
        state.contact_loading = false;
        state.contact = [];
      })

      // adminChangeStatusContactAction
      .addCase(adminChangeStatusContactAction.pending, (state, action) => {
        state.chnage_contact_status_loading = true;
      })
      .addCase(adminChangeStatusContactAction.fulfilled, (state, action) => {
        state.chnage_contact_status_loading = false;
        const { activeIndex } = action.payload;
        const { status } = action.payload.data;
        state.contact[activeIndex].status = status;
      })
      .addCase(adminChangeStatusContactAction.rejected, (state, action) => {
        state.chnage_contact_status_loading = false;
        state.error = action.payload.message;
      })

      // create event
      .addCase(adminCreateEventAction.pending, (state, action) => {
        state.adminCreateEventLoding = true;
      })
      .addCase(adminCreateEventAction.fulfilled, (state, action) => {
        state.adminCreateEventLoding = false;
      })
      .addCase(adminCreateEventAction.rejected, (state, action) => {
        state.adminCreateEventLoding = false;
        state.error = action.payload.message;
      })

      // edit event
      .addCase(adminEditEventAction.pending, (state, action) => {
        state.adminEditeEventLoding = true;
      })
      .addCase(adminEditEventAction.fulfilled, (state, action) => {
        state.adminEditeEventLoding = false;
        const { activeIndex } = action.payload;
        const { event } = action.payload.data;
        state.events[activeIndex] = event;
      })
      .addCase(adminEditEventAction.rejected, (state, action) => {
        state.adminEditeEventLoding = false;
        state.error = action.payload.message;
      })
      // adminGetAllEventAction
      .addCase(adminGetAllEventAction.pending, (state, action) => {
        state.event_loading = true;
        state.events = [];
      })
      .addCase(adminGetAllEventAction.fulfilled, (state, action) => {
        state.event_loading = false;
        const { event, resultperpage, totaleventcount } = action.payload;
        state.events = event;
        state.resultperpage = resultperpage;
        state.totaleventcount = totaleventcount;
      })
      .addCase(adminGetAllEventAction.rejected, (state, action) => {
        state.event_loading = true;
        state.events = [];
        state.error = action.payload.message;
      })

      // adminGetAllLogsAction
      .addCase(adminGetAllLogsAction.pending, (state, action) => {
        state.logs_loading = true;
        state.logs = [];
      })
      .addCase(adminGetAllLogsAction.fulfilled, (state, action) => {
        state.logs_loading = false;
        const { logs, totallogscount, resultperpage } = action.payload;
        state.logs = logs;
        state.logsresultperpage = resultperpage;
        state.totallogscount = totallogscount;
      })
      .addCase(adminGetAllLogsAction.rejected, (state, action) => {
        state.logs_loading = false;
        state.logs = [];
      })

      // adminCreateFaqAction
      .addCase(adminCreateFaqAction.pending, (state, action) => {
        state.adminCreateFaqLoading = true;
      })
      .addCase(adminCreateFaqAction.fulfilled, (state, action) => {
        state.adminCreateFaqLoading = false;
        const { faq } = action.payload;
        state.faqs.push(faq);
      })
      .addCase(adminCreateFaqAction.rejected, (state, action) => {
        state.adminCreateFaqLoading = false;
        state.error = action.payload.message;
      })

      // adminEditFaqAction

      .addCase(adminEditFaqAction.pending, (state, action) => {
        state.adminUpdateFaqLoading = true;
      })
      .addCase(adminEditFaqAction.fulfilled, (state, action) => {
        state.adminUpdateFaqLoading = false;
        const { faq } = action.payload.data;
        const { index } = action.payload;
        state.faqs[index] = faq;
      })
      .addCase(adminEditFaqAction.rejected, (state, action) => {
        state.adminUpdateFaqLoading = false;
        state.error = action.payload.message;
      })

      // adminGetAllFaq
      .addCase(adminGetAllFaq.pending, (state, action) => {
        state.get_faq_loading = true;
        state.faqs = [];
      })
      .addCase(adminGetAllFaq.fulfilled, (state, action) => {
        state.get_faq_loading = false;
        const { faq, resultperpage, totalfaqcount } = action.payload;
        state.faqs = faq;
        state.resultperpage = resultperpage;
        state.totalfaqcount = totalfaqcount;
      })
      .addCase(adminGetAllFaq.rejected, (state, action) => {
        state.get_faq_loading = false;
        state.faqs = [];
      })
      // get table by country
      .addCase(adminGetTableByCuntryAction.pending, (state, action) => {
        state.get_student_requirement_table_loading = true;
        state.tables = [];
      })
      .addCase(adminGetTableByCuntryAction.fulfilled, (state, action) => {
        state.get_student_requirement_table_loading = false;
        const { sudent_requirements_table } = action.payload;
        state.sudent_requirements_table = sudent_requirements_table;
      })
      .addCase(adminGetTableByCuntryAction.rejected, (state, action) => {
        state.get_student_requirement_table_loading = false;
        state.sudent_requirements_table = [];
      });
  },
});

export const { clearError } = adminSlice.actions;

export default adminSlice.reducer;
