import React from "react";
import { Box } from "@mui/material";
import { ImCross } from "react-icons/im";

import { AiFillCheckCircle } from "react-icons/ai";
import { FormInputB, FormSelectB } from "../../../../../../components/Index";
import { useDispatch } from "react-redux";
import { adminEditFlatemateAction } from "../../../../../../context/actions/admin/flatemateAction";
import { toast } from "react-toastify";
import {
  Ages,
  lookingIconForLst,
  genderlst,
} from "../../../../../../utils/flateMateSettings";
const LookingForChip = ({ item, selected, handleFormValue }) => {
  return (
    <Box
      sx={{
        fontSize: { xs: "0.9rem", md: "1.5em" },
      }}
      role="button"
      onClick={() => handleFormValue("lookingFor", item.name)}
      className="d-flex align-items-center rounded rounded-3 gap-1 p-2 position-relative border border-dark border-1 shadow shadow-1-soft"
    >
      <span>{item.icon}</span>
      <span>{item.name}</span>
      {item.name === selected ? (
        <AiFillCheckCircle
          className="position-absolute "
          style={{
            top: "2%",
            right: "2%",
            fonSize: "1.2em",

            color: "green",
          }}
        />
      ) : null}
    </Box>
  );
};

const PersonalInformation = ({
  firstName,
  lastName,
  age,
  lookingFor,
  onClose,
  gender,
  id,
  activeindex,
}) => {
  const value = {
    firstName: firstName,
    lastName: lastName,
    lookingFor: lookingFor,
    gender: gender,
    age: age,
  };

  const evalue = {
    firstName: "",
    lastName: "",
    lookingFor: "",
    gender: "",
    age: "",
    message: "",
  };

  const [formValue, setFormValue] = React.useState(value);
  const [errors, setError] = React.useState(evalue);

  const handleFormValue = (name, value) => {
    switch (name) {
      case "age":
        const birthTime = new Date(value).getTime();
        const nowTime = new Date().getTime();
        const ageInMilliseconds = nowTime - birthTime;
        const ageInYears = Math.floor(
          ageInMilliseconds / (1000 * 60 * 60 * 24 * 365)
        );
        setFormValue({ ...value, age: ageInYears });

        break;

      default:
        setFormValue({ ...formValue, [name]: value });
    }
  };

  const handleFormChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "age":
        setFormValue({ ...formValue, age: value });

        break;

      default:
        setFormValue({ ...formValue, [name]: value });
    }
  };

  const formValidation = () => {
    const { firstName, lastName, lookingFor, gender, age } = formValue;
    let isValid = false;
    let newerror = { ...evalue };
    if (
      !firstName ||
      !lastName ||
      !lookingFor ||
      !gender ||
      !age ||
      (age && age < 16)
    ) {
      if (!firstName) {
        newerror.firstName = "Please Enter first Name";
      }
      if (!lastName) {
        newerror.lastName = "Please Enter last Name";
      }

      if (!lookingFor) {
        newerror.lookingFor = "please";
      }
      if (!gender) {
        newerror.gender = "Please Enter Your Gender";
      }
      if (!age) {
        newerror.age = "Please enter you age";
      }
      if (age && age < 16) {
        newerror.age = "you must 16 or older";
      }
      newerror.message = "Invalid Credential";
      setError(newerror);
    } else {
      isValid = true;
    }

    return isValid;
  };
  const dispatch = useDispatch();
  const onSubmit = () => {
    const isValid = formValidation();
    if (isValid) {
    

      dispatch(
        adminEditFlatemateAction({
          id: id,
          formData: formValue,
          activeindex: activeindex,
          toast: toast,
        })
      );
      onClose();
    }
  };
  return (
    <div className="d-flex flex-column gap-2 rounded rounded-5">
      <Box
        sx={{
          borderBottom: "1px solid var(--hover-color)",
        }}
        className="d-flex align-items-center justify-content-between m-0"
      >
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Personal Information
        </span>
        <Box
          className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
          }}
          onClick={onClose}
        >
          <ImCross
            style={{
              fontSize: "1.3em",
            }}
          />
        </Box>
      </Box>

      <div className="d-flex flex-column gap-2 m-0 p-0">
        <span className="fs-5">I am looking property for</span>

        <div className="d-flex flex-wrap w-100 align-items-center">
          {lookingIconForLst.map((item) => {
            return (
              <div className="w-25 p-1">
                <LookingForChip
                  selected={formValue.lookingFor}
                  item={item}
                  key={item.name}
                  handleFormValue={handleFormValue}
                />
              </div>
            );
          })}
        </div>

        <div className="row-cols-2 row-cols-1 row gx-3">
          <div className="">
            <FormInputB
              name={"firstName"}
              label={"First Name"}
              error={errors.firstName ? true : false}
              placeholder={"Enter FirstName"}
              type={"text"}
              value={formValue.firstName}
              handleChange={handleFormChange}
              helpertext={errors.firstName}
            />
          </div>

          <div className="">
            <FormInputB
              name={"lastName"}
              label={"Last Name"}
              error={errors.lastName ? true : false}
              placeholder={"Enter LastName"}
              type={"text"}
              value={formValue.lastName}
              handleChange={handleFormChange}
              helpertext={errors.lastName}
            />
          </div>

          <Box sx={{}} className="">
            <FormSelectB
              list={genderlst}
              name={"gender"}
              label={"Gender"}
              error={errors.gender ? true : false}
              placeholder={"Enter Gender"}
              type={"text"}
              value={formValue.gender}
              handleChange={handleFormChange}
              helpertext={errors.gender}
            />
          </Box>

          <div className="">
            <FormSelectB
              name="age"
              label={"Age"}
              list={Ages}
              placeholder="Select Your Age"
              value={formValue.age}
              handleChange={handleFormChange}
              error={errors.age ? true : false}
              helpertext={errors.age}
            />
          </div>
        </div>
      </div>

      <button
        className="btn-dark btn-sm btn ms-auto justify-self-end"
        onClick={onSubmit}
      >
        Update
      </button>
    </div>
  );
};

export default PersonalInformation;
