import React from "react";

import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import org from "../../../../utils/org";
import Wrapper from "./OrganizationStyle";
import Overview from "./Overview";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Box, List, Modal } from "@mui/material";
import { BiPlusCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationProfile } from "../../../../context/actions/authAction";
import Agreements from "./Agreements";
import UserEditOrg from "./modal/UserEditOrg";
import {
  UserContactDetails,
  UserIntroductionTab,
  UserLstOfBoardMembers,
} from "./tabs/index";
const reviewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 800, xs: "97vw" },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  p: { xs: 1, sm: 2 },
};
const OrganizationTab = () => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(1);
  const [state, setState] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [reviewModal, setReviewModal] = React.useState(false);
  const toggleReviewModal = () => {
    setReviewModal((prev) => !prev);
  };

  const name = "he";
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  React.useEffect(() => {
    dispatch(getOrganizationProfile());
  }, [dispatch]);

  const { organization_profile } = useSelector((state) => ({
    organization_profile: state.user_reducer.organization_profile,
  }));

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="d-flex flex-wrap align-items-center justify-content-between flex-column flex-md-row w-100 m-0 p-0">
        <h4 className="fw-bold">Your orgaizational profile</h4>

        <div className="d-flex align-items-center gap-1">
          {organization_profile?.active && organization_profile?.status  && (
            <button
              className="btn btn-outline-dark"
              onClick={toggleReviewModal}
            >
              Edit Organization
            </button>
          )}
          {!organization_profile.introduction ? (
            <button
              className="btn btn-sm d-flex gap-2 align-items-center btn-dark"
              onClick={handleOpen}
            >
              List your organization
              <BiPlusCircle />
            </button>
          ) : organization_profile?.active && organization_profile?.status ? (
            <button className="btn  d-flex gap-2 align-items-center btn-outline-success">
              ORGANIZATION VERIFIED ✔️
            </button>
          ): !organization_profile?.active && organization_profile?.status ? (
            <button className="btn  d-flex gap-2 align-items-center bg-danger text-white">
              Disabled | Please contact admin
            </button>
          ) : organization_profile?.reviewMessage ? (
            <button
              className="btn  d-flex gap-2 align-items-center bg-danger text-white"
              onClick={toggleReviewModal}
            >
              Review Submit Application
            </button>
          ) : (
            <button className="btn btn-sm d-flex gap-2 align-items-center btn-outline-warning">
              <ArrowCircleUpIcon fontSize="small" />
              pending
            </button>
          )}
        </div>
      </section>
      <hr />
      <Wrapper className="position-relative p-0 mt-2">
        {organization_profile?.active && (
          <MenuIcon
            className="position-absolute  d-md-none text-dark bg-hover p-1 "
            sx={{
              color: "black",
              zIndex: "2",
              left: "3%",
              top: "2%",
            }}
            onClick={toggleDrawer(true)}
          />
        )}
        {organization_profile?.active && (
          <SwipeableDrawer
            anchor={"left"}
            open={state}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
              width: "400px",
            }}
            className="d-md-none"
          >
            <List
              className="p-0 m-0 organization-tabs list-unstyled "
              sx={{
                listStyle: "none",
              }}
            >
              <li
                className={value === 1 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(1)}
              >
                Overview
              </li>
              <li
                className={value === 2 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(2)}
              >
                Introduction
              </li>
              <li
                className={value === 3 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(3)}
              >
                Overview
              </li>
              <li
                className={value === 4 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(4)}
              >
                Contact Details
              </li>
              <li
                className={value === 5 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(5)}
              >
                Social Media
              </li>
              <li
                className={value === 6 ? "p-2 bg-hover" : "p-2"}
                onClick={() => handleChange(6)}
              >
                Other Information
              </li>
            </List>
          </SwipeableDrawer>
        )}
        {organization_profile?.active && (
          <div className="organization-left col-md-2 me-2 border rounded rounded-3 d-none d-md-block">
            <div className="d-flex flex-column">
              <ul className="p-0 m-0 organization-tabs">
                <li
                  className={
                    value === 1 ? "p-2 bg-hover rounded rounded-3" : "p-2"
                  }
                  onClick={() => handleChange(1)}
                >
                  Overview
                </li>
                <li
                  className={value === 2 ? "p-2 bg-hover" : "p-2"}
                  onClick={() => handleChange(2)}
                >
                  Introduction
                </li>
                <li
                  className={value === 3 ? "p-2 bg-hover" : "p-2"}
                  onClick={() => handleChange(3)}
                >
                  Contact Details
                </li>
                <li
                  className={value === 4 ? "p-2 bg-hover" : "p-2"}
                  onClick={() => handleChange(4)}
                >
                  Board Members
                </li>
                <li
                  className={value === 5 ? "p-2 bg-hover" : "p-2"}
                  onClick={() => handleChange(5)}
                >
                  Agreements
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="organization-right w-100 ">
          {value === 1 ? (
            <Overview
              organization_profile={organization_profile}
              reviewModal={reviewModal}
              toggleReviewModal={toggleReviewModal}
              open={open}
              handleOpen={handleOpen}
              handleClose={handleClose}
            />
          ) : null}
          {value === 2 ? (
            <UserIntroductionTab introduction={organization_profile?.introduction} />
          ) : null}
          {value === 3 ? (
            <UserContactDetails item={organization_profile?.contactAndLinks} />
          ) : null}
          {value === 4 ? <UserLstOfBoardMembers item={org} /> : null}
          {value === 5 ? <Agreements /> : null}
        </div>
      </Wrapper>
      <Modal
        open={reviewModal}
        onClose={toggleReviewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={reviewStyle}>
          {organization_profile?.user && (
            <UserEditOrg
              data={organization_profile}
              onClose={toggleReviewModal}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default OrganizationTab;
