const professions = [
  { id: "1", value: "Doctor" },
  { id: "2", value: "Nurse" },
  { id: "3", value: "Teacher" },
  { id: "4", value: "Maid" },
  { id: "5", value: "Trainer" },
  { id: "6", value: "Musician" },
];

export { professions };
