import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminEventCard from "./AdminEventCard";

import { useDispatch, useSelector } from "react-redux";
import {  Drawer } from "@mui/material";

import { adminGetAllEventAction } from "../../../../context/actions/admin/eventAction";
import AdminEditEvent from "./AdminEditEvent";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const AdminEventsAndGallary = () => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const [isOpen, setIsOpen] = React.useState(false);

  const [activeIndex, setActive] = React.useState(0);
  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const handleActive = (index) => {
    setActive(index);
    setIsOpen(true);
  }


  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(adminGetAllEventAction({}));
  }, []);

  const { events, event_loading, resultperpage, totaleventcount } = useSelector(
    (state) => ({
      events: state.admin_reducer.events,
      event_loading: state.admin_reducer.event_loading,
      resultperpage: state.admin_reducer.resultperpage,
      totaleventcount: state.admin_reducer.totaleventcount,
    })
  );

  console.log(events);

  return (
    <Box className="container mt-3">
      <Stack direction="row" justifyContent="space-between" spacing={4}>
        <div className="d-flex flex-column">
          <span className="fs-3 fw-bold text-black">Events & Gallary</span>
          <small>Post important events and program photos here</small>
        </div>
        <div>
          <button
            className="btn btn-outline-black"
            onClick={() => navigate("add")}
          >
            ADD EVENT
          </button>
        </div>
      </Stack>
      <div className="row row-cols-1 row-cols-md-3 g-3 mt-3">
        {events && events?.map((event, index) => (
          <div className="col">
            <AdminEventCard index={index} event={event} isActive={handleActive} />
          </div>
        ))}
      </div>

      <Drawer open={isOpen} anchor="right" onClose={toggleDrawer}>
        <AdminEditEvent 
        activeindex={activeIndex}
        id={events[activeIndex]?._id}
        
        data={events[activeIndex]} />
      </Drawer>
    </Box>
  );
};

export default AdminEventsAndGallary;
