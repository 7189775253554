import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  MdAlternateEmail,
  MdEditSquare,
  MdOutlineBathroom,
  MdOutlineBedroomChild,
} from "react-icons/md";
import { FaUserAlt, FaWifi } from "react-icons/fa";
import { BiCalendar } from "react-icons/bi";
import { TbPigMoney } from "react-icons/tb";
import { BsCheckCircle, BsArrowUpSquare, BsEye } from "react-icons/bs";

import Groups2Icon from "@mui/icons-material/Groups2";

import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  get_flatmate_by_user,
  editFlatemateByUser,
} from "../../context/actions/authAction";
import Modal from "@mui/material/Modal";

import {
  PersonalInformation,
  BasicInformation,
  EditDescription,
  AccomondationType,
  PreferedLocations,
  Feature,
  PropertyPreference,
} from "./modals";
import { RiParkingBoxLine } from "react-icons/ri";
import { AiOutlineGroup } from "react-icons/ai";
import moment from "moment/moment";
import { accomondationTypes } from "../../utils/flateMateSettings";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { toast } from "react-toastify";

const AccomondationCard = ({ name }) => {
  const icon = accomondationTypes.find((item) => item.name === name)?.icon;

  return (
    <div className="d-flex h-maxcontent align-items-center px-2 gap-1 py-1 border border-2 rounded rounded-pill">
      <span>{icon}</span>
      <span>{name}</span>
    </div>
  );
};

const FlateMateDetail = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(get_flatmate_by_user());
  }, []);

  const { user_flatmate_data, flatemate_loading } = useSelector(
    (state) => state.user_reducer
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "95vw", sm: "95vw", md: "70%", lg: "50%" },
    bgcolor: "white",
    borderRadius: "15px",

    boxShadow: 24,
    p: { xs: 2, md: 4 },
  };

  const modals = {
    personalInformation: false,
    basicInformation: false,
    editDescription: false,
    accomondationType: false,
    propertyPreference: false,
    feature: false,
    preferedLocations: false,
  };
  const [open, setOpen] = React.useState(modals);
  const handleModal = (name, value) => {
    setOpen({ ...open, [name]: value });
  };

  const [editImg, setEditImg] = React.useState({
    isEdit: false,
    pImg: `${process.env.REACT_APP_IMAGE_PATH}${user_flatmate_data?.userPic}`,
    file: "",
  });

  const [showMore, SetshowMore] = React.useState(false);
  // to show more/less description
  const toggleShowMore = () => {
    SetshowMore((prev) => !prev);
  };

  // if (
  //   (user_flatmate_data && Object?.keys(user_flatmate_data).length === 0) ||
  //   user_flatmate_data === undefined
  // ) {
  //   return <NotFoundPage />;
  // }

  const handleUser_flatmate_data = (name, value) => {
    switch (name) {
      case "imgedit":
        let blobURL = URL.createObjectURL(value.target.files[0]);
        setEditImg({
          isEdit: true,
          pImg: blobURL,
          file: value.target.files[0],
        });
        value.target.value = "";

        break;

      case "userPic":
        const data = new FormData();
        data.append("images", editImg.file);
        dispatch(
          editFlatemateByUser({
            id: user_flatmate_data?._id,
            formData: data,
            toast: toast,
          })
        );
        setEditImg({ ...editImg, isEdit: false });
        break;

      case "cancelPic":
        setEditImg({
          ...editImg,
          isEdit: false,
          pImg: "",
          file: "",
        });

        break;

      default:
        setEditImg({ ...editImg, isEdit: false, pImg: "" });
    }
  };
  return (
    <div className="container">
      <section className="rental-header">
        <span className="text-decoration-underline fs-5 fw-bold text-black">
          Your flatemate profile
        </span>
        {user_flatmate_data?.active ? (
          <div className="d-flex w-100 justify-content-between mt-1">
            <div className="d-flex flex-column">
              <span className="fs-2 text-success fw-bold m-0 p-0">
                Activated
              </span>

              <p className="">
                Your profile is activated, hence available for other user
              </p>
            </div>

            <div className="rental-actionbtn d-flex gap-2 align items-center">
              <button className="bg-danger text-white fw-bold btn h-maxcontent">
                Deactive Now
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex w-100 justify-content-between mt-1">
            <div className="d-flex flex-column">
              <span className="fs-2 text-danger fw-bold m-0 p-0">
                Deactivated
              </span>

              <p className="">
                You are not activated, hence invisible for other user
              </p>
            </div>

            <div className="rental-actionbtn d-flex gap-2 align items-center">
              <button className="bg-success text-white fw-bold btn h-maxcontent">
                Activate Now
              </button>
            </div>
          </div>
        )}
      </section>
      <hr />
      <section>
        <div className="d-flex flex-column flex-lg-row gap-5 w-100">
          <Box
            sx={{
              aspectRatio: "1/0.7",
              width: { xm: "100%", md: "80%", lg: "40%" },
            }}
            className=" rounded rounded-3 bg-black overflow-hidden position-relative"
          >
            <img
              src={
                editImg.pImg
                  ? editImg.pImg
                  : `${process.env.REACT_APP_IMAGE_PATH}${user_flatmate_data?.userPic}`
              }
              alt=""
              srcSet=""
              className="w-100 h-100 "
              style={{
                objectPosition: "center",
                objectFit: "contain",
              }}
            />

            <input
              type="file"
              name="userPic"
              id="userPic"
              accept="images/*"
              className="d-none"
              onChange={(e) => {
                handleUser_flatmate_data("imgedit", e);
              }}
            />
            {editImg.isEdit ? (
              <div className="row row-cols-2 gx-2 position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center">
                <button
                  className="btn bg-black text-white"
                  onClick={() => handleUser_flatmate_data("cancelPic", 0)}
                >
                  Cancel
                </button>
                <button
                  className="btn bg-success text-white"
                  onClick={() => handleUser_flatmate_data("userPic", 0)}
                >
                  Save
                </button>
              </div>
            ) : (
              <label
                htmlFor="userPic"
                className="position-absolute bottom-0 start-0 w-100 p-2 bg-dark  text-white text-center"
              >
                Edit Profile Picture
              </label>
            )}
          </Box>

          <Box className="w-100 fs-5 text-black d-flex flex-column gap-2 position-relative m-0 p-0">
            <Box
              className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
              role="button"
              sx={{
                top: "2%",
                right: "2%",
                width: "2.2rem",
                height: "2.2rem",
                backgroundColor: "black",
                color: "white",
              }}
              onClick={() => handleModal("personalInformation", true)}
            >
              <MdEditSquare />
            </Box>
            <p className="fs-1 text-black fw-bold text-nowrap p-0 m-0">
              {user_flatmate_data?.firstName} {user_flatmate_data?.lastName}
            </p>
            <div className="d-flex gap-2 align-items-center">
              <h6 className="m-0 p-0 h-maxcontent">Property for</h6>
              <button className="btn btn-sm btn-outline-black">
                {user_flatmate_data?.lookingFor}
              </button>
            </div>
            <div className="d-flex align-items-center gap-1">
              <FaUserAlt />
              <span>{user_flatmate_data?.gender}</span>
              <span>-</span>
              <span className="text-nowrap">
                {user_flatmate_data?.age} years old
              </span>
            </div>

            <div className="d-flex align-items-center gap-1">
              <MdAlternateEmail />
              <span className="text-decoration-underline">
                {user_flatmate_data?.email}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <BiCalendar />
              <strong className="text-nowrap">Created At :</strong>
              <span className="text-nowrap">
                {moment(user_flatmate_data?.createAt).format("DD MMM YYYY")}
              </span>
            </div>
            <div className="d-flex align-items-center gap-1">
              <BsEye />
              <strong className="text-nowrap">Total views :</strong>
              <span className="text-nowrap">
                {user_flatmate_data?.viewCount}
              </span>
            </div>
          </Box>
        </div>
      </section>

      <hr />

      <section className="row row-cols-1 row-cols-lg-3 row-cols-md-2 position-relative m-0 p-0">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("basicInformation", true)}
        >
          <MdEditSquare />
        </Box>
        <div className="row row-cols-2 ">
          <TbPigMoney
            style={{
              fontSize: "3.5em",
              // color: "black",
            }}
            className="col-2"
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Budget
            </Typography>
            <p>
              <strong>{user_flatmate_data?.price}</strong> per week
            </p>
          </div>
        </div>

        <div className="row row-cols-2 ">
          <BsCheckCircle
            style={{
              fontSize: "3.5em",
              // color: "black",
            }}
            className="col-2"
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Length of stay
            </Typography>
            <h5>
              <strong>{user_flatmate_data?.lengthOfStay}</strong>
            </h5>
          </div>
        </div>
        <div className="row row-cols-2  ">
          <BsArrowUpSquare
            style={{
              fontSize: "3.5em",
              // color: "black",
            }}
            className="col-2 "
          />
          <div className="d-flex flex-column col-10">
            <Typography variant="p" sx={{}}>
              Move in date
            </Typography>
            <h5>
              <strong>
                {" "}
                {moment(user_flatmate_data?.moveInDate).format("DD MMM YYYY")}
              </strong>
            </h5>
          </div>
        </div>
      </section>

      <hr />

      <section className="position-relative w-100 ">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("editDescription", true)}
        >
          <MdEditSquare />
        </Box>
        <p className="fs-5 fw-bold">About me</p>
        {showMore
          ? user_flatmate_data?.description
          : user_flatmate_data?.description?.slice(0, 400)}
        {user_flatmate_data?.description?.length > 400 ? (
          !showMore ? (
            <span
              onClick={toggleShowMore}
              className="text-black fw-bold"
              role="button"
            >
              ...ReadMore
            </span>
          ) : (
            <span
              onClick={toggleShowMore}
              className="text-black fw-bold"
              role="button"
            >
              ...ReadLess
            </span>
          )
        ) : null}
      </section>

      <hr />

      <section className="w-100 position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("propertyPreference", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Property Preference</span>
        <div className="d-flex gap-5 mt-3 w-100 flex-wrap">
          <div className="d-flex gap-2">
            <MdOutlineBedroomChild size={50} />
            <div>
              <p className="m-0">Room furnishing</p>
              <h5 className="fw-bold">{user_flatmate_data?.roomFurnishings}</h5>
            </div>
          </div>
          <div className="d-flex gap-2">
            <FaWifi size={50} />
            <div>
              <p className="m-0">Internet</p>
              <h5 className="fw-bold">{user_flatmate_data?.internet}</h5>
            </div>
          </div>
          <div className="d-flex gap-2">
            <MdOutlineBathroom size={50} />
            <div>
              <p className="m-0">Bathroom</p>
              <h5 className="fw-bold">{user_flatmate_data?.bathRoomType}</h5>
            </div>
          </div>
          <div className="d-flex gap-2">
            <RiParkingBoxLine size={50} />
            <div>
              <p className="m-0">Parking</p>
              <h5 className="fw-bold">{user_flatmate_data?.parking}</h5>
            </div>
          </div>
          <div className="d-flex gap-2">
            <AiOutlineGroup size={50} />
            <div>
              <p className="m-0">Max number of flatmates</p>
              <h5 className="fw-bold">{user_flatmate_data?.totalFlateMates}</h5>
            </div>
          </div>
        </div>
      </section>

      <hr />

      <section className="position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("accomondationType", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Preferred Accomondation</span>
        {user_flatmate_data?.teamUp ? (
          <div className="d-flex flex-wrap gap-2 align-items-center">
            {user_flatmate_data?.accomondationType?.map((item) => {
              return <AccomondationCard name={item} key={item} />;
            })}

            {user_flatmate_data?.haveProperty ? (
              <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-center px-2 h-maxcontent gap-1 py-1 border border-2 rounded rounded-pill text-danger border-danger">
                  <span>
                    <Groups2Icon />
                  </span>
                  <span className="text-nowrap">team-up</span>
                </div>
                <div className="d-flex align-items-center">
                  <Box
                    className="bg-hover shadow-1-primary shadow position-relative"
                    sx={{
                      width: "40px",
                      height: "40px",
                    }}
                  ></Box>
                  <Box className="bg-hover p-2" sx={{}}>
                    I have property <strong>on</strong>{" "}
                    {user_flatmate_data?.propertyLocation}{" "}
                    <span>{user_flatmate_data?.typeOfProperty}</span>
                    <span className="mx-1">-</span>
                    {user_flatmate_data?.propertyCapacity} persons
                  </Box>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </section>

      <hr />

      <section className="position-relative">
        <Box
          className="d-flex justify-content-center align-items-center position-absolute rounded-circle"
          role="button"
          sx={{
            top: "2%",
            right: "2%",
            width: "2.2rem",
            height: "2.2rem",
            backgroundColor: "black",
            color: "white",
          }}
          onClick={() => handleModal("preferedLocations", true)}
        >
          <MdEditSquare />
        </Box>
        <span className="fs-5 fw-bold">Preferred location</span>
        <div className="d-flex gap-1 flex-wrap">
          {user_flatmate_data?.preferedLocations?.map((item, index) => {
            return (
              <div
                className="d-flex h-maxcontent align-items-center px-2 gap-1 py-1 border border-2 rounded rounded-pill"
                key={index}
              >
                <span>
                  <FmdGoodOutlinedIcon />
                </span>
                <span>{item}</span>
              </div>
            );
          })}
        </div>
      </section>

      <Modal
        open={open.personalInformation}
        onClose={() => handleModal("personalInformation", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PersonalInformation
            gender={user_flatmate_data?.gender}
            firstName={user_flatmate_data?.firstName}
            lastName={user_flatmate_data?.lastName}
            age={user_flatmate_data?.age}
            lookingFor={user_flatmate_data?.lookingFor}
            gener={user_flatmate_data?.gender}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("personalInformation", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.basicInformation}
        onClose={() => handleModal("basicInformation", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BasicInformation
            budgetPerWeek={user_flatmate_data?.price}
            lengthOfStay={user_flatmate_data?.lengthOfStay}
            moveInDate={user_flatmate_data?.moveInDate}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("basicInformation", false)}
          />
        </Box>
      </Modal>

      <Modal
        open={open.editDescription}
        onClose={() => handleModal("editDescription", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <EditDescription
            description={user_flatmate_data?.description}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("editDescription", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.feature}
        onClose={() => handleModal("feature", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Feature
            id={user_flatmate_data?._id}
            feature={user_flatmate_data?.features}
            onClose={() => handleModal("feature", false)}
          />
        </Box>
      </Modal>

      <Modal
        open={open.propertyPreference}
        onClose={() => handleModal("propertyPreference", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PropertyPreference
            roomFurnishings={user_flatmate_data?.roomFurnishings}
            internet={user_flatmate_data?.internet}
            bathRoomType={user_flatmate_data?.bathRoomType}
            parking={user_flatmate_data?.parking}
            totalFlateMates={user_flatmate_data?.totalFlateMates}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("propertyPreference", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.accomondationType}
        onClose={() => handleModal("accomondationType", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AccomondationType
            accomondationType={user_flatmate_data?.accomondationType}
            teamUp={user_flatmate_data?.teamUp}
            id={user_flatmate_data?._id}
            haveProperty={user_flatmate_data?.haveProperty}
            typeOfProperty={user_flatmate_data?.typeOfProperty}
            propertyCapacity={user_flatmate_data?.propertyCapacity}
            propertyLocation={user_flatmate_data?.preferedLocations}
            onClose={() => handleModal("accomondationType", false)}
          />
        </Box>
      </Modal>
      <Modal
        open={open.preferedLocations}
        onClose={() => handleModal("preferedLocations", false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <PreferedLocations
            preferedLocations={user_flatmate_data?.preferedLocations}
            id={user_flatmate_data?._id}
            onClose={() => handleModal("preferedLocations", false)}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default FlateMateDetail;
