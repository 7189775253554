import React from "react";
import { Box, Typography } from "@mui/material";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
const RentalReview = ({ reviewImg, values }) => {
  return (
    <div className="">
            <p className="text-black fs-3 fw-bold p-0 m-0">Review your listing</p>

      <p>you can change it anytime</p>
      <div className="row-cols-1 row-cols-lg-4 row mt-2">
        <Box
          sx={{
            height: "300px",
          }}
          className="rounded rounded-3 overflow-hidden"
        >
          <img
            src={reviewImg}
            width={300}
            className=" h-100 object-cover rounded rounded-3"
          />
        </Box>
        <div className="d-flex flex-column">
          <span className="fs-2 text-black fw-bold">{values.title}</span>

          <div className="d-flex gap-2 align-items-center">
            <NotListedLocationOutlinedIcon />
            <Typography variant="span">
              {values.location.place_name}
            </Typography>
          </div>
          <Typography variant="span" className="fs-3">
            {values.price}$ / Month
          </Typography>
          <p>{values.description}</p>
        </div>
      </div>
    </div>
  );
};

export default RentalReview;
