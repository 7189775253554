const allProvince = [
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];
const Category = [
  "Animal Welfare",
  "Arts",
  "Business",
  "Children & Youth",
  "Civil Rights",
  "Community Development",
  "Culture",
  "Disabilities",
  "Disaster Relief",
  "Economic Development",
  "Environment",
  "Health",
  "Human Rights",
  "Humanitarian",
  "International",
  "Politics",
  "Religion",
  "Research",
  "Social Services",
  "Sports",
  "Technology",
  "Others",
];
const positionlist = ["CEO", "Founder", "Co-Founder", "Manager"];
export { allProvince, Category, positionlist };
