import React, { useEffect, useState } from "react";

import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminGetAllFlatemateAction } from "../../../../context/actions/admin/flatemateAction";
import AdminAddFlateMate from "./AdminAddFlateMate";
import FlateMateTable from "./FlateMateTable";

const AdminFlateMate = () => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("all");
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();

  const [flatematedata, setFlatematedata] = useState([]);

  const [activeComponent, setActiveComponent] = useState(<FlateMateTable />);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  // open add drawer
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const buttons = [
    <Button key="one">Added By User</Button>,
    <Button key="two">Added By Admin</Button>,
  ];
  useEffect(() => {
    dispatch(
      adminGetAllFlatemateAction({
        page: page,
        keyword: keyword,
      })
    );
  }, [dispatch, page, keyword]);

  const { flatemate_loading, flatemate, resultperpage, totalflatematecount } =
    useSelector((state) => state.admin_reducer);

  useEffect(() => {
    setFlatematedata(flatemate);
  }, [flatemate]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
            <div className="d-flex flex-column">
                <span className="fs-3 fw-bold text-black">
                  FlateMate - Showing listed flatemate 
                </span>
                <small>
                  Flatmate profile added by user and admin are shown here
                </small>
              </div>
              <div>
                <Button
                  sx={{ mr: 1 }}
                  variant={activeButton === "all" ? "contained" : "outlined"}
                  onClick={() => {
                    setActiveButton("all");
                    setActiveComponent(<FlateMateTable />);
                  }}
                >
                  All FlateMate
                </Button>

                <Button variant="outlined" onClick={toggleDrawer}>
                  ADD
                </Button>
              </div>
            </Stack>

            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <OutlinedInput
                sx={{}}
                size="small"
                defaultValue=""
                fullWidth
                placeholder="Search flateMate"
                startAdornment={
                  <InputAdornment position="start">
                    <SvgIcon color="action" fontSize="small">
                      <MagnifyingGlassIcon />
                    </SvgIcon>
                  </InputAdornment>
                }
                onChange={(event) => {
                  if (event.target.value === "") {
                    setKeyword("");
                  }
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    setKeyword(event.target.value);
                  }
                }}
              />
              <ButtonGroup color="primary" size="small" variant="outlined">
                {buttons}
              </ButtonGroup>
            </Stack>

            {activeButton === "all" ? (
              <FlateMateTable
                keyword={keyword}
                flatemate={flatematedata}
                resultperpage={resultperpage}
                totalflatematecount={totalflatematecount}
                loading={flatemate_loading}
                page={page}
                setPage={setPage}
              />
            ) : null}
          </Stack>
        </Container>

        <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
          <AdminAddFlateMate onClose={toggleDrawer} />
        </Drawer>
      </Box>
    </>
  );
};

export default AdminFlateMate;
