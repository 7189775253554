const org = {
  boardMembers: [
    {
      img: "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      name: "suman",
      position: "founder",
    },
    {
      img: "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      name: "sagun",
      position: "founder",
    },

    {
      img: "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      name: "suman",
      position: "founder",
    },
    {
      img: "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      name: "sagun",
      position: "founder",
    },
  ],
  contactAndLinks: {
    email: "JohnDoe@gmail.com",
    telephone: "2342342",
    phone: "234324",
    websiteLink: "sfsfdsdf",
    facebookLink: "234wfs",
    instagramLink: "sdfsfd",
    twitterLink: "sdfsfd",
    location:[]
  },
  introduction: {
    city: "kathmandu",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni modi quam repellendus distinctio iusto reprehenderit, porro, magnam, laboriosam recusandae facere culpa ullam!",
    documentBackpage:
      "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    documentFrontpage:
      "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    organizationName: "Nike",
    profile:
      "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    coverPage:
      "https://images.unsplash.com/photo-1473172707857-f9e276582ab6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    provenience: "Oranato",
  },
  isverified: false,
  _id: "sdfsdfwwerwersdf234234",
};

export default org;
