import React from "react";

const FormInputB = React.memo(({
  name,
  type,
  label,
  placeholder,  
  error,
  helpertext,
  required,
  handleChange,
  value,
}) => {
  return (
    <div className="form-group mt-2 w-100">
      <small className="mb-1 text-black" htmlFor={name}>{label}</small>
      <input
        type={type}
        className={error ? "form-control is-invalid py-2 m-0" : "form-control py-2"}
        id={name}
        name={name}
        aria-describedby="emailHelp"
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
        value={value}
      />
      <small id="emailHelp" className={error ? "form-text text-danger" : ""}>
        {helpertext}
      </small>
    </div>
  );
});

export default FormInputB;
