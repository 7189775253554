import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";

import { FaEdit, FaEllipsisV, FaTrash } from "react-icons/fa";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Drawer } from "@mui/material";
import AdminFlateMateDetail from "./flateMateDetail/AdminFlateMateDetail";
import { CancelOutlined, CheckOutlined } from "@mui/icons-material";

export default function FlatemateTable({
  keyword,
  flatemate,
  loading,
  totalflatematecount,
  resultperpage,
  page,
  setPage,
}) {
  const [activeIndex, setActive] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const onEdit = (value) => setActive(value);

  const options = [
    {
      value: "more",
      label: "more ",
      icon: <FaEllipsisV />,
    },
    {
      value: "edit",
      label: "Edit",
      icon: <FaEdit />,
    },
    {
      value: "delete",
      label: "Delete",
      icon: <FaTrash />,
    },
  ];
  const columns = [
    {
      field: "profile",
      headerName: "Profile Pic",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          alt="profile"
          style={{ width: "35px", height: "35px", borderRadius: "50%", objectFit: "cover" }}
        />
      ),
    },
    { field: "name", headerName: "Full Name", flex: 1 },
    { field: "lookingFor", headerName: "Looking For", flex: 1 },

    { field: "Location", headerName: "Location", flex: 1 },
    { field: "Category", headerName: "Category", flex: 1 },
    { field: "Views", headerName: "Views", flex: 1 },

    {
      field: "Price",
      headerName: "Budget",
      type: "number",
      flex: 1,
    },
    { field: "active", headerName: "Active", flex: 1 },
    {
      field: "featured",
      headerName: "Featured",
      width: 150,
      renderCell: (params) => {
        if (params.value) {
          return <CheckOutlined color="primary" />;
        } else {
          return <CancelOutlined color="error" />;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,

      renderCell: (params) => {
        const { id } = params.row;

        return (
          <Box
            sx={{
              height: "40px",
              width: "40px",
            }}
            className="p-2 border border-1 rounded rounded-4 border-dark d-flex justify-content-center align-items-center"
          >
            <VisibilityIcon
              onClick={() => {
                setActive(id);
                toggleDrawer();
              }}
              role="button"
            />
          </Box>
        );
      },
    },
  ];

  const mappedRows = flatemate?.map((flatemate, index) => ({
    id: index,
    index: index,
    profile: `${process.env.REACT_APP_IMAGE_PATH}${flatemate?.userPic}`,
    name: flatemate?.firstName,
    Location: flatemate?.preferedLocations[0],
    Price: flatemate?.price,
    Category: flatemate?.occupation,
    Views: flatemate?.viewCount,
    featured: flatemate?.featured,
    lookingFor: flatemate?.lookingFor,
  }));

  return (
    <div className="">
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={mappedRows}
          columns={columns}
          pageSize={resultperpage}
          loading={loading}
          page={page}
          rowCount={totalflatematecount}
          paginationMode="server"
          getRowId={(row) => row.id}
          onPageChange={(page) => {
            setPage(page);
          }}
        />
      </div>

      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminFlateMateDetail
          user_flatmate_data={flatemate[activeIndex]}
          activeindex={activeIndex}
          closeDrawer={toggleDrawer}
        />
      </Drawer>
    </div>
  );
}
