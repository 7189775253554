import React from 'react'

const SendPasswordResetLink = () => {
    const [email, setEmail] = React.useState('')
  return (
    <div className='container'>
        <h4>
            Send Password Reset Link
        </h4>

        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label htmlFor="">Enter Email address</label>
                    <input type="text" className="form-control" placeholder="Enter Email" onChange={
                        (e) => setEmail(e.target.value)
                    } />
                    <button className='btn btn-dark'>Send reset link</button>
                </div>
            </div>
        </div>



    </div>

  )
}

export default SendPasswordResetLink