import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";
import {
  AddLocation,
  FormInputB,
  IconInputB,
} from "../../../../../../../components/Index";

const AdminEditContactsAndLinks = ({
  handleLocation,
  handleformvalue,
  errors,
  value,
}) => {
  return (
    <div className={""}>
      <h4>Contact Information</h4>
      <div className="bg-hover p-1 p-lg-5 rounded rounded-2 ">
        <FormInputB
          name={"email"}
          handleChange={handleformvalue}
          value={value?.email}
          error={errors.email ? true : false}
          helpertext={errors.email}
          label={"Email"}
          type={"email"}
          classname={" w-100  form-control"}
          placeholder={"Enter Email Address"}
        />
        <FormInputB
          name={"telephonenumber"}
          handleChange={handleformvalue}
          error={errors.telephonenumber ? true : false}
          label={"Telephone Number"}
          helpertext={errors.telephonenumber}
          value={value?.telephonenumber}
          classname={" w-100  "}
          placeholder={"Enter Telephone Number"}
          type={"number"}
        />
        <FormInputB
          name={"phonenumber"}
          handleChange={handleformvalue}
          error={errors.phonenumber ? true : false}
          label={"Phonenumber"}
          helpertext={errors.phonenumber}
          value={value?.phonenumber}
          type={"number"}
          classname={" w-100  form-control"}
          placeholder={"Enter PhoneNumber"}
        />
        <p className="mt-2 ">Where is your organization located</p>
        {value.location?.location_place_name && (
          <div className="d-flex align-items-center my-2">
            <LocationOnIcon />
            <small>{value.location.location_place_name}</small>
          </div>
        )}
        <div className={errors.location ? "form-control is-invalid p-2" : ""}>
          <AddLocation handleLocation={handleLocation} />
          <small className={errors.location ? "form-text text-danger" : ""}>
            {errors.location}
          </small>
        </div>
      </div>

      <h4 className="mt-3 fw-bolder">Contact informations</h4>

      <div className="w-100 m-0 row row-cols-1 row-cols-md-2 bg-hover p-1 p-lg-5 rounded rounded-2 ">
        <IconInputB
          icon={<LanguageIcon />}
          required={true}
          name={"websitelink"}
          placeholder={"enter website link"}
          label={"Personal Website"}
          value={value.websitelink}
          handleChange={handleformvalue}
        />
        <IconInputB
          icon={<FacebookIcon />}
          required={true}
          name={"fblink"}
          placeholder={"enter FaceBook Link"}
          label={"FaceBook Link"}
          handleChange={handleformvalue}
          value={value.fblink}
        />
        <IconInputB
          icon={<InstagramIcon />}
          required={true}
          name={"instagramlink"}
          placeholder={"enter Instagram Link"}
          label={"Instagram Link"}
          handleChange={handleformvalue}
          value={value.instagramlink}
        />
        <IconInputB
          icon={<TwitterIcon />}
          required={true}
          name={"twitterlink"}
          placeholder={"enter Twitter Link"}
          label={"Twitter Link"}
          handleChange={handleformvalue}
          value={value.twitterlink}
          twitterlink
        />
      </div>
    </div>
  );
};

export default AdminEditContactsAndLinks;
