import React from "react";
import { Box, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import moment from "moment";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { DropdownEditDelete } from "../../Index";
import formatViewCount from "../../../utils/formatViewCount";
import { useDispatch, useSelector } from "react-redux";
import { post_bookmark } from "../../../context/actions/authAction";

const BCard = React.memo(({ item, index }) => {
  const dispatch = useDispatch();
  const [liked, setliked] = React.useState(false);
  const [type, setType] = React.useState("Blog");
  const [bookmark, setBookmark] = React.useState({
    name: "",
    type: "",
    image: "",
  });

  const { isAuth, bookmarks } = useSelector((state) => ({
    isAuth: state.auth_slice.isAuth,
    bookmarks: state.auth_slice.user_data?.bookmarks || [],
  }));

  // set the bookmark

  // set the bookmark
  React.useEffect(() => {
    if (item) {
      setBookmark({
        name: item.title,
        type: type,
        image: item.thumbnail,
      });
    }
  }, [item, type]);

  // check it is already bookmarked or not
  React.useEffect(() => {
    if (isAuth) {
      const isLiked = bookmarks?.find((bookm) => bookm.id === item?._id);
      if (isLiked) {
        setliked(true);
      } else {
        setliked(false);
      }
    }
  }, [isAuth, bookmarks, item?._id]);

  const togglelike = () => {
    if (isAuth) {
      dispatch(post_bookmark({ id: item?._id, data: bookmark }));
    }
  };

  return (
    <Box
      className="position-relative h-100"
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        "&:hover .card-title": {
          textDecoration: "underline",
        },

      }}

    >
      <Link to={`/blogs/${item._id}`}>
        <Box
          className="overflow-hidden w-100"
          sx={{
            height: "100%",
          }}
        >
          <Box
            className="carousel slide"
            data-mdb-interval="false"
            sx={{
              height: "min(190px , calc(130px + 5vw))",
            }}
          >
            <img
              src={`${process.env.REACT_APP_IMAGE_PATH}${item.thumbnail}`}
              className="d-block w-100 h-100 rounded rounded-3 object-cover"
              alt="Wild Landscape"
            />
          </Box>

          <Box
            className="d-flex flex-column gap-1 justify-content-between "
            sx={{
              lineHeight: "1.5rem",
            }}
          >
            <Typography
              className="mt-2 text-black"
              variant="p"
              sx={{
                lineHeight: "1.5",
                fontSize: "0.9rem",
                fontWeight: "400",
              }}
            >
              Posted on: {moment(item.createdAt).format("DD MMMM YYYY")}
            </Typography>
            <h6 className="card-title mb-3 mt-1 text-black ">
              {index === 0 ? item.title : item.title.slice(0, 60) + "..."}
            </h6>
            <Box
              sx={{
                height: "20px",
              }}
            ></Box>
          </Box>
        </Box>
      </Link>
      <Box
        className="d-flex align-items-center"
        sx={{
          position: "absolute",
          bottom: 0,
          right: "2%",
        }}
      >
        <Box className="d-flex text-black align-items-center ">
          {!liked ? (
            <FavoriteBorder
              onClick={togglelike}
              sx={{
                color: "#C1C1C1",
                fontSize: "clamp(0.7rem,1vw + 1em,1.6rem)",
              }}
            />
          ) : (
            <Favorite
              onClick={togglelike}
              sx={{
                color: "#252020",
                fontSize: "clamp(0.7rem,1vw = 1em,1.6rem)",
              }}
            />
          )}
        </Box>
        <VisibilityIcon
          sx={{
            color: "#C1C1C1",
            fontSize: "clamp(0.7rem,1vw + 2em,1.6rem)",
          }}
        />
        <Typography
          sx={{
            fontSize: "clamp(0.7rem,1vw,1.6rem)",
          }}
          className="text-black ms-1"
        >
          {formatViewCount(item.viewCount)}
        </Typography>
      </Box>

      <Box
        className=" bcard-option "
        sx={{
          position: "absolute",
          top: "2%",
          right: "2%",
          display: "none",
        }}
      >
        <DropdownEditDelete />
      </Box>

      <Box
        className=""
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <button
          className="btn btn-sm btn-outline-dark "
          data-mdb-ripple-color="dark"
        >
          {item.category}
        </button>
      </Box>
    </Box>
  );
});

export default BCard;