import React, { useState } from "react";
import Modal from "@mui/material/Modal";

import Box from "@mui/material/Box";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Chip from "@mui/material/Chip";
import {
  FormSelectB,
  FormTextAreaB,
  FormInputB,
  SelectProvince,
  SelectCity,
  SearchbarB,
} from "../../../../../../components/Index";
import { category } from "../../../../../../utils/bussinessSettings";

const EditBusinessIntroduction = ({
  value,
  handleformvalue,
  handleSubmit,

  errors,
  onprovinceselect,
  oncityselect,
  addoffer,
}) => {
  const style = {
    position: "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  let fliteredcategorylst = category.find(
    (item) => item.name === value.category
  );

  // modal close open state
  const [open, setOpen] = React.useState(false);

  // offer filtered according to category
  const [weoffer, setweoffer] = React.useState(fliteredcategorylst);

  const [saveWeoffer, setSaveWeOffer] = React.useState(fliteredcategorylst);

  // on modal open
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  // oncancel remove the changes
  const handleClose = (e) => {
    e.stopPropagation();
    const unsaveoffer = structuredClone(saveWeoffer);
    setweoffer(unsaveoffer);
    setlist(value.offers);
    setOpen(false);
  };

  // modal offers list
  const [list, setlist] = useState(value.offers);

  // remove or add items from modal list
  const addtolist = (value, index) => {
    const iscontained = list.includes(value);

    let newoffer = structuredClone(weoffer);
    let newlist = [];

    if (!iscontained) {
      newlist = [...list, value];
      newoffer.offer[index].selected = true;
    } else {
      newlist = list.filter((item) => item !== value);

      const objIndex = newoffer.offer.findIndex((obj) => obj.name === value);
      if (objIndex !== -1) {
        newoffer.offer[objIndex].selected = false;
      }

      // newoffer.offer[index].selected = false;
    }
    setweoffer({ ...newoffer, offer: [...newoffer.offer] });

    setlist(newlist);
  };
  // handle category change
  const changecategory = (e) => {
    handleformvalue(e);
    let fliteredcategorylst = category.find(
      (item) => item.name === e.target.value
    );
    setweoffer(fliteredcategorylst);
    setSaveWeOffer(fliteredcategorylst);
  };

  const addnewoffers = () => {
    addoffer(list);
    const savedoffer = structuredClone(weoffer);
    setSaveWeOffer(savedoffer);
    setOpen(false);
  };

  const removeOffers = (value, index) => {
    const iscontained = list.includes(value);

    let newoffer = { ...weoffer };
    let newlist = [];

    if (!iscontained) {
      newlist = [...list, value];
      newoffer.offer[index].selected = true;
    } else {
      newlist = list.filter((item) => item !== value);

      const objIndex = newoffer.offer.findIndex((obj) => obj.name === value);
      if (objIndex !== -1) {
        newoffer.offer[objIndex].selected = false;
      }

      // newoffer.offer[index].selected = false;
    }
    setweoffer({ ...newoffer, offer: [...newoffer.offer] });
    setSaveWeOffer({ ...newoffer, offer: [...newoffer.offer] });

    setlist(newlist);
    addoffer(newlist);
  };

  return (
    <div>
      <h2>Basic information</h2>
      <div className="bg-hover p-lg-5 p-3  rounded rounded-6 ">
        <FormInputB
          name={"businessName"}
          handleChange={handleformvalue}
          label={"BusinessName (Ex: ONF Canada Public Group)"}
          required={true}
          classname={"w-100 form-control"}
          placeholder={"Enter Business Name"}
          error={errors.businessName ? true : false}
          helpertext={errors.businessName}
          value={value.businessName}
        />

        <FormInputB
          name={"ownerName"}
          handleChange={handleformvalue}
          label={"Business owner name"}
          required={true}
          placeholder={"Enter Business Owner Name"}
          type={"text"}
          classname={" w-100  form-control"}
          error={errors.ownerName ? true : false}
          helpertext={errors.ownerName}
          value={value.ownerName}
        />
        <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
          <FormInputB
            name={"openingHour"}
            handleChange={handleformvalue}
            label={"Opening Hour"}
            required={true}
            placeholder={"Enter Opening Hour"}
            type={"time"}
            classname={" w-100  form-control"}
            error={errors.openingHour ? true : false}
            helpertext={errors.openingHour}
            value={value.openingHour}
          />
          <FormInputB
            name={"closingHour"}
            handleChange={handleformvalue}
            label={"Closing Hour"}
            required={true}
            placeholder={"Enter Closing Hour"}
            type={"time"}
            classname={" w-100  form-control"}
            error={errors.closingHour ? true : false}
            helpertext={errors.closingHour}
            value={value.closingHour}
          />
        </div>
        <div className="mt-3">
          <FormSelectB
            name={"category"}
            label={"Category"}
            handleChange={changecategory}
            disabled={false}
            list={category.map((obj) => obj.name)}
            placeholder={"Select Categories"}
            error={errors.category ? true : false}
            helpertext={errors.category}
            value={value.category}
          />
        </div>

        <div className="d-flex flex-column mt-2">
          {value.category.length > 0 && (
            <button
              onClick={handleOpen}
              className="btn bg-black rounded w-maxcontent rounded-pill text-capitalize my-2 text-white"
            >
              + Add Offers
            </button>
          )}
          <div className="d-flex flex-wrap gap-2">
            {value?.offers?.map((item, index) => {
              return (
                <Chip
                  label={item}
                  key={index}
                  size="small"
                  variant="outlined"
                  onDelete={() => removeOffers(item, index)}
                />
              );
            })}
          </div>
        </div>

        <div className="mt-3">
          <FormTextAreaB
            name={"description"}
            handleformvalue={handleformvalue}
            label={"Description (Introduce your business in brief)"}
            required={true}
            placeholder={"Briefly describe your Bussiness"}
            maxlength={1000}
            value={value?.description}
            className={"mt-3"}
            error={errors.description ? true : false}
            helpertext={errors.description}
          />
        </div>

        <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
          <FormInputB
            name={"establishedDate"}
            handleChange={handleformvalue}
            label={"Established date (Ex.2014)"}
            required={true}
            type={"date"}
            classname={" w-100  form-control"}
            error={errors.establishedDate ? true : false}
            helpertext={errors.establishedDate}
            value={value.establishedDate}
          />
        </div>

        <div className="d-flex gap-2 gap-md-5 flex-column flex-md-row">
          <SelectProvince
            onprovinceselect={onprovinceselect}
            name="proveniece"
            handleChange={handleformvalue}
            placeholder={"Select Province"}
            error={errors.proveniece ? true : false}
            helpertext={errors.proveniece}
            value={value.proveniece}
          />
          <SelectCity
            province={value.proveniece}
            city={value.city}
            handleChange={oncityselect}
            error={errors.city ? true : false}
            helpertext={errors.city}
          />
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="w-100">
            <SearchbarB placeholder={"Search offers"} />
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h2>{value.category}</h2>

          <div className="d-flex flex-wrap gap-1">
            {weoffer?.offer.map((item, index) => {
              return (
                <Box
                  sx={{
                    width: "max-content",
                  }}
                  key={index}
                  className="d-flex rounded rounded-pill  justify-content-center gap-2 py-2 px-3 bg-hover align-items-center"
                >
                  {!item.selected ? (
                    <CheckBoxOutlineBlankIcon
                      onClick={() => addtolist(item.name, index)}
                    />
                  ) : (
                    <CheckBoxIcon onClick={() => addtolist(item.name, index)} />
                  )}
                  <span>{item.name}</span>
                </Box>
              );
            })}
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap gap-1 my-2">
              {list?.map((item, index) => {
                return (
                  <Chip
                    label={item}
                    key={index}
                    size="small"
                    variant="outlined"
                    onDelete={() => addtolist(item, index)}
                  />
                );
              })}
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button className="btn btn-dark" onClick={handleClose}>
                cancel
              </button>
              <button className="btn btn-primary" onClick={addnewoffers}>
                Save
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditBusinessIntroduction;
