import customInstance from "./manage_access_route";
import API from "./common_api";

export const create_business = (data) =>
  customInstance.post("/api/business/create", data);
export const create_buiness_review = (data, id) =>customInstance.post(`/api/business/create_business_review/${id}`,data);

export const get_all_business = (keyword, page = 1, city, province, category) =>
  API.get(`/api/business/get_all_business?keyword=${keyword}&page=${page}&introduction.city=${city}&introduction.provenience=${province}&introduction.category=${category}`);


  // get all featured business
export const get_featured_business = () =>
  API.get("/api/business/get_all_featured_business");
export const get_business_details = (id) => API.get(`/api/business/get_business_details/${id}`);
