import { Divider, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const OrgCardSkeleton = () => {
    return (
        <div className="col">
            <Box
                sx={{
                    width: "100%",
                    height: "400px",
                }}
                className="text-center card col p-4 mt-3 position-relative"
            >
                <Box
                    sx={{
                        width: "100%",
                        height: "38%",
                        backgroundColor: "#c8c8c8",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        borderRadius: "10px 10px 0 0 ",
                    }}
                ></Box>
                <Skeleton
                    circle={true}
                    height={"calc(50px + 7vw)"}
                    width={"calc(50px + 7vw)"}
                    className="object-cover mt-3 mx-auto"
                />
                <h5 className="text-black mt-3">
                    <Skeleton />
                </h5>

                <Typography
                    className="mt-2 text-black"
                    variant="p"
                    sx={{
                        lineHeight: "1.5",
                        fontSize: "clamp(0.7rem,1vw,1rem)",
                    }}
                >
                    <Skeleton count={2} />
                </Typography>
                <Divider className="my-2" />
                <div className="d-flex justify-content-between mt-2  mt-auto">
                    <span>
                        <Skeleton width={80} height={20} />
                    </span>
                    <div className="org-two_interaction d-flex gap-2 align-items-center">
                        <div className="d-flex">
                            <Skeleton circle={true} height={20} width={20} />
                            <span className="text-black">
                                <Skeleton />
                            </span>
                        </div>
                        <div className="d-flex text-black">
                            <Skeleton circle={true} height={20} width={20} />
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default OrgCardSkeleton