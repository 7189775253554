

import ProtectApi from "./adminAuthorizeApi";
// get all help support
export const getAllHelpSupport = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/help/get_all_help/?keyword=${keyword}&page=${page}`
  );

  //change staus and message of help support
export const changeStatusHelpSupport = (id, data) =>
  ProtectApi.put(`/api/admin/help/change_status_and_message/${id}`, data);

