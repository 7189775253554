import { createSlice } from "@reduxjs/toolkit";

import {
  CreateProfessionalAction,
  GetAllProfessionalAction,
  CreateProfessionalReviewAction,
  GetProfessionalDetailAction,
} from "../actions/professionalAction";

const initialState = {
  loading_professional: false,
  loading_professional_detail: false,
  createProfessionalLoading: false,
  reviewLoading: false,
  professional: [],
  professional_detail: {},
  professional_comments: [],
  avg_rating: 0,
  totalprofessionalcount: 0,
  filterprofessionalcount: 0,
  resultperpage: 0,
  error: "",
};

const professionalSlice = createSlice({
  name: "professional",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder

      // create professional
      .addCase(CreateProfessionalAction.pending, (state, action) => {
        state.createProfessionalLoading = true;
      })
      .addCase(CreateProfessionalAction.fulfilled, (state, action) => {
        state.createProfessionalLoading = false;
      })
      .addCase(CreateProfessionalAction.rejected, (state, action) => {
        state.createProfessionalLoading = false;
        state.error = action.payload.message;
      })

      .addCase(GetAllProfessionalAction.pending, (state, action) => {
        state.loading_professional = true;
      })
      .addCase(GetAllProfessionalAction.fulfilled, (state, action) => {
        const {
          professional,
          resultperpage,
          filterprofessionalcount,
          totalprofessionalcount,
        } = action.payload;
        state.loading_professional = false;
        state.professional = professional;
        state.resultperpage = resultperpage;
        state.filterprofessionalcount = filterprofessionalcount;
        state.totalprofessionalcount = totalprofessionalcount;
      })
      .addCase(GetAllProfessionalAction.rejected, (state, action) => {
        state.loading_professional = false;
        state.professional = [];
      })

      // get professional detail

      .addCase(GetProfessionalDetailAction.pending, (state, action) => {
        state.loading_professional_detail = true;
      })
      .addCase(GetProfessionalDetailAction.fulfilled, (state, action) => {
        state.loading_professional_detail = false;
        const { professional } = action.payload;
        state.professional_detail = professional;
        state.professional_comments = professional.reviews;
        state.avg_rating = professional.averageRating;
      })
      .addCase(GetProfessionalDetailAction.rejected, (state, action) => {
        state.loading_professional_detail = false;
      })

      // create professional review
      .addCase(CreateProfessionalReviewAction.pending, (state, action) => {
        state.reviewLoading = true;
      })
      .addCase(CreateProfessionalReviewAction.fulfilled, (state, action) => {
        state.reviewLoading = false;
        const { review, avg_rating } = action.payload;
        state.professional_comments = review;
        state.avg_rating = avg_rating;
      })
      .addCase(CreateProfessionalReviewAction.rejected, (state, action) => {
        state.reviewLoading = false;
      });
  },
});

export const { clearError } = professionalSlice.actions;

export default professionalSlice.reducer;
