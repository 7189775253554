import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";

import VerifiedIcon from "@mui/icons-material/Verified";
import { FormInput } from "../../../components/Index";
import { ProfilePic } from "../components";

import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import InputCountry from "../../../components/Input/InputCountry";
import { EditProfileAction } from "../../../context/actions/authAction";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "97vw", md: 600 },
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  height: { xs: 400, md: "auto" },
};

const EditProfile = ({ open, handleClose, data }) => {
  console.log(data);
  const dispatch = useDispatch();
  const formvalues = {
    firstname: data?.firstname,
    email: data?.email,
    lastname: data?.lastname,
    phonenumber: data?.phone,
    country: data?.country,
    city: data?.city,
    profilePicture: "",
  };
  const [errors, setErrors] = React.useState({});
  const [formvalue, setformvalue] = React.useState(formvalues);

  const handleChange = (e) => {
    setformvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleChanges2 = (event, value) => {
    setformvalue({ ...formvalue, country: value, city: "" });
  };

  const validateForm = ({
    firstname,
    lastname,
    email,
    city,
    phonenumber,
    country,
  }) => {
    const errors = {};
    let formIsValid = true;

    if (!firstname) {
      formIsValid = false;
      errors.firstname = "First name is required *";
    }

    if (!lastname) {
      formIsValid = false;
      errors.lastname = "Last name is required *";
    }

    const emailRegex = /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9_-]+)\.([a-zA-Z]{2,5})$/;

    if (!emailRegex.test(email)) {
      formIsValid = false;
      errors.email = "Please enter valid email-ID *";
    }

    if (!country) {
      formIsValid = false;
      errors.country = "Country is required *";
    }

    if (!city) {
      formIsValid = false;
      errors.city = "City is required *";
    }

    if (!phonenumber) {
      formIsValid = false;
      errors.phonenumber = "Confirm your phonenumber *";
    }

    setErrors(errors);
    return formIsValid;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const firstname = formvalue.firstname;
    const lastname = formvalue.lastname;
    const email = formvalue.email;
    const phonenumber = formvalue.phonenumber;
    const city = formvalue.city;
    const country = formvalue.country;
    const profilePicture = formvalue.profilePicture;

    // change this to your own validation
    const isValid = validateForm({
      firstname,
      lastname,
      email,
      city,
      phonenumber,
      country,
    });

    if (isValid) {
      const formData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        country: country,
        city: city,
        phone: phonenumber,
        userpic: profilePicture,
      };
      dispatch(EditProfileAction({ formData: formData, toast: toast }));
    }
  };
  const closemodal = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const saveprofilepic = (value) => {
    console.log(value);
    setformvalue({ ...formvalue, profilePicture: value });
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            overflow: "hidden",
            height: "100%",
            overflowY: "scroll",
            // hide scrollbar
            scrollbarWidth: 0,
          }}
        >
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-5 fw-bold text-black">Edit your profile</span>
              <small>
                Change your<span className="text-danger"> information </span>{" "}
                save a new one
              </small>
            </div>
            <CancelIcon role="button" onClick={closemodal} />
          </div>
          <hr className="my-3" />
          <form
            action=""
            onSubmit={onSubmit}
            noValidate
            className="d-flex flex-column gap-3"
          >
            <div className="d-flex flex-column align-items-center w-100 justify-content-center">
              <ProfilePic
                image={`${process.env.REACT_APP_IMAGE_PATH}${data?.profilepic}`}
                id={"profilepicedit"}
                errors={errors.ProfilePic}
                onfilesave={saveprofilepic}
              />

              {/* {errors.profilePicture && (
              <FormHelperText
                className="ps-3"
                sx={{
                  color: "#F45757",
                }}
              >
                Profile Picture is required *
              </FormHelperText>
            )} */}

              <div className="text-center ">
                <p className="mt-2 text-center">
                  {formvalue?.firstname?.length === 0
                    ? data.firstname
                    : formvalue.firstname}
                  &nbsp;
                  {formvalue?.lastname?.length === 0
                    ? data?.lastname
                    : formvalue?.lastname}
                </p>
                <p className="mt-2 text-center bg-hover p-1 d-flex align-items-center">
                  {formvalue?.email} &nbsp;
                  <VerifiedIcon
                    sx={{
                      color: "#16C0FE",
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex flex-column flex-lg-row w-100 gap-2  align-items-start">
                <FormInput
                  required={true}
                  name={"firstname"}
                  label={"First Name"}
                  helperText={errors.firstname}
                  type={"text"}
                  classname={"w-100"}
                  handleChange={handleChange}
                  error={errors.firstname ? true : false}
                  value={formvalue?.firstname}
                />
                <FormInput
                  name={"lastname"}
                  label={"Last name"}
                  type={"text"}
                  required={true}
                  error={errors.lastname ? true : false}
                  helperText={errors.lastname}
                  classname={"w-100"}
                  handleChange={handleChange}
                  value={formvalue?.lastname}
                />
              </div>
              <div className="d-flex flex-column flex-lg-row w-100 gap-2  align-items-start">
                <FormInput
                  name={"email"}
                  label={"Email Address"}
                  type={"email"}
                  required={true}
                  error={errors.email ? true : false}
                  helperText={errors.email}
                  classname={"w-100"}
                  handleChange={handleChange}
                  value={formvalue?.email}
                />
                <FormInput
                  name={"phonenumber"}
                  label={"Phone Number"}
                  type={"tel"}
                  required={true}
                  error={errors.phonenumber ? true : false}
                  helperText={errors.phonenumber}
                  classname={"w-100"}
                  handleChange={handleChange}
                  value={formvalue.phonenumber}
                />
              </div>
              <div className="d-flex flex-column flex-lg-row w-100   gap-2 align-items-start">
                <div className="w-100">
                  <InputCountry
                    label={"Choose your country"}
                    handleChange={handleChanges2}
                    required={true}
                    selectedOptions={"Nepal"}
                    error={errors.country ? true : false}
                    helperText={errors.country}
                  />
                </div>
                <div className="w-100">
                  <FormInput
                    name={"city"}
                    label={"City"}
                    type={"text"}
                    required={true}
                    error={errors.city ? true : false}
                    helperText={errors.city}
                    classname={"w-100"}
                    handleChange={handleChange}
                    value={formvalue.city}
                  />
                </div>
              </div>
            </div>

            <div className="bottom-0 position-sticky d-flex justify-content-center bg-white w-100">
              <button className="btn-dark w-100 " type="submmi">
                Save Changes
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditProfile;
