
import customInstance from "./manage_access_route";
import API from "./common_api";

export const register_org = (data) => customInstance.post("/api/organization/create", data);
export const get_organization = (keyword, page = 1, city, province, category) =>
  API.get(
    `/api/organization/get_all_organization?keyword=${keyword}&page=${page}&introduction.city=${city}&introduction.provenience=${province}&introduction.category=${category}`
  );
// get all featured organization
export const get_featured_organization = () =>
  API.get("/api/organization/get_all_featured_organization");
export const get_organization_details = (id) => API.get(`/api/organization/get_organization_details/${id}`);

export const send_otp = (data) => API.post("/api/organization/send_otp", data);
export const verify_otp = (data) => API.post("/api/organization/verify_otp", data);
