import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllFaqAction } from "../../context/actions/faqAction";

const FaqsHome = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getAllFaqAction());
  }, [dispatch]);

  const { faqs, loading } = useSelector((state) => ({
    ...state.faq_reducer,
  }));
  return (
    <div className="container mt-2">
      <div className="card border shadow-0 mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between flex-wrap-reverse">
            <div className="p-5">
              <h4 class="card-title text-danger fs-3 fw-bold">
                Frequently Asked Questions
              </h4>
              <p class="card-text">
                Question that asked while using ONF Canada Please check !!!
              </p>
            </div>
          </div>
          <div className="accordion" id="accordionExampleY">
            {faqs?.map((faq, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading${index}Y`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-mdb-toggle="collapse"
                    data-mdb-target={`#collapse${index}Y`}
                    aria-expanded="true"
                    aria-controls={`collapse${index}Y`}
                  >
                    <i className="fas fa-question-circle fa-sm me-2 opacity-70"></i>
                    {faq?.title}
                  </button>
                </h2>
                <div
                  id={`collapse${index}Y`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${index}Y`}
                  data-mdb-parent="#accordionExampleY"
                >
                  <div className="accordion-body">
                    <strong>{faq?.description}</strong>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqsHome;
