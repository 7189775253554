import { createSlice } from "@reduxjs/toolkit";
import {
  CreateBusinessAction,
  getAllBusinessAction,
  getFeaturedBusinessAction,
  getBusinessDetailsAction,
  createBusinessReviewAction
} from "../actions/businessAction";

const initialState = {
  createBusinessLoading: false,
  loading: false,
  featuredLoading: false,
  reviewLoading :false,
  business: [],
  featured_business: [],
  business_comments: [],
  resultperpage: 0,
  filteredbusinessCount: 0,
  totalbusinesscount: 0,
  avg_rating: 0,
  error: "",
  business_details: null,
  
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    clearError: (state, action) => {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create business
      .addCase(CreateBusinessAction.pending, (state, action) => {
        state.createBusinessLoading = true;
      })
      .addCase(CreateBusinessAction.fulfilled, (state, action) => {
        state.createBusinessLoading = false;
        
      })
      .addCase(CreateBusinessAction.rejected, (state, action) => {
        state.createBusinessLoading = false;
        state.error = action.payload.message;
      })

      .addCase(getAllBusinessAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getAllBusinessAction.fulfilled, (state, action) => {
        // const existingIds = state.data.map((business) => business._id);
        // const uniqueBusinesses = action.payload.business.filter(
        //   (business) => !existingIds.includes(business._id)
        // );
        // return {
        //   ...initialState,
        //   data: [...state.data, ...uniqueBusinesses],
        //   resultperpage: action.payload.resultperpage,
        //   filteredbusinessCount: action.payload.filteredbusinesscount,
        //   totalbusinesscount: action.payload.totalbusinesscount,
        //   loading: false,
        // };
        state.loading = false;
        const {
          business,
          resultperpage,
          filteredbusinesscount,
          totalbusinesscount,
        } = action.payload;
        state.business = business;
        state.resultperpage = resultperpage;
        state.filteredbusinessCount = filteredbusinesscount;
        state.totalbusinesscount = totalbusinesscount;
      })

      .addCase(getAllBusinessAction.rejected, (state, action) => {
        state.loading = false;
        state.business = [];
        state.error = action.payload.message;
      })

      // get all featured business
      .addCase(getFeaturedBusinessAction.pending, (state, action) => {
        state.featuredLoading = true;
      })

      .addCase(getFeaturedBusinessAction.fulfilled, (state, action) => {
        state.featuredLoading = false;
        const { featuredBusiness } = action.payload;
        state.featured_business = featuredBusiness;
      })

      .addCase(getFeaturedBusinessAction.rejected, (state, action) => {
        state.featuredLoading = false;
        state.featured_business = [];
        state.error = action.payload.message;
      })

      // get business details
      .addCase(getBusinessDetailsAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBusinessDetailsAction.fulfilled, (state, action) => {
        const { business } = action.payload;
        state.loading = false;
        state.business_details = business;
        state.business_comments = business.reviews;
        state.avg_rating = business.averageRating;
      })
      .addCase(getBusinessDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      // create a new business review
      .addCase(createBusinessReviewAction.pending, (state, action) => {
        state.reviewLoading=true;
      })
      .addCase(createBusinessReviewAction.fulfilled, (state, action) => {
        state.reviewLoading = false;
        const { reviews } = action.payload;
        state.business_comments = reviews;
        state.avg_rating = action.payload.avg_rating;
        
      })
      .addCase(createBusinessReviewAction.rejected, (state, action) => {
          state.reviewLoading = false;
      })
  },
});

export const { clearError } = businessSlice.actions;

export default businessSlice.reducer;
