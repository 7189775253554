import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const TenantAccessCard = ({ item, selected, handleAccessType }) => {
  const { name, icon, description } = item;

  return (
    <div
      role="button"
      onClick={() => handleAccessType("accessTo", name)}
      className={
        selected === name
          ? "d-flex p-2 mb-3  justify-content-center flex-column border border-2 border-dark rounded rounded-3 text-start position-relative"
          : "d-flex p-2 mb-3  justify-content-center flex-column border border-2 rounded rounded-3 text-start position-relative"
      }
    >
      <div className="d-felx"></div>
      <div className="m-3">
        <div className="d-flex justify-content-between">
          
          <div>
            <h6 className="fw-bold p-0 m-0">{name}</h6>
            <p>{description}</p>
          </div>
          <div>{icon}</div>
        </div>
      </div>

      <CheckCircleOutlineIcon
        className={selected === name ? "d-flex" : "d-none"}
        sx={{
          position: "absolute",
          top: "6%",
          right: "1%",
        }}
      />
    </div>
  );
};

export default TenantAccessCard;
