import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, ButtonGroup, Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

import Loader from "../../../../../components/loader/Loader";
import Chip from "@mui/material/Chip";
import LaunchIcon from "@mui/icons-material/Launch";
import { BsCalendar2Date, BsPersonSquare } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { SlPhone } from "react-icons/sl";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";

import HTMLReactParser from "html-react-parser";
import { ImCross } from "react-icons/im";

import {
  ArrowDownward,
  ArrowUpward,
  Bookmark,
  RemoveRedEye,
} from "@mui/icons-material";
import ShowLocation from "../../../../../components/location/ShowLocation";
import BoardMembers from "./components/AdminReviewBoardMembers";
import { useDispatch } from "react-redux";
import {
  adminVerifyOrganizationAction,
  adminGiveReviewOrganizationAction,
} from "../../../../../context/actions/admin/organizationAction";
import { toast } from "react-toastify";

import styled from "styled-components";

const Wrapper = styled.div`
  & > .image-section {
    & > .overview-header {
      width: 100%;
      height: 30vh;

      position: relative;
      display: flex;
      align-items: end;
      justify-content: end;

      & > .overview-img {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(30vh / 2.1 + 1.5vw);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
      }
      & > .info {
        position: absolute;
        top: calc(30vh / 2);
      }
    }

    & > .overview-info {
      line-height: 5px;
    }
  }
`;

const AdminOrganizationDetailReview = ({ data, closeModal, activeindex }) => {
  // get id from url
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const [comment, setcomment] = React.useState("");
  const [profilepic, setPfile] = React.useState("");

  const commentchange = (e) => {
    if (comment.length < 300) {
      setcomment(e.target.value);
    }
  };
  const handlegiveReview = (e) => {
    e.preventDefault();
    dispatch(
      adminGiveReviewOrganizationAction({
        id: data._id,
        data: comment,
        toast,
        activeindex,
      })
    );
    setcomment("");
  };

  const handleApprove = (e) => {
    e.preventDefault();
    const options = {
      status: true,
      active: true,
    };
    dispatch(
      adminVerifyOrganizationAction({
        id: data._id,
        data: options,
        toast,
        activeindex,
      })
    );
  };
  // use dispatch to dispatch action

  // use callback to get organization details to memoize the function

  // call getOrganizationDetails function on component mount

  // use useSelector to get data from store organization details
  const { introduction, contactAndLinks, boardMembers, viewCount } = data;
  // toggle show more function

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Container
      sx={{
        paddingBlock: "3vh",
        overflow: "hidden",
        overflowY: "scroll",
        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
    >
      <Box>
        <section className="rental-header">
          <div className="d-flex align-items-center justify-content-between m-0">
            <span className="text-decoration-underline fs-5 fw-bold text-black">
              Organization Reivew Dashboard
            </span>
            <Box
              className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50px",
              }}
              onClick={closeModal}
              role="button"
            >
              <ImCross
                style={{
                  fontSize: "1.3em",
                }}
              />
            </Box>
          </div>
        </section>
        <hr />
        <Wrapper>
          <section className=" profile image-section">
            <div className="overview-header w-100  p-0">
              <div className="overview-img  w-100 ">
                <img
                  src={`${process.env.REACT_APP_IMAGE_PATH}${introduction.coverPage}`}
                  alt=""
                  srcSet=""
                  className="object-cover w-100  "
                />
              </div>
              <div className="info d-flex w-100 align-items-center mt-auto">
                <Box
                  className="profilepic  ms-4 position-relative "
                  sx={{
                    transform: " translateY(-5vh)",
                  }}
                >
                  <img
                    alt="Remy Sharp"
                    src={`${process.env.REACT_APP_IMAGE_PATH}${introduction.profile}`}
                    style={{
                      height: "calc(50px + 7vw)",
                      width: "calc(50px + 7vw)",
                      maxHeight: "180px",
                      maxWidth: "180px",
                      margin: "auto",
                      cursor: "pointer",
                      borderRadius: "50%",
                      border: "10px solid #f1f1f1",
                    }}
                    className="object-cover object-center"
                  />
                </Box>
                <Box
                  className="d-flex justify-content-between w-100"
                  sx={{
                    height: "40%",
                    marginLeft: "10px",
                  }}
                >
                  <Box>
                    <h2 className="p-0 m-0">{introduction.organizationName}</h2>
                    <h6 className="p-0 pt-1">
                      {introduction.provenience} | {introduction.city}
                    </h6>
                    <div className="d-flex gap-2 mt-1">
                      <Chip
                        icon={<FacebookIcon />}
                        label="Facebook"
                        variant="outlined"
                        onClick={() => {
                          window.open(contactAndLinks.facebookLink);
                        }}
                        size="small"
                      />
                      <Chip
                        icon={<TwitterIcon />}
                        label="Twitter"
                        variant="outlined"
                        onClick={() => {
                          window.open(contactAndLinks.twitterLink);
                        }}
                        size="small"
                      />
                      <Chip
                        icon={<LaunchIcon />}
                        label="Website"
                        variant="outlined"
                        onClick={() => {
                          window.open(contactAndLinks.websiteLink);
                        }}
                        size="small"
                      />
                    </div>
                  </Box>
                  <Box
                    sx={{
                      height: "40%",
                      marginLeft: "10px",
                    }}
                  >
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined button group"
                    >
                      <Button>
                        <div className="comments d-flex align-items-center gap-1">
                          <RemoveRedEye />
                          {viewCount}
                        </div>
                      </Button>
                      <Button>
                        <div className="clapped d-flex align-content-center gap-1">
                          <Bookmark />
                        </div>
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-md-8">
              <section className="org-three col-lg-10 mt-5 text-hover">
                <h5 className="fw-bold">General Information</h5>
                <div className="d-flex gap-5 mt-4">
                  <div className="d-flex gap-2">
                    <BsCalendar2Date size={40} />
                    <div>
                      <h6 className="m-0">Founded</h6>
                      <h5>Jan 10, 2014</h5>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <BsPersonSquare size={40} />
                    <div>
                      <h6 className="m-0">Founded by</h6>
                      <h5>Ram Chandra</h5>
                    </div>
                  </div>
                </div>
              </section>
              <section className="mt-4">
                <h5 className="fw-bold">About organizations</h5>
                <p>
                  {showMore
                    ? typeof introduction?.description === "string" &&
                      HTMLReactParser(introduction?.description)
                    : typeof introduction?.description === "string" &&
                      HTMLReactParser(introduction?.description.slice(0, 500))}
                </p>
                <Button
                  onClick={toggleShowMore}
                  disableRipple
                  className="w-100"
                  sx={{
                    borderBlock: "1px solid grey",
                    borderRadius: "0px",
                  }}
                >
                  {showMore ? (
                    <p>
                      show less <ArrowUpward />
                    </p>
                  ) : (
                    <p>
                      show more <ArrowDownward />
                    </p>
                  )}
                </Button>
              </section>
              <section className="mt-4">
                <h5 className="fw-bold">Actve Board Members</h5>
                <div className="row row-cols-1 row-cols-md-4 g-2">
                  {boardMembers.map((boardMember) => {
                    return (
                      <div key={boardMember} className="col">
                        <BoardMembers data={boardMember} />
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
            <div className="col-md-4">
              <section className="mt-5">
                <h5 className="fw-bold">Get in touch | Contacts</h5>
                <div className="d-flex gap-3 mt-3">
                  <MdMarkEmailUnread size={45} color="#4e514d" />
                  <div>
                    <h6 className="m-0">General Email</h6>
                    <p className="fs-5 fw-bold">{contactAndLinks.email}</p>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-3">
                  <SlPhone size={40} color="#dd2727" />
                  <div>
                    <h6 className="m-0">Phone Number</h6>
                    <p className="fs-5 fw-bold">{contactAndLinks.phone}</p>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-3">
                  <FaFacebook size={40} color="#4267B2" />
                  <div>
                    <h6 className="m-0">Facebook | Social Media</h6>
                    <p className="fs-5 fw-bold">
                      {contactAndLinks.twitterLink}
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-3 mt-3">
                  <AiFillTwitterCircle size={40} color="#1DA1F2" />
                  <div>
                    <h6 className="m-0">Twitter | Social Media</h6>
                    <p className="fs-5 fw-bold">
                      {contactAndLinks.twitterLink}
                    </p>
                  </div>
                </div>
              </section>
              <hr />
            </div>
            <section>
              <h5 className="fw-bold mt-4">Location of organization</h5>
              <ShowLocation location={contactAndLinks.location} />
            </section>
            <section className=" col-lg-10 w-100 mt-5 w-100 ">
              <h4 className="my-2 p-0 m-0">Is this organization reviewed?</h4>
              <div className="mt-2 text-end d-flex flex-column">
                <textarea
                  className="form-control w-100 p-2"
                  id="textAreaExample"
                  rows="4"
                  placeholder="Give your comments here..."
                  value={comment}
                  onChange={commentchange}
                ></textarea>
                <span className="my-2">{comment.length} / 300</span>
                <div className="d-flex w-100 justify-content-end gap-2 mt-2">
                  <button
                    className="btn shadow-0 "
                    onClick={() => {
                      setcomment("");
                    }}
                  >
                    Clear
                  </button>
                  <button
                    onClick={handlegiveReview}
                    className="btn shadow-0 btn-danger"
                  >
                    Need More Info
                  </button>
                  <button
                    onClick={handleApprove}
                    className="btn shadow-0 btn-success "
                  >
                    Approve
                  </button>
                </div>
              </div>
            </section>
          </div>
        </Wrapper>
      </Box>
    </Container>
  );
};

export default AdminOrganizationDetailReview;
