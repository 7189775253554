import { Container, Button, Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { FormInput, InputCountry } from "../../../../../components/Index";
import { ImCross } from "react-icons/im";

import{adminEditUserAction} from "../../../../../context/actions/admin/userAction"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const AdminUserEdit = ({ data,activeindex,id, onClose }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = React.useState({});
  const [formvalue, setFormValue] = React.useState({
    firstname: data.firstname,
    lastname: data.lastname,
    email: data.email,
    profilepic: "",
  });

  const options = {
    isedit: false,
    preview: null,
    coverimg: null,

    file: "",
  };
  const [pfile, setpFile] = React.useState("");

  const [option, setoptions] = React.useState(options);
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      setoptions({
        ...option,
        preview: blobURL,
        file: e.target.files[0],
        isedit: true,
      });
      setpFile(e.target.files[0]);
      e.target.value = "";
    }
  };

  const [selectedOptions, setSelectedOptions] = useState(data?.country);
  const handleSubmit = (e) => {
    e.preventDefault();
   const formData = {
     firstname: formvalue.firstname,
     lastname: formvalue.lastname,
     email: formvalue.email,
     country: selectedOptions,
     userpic: pfile,
   };
  dispatch(
    adminEditUserAction({
      data: formData,
      id: id,
      toast: toast,
      activeindex: activeindex,
    })
  );

  };


  const handleChange = (e) => {
    setFormValue({ ...formvalue, [e.target.name]: e.target.value });
  };
  const handleChanges2 = (event, value) => setSelectedOptions(value);

  return (
    <Container
      className="d-flex flex-column gap-3 pt-3 position-relative "
      sx={{
        width: { xs: "100vw", lg: "70vw", md: "80vw", sm: "90vw" },

        minHeight: "90vh",
      }}
    >
      <section className="option-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-6 text-black">
            Users Edit
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <Box
        className="profilepic  position-relative"
        sx={{
          height: "calc(50px + 7vw)",
          width: "calc(50px + 7vw)",
          maxHeight: "180px",
          maxWidth: "180px",
          margin: "auto",
          cursor: "pointer",
          borderRadius: "50%",
          border: "10px solid #f1f1f1",
          overflow: "hidden",
          "&:hover > #child-element ": {
            visibility: "visible",
            height: "50%",
          },
        }}
      >
        <img
          alt="Remy Sharp"
          src={
            option.preview ??
            option.coverimg ??
            `${process.env.REACT_APP_IMAGE_PATH}${data.profilepic}`
          }
          className="object-cover object-center w-100 h-100"
        />
        <Box
          className="bg-black hover-section w-100 text-white d-flex  align-items-center justify-content-center fw-bold bg-opacity-50 position-absolute bottom-0  start-0  w-100"
          sx={{
            borderBottomLeftRadius: "300px",
            borderBottomRightRadius: "300px",
            visibility: "hidden",
            cursor: "pointer",
            height: "0",
            transition: "height 1s ",
          }}
          id="child-element"
        >
          <label
            htmlFor={"profilepic"}
            className="h-100 w-100 d-flex align-items-center justify-content-center  "
            role="button"
          >
            Edit
          </label>
          <input
            type="file"
            accept="image/*"
            className="d-none"
            id={"profilepic"}
            onChange={onEdit}
          />
        </Box>
      </Box>

      <div className="right-side">
        <Stack spacing={2} className="d-flex flex-column gap-2">
          <div className="d-flex gap-3 w-100">
            <FormInput
              name={"firstname"}
              label={"First name"}
              type={"text"}
              required={true}
              value={formvalue.firstname}
              error={errors.firstname ? true : false}
              helperText={errors.firstname}
              classname={"w-100 "}
              handleChange={handleChange}
            />
            <FormInput
              name={"lastname"}
              label={"Last name"}
              value={formvalue.lastname}
              type={"text"}
              required={true}
              error={errors.lastname ? true : false}
              helperText={errors.lastname}
              classname={"w-100"}
              handleChange={handleChange}
            />
          </div>
          <FormInput
            name={"email"}
            label={"Email Address"}
            type={"email"}
            required={true}
            error={errors.email ? true : false}
            helperText={errors.email}
            value={formvalue.email}
            handleChange={handleChange}
          />

          <InputCountry
            label={"Choose your country"}
            handleChange={handleChanges2}
            required={true}
            error={errors.country ? true : false}
            helperText={errors.country}
            selectedOptions={selectedOptions}
          />
        </Stack>
      </div>

      <div className="position-sticky bottom-0 start-0 mt-auto">
        <Button
          className="btn-dark text-capitalize  "
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
        >
          Save and send Email
        </Button>
      </div>
    </Container>
  );
};

export default AdminUserEdit;
