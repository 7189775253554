import ProtectApi from "./adminAuthorizeApi";

export const adminCreateStudentRequiremnet = (data) =>
  ProtectApi.post("/api/admin/student/admin_create_student_requirement", data);
export const adminGetAllStudentRequirement = (keyword = "", page = 0) =>
  ProtectApi.get(
    `/api/admin/student/admin_get_all_student_requirement?keyword=${keyword}&page=${page}`
  );

  //admin_create_country_overview
export const adminCreateCountryOverview = (data) =>
ProtectApi.post("/api/admin/student/admin_create_country_overview", data);


// admin_table_by_country
export const adminGetTableByCountry = (country) =>
ProtectApi.get(
  `/api/admin/student/admin_get_abroad_study_table/${country}`
);



