import React from "react";

import Wrapper from "./OrganizationReviewStyle";
import Avatar from "@mui/material/Avatar";
import { Box, Typography } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import FavoriteIcon from "@mui/icons-material/Favorite";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ProfilePic } from "../../../profile/components";

import LinkIcon from "@mui/icons-material/Link";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import PhoneIcon from "@mui/icons-material/Phone";
import { ImCross } from "react-icons/im";

const OrganizationReview = ({ closemodal }) => {
  const options = {
    isedit: false,
    preview: null,
    coverimg: null,
    file: "",
  };
  const [coverimg, setcoverimg] = React.useState(options);
  const [comment, setcomment] = React.useState("");
  const [profilepic, setPfile] = React.useState("");

  const commentchange = (e) => {
    if (comment.length < 300) {
      setcomment(e.target.value);
    }
  };

  // save profilepic
  const onfilesave = (file) => {
    setPfile(file);
  };

  // on coverpage edit
  const onEdit = (e) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      let blobURL = URL.createObjectURL(file);
      setcoverimg({
        ...coverimg,
        isedit: true,
        preview: blobURL,
        file: e.target.files[0],
      });
      e.target.value = "";
    }
  };

  return (
    <Wrapper className="pt-2">
      <div className="d-flex w-100 justify-content-between my-2">
        <span className="fs-4 fw-bold px-2">Review For Name</span>
        <Box
          className="bg-hover rounded rounded-circle p-2 d-flex justify-content-center align-items-center"
          onClick={closemodal}
          role="button"
        >
          <ImCross className=" bg-hover rounded rounded-circle fs-5" />
        </Box>
      </div>
      <section className="profile image-section">
        <div className="overview-header  w-100">
          <div className="overview-img  w-100 ">
            <div className="edit-icons position-absolute top-0   end-0 p-2 ">
              <input
                type="file"
                name=""
                id="orgcoverimg"
                accept="image/*"
                className="d-none"
                onChange={onEdit}
              />
            </div>
            <img
              src={
                coverimg.preview ??
                coverimg.coverimg ??
                "https://images.unsplash.com/photo-1530625243345-797b4c1836ee?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80"
              }
              alt=""
              srcSet=""
              className="object-cover w-100 h-100 "
            />
          </div>
          <ProfilePic
            image={
              "https://images.unsplash.com/photo-1488161628813-04466f872be2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
            }
            id={"organizationpic"}
          />
        </div>
        <Box className="text-center d-flex flex-column align-items-center m-0 ">
          <h4>Organization name</h4>
          <p className="fs-6">Toronto ON, Canada</p>
          <button className="verifed  p-2 rounded-2 px-5 m-0 d-flex bg-hover border-0  gap-2 align-items-center justify-content-center">
            <span className=" fs-5">Organization</span>
            <VerifiedIcon
              className="fs-3"
              sx={{
                color: "#13B9FF",
              }}
            />
          </button>
        </Box>
      </section>

      <div className="container pb-2">
        <section className="org-two p-3 row row-cols-lg-2 row-cols-1 col-lg-10 justify-content-between  w-100  rounded-2 mt-3 ">
          <div className="bg-hover col col-lg-8 ">
            <div className="d-flex justify-content-between p-2">
              <Typography variant="h4" className="fw-bolder">
                Overview
              </Typography>
              <div className="org-two_interaction d-flex gap-2 align-items-center">
                <div className="d-flex ">
                  <FavoriteIcon
                    sx={{
                      color: "#C1C1C1",
                    }}
                  />
                  <span className="ms-2">300</span>
                </div>
                <div className="d-flex">
                  <RemoveRedEyeIcon
                    sx={{
                      color: "#C1C1C1",
                    }}
                  />
                  <span className="ms-2">200</span>
                </div>
              </div>
            </div>
            <p className="fw-bold">Non-profitable organizations</p>
            <div className="d-flex org-two_info gap-5  mt-3">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum
                amet aspernatur eaque reiciendis, inventore facilis optio magnam
                ut odit quo dignissimos, id exercitationem voluptatum! Itaque
                explicabo laborum exercitationem necessitatibus sit alias totam
                ex! Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Dicta tenetur adipisci animi veniam quibusdam, autem ratione!
                Quae enim dignissimos voluptas aspernatur iste eveniet
                similique, quia autem quo itaque molestias consectetur nam!
                Illum, consequuntur?
              </p>
            </div>
          </div>
          <div className="col col-lg-4 m-0 ">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.920542841694!2d85.35806101487087!3d27.688850582799617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb1a23ff53c6c1%3A0xbf154d5bf0abe0ba!2sPepsicola!5e0!3m2!1sen!2snp!4v1676468027240!5m2!1sen!2snp"
              title="rental location"
              allowfullscreen=""
              className="w-100 object-cover h-100 m-0"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
        <section className="org-three col-lg-10 mt-5 text-hover">
          <h4 className="my-2">General Information</h4>
          <ul className="fs-5 m-0 p-0">
            <li>
              <span className="me-2">
                <LinkIcon />
              </span>
              www.onfcanada.com
            </li>
            <li>
              <span className="me-2">
                <DateRangeIcon />
              </span>
              Established at 2023
            </li>
          </ul>
        </section>
        <section className="org-three col-lg-10 mt-5 text-hover">
          <h4 className="my-2 p-0 m-0">Contact Information</h4>
          <Box
            className="row-cols-lg-2 row m-0 p-0"
            sx={{
              lineHeight: "35px",
            }}
          >
            <ul className="fs-5 m-0 p-0 col">
              <li>
                <span className="me-2">@</span>
                info@onfcanada.com
              </li>
              <li>
                <span className="me-2">
                  <FacebookIcon />
                </span>
                www.facebook.com
              </li>
              <li>
                <span className="me-2">
                  <TwitterIcon />
                </span>
                www.twitter.com
              </li>
            </ul>
            <ul className="fs-5 m-0 p-0 col">
              <li>
                <span className="me-2">
                  <PhoneIcon />
                </span>
                +977 982343242342
              </li>
              <li>
                <span className="me-2">
                  <PhoneIcon />
                </span>
                +977 982343242342
              </li>
            </ul>
          </Box>
        </section>
        <section className=" col-lg-10 mt-3 ">
          <h6>Board Members</h6>
          <div className="row row-cols-2 g-2  ">
            {[...Array(4)].map((item) => {
              return (
                <div className="col-lg-6 border-member d-flex align-items-center gap-2">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
                    sx={{
                      height: "40px",
                      width: "40px",
                    }}
                  />
                  <Box
                    className="mt-2"
                    sx={{
                      lineHeight: "1px",
                      fontSize: "0.7rem",
                    }}
                  >
                    <h6 className="fw-bold">John Doe</h6>
                    <p>Chief Executing Officer</p>
                  </Box>
                </div>
              );
            })}
          </div>
        </section>
        <section className=" col-lg-10 w-100 mt-5 w-100 ">
          <h4 className="my-2 p-0 m-0">Review</h4>
          <div className="mt-2 text-end d-flex flex-column">
            <textarea
              className=" border border-2 w-100 p-2"
              id="textAreaExample"
              rows="4"
              placeholder="Enter Review"
              value={comment}
              onChange={commentchange}
            ></textarea>
            <span className="my-2">{comment.length} / 300</span>
            <div className="d-flex w-100 justify-content-end gap-2 mt-2">
              <button
                className="btn shadow-0 "
                onClick={() => {
                  setcomment("");
                }}
              >
                Clear
              </button>
              <button className="btn shadow-0 btn-danger">
                Need More Info
              </button>
              <button className="btn shadow-0 btn-success ">Approve</button>
            </div>
          </div>
        </section>
      </div>
    </Wrapper>
  );
};

export default OrganizationReview;
