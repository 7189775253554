import { Modal, Rating, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 3,
  p: 4,
};

const ReviewModal = ({ submitCommnet,openReview, handleCloseReview, handleRatingChnage, handleCommnetchnage, review, }) => {

  return (
    <Modal
      open={openReview}
      onClose={handleCloseReview}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Review & Ratings
        </Typography>
        <hr className="mt-1 mb-1" />
        <p className="fw-bold">Give your rating</p>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <Rating
              name="simple-controlled"
              value={review.rating}
              onChange={handleRatingChnage}
            />
            <p>{review.rating} Star</p>
          </div>

        </div>
        <div className="d-flex flex-column mt-2">
            <TextField
              multiline
              rows={2}
              variant="outlined"
              placeholder="Write your comment here (optional)"
              onChange={handleCommnetchnage}
            />
            <button 
            onClick={submitCommnet}
            
            className="btn btn-primary mt-2">Submit</button>
          </div>
      </Box>
    </Modal>
  );
};

export default ReviewModal;
