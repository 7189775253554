import { Box, Container, Drawer, Tab, Typography } from "@mui/material";
import React from "react";

import { ImCross } from "react-icons/im";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BiPencil } from "react-icons/bi";
import { AdminProfessionalIntro, AdminProfessionalContact } from "./tabs";
import AdminProfessionalEdit from "../AdminProfessionalEdit";
import {
  adminChangeProfessionalStatusAction,
  adminUpdateFeaturedProfessionalAction,
} from "../../../../../context/actions/admin/professionalAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
const AdminProfessionalDetail = ({ data, id, onClose, activeindex }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const tabs = ["Information", "Contact Details", "Offers"];

  const [value, setValue] = React.useState("0");

  // change tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // make handleChangesfalse function
  const handleChangesfalse = (e) => {
    e.preventDefault();
  };
  const handleChangestrue = (e) => {
    e.preventDefault();
  };

  const handleActiveStatusFalse = (e) => {
    e.preventDefault();

    dispatch(
      adminChangeProfessionalStatusAction({
        id: id,
        data: false,
        toast: toast,
        activeindex: activeindex,
      })
    );
  };

  const handleActiveStatusTrue = (e) => {
    e.preventDefault();
    dispatch(
      adminChangeProfessionalStatusAction({
        id: id,
        data: true,
        toast: toast,
        activeindex: activeindex,
      })
    );
  };

    const featuredTure = (e) => {
      e.preventDefault();
      dispatch(
        adminUpdateFeaturedProfessionalAction({
          id: id,
          data: true,
          toast,
          activeindex,
        })
      );
    };

    const featuredFalse = (e) => {
      e.preventDefault();
      dispatch(
        adminUpdateFeaturedProfessionalAction({
          id: id,
          data: false,
          toast,
          activeindex,
        })
      );
    };


  const { admin_change_staus_of_professional_loading } = useSelector((state) => state.admin_reducer);


  return (
    <Container
      sx={{
        paddingBlock: "3vh",
        minHeight: "100vh",

        width: { lg: "70vw", xs: "100vw", md: "80vw", sm: "90vw" },
      }}
      className="container h-maxcontent"
    >
      <section className="rental-header">
        <div className="d-flex align-items-center justify-content-between m-0">
          <span className="text-decoration-underline fs-5 fw-bold text-black">
            Professional Dashboard
          </span>
          <Box
            className="bg-hover d-flex align-items-center justify-content-center p-2 me-2"
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50px",
            }}
            onClick={onClose}
            role="button"
          >
            <ImCross
              style={{
                fontSize: "1.3em",
              }}
            />
          </Box>
        </div>
      </section>
      <hr />

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          textAlign: { xs: "center", sm: "start" },
        }}
        className=" gap-2   w-100 align-items-center justify-content-center "
      >
        <img
          src={`${process.env.REACT_APP_IMAGE_PATH}${data.userPic}`}
          alt=""
          srcSet=""
          className="rounded-circle rounded object-cover"
          style={{
            border: "6px solid #F1F1F1",
            height: 80,
            width: 80,
          }}
        />
        <div className="d-flex flex-column">
          <Typography
            variant="h2"
            className="fw-bold text-black"
            sx={{
              fontSize: { xs: "0.7em", sm: "0.9em", md: "1.1em", lg: "1.5em" },
            }}
          >
            {data.firstName} {data.lastName}
          </Typography>
          <Typography
            variant="span"
            className="badge badge-light"
            sx={{
              fontSize: { xs: "0.7em", sm: "0.7em", md: "1.1em", lg: "1em" },
            }}
          >
            {data?.email} ✅
          </Typography>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", sm: "row", md: "row" },
            justifyContent: { xs: "center" },
            width: { xs: "100%", sm: "max-content" },
          }}
          className=" gap-2 ms-auto  "
        >
          <button
            type="button"
            className="btn btn-sm btn-outline-dark d-flex flex-nowrap gap-1"
            data-mdb-ripple-color="dark"
            onClick={toggleDrawer}
          >
            <BiPencil /> <span>Edit</span>
          </button>
          {data?.featured ? (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              data-mdb-ripple-color="dark"
              onClick={featuredFalse}
            >
              unfeatured
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-sm btn-outline-primary"
              data-mdb-ripple-color="dark"
              onClick={featuredTure}
            >
              featured
            </button>
          )}
          {data?.active ? (
            <button
              type="button"
              class="btn btn-sm btn-danger"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusFalse}
            >
              Deactive
            </button>
          ) : (
            <button
              type="button"
              class="btn btn-sm btn-success"
              data-mdb-ripple-color="dark"
              onClick={handleActiveStatusTrue}
            >
              Active
            </button>
          )}
        </Box>
      </Box>

      <section className="mt-3">
        <TabContext value={value.toString()}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              background: "#F6F6F6",
            }}
          >
            {tabs.map((item, index) => {
              return (
                <Tab
                  label={item}
                  value={index.toString()}
                  key={item}
                  className="text-capitalize"
                  indicatorcolor={""}
                  sx={{
                    "&.Mui-selected": {
                      color: "#797979",
                      fontWeight: "bolder",
                      border: "none",
                    },
                  }}
                />
              );
            })}
          </TabList>

          <TabPanel className="m-0 p-0 py-3" value="0">
            <AdminProfessionalIntro data={data} />
          </TabPanel>
          <TabPanel className="m-0 p-0 py-3" value="1">
            <AdminProfessionalContact data={data} />
          </TabPanel>
          <TabPanel className="m-0 p-0 py-3" value="2">
            {/* <LstOfBoardMembers /> */}
          </TabPanel>
        </TabContext>
      </section>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <AdminProfessionalEdit
          data={data}
          activeindex={activeindex}
          onClose={toggleDrawer}
        />
      </Drawer>
    </Container>
  );
};

export default AdminProfessionalDetail;
