import React from "react";
import { lookingForLst } from "../../../utils/flatemateutils";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/material";

const LookingForCard = ({ item, selected, handleFormValue }) => {
  return (
    <Box
      role="button"
      sx={{
        fonSize: "2em",
      }}
      onClick={() => handleFormValue("lookingFor", item.name)}
      className={
        selected === item.name
          ? "d-flex rounded rounded-3 position-relative  border-dark text-lg-start border border-2  justify-content-between align-items-center text-center p-2"
          : "d-flex rounded rounded-3 position-relative  text-lg-start border border-1 border-dark justify-content-between align-items-center text-center p-2"
      }
    >
      <div className="d-flex flex-column p-2 align-items-start">
        <h6 className="fw-bold p-0 m-0">{item.name}</h6>
        <p className="text-start">{item.description}</p>
      </div>
      <div className="fs-3">{item.icon}</div>

      <CheckCircleOutlineIcon
        className={selected === item.name ? "d-flex fw-bold" : "d-none"}
        sx={{
          position: "absolute",
          top: "3%",
          right: "1%",
        }}
      />
    </Box>
    //   <div
    //   role="button"
    //   onClick={() => handleAccessType("accessTo", name)}
    //   className={
    //     selected === name
    //       ? "d-flex p-2 mb-3  justify-content-center flex-column border border-2 border-dark rounded rounded-3 text-start position-relative"
    //       : "d-flex p-2 mb-3  justify-content-center flex-column border border-2 rounded rounded-3 text-start position-relative"
    //   }
    // >
    //   <div className="d-felx"></div>
    //   <div className="m-3">
    //     <div className="d-flex justify-content-between">

    //       <div>
    //         <h6 className="fw-bold p-0 m-0">{name}</h6>
    //         <p>{description}</p>
    //       </div>
    //       <div>{icon}</div>
    //     </div>
    //   </div>

    //   <CheckCircleOutlineIcon
    //     className={selected === name ? "d-flex" : "d-none"}
    //     sx={{
    //       position: "absolute",
    //       top: "6%",
    //       right: "1%",
    //     }}
    //   />
    // </div>
  );
};
const LookingFor = ({ handleFormValue, selected, errors }) => {
  return (
    <Box className={errors ? " p-2 border-danger border border-2 " : " p-2 "}>
      <p className="fs-3 fw-bold text-black">I am looking property for,</p>
      {errors ? <small className="text-danger">{errors}</small> : null}

      <div className="row-cols-1 row row-cols-md-2 gx-2 gy-2">
        {lookingForLst.map((item) => (
          <div key={item.name}>
            <LookingForCard
              selected={selected}
              handleFormValue={handleFormValue}
              item={item}
            />
          </div>
        ))}
      </div>
    </Box>
  );
};

export default LookingFor;
